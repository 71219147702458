import { LookUpTypes } from "../actionTypes";
import { getGroupedArray } from "../../utilities/utils";
const initialState = {
  config: null,
};

export default (state = initialState, action) => {
  //NOSONAR
  switch (action.type) {
    case LookUpTypes.LOOK_UP_DATA: {
      const data = action.payload;
      if (data.trades) {
        data.trades = getGroupedArray(data.trades, "parent");
      }
      return {
        ...state,
        LookUpData: { ...action.payload },
      };
    }
    default:
      return state;
  }
};
