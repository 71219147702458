import { withStyles } from "@material-ui/core/styles";
import tradework_theme, { pxToRem, color } from "../../../utilities/themes";

const styles = withStyles({
  mainSettingsBlock: {
    marginTop: pxToRem(22),
    marginBottom: pxToRem(24),
  },
  settingsTxtAlign: {
    position: "relative",
    top: pxToRem(10),
  },
  settingsLogoAlign: {
    marginLeft: pxToRem(9),
    width: pxToRem(46),
    height: pxToRem(42),
  },
  marginLeft120: {
    marginLeft: pxToRem(120),
  },
  mainTextUnderline: {
    ...tradework_theme.typography.styles.avenir_sb,
    color: color.primary_palette.franklin_purple,
    textDecoration: "underline",
    fontSize: pxToRem(14),
    cursor: "pointer",
    width: pxToRem(185),
    height: pxToRem(23),
    marginBottom: pxToRem(40),
  },
  mainTextsize: {
    width: pxToRem(185),
    cursor: "pointer",
    height: pxToRem(23),
    borderRadius: pxToRem(3),
    lineHeight: pxToRem(23),
    marginLeft: pxToRem(44),
    marginBottom: pxToRem(42),
    textAlign: "center",
    background: color.background_gray,
    border: `solid ${pxToRem(1)} ${color.bordered_gray}`,
    color: color.form_colors.textfield_color,
    fontSize: pxToRem(11),
    ...tradework_theme.typography.styles.gillsans_r,
  },
  profileImgStyle: {
    width: pxToRem(70),
    height: pxToRem(70),
    borderRadius: "50%",
    marginRight: pxToRem(9),
    position: "relative",
    top: pxToRem(30),
    display: "inline-block",
  },
  profileImgStyleAlign: {
    width: pxToRem(70),
    height: pxToRem(70),
    borderRadius: "50%",
    marginRight: pxToRem(9),
    position: "relative",
    top: pxToRem(-20),
  },
  profileBlockAlign: {
    marginBottom: pxToRem(35),
  },
  textInline: {
    display: "inline",
  },
  txtBlock: {
    marginBottom: pxToRem(13),
  },
  subDivBlock: {
    display: "inline-block",
    padding: pxToRem(10),
    marginTop: pxToRem(124),
    borderLeft: `solid ${pxToRem(2)} ${color.primary_palette.franklin_purple}`,
    paddingLeft: pxToRem(29),
  },
  subDivBlockMain: {
    display: "inline-block",
    padding: pxToRem(10),
    width: pxToRem(366),
    paddingLeft: pxToRem(40),
  },
  sideNavAlign: {
    width: "20%",
  },
  passwordAlign: {
    position: "relative",
    top: pxToRem(8),
  },
  toggleSwitchStyle: {
    display: "inline-block",
  },
  edit_mode_switch: {
    paddingLeft: pxToRem(9),
    marginTop: pxToRem(-8),
    "& .MuiGrid-container ": {
      "& .MuiSwitch-root": {
        marginLeft: pxToRem(0),
        marginRight: pxToRem(0),
      },
      position: "relative",
      "& .checked_dot": {
        top: pxToRem(-9),
        left: pxToRem(-7),
      },
      "& .MuiGrid-item": {
        "& label": {
          fontSize: pxToRem(15),
          ...tradework_theme.typography.styles.gillsans_sb,
          color: color.primary_palette.franklin_purple,
        },
      },
    },
  },
  grayButtonStyle: {
    width: pxToRem(185),
    cursor: "pointer",
    height: pxToRem(23),
    borderRadius: pxToRem(3),
    lineHeight: pxToRem(23),
    textAlign: "center",
    background: color.background_gray,
    border: `solid ${pxToRem(1)} ${color.bordered_gray}`,
    color: color.form_colors.textfield_color,
    fontSize: pxToRem(11),
    ...tradework_theme.typography.styles.gillsans_r,
    marginBottom: pxToRem(4),
  },
  customToggleAlign: {
    marginTop: pxToRem(17),
  },
  blueButtonStyle: {
    width: pxToRem(185),
    height: pxToRem(23),
    lineHeight: pxToRem(23),
    cursor: "pointer",
    textAlign: "center",
    borderRadius: pxToRem(3),
    background: color.fb_button_bg_color,
    border: `solid ${pxToRem(1)} ${color.bordered_gray}`,
    color: color.primary_palette.white,
    fontSize: pxToRem(11),
    ...tradework_theme.typography.styles.gillsans_r,
    marginBottom: pxToRem(4),
  },
  passwordBlkAlign: {
    marginBottom: pxToRem(46),
  },
  orTxtColor: {
    fontSize: pxToRem(11),
    ...tradework_theme.typography.styles.gillsans_r,
    color: color.dark_grayed_text_color,
    marginBottom: pxToRem(4),
    textAlign: "center",
  },
  btnBlockAlign: {
    display: "inline-block",
  },
  basicInfo: {
    marginBottom: pxToRem(13),
  },
  leftLine: {
    width: pxToRem(75),
    marginRight: pxToRem(5),
    position: "relative",
    top: pxToRem(-3),
    display: "inline-block",
    borderBottom: `solid ${pxToRem(1)} ${color.bordered_gray}`,
  },
  rightLine: {
    width: pxToRem(75),
    marginLeft: pxToRem(5),
    position: "relative",
    top: pxToRem(-3),
    borderBottom: `solid ${pxToRem(1)} ${color.bordered_gray}`,
    display: "inline-block",
  },
  authOptionFieldAlign: {
    marginBottom: pxToRem(20),
    position: "relative",
    left: pxToRem(100),
    bottom: pxToRem(30),
    "& .MuiInputBase-input": {
      ...tradework_theme.typography.styles.NeutraText,
      color: color.primary_palette.black,
    },
  },
  textWidthMobile: {
    width: pxToRem(322),
  },
  passwordTxtAlign: {
    marginLeft: pxToRem(135),
    fontSize: pxToRem(13),
    fontFamily: "avenir_light",
    color: color.form_colors.textfield_color,
  },
  errorText: {
    position: "relative",
    fontSize: pxToRem(12),
    color: color.primary_palette.christmas_red + "!important",
    fontFamily: "avenir_black_r",
  },
  successText: {
    position: "relative",
    fontSize: pxToRem(12),
    color: color.form_colors.sucess_color + "!important",
    fontFamily: "avenir_black_r",
  },
  edit_save_btn: {
    width: pxToRem(99),
    height: pxToRem(25),
    borderRadius: pxToRem(27),
    border: `solid ${pxToRem(1)} ${color.primary_palette.franklin_purple}`,
    backgroundColor: color.primary_palette.white,
    marginRight: pxToRem(13),
    padding: 0,
    "& .MuiButton-label": {
      color: color.primary_palette.franklin_purple,
      fontSize: pxToRem(15),
      ...tradework_theme.typography.styles.avenir_sb,
    },
  },
  edit_save_btn_disable: {
    width: pxToRem(132),
    height: pxToRem(35),
    borderRadius: pxToRem(22),
    backgroundColor: color.primary_palette.white,
    border: `solid ${pxToRem(2)} ${color.primary_palette.disable_border}`,
    "& .MuiButton-label": {
      ...tradework_theme.typography.styles.avenir_sb,
      fontSize: pxToRem(15),
    },
  },
  passwordFieldAlign: {
    marginLeft: pxToRem(8),
    width: pxToRem(280),
    "& .MuiInputBase-input": {
      ...tradework_theme.typography.styles.NeutraText,
      color: color.primary_palette.black,
    },
  },
  password_field: {
    display: "flex",
  },
  pencil_icon: {
    marginLeft: pxToRem(7),
    height: pxToRem(16),
    width: pxToRem(16),
    cursor: "pointer",
  },
  saveFlipIcon: {
    marginTop: pxToRem(10),
    height: pxToRem(16),
    width: pxToRem(16),
    cursor: "pointer",
  },
  auth_pencil_icon: {
    height: pxToRem(16),
    width: pxToRem(16),
    position: "relative",
    bottom: pxToRem(19),
    marginLeft: pxToRem(110),
    cursor: "pointer",
  },
  happy_arrow: {
    height: pxToRem(16),
    width: pxToRem(16),
    position: "relative",
    bottom: pxToRem(14),
    marginLeft: pxToRem(20),
    top: pxToRem(8),
    cursor: "pointer",
  },
  SaveBtnDiv: {
    textAlign: "center",
    marginTop: pxToRem(40),
    marginBottom: pxToRem(40),
  },
  toggle_alignment: {
    "& .MuiGrid-item": {
      marginRight: pxToRem(9),
    },
  },
  padding_left_7: {
    paddingLeft: pxToRem(7),
  },
  edit_mode_switch1: {
    "& .MuiGrid-align-items-xs-center": {
      right: pxToRem(-3),
    },
  },
  dropDownIcon: {
    height: "5px",
    width: "11px",
    cursor: "pointer",
  },
  phoneNumberActions: {
    marginLeft: pxToRem(14),
    display: "inline",
    marginTop: pxToRem(6),
  },
  dropdownOptions: {
    position: "absolute",
    border: "1px solid #bfbfbf",
    borderRadius: "4px",
    boxShadow: "1px 1px 2px 0 rgba(0, 0, 0, 0.15)",
    fontSize: "12px",
    height: "30px",
    width: "74px",
    padding: "5px 2px 5px 2px",
    background: "white",
    cursor: "pointer",
    zIndex: 1000,
  },
  countryImage: {
    width: "16px",
    height: "12px",
    paddingRight: "2px",
  },
  default_search_wrap: {
    display: "flex",
    justifyContent: "flex-start",
  },
  default_search_dd: {
    width: pxToRem(148),
    marginLeft: pxToRem(12),
    ...tradework_theme.typography.styles.avenir_light,
    fontSize: pxToRem(15),
    "& .MuiSvgIcon-root": {
      fontSize: pxToRem(10),
    },
  },
  default_search_items: {
    fontSize: pxToRem(15),
    ...tradework_theme.typography.styles.avenir_light,
  },
  unEditable: {
    pointerEvents: "none",
    "& .MuiInputBase-input": {
      ...tradework_theme.typography.styles.NeutraText,
      color: color.primary_palette.black,
    },
  },
  width_185: {
    width: pxToRem(185),
  },
});

export default styles;
