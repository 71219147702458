import { withStyles } from "@material-ui/core/styles";
import tradework_theme, { pxToRem, color } from "../../../utilities/themes";

const styles = withStyles({
  textCenter: {
    textAlign: "center",
  },
  product: {
    position: "relative",
    border: `solid ${pxToRem(1)} ${color.primary_palette.free_purple}`,
    marginTop: pxToRem(54),
    paddingBottom: pxToRem(54),
  },
  product_text: {
    position: "absolute",
    top: pxToRem(-18),
    backgroundColor: "white",
    padding: `${pxToRem(0)} ${pxToRem(14)}`,
    left: pxToRem(14),
  },
  product_publication: {
    marginRight: pxToRem(25),
    marginLeft: pxToRem(25),
    width: "96%",
    marginTop: pxToRem(80),
  },
  product_img: {
    width: pxToRem(188),
    height: pxToRem(170),
  },
  img_block: {
    width: pxToRem(135),
    height: pxToRem(162),
    margin: "0 auto",
    backgroundColor: color.secondary_palette.grays.background_gray,
    "& img": {
      width: "100%",
      height: "100%",
      objectFit: "cover",
    },
  },
  product_spacing: {
    marginTop: pxToRem(40),
    width: "96%",
    margin: "0 auto",
  },
  width40: {
    width: "40%",
    borderRight: `solid ${pxToRem(1)} ${
      color.secondary_palette.grays.shadow_gray
    }`,
  },
  width60: {
    width: "60%",
    marginRight: pxToRem(61),
    marginLeft: pxToRem(50),
    display: "block",
  },
  more_info: {
    textDecoration: "underline",
    color: color.primary_palette.free_purple,
  },
  flex_end_block: {
    alignItems: "flex-end",
  },
  product_des: {
    height: pxToRem(158),
    display: "table-cell",
    verticalAlign: "middle",
  },
  margin_17: {
    marginRight: pxToRem(17),
    marginLeft: pxToRem(17),
  },
  padding_top_25: {
    paddingTop: pxToRem(25),
  },
  horizontal_DB: {
    width: pxToRem(238),
    height: pxToRem(16),
  },
  padding_top_18: {
    paddingTop: pxToRem(18),
  },
  titleDivAlign: {
    width: pxToRem(250),
  },
  subDivAlign: {
    height: pxToRem(144),
  },
  product_publication_public: {
    marginRight: pxToRem(25),
    marginLeft: pxToRem(25),
    width: "96%",
  },
  publicIconAlign: {
    position: "relative",
    top: pxToRem(20),
    "& .hover_icon": {
      cursor: "pointer",
    },
    "&:hover": {
      "& .hover_icon": {
        "& path": {
          fill: color.form_colors.sucess_color,
        },
      },
    },
  },
  preview_btn: {
    height: pxToRem(16),
    width: pxToRem(195),
    marginRight: pxToRem(20),
    borderRadius: pxToRem(2),
    marginLeft: pxToRem(32),
    position: "absolute",
    top: pxToRem(130),
    border: `solid ${pxToRem(0.5)} ${color.primary_palette.black}`,
    backgroundColor: color.primary_palette.white,
    "& .MuiButton-label": {
      color: color.primary_palette.black,
      fontSize: pxToRem(12),
      ...tradework_theme.typography.styles.avenir_sb,
      position: "relative !important",
      textTransform: "none",
    },
    "&:hover": {
      border: `solid ${pxToRem(1.6)} ${color.primary_palette.highlight_purple}`,
      backgroundColor: `${color.primary_palette.white} !important`,
    },
  },
  betaStampIcon: {
    height: pxToRem(100),
    position: "absolute",
    right: pxToRem(10),
    cursor: "pointer",
  },
});

export default styles;
