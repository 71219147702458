import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core";
import tradework_theme, { pxToRem, color } from "../../../utilities/themes";

const useStyles = makeStyles({
  text: (props) => ({
    ...tradework_theme.typography.styles[props.family],
    fontSize: pxToRem(props.size),
    color: props.color,
    textTransform: props.transform,
  }),
});

const Text = ({ className, style, children, onClick, ...props }) => {
  const classes = useStyles(props);
  return (
    <p
      className={`${classes.text} ${className}`}
      style={style}
      onClick={onClick}
    >
      {children}
    </p>
  );
};

Text.propTypes = {
  family: PropTypes.oneOf([
    "gillsans_r",
    "gillsans_sb",
    "gillsans_light",
    "gillsans_bold",
    "avenir_black_r",
    "avenir_book_r",
    "avenir_bold",
    "avenir_light",
    "avenir_sb",
    "avenir_roman",
    '"Helvetica Neue"',
    "Zapfino_bold",
    "Zapfino",
    "Pacifico",
    "Pacifico_bold",
    "ACaslonPro",
    "ACaslonPro_bold",
    "OptimaLT",
    "OptimaLT_bold",
    "NeutraText",
    "NeutraText_bold",
    "Helvetica",
    "millerdisplay_light",
  ]),
  transform: PropTypes.oneOf(["uppercase", "capitalize", "lowercase"]),
  size: PropTypes.number,
  color: PropTypes.string,
  style: PropTypes.object,
};

Text.defaultProps = {
  family: "gillsans_r",
  size: 16,
  color: color.primary_palette.black,
};

export default Text;
