import { get } from "lodash";

import Api, { profileStepperApi } from "../api";
import { ProfileTypes } from "../actionTypes";
import { ApiActions } from ".";
import { URI } from "../../constants";

export default {
  fetchAllRegions: () => {
    return async (dispatch) => {
      ApiActions.request(dispatch);
      try {
        const res = await profileStepperApi.getAllRegions();
        const {
          data: { data },
        } = res;
        dispatch({
          type: ProfileTypes.FETCH_ALL_REGIONS,
          payload: data,
        });
        ApiActions.success(dispatch);
      } catch (err) {
        ApiActions.failure(dispatch, err);
      }
    };
  },
  fetchAllCategories: () => {
    return async (dispatch) => {
      ApiActions.request(dispatch);
      try {
        const res = await profileStepperApi.getAllCategories();
        const {
          data: { data },
        } = res;
        dispatch({
          type: ProfileTypes.FETCH_ALL_CATEGORIES,
          payload: data,
        });
        ApiActions.success(dispatch);
      } catch (err) {
        ApiActions.failure(dispatch, err);
      }
    };
  },
  fetchProjectSelection: () => {
    return async (dispatch) => {
      ApiActions.request(dispatch);
      try {
        const res = await profileStepperApi.getProjectSelection();
        const {
          data: { data },
        } = res;
        dispatch({
          type: ProfileTypes.FETCH_PROJECT_SELECTION,
          payload: data,
        });
        ApiActions.success(dispatch);
      } catch (err) {
        ApiActions.failure(dispatch, err);
      }
    };
  },
  fetchProjectImageSelection: () => {
    return async (dispatch) => {
      ApiActions.request(dispatch);
      try {
        const res = await profileStepperApi.getProjectImageSelection();
        const {
          data: { data },
        } = res;
        dispatch({
          type: ProfileTypes.FETCH_PROJECT_IMAGE_SELECTION,
          payload: data,
        });
        ApiActions.success(dispatch);
      } catch (err) {
        ApiActions.failure(dispatch, err);
      }
    };
  },
  saveProfileImage: (imageData, cb) => {
    return async (dispatch) => {
      ApiActions.request(dispatch);
      try {
        const formData = new FormData();
        imageData.profile && formData.append("profile", imageData.profile);
        imageData.cover && formData.append("cover", imageData.cover);
        imageData.logo && formData.append("logo", imageData.logo);
        imageData.profile &&
          formData.append("originalProfile", imageData.originalprofile);
        imageData.cover &&
          formData.append("originalCover", imageData.originalcover);
        imageData.logo &&
          formData.append("originalLogo", imageData.originallogo);
        formData.append("portfolioId", localStorage.getItem("portfolioId"));
        formData.append("backgroundColor", imageData.backgroundColor);
        const res = await Api.post(URI.SAVE_PROFILE_IMAGES, formData);
        cb && cb(res.data);
        dispatch({
          type: ProfileTypes.SAVE_PROFILE_IMAGE_DATA,
          payload: res.data.data,
        });
        ApiActions.success(dispatch);
      } catch (err) {
        ApiActions.failure(dispatch, err);
      }
    };
  },
  saveCompanyBasicsImages: (imageData, cb) => {
    return async (dispatch) => {
      ApiActions.request(dispatch);
      try {
        const formData = new FormData();
        imageData.headShot &&
          formData.append("headShotOrTeamPhoto", imageData.headShot);
        imageData.companyBasicsLogo &&
          formData.append("companyLogo", imageData.companyBasicsLogo);
        imageData.originalcompanyBasicsLogo &&
          formData.append(
            "originalCompanyLogo",
            imageData.originalcompanyBasicsLogo
          );
        imageData.originalheadShot &&
          formData.append(
            "originalHeadShotOrTeamPhoto",
            imageData.originalheadShot
          );
        formData.append("companyId", localStorage.getItem("companyId"));
        const res = await Api.post(
          URI.SAVE_COMPANY_BASICS_PROFILE_IMAGES,
          formData
        );
        cb && cb(res.data);
        dispatch({
          type: ProfileTypes.SAVE_PROFILE_IMAGE_DATA,
          payload: res.data.data,
        });
        ApiActions.success(dispatch);
      } catch (err) {
        ApiActions.failure(dispatch, err);
      }
    };
  },
  saveElevatorPitch: (data, callback) => {
    return async (dispatch) => {
      ApiActions.request(dispatch);
      try {
        const res = await profileStepperApi.saveElevatorPitchData(data);
        dispatch({
          type: ProfileTypes.SAVE_ELEVATOR_PITCH_DATA,
          payload: res.data.data.elevatorPitch,
        });
        if (!get(res, "data.error.error", true)) {
          callback && callback();
        }
        ApiActions.success(dispatch);
      } catch (err) {
        ApiActions.failure(dispatch, err);
      }
    };
  },
  saveProjectSortOrder: (data, callback) => {
    return async (dispatch) => {
      ApiActions.request(dispatch);
      const projects = data.projectsData.map((proj, idx) => {
        return {
          id: proj._id,
          shortedOrder: idx,
        };
      });
      data.projects = projects;
      try {
        const res = await profileStepperApi.sortProject(data);
        if (!get(res, "data.error.error", true)) {
          callback && callback();
        }
        ApiActions.success(dispatch);
      } catch (err) {
        ApiActions.failure(dispatch, err);
      }
    };
  },

  saveVignetteSortOrder: (data, callback) => {
    return async (dispatch) => {
      ApiActions.request(dispatch);
      const vignettes = data.map((proj, idx) => {
        return {
          id: proj._id,
          shortedOrder: idx,
        };
      });
      try {
        const res = await profileStepperApi.sortVignettes({
          vignettesData: vignettes,
          portfolioId: localStorage.getItem("portfolioId"),
        });
        if (!get(res, "data.error.error", true)) {
          callback && callback();
        }
        ApiActions.success(dispatch);
      } catch (err) {
        ApiActions.failure(dispatch, err);
      }
    };
  },
  saveProductSortOrder: (data, callback) => {
    return async (dispatch) => {
      ApiActions.request(dispatch);
      const projects = data.products.map((proj, idx) => {
        return {
          id: proj._id,
          shortedOrder: idx,
        };
      });
      const publications = data.publications.map((proj, idx) => {
        return {
          id: proj._id,
          shortedOrder: idx,
        };
      });
      data.productId = projects;
      data.publicationId = publications;
      try {
        const res = await profileStepperApi.sortProducts(data);
        if (!get(res, "data.error.error", true)) {
          callback && callback();
        }
        ApiActions.success(dispatch);
      } catch (err) {
        ApiActions.failure(dispatch, err);
      }
    };
  },
  saveBusinessCardToPortfolio: (data, callback) => {
    return async (dispatch) => {
      ApiActions.request(dispatch);
      try {
        const displayedAt = data.selectedRegions;
        data.displayedAt = displayedAt;
        data.phoneNumber = data.phoneNumber.replace(/[().\s/]/g, "");
        const res = await profileStepperApi.saveBusinessData(data);
        callback(res.data);
        ApiActions.success(dispatch);
      } catch (err) {
        ApiActions.failure(dispatch, err);
      }
    };
  },
  saveProjectData: (data, callback) => {
    return async (dispatch) => {
      ApiActions.request(dispatch);
      try {
        const { projectImages, imageData } = data;
        for (let i = 0; i < projectImages.length; i++) {
          const { alterImageUri, imageUri } = projectImages[i];
          if (alterImageUri) {
            if (alterImageUri.blobUrl) {
              const formData = new FormData();
              formData.append("files", alterImageUri.blobUrl);
              const res = await Api.post(URI.UPLOAD, formData);
              projectImages[i].imageUri = get(res, "data.data[0]");
            } else {
              projectImages[i].imageUri = imageUri.imageUrl;
            }
            if (alterImageUri.originalProjImgFile) {
              const formData = new FormData();
              formData.append("files", alterImageUri.originalProjImgFile);
              const res = await Api.post(URI.UPLOAD, formData);
              projectImages[i].actualImageUri = get(res, "data.data[0]");
            }
          }
        }
        if (imageData) {
          if (imageData.blobFile) {
            const formData = new FormData();
            formData.append("files", imageData.blobFile);
            const res = await Api.post(URI.UPLOAD, formData);
            data.coverImageUri = get(res, "data.data[0]");
          }
          if (imageData.originalImageFile) {
            const formData = new FormData();
            formData.append("files", imageData.originalImageFile);
            const res = await Api.post(URI.UPLOAD, formData);
            data.actualCoverImageUri = get(res, "data.data[0]");
          }
        }
        const resData = await profileStepperApi.saveProjectData(data);
        callback(resData.data);
        dispatch({
          type: ProfileTypes.SAVE_UPDATE_PROJECT,
          payload: resData.data.data,
        });
        ApiActions.success(dispatch);
      } catch (err) {
        ApiActions.failure(dispatch, err);
      }
    };
  },
  saveProductData: (data, callback) => {
    return async (dispatch) => {
      ApiActions.request(dispatch);
      try {
        const formData = new FormData();
        formData.append("portfolioId", data.portfolioId);
        data.imageData.blobFile &&
          formData.append("imageUri", data.imageData.blobFile);
        formData.append("isProduct", data.isProduct);
        data.title && formData.append("title", data.title);
        data.subTitle && formData.append("subTitle", data.subTitle);
        data.referenceUri && formData.append("referenceUri", data.referenceUri);
        data.description && formData.append("description", data.description);
        data.tags && formData.append("tags", data.tags);
        data._id && formData.append("_id", data._id);
        const res = await Api.post(URI.CREATE_PRODUCT_PUBLICATION, formData);
        if (!get(res, "data.error.error", true)) {
          callback && callback(res.data.data);
        }
        ApiActions.success(dispatch);
      } catch (err) {
        ApiActions.failure(dispatch, err);
      }
    };
  },
  saveVignetteData: (data, callback) => {
    return async (dispatch) => {
      ApiActions.request(dispatch);
      try {
        const formData = new FormData();
        formData.append("isLinkedToProject", data.isProduct);
        formData.append("portfolioId", data.portfolioId);
        data.productLink && formData.append("productLink", data.productLink);
        data.projectId && formData.append("projectId", data.projectId);
        data.position && formData.append("position", data.position);
        data.draggedImage &&
          formData.append("actualImageUri", data.draggedImage);
        formData.append("imageUri", data.imageFile.blobUrl);
        const res = await Api.post(URI.CREATE_UPDATE_VIGNETTE, formData);
        if (!get(res, "data.error.error", true)) {
          callback && callback();
        }
        ApiActions.success(dispatch);
      } catch (err) {
        ApiActions.failure(dispatch, err);
      }
    };
  },
  fetchProjectAndPublicationData: (id) => {
    return async (dispatch) => {
      ApiActions.request(dispatch);
      try {
        const res = await profileStepperApi.getProductsAndPublicationData(id);
        const {
          data: { data },
        } = res;
        dispatch({
          type: ProfileTypes.GET_PRODUCT_PUBLICATION_DATA,
          payload: data,
        });
        ApiActions.success(dispatch);
      } catch (err) {
        ApiActions.failure(dispatch, err);
      }
    };
  },
  deleteProductOrPublication: (id, callback) => {
    return async (dispatch) => {
      ApiActions.request(dispatch);
      try {
        const res = await profileStepperApi.deleteProductAndPublications(id);
        if (!get(res, "data.error.error", true)) {
          callback && callback();
        }
        ApiActions.success(dispatch);
      } catch (err) {
        ApiActions.failure(dispatch, err);
      }
    };
  },
  deleteVignette: (id, callback) => {
    return async (dispatch) => {
      ApiActions.request(dispatch);
      try {
        const res = await profileStepperApi.deleteVignette(id);
        if (!get(res, "data.error.error", true)) {
          callback && callback();
        }
        ApiActions.success(dispatch);
      } catch (err) {
        ApiActions.failure(dispatch, err);
      }
    };
  },
  getPortfolioData: (id, viewType) => {
    return async (dispatch) => {
      ApiActions.request(dispatch);
      try {
        const res = await profileStepperApi.getPortfolioData(id, viewType);
        dispatch({
          type: ProfileTypes.GET_PORTFOLIO_DATA,
          payload: res.data.data,
        });
        ApiActions.success(dispatch);
        ApiActions.horseSuccess(dispatch);
      } catch (err) {
        ApiActions.failure(dispatch, err);
        ApiActions.horseFailure(dispatch, err);
      }
    };
  },
  getPortfolioDataBySlug: (market, slug, cb) => {
    return async (dispatch) => {
      ApiActions.request(dispatch);
      try {
        const res = await profileStepperApi.getPortfolioDataBySlug(
          market,
          slug
        );
        dispatch({
          type: ProfileTypes.GET_PORTFOLIO_DATA,
          payload: res.data.data,
        });
        cb && cb(res.data);
        ApiActions.horseSuccess(dispatch);
        ApiActions.failure(dispatch);
      } catch (err) {
        ApiActions.horseFailure(dispatch, err);
        ApiActions.failure(dispatch, err);
      }
    };
  },
  deleteProfileImage: (portfolioId, type, cb) => {
    return async (dispatch) => {
      ApiActions.request(dispatch);
      try {
        const res = await profileStepperApi.deleteProfileImage(
          portfolioId,
          type
        );
        cb && cb(res.data);
        ApiActions.success(dispatch);
      } catch (err) {
        ApiActions.failure(dispatch, err);
      }
    };
  },
  getCoverImageData: (id) => {
    return async (dispatch) => {
      ApiActions.request(dispatch);
      try {
        const res = await profileStepperApi.getCoverProfileData(id);
        dispatch({
          type: ProfileTypes.GET_COVER_IMAGE_DATA,
          payload: res.data.data,
        });
        ApiActions.success(dispatch);
        ApiActions.horseSuccess(dispatch);
      } catch (err) {
        ApiActions.failure(dispatch, err);
        ApiActions.horseFailure(dispatch, err);
      }
    };
  },
  getIndividualProjectData: (id, callback) => {
    return async (dispatch) => {
      ApiActions.request(dispatch);
      try {
        const res = await profileStepperApi.getProjectIndividualData(id);
        if (!get(res, "data.error.error", true)) {
          callback && callback(res);
        }
        dispatch({
          type: ProfileTypes.GET_INDIVIDUAL_PROJECT_DATA,
          payload: res.data.data,
        });
        ApiActions.success(dispatch);
      } catch (err) {
        ApiActions.failure(dispatch, err);
      }
    };
  },
  deleteProject: (id, callback) => {
    return async (dispatch) => {
      ApiActions.request(dispatch);
      try {
        const res = await profileStepperApi.deleteProject(id);
        if (!get(res, "data.error.error", true)) {
          callback && callback();
        }
        ApiActions.success(dispatch);
      } catch (err) {
        ApiActions.failure(dispatch, err);
      }
    };
  },
  saveProductOrPublication: (data) => {
    return async (dispatch) => {
      ApiActions.request(dispatch);
      try {
        ApiActions.success(dispatch);
      } catch (err) {
        ApiActions.failure(dispatch, err);
      }
    };
  },
  fetchAllProjects: () => {
    return async (dispatch) => {
      ApiActions.request(dispatch);
      try {
        const res = await profileStepperApi.getProjectData();
        const {
          data: { data },
        } = res;
        dispatch({
          type: ProfileTypes.FETCH_ALL_PROJECTS,
          payload: data,
        });
        ApiActions.success(dispatch);
      } catch (err) {
        ApiActions.failure(dispatch, err);
      }
    };
  },

  getProjectsByPortfolio: (portfolioId) => {
    return async (dispatch) => {
      ApiActions.request(dispatch);
      try {
        const res = await profileStepperApi.getProjectsByPortfolio(portfolioId);
        const {
          data: { data },
        } = res;
        dispatch({
          type: ProfileTypes.PROJECTS_BY_PORTFOLIO,
          payload: data,
        });
        ApiActions.success(dispatch);
      } catch (err) {
        ApiActions.failure(dispatch, err);
      }
    };
  },
  getInstagramVerificationCode: (data, cb) => {
    return async (dispatch) => {
      ApiActions.request(dispatch);
      try {
        const res = await profileStepperApi.instagramVerificationCode(data);
        cb && cb(res.data);
        ApiActions.success(dispatch);
      } catch (err) {
        ApiActions.failure(dispatch, err);
      }
    };
  },
  getInstaFeeds: () => {
    return async (dispatch) => {
      ApiActions.request(dispatch);
      try {
        const res = await profileStepperApi.getInstagramFeeds();
        dispatch({
          typeOf: ProfileTypes.INSTAGRAM_FEEDS,
          type: ProfileTypes.INSTAGRAM_FEEDS,
          payload: res.data.data.feed,
        });
        ApiActions.success(dispatch);
      } catch (err) {
        ApiActions.failure(dispatch, err);
      }
    };
  },
  getPersonalSettingData: () => {
    return async (dispatch) => {
      ApiActions.request(dispatch);
      try {
        const res = await profileStepperApi.getUserData();
        dispatch({
          type: ProfileTypes.PERSONAL_SETTING_DATA,
          payload: res.data.data,
        });
        ApiActions.success(dispatch);
      } catch (err) {
        ApiActions.failure(dispatch, err);
      }
    };
  },
  getCompanyDataByUser: () => {
    return async (dispatch) => {
      ApiActions.request(dispatch);
      try {
        const res = await profileStepperApi.getCompaniesByUser();
        dispatch({
          type: ProfileTypes.COMPANY_DATA_BY_USER,
          payload: res.data.data,
        });
        ApiActions.success(dispatch);
      } catch (err) {
        ApiActions.failure(dispatch, err);
      }
    };
  },
  savePersonalSettingData: (data, callback) => {
    return async (dispatch) => {
      try {
        ApiActions.request(dispatch);
        const { imageData } = data;
        if (get(imageData, "profile.blobFile")) {
          const formData = new FormData();
          formData.append("files", imageData.profile.blobFile);
          const res = await Api.post(URI.UPLOAD, formData);
          data.profileUrl = get(res, "data.data[0]");
        }
        const resData = await profileStepperApi.updateUserData(data);
        callback(resData.data);
        ApiActions.success(dispatch);
      } catch (err) {
        ApiActions.failure(dispatch, err);
      }
    };
  },
  getCompanySettingData: () => {
    return async (dispatch) => {
      ApiActions.request(dispatch);
      try {
        const res = await profileStepperApi.getCompanyProfileData();
        dispatch({
          type: ProfileTypes.COMPANY_SETTING_DATA,
          payload: res.data.data,
        });
        ApiActions.success(dispatch);
      } catch (err) {
        ApiActions.failure(dispatch, err);
      }
    };
  },
  getTradesData: () => {
    return async (dispatch) => {
      ApiActions.request(dispatch);
      try {
        const res = await profileStepperApi.getTrades();
        dispatch({
          type: ProfileTypes.ALL_TRADES_LIST,
          payload: res.data.data,
        });
        ApiActions.success(dispatch);
      } catch (err) {
        ApiActions.failure(dispatch, err);
      }
    };
  },
  getAccoladesData: () => {
    return async (dispatch) => {
      ApiActions.request(dispatch);
      try {
        const res = await profileStepperApi.getAccolades();
        dispatch({
          type: ProfileTypes.GET_ACCOLADES_LIST,
          payload: res.data.data,
        });
        ApiActions.success(dispatch);
      } catch (err) {
        ApiActions.failure(dispatch, err);
      }
    };
  },
  getConfigData: () => {
    return async (dispatch) => {
      ApiActions.request(dispatch);
      try {
        const res = await profileStepperApi.getConfigData();
        dispatch({
          type: ProfileTypes.GET_CONFIG_DATA,
          payload: res.data.data,
        });
        ApiActions.success(dispatch);
      } catch (err) {
        ApiActions.failure(dispatch, err);
      }
    };
  },
  getVibesData: () => {
    return async (dispatch) => {
      ApiActions.request(dispatch);
      try {
        const res = await profileStepperApi.getVibes();
        dispatch({
          type: ProfileTypes.GET_VIBES_DATA,
          payload: res.data.data,
        });
        ApiActions.success(dispatch);
      } catch (err) {
        ApiActions.failure(dispatch, err);
      }
    };
  },
  getClientelData: () => {
    return async (dispatch) => {
      ApiActions.request(dispatch);
      try {
        const res = await profileStepperApi.getClientel();
        dispatch({
          type: ProfileTypes.GET_CLIENTEL_DATA,
          payload: res.data.data,
        });
        ApiActions.success(dispatch);
      } catch (err) {
        ApiActions.failure(dispatch, err);
      }
    };
  },
  getVenueData: () => {
    return async (dispatch) => {
      ApiActions.request(dispatch);
      try {
        const res = await profileStepperApi.getVenue();
        dispatch({
          type: ProfileTypes.GET_VENUE_DATA,
          payload: res.data.data,
        });
        ApiActions.success(dispatch);
      } catch (err) {
        ApiActions.failure(dispatch, err);
      }
    };
  },
  saveCompanySettingData: (data, callback) => {
    return async (dispatch) => {
      ApiActions.request(dispatch);
      try {
        const { imageData } = data;
        if (get(imageData, "logo.blobFile")) {
          const formData = new FormData();
          formData.append("files", imageData.logo.blobFile);
          const res = await Api.post(URI.UPLOAD, formData);
          data.companyLogo = get(res, "data.data[0]");
        }
        const resData = await profileStepperApi.updateCompanyInfo(
          localStorage.getItem("companyId"),
          data
        );
        if (!get(resData, "data.error.error", true)) {
          callback && callback();
        }
        ApiActions.success(dispatch);
      } catch (err) {
        ApiActions.failure(dispatch, err);
      }
    };
  },
  deleteCompanyAdmin: (data, callback) => {
    return async (dispatch) => {
      ApiActions.request(dispatch);
      try {
        const res = await profileStepperApi.removeCompanyAdmin(data);
        if (!get(res, "data.error.error", true)) {
          callback && callback();
        }
        ApiActions.success(dispatch);
      } catch (err) {
        ApiActions.failure(dispatch, err);
      }
    };
  },
  getCompanyData: () => {
    return async (dispatch) => {
      ApiActions.request(dispatch);
      try {
        const res = await profileStepperApi.getCompanyList();
        dispatch({
          type: ProfileTypes.GET_COMPANY_DATA,
          payload: res.data.data,
        });
        ApiActions.success(dispatch);
      } catch (err) {
        ApiActions.failure(dispatch, err);
      }
    };
  },
  getPortfolioListData: () => {
    return async (dispatch) => {
      ApiActions.request(dispatch);
      try {
        const res = await profileStepperApi.getPortfolioList();
        dispatch({
          type: ProfileTypes.GET_PORTFOLIO_LIST,
          payload: res.data.data,
        });
        ApiActions.success(dispatch);
      } catch (err) {
        ApiActions.failure(dispatch, err);
      }
    };
  },
  submitPotfolioPayment: (data, cb) => {
    return async (dispatch) => {
      ApiActions.request(dispatch);
      try {
        const res = await profileStepperApi.portfolioPayment(data);
        cb && cb(res.data);
        ApiActions.success(dispatch);
      } catch (err) {
        ApiActions.failure(dispatch, err);
      }
    };
  },
  printPortfolioInvoice: (data, cb) => {
    return async (dispatch) => {
      ApiActions.request(dispatch);
      try {
        const res = await profileStepperApi.printPortfolioInvoice(data);
        cb && cb(res.data);
        ApiActions.success(dispatch);
      } catch (err) {
        ApiActions.failure(dispatch, err);
      }
    };
  },
  saveConnectData: (data, cb) => {
    return async (dispatch) => {
      ApiActions.request(dispatch);
      try {
        const res = await profileStepperApi.saveConnectData(data);
        cb && cb(res.data);
        ApiActions.success(dispatch);
      } catch (err) {
        ApiActions.failure(dispatch, err);
      }
    };
  },
  savePaypalTranscationData: (data, cb) => {
    return async (dispatch) => {
      ApiActions.request(dispatch);
      try {
        const res = await profileStepperApi.savePaypalTransactions(data);
        cb && cb(res.data);
        ApiActions.success(dispatch);
      } catch (err) {
        ApiActions.failure(dispatch, err);
      }
    };
  },
  sendInviteMails: (data, cb) => {
    return async (dispatch) => {
      ApiActions.request(dispatch);
      try {
        const res = await profileStepperApi.sendInviteMails(data);
        cb && cb(res.data);
        ApiActions.success(dispatch);
      } catch (err) {
        ApiActions.failure(dispatch, err);
      }
    };
  },
  getProductsResponseData: (data) => {
    return async (dispatch) => {
      ApiActions.request(dispatch);
      try {
        dispatch({
          type: ProfileTypes.GET_PRODUCT_PUBLICATION_DATA,
          payload: data,
        });
        ApiActions.success(dispatch);
      } catch (err) {
        ApiActions.failure(dispatch, err);
      }
    };
  },
  actAsUser: (data, cb) => {
    return async (dispatch) => {
      ApiActions.request(dispatch);
      try {
        const res = await profileStepperApi.actAsUser(data);
        cb && cb(res.data.data);
        // dispatch({
        //   type: ProfileTypes.GET_PRODUCT_PUBLICATION_DATA,
        //   payload: data,
        // });
        ApiActions.success(dispatch);
      } catch (err) {
        ApiActions.failure(dispatch, err);
      }
    };
  },
};
