import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  flex: {
    display: "flex"
  }
});

const Row = ({
  className,
  style,
  justify,
  align,
  onClick,
  direction = "row",
  children
}) => {
  const classes = useStyles();
  return (
    <div
      className={`${classes.flex} ${className}`}
      onClick={onClick}
      style={{
        flexDirection: direction,
        justifyContent: justify,
        alignItems: align,
        ...style
      }}
    >
      {children}
    </div>
  );
};

export default Row;
