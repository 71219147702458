import { LookUpDataApi } from "../api";
import { LookUpTypes } from "../actionTypes";
import { ApiActions } from ".";

export default {
  getLookUpData: () => {
    return async (dispatch) => {
      ApiActions.request(dispatch);
      try {
        const res = await LookUpDataApi.lookUp();
        dispatch({
          type: LookUpTypes.LOOK_UP_DATA,
          payload: res.data.data,
        });
        ApiActions.success(dispatch);
      } catch (err) {
        ApiActions.failure(dispatch, err);
      }
    };
  },
};
