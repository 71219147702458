import _ from "lodash";
import React, { Component } from "react";
import { Redirect, Route } from "react-router-dom";

class PrivateRoute extends Component {
  render() {
    // const isLoggedIn = this.props.login.loggedIn;
    const isLoggedIn = localStorage.getItem("userType");
    const { component: Component, ...rest } = this.props;
    return (
      <Route
        {...rest}
        render={(props) => {
          if (isLoggedIn) {
            //if logged in and path is '/' or '/joinus'
            if (
              props.location.pathname === "/" ||
              props.location.pathname === "/joinus"
            ) {
              const prevPath = _.get(
                props,
                "location.state.from.pathname",
                "/tw"
              );
              return (
                <Redirect
                  to={{
                    pathname: prevPath,
                    state: { from: props.location },
                  }}
                />
              );
            }
            //if loggedin and path is not '/' or 'joinus' redirect to component
            return <Component {...props} />;
          } else {
            //If not loggedin redirect to initial screen and route is '/' or 'joinus' redore

            if (
              props.location.pathname === "/" ||
              props.location.pathname === "/joinus"
            ) {
              return <Component {...props} />;
            } else {
              //If not loggedin redirect to initial screen
              return (
                <Redirect
                  to={{
                    pathname: "/",
                    state: { from: props.location },
                  }}
                />
              );
            }
          }
        }}
      />
    );
  }
}

export default PrivateRoute;
