import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { get, map } from "lodash";

import headerStyles from "./styles/landing_headerStyle";
import Grid from "@material-ui/core/Grid";
import Row from "../common/ui_kit/row";
import Text from "../common/ui_kit/text";
import CustomLink from "../navigations/custom_links";
import strings from "../../utilities/strings";
import LoginModal from "../../pages/login/login";
import Join_Us from "../../pages/auth/joinus/join_us";
import RecoverCredentials from "../../pages/recover_password/recover_credentials";
import NewPortfolioModal from "../build_portfolio/new_port_popup";
import CustomModal from "../inputs/custom_modal";

import { color } from "../../utilities/themes";
import { LoginActions } from "../../redux/actions";
import { ProfileActions, SettingsActions } from "../../redux/actions";
import {
  FRANKLIN_QA,
  FRANKLIN_STAGE,
  LLAMA_DEV_URL,
  LLAMA_QA_URL,
  LLAMA_STAGE_URL,
  FRANKLIN_DEFAULT_QA,
  FRANKLIN_DEFAULT_STAGE,
  LLAMA_PROD_URL,
  FRANKLIN_PROD,
  FRANKLIN_DEFAULT_PROD,
} from "../../constants";
import { ClickAwayListener } from "@material-ui/core";

function Header(props) {
  const [modals, setModals] = useState({
    displayLogin: localStorage.getItem("frpage") === "login" ? true : false,
    displayJoinUs: localStorage.getItem("frpage") === "joinus" ? true : false,
    openDupeModal: false,
    showRecoverCredentials: false,
  });
  const [state, setState] = useState({
    signUpError: false,
    pwdSuccess: false,
    errorMessage: "",
    firstUser: true,
    displayedMarketsData: [],
  });
  const { classes } = props;
  const [isToggle, setIsToggle] = React.useState(false);

  const toggle = React.useCallback(() => setIsToggle(!isToggle));

  const {
    errorMessage,
    emailId,
    userName,
    selectedCompanyId,
    selectedActiveType,
    firstUser,
    displayedMarketsData,
    publicPortfoliosLength,
    portfolioLength,
  } = state;

  const { showRecoverCredentials, openDupeModal, displayLogin, displayJoinUs } =
    modals;

  const openLogin = () => {
    setModals({
      ...modals,
      displayLogin: true,
    });
  };

  const CompanyPortfolioData = useSelector(
    (resData) => resData.Settings.CompanyPortfolioData
  );

  const redirectToNewPortfolio = () => {
    if (isLoggedIn) {
      if (localStorage.getItem("pathName") !== "/portfolioView") {
        history.push("/NewPortfolio");
      } else {
        if (!localStorage.getItem("companyName")) {
          return;
        }
        setModals({
          ...modals,
          openDupeModal: true,
        });
      }
    } else {
      openLogin();
    }
  };

  const pageValue = localStorage.getItem("frpage");
  const companyId = localStorage.getItem("companyId");
  const activeType = localStorage.getItem("active");

  const clearError = () => {
    setState({
      ...state,
      signUpError: false,
      errorMessage: "",
      emailId: "",
      displayLogin: false,
    });
  };

  const closeJoinUs = () => {
    setModals({ ...modals, displayJoinUs: false });
    localStorage.removeItem("frpage");
    localStorage.removeItem("Review");
    history.push("/");
  };

  const redirectToPortfolio = (id, name) => () => {
    localStorage.setItem("portfolioId", id);
    localStorage.setItem("portfolioName", name);
    localStorage.removeItem("isPortfolioPublic");
    history.push("/portfolioView");
  };

  const toggleModal = (closeModal, openModal) => {
    localStorage.removeItem("frpage");
    if (openModal) {
      setModals({
        ...modals,
        [closeModal]: false,
        [openModal]: true,
      });
      return;
    } else {
      setModals({
        ...modals,
        [closeModal]: false,
      });
      history.push("/");
    }
  };

  const addDefaultSrc = (ev) => {
    ev.target.src = "/assets/images/Gray Star.svg";
  };

  const toggleLoginModal = () => {
    setModals({
      ...modals,
      displayLogin: !displayLogin,
      displayJoinUs: false,
    });
    localStorage.removeItem("frpage");
    if (displayLogin) {
      history.push("/");
      return;
    }
    history.push("/login");
  };

  const dispatch = useDispatch();
  const history = useHistory();
  const logOut = () => {
    dispatch(LoginActions.logOut());
    let host = get(window, "location.host", "");
    if (host) {
      if (host.includes("localhost")) {
        window.location.href = FRANKLIN_DEFAULT_QA;
      } else if (host.includes("-dev-")) {
        window.location.href = FRANKLIN_DEFAULT_QA;
      } else if (host.includes("-qa-")) {
        window.location.href = FRANKLIN_DEFAULT_QA;
      } else if (host.includes("-stage-")) {
        window.location.href = FRANKLIN_DEFAULT_STAGE;
      } else if (host.includes("franklinreport")) {
        window.location.href = FRANKLIN_DEFAULT_PROD;
      }
    }
    toggle();
    emptyCache();
  };
  const logOutInposonate = () => {
    localStorage.removeItem("inpersonate_token");
    if (history.location.pathname === "/settings/company") {
      window.location.reload();
      toggle();
      return;
    }
    history.push("/settings/company");
    toggle();
  };

  const emptyCache = () => {
    if ("caches" in window) {
      caches.keys().then((names) => {
        // Delete all the cache files
        names.forEach((name) => {
          caches.delete(name);
        });
      });

      // Makes sure the page reloads. Changes are only visible after you refresh.
      // window.location.reload(true);
    }
  };

  const handleMenuClick = (selectedItem) => () => {
    let host = get(window, "location.host", "");
    if (selectedItem === "Write a review") {
      localStorage.setItem("Review", true);
    } else {
      localStorage.setItem("Review", false);
    }

    let frLoginHost = "";
    let llamaLoginHost = "";
    if (host) {
      if (host.includes("localhost")) {
        frLoginHost = FRANKLIN_QA;
        llamaLoginHost = "localhost:3005";
      } else if (host.includes("-dev-")) {
        frLoginHost = FRANKLIN_QA;
        llamaLoginHost = LLAMA_DEV_URL;
      } else if (host.includes("-qa-")) {
        frLoginHost = FRANKLIN_QA;
        llamaLoginHost = LLAMA_QA_URL;
      } else if (host.includes("-stage-")) {
        frLoginHost = FRANKLIN_STAGE;
        llamaLoginHost = LLAMA_STAGE_URL;
      } else if (host.includes("franklinreport")) {
        frLoginHost = FRANKLIN_PROD;
        llamaLoginHost = LLAMA_PROD_URL;
      }
    }
    switch (selectedItem) {
      case "FR logo":
        return (window.location.href = !isLoggedIn
          ? `${frLoginHost}/default.aspx`
          : `${frLoginHost}/NavigateUsers.aspx?Token=${isLoggedIn}&URL=default`);
      case "Hire":
        return (window.location.href = !isLoggedIn
          ? `${frLoginHost}/Categories/DeepCategoryMkt.aspx?m=NYC`
          : `${frLoginHost}/NavigateUsers.aspx?Token=${isLoggedIn}&URL=DeepCategoryMkt`);
      case "Portfolios":
        return (window.location.href = !isLoggedIn
          ? `${frLoginHost}/MktPortSC.aspx`
          : `${frLoginHost}/NavigateUsers.aspx?Token=${isLoggedIn}&URL=MktPortSC`);
      case "Write a review":
        return (window.location.href = !isLoggedIn
          ? `${llamaLoginHost}/frlogin`
          : `${frLoginHost}/NavigateUsers.aspx?Token=${isLoggedIn}&URL=MemberReview`);
      case "Submit Your company":
        return (window.location.href = !isLoggedIn
          ? `${frLoginHost}/ServiceProvider.aspx`
          : `${frLoginHost}/NavigateUsers.aspx?Token=${isLoggedIn}&URL=ServiceProvider`);
      case "New Company":
        return (window.location.href = !isLoggedIn
          ? `${frLoginHost}/vendorjoin.aspx`
          : `${frLoginHost}/NavigateUsers.aspx?Token=${isLoggedIn}&URL=vendorjoin`);
      case "search":
        return (window.location.href = `${frLoginHost}/MktPortSC.aspx`);
      default:
        return "";
    }
  };

  const redirectTo = (type) => () => {
    if (type == "personal") {
      setState({
        ...state,
        userName: `${get(personalData, "firstName", "")}`,
        companyLogo: get(personalData, "profileUrl"),
      });
      localStorage.setItem("active", "personal");
      localStorage.setItem(
        "activeCompanyLogo",
        get(personalData, "profileUrl", "/assets/images/Blank Eagle Ribbon.svg")
      );
      localStorage.setItem(
        "companyName",
        `${get(personalData, "firstName", "")}`
      );
    }
    toggle();
    switch (type) {
      case "personal":
        return history.push("/settings/personal");
      case "company":
        return history.push("/settings/company");
      case "portfolios":
        return history.push("/settings/portfolios");
      case "locations":
        return history.push("/settings/locations");
      case "newPortfolio":
        return history.push("/NewPortfolio");
      case "history":
        return history.push("/settings/history");
      default:
        break;
    }
  };

  const settings = () => {
    history.push("/settings");
    toggle();
  };

  const toggleJoinUsModal = () => {
    setModals({
      ...modals,
      displayJoinUs: true,
      displayLogin: false,
      signUpError: false,
      errorMessage: "",
      emailId: null,
    });
    localStorage.removeItem("frpage");
    history.push("/joinus");
  };

  const addDefaultSrcEagle = (ev) => {
    ev.target.src = "/assets/images/Blank Eagle Ribbon.svg";
  };

  const signUpError = (errorMessageVal, emailIdVal, openLoginVal) => {
    setState({
      ...state,
      signUpError: true,
      errorMessage: errorMessageVal,
      emailId: emailIdVal,
      displayJoinUs: openLoginVal ? false : state.displayJoinUs,
      displayLogin: openLoginVal ? true : state.displayLogin,
    });
  };

  const fetchData = () => {
    if (isLoggedIn) {
      dispatch(ProfileActions.getPersonalSettingData());
      dispatch(SettingsActions.getCompanyDataByUser());
    }
  };

  useEffect(() => {
    fetchData();
  }, [displayLogin, displayJoinUs]);

  useEffect(() => {
    setState({
      ...state,
      companyLogo: localStorage.getItem("activeCompanyLogo"),
      userName: localStorage.getItem("companyName"),
    });
  }, [localStorage.getItem("activeCompanyLogo")]);

  useEffect(() => {
    setModals({
      ...modals,
      displayLogin: localStorage.getItem("frpage") === "login" ? true : false,
      displayJoinUs: localStorage.getItem("frpage") === "joinus" ? true : false,
    });
  }, [pageValue]);

  useEffect(() => {
    setState({
      ...state,
      selectedCompanyId: localStorage.getItem("companyId"),
      selectedActiveType: localStorage.getItem("active"),
      companyLogo: localStorage.getItem("activeCompanyLogo"),
      userName: localStorage.getItem("companyName"),
    });
  }, [companyId, activeType]);

  const personalData = useSelector(
    (resData) => resData.Profile.personalSettingData
  );

  const CompanyListByUser = useSelector(
    (resData) => resData.Settings.CompanyListByUser
  );

  const lookUpData = useSelector((resData) => resData.LookUp.LookUpData);

  const portfolioData = useSelector((resData) => resData.Profile.portfolioData);

  useEffect(() => {
    if (personalData && firstUser) {
      setState({
        ...state,
        userName: `${get(personalData, "firstName", "")}`,
        companyLogo: get(personalData, "profileUrl"),
        selectedCompanyId: localStorage.getItem("companyId"),
        selectedActiveType: localStorage.getItem("active"),
        firstUser: false,
      });
      localStorage.setItem(
        "activeCompanyLogo",
        get(personalData, "profileUrl", "/assets/images/Blank Eagle Ribbon.svg")
      );
      localStorage.setItem("profile", JSON.stringify(personalData));
    } else {
      setState({
        ...state,
        userName: `${get(personalData, "firstName", "")}`,
        companyLogo: get(personalData, "profileUrl"),
      });
    }
  }, [personalData]);

  useEffect(() => {
    if (portfolioData) {
      setState({
        ...state,
        displayedMarketsData: get(portfolioData, "displayedAt", []),
      });
    }
  }, [portfolioData]);

  useEffect(() => {
    if (CompanyPortfolioData && CompanyPortfolioData.portfolios) {
      let publicPortfolios =
        CompanyPortfolioData &&
        CompanyPortfolioData.portfolios.filter(
          (each) => each.isVisibleToPublic
        );
      setState({
        ...state,
        portfolioLength: get(CompanyPortfolioData, "portfolios.length", 0),
        publicPortfoliosLength: get(publicPortfolios, "length", 0),
      });
    }
  }, [CompanyPortfolioData]);

  const isLoggedIn = localStorage.getItem("userType");
  const inpersonate_token = localStorage.getItem("inpersonate_token");

  const setDefaultCompany = (company) => () => {
    localStorage.setItem("companyId", get(company, "_id", ""));
    localStorage.setItem("companyName", get(company, "name", ""));
    localStorage.setItem("active", "company");
    localStorage.setItem(
      "activeCompanyLogo",
      get(company, "companyLogo")
        ? get(company, "companyLogo")
        : `${get(lookUpData, "configData.eaglesPath")}/${get(
            company,
            "FRCompanyId"
          )}.jpg`
    );
    setState({
      ...state,
      userName: get(company, "name", ""),
      selectedCompanyId: localStorage.getItem("companyId"),
      selectedActiveType: localStorage.getItem("active"),
      companyLogo: get(
        company,
        "companyLogo",
        "/assets/images/Blank Eagle Ribbon.svg"
      ),
    });
    history.push("/settings/company");
    toggle();
  };

  const closeDupeModal = () => {
    setModals({
      ...modals,
      openDupeModal: false,
    });
  };

  const displayedMarketRibbon = (marketsData) => {
    let greenMarkets = marketsData.filter((each) => {
      return each.madePublic;
    });
    let blueMarkets = marketsData.filter((each) => {
      return !each.madePublic;
    });
    if (get(greenMarkets, "length") === 0 && get(blueMarkets, "length") > 1) {
      return (
        <img
          // src="/assets/icons/Pay and Publish.svg"
          alt=""
          className={`${classes.payPublish} ${classes.ribbonAlign}`}
        />
        // <div className={`${classes.payPublish} ${classes.ribbonAlign}`}></div>
      );
    } else if (
      get(greenMarkets, "length") > 0 &&
      get(blueMarkets, "length") > 1
    ) {
      return (
        <img
          // src="/assets/icons/Pay and Publish OTHER.svg"
          alt=""
          className={`${classes.another} ${classes.ribbonAlign}`}
        />
        // <div className={`${classes.another} ${classes.ribbonAlign}`}></div>
      );
    } else if (get(blueMarkets, "length") === 1) {
      switch (blueMarkets[0].region) {
        case "New York City":
          return (
            <img
              // src="/assets/icons/Pay and Publish NYC.svg"
              alt=""
              className={`${classes.nycPublish} ${classes.ribbonAlign}`}
            />
            // <div
            //   className={`${classes.nycPublish} ${classes.ribbonAlign}`}
            // ></div>
          );
        case "SE Florida":
          return (
            <img
              // src="/assets/icons/Pay and Publish SOFLO.svg"
              alt=""
              className={`${classes.soflo} ${classes.ribbonAlign}`}
            />
            // <div className={`${classes.soflo} ${classes.ribbonAlign}`}></div>
          );
        case "Los Angeles":
          return (
            <img
              // src="/assets/icons/Pay and Publish LA.svg"
              alt=""
              className={`${classes.losAngels} ${classes.ribbonAlign}`}
            />
            // <div
            //   className={`${classes.losAngels} ${classes.ribbonAlign}`}
            // ></div>
          );
        case "CT/Westchester":
          return (
            <img
              // src="/assets/icons/Pay and Publish CTWEST.svg"
              alt=""
              className={`${classes.ctWest} ${classes.ribbonAlign}`}
            />
            // <div className={`${classes.ctWest} ${classes.ribbonAlign}`}></div>
          );
        case "Chicago":
          return (
            <img
              // src="/assets/icons/Pay and Publish CHICAGO.svg"
              alt=""
              className={`${classes.chicago} ${classes.ribbonAlign}`}
            />
            // <div className={`${classes.chicago} ${classes.ribbonAlign}`}></div>
          );
        case "Santa Barbara":
          return (
            <img
              // src="/assets/icons/Pay and Publish CHICAGO.svg"
              alt=""
              className={`${classes.santaBarbarar} ${classes.ribbonAlign}`}
            />
            // <div className={`${classes.chicago} ${classes.ribbonAlign}`}></div>
          );
        default:
          return (
            <img
              // src="/assets/icons/Pay and Publish OTHER.svg"
              alt=""
              className={`${classes.another} ${classes.ribbonAlign}`}
            />
            // <div className={`${classes.another} ${classes.ribbonAlign}`}></div>
          );
      }
    }
    // return <img src="/assets/icons/Pay and Publish OTHER.svg" alt="" />;
  };

  return (
    <>
      <Row
        className={
          window.location.pathname === "/"
            ? classes.header_border
            : classes.coloredBorder
        }
      >
        <Row>
          {/* <CustomLink> */}
          {/* <label className={classes.stamp}> */}
          {/* <img
                src="/assets/icons/fr_stamp.svg"
                onClick={handleMenuClick("FR logo")}
                alt=""
              /> */}
          <img
            className={`${classes.fr_stamp}`}
            onClick={handleMenuClick("FR logo")}
          />
          {/* </label> */}
          {/* </CustomLink> */}
          <Row className={classes.marginLeftAlign}>
            <Row className={classes.alignCenter}>
              <CustomLink>
                <label
                  className={classes.link_text}
                  onClick={handleMenuClick("Hire")}
                >
                  {strings.header.titles.hire}
                </label>
              </CustomLink>
            </Row>
            <Row className={classes.alignCenter}>
              <CustomLink>
                <label
                  className={classes.link_text}
                  onClick={handleMenuClick("Portfolios")}
                >
                  {strings.header.titles.view_portfolios}
                </label>
              </CustomLink>
            </Row>
            <Row className={classes.alignCenter}>
              <CustomLink>
                <label
                  className={classes.link_text}
                  onClick={handleMenuClick("Write a review")}
                >
                  {strings.header.titles.write_review}
                </label>
              </CustomLink>
            </Row>
            <span className={classes.divider}></span>
            <Row className={`${classes.alignCenter}`}>
              <CustomLink>
                <label
                  className={classes.link_text}
                  onClick={handleMenuClick("Submit Your company")}
                >
                  {strings.header.titles.submit_company}
                </label>
              </CustomLink>
            </Row>
            <Row className={`${classes.alignCenter} ${classes.paddingRight0}`}>
              <CustomLink>
                <label
                  className={classes.link_text}
                  onClick={redirectToNewPortfolio}
                >
                  {strings.header.titles.add_portfolio}
                </label>
              </CustomLink>
            </Row>

            {get(displayedMarketsData, "length") > 0 &&
              localStorage.getItem("isPublicMode") !== "true" && (
                <Row className={classes.alignCenterIcon}>
                  {displayedMarketRibbon(displayedMarketsData)}
                </Row>
              )}
          </Row>
        </Row>
        <Row className={classes.user_blk}>
          <img
            src="/assets/images/Search_Icon.png"
            className={classes.search}
            onClick={handleMenuClick("search")}
            alt=""
          />
          {isLoggedIn && (
            <img
              src={
                get(
                  state,
                  "companyLogo",
                  "/assets/images/Blank Eagle Ribbon.svg"
                ) || "/assets/images/Blank Eagle Ribbon.svg"
              }
              className={classes.profieImage}
              alt=""
              onError={addDefaultSrcEagle}
              onClick={toggle}
            />
          )}
          {!isLoggedIn ? (
            <Row>
              <Text
                size={13}
                family="gillsans_sb"
                color={color.primary_palette.black}
                className={classes.loginBtns}
                onClick={toggleJoinUsModal}
              >
                {strings.header.titles.joinus}
              </Text>{" "}
              <Text
                size={13}
                family="gillsans_sb"
                color={color.primary_palette.black}
                className={classes.centerLine}
              >
                |{" "}
              </Text>{" "}
              <div
                className={`${classes.loginBtns} ${classes.signInStyles}`}
                ref={props.loginRef}
                onClick={toggleLoginModal}
              >
                {strings.header.titles.signin}
              </div>
            </Row>
          ) : (
            <Row className={classes.pos_relative}>
              <Text
                size={13}
                family="gillsans_sb"
                color={color.primary_palette.black}
                className={classes.userName}
                onClick={toggle}
              >
                {userName
                  ? `HELLO ${userName} ${
                      inpersonate_token ? "(Impersonate)" : ""
                    }`
                  : strings.header.titles.userName}
              </Text>
              <img
                src="/assets/images/arrow_down.png"
                className={classes.arrowIcon}
                onClick={toggle}
                alt=""
              />
              {isToggle && (
                <ClickAwayListener onClickAway={toggle}>
                  <div className={classes.settings}>
                    <ul>
                      <Row
                        className={
                          "personal" === selectedActiveType
                            ? classes.flexCenter
                            : classes.flexCenterInactive
                        }
                      >
                        <Text
                          size={15}
                          color={color.primary_palette.black}
                          family="avenir_roman"
                          className={`${classes.txtRight} ${
                            "personal" !== selectedActiveType &&
                            classes.hoverTxt
                          }`}
                          onClick={redirectTo("personal")}
                        >
                          {get(personalData, "firstName", "")}{" "}
                          {get(personalData, "lastName", "")}
                        </Text>
                        <img
                          alt=""
                          src={
                            get(
                              personalData,
                              "profileUrl",
                              "/assets/images/Circle Plus 1.svg"
                            ) || "/assets/images/Circle Plus 1.svg"
                          }
                          className={classes.nav_logo}
                        />
                      </Row>
                      {CompanyListByUser &&
                        map(CompanyListByUser, (company) => {
                          return (
                            <Row
                              key={company._id}
                              className={
                                company._id === selectedCompanyId &&
                                "company" == selectedActiveType
                                  ? classes.flexCenter
                                  : classes.flexCenterInactive
                              }
                              onClick={setDefaultCompany(company)}
                            >
                              <Text
                                size={15}
                                color={color.primary_palette.black}
                                family="avenir_sb"
                                className={`${classes.txtRight} ${
                                  company._id ===
                                    localStorage.getItem("companyId") &&
                                  "company" == selectedActiveType
                                    ? classes.textInitial
                                    : classes.hoverTxt
                                }`}
                              >
                                {get(company, "name", "")} <br />
                              </Text>
                              <img
                                alt=""
                                onError={addDefaultSrc}
                                src={get(
                                  company,
                                  "companyLogo",
                                  `${get(
                                    lookUpData,
                                    "configData.eaglesPath"
                                  )}/${get(company, "FRCompanyId")}.jpg`
                                )}
                                className={classes.nav_logo}
                              />
                            </Row>
                          );
                        })}
                      <Text
                        size={15}
                        family="avenir_sb"
                        color={color.primary_palette.franklin_purple}
                        className={`${classes.txtRight} ${classes.spacing_plus} ${classes.hoverTxt}`}
                        onClick={handleMenuClick("New Company")}
                      >
                        {strings.settings.titles.plus_company}
                      </Text>

                      {localStorage.getItem("active") === "company" && (
                        <div className={classes.border_top_gray}>
                          <p onClick={redirectTo("portfolios")}>
                            <Text
                              size={15}
                              color={color.primary_palette.franklin_purple}
                              family="avenir_black_r"
                              className={`${classes.txtRight} ${classes.spacing_plus_company} ${classes.text_upper} ${classes.hoverTxt}`}
                            >
                              {strings.settings.titles.our_portfolio_settings}
                            </Text>
                          </p>
                          {/* sub section */}
                          <div className={classes.portfoliosBlock}>
                            {portfolioLength > 0 && (
                              <>
                                <Text
                                  size={12}
                                  color={
                                    color.secondary_palette.grays.medium_gray
                                  }
                                  family="gillsans_r"
                                >
                                  Number of Public Portfolios:{" "}
                                  {publicPortfoliosLength}/{portfolioLength}
                                </Text>
                                {CompanyPortfolioData &&
                                  CompanyPortfolioData.portfolios &&
                                  map(
                                    CompanyPortfolioData.portfolios,
                                    (portfolios) => {
                                      return (
                                        <Text
                                          size={12}
                                          className={classes.portfolioNameHover}
                                          color={
                                            color.secondary_palette.grays
                                              .medium_gray
                                          }
                                          family="gillsans_r"
                                          onClick={redirectToPortfolio(
                                            get(portfolios, "_id", ""),
                                            get(portfolios, "name", "")
                                          )}
                                        >
                                          View/Edit Portfolio: {portfolios.name}
                                        </Text>
                                      );
                                    }
                                  )}
                              </>
                            )}
                          </div>
                          <p onClick={redirectTo("newPortfolio")}>
                            <Text
                              size={15}
                              color={color.primary_palette.franklin_purple}
                              family="avenir_sb"
                              className={`${classes.txtRight} ${classes.spacing_plus_company} ${classes.text_caps}  ${classes.hoverTxt}`}
                            >
                              {strings.settings.titles.new_portfolios}
                            </Text>
                          </p>
                          <p onClick={redirectTo("history")}>
                            <Text
                              size={15}
                              color={color.primary_palette.franklin_purple}
                              family="avenir_sb"
                              className={`${classes.txtRight} ${classes.spacing_plus_company} ${classes.text_caps}  ${classes.hoverTxt}`}
                            >
                              {strings.settings.titles.purchase_hist}
                            </Text>
                          </p>
                          {/* sub section */}
                          <p
                            onClick={redirectTo("locations")}
                            className={classes.border_top_gray}
                          >
                            <Text
                              size={15}
                              color={color.primary_palette.franklin_purple}
                              family="avenir_black_r"
                              className={`${classes.txtRight} ${classes.spacing_plus_company} ${classes.text_upper}  ${classes.hoverTxt}`}
                            >
                              {strings.settings.titles.office_locations}
                            </Text>
                          </p>
                          <p onClick={redirectTo("company")}>
                            <Text
                              size={15}
                              color={color.primary_palette.franklin_purple}
                              family="avenir_black_r"
                              className={`${classes.txtRight} ${classes.spacing_plus_company} ${classes.text_upper}  ${classes.hoverTxt}`}
                            >
                              {strings.settings.titles.company_basics}
                            </Text>
                          </p>
                        </div>
                      )}
                      {localStorage.getItem("active") === "personal" && (
                        <li onClick={settings}>
                          <Text
                            size={15}
                            color={color.primary_palette.franklin_purple}
                            family="avenir_sb"
                            className={`${classes.text_upper}  ${classes.hoverTxt}`}
                          >
                            {strings.settings.titles.personal_settings}
                          </Text>
                        </li>
                      )}
                      {inpersonate_token ? (
                        <li>
                          <>
                            <Text
                              size={15}
                              color={color.primary_palette.black}
                              family="avenir_sb"
                              className={`${classes.logoutTxt} ${classes.hoverPurpleTxt}`}
                              onClick={logOutInposonate}
                            >
                              {strings.settings.titles.log_out} (Impersonate)
                              <img
                                src="/assets/icons/Purple Log Out.svg"
                                className={classes.logout_align}
                                alt=""
                              />
                            </Text>
                          </>
                        </li>
                      ) : (
                        <li>
                          <>
                            <Text
                              size={15}
                              color={color.primary_palette.black}
                              family="avenir_sb"
                              className={`${classes.logoutTxt} ${classes.hoverPurpleTxt}`}
                              onClick={logOut}
                            >
                              {strings.settings.titles.log_out}
                              <img
                                src="/assets/icons/Purple Log Out.svg"
                                className={classes.logout_align}
                                alt=""
                              />
                            </Text>
                          </>
                        </li>
                      )}
                    </ul>
                  </div>
                </ClickAwayListener>
              )}
            </Row>
          )}
        </Row>
      </Row>
      {displayLogin && (
        <Grid container item justify="center" alignItems="center">
          <LoginModal
            closeModal={toggleLoginModal}
            openJoinusModal={toggleJoinUsModal}
            signUpError={state.signUpError}
            errorMessage={errorMessage}
            emailId={emailId}
            toggleModal={toggleModal}
            clearError={clearError}
          />
        </Grid>
      )}
      {displayJoinUs && (
        <Grid container item justify="center" alignItems="center">
          <Join_Us
            closeModalJoin={toggleJoinUsModal}
            openLoginModal={toggleLoginModal}
            signUpError={signUpError}
            displayJoinUs={displayJoinUs}
            closeJoinUs={closeJoinUs}
          />
        </Grid>
      )}
      <CustomModal open={openDupeModal} onClose={closeDupeModal}>
        <NewPortfolioModal onClose={closeDupeModal} />
      </CustomModal>

      <Grid container item justify="center" alignItems="center">
        <RecoverCredentials
          showRecoverCredentials={showRecoverCredentials}
          toggleModal={toggleModal}
        />
      </Grid>
    </>
  );
}

export default headerStyles(Header);
