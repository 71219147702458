import React from "react";
import { get } from "lodash";

import GridmsStyles from "./styles/gridmsStyles";
import Row from "../common/ui_kit/row";

function GridTwoMs(props) {
  const { classes, imageData } = props;
  return (
    <>
      <div className={classes.publicviewProject}>
        <Row className={classes.grid_main_spacing}>
          <div className={classes.imgDiv}>
            <img
              className={classes.grid_2ms_1}
              alt=""
              src={
                get(
                  imageData[0],
                  "alterImageUri.croppedImage",
                  "/assets/images/green.PNG"
                ) || "/assets/images/green.PNG"
              }
            />
            {!get(imageData[0], "alterImageUri.croppedImage") && (
              <div className={classes.numberAlign}>1</div>
            )}
          </div>
          <div className={classes.imgDiv}>
            <img
              className={classes.grid_2ms_2}
              alt=""
              src={
                get(
                  imageData[1],
                  "alterImageUri.croppedImage",
                  "/assets/images/green.PNG"
                ) || "/assets/images/green.PNG"
              }
            />
            {!get(imageData[1], "alterImageUri.croppedImage") && (
              <div className={classes.numberAlign}>2</div>
            )}
          </div>
        </Row>
      </div>
    </>
  );
}

export default GridmsStyles(GridTwoMs);
