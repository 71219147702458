import React from "react";
import { Input, InputAdornment } from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { color, font_sizes } from "../../utilities/themes";
import { withStyles } from "@material-ui/core/styles";
import Close_Icon from "../../components/data_display/icons/Close";
/**
 * TextField component normal input without deriving it from material-ui
 * @param {*} props
 */
function CustomPlainInputField(props) {
  /**
   * Applying the styles to the input component
   * @param {*} styleInputProps
   */
  function styleInput(styleInputProps) {
    return (
      <Input
      autoComplete="nofill"
        endAdornment={
          // props.defaultValue && (
          <InputAdornment position="end">
            <Close_Icon
              onMouseUp={styleInputProps.onClear}
              style={{
                fontSize: "12px",
                cursor: "pointer",
                zIndex: "1000",
                marginRight: "-10px",
              }}
            />
          </InputAdornment>
          // )
        }
        {...styleInputProps}
      />
    );
  }
  /**
   * Style customization for inputs
   */
  const CustomPlainInputFieldStyles = withStyles({
    root: {
      border: props.error
        ? `1px solid ${color.primary_palette.christmas_red}`
        : "",
      height: "1.5625em",
      width: props.width ? props.width : "25.5625em",
      padding: props.padding ? props.padding : "0 0.5em",
      fontSize: props.fontSize ? props.fontSize : font_sizes.font_14,
      "&:after": {
        borderBottom: "transparent !important",
      },
      "&:before": {
        borderBottom: "transparent !important",
      },
      "&:hover": {
        border: "1px solid #C6DDFF !important",
        "&:not(.Mui-disabled)": {
          "&:after": {
            borderBottom: "transparent !important",
          },
          "&:before": {
            borderBottom: "transparent !important",
          },
        },
      },
      "&:active": {
        border: "1px solid #5E94E0 !important",
        "&:not(.Mui-disabled)": {
          "&:after": {
            borderBottom: "transparent !important",
          },
          "&:before": {
            borderBottom: "transparent !important",
          },
        },
      },
      "&:focus": {
        border: "1px solid #5E94E0 !important",
        "&:not(.Mui-disabled)": {
          "&:after": {
            borderBottom: "transparent !important",
          },
          "&:before": {
            borderBottom: "transparent !important",
          },
        },
      },
      "&:focus-within": {
        border: "1px solid #5E94E0 !important",
        "&:not(.Mui-disabled)": {
          "&:after": {
            borderBottom: "transparent !important",
          },
          "&:before": {
            borderBottom: "transparent !important",
          },
        },
      },
    },
    error: {
      border: `1px solid ${color.primary_palette.christmas_red} !important`,
      margin: 0,
    },
  })(styleInput);
  /**
   * Style customization for formcontrol label
   */
  const CustomFormControl = withStyles({
    root: {
      textTransform: props.text_transform ? props.text_transform : "",
      alignItems: "flex-start",
      color: props.error ? color.primary_palette.christmas_red : "",
    },
  })(FormControlLabel);

  return (
    <>
      <CustomFormControl
        inputProps={{
          style: { fontSize: font_sizes.font_18 },
        }}
        labelPlacement={props.labelPlacement ? props.labelPlacement : "top"}
        label={props.label ? props.label : ""}
        control={<CustomPlainInputFieldStyles {...props} />}
        {...props}
      />
      {props.showErrorText && props.error && (
        <p style={{ color: color.primary_palette.christmas_red, margin: 0 }}>
          {props.errorIcon}
          {props.error}
        </p>
      )}
    </>
  );
}

export default CustomPlainInputField;
