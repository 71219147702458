import { withStyles } from "@material-ui/core/styles";
import tradework_theme, { pxToRem, color } from "../../../utilities/themes";

const styles = withStyles({
  mainSettingsBlock: {
    marginBottom: pxToRem(15),
  },
  mainAdminSettingsBlock: {
    marginBottom: pxToRem(8),
  },
  settingsTxtAlign: {
    position: "relative",
    marginRight: pxToRem(6),
    alignItems: "baseline",
  },
  marginTop4: {
    marginTop: pxToRem(4),
  },
  alignItemsBottom: {
    position: "relative",
    top: pxToRem(8),
  },
  leftLine: {
    width: pxToRem(75),
    marginRight: pxToRem(5),
    position: "relative",
    top: pxToRem(-3),
    display: "inline-block",
    borderBottom: `solid ${pxToRem(1)} ${color.bordered_gray}`,
  },
  rightLine: {
    width: pxToRem(75),
    marginLeft: pxToRem(5),
    position: "relative",
    top: pxToRem(-3),
    borderBottom: `solid ${pxToRem(1)} ${color.bordered_gray}`,
    display: "inline-block",
  },
  addAdminBlockAlign: {
    textAlign: "right",
    "&:hover": {
      "& ellipse": {
        fill: color.primary_palette.franklin_purple,
      },
    },
  },
  disabled_opacity: {
    opacity: "0.4",
  },
  no_opacity: {
    opacity: 1,
  },
  disabledAdminBlockAlign: {
    opacity: "0.4",
    textAlign: "right",
    "&:hover": {
      "& ellipse": {
        fill: color.primary_palette.franklin_purple,
      },
    },
  },
  newAdminAlign: {
    position: "relative",
    top: pxToRem(2),
    cursor: "pointer",
    marginRight: pxToRem(6),
    display: "inline-block",
  },
  settingsLogoAlign: {
    marginLeft: pxToRem(9),
    width: pxToRem(32),
    height: pxToRem(39),
  },
  profileImgStyle: {
    width: pxToRem(70),
    height: pxToRem(70),
    borderRadius: "50%",
    marginRight: pxToRem(9),
    position: "relative",
    top: pxToRem(12),
  },
  profileBlockAlign: {
    marginBottom: pxToRem(21),
  },
  textInline: {
    display: "inline-block",
  },
  txtBlock: {
    marginBottom: pxToRem(13),
  },
  subDivBlock: {
    width: "30%",
    display: "inline-block",
    padding: pxToRem(10),
    borderLeft: `solid ${pxToRem(2)} ${color.primary_palette.franklin_purple}`,
  },
  passwordAlign: {
    position: "relative",
    top: pxToRem(8),
  },
  toggleSwitchStyle: {
    display: "inline-block",
  },
  edit_mode_switch: {
    paddingLeft: pxToRem(9),
    marginTop: pxToRem(-8),
    "& .MuiGrid-container ": {
      "& .MuiSwitch-root": {
        marginLeft: pxToRem(0),
        marginRight: pxToRem(0),
      },
      position: "relative",
      "& .checked_dot": {
        top: pxToRem(-9),
        left: pxToRem(-7),
      },
      "& .MuiGrid-item": {
        "& label": {
          fontSize: pxToRem(15),
          ...tradework_theme.typography.styles.gillsans_sb,
          color: color.primary_palette.franklin_purple,
        },
      },
    },
  },
  grayButtonStyle: {
    width: pxToRem(185),
    height: pxToRem(23),
    borderRadius: pxToRem(3),
    lineHeight: pxToRem(23),
    textAlign: "center",
    background: color.background_gray,
    border: `solid ${pxToRem(1)} ${color.bordered_gray}`,
    color: color.form_colors.textfield_color,
    fontSize: pxToRem(11),
    ...tradework_theme.typography.styles.gillsans_r,
    marginBottom: pxToRem(4),
  },
  customToggleAlign: {
    marginTop: pxToRem(17),
  },
  blueButtonStyle: {
    width: pxToRem(185),
    height: pxToRem(23),
    lineHeight: pxToRem(23),
    textAlign: "center",
    borderRadius: pxToRem(3),
    background: color.fb_button_bg_color,
    border: `solid ${pxToRem(1)} ${color.bordered_gray}`,
    color: color.primary_palette.white,
    fontSize: pxToRem(11),
    ...tradework_theme.typography.styles.gillsans_r,
    marginBottom: pxToRem(4),
  },
  passwordBlkAlign: {
    marginBottom: pxToRem(26),
  },
  orTxtColor: {
    fontSize: pxToRem(11),
    ...tradework_theme.typography.styles.gillsans_r,
    color: color.dark_grayed_text_color,
    marginBottom: pxToRem(4),
    textAlign: "center",
  },
  btnBlockAlign: {
    display: "inline-block",
  },
  basicInfo: {
    marginBottom: pxToRem(13),
  },
  adminTextStyle: {
    fontWeight: "bold",
    marginBottom: pxToRem(19),
  },
  adminDisplayBlock: {
    marginTop: pxToRem(10),
    border: `solid ${pxToRem(1)} ${color.secondary_palette.grays.shadow_gray}`,
    padding: `${pxToRem(10)} ${pxToRem(37)}`,
    backgroundColor: color.primary_palette.fafa_gray,
    marginBottom: pxToRem(57),
  },
  rowAlign: {
    height: pxToRem(20),
    marginBottom: pxToRem(12),
  },
  checkBoxHeading: {
    width: pxToRem(60),
    marginLeft: pxToRem(-22),
  },
  input1: {
    width: pxToRem(181),
    "& .MuiInputBase-input": {
      backgroundColor: color.primary_palette.white,
      padding: 0,
      fontSize: pxToRem(15),
      ...tradework_theme.typography.styles.Helvetica,
      fontWeight: "lighter",
    },
  },
  principalInput: {
    width: pxToRem(238),
    "& ..MuiInputBase-input": {
      paddingBottom: pxToRem(0),
    },
  },
  profileImgStyleAlign: {
    width: pxToRem(70),
    height: pxToRem(70),
    marginRight: pxToRem(9),
    position: "relative",
    display: "inline-block",
    top: pxToRem(4),
  },
  mainTextUnderline: {
    ...tradework_theme.typography.styles.avenir_sb,
    color: color.primary_palette.franklin_purple,
    // textDecoration: "underline",
    fontSize: pxToRem(14),
    cursor: "pointer",
  },
  mainTextsize: {
    width: pxToRem(185),
    cursor: "pointer",
    height: pxToRem(23),
    borderRadius: pxToRem(3),
    lineHeight: pxToRem(23),
    marginLeft: pxToRem(44),
    marginBottom: pxToRem(4),
    textAlign: "center",
    background: color.background_gray,
    border: `solid ${pxToRem(1)} ${color.bordered_gray}`,
    color: color.form_colors.textfield_color,
    fontSize: pxToRem(11),
    ...tradework_theme.typography.styles.gillsans_r,
  },
  sub_fields: {
    color: color.secondary_palette.grays.dim_grey,
    fontSize: pxToRem(16),
    ...tradework_theme.typography.styles.avenir_sb,
    marginTop: pxToRem(8),
    marginRight: pxToRem(6),
  },
  sub_fields_2: {
    color: color.secondary_palette.grays.dim_grey,
    fontSize: pxToRem(16),
    ...tradework_theme.typography.styles.avenir_sb,
    paddingRight: pxToRem(8),
  },
  sub_input: {
    width: "auto !important",
    margin: 0,
  },
  input_style: {
    marginLeft: pxToRem(8),
    marginRight: pxToRem(33),
    input: {
      paddingBottom: pxToRem(1),
    },
  },
  input_field: {
    "& .MuiInputBase-input": {
      ...tradework_theme.typography.styles.NeutraText,
      color: color.primary_palette.black,
    },
  },
  input2: {
    width: pxToRem(225),
    "& .MuiInputBase-input": {
      backgroundColor: color.primary_palette.white,
      padding: 0,
      fontSize: pxToRem(15),
      ...tradework_theme.typography.styles.Helvetica,
      fontWeight: "lighter",
    },
  },
  input3: {
    width: pxToRem(216),
    "& .MuiInputBase-input": {
      backgroundColor: color.primary_palette.white,
      padding: 0,
      fontSize: pxToRem(15),
      ...tradework_theme.typography.styles.Helvetica,
      fontWeight: "lighter",
    },
  },
  trash_icon: {
    fontSize: pxToRem(14),
    display: "block",
    paddingBottom: pxToRem(6),
    cursor: "pointer",
    marginRight: pxToRem(9),
    "& path": {
      fill: color.beneficial_silver,
    },
  },
  save_icon: {
    cursor: "pointer",
    verticalAlign: "text-top",
    marginRight: pxToRem(9),
    width: pxToRem(14),
    height: pxToRem(14),
    marginTop: pxToRem(2),
  },
  addAdminBlock: {
    paddingBottom: pxToRem(10),
    marginBottom: pxToRem(10),
    borderBottom: `solid ${pxToRem(1)} ${
      color.secondary_palette.grays.shadow_gray
    }`,
  },
  addAdminBlockOpacity: {
    paddingBottom: pxToRem(10),
    marginBottom: pxToRem(10),
    borderBottom: `solid ${pxToRem(1)} ${
      color.secondary_palette.grays.shadow_gray
    }`,
    opacity: "0.6",
    pointerEvents: "none",
  },
  addFrAdminOpacity: {
    paddingBottom: pxToRem(10),
    marginBottom: pxToRem(10),
    borderBottom: `solid ${pxToRem(1)} ${
      color.secondary_palette.grays.shadow_gray
    }`,
    opacity: "0.6",
    pointerEvents: "none",
  },
  pointerNone: {
    pointerEvents: "all",
  },
  dropdownWidth: {
    border: 0,
    marginTop: pxToRem(4),
    borderBottom: `solid ${pxToRem(1)} ${
      color.secondary_palette.grays.shadow_gray
    }`,
    "& p": {
      fontSize: pxToRem(14),
      color: color.primary_palette.black,
      ...tradework_theme.typography.styles.avenir_light,
      marginTop: pxToRem(7),
    },
    "& .MuiSvgIcon-root": {
      fontSize: pxToRem(10),
      position: "relative",
      top: pxToRem(4),
    },
  },
  primary_field: {
    width: "40%",
    float: "left",
  },
  tradeWidth: {
    width: pxToRem(200),
    marginRight: pxToRem(6),
    "& .MuiSvgIcon-root": {
      fontSize: pxToRem(10),
      position: "relative",
      top: pxToRem(4),
    },
    "& p": {
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
    },
  },
  yearWidth: {
    width: pxToRem(56),
  },
  employeeWidth: {
    width: pxToRem(140),
  },
  costWidth: {
    width: pxToRem(118),
  },
  clienteleProjWidth: {
    width: pxToRem(320),
  },
  whomeWidth: {
    width: pxToRem(415),
  },
  headingAlign: {
    marginBottom: pxToRem(20),
    marginTop: pxToRem(94),
    marginLeft: pxToRem(40),
  },
  companySettBlock: {
    marginBottom: pxToRem(21),
  },
  fieldsAlign: {
    marginRight: pxToRem(44),
    alignItems: "baseline",
  },
  companyImgAlign: {
    marginLeft: pxToRem(18),
  },
  ovalCircleFilled: {
    height: pxToRem(8),
    width: pxToRem(8),
    backgroundColor: color.primary_palette.highlight_purple,
    marginRight: pxToRem(6),
    borderRadius: "50%",
    position: "relative",
    top: pxToRem(16),
  },
  ovalCircle: {
    height: pxToRem(8),
    width: pxToRem(8),
    border: `solid ${pxToRem(1)} ${color.primary_palette.highlight_purple}`,
    marginRight: pxToRem(6),
    borderRadius: "50%",
    position: "relative",
    top: pxToRem(16),
  },
  plusAlign: {
    width: pxToRem(15),
    position: "relative",
    top: pxToRem(4),
    marginRight: pxToRem(6),
    verticalAlign: "bottom",
    cursor: "pointer",
  },
  plusAlignTrade: {
    width: pxToRem(15),
    position: "relative",
    top: pxToRem(8),
    marginRight: pxToRem(6),
    verticalAlign: "bottom",
  },
  phoneBlock: {
    marginLeft: pxToRem(0),
  },
  sideNavAlign: {
    width: "20%",
  },
  mainBlock: {
    width: "80%",
    paddingLeft: pxToRem(22),
  },
  chkBoxAlign: {
    position: "relative",
    bottom: pxToRem(1),
  },
  plus: {
    fontSize: pxToRem(20),
    color: color.primary_palette.highlight_purple,
    cursor: "pointer",
  },
  crossIconStyle: {
    marginLeft: pxToRem(6),
    marginRight: pxToRem(6),
    cursor: "pointer",
    position: "relative",
    top: pxToRem(4),
  },
  eraseTxt: {
    cursor: "pointer",
    textTransform: "capitalize",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  dropdown_margin: {
    width: "50%",
    marginBottom: pxToRem(10),
    flexWrap: "wrap",
  },
  license_margin: {
    width: "50%",
    marginBottom: pxToRem(10),
    marginLeft: pxToRem(280),
  },
  edit_save_btn: {
    width: pxToRem(99),
    height: pxToRem(25),
    borderRadius: pxToRem(27),
    border: `solid ${pxToRem(1)} ${color.primary_palette.franklin_purple}`,
    backgroundColor: color.primary_palette.white,
    "& .MuiButton-label": {
      color: color.primary_palette.franklin_purple,
      fontSize: pxToRem(15),
      ...tradework_theme.typography.styles.avenir_sb,
    },
  },
  save_wrapper: {
    marginTop: pxToRem(19),
    width: "40%",
    justifyContent: "space-between",
  },
  SaveBtnDiv: {
    textAlign: "center",
    marginBottom: pxToRem(40),
  },
  listAlign: {
    ...tradework_theme.typography.styles.NeutraText,
    fontSize: pxToRem(15),
    padding: pxToRem(4),
  },
  radioBtn: {
    "& .MuiTypography-root": {
      ...tradework_theme.typography.styles.NeutraText,
      fontSize: pxToRem(16),
      color: color.primary_palette.black,
    },
  },
  camelCase: {
    textTransform: "capitalize",
  },
  iconsAlign: {
    display: "block",
    alignSelf: "center",
  },
  padding_left_13: {
    paddingLeft: pxToRem(13),
  },
  padding_left_37: {
    paddingLeft: pxToRem(28),
  },

  employee_scroll: {
    minHeight: `${pxToRem(50)} !important`,
    "& li": {
      overflow: "hidden",
      whiteSpace: "normal",
      textOverflow: "ellipsis",
    },
  },
  labelText: {
    width: pxToRem(125),
    textAlign: "right",
  },
  margin_top_neg_12: {
    marginTop: pxToRem(-12),
  },
  margin_top_neg_8: {
    marginTop: pxToRem(-8),
  },
  margin_bottom_0: {
    marginBottom: pxToRem(0),
  },
  lower_zindex: {
    zIndex: -1,
  },
  padding_bottom_1: {
    paddingBottom: pxToRem(1),
  },
  trash_icon_delete: {
    fontSize: pxToRem(17),
    cursor: "pointer",
    verticalAlign: "text-top",
    paddingRight: pxToRem(6),
    "& path": {
      fill: color.secondary_palette.grays.medium_gray,
    },
  },
  txtLeft: {
    textAlign: "left",
  },
  delete_company: {
    display: "inline-block",
  },
  pointer: {
    cursor: "pointer",
  },
  errorText: {
    color: color.primary_palette.christmas_red,
    fontSize: pxToRem(12),
    fontStyle: "normal",
    fontFamily: "gillsans_sb",
    fontWeight: "600",
    textAlign: "left",
  },
  typical_job: {
    width: pxToRem(701),
    height: pxToRem(78),
    fontSize: pxToRem(15),
    border: `solid ${pxToRem(1)} ${color.secondary_palette.grays.shadow_gray}`,
    ...tradework_theme.typography.styles.NeutraText,
    marginTop: pxToRem(9),
    resize: "none",
  },
  photos_wrapper: {
    justifyContent: "space-between",
    alignItems: "center",
  },
  iconPlusStyle: {
    backgroundImage: `url("/assets/icons/Purple Plus 26x26.svg")`,
    backgroundSize: "cover",
    width: pxToRem(26),
    height: pxToRem(26),
    display: "inline-block",
    cursor: "pointer",
    "&:hover": {
      backgroundImage: `url("/assets/icons/Green Plus 26x26.svg")`,
      backgroundSize: "cover",
      width: pxToRem(26),
      height: pxToRem(26),
      display: "inline-block",
      cursor: "pointer",
    },
  },
  inputField: {
    width: "100%",
    border: "none",
    paddingLeft: pxToRem(8),
    borderTop: `${pxToRem(1)} solid`,
    height: pxToRem(22),

    "&::placeholder": {
      color: color.primary_palette.highlight_purple,
      fontSize: pxToRem(12),
      ...tradework_theme.typography.styles.avenir_light,
      opacity: 1,
    },
    "&:focus": {
      outline: "none",
    },
  },
  inputContainer: {
    marginBottom: pxToRem(-10),
    marginTop: pxToRem(10),
    height: pxToRem(26),
  },
  element_required: {
    fontSize: pxToRem(15),
  },
  width_144: {
    width: pxToRem(143),
    textAlign: "left",
  },
  marginTop14: {
    marginTop: pxToRem(14),
  },
  licensedAlign: {
    top: pxToRem(-6),
  },
  dropDownIcon: {
    height: "5px",
    width: "11px",
    cursor: "pointer",
  },
  countryImage: {
    width: "14px",
    height: "9px",
    paddingRight: "2px",
  },
  dropdownOptions: {
    position: "absolute",
    border: "1px solid #bfbfbf",
    borderRadius: "4px",
    boxShadow: "1px 1px 2px 0 rgba(0, 0, 0, 0.15)",
    fontSize: "12px",
    height: "30px",
    width: "74px",
    padding: "5px 2px 5px 2px",
    background: "white",
    cursor: "pointer",
    zIndex: 1000,
  },
  billingAlign: {
    position: "relative",
    top: pxToRem(2),
  },
  tradesBottomAlignStyle: {
    clear: "both",
    paddingTop: pxToRem(21),
  },
  yearDropdownHeight: {
    minHeight: pxToRem(220),
  },
  LicenseBlockAlign: {
    marginTop: pxToRem(13),
  },
  textCapital: {
    textTransform: "uppercase",
  },
});

export default styles;
