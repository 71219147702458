import { withStyles } from "@material-ui/core/styles";
import tradework_theme, { pxToRem, color } from "../../../utilities/themes";

const styles = withStyles({
  txtTransform: {
    textTransform: "uppercase",
  },
  nickname_label: {
    marginTop: pxToRem(10),
  },
  padding_left_42: {
    paddingLeft: pxToRem(42),
  },
  heading_location: {
    paddingLeft: `${pxToRem(16)} !important`,
  },
  list_dropdown: {
    color: color.primary_palette.franklin_purple,
    fontSize: pxToRem(16),
    fontWeight: "bold",
  },
  categoryAlign: {
    color: color.primary_palette.franklin_purple,
    fontSize: pxToRem(16),
    fontWeight: "bold",
  },
  add_fill: {
    fontSize: pxToRem(26),
    verticalAlign: "middle",
    marginTop: pxToRem(-8),
    paddingRight: pxToRem(7),
    "& path": {
      "&:nth-child(1)": {
        fill: color.secondary_palette.grays.shadow_gray,
      },
    },
  },
  addOffice: {
    ...tradework_theme.typography.styles.gillsans_sb,
    fontSize: pxToRem(24.7),
    color: `${color.primary_palette.franklin_purple} !important`,
    textTransform: "capitalize",
    cursor: "pointer",
    "&:hover": {
      "& .MuiSvgIcon-root": {
        "& path": {
          "&:nth-child(1)": {
            fill: color.primary_palette.franklin_purple,
          },
        },
      },
    },
  },
  location_content: {
    width: "100%",
    display: "block",
  },
  width_70: {
    width: "70%",
  },
  width_30: {
    width: "30%",
  },
  txtCenter: {
    textAlign: "center",
  },
  description_spacing: {
    padding: `${pxToRem(20)} ${pxToRem(0)}`,
  },
  Headquarters: {
    "& .MuiTypography-root": {
      ...tradework_theme.typography.styles.gillsans_sb,
      color: color.primary_palette.franklin_purple,
      fontSize: pxToRem(14),
    },
  },
  city_textfield: {
    "& .MuiInputBase-input": {
      // ...tradework_theme.typography.styles.avenir_light,
      ...tradework_theme.typography.styles.NeutraText,
      color: color.primary_palette.black,
      fontSize: pxToRem(25),
      width: pxToRem(460),
      textTransform: "capitalize",
      padding: `${pxToRem(0)} !important`,
    },
  },
  width_270: {
    width: pxToRem(270),
  },
  width_155: {
    width: pxToRem(155),
  },
  margin_right_8: {
    marginRight: pxToRem(8),
  },
  margin_right_4: {
    marginRight: pxToRem(4),
  },
  addBtn: {
    width: pxToRem(59),
    height: pxToRem(20),
    borderRadius: pxToRem(3),
    padding: 0,
    border: `solid ${pxToRem(1)} ${color.primary_palette.free_purple}`,
    backgroundColor: color.secondary_palette.grays.btn_gray,
    "& .MuiButton-label": {
      fontSize: pxToRem(15),
      ...tradework_theme.typography.styles.avenir_sb,
      color: color.primary_palette.free_purple,
      lineHeight: pxToRem(15),
    },
  },
  width_50: {
    width: "50%",
  },
  width_581: {
    width: pxToRem(480),
  },
  saveBtn: {
    width: pxToRem(99),
    height: pxToRem(25),
    marginLeft: pxToRem(20),
    borderRadius: pxToRem(27),
    border: `solid ${pxToRem(1)} ${color.primary_palette.franklin_purple}`,
    backgroundColor: color.primary_palette.white,
    marginTop: pxToRem(60),
    marginBottom: pxToRem(45),
    "& .MuiButton-label": {
      color: color.primary_palette.franklin_purple,
      fontSize: pxToRem(15),
      ...tradework_theme.typography.styles.avenir_sb,
    },
  },
  businessCardStyle: {
    marginTop: pxToRem(16),
    borderTop: `solid ${pxToRem(1)} ${color.primary_palette.black}`,
  },
  dropDownRow: {
    marginTop: pxToRem(21),
    marginBottom: pxToRem(2),
  },
  customScrollHeight: {
    minHeight: `${pxToRem(50)} !important`,
    "& li": {
      overflow: "hidden",
      whiteSpace: "normal",
      textOverflow: "ellipsis",
    },
  },
  crossIconStyle: {
    marginLeft: pxToRem(5),
    marginRight: pxToRem(12),
    cursor: "pointer",
    width: pxToRem(8),
    height: pxToRem(10),
  },
  listAlign: {
    fontSize: pxToRem(15),
    ...tradework_theme.typography.styles.avenir_sb,
  },
  trash_icon: {
    width: pxToRem(12),
    height: pxToRem(20),
    position: "relative",
    top: pxToRem(62),
    left: pxToRem(10),
    cursor: "pointer",
  },
  pos_relative: {
    position: "relative",
  },
  width_231: {
    width: pxToRem(231),
  },
  width_309: {
    width: pxToRem(309),
  },
  note_icon: {
    position: "relative",
    marginLeft: pxToRem(5),
    "& .hover_display": {
      display: "none",
    },
    "&:hover": {
      "& .hover_display": {
        display: "block",
      },
    },
  },
  hover_content: {
    width: pxToRem(200),
    border: `solid ${pxToRem(1)} ${color.indigo_blue}`,
    padding: pxToRem(4),
    position: "absolute",
    left: pxToRem(0),
    top: pxToRem(-48),
    borderRadius: pxToRem(4),
    backgroundColor: color.primary_palette.white,
    zIndex: 9,
    boxShadow: "0 1px 4px 0 rgba(0, 0, 0, 0.5)",
    "& .triangle": {
      borderBottomColor: color.indigo_blue,
      position: "absolute",
      display: "block",
      width: 0,
      height: 0,
      borderStyle: "solid",
      borderWidth: pxToRem(8),
      borderTopWidth: pxToRem(0),
      borderColor: "transparent",
      right: pxToRem(11),
      top: "100%",
      left: pxToRem(5),
      transform: "rotate(180deg)",
      "&:after": {
        top: pxToRem(11.5),
        margin: pxToRem(-10),
        content: "''",
        borderBottomColor: color.primary_palette.white,
        position: "absolute",
        display: "block",
        width: 0,
        height: 0,
        borderStyle: "solid",
        borderWidth: pxToRem(10),
        borderTopWidth: pxToRem(0),
        borderColor: "transparent",
      },
    },
  },
  cursor: {
    cursor: "pointer",
  },
  select_field_required_another: {
    fontSize: pxToRem(14),
    color: color.primary_palette.franklin_purple,
    zIndex: 9,
    marginRight: pxToRem(4),
  },
  marginTop30: {
    marginTop: pxToRem(30),
  },
  errorBlockAlign: {
    position: "absolute",
  },
  coloredCrossStyle: {
    width: pxToRem(14),
    marginLeft: pxToRem(6),
    marginRight: pxToRem(6),
    position: "relative",
    top: pxToRem(6),
    cursor: "pointer",
    "& path": {
      fill: color.secondary_palette.grays.shadow_gray,
    },
  },
  select_items: {
    height: pxToRem(20),
    borderRadius: pxToRem(2),
    marginRight: pxToRem(8),
    backgroundColor: color.primary_palette.white,
    border: `solid ${pxToRem(1)} ${color.secondary_palette.grays.shadow_gray}`,
    color: color.secondary_palette.grays.drag_text,
    "& .MuiSvgIcon-root": {
      fontSize: pxToRem(14),
    },
    "& p": {
      ...tradework_theme.typography.styles.avenir_sb,
      fontSize: pxToRem(15),
      color: color.primary_palette.black,
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
    },
  },
  add_btn: {
    width: pxToRem(59),
    height: pxToRem(20),
    borderRadius: pxToRem(3),
    padding: 0,
    border: `solid ${pxToRem(1)} ${color.primary_palette.free_purple}`,
    backgroundColor: color.secondary_palette.grays.btn_gray,
    "& .MuiButton-label": {
      fontSize: pxToRem(15),
      ...tradework_theme.typography.styles.avenir_sb,
      color: color.primary_palette.free_purple,
      lineHeight: pxToRem(15),
    },
  },
  marginTop6: {
    marginTop: pxToRem(6),
  },
  marginTop30: {
    marginTop: pxToRem(30),
  },
  infoIconStyle: {
    marginRight: pxToRem(3),
    verticalAlign: "middle",
  },
  colorPurple: {
    color: color.primary_palette.highlight_purple,
  },
  customScrollHeightCategory: {
    minHeight: `${pxToRem(330)} !important`,
    "& li": {
      overflow: "hidden",
      whiteSpace: "normal",
      textOverflow: "ellipsis",
    },
  },
  textCapital: {
    textTransform: "uppercase",
  },
});

export default styles;
