import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import MenuItem from "@material-ui/core/MenuItem";
import { get, map, filter, find } from "lodash";

import { color } from "../../utilities/themes";
import DeleteOfficeStyles from "./styles/deleteOfficeStyles";
import Text from "../common/ui_kit/text";
import strings from "../../utilities/strings";
import Close_Icon from "../data_display/icons/Close";
import CustomSelect from "../common/ui_kit/custom_select";
import ChevronDownIcon from "../data_display/icons/Arrow_Chevron_Down";
import CustomButton from "../navigations/custom_buttons";
import CustomScrollbars from "../data_display/custom_scroll";

import { SettingsActions } from "../../redux/actions";

function DeleteOfficeLocation(props) {
  const { classes, onClose, locationId, reloadOfficeLocations } = props;
  const [state, setState] = useState({
    filteredLocations: [],
  });
  const dispatch = useDispatch();

  const { filteredLocations } = state;

  const portfolioByLocation = useSelector(
    (resData) => resData.Settings.portfolioByLocation
  );
  const addressData = useSelector(
    (resData) => resData.Settings.CompanyLocationsData
  );

  //API
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    dispatch(
      SettingsActions.getCompanyLocationsData(localStorage.getItem("companyId"))
    );
    dispatch(SettingsActions.getPortfoliosByLocation(locationId));
  };

  useEffect(() => {
    if (addressData) {
      const updatedData = addressData?.businessCards?.filter((each) => {
        return each._id != locationId;
      });
      setState({
        ...state,
        filteredLocations: updatedData,
      });
    }
  }, []);

  const selectedLocation = (name, value, regionObj) => (e) => {
    if (regionObj) {
      regionObj.location = value;
      setState({ ...state });
    }
  };

  const executePortfolios = () => {
    let displayAt = [];
    portfolioByLocation.map((location) => {
      location.markets.map((marketLocations) => {
        displayAt.push({
          displayId: get(marketLocations, "displayId"),
          businessCardId: get(marketLocations, "location._id"),
          location: location.portfolioId,
        });
      });
      return null;
    });

    const dataToSubmit = {
      businessCardId: locationId,
      portfolios: portfolioByLocation.map((portfolio) => {
        const filteredDisplayedAt = displayAt.filter(
          (each) => each.location === portfolio.portfolioId
        );
        return {
          portfolioId: portfolio.portfolioId,
          displayAt: filteredDisplayedAt,
        };
      }),
    };

    dispatch(
      SettingsActions.deleteOfficeLocation(dataToSubmit, (res) => {
        if (!res.error.error) {
          reloadOfficeLocations();
          onClose();
        } else {
        }
      })
    );
  };

  return (
    <>
      <div className={classes.delete_company_width}>
        <div className={classes.txtRight}>
          <Close_Icon className={classes.cross_icon} onClick={onClose} />
        </div>
        <div className={classes.txtCenter}>
          <div>
            <img
              alt=""
              src="/assets/images/Moving_Truck.png"
              className={classes.truck}
            />
          </div>
          {get(portfolioByLocation, "length") == 0 ? (
            <Text
              size={15}
              color={color.primary_palette.black}
              className={classes.padding_30}
              family="avenir_sb"
            >
              {strings.modal.titles.delete_office}
            </Text>
          ) : get(portfolioByLocation, "length") == 1 ? (
            <Text
              size={15}
              color={color.primary_palette.black}
              className={classes.padding_30}
              family="avenir_sb"
            >
              {strings.modal.titles.delete_off} &nbsp;
              {get(portfolioByLocation[0], "portfolioName", "")}&nbsp;
              {strings.modal.titles.delete_off_2}
            </Text>
          ) : (
            <Text
              size={15}
              color={color.primary_palette.black}
              className={classes.padding_30}
              family="avenir_sb"
            >
              {strings.modal.titles.delete_multi_off}
            </Text>
          )}
          {portfolioByLocation &&
            map(portfolioByLocation, (location) => {
              return (
                <div className={classes.dropDown_blk}>
                  <Text
                    size={15}
                    color={color.primary_palette.free_purple}
                    transform="uppercase"
                  >
                    {get(location, "portfolioName", "")}
                  </Text>
                  {map(location.markets, (marketsList, idx) => {
                    return (
                      <>
                        <Text
                          size={15}
                          color={color.primary_palette.free_purple}
                          className={classes.alignMarketName}
                        >
                          {idx + 1}. {get(marketsList, "market")}&nbsp; Market
                        </Text>
                        <CustomSelect
                          className={classes.choose_number}
                          IconComponent={ChevronDownIcon}
                          value={marketsList.location}
                          name="location"
                          renderValue={(value) =>
                            value && (
                              <span className={classes.avenir_light}>
                                <span className={classes.avenir_heavy}>
                                  {get(value, "name", "")}{" "}
                                </span>{" "}
                                - {get(value, "address1", "")} -
                                {get(value, "city", "")}
                              </span>
                            )
                          }
                          MenuProps={{
                            getContentAnchorEl: null,
                            disableScrollLock: true,
                            anchorOrigin: {
                              vertical: "bottom",
                              horizontal: "left",
                            },
                          }}
                        >
                          <CustomScrollbars
                            className={classes.customScrollHeight}
                          >
                            {filteredLocations &&
                              map(filteredLocations, (filterLocation) => {
                                return (
                                  <MenuItem
                                    onClick={selectedLocation(
                                      "location",
                                      filterLocation,
                                      marketsList
                                    )}
                                  >
                                    {get(filterLocation, "nickname", "") ||
                                      get(filterLocation, "name", "")}
                                  </MenuItem>
                                );
                              })}
                          </CustomScrollbars>
                        </CustomSelect>{" "}
                      </>
                    );
                  })}
                </div>
              );
            })}

          <div className={classes.txtCenter}>
            <CustomButton
              className={classes.carryBtn}
              onClick={executePortfolios}
            >
              EXECUTE
            </CustomButton>
          </div>
        </div>
      </div>
    </>
  );
}

export default DeleteOfficeStyles(DeleteOfficeLocation);
