import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { get } from "lodash";
import {
  sortableContainer,
  sortableElement,
  sortableHandle,
} from "react-sortable-hoc";
import arrayMove from "array-move";

import dashboardStyles from "./styles/dashboardStyles";
import Row from "../common/ui_kit/row";
import Text from "../common/ui_kit/text";
import strings from "../../utilities/strings";
import Close_Icon from "../data_display/icons/Close";
import CustomButton from "../navigations/custom_buttons";
import Edit_Circle_Icon from "../data_display/icons/Edit";
import CustomModal from "../inputs/custom_modal";
import ProductPublication from "./product_publication";
import Trash_can from "../data_display/icons/Trash_can";
import ProductDelete from "./product_deleteModal";
import SvgHamburger from "../data_display/icons/Hamburger";
import AddImageHover from "../data_display/icons/add_default_hover";

import { color } from "../../utilities/themes";
import { ProfileActions } from "../../redux/actions";
import CustomScrollbars from "../data_display/custom_scroll";

function ProductDashBoard(props) {
  const [state, setState] = useState({});
  const { classes, onClose } = props;

  const {
    openProductsModal,
    deleteProduct,
    openDeleteModal,
    isProductValue,
    productsInfo,
    products,
    publications,
    productOrPublicationData,
  } = state;

  const productsAndPublications = useSelector(
    (resData) => resData.Profile.productsAndPublicationData
  );

  const handleProductsModal = (name, productData) => () => {
    setState({
      ...state,
      openProductsModal: !openProductsModal,
      isProductValue: name == "product" ? true : false,
      productOrPublicationData: productData,
    });
  };

  //API
  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (productsAndPublications) {
      const { products, publications } = productsAndPublications;
      setState({
        products: [...products],
        publications: [...publications],
      });
    }
  }, [productsAndPublications]);

  const dispatch = useDispatch();
  const fetchData = () => {
    dispatch(
      ProfileActions.fetchProjectAndPublicationData(
        localStorage.getItem("portfolioId")
      )
    );
  };

  const SortableContainer = sortableContainer(
    ({ styleContainer, children }) => {
      return <ul className={styleContainer.ulAlign}>{children}</ul>;
    }
  );

  const SortableItem = sortableElement(({ productsData, name }) => {
    return (
      <>
        <div className={classes.product_img}>
          <img
            alt=""
            src={
              name === "product"
                ? get(
                    productsData,
                    "imageUri",
                    "/assets/images/Add_Product.png"
                  )
                : get(
                    productsData,
                    "imageUri",
                    "/assets/images/Add_Publication.png"
                  )
            }
            className={classes.product_block_img}
            onClick={
              !productsData.imageUri &&
              handleProductsModal(
                name === "product" ? "product" : "publication"
              )
            }
          />
          {productsData.imageUri && (
            <div className={classes.icons}>
              <Trash_can
                className={classes.trash_can}
                onClick={handleDeleteModal(productsData)}
              />
              {((name === "product" && get(products, "length") > 1) ||
                (name === "publication" &&
                  get(publications, "length") > 1)) && (
                <DragHandle styleClasses={classes} />
              )}
              <img
                src="/assets/icons/pencil.svg"
                className={`${classes.hamburger} ${classes.cursorPointer}`}
                onClick={handleProductsModal("product", productsData)}
                alt=""
              />
            </div>
          )}
        </div>
      </>
    );
  });

  const handleDeleteModal = (deleteProduct) => () => {
    setState({
      ...state,
      openDeleteModal: !openDeleteModal,
      productsInfo: deleteProduct,
    });
  };

  const defaultImageJsx = (type, title) => {
    return (
      <div
        className={classes.addImage_default}
        onClick={handleProductsModal(type)}
      >
        {" "}
        <AddImageHover className={`${classes.addHover} addHover_effect`} />
        <Text
          size={13}
          color={color.secondary_palette.grays.shadow_gray}
          family="avenir_sb"
        >
          {title}
        </Text>
      </div>
    );
  };

  const onSortEnd = ({ oldIndex, newIndex }) => {
    setState({
      ...state,
      products: arrayMove(state.products, oldIndex, newIndex),
    });
  };

  const DragHandle = sortableHandle(({ styleClasses }) => (
    <SvgHamburger className={styleClasses.hamburger_icon} />
  ));

  const onSortEndPub = ({ oldIndex, newIndex }) => {
    setState({
      ...state,
      publications: arrayMove(state.publications, oldIndex, newIndex),
    });
  };

  const deleteProdPub = (id) => () => {
    dispatch(
      ProfileActions.deleteProductOrPublication(id, () => {
        dispatch(
          ProfileActions.fetchProjectAndPublicationData(
            localStorage.getItem("portfolioId")
          )
        );
        dispatch(
          ProfileActions.getPortfolioData(localStorage.getItem("portfolioId"))
        );
      })
    );
    setState({
      ...state,
      openDeleteModal: false,
    });
  };

  const saveProductSortOrder = () => {
    const dataToSubmit = {
      portfolioId: localStorage.getItem("portfolioId"),
      publications: publications,
      products: products,
    };
    dispatch(
      ProfileActions.saveProductSortOrder(dataToSubmit, () => {
        onClose();
        dispatch(
          ProfileActions.getPortfolioData(localStorage.getItem("portfolioId"))
        );
      })
    );
  };

  return (
    <>
      <div className={classes.dashboardWidth}>
        <div className={classes.textCenter}>
          <Edit_Circle_Icon className={classes.edit_icon} />
        </div>
        <div className={classes.btnRight}>
          <CustomButton className={classes.crossBtn} isX>
            <Close_Icon onClick={saveProductSortOrder} />
          </CustomButton>
        </div>
        <div className={classes.spacing}>
          <Text
            size={30}
            family="avenir_light"
            color={color.primary_palette.black}
            transform="uppercase"
            className={`${classes.textCenter} ${classes.padding_bottom_40}`}
          >
            {strings.portfolio_userView.titles.products}
          </Text>
          <CustomScrollbars
            className={classes.prod_pub_height}
            isModalScroll={true}
          >
            <div>
              <div className={classes.padding_Top_8}>
                <Text
                  size={16}
                  color={color.primary_palette.franklin_purple}
                  family="avenir_sb"
                  transform="uppercase"
                >
                  {strings.product_publication.titles.products}
                </Text>
                <Row className={classes.spaceBetween}>
                  {defaultImageJsx("product", "Add Product")}
                  <Row className={classes.product_icon}>
                    <SortableContainer
                      styleContainer={classes}
                      onSortEnd={onSortEnd}
                      useDragHandle
                      axis="xy"
                    >
                      {products &&
                        products.map((productsData, idx) => {
                          return (
                            <SortableItem
                              productsData={productsData}
                              key={idx}
                              index={idx}
                              title={idx}
                              name="product"
                            />
                          );
                        })}
                    </SortableContainer>
                  </Row>
                </Row>
              </div>
              <div>
                <Text
                  size={16}
                  color={color.primary_palette.franklin_purple}
                  family="avenir_sb"
                  transform="uppercase"
                >
                  {strings.product_publication.titles.publications}
                </Text>
                <Row className={classes.spaceBetween}>
                  {defaultImageJsx("publication", "Add Publication")}
                  <Row className={classes.product_icon}>
                    <SortableContainer
                      styleContainer={classes}
                      onSortEnd={onSortEndPub}
                      useDragHandle
                      axis="xy"
                    >
                      {publications &&
                        publications.map((productsData, idx) => {
                          return (
                            <SortableItem
                              productsData={productsData}
                              key={idx}
                              index={idx}
                              title={idx}
                              name="publication"
                            />
                          );
                        })}
                    </SortableContainer>
                  </Row>
                </Row>
              </div>
            </div>
          </CustomScrollbars>
          <div className={classes.textCenter}>
            <CustomButton
              className={classes.save_item}
              onClick={saveProductSortOrder}
            >
              {strings.product_publication.titles.publish}
            </CustomButton>
          </div>
        </div>
      </div>
      <React.Fragment>
        <CustomModal
          open={openProductsModal}
          aria-labelledby="modal-title"
          aria-describedby="modal-description"
          onClose={handleProductsModal("")}
        >
          <ProductPublication
            onClose={handleProductsModal("")}
            deleteProduct={deleteProduct}
            isProductValue={isProductValue}
            productOrPublicationData={productOrPublicationData}
          />
        </CustomModal>
        <CustomModal
          open={openDeleteModal}
          aria-labelledby="modal-title"
          aria-describedby="modal-description"
          onClose={handleDeleteModal("")}
        >
          <ProductDelete
            onClose={handleDeleteModal("")}
            productsInfo={productsInfo}
            deleteProdPub={deleteProdPub}
          />
        </CustomModal>
      </React.Fragment>
    </>
  );
}

export default dashboardStyles(ProductDashBoard);
