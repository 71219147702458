import React, { useState, useEffect } from "react";
import { map, get, sortBy, startsWith, find, filter } from "lodash";
import { useSelector, useDispatch } from "react-redux";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useHistory, useParams } from "react-router-dom";
import {
  INSTAGRAM_AUTHENTICATION_URL,
  COVER_IMAGE_URI,
  COVER_IMAGE_URI_HOVER,
  PROD_PLACEHOLDER,
  ARROW_IMG,
  ENVELOP_ICON,
  FACEBOOK_URL,
  INSTAGRAM_URL,
  TWITTER_URL,
  BW_SNAPSHOT,
  COLOR_SNAPSHOT,
} from "../../constants";

import Row from "../common/ui_kit/row";
import Text from "../common/ui_kit/text";
import CustomButton from "../navigations/custom_buttons";
import strings from "../../utilities/strings";
import PortfolioUserStyles from "./styles/portfolioUserStyles";
import AddImage from "../add_images/add_image";
import CustomToggleButton from "../../components/common/ui_kit/custom_toggle_button";
import FlagShip from "../llamaComponents/flagShip";
import ChoosePicture from "../add_images/choose_picture";
import CustomModal from "../inputs/custom_modal";
import ElevatorPitch from "../elevator_pitch/elevator_pitch";
import ProfileImage from "../add_images/profile_image";
import ProductDashBoard from "../product_publications/product_dashboard";
import VignettesAddProject from "../vignettes/vignettesAddProject";
import VignetteDashBoard from "../vignettes/vignetteDashboard";
import ProjectDashBoard from "../add_new_project/project_dashboard";
import CoverBusiness from "../business_card/coverBusinessCard";
import BusinessCardModal from "../business_card/businessCardmodal";
import MoneyModal from "../modals/moneyModal";
import CongratsModal from "../modals/congratsModal";
import InviteModal from "../modals/InviteModal";
import MarketsModal from "../modals/marketsModal";
import SocialLink from "./social_links";
import BusinessOne from "../business_card/businessCard_step1";
import PortfolioMoneyModal from "../portfolioMoneyModal/portfolioMoneyModal";
import AddIcon from "../data_display/icons/add_default_hover";

import { color } from "../../utilities/themes";
import {
  ProfileActions,
  LookUpActions,
  SettingsActions,
} from "../../redux/actions";
import Location from "../data_display/icons/location";
import Feedback from "../modals/feedbackModal";

function PorfolioUserView(props) {
  const {
    classes,
    openImageModal,
    openBusinessModal,
    openElevatorModal,
    openProjModal,
    openProductModal,
    openVignetteModal,
    disableEdit,
    triggerPopupModals,
    isPublic,
    openBusinessEditModal,
  } = props;

  const [values, setValues] = useState({
    openCroppingModal: false,
    openElevatorPitch: false,
    croppingModalType: null,
    openProjectDashboard: false,
    imageData: {},
    openImageExp: {},
    openEditProduct: false,
    openVignettes: false,
    isPortfolioVisible: false,
    isPublicMode: disableEdit ? true : false,
    openEditModal: false,
    openBusinessPopup: false,
    openEditBusinessModal: false,
    showMoneyModal: false,
    showMoneyModalLimbo: false,
    openInviteModal: false,
    openMarketModal: false,
    region: "",
    category: "",
    location: "",
    selectedRegions: [],
    customerCategory: "",
    portfolioDataObj: "",
    defaultCover: COVER_IMAGE_URI,
    defaultProfile: BW_SNAPSHOT,
    eachRegionData: "",
    openCongratsModal: false,
    toggleFeedbackModal: false,
  });

  const {
    openCroppingModal,
    croppingModalType,
    imageData,
    openElevatorPitch,
    openEditProduct,
    openVignettes,
    sortedVignettes,
    openProjectDashboard,
    isPublicMode,
    isPortfolioVisible,
    openEditModal,
    showMoneyModal,
    openBusinessPopup,
    openEditBusinessModal,
    openInviteModal,
    openMarketModal,
    customerCategory,
    showMoneyModalLimbo,
    portfolioDataObj,
    defaultCover,
    defaultProfile,
    isAllPortfolioPublic,
    eachRegionData,
    openCongratsModal,
    toggleFeedbackModal,
  } = values;

  let { slug, market, frReview } = useParams();

  const history = useHistory();

  const loadedImageData = useSelector(
    (resData) => resData.Profile.coverImageData
  );
  const portfolioData = useSelector((resData) => resData.Profile.portfolioData);
  const displayedAtData = useSelector(
    (resData) => resData.Profile.displayedAtData
  );
  const InstafeedsData = useSelector((resData) => resData.Profile.instaFeeds);
  const lookUpData = useSelector((resData) => resData.LookUp.LookUpData);
  const companyPortfolioData = useSelector(
    (resData) => resData.Settings.companyPortfolioData
  );
  const CompanyBasicData = useSelector(
    (resData) => resData.Settings.CompanyBasicData
  );
  const isOpenMarketModal = useSelector(
    (resData) => resData.Settings.isOpenMarketModal
  );

  const onEditModeChange = (e) => {
    history.push("/publicPortfolioView");
  };

  const getUrl = (url) => {
    if (url) {
      if (startsWith(url, "http://") || startsWith(url, "https://")) {
        return url;
      }
      return `//${url}`;
    }
    return "javascript:void(0)";
  };

  const redirectPublic = (projectId) => () => {
    dispatch(
      ProfileActions.getIndividualProjectData(projectId, (res) => {
        const projectData = res.data.data;
        history.push({
          pathname: "/ProjectSingleView",
          state: { ...projectData, val: false },
        });
      })
    );

    // history.push("/publicView");
  };

  const returnSortedVignettes = () => {
    const data = values.sortedVignettes?.length
      ? values.sortedVignettes
      : vignettes;
    return data.map((vignette) => {
      return (
        <div className={classes.vignettes_img}>
          <a
            href={vignette.productLink && getUrl(vignette.productLink)}
            target="_blank"
          >
            <img
              src={sortedVignettes?.length > 0 ? vignette.imageUri : vignette}
              onClick={
                // !isPublicMode &&
                !vignette.productLink &&
                vignette.imageUri &&
                redirectPublic(vignette.projectId)
              }
              className={classes.cursor}
              alt=""
            />
          </a>
        </div>
      );
    });
  };

  const returnFlagShipData = () => {
    const flagshipsData =
      portfolioData && portfolioData.projects
        ? [...portfolioData.projects]
        : [];
    if (flagshipsData && flagshipsData.length < 6) {
      flagshipsData.push(
        {
          title: "TROPICAL HOUSE BY THE SEA",
          subtitle: "CLASSIC REVIVAL",
          isAdd: true,
          className: `${classes.placeHolder1} placeHolder1`,
        },
        {
          title: "COUNTRY HOUSE CONNECTICUT",
          subtitle: "GUSTAVIAN GRACE",
          isAdd: true,
          className: `${classes.placeHolder2} placeHolder2`,
        },
        {
          title: "CONTEMPORARY FIFTH AVENUE",
          subtitle: "RUSTIC GRACE",
          className: `${classes.placeHolder3} placeHolder3`,
          isAdd: true,
        },
        {
          title: "SUMMER HOUSE IN JACKSON HOLE",
          subtitle: "RUSTIC MAJESTY",
          className: `${classes.placeHolder4} placeHolder4`,
          isAdd: true,
        },
        {
          title: "HOUSE IN SALZBURG",
          subtitle: "SIMPLE GRANDEUR",
          className: `${classes.placeHolder5} placeHolder5`,
          isAdd: true,
        },
        {
          title: "SOHO LOFT NYC",
          subtitle: "CITY LIVING",
          className: `${classes.placeHolder6} placeHolder6`,
          isAdd: true,
        }
      );
    }
    return flagshipsData.slice(0, 6);
  };

  const redirectToAddProject = () => {
    history.push({
      pathname: "/AddProjects",
      state: { edit: false },
    });
  };

  const returnFlagShipDataPublic = () => {
    const flagshipsData =
      portfolioData && portfolioData.projects
        ? [...portfolioData.projects]
        : [];
    return flagshipsData.slice(0, 6);
  };

  const handleClose = () => {
    setValues({
      ...values,
      openCroppingModal: false,
    });
  };

  const redirectToSocial = (name, link) => () => {
    if (link) {
      if (!startsWith(link, "http://") || !startsWith(link, "https://")) {
        window.open(
          name === "twitter"
            ? TWITTER_URL + link
            : name === "facebook"
            ? FACEBOOK_URL + link
            : INSTAGRAM_URL + link,
          "_blank" // <- This is what makes it open in a new window.
        );
      } else {
        window.open(
          link,
          "_blank" // <- This is what makes it open in a new window.
        );
      }
    }
  };

  const toggleCroppingModal = (obj) => {
    const { imageData, type } = obj;
    setValues({
      ...values,
      openCroppingModal: true,
      croppingModalType: type,
      imageData: { ...values.imageData, [type]: imageData },
    });
  };

  const updateCroppedImage = (imageObject, type) => {
    const { croppedImage, blobUrl } = imageObject;
    imageData[type] = imageData[type] || {};
    imageData[type].imageUrl = croppedImage;
    imageData[type].blobFile = blobUrl;
    setValues({ ...values, imageData });
  };

  const openImageExp = (type, openImageUpload) => () => {
    if (
      values.imageData &&
      values.imageData[type] &&
      values.imageData[type].imageUrl &&
      !openImageUpload
    ) {
      setValues({
        ...values,
        openCroppingModal: true,
        croppingModalType: type,
      });
      return;
    }
    setValues({
      ...values,
      openImageExp: { ...openImageExp, [type]: Date.now() },
      openCroppingModal: false,
    });
  };

  const handleElevatorClose = () => {
    setValues({
      ...values,
      openElevatorPitch: false,
    });
  };

  const handleEditModal = () => {
    setValues({
      ...values,
      openEditModal: !openEditModal,
    });
  };

  const triggerBusinessPopup = () => {
    setValues({
      ...values,
      openBusinessPopup: !openBusinessPopup,
    });
  };

  const handleElevatorOpen = () => {
    setValues({
      ...values,
      openElevatorPitch: true,
    });
  };

  const handleProjectDashboard = () => {
    setValues({
      ...values,
      openProjectDashboard: !openProjectDashboard,
      openVignettes: false,
    });
  };

  const handleEditProductClose = () => {
    setValues({
      ...values,
      openEditProduct: false,
    });
  };

  const handleEditProductOpen = () => {
    setValues({
      ...values,
      openEditProduct: true,
    });
  };

  const handleVignettesClose = () => {
    setValues({
      ...values,
      openVignettes: false,
    });
  };

  const handleVignettesOpen = () => {
    setValues({
      ...values,
      openVignettes: true,
    });
  };

  const mouseOver = (type) => () => {
    if (type === "cover") {
      setValues({
        ...values,
        defaultCover: COVER_IMAGE_URI_HOVER,
      });
    } else {
      setValues({
        ...values,
        defaultProfile: COLOR_SNAPSHOT,
      });
    }
  };

  const mouseOut = (type) => () => {
    if (type === "cover") {
      setValues({
        ...values,
        defaultCover: COVER_IMAGE_URI,
      });
    } else {
      setValues({
        ...values,
        defaultProfile: BW_SNAPSHOT,
      });
    }
  };

  const onPortfolioVisibility =
    (flag, portfolioId, displayedAtId, portifolioObj, eachRegion) => () => {
      if (eachRegion) {
        if (new Date() < new Date(eachRegion.expirationDate)) {
          eachRegion.isVisibleToPublic = flag;
          setValues({
            ...values,
          });
          return;
        }
      }
      localStorage.setItem("displayedAtId", displayedAtId);
      localStorage.setItem("portfolioId", portfolioId);
      let customerCategory = get(
        CompanyBasicData,
        "companyBasics.customerCategory",
        ""
      );
      localStorage.setItem("customerCategory", 'LIMBO');
      if (flag) {
        if (customerCategory === "LUCKY") {
          eachRegion.isVisibleToPublic = true;
          setValues({
            ...values,
            portfolioDataObj: portifolioObj,
            customerCategory: customerCategory,
            eachRegionData: eachRegion,
            isAllPortfolioPublic: false,
          });
          return;
        } else if (
          customerCategory === "LIMBO" ||
          customerCategory === "LLAMA" ||
          customerCategory === "REDS" ||
          customerCategory === "VULCANS" ||
          customerCategory === "FREEFRIENDS"
        ) {
          setValues({
            ...values,
            showMoneyModalLimbo: true,
            portfolioDataObj: portifolioObj,
            customerCategory: customerCategory,
            eachRegionData: eachRegion,
            isAllPortfolioPublic: false,
          });
          return;
        }
        setValues({
          ...values,
          showMoneyModal: true,
          portfolioDataObj: portifolioObj,
          customerCategory: customerCategory,
          eachRegionData: eachRegion,
          isAllPortfolioPublic: false,
        });
      } else {
        eachRegion.isVisibleToPublic = false;
        setValues({
          ...values,
          showMoneyModal: false,
        });
      }
    };

  const onPortfolioVisibilityAll = (portifolioObj) => () => {
    localStorage.setItem("portfolioId", portifolioObj._id);
    localStorage.setItem(
      "displayedAtId",
      get(portifolioObj, "displayedAt[0].displayedAtId")
    );
    let customerCategory = get(
      CompanyBasicData,
      "companyBasics.customerCategory",
      ""
    );
    localStorage.setItem("customerCategory", 'LIMBO');
    if (customerCategory === "LUCKY") {
      setValues({
        ...values,
        portfolioDataObj: portifolioObj,
        customerCategory: customerCategory,
        isAllPortfolioPublic: true,
      });
      return;
    } else if (
      customerCategory === "LIMBO" ||
      customerCategory === "LLAMA" ||
      customerCategory === "REDS" ||
      customerCategory === "VULCANS" ||
      customerCategory === "FREEFRIENDS"
    ) {
      setValues({
        ...values,
        showMoneyModalLimbo: true,
        portfolioDataObj: portifolioObj,
        customerCategory: customerCategory,
        isAllPortfolioPublic: true,
      });
      return;
    }
    setValues({
      ...values,
      showMoneyModal: true,
      portfolioDataObj: portifolioObj,
      customerCategory: customerCategory,
      isAllPortfolioPublic: true,
    });
  };

  //returns formatted date in US
  const getPreviewDate = (date) => {
    if (!date) {
      return "";
    }
    const NewDt = new Date(date);
    return new Intl.DateTimeFormat("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    }).format(NewDt);
  };

  const authorizeInstagram = () => {
    let host = get(window.location, "host", "");
    if (host.includes("-dev-")) {
      window.location.href =
        INSTAGRAM_AUTHENTICATION_URL.P1 + INSTAGRAM_AUTHENTICATION_URL.P2_DEV;
    }
    if (host.includes("-qa-")) {
      window.location.href =
        INSTAGRAM_AUTHENTICATION_URL.P1 + INSTAGRAM_AUTHENTICATION_URL.P2_QA;
    }
    if (host.includes("-stage-")) {
      window.location.href =
        INSTAGRAM_AUTHENTICATION_URL.P1 + INSTAGRAM_AUTHENTICATION_URL.P2_STAGE;
    }
    window.location.href =
      INSTAGRAM_AUTHENTICATION_URL.P1 + INSTAGRAM_AUTHENTICATION_URL.P2_QA;
  };

  const savePortfolio = (portfolioData) => () => {
    const dataToSubmit = {
      name: portfolioData.name,
      isVisibleToPublic: portfolioData.isVisibleToPublic,
      displayedAt: portfolioData.displayedAt,
    };
    dispatch(
      SettingsActions.updateCompanyPortfolioData(
        portfolioData._id,
        dataToSubmit,
        (res) => {
          if (!res.error.error) {
            fetchData();
            toggleMarketModal();
          }
        }
      )
    );
  };

  //API
  useEffect(() => {
    const authCode = get(props, "location.search", "").substring(6);
    if (slug) {
      localStorage.setItem("viewType", "public");
      localStorage.setItem("portfolioSlug", slug);
      dispatch(
        ProfileActions.getPortfolioDataBySlug(market, slug, (res) => {
          localStorage.setItem("portfolioId", res.data.portfolio._id);
        })
      );
    } else {
      const hasPortfolio = localStorage.getItem("portfolioId");
      const hasPortfolioPublic = localStorage.getItem("isPortfolioPublic");
      const hasMarketModal = localStorage.getItem("openMarketModal");
      if (!hasPortfolio || hasPortfolio === "undefined") {
        triggerBusinessPopup();
      }
      if (hasPortfolioPublic) {
        toggleInviteModal();
      }
      if (hasMarketModal) {
        toggleMarketModal();
      }
      localStorage.setItem("viewType", "");
      localStorage.setItem("portfolioSlug", "");
      fetchData(authCode);
    }
    return () => {
      if (!slug) {
        localStorage.setItem("portfolioSlug", "");
        localStorage.setItem("viewType", "");
      }
    };
  }, []);

  const openEditBusinessCard = () => {
    localStorage.setItem(
      "companyName",
      get(displayedAtData, "businessCardData.name", "")
    );
    setValues({
      ...values,
      openEditBusinessModal: !openEditBusinessModal,
    });
  };

  const redirectPortfolio = () => {
    history.push("/settings/portfolios");
  };

  const returnTallyValue = (portfolioData) => {
    const tallyData = {
      hasElevatorPitchData:
        Object.keys(get(portfolioData, "elevatorPitch", {})).length > 0,
      hasProjects: get(portfolioData, "projects.length", 0) > 0,
      hasPublications:
        get(portfolioData, "productsOrPublications.length", 0) > 0,
      hasVignettes: get(portfolioData, "vignettes.length", 0) > 0,
      hasCoverPic:
        get(portfolioData, "originalProfileImgUri", "") ||
        get(portfolioData, "originalCompanyLogo", "")
          ? true
          : false,
    };
    const tallyValue = Object.values(tallyData).filter((each) => each === true);
    return tallyValue.length;
  };

  const handleAddRegionClick = (portifolioObj) => () => {
    const { category, region } = portifolioObj;
    if (!category || !region) {
      return;
    }
    portifolioObj.displayedAt = portifolioObj.displayedAt || [];
    let foundRegion = find(portifolioObj.displayedAt, { region: region });
    if (foundRegion) {
      let foundCategory = filter(
        foundRegion.category,
        (each) => each === category
      )[0];
      if (foundCategory) {
        return;
      }
      foundRegion.category = foundRegion.category || [];
      foundRegion.category = [...foundRegion.category, category];
      foundRegion.displayedAtId = get(foundRegion, "_id", "");
    } else {
      portifolioObj.displayedAt.category =
        portifolioObj.displayedAt.category || [];
      portifolioObj.displayedAt = [
        ...portifolioObj.displayedAt,
        {
          region,
          category: [...portifolioObj.displayedAt.category, category],
          displayedAtId: get(portifolioObj, "displayedAt._id", ""),
        },
      ];
    }
    portifolioObj.region = "";
    portifolioObj.category = "";
    setValues({ ...values });
  };

  const deleteRegion = (portfolioObj, region, categoryName) => () => {
    const { displayedAt } = portfolioObj;
    const foundRegion = find(displayedAt, { region });
    if (foundRegion) {
      foundRegion.category = foundRegion.category.filter(
        (each) => each !== categoryName
      );
    }
    if (foundRegion.category.length === 0) {
      portfolioObj.displayedAt = portfolioObj.displayedAt.filter(
        (each) => each.region !== region
      );
    }
    setValues({ ...values });
  };

  const deleteCategory = (portfolioObj, region, categoryName) => () => {
    const { displayedAt } = portfolioObj;
    const foundRegion = find(displayedAt, { region });
    portfolioObj.displayedAt = portfolioObj.displayedAt.filter(
      (each) => each.region !== region
    );
    setValues({ ...values });
    return;
  };

  const handleCongratsModal = () => {
    setValues({
      ...values,
      openCongratsModal: !openCongratsModal,
    });
  };

  useEffect(() => {
    if (portfolioData && portfolioData._id) {
      localStorage.setItem("portfolioId", portfolioData._id);
      const hasPortfolioPublic = localStorage.getItem("isPortfolioPublic");
      const hasMarketModal = localStorage.getItem("openMarketModal");
      const { vignettes } = portfolioData;
      const sortedData = sortBy(vignettes, "position");
      setValues({
        ...values,
        sortedVignettes: [...sortedData],
        isPortfolioVisible: get(displayedAtData, "isVisibleToPublic", false),
        selectedRegions: get(portfolioData, "displayedAt"),
        openInviteModal: hasPortfolioPublic ? true : false,
        openMarketModal: hasMarketModal ? true : false,
      });
      dispatch(
        ProfileActions.getCoverImageData(localStorage.getItem("portfolioId"))
      );
    }
  }, [
    portfolioData,
    localStorage.getItem("isPortfolioPublic"),
    localStorage.getItem("openMarketModal"),
  ]);

  const dispatch = useDispatch();
  const fetchData = (authCode) => {
    dispatch(
      ProfileActions.getPortfolioData(localStorage.getItem("portfolioId"))
    );
    dispatch(LookUpActions.getLookUpData());
    dispatch(
      SettingsActions.getCompanyLocationsData(localStorage.getItem("companyId"))
    );
    dispatch(
      SettingsActions.getCompanyBasicsData(localStorage.getItem("companyId"))
    );
    dispatch(
      SettingsActions.getCompanyPortfolioData(localStorage.getItem("companyId"))
    );
    if (authCode) {
      dispatch(
        ProfileActions.getInstagramVerificationCode(
          {
            code: authCode,
            token: authCode,
          },
          () => {
            dispatch(ProfileActions.getInstaFeeds());
          }
        )
      );
    }
  };

  const returnSliderClass = (length) => {
    switch (length) {
      case 2:
        return classes.slider2Images;
      case 3:
        return classes.slider3Images;
      default:
        return classes.slider;
    }
  };

  const redirectToProdView = () => {
    history.push({
      pathname: "/PublicViewProducts",
      state: { isPublicMode },
    });
  };

  const toggleInviteModal = () => {
    setValues({
      ...values,
      openInviteModal: !openInviteModal,
    });
  };

  const closeInviteModal = () => {
    setValues({
      ...values,
      openInviteModal: false,
      openMarketModal: false,
    });
    localStorage.removeItem("isPortfolioPublic");
  };

  const toggleMarketModal = () => {
    localStorage.setItem("openMarketModal", true);
    setValues({
      ...values,
      openMarketModal: !openMarketModal,
    });
    dispatch(
      ProfileActions.getPortfolioData(localStorage.getItem("portfolioId"))
    );
  };

  const closeMarketModal = () => {
    setValues({
      ...values,
      openMarketModal: false,
    });
    localStorage.removeItem("openMarketModal");
  };

  useEffect(() => {
    if (loadedImageData) {
      setValues({
        ...values,
        imageData: {
          cover: {
            imageUrl: loadedImageData.coverImgUri,
            originalUrl: get(loadedImageData, "originalCoverImgUri", ""),
          },
          profile: {
            imageUrl: loadedImageData.profileImgUri,
            originalUrl: get(loadedImageData, "originalProfileImgUri", ""),
          },
          logo: {
            imageUrl: loadedImageData.companyLogo,
            originalUrl: get(loadedImageData, "originalCompanyLogo", ""),
          },
        },
      });
    }
  }, [loadedImageData]);

  // useEffect(() => {
  //   setValues({
  //     ...values,
  //     openMarketModal: isOpenMarketModal ? true : false,
  //   });
  // }, [isOpenMarketModal]);

  const toggleModal = (type, type2) => () => {
    if (type2) {
      setValues({ ...values, [type]: ![type], [type2]: ![type2] });
      return;
    }
    setValues({ ...values, [type]: ![type] });
  };

  const addDefaultSrc = (ev) => {
    ev.target.src = "/assets/images/Blank Eagle Ribbon.svg";
  };

  const renderSlides = () => {
    const sortedData = sortBy(
      get(portfolioData, "productsOrPublications", []),
      (obj) => !obj.isProduct
    );
    const labelIndexes = {
      products: null,
      publications: null,
    };
    sortedData.map((each, idx) => {
      if (
        each.isProduct &&
        !labelIndexes.products &&
        labelIndexes.products !== 0
      ) {
        labelIndexes.products = idx;
      }
      if (!each.isProduct && !labelIndexes.publications) {
        labelIndexes.publications = idx;
      }
      return;
    });
    return map(sortedData, (data, idx) => {
      return (
        <div className={classes.slider_width}>
          {idx === Number(labelIndexes.products) &&
            labelIndexes.products !== null && (
              <Text
                size={20}
                color={color.primary_palette.franklin_purple}
                family="avenir_bold"
                transform="uppercase"
                className={classes.prodPubTxtAlign1}
              >
                Products
              </Text>
            )}
          {(labelIndexes.products == null
            ? idx === Number(labelIndexes.publications - 1)
            : idx === Number(labelIndexes.publications) &&
              labelIndexes.publications !== null) && (
            <Text
              size={20}
              color={color.primary_palette.franklin_purple}
              family="avenir_bold"
              transform="uppercase"
              className={classes.prodPubTxtAlign2}
            >
              Publications
            </Text>
          )}
          {Number(labelIndexes.publications) === 0 &&
            labelIndexes.publications !== null && (
              <Text
                size={20}
                color={color.primary_palette.franklin_purple}
                family="avenir_bold"
                transform="uppercase"
                className={classes.prodPubTxtAlign1}
              >
                Publications
              </Text>
            )}
          {idx !== Number(labelIndexes.products) &&
            (labelIndexes.products == null
              ? idx !== Number(labelIndexes.publications - 1)
              : idx !== Number(labelIndexes.publications)) && (
              <div style={{ minHeight: "29px" }}> </div>
            )}
          {data.referenceUri ? (
            <a
              href={data.referenceUri && getUrl(data.referenceUri)}
              target="_blank"
            >
              <img
                src={get(data, "imageUri", "/assets/images/engraved-pilar.png")}
                className={`${classes.sliderImgStyle} ${classes.cursor}`}
                alt=""
              />
            </a>
          ) : (
            <img
              src={get(data, "imageUri", "/assets/images/engraved-pilar.png")}
              className={`${classes.sliderImgStyle} ${classes.cursor}`}
              onClick={!isPublicMode && redirectToProdView}
              alt=""
            />
          )}
          <Text
            className={`${classes.textCenter} ${classes.sliderTextWrap} hover_text`}
            size={14}
            color={color.primary_palette.black}
            family="gillsans_sb"
            transform="capitalize"
          >
            {get(
              data,
              "title",
              strings.portfolio_userView.titles.products_des1
            )}
          </Text>
          <Text
            className={`${classes.textCenter} ${classes.sliderTextWrap} ${classes.subTxtHover}`}
            size={14}
            color={color.primary_palette.black}
            family="gillsans_r"
          >
            {get(data, "subTitle")}
          </Text>
        </div>
      );
    });
  };

  const renderInstaSlides = () => {
    return map(InstafeedsData, (data) => {
      return (
        <div className={classes.slider_width}>
          <img
            src={get(data, "media_url", "/assets/images/engraved-pilar.png")}
            className={classes.instaSliderImgStyle}
            alt=""
          />
        </div>
      );
    });
  };

  const handleDropdownSelect =
    (name, value, regionObj, portifolioObj) => (e) => {
      e.preventDefault();
      e.stopPropagation();
      if (regionObj) {
        regionObj.location = value;
        setValues({ ...values });
        return;
      }
      if (name === "region" || name === "category") {
        portifolioObj[name] = value;
      }
      setValues({ ...values });
    };

  const vignettes = [
    "/assets/images/vig1.PNG",
    "/assets/images/vig2.PNG",
    "/assets/images/vig3.PNG",
    "/assets/images/vig4.PNG",
    "/assets/images/vig6.PNG",
    "/assets/images/vig7.PNG",
    "/assets/images/vig9.PNG",
    "/assets/images/vig10.PNG",
    "/assets/images/vig11.PNG",
    "/assets/images/vig12.PNG",
  ];

  const handleFeedbackModal = () => {
    setValues({
      ...values,
      toggleFeedbackModal: !toggleFeedbackModal,
    });
  };

  const flagshipSequences_test = [];
  flagshipSequences_test[6] = "a";
  const flagshipProjectData = returnFlagShipData();
  const flagshipProjectDataPublic = returnFlagShipDataPublic();

  return (
    <div
      className={
        !disableEdit
          ? `${classes.borderTopGray} ${classes.width100}`
          : classes.width100
      }
    >
      <Row className={classes.userView}>
        {!isPublic ? (
          <Row className={classes.topHeaderBlock}>
            <Text
              size={14}
              color={color.primary_palette.black}
              family="avenir_bold"
              transform="uppercase"
              className={`${classes.txtAlignTop} ${classes.hover_location}`}
              onClick={toggleMarketModal}
            >
              <span className="txt_green">
                {strings.portfolio_userView.titles.displayed_where_is}
              </span>
              <Location className={`${classes.markerAlign} hover_icon`} />
              <div className={classes.ellipses}>
                {portfolioData?.displayedAt?.map((display, idx) => {
                  return (
                    <span
                      className={
                        display.isVisibleToPublic
                          ? classes.regionsAlignPublic
                          : classes.regionsAlignNotPublic
                      }
                    >
                      {display.region}&nbsp;
                      {get(portfolioData, "displayedAt.length") > idx + 1 && (
                        <>•&nbsp;</>
                      )}
                    </span>
                  );
                })}
              </div>
              <div className={`${classes.hover_content} hover_display`}>
                <div className="triangle"></div>
                <Text
                  size={10}
                  color={color.primary_palette.black}
                  family="avenir_roman"
                  className={classes.initial_txt}
                >
                  The Cities and Categories where you want this Portfolio to be
                  displayed
                </Text>
              </div>
            </Text>
            <Row
              className={`${classes.flex_grow} ${classes.positionRelative} `}
            >
              <Row className={classes.envolopStyle}>
                {isPortfolioVisible && (
                  <>
                    <Text
                      size={14}
                      color={color.primary_palette.black}
                      family="avenir_bold"
                      transform="uppercase"
                      className={classes.txtAlignTop}
                    >
                      {strings.portfolio_userView.titles.share}
                    </Text>
                    <img
                      src={ENVELOP_ICON}
                      className={classes.envelope_icon}
                      onClick={handleCongratsModal}
                      alt=""
                    />
                  </>
                )}
              </Row>
              <Row
                className={`${classes.envolopStyle} ${classes.txtAlignTop20}`}
              >
                {!disableEdit && (
                  <Row
                    className={`${classes.edit_mode_switch} ${classes.justify_right}`}
                  >
                    <Row
                      justify="space-between"
                      align="center"
                      className={classes.edit_mode_switch}
                    >
                      <Text
                        family="gillsans_sb"
                        size={14}
                        color={color.primary_palette.black}
                        className={classes.editToggleTxt}
                      >
                        {strings.portfolio_userView.titles.edit_view}
                      </Text>
                      <CustomToggleButton
                        onChange={onEditModeChange}
                        checked={isPublicMode}
                        className={classes.toggleStyle}
                      />
                      <Text
                        family="gillsans_sb"
                        size={14}
                        color={color.primary_palette.black}
                        className={classes.editToggleTxt}
                        onClick={onEditModeChange}
                      >
                        {strings.portfolio_userView.titles.public_preview}
                      </Text>
                    </Row>
                  </Row>
                )}
              </Row>
            </Row>
          </Row>
        ) : (
          <Row className={classes.topHeaderBlock}> </Row>
        )}
        <div
          className={`${classes.textCenter} ${classes.position} ${classes.height100}`}
        >
          <Text
            size={50}
            color={color.primary_palette.black}
            family="TrajanPro3"
            className={classes.portfolioNameAlign}
          >
            {get(portfolioData, "name", "Your FR Portfolio is Loading")}
          </Text>
          <img
            src="/assets/images/Beta_Stamp.png"
            className={classes.betaStampIcon}
            alt=""
            onClick={handleFeedbackModal}
          />
        </div>
        <div
          className={isPublicMode ? classes.bgHeightPublic : classes.bgHeight}
        >
          <Row className={classes.cover_bg}>
            {isPublicMode ? (
              <img
                src={get(imageData, "cover.imageUrl") || defaultCover}
                className={classes.cover_img_public}
                alt=""
              />
            ) : (
              <AddImage
                addImageStyleImport={classes.background_image}
                defaultImage={defaultCover}
                className={classes.cover_img}
                type="cover"
                croppedImage={get(imageData, "cover.imageUrl", "")}
                imageUpload={toggleCroppingModal}
                openImageExp={get(values.openImageExp, "cover", false)}
              />
            )}
            {!isPublicMode && (
              <>
                <div className={classes.blk_hover}>
                  <div
                    onMouseLeave={mouseOut("cover")}
                    onMouseEnter={mouseOver("cover")}
                  >
                    {!get(imageData, "cover.imageUrl", "") && (
                      <Row className={classes.textStartHere}>
                        <Text
                          size={15}
                          family="avenir_black_r"
                          color={color.primary_palette.christmas_red}
                          transform="uppercase"
                          className={classes.startTxt}
                        >
                          START HERE
                        </Text>
                        <img src="/assets/icons/Right Red Arrow.svg" />
                      </Row>
                    )}
                    <Text
                      size={20}
                      family="avenir_sb"
                      color={color.primary_palette.franklin_purple}
                      transform="uppercase"
                      className={`${classes.coverPicTitleAlign} ${classes.supportTextStyle} ${classes.newHelpTxtAlign}`}
                      onClick={openImageExp("cover")}
                    >
                      {strings.portfolio_userView.titles.cover_pic}
                    </Text>
                  </div>
                  {get(portfolioData, "coverImgUri") ? (
                    <div
                      className={`${classes.iconStylePencil} ${classes.edit_icon_cover} icon_edit`}
                      onClick={openImageExp("cover")}
                    />
                  ) : (
                    <div
                      className={`${classes.add_icon} ${classes.edit_icon_cover} ${classes.iconStyleAdd} icon_plus`}
                      onClick={openImageExp("cover")}
                      onMouseLeave={mouseOut("cover")}
                      onMouseEnter={mouseOver("cover")}
                    />
                  )}
                </div>
              </>
            )}
            <Row
              className={
                !isPublicMode || returnTallyValue(portfolioData) >= 1
                  ? classes.left_side
                  : classes.left_side_hide
              }
            >
              <div className={`${classes.textRight} ${classes.snap_hover} `}>
                {!isPublicMode && (
                  <>
                    <div className={classes.blk_hover}>
                      <Text
                        size={20}
                        family="avenir_sb"
                        color={color.primary_palette.franklin_purple}
                        transform="uppercase"
                        className={`${classes.snapTitleAlign} ${classes.supportTextStyle} ${classes.newHelpTxtAlign}`}
                        onClick={handleElevatorOpen}
                      >
                        {strings.portfolio_userView.titles.company_snap}
                      </Text>
                      {get(portfolioData, "elevatorPitch.snapshop") ? (
                        <div
                          className={`${classes.iconStylePencil} ${classes.elevatorAlign} icon_edit`}
                          onClick={handleElevatorOpen}
                        />
                      ) : (
                        <div
                          className={`${classes.add_icon} ${classes.elevatorAlign} ${classes.iconStyleAdd} icon_plus`}
                          onClick={handleElevatorOpen}
                        />
                      )}
                    </div>
                  </>
                )}
                <Text
                  size={14}
                  family="avenir_sb"
                  color={
                    get(portfolioData, "elevatorPitch.snapshop")
                      ? color.primary_palette.black
                      : color.secondary_palette.grays.dramatic_gray
                  }
                  className={
                    isPublicMode
                      ? `${classes.snapshopTextCenter}`
                      : `${classes.textCenter} ${classes.margin_top_10}`
                  }
                >
                  {get(
                    portfolioData,
                    "elevatorPitch.snapshop",
                    !isPublicMode
                      ? strings.portfolio_userView.titles.job_des
                      : ""
                  )}
                </Text>
              </div>
              <div>
                <div
                  className={`${classes.profile_spacing} ${classes.profile_hover}`}
                >
                  {isPublicMode ? (
                    get(imageData, "profile.imageUrl", "") && (
                      <img
                        src={
                          get(imageData, "profile.imageUrl", "") ||
                          defaultProfile
                        }
                        className={classes.profile_img_public}
                        alt=""
                      />
                    )
                  ) : (
                    <AddImage
                      defaultImage={defaultProfile}
                      className={classes.profile_img}
                      type="profile"
                      croppedImage={get(imageData, "profile.imageUrl", "")}
                      imageUpload={toggleCroppingModal}
                      openImageExp={get(values.openImageExp, "profile", false)}
                    />
                  )}
                  {!isPublicMode && (
                    <>
                      <div className={classes.blk_hover}>
                        <div
                          onMouseLeave={mouseOut("profile")}
                          onMouseEnter={mouseOver("profile")}
                        >
                          <Text
                            size={20}
                            family="avenir_sb"
                            color={color.primary_palette.franklin_purple}
                            transform="uppercase"
                            className={`${classes.headTitleAlign} ${classes.textCenter} ${classes.newHelpTxtAlign} `}
                            onClick={openImageExp("profile")}
                          >
                            {strings.portfolio_userView.titles.company_head}
                          </Text>
                        </div>
                        {get(portfolioData, "profileImgUri") ? (
                          <div
                            className={`${classes.iconStylePencil} ${classes.edit_icon_profile} icon_edit`}
                            onClick={openImageExp("profile")}
                          />
                        ) : (
                          <div
                            className={`${classes.add_icon} ${classes.edit_icon_profile} ${classes.iconStyleAdd} icon_plus`}
                            onClick={openImageExp("profile")}
                            onMouseLeave={mouseOut("profile")}
                            onMouseEnter={mouseOver("profile")}
                          />
                        )}
                      </div>
                    </>
                  )}
                  {(customerCategory === "GREENS" || frReview === "1") && (
                    <img
                      src="/assets/icons/purple _ribbon.svg"
                      className={classes.ribbon_icon}
                    />
                  )}
                </div>
                <div className={classes.hover_des}>
                  {!isPublicMode && (
                    <>
                      <div className={classes.blk_hover}>
                        {!get(portfolioData, "elevatorPitch.description") && (
                          <Row className={classes.thenDoThisTxt}>
                            <img src="/assets/icons/Left Red Arrow.svg" />
                            <Text
                              size={15}
                              family="avenir_black_r"
                              color={color.primary_palette.christmas_red}
                              transform="uppercase"
                              className={classes.thenDo}
                            >
                              THEN DO THIS
                            </Text>
                          </Row>
                        )}
                        {get(portfolioData, "elevatorPitch.description") ? (
                          <div
                            className={`${classes.iconStylePencil} ${classes.add_icon_Philosophy} icon_edit`}
                            onClick={handleElevatorOpen}
                          />
                        ) : (
                          <div
                            className={`${classes.add_icon} ${classes.add_icon_Philosophy} ${classes.iconStyleAdd} icon_plus`}
                            onClick={handleElevatorOpen}
                          />
                        )}
                        <Text
                          size={20}
                          family="avenir_sb"
                          color={color.primary_palette.franklin_purple}
                          transform="uppercase"
                          className={`${classes.philosophyTitleAlign} ${classes.supportTextStyle} ${classes.newHelpTxtAlign}`}
                          onClick={handleElevatorOpen}
                        >
                          {strings.settings.titles.elevator_pitch}
                        </Text>
                      </div>
                    </>
                  )}
                  <Text
                    size={20}
                    family="avenir_sb"
                    color={color.primary_palette.black}
                    transform="uppercase"
                    className={classes.descriptionDataHeading}
                  >
                    {get(portfolioData, "elevatorPitch.phylosophyTitle")
                      ? get(portfolioData, "elevatorPitch.phylosophyTitle")
                      : !isPublicMode
                      ? strings.portfolio_userView.titles.company_philosophy
                      : ""}
                  </Text>
                  {get(portfolioData, "projects.length", 0) === 0 && (
                    <div className={classes.AddProjectTxt}>
                      <Text
                        size={15}
                        family="avenir_black_r"
                        color={color.primary_palette.christmas_red}
                        transform="uppercase"
                        className={classes.addProjectArrow}
                      >
                        NEXT ADD PROJECTS
                      </Text>
                      <img src="/assets/icons/Down Arrow.svg" />
                    </div>
                  )}
                  {get(portfolioData, "elevatorPitch.description") ? (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: get(
                          portfolioData,
                          "elevatorPitch.description",
                          !isPublicMode
                            ? strings.portfolio_userView.titles
                                .company_philosophy
                            : ""
                        ),
                      }}
                      className={classes.descriptionData}
                    ></div>
                  ) : (
                    !isPublicMode && (
                      <>
                        <div className={classes.descriptionData}>
                          <div className={classes.profile_text}>
                            <div className={classes.company_philosophy}>
                              <Text
                                size={11}
                                family="avenir_light"
                                color={color.portfolio_gray}
                              >
                                {strings.portfolio_userView.titles.des1}
                              </Text>
                              <Text
                                size={11}
                                family="avenir_light"
                                color={color.portfolio_gray}
                              >
                                {strings.portfolio_userView.titles.des2}
                              </Text>
                            </div>
                          </div>
                        </div>
                      </>
                    )
                  )}
                </div>
                <Text
                  size={20}
                  family="avenir_sb"
                  color={color.primary_palette.black}
                  transform="uppercase"
                  className={classes.descriptionDataHeading}
                >
                  {get(portfolioData, "elevatorPitch.expertiseTitle")
                    ? get(portfolioData, "elevatorPitch.expertiseTitle")
                    : !isPublicMode
                    ? strings.portfolio_userView.titles.areas_expertise
                    : ""}
                </Text>
                {get(portfolioData, "elevatorPitch.expertise") ? (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: get(
                        portfolioData,
                        "elevatorPitch.expertise",
                        !isPublicMode
                          ? strings.portfolio_userView.titles.areas_expertise
                          : ""
                      ),
                    }}
                    className={classes.descriptionData}
                  ></div>
                ) : (
                  !isPublicMode && (
                    <div className={classes.descriptionData}>
                      <div className={classes.profile_text}>
                        <div className={classes.company_philosophy}>
                          <Text
                            size={11}
                            family="avenir_light"
                            color={color.portfolio_gray}
                          >
                            {strings.portfolio_userView.titles.des1}
                          </Text>
                        </div>
                      </div>
                    </div>
                  )
                )}
              </div>
            </Row>
            <Row className={classes.right_side}>
              <div
                className={`${classes.relative} ${classes.pencil_hover1} ${classes.right30}`}
              >
                {/* {!isPublicMode && (
                  <div className="pencil_text">
                    <Text
                      size={20}
                      family="avenir_sb"
                      color={color.primary_palette.franklin_purple}
                      transform="uppercase"
                      className={`${classes.businessTxtAlign} ${classes.newHelpTxtAlign} `}
                    >
                      {strings.portfolio_userView.titles.business_card}
                    </Text>
                  </div>
                )} */}

                <CoverBusiness
                  businessCardData={get(
                    displayedAtData,
                    "businessCardData",
                    ""
                  )}
                  isPublicMode={isPublicMode}
                  openEditBusinessCard={openEditBusinessCard}
                />
                {!isPublicMode && (
                  <div className={`${classes.businessHelperTxtAlign}`}>
                    <Text
                      size={20}
                      family="avenir_sb"
                      color={color.primary_palette.franklin_purple}
                      className={`${classes.newHelpTxtHelperAlign}`}
                    >
                      {strings.portfolio_userView.titles.business_helper}&nbsp;
                      <span
                        className={classes.textUnderline}
                        onClick={redirectPortfolio}
                      >
                        {strings.portfolio_userView.titles.helper_settings}
                      </span>
                    </Text>
                  </div>
                )}
                <div
                  className={`${classes.position} ${classes.logoBlockAlign} ${classes.card_hover}`}
                >
                  {!isPublicMode && (
                    <>
                      <Text
                        size={20}
                        family="avenir_sb"
                        color={color.primary_palette.franklin_purple}
                        transform="uppercase"
                        className={`${classes.logoTitleAlign}`}
                      >
                        {strings.portfolio_userView.titles.company_logo_txt}
                      </Text>
                      <div
                        className={`${classes.blk_hover} ${classes.blk_hover_company_logo}`}
                      >
                        {get(portfolioData, "companyLogo") ? (
                          <div
                            className={`${classes.iconStylePencil} ${classes.company_edit} icon_edit`}
                            onClick={openImageExp("logo")}
                          />
                        ) : (
                          <div
                            className={`${classes.add_icon} ${classes.logo_edit} ${classes.iconStyleAdd} icon_plus`}
                            onClick={openImageExp("logo")}
                          />
                        )}
                        <Text
                          size={20}
                          family="avenir_sb"
                          color={color.primary_palette.franklin_purple}
                          transform="uppercase"
                          className={`${classes.logoTitleAlign} ${classes.newHelpTxtAlign}`}
                          onClick={openImageExp("logo")}
                        >
                          {strings.portfolio_userView.titles.company_logo}
                        </Text>
                      </div>
                    </>
                  )}
                  {isPublicMode ? (
                    (get(imageData, "logo.imageUrl", "") ||
                      get(lookUpData, "configData.eaglesPath")) && (
                      <img
                        src={
                          get(
                            imageData,
                            "logo.imageUrl",
                            `${get(lookUpData, "configData.eaglesPath")}/${get(
                              portfolioData,
                              "FRCompanyId"
                            )}.jpg`
                          ) ||
                          `${get(lookUpData, "configData.eaglesPath")}/${get(
                            portfolioData,
                            "FRCompanyId"
                          )}.jpg`
                        }
                        className={classes.company_logo_public}
                        alt=""
                        onError={addDefaultSrc}
                      />
                    )
                  ) : (
                    <AddImage
                      defaultImage={`${get(
                        lookUpData,
                        "configData.eaglesPath"
                      )}/${get(portfolioData, "FRCompanyId")}.jpg`}
                      className={classes.company_logo}
                      type="logo"
                      croppedImage={get(imageData, "logo.imageUrl", "")}
                      imageUpload={toggleCroppingModal}
                      openImageExp={get(values.openImageExp, "logo", false)}
                    />
                  )}
                </div>
              </div>
            </Row>
          </Row>
        </div>
        {(!isPublicMode ||
          (isPublicMode && get(portfolioData, "vignettes.length", 0) > 0)) &&
          get(portfolioData, "projects.length", 0) !== 0 && (
            <Row
              className={`${classes.vignettes} ${classes.vignette_placeholder_hover}`}
            >
              <Text
                size={35}
                family="gillsans_light"
                color={color.primary_palette.black}
                transform="uppercase"
                className={`${classes.textCenter} ${classes.padding_bottom_20}`}
              >
                {strings.portfolio_userView.titles.Vignettes}
                {!isPublicMode &&
                  get(portfolioData, "vignettes.length") > 0 && (
                    <>
                      <div
                        className={`${classes.iconStylePencil} ${classes.edit_icon_vignettes}`}
                        onClick={handleVignettesOpen}
                      />
                    </>
                  )}
              </Text>
              <div
                className={`${classes.margin_top_25} ${classes.vignette_width_1000} add_please_hover`}
              >
                {!isPublicMode &&
                  get(portfolioData, "vignettes.length") === 0 && (
                    <>
                      <div>
                        <Text
                          size={
                            get(portfolioData, "projects.length", 0) > 0
                              ? 20
                              : 12
                          }
                          family="avenir_sb"
                          color={
                            get(portfolioData, "projects.length", 0) > 0
                              ? color.primary_palette.franklin_purple
                              : color.primary_palette.christmas_red
                          }
                          transform="uppercase"
                          className={`${
                            get(portfolioData, "projects.length", 0) > 0
                              ? classes.add_please
                              : classes.add_please_small
                          } add_please`}
                          onClick={handleVignettesOpen}
                        >
                          <AddIcon />
                          {get(portfolioData, "projects.length", 0) > 0
                            ? strings.portfolio_userView.titles.please_add
                            : strings.portfolio_userView.titles
                                .vignette_no_projects}
                        </Text>
                      </div>
                    </>
                  )}
                <Row className={classes.vignettes_flex}>
                  {get(portfolioData, "vignettes.length") > 0 ? (
                    returnSortedVignettes()
                  ) : (
                    <div
                      className={`${classes.vignettePlaceholder} hover_effect`}
                      onClick={handleVignettesOpen}
                    ></div>
                  )}
                </Row>
              </div>
            </Row>
          )}
        {(!isPublicMode ||
          (isPublicMode && get(portfolioData, "projects.length", 0) > 0)) && (
          <Row className={`${classes.flagship_project} ${classes.userView}`}>
            <Text
              size={35}
              family="gillsans_light"
              color={color.primary_palette.black}
              transform="uppercase"
              className={`${classes.textCenter} ${classes.padding_bottom_60}`}
            >
              {strings.portfolio_userView.titles.flagship_project}
            </Text>
            {!isPublicMode && (
              <div className={classes.addProjectBtnDiv}>
                <CustomButton
                  className={classes.edit_btn}
                  onClick={
                    get(portfolioData, "projects.length", 0) > 0
                      ? handleProjectDashboard
                      : redirectToAddProject
                  }
                >
                  {get(portfolioData, "projects.length", 0) > 0
                    ? strings.portfolio_userView.titles.edit_reorder_project
                    : strings.portfolio_userView.titles.add_project}
                </CustomButton>
              </div>
            )}
            {!isPublicMode ? (
              <Row style={{ display: "block" }}>
                {flagshipProjectData.map((project, index) => {
                  const align =
                    (index + 1) % 2 === 0 ? "label_left" : "label_right";
                  return (
                    <FlagShip
                      align={align}
                      project={project}
                      isPublicMode={isPublicMode}
                      idx={index}
                    />
                  );
                })}
              </Row>
            ) : (
              <Row style={{ display: "block" }}>
                {flagshipProjectDataPublic.map((project, index) => {
                  const align =
                    (index + 1) % 2 === 0 ? "label_left" : "label_right";
                  return (
                    <FlagShip
                      align={align}
                      project={project}
                      isPublicMode={isPublicMode}
                      idx={index}
                    />
                  );
                })}
              </Row>
            )}
            {!isPublicMode && get(portfolioData, "projects.length", 0) >= 6 && (
              <div className={classes.add_project_btn}>
                <CustomButton
                  className={classes.add_project}
                  onClick={handleProjectDashboard}
                  isX
                >
                  <AddIcon />
                  {strings.add_project.titles.add_new_project}
                </CustomButton>
              </div>
            )}
          </Row>
        )}
        {(!isPublicMode ||
          (isPublicMode &&
            get(portfolioData, "productsOrPublications.length", 0) > 0)) && (
          <Row className={`${classes.products_block} ${classes.userView}`}>
            {!isPublicMode &&
              get(portfolioData, "productsOrPublications.length") === 0 && (
                <>
                  <div
                    className={`${classes.please_add_text} ${classes.margin_left_80}`}
                  >
                    <div
                      className={`${classes.add_icon} ${classes.add_icon_product} ${classes.padding_right_10}`}
                      onClick={handleEditProductOpen}
                    />
                    <Text
                      size={20}
                      family="avenir_sb"
                      color={color.primary_palette.franklin_purple}
                      transform="uppercase"
                      // className={classes.headTitleAlignRight}
                      className={classes.add_please}
                      onClick={handleEditProductOpen}
                    >
                      <AddIcon />
                      {strings.portfolio_userView.titles.add_some}
                    </Text>
                  </div>
                </>
              )}
            <Text
              size={35}
              family="gillsans_light"
              color={color.primary_palette.black}
              transform="uppercase"
              className={`${classes.textCenter} ${classes.productsPadding}`}
            >
              {strings.portfolio_userView.titles.products}
              {!isPublicMode &&
                get(portfolioData, "productsOrPublications.length") > 0 && (
                  <>
                    <div
                      className={`${classes.iconStylePencil} ${classes.edit_icon_product}  ${classes.pencilAlign}`}
                      onClick={handleEditProductOpen}
                    />
                  </>
                )}
            </Text>
            <div className={`${classes.insta_block}`}>
              {!get(portfolioData, "productsOrPublications.length", 0) > 0 && (
                // <img src={PROD_PLACEHOLDER} alt=""/>
                <>
                  <div
                    className={classes.productsPlaceholder}
                    onClick={handleEditProductOpen}
                  ></div>
                  {/* <Text
                    className={` ${classes.sliderTextWrap}`}
                    size={16}
                    color={color.secondary_palette.grays.shadow_gray}
                    family="avenir_black_r"
                    transform="capitalize"
                  >
                    Robert Couturier: Designing Paradises
                  </Text>
                  <Text
                    className={`${classes.sliderTextWrap}`}
                    size={16}
                    color={color.secondary_palette.grays.shadow_gray}
                    family="gillsans_r"
                  >
                    Hardcover - September 23, 2014
                  </Text> */}
                </>
              )}
              {get(portfolioData, "productsOrPublications.length", 0) > 0 && (
                <div
                  className={returnSliderClass(
                    get(portfolioData, "productsOrPublications.length", 0)
                  )}
                >
                  <div>
                    <Slider
                      slidesToShow={
                        get(portfolioData, "productsOrPublications.length", 0) <
                        4
                          ? get(
                              portfolioData,
                              "productsOrPublications.length",
                              0
                            )
                          : 4
                      }
                    >
                      {renderSlides()}
                    </Slider>

                    {!isPublicMode && (
                      <div className={`${classes.seeAllAlign}`}>
                        <div className={classes.seeLink}>
                          <a onClick={redirectToProdView}>
                            {strings.build.titles.see_all}
                          </a>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          </Row>
        )}
        <div className={classes.socialAlign}>
          <div>
            {get(portfolioData, "socialMediaConnect.instagram", "") ? (
              <img
                src="/assets/icons/Instagram_colored.svg"
                className={`${classes.socialImageStyle} ${classes.cursor}`}
                onClick={redirectToSocial(
                  "instagram",
                  get(portfolioData, "socialMediaConnect.instagram", "")
                )}
                alt=""
              />
            ) : (
              !isPublicMode && (
                <img
                  src="/assets/images/Instagram_Gray.jpg"
                  className={classes.socialImageStyle}
                  alt=""
                />
              )
            )}
            {get(portfolioData, "socialMediaConnect.twitter", "") ? (
              <img
                src="/assets/icons/twitter_colored.svg"
                className={`${classes.socialImageStyle} ${classes.cursor}`}
                onClick={redirectToSocial(
                  "twitter",
                  get(portfolioData, "socialMediaConnect.twitter", "")
                )}
                alt=""
              />
            ) : (
              !isPublicMode && (
                <img
                  src="/assets/images/Twitter_Gray.jpg"
                  className={classes.socialImageStyle}
                  alt=""
                />
              )
            )}

            {get(portfolioData, "socialMediaConnect.facebook", "") ? (
              <img
                src="/assets/icons/Facebook_colored.svg"
                className={`${classes.socialImageStyle} ${classes.cursor}`}
                onClick={redirectToSocial(
                  "facebook",
                  get(portfolioData, "socialMediaConnect.facebook", "")
                )}
                alt=""
              />
            ) : (
              !isPublicMode && (
                <img
                  src="/assets/images/facebook_Gray.jpg"
                  className={classes.socialImageStyle}
                  alt=""
                />
              )
            )}

            {!isPublicMode && (
              <Text
                size={15}
                color={color.primary_palette.franklin_purple}
                family="avenir_black_r"
                className={classes.connect_accounts}
                transform="uppercase"
                onClick={handleEditModal}
              >
                Connect your accounts
              </Text>
            )}
          </div>
        </div>
      </Row>
      <CustomModal
        open={openCroppingModal}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <ChoosePicture
          isProfileCropper={true}
          imageUrl={get(imageData[croppingModalType], "imageUrl", "")}
          type={croppingModalType}
          updateCroppedImage={updateCroppedImage}
          openImageExp={openImageExp}
          onClose={handleClose}
          transparentColor={color.primary_palette.white}
        />
      </CustomModal>
      <CustomModal
        open={openElevatorPitch}
        onClose={handleElevatorClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <ElevatorPitch
          data={get(portfolioData, "elevatorPitch", {})}
          onClose={handleElevatorClose}
        />
      </CustomModal>
      <CustomModal
        open={openImageModal}
        onClose={() => triggerPopupModals("openImageModal")}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <ProfileImage
          onClose={() => triggerPopupModals("openImageModal")}
          updateCroppedOnProfile={updateCroppedImage}
        />
      </CustomModal>
      <CustomModal
        open={openBusinessModal}
        onClose={() => triggerPopupModals("openBusinessModal")}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <BusinessCardModal
          onClose={() => triggerPopupModals("openBusinessModal")}
        />
      </CustomModal>
      <CustomModal
        open={openVignetteModal}
        onClose={() => triggerPopupModals("openVignetteModal")}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <VignetteDashBoard
          onClose={() => triggerPopupModals("openVignetteModal")}
          project={get(portfolioData, "projects", [])}
          sortedVignetteData={get(values, "sortedVignettes", "")}
          onProductOpen={handleProjectDashboard}
        />
      </CustomModal>
      <CustomModal
        open={openBusinessEditModal}
        onClose={() => triggerPopupModals("openBusinessEditModal")}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <BusinessOne
          onClose={() => triggerPopupModals("openBusinessEditModal")}
          businessCard={get(displayedAtData, "businessCardData", "")}
        />
      </CustomModal>
      <CustomModal
        open={openProjModal}
        onClose={() => triggerPopupModals("openProjModal")}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <ProjectDashBoard
          onClose={() => triggerPopupModals("openProjModal")}
          projectData={get(portfolioData, "projects", [])}
        />
      </CustomModal>
      <CustomModal
        open={openProductModal}
        onClose={() => triggerPopupModals("openProductModal")}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <ProductDashBoard
          onClose={() => triggerPopupModals("openProductModal")}
        />
      </CustomModal>
      <CustomModal
        open={openElevatorModal}
        onClose={() => triggerPopupModals("openElevatorModal")}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <ElevatorPitch
          onClose={() => triggerPopupModals("openElevatorModal")}
          data={get(portfolioData, "elevatorPitch", {})}
        />
      </CustomModal>

      <CustomModal
        open={openEditProduct}
        onClose={handleEditProductClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <ProductDashBoard onClose={handleEditProductClose} />
      </CustomModal>
      <CustomModal
        open={openVignettes}
        onClose={handleVignettesClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        {get(portfolioData, "projects", []) ? (
          <VignetteDashBoard
            onClose={handleVignettesClose}
            project={get(portfolioData, "projects", [])}
            sortedVignetteData={get(values, "sortedVignettes", "")}
            onProductOpen={handleProjectDashboard}
          />
        ) : (
          <VignettesAddProject onClose={handleVignettesClose} />
        )}
      </CustomModal>
      <CustomModal
        open={openProjectDashboard}
        onClose={handleProjectDashboard}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <ProjectDashBoard
          onClose={handleProjectDashboard}
          projectData={get(portfolioData, "projects", [])}
        />
      </CustomModal>

      <CustomModal open={openEditModal} onClose={handleEditModal}>
        <SocialLink
          onClose={handleEditModal}
          socialFeedData={get(portfolioData, "socialMediaConnect", {})}
        />
      </CustomModal>
      <CustomModal
        open={openBusinessPopup}
        onClose={triggerBusinessPopup}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <BusinessCardModal onClose={triggerBusinessPopup} />
      </CustomModal>
      <CustomModal
        open={openEditBusinessModal}
        onClose={openEditBusinessCard}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <BusinessOne
          onClose={openEditBusinessCard}
          businessCard={get(displayedAtData, "businessCardData", "")}
        />
      </CustomModal>
      <CustomModal
        open={openCongratsModal}
        onClose={handleCongratsModal}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <CongratsModal onClose={handleCongratsModal} />
      </CustomModal>
      <CustomModal
        open={openInviteModal}
        onClose={closeInviteModal}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <InviteModal onClose={closeInviteModal} />
      </CustomModal>
      <CustomModal
        open={openMarketModal}
        onClose={closeMarketModal}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <MarketsModal
          onClose={closeMarketModal}
          onPortfolioVisibility={onPortfolioVisibility}
          onPortfolioVisibilityAll={onPortfolioVisibilityAll}
          savePortfolio={savePortfolio}
        />
      </CustomModal>
      <PortfolioMoneyModal
        open={showMoneyModal}
        onClose={toggleModal}
        portfolioDataObj={portfolioDataObj}
        customerCategory={customerCategory}
        CompanyBasicData={CompanyBasicData}
        eachRegionData={eachRegionData}
        isAllPortfolioPublic={isAllPortfolioPublic}
      />
      <MoneyModal
        open={showMoneyModalLimbo}
        onClose={toggleModal}
        portfolioDataObj={portfolioDataObj}
        customerCategory={customerCategory}
        CompanyBasicData={CompanyBasicData}
        eachRegionData={eachRegionData}
        isAllPortfolioPublic={isAllPortfolioPublic}
      />
      <CustomModal
        open={toggleFeedbackModal}
        onClose={handleFeedbackModal}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Feedback
          open={toggleFeedbackModal}
          onClose={handleFeedbackModal}
          locationInfo="Portfolio Main Page"
        />
      </CustomModal>
    </div>
  );
}

export default PortfolioUserStyles(PorfolioUserView);
