import { withStyles } from "@material-ui/core/styles";
import tradework_theme, { pxToRem, color } from "../../../utilities/themes";

const styles = withStyles({
  congratsModalStyles: {
    width: pxToRem(800),
    border: `solid ${pxToRem(2)} ${color.primary_palette.free_purple}`,
    backgroundColor: color.secondary_palette.blues.modal_blue,
    margin: "0 auto",
  },
  btnRight: {
    textAlign: "right",
  },
  crossBtn: {
    minWidth: pxToRem(30),
    padding: pxToRem(16),
    "& .MuiSvgIcon-root": {
      fontSize: pxToRem(15),
    },
  },
  textCenter: {
    textAlign: "center",
  },
  invite_Envelope: {
    height: pxToRem(118),
    float: "right",
    position: "relative",
    top: pxToRem(-60),
    right: pxToRem(55),
  },
  padding_top_56: {
    paddingTop: pxToRem(56),
  },
  email_input: {
    width: "100%",
    borderBottom: `solid ${pxToRem(2)} ${
      color.secondary_palette.grays.shadow_gray
    }`,
    marginLeft: pxToRem(9),
    "&:hover": {
      "&:not(.Mui-disabled)": {
        "&:after": {
          display: "none",
        },
        "&:before": {
          display: "none",
        },
      },
    },
    "&:before": {
      borderBottom: 0,
    },
    "& .MuiInputBase-input": {
      paddingBottom: pxToRem(0),
      ...tradework_theme.typography.styles.avenir_light,
      "&::placeholder": {
        // fontStyle: "oblique",
        ...tradework_theme.typography.styles.avenir_light,
        opacity: 1,
      },
    },
  },
  input_width: {
    flexGrow: 1,
    width: "50%",
    paddingRight: pxToRem(30),
    paddingTop: pxToRem(20),
  },
  form_width: {
    margin: "0 auto",
    padding: `${pxToRem(0)} ${pxToRem(40)}`,
    marginTop: pxToRem(20),
  },
  inputsAlignDiv: {
    position: "relative",
    left: pxToRem(27),
    top: pxToRem(45),
  },
  addBtn: {
    marginTop: pxToRem(20),
    "& .MuiButton-label": {
      color: color.primary_palette.franklin_purple,
      ...tradework_theme.typography.styles.avenir_sb,
      textTransform: "none",
      fontSize: pxToRem(16),
    },
    "& .MuiSvgIcon-root": {
      fontSize: pxToRem(16),
      paddingRight: pxToRem(8),
      "& path": {
        "&:nth-child(1)": {
          fill: color.secondary_palette.grays.background_gray,
        },
      },
    },
  },
  invitesBtn: {
    width: pxToRem(138),
    height: pxToRem(27),
    borderRadius: pxToRem(22.3),
    marginBottom: pxToRem(25),
    float: "right",
    marginTop: pxToRem(10),
    marginRight: pxToRem(48),
    border: `solid ${pxToRem(1.8)} ${color.primary_palette.franklin_purple}`,
    backgroundColor: color.primary_palette.white,
    "& .MuiButton-label": {
      color: color.primary_palette.franklin_purple,
      ...tradework_theme.typography.styles.avenir_black_r,
      textTransform: "none",
      fontSize: pxToRem(15),
    },
  },
  invitesBtn_disabled: {
    width: pxToRem(241),
    height: pxToRem(38),
    borderRadius: pxToRem(25),
    marginBottom: pxToRem(30),
    backgroundColor: color.primary_palette.white,
    border: `solid ${pxToRem(2)} ${color.primary_palette.disable_border}`,
    "& .MuiButton-label": {
      ...tradework_theme.typography.styles.avenir_sb,
      fontSize: pxToRem(16),
    },
  },
  textArea: {
    width: "97.7%",
    height: `${pxToRem(86)} !important`,
    resize: "none",
    ...tradework_theme.typography.styles.Helvetica,
    fontSize: pxToRem(15),
    padding: pxToRem(10),
    "&:focus": {
      outline: "none",
    },
    "&::placeholder": {
      color: color.secondary_palette.grays.shadow_gray,
      ...tradework_theme.typography.styles.avenir_light,
      // fontStyle: "oblique",
      opacity: 1,
    },
  },
  input_field1: {
    borderBottom: `${pxToRem(0)} !important`,
    height: `${pxToRem(58)} !important`,
  },
  button_envolop: {
    width: pxToRem(32),
    height: pxToRem(34),
    position: "absolute",
    top: pxToRem(-26),
    right: pxToRem(-10),
  },
  alignBaseline: {
    alignItems: "baseline",
  },
  project_title_input: {
    marginLeft: pxToRem(6),
    pointerEvents: "none",
    "& .MuiInputBase-input": {
      paddingLeft: pxToRem(4),
      fontSize: pxToRem(12),
      ...tradework_theme.typography.styles.avenir_book_r,
      color: color.primary_palette.black,
      "&::placeholder": {
        color: color.secondary_palette.grays.shadow_gray,
        ...tradework_theme.typography.styles.avenir_light,
        // fontStyle: "oblique",
        opacity: 1,
      },
    },
  },
  marinLeft5: {
    marginLeft: pxToRem(5),
  },
  input_field: {
    width: "100%",
    height: pxToRem(32),
    border: `solid ${pxToRem(1)} ${color.secondary_palette.grays.shadow_gray}`,
    fontSize: pxToRem(15),
    backgroundColor: color.primary_palette.white,
    ...tradework_theme.typography.styles.Helvetica,
    "& .MuiInput-underline": {
      height: pxToRem(58),
      "& .MuiInputBase-input": {
        paddingLeft: pxToRem(10),
        ...tradework_theme.typography.styles.NeutraText,
        color: color.primary_palette.black,
        "&::placeholder": {
          color: color.secondary_palette.grays.shadow_gray,
          ...tradework_theme.typography.styles.avenir_light,
          // fontStyle: "oblique",
          opacity: 1,
        },
      },
      "&:before": {
        border: `solid ${pxToRem(0)} ${
          color.secondary_palette.grays.shadow_gray
        }`,
      },
      "&:after": {
        border: `solid ${pxToRem(0)} ${
          color.secondary_palette.grays.shadow_gray
        }`,
      },
      "&:hover": {
        borderBottom: `${pxToRem(0)} !important`,
        "&:not(.Mui-disabled)": {
          "&:after": {
            display: "none",
          },
          "&:before": {
            display: "none",
          },
        },
      },
    },
  },
  padding_bottom_23: {
    paddingBottom: pxToRem(23),
  },
  boxAlign: {
    width: pxToRem(690),
    height: pxToRem(400),
    border: `solid ${pxToRem(1)} ${color.primary_palette.franklin_purple}`,
    backgroundColor: color.primary_palette.white,
    margin: "0 auto",
  },
  newAdminAlign: {
    display: "inline-block",
    cursor: "pointer",
  },
  btnRightAlign: {
    textAlign: "right",
    marginRight: pxToRem(44),
    paddingTop: pxToRem(21),
    "&:hover": {
      "& .addHover_effect": {
        "& ellipse": {
          fill: color.primary_palette.franklin_purple,
        },
      },
    },
  },
  headerTxtAlign: {
    paddingBottom: pxToRem(19),
    textTransform: "uppercase",
    width: pxToRem(550),
    margin: "0 auto",
  },
  plusAlign: {
    cursor: "pointer",
    fontSize: pxToRem(15),
    verticalAlign: "text-bottom",
    paddingRight: pxToRem(4),
  },
  scroll_area_profile: {
    minHeight: `${pxToRem(230)} !important`,
  },
  scroll_area_invite: {
    minHeight: `${pxToRem(270)} !important`,
  },
  successBanner: {
    backgroundImage: `url("/assets/images/banner_img.png")`,
    backgroundSize: "cover",
    height: pxToRem(114),
  },
  input_field_email: {
    width: pxToRem(241),
    marginRight: pxToRem(29),
    display: "inline-block",
  },
  input_field_notes: {
    width: pxToRem(330),
  },
  input_field_notes_message: {
    width: pxToRem(814),
    marginLeft: pxToRem(50),
  },
  checkBoxAlign: {
    marginLeft: pxToRem(50),
    paddingTop: pxToRem(27),
  },
  check_box: {
    marginRight: pxToRem(0),
    "& .MuiTypography-root": {
      ...tradework_theme.typography.styles.avenir_book_r,
      color: color.primary_palette.black,
      fontSize: pxToRem(12),
      marginRight: pxToRem(30),
    },
    "& .MuiButtonBase-root": {
      paddingTop: pxToRem(0),
      paddingBottom: pxToRem(0),
    },
  },
  inviteModalStyles: {
    width: pxToRem(650),
    border: `solid ${pxToRem(1.5)} ${color.primary_palette.free_purple}`,
    backgroundColor: color.secondary_palette.blues.modal_blue,
    margin: "0 auto",
  },
  bgRibbon: {
    backgroundImage: `url("/assets/images/ribbon.png")`,
    backgroundPosition: "center",
    backgroundSize: `${pxToRem(666)} ${pxToRem(90)}`,
    height: pxToRem(80),
  },
  invite_textArea: {
    height: `${pxToRem(58)} !important`,
    width: pxToRem(555),
    padding: pxToRem(10),
    resize: "none",
    ...tradework_theme.typography.styles.Helvetica,
    backgroundColor: color.primary_palette.white,
    fontSize: pxToRem(14),
    outline: "none",
    "&::placeholder": {
      // fontStyle: "oblique",
      color: color.secondary_palette.grays.shadow_gray,
    },
  },
  input_field_width: {
    width: pxToRem(575),
    margin: "0 auto",
    paddingTop: pxToRem(14),
  },
  invite_textField: {
    width: pxToRem(555),
    padding: `${pxToRem(2)} ${pxToRem(10)}`,
    height: pxToRem(24),
    marginTop: pxToRem(-5),
    border: `solid ${pxToRem(0.7)} ${
      color.secondary_palette.grays.shadow_gray
    }`,
    ...tradework_theme.typography.styles.Helvetica,
    fontSize: pxToRem(14),
    backgroundColor: color.primary_palette.white,
    "& .MuiInput-underline": {
      "&:before": {
        display: "none",
      },
      "&:after": {
        display: "none",
      },
    },
    "& .MuiInputBase-input": {
      padding: 0,
      "&::placeholder": {
        // fontStyle: "oblique",
      },
    },
  },
  add_moreBtn: {
    width: pxToRem(138),
    padding: 0,
    height: pxToRem(25),
    backgroundColor: "transparent !important",
    ...tradework_theme.typography.styles.avenir_bold,
    marginTop: pxToRem(12),
    marginRight: pxToRem(10),
    color: `${color.primary_palette.franklin_purple} !important`,
    "& .MuiSvgIcon-root ": {
      fontSize: pxToRem(19),
      paddingTop: pxToRem(5),
      paddingRight: pxToRem(5),
    },
    "& .MuiButton-label": {
      textTransform: "capitalize",
    },
    "&:hover": {
      "& .MuiSvgIcon-root": {
        "& ellipse": {
          fill: color.primary_palette.franklin_purple,
        },
      },
    },
  },
  send_invitesBtn: {
    width: pxToRem(215),
    height: pxToRem(34),
    border: `solid ${pxToRem(1.8)} ${color.primary_palette.franklin_purple}`,
    backgroundColor: color.primary_palette.white,
    fontSize: pxToRem(16),
    ...tradework_theme.typography.styles.avenir_bold,
    color: `${color.primary_palette.franklin_purple} !important`,
    marginBottom: pxToRem(30),
    position: "relative",
  },
  Invite_Envelope_btn: {
    width: pxToRem(32),
    position: "absolute",
    right: pxToRem(-14),
    top: pxToRem(-27),
  },
  llama: {
    width: pxToRem(85),
    height: pxToRem(117),
  },
  copyBlock: {
    marginLeft: pxToRem(54),
    marginTop: pxToRem(10),
    float: "left",
  },
  copyAlign: {
    display: "inline",
    marginLeft: pxToRem(6),
    cursor: "pointer",
  },
  displayInline: {
    display: "inline",
  },
  clipBoardBtn: {
    border: `solid ${pxToRem(2)} ${color.primary_palette.franklin_purple}`,
    color: color.primary_palette.franklin_purple,
    backgroundColor: color.primary_palette.white,
    width: pxToRem(60),
    height: pxToRem(22),
    marginLeft: pxToRem(10),
    borderRadius: pxToRem(4),
    marginRight: pxToRem(4),
    cursor: "pointer",
  },
  messageBoxStyle: {
    border: `solid ${pxToRem(1)} ${color.primary_palette.franklin_purple}`,
    borderRadius: pxToRem(4),
    marginLeft: pxToRem(6),
    padding: `${pxToRem(0)} ${pxToRem(2)}`,
    height: pxToRem(22),
    pointerEvents: "none",
  },
});

export default styles;
