import React from "react";

import publicStyles from "./styles/portfolioUserStyles";
import PublicNav from "../publicNav/publicNav";
import { useParams } from "react-router-dom";

function PorfolioPublicView(props) {
  let tokenString = props.location.search;
  let { frReview, vendorId } = useParams();
  localStorage.setItem("isPublicMode", true);
  localStorage.removeItem("userToken");
  localStorage.removeItem("active");
  localStorage.removeItem("user_data");
  localStorage.removeItem("Review");
  localStorage.removeItem("NewPortfolio");
  localStorage.removeItem("profile");
  localStorage.removeItem("userType");
  localStorage.removeItem("frpage");
  localStorage.removeItem("companyId");
  localStorage.removeItem("companyName");
  // localStorage.removeItem("portfolioId");
  localStorage.removeItem("customerCategory");
  if (tokenString) {
    let res = tokenString.split("?Token=")[1];
    localStorage.setItem("userType", res);
  }
  return <PublicNav hasSlug={true} vendorId={vendorId} frReview={frReview} />;
}

export default publicStyles(PorfolioPublicView);
