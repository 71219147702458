import React from "react";

import RecoverCredentialsStyles from "./styles/recoverCredentialsStyle";
import Text from "../../components/common/ui_kit/text";
import strings from "../../utilities/strings";
import CustomButton from "../../components/navigations/custom_buttons";
import Close_Icon from "../../components/data_display/icons/Close";
import CustomModal from "../../components/inputs/custom_modal";
import { color } from "../../utilities/themes";

function NotThatLink(props) {
  const { classes, dataFromRecover, onClose } = props;

  return (
    <>
      <CustomModal open={true}>
        <div className={classes.recover_credentials}>
          <div className={classes.textRight}>
            <CustomButton className={classes.crossBtn} onClick={onClose} isX>
              <Close_Icon />
            </CustomButton>
          </div>
          <div className={classes.textCenter}>
            <img
              src="/assets/images/recover1.png"
              alt="recover1"
              className={classes.recover_icon}
            />
            <Text
              size={20}
              family="gillsans_sb"
              color={color.primary_palette.franklin_purple}
              className={classes.txtCapital}
            >
              {strings.passwords.titles.notone}
            </Text>
            <Text
              size={15}
              family="gillsans_sb"
              color={color.primary_palette.black}
              className={`${classes.padding_top_8} ${classes.padding_r_l_60}`}
            >
              {strings.passwords.titles.we_dont1}
              {dataFromRecover}
              {strings.passwords.titles.we_dont2}
            </Text>
            <CustomButton onClick={onClose} className={classes.againBtn}>
              {strings.passwords.titles.try_again}
            </CustomButton>
          </div>
        </div>
      </CustomModal>
    </>
  );
}

export default RecoverCredentialsStyles(NotThatLink);
