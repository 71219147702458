import { withStyles } from "@material-ui/core/styles";
import tradework_theme, { pxToRem, color } from "../../../utilities/themes";

const styles = withStyles({
  dashboardWidth: {
    position: "relative",
    width: pxToRem(475),
    border: `solid ${pxToRem(2)} ${color.primary_palette.free_purple}`,
    backgroundColor: color.secondary_palette.blues.modal_blue,
  },
  btnRight: {
    textAlign: "right",
  },
  crossBtn: {
    minWidth: pxToRem(30),
    padding: pxToRem(16),
    "& .MuiSvgIcon-root": {
      fontSize: pxToRem(15),
    },
  },
  textCenter: {
    textAlign: "center",
  },
  edit_icon: {
    fontSize: pxToRem(60),
    position: "absolute",
    top: pxToRem(-35),
  },
  vignettes_flex: {
    justifyContent: "center",
  },
  vignettes_img: {
    width: pxToRem(58),
    height: pxToRem(58),
    marginRight: pxToRem(8),
    "& img": {
      width: "100%",
      height: "100%",
      objectFit: "cover",
    },
  },
  textSpacing: {
    paddingTop: pxToRem(20),
    paddingBottom: pxToRem(25),
  },
  spacing: {
    padding: `${pxToRem(0)} ${pxToRem(35)}`,
  },
  add_projectBtn: {
    marginBottom: pxToRem(30),
    width: pxToRem(237),
    height: pxToRem(35),
    borderRadius: pxToRem(20),
    border: `solid ${pxToRem(2)} ${color.form_colors.blueberry_purple}`,
    backgroundColor: color.primary_palette.white,
    "& .MuiButton-label": {
      ...tradework_theme.typography.styles.avenir_sb,
      fontSize: pxToRem(16),
      color: color.form_colors.royal_purple_1,
    },
  },
});

export default styles;
