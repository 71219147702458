import React from "react";

import { color } from "../../utilities/themes";
import UpdatePasswordStyles from "./styles/updatePasswordStyles";
import Text from "../common/ui_kit/text";
import strings from "../../utilities/strings";
import CustomButton from "../navigations/custom_buttons";

function UpdatePassword(props) {
  const { classes, onClose } = props;

  return (
    <>
      <div className={classes.congratsModalStyles}>
        <div className={classes.txtCenter}>
          <div>
            <img
              alt=""
              src="/assets/images/recover1.png"
              className={classes.recover}
            />
          </div>
          <Text
            size={15}
            color={color.primary_palette.black}
            family="gillsans_sb"
          >
            {strings.modal.titles.upadte_password_1} <br />
            {strings.modal.titles.upadte_password_2}
          </Text>
          <div>
            <CustomButton className={classes.carryBtn} onClick={onClose}>
              Carry On
            </CustomButton>
          </div>
        </div>
      </div>
    </>
  );
}

export default UpdatePasswordStyles(UpdatePassword);
