import React from "react";
import RecoverCredentialsStyles from "./styles/recoverCredentialsStyle";
import Text from "../../components/common/ui_kit/text";
import strings from "../../utilities/strings";
import CustomButton from "../../components/navigations/custom_buttons";
import Close_Icon from "../../components/data_display/icons/Close";
import CustomModal from "../../components/inputs/custom_modal";
import { color } from "../../utilities/themes";
import { useHistory } from "react-router-dom";

function AllSet(props) {
  const { classes } = props;

  const history = useHistory();

  const toggleLoginModal = () => {
    localStorage.removeItem("resetToken");
    localStorage.removeItem("frpage");
    history.push("/frlogin");
  };

  return (
    <>
      <CustomModal open={true}>
        <div className={classes.recover_credentials}>
          <div className={classes.textRight}>
            <CustomButton
              className={classes.crossBtn}
              onClick={() => {
                toggleLoginModal();
              }}
              isX
            >
              <Close_Icon />
            </CustomButton>
          </div>
          <div className={classes.textCenter}>
            <img
              src="/assets/images/recover1.png"
              alt="recover1"
              className={classes.recover_icon}
            />
            <Text
              size={24}
              family="gillsans_sb"
              color={color.primary_palette.black}
              className={classes.txtCapital}
            >
              {strings.passwords.titles.set}
            </Text>
            <CustomButton
              onClick={() => {
                toggleLoginModal();
              }}
              className={classes.sign_inBtn}
            >
              {strings.passwords.titles.sign_in}
            </CustomButton>
          </div>
        </div>
      </CustomModal>
    </>
  );
}

export default RecoverCredentialsStyles(AllSet);
