import { withStyles } from "@material-ui/core/styles";
import tradework_theme, { pxToRem, color } from "../../../utilities/themes";

const styles = withStyles({
  congratsModalStyles: {
    width: pxToRem(800),
    border: `solid ${pxToRem(2)} ${color.primary_palette.free_purple}`,
    backgroundColor: color.secondary_palette.blues.modal_blue,
    margin: "0 auto",
  },
  btnRight: {
    height: pxToRem(30),
    textAlign: "right",
  },
  crossBtn: {
    minWidth: pxToRem(30),
    padding: pxToRem(16),
    "& .MuiSvgIcon-root": {
      fontSize: pxToRem(15),
    },
  },
  textCenter: {
    textAlign: "center",
  },
  invite_Envelope: {
    width: pxToRem(90),
  },
  padding_top_56: {
    paddingTop: pxToRem(56),
  },
  email_input: {
    width: "100%",
    borderBottom: `solid ${pxToRem(2)} ${
      color.secondary_palette.grays.shadow_gray
    }`,
    marginLeft: pxToRem(9),
    "&:hover": {
      "&:not(.Mui-disabled)": {
        "&:after": {
          display: "none",
        },
        "&:before": {
          display: "none",
        },
      },
    },
    "&:before": {
      borderBottom: 0,
    },
    "& .MuiInputBase-input": {
      paddingBottom: pxToRem(0),
      ...tradework_theme.typography.styles.avenir_light,
      "&::placeholder": {
        // fontStyle: "oblique",
        ...tradework_theme.typography.styles.avenir_light,
        opacity: 1,
      },
    },
  },
  input_width: {
    flexGrow: 1,
    width: "50%",
    paddingRight: pxToRem(30),
    paddingTop: pxToRem(20),
  },
  form_width: {
    margin: "0 auto",
    padding: `${pxToRem(0)} ${pxToRem(40)}`,
    marginBottom: pxToRem(30),
  },
  invitesBtn: {
    width: pxToRem(216),
    height: pxToRem(25),
    borderRadius: pxToRem(17),
    marginBottom: pxToRem(30),
    border: `solid ${pxToRem(1)} ${color.primary_palette.franklin_purple}`,
    backgroundColor: color.primary_palette.white,
    padding: 0,
    marginRight: pxToRem(12),
    marginLeft: pxToRem(12),
    "& .MuiButton-label": {
      color: color.form_colors.blueberry_purple,
      ...tradework_theme.typography.styles.gillsans_sb,
      textTransform: "none",
      fontSize: pxToRem(15),
    },
  },
  invitesBtn_disabled: {
    width: pxToRem(241),
    height: pxToRem(38),
    borderRadius: pxToRem(25),
    marginBottom: pxToRem(30),
    backgroundColor: color.primary_palette.white,
    border: `solid ${pxToRem(2)} ${color.primary_palette.disable_border}`,
    "& .MuiButton-label": {
      ...tradework_theme.typography.styles.avenir_sb,
      fontSize: pxToRem(16),
    },
  },
  textArea: {
    width: "97.7%",
    height: `${pxToRem(86)} !important`,
    resize: "none",
    ...tradework_theme.typography.styles.Helvetica,
    fontSize: pxToRem(15),
    padding: pxToRem(10),
    "&:focus": {
      outline: "none",
    },
    "&::placeholder": {
      color: color.secondary_palette.grays.shadow_gray,
      // fontStyle: "oblique",
      ...tradework_theme.typography.styles.avenir_light,
      opacity: 1,
    },
  },
  input_field: {
    width: "100%",
    border: `solid ${pxToRem(1)} ${color.secondary_palette.grays.shadow_gray}`,
    marginTop: pxToRem(-5),
    fontSize: pxToRem(15),
    backgroundColor: color.primary_palette.white,
    ...tradework_theme.typography.styles.Helvetica,

    "& .MuiInput-underline": {
      "& .MuiInputBase-input": {
        paddingLeft: pxToRem(10),

        "&::placeholder": {
          color: color.secondary_palette.grays.shadow_gray,
          // fontStyle: "oblique",
          ...tradework_theme.typography.styles.avenir_light,
          opacity: 1,
        },
      },
      "&:before": {
        border: `solid ${pxToRem(0)} ${
          color.secondary_palette.grays.shadow_gray
        }`,
      },
      "&:after": {
        border: `solid ${pxToRem(0)} ${
          color.secondary_palette.grays.shadow_gray
        }`,
      },
      "&:hover": {
        borderBottom: `${pxToRem(0)} !important`,
        "&:not(.Mui-disabled)": {
          "&:after": {
            display: "none",
          },
          "&:before": {
            display: "none",
          },
        },
      },
    },
  },
  padding_bottom_23: {
    paddingBottom: pxToRem(23),
  },
  boxAlign: {
    height: pxToRem(300),
    overflowY: "auto",
  },
  newAdminAlign: {
    display: "inline-block",
    cursor: "pointer",
  },
  btnRightAlign: {
    textAlign: "right",
    marginRight: pxToRem(26),
    "& .MuiSvgIcon-root": {
      position: "relative",
      top: pxToRem(6),
      right: pxToRem(6),
    },
  },
  plusAlign: {
    cursor: "pointer",
  },
  // market_dropdown: {
  //   width: pxToRem(155),
  //   height: pxToRem(20),
  // },
  category_dropdown: {
    // width: pxToRem(207),
    // height: pxToRem(20),
    "& p": {
      ...tradework_theme.typography.styles.avenir_sb,
      fontSize: pxToRem(15),
      textOverflow: "ellipsis",
      overflow: "hidden",
      whiteSpace: "nowrap",
      textTransform: "capitalize",
    },
  },
  customScrollHeight: {
    minHeight: `${pxToRem(150)} !important`,
    "& li": {
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "normal",
    },
  },
  dropdown: {
    width: pxToRem(265),
    height: pxToRem(20),
    border: `solid ${pxToRem(1)} ${color.secondary_palette.grays.shadow_gray}`,
    backgroundColor: color.primary_palette.white,
    borderRadius: pxToRem(2),
    "& .MuiSvgIcon-root": {
      fontSize: pxToRem(10),
    },
    "& p": {
      fontSize: pxToRem(15),
      ...tradework_theme.typography.styles.avenir_sb,
    },
  },
  margin_left_10: {
    marginLeft: pxToRem(10),
  },
  addBtn: {
    width: pxToRem(59),
    height: pxToRem(20),
    padding: 0,
    border: `solid ${pxToRem(1)} ${color.primary_palette.free_purple}`,
    backgroundColor: color.secondary_palette.grays.btn_gray,
    fontSize: pxToRem(15),
    color: `${color.primary_palette.franklin_purple} !important`,
    ...tradework_theme.typography.styles.avenir_sb,
    borderRadius: pxToRem(5),
    marginLeft: pxToRem(15),
    "& .MuiSvgIcon-root": {
      fontSize: pxToRem(16),
      paddingRight: pxToRem(8),
      "& path": {
        "&:nth-child(1)": {
          fill: color.secondary_palette.grays.background_gray,
        },
      },
    },
  },
  list_data: {
    ...tradework_theme.typography.styles.avenir_sb,
    fontSize: pxToRem(15),
    color: color.primary_palette.black,
  },
  cross_styled_icon: {
    fontSize: pxToRem(8),
    "& path": {
      fill: color.secondary_palette.grays.shadow_gray,
    },
  },
  margin_right_5: {
    marginRight: pxToRem(5),
  },
  list_dropdowm: {
    fontSize: pxToRem(15),
    ...tradework_theme.typography.styles.avenir_sb,
    color: color.primary_palette.black,
  },
  dropdown_ofc: {
    borderRadius: pxToRem(4),
    width: pxToRem(454),
    height: pxToRem(24),
    border: `solid ${pxToRem(1)} ${color.secondary_palette.grays.shadow_gray}`,
    "& .MuiSvgIcon-root": {
      fontSize: pxToRem(15),
      "& path": {
        stroke: color.secondary_palette.grays.shadow_gray,
      },
    },
    "& p": {
      fontSize: pxToRem(15),
      ...tradework_theme.typography.styles.avenir_sb,
    },
  },
  coloredCrossStyle: {
    width: pxToRem(14),
    marginLeft: pxToRem(6),
    marginRight: pxToRem(6),
    position: "relative",
    cursor: "pointer",
    top: pxToRem(0),
    "& path": {
      fill: color.secondary_palette.grays.shadow_gray,
    },
  },
  alignDropdown: {
    display: "inline-block",
  },
  which_office: {
    width: pxToRem(149),
    borderRadius: pxToRem(2),
    height: pxToRem(20),
    marginLeft: pxToRem(8),
    position: "relative",
    bottom: pxToRem(2),
    "& .MuiSvgIcon-root": {
      fontSize: pxToRem(10),
    },
    "& p": {
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      ...tradework_theme.typography.styles.avenir_sb,
      fontSize: pxToRem(14),
    },
  },
  trash_icon: {
    fontSize: pxToRem(11),
    paddingLeft: pxToRem(10),
    cursor: "pointer",
    "& path": {
      fill: color.secondary_palette.grays.shadow_gray,
    },
  },
  topHeaderBlock: {
    width: "50%",
    position: "absolute",
    top: pxToRem(0),
    right: pxToRem(0),
  },
  regionBlock: {
    width: "100%",
    // display: "inline-block",
    padding: `${pxToRem(10)} ${pxToRem(43)}`,
    // borderRight: `solid ${pxToRem(1)} ${
    //   color.secondary_palette.grays.shadow_gray
    // }`,
  },
  centerContent: {
    textAlign: "center",
    // marginLeft: pxToRem(110),
    marginBottom: pxToRem(39),
    justifyContent: "center",
  },
  positionRelative: {
    position: "relative",
  },
  gary_data: {
    color: color.secondary_palette.grays.medium_gray,
  },
  publicTxtStyle: {
    textDecoration: "underline",
    cursor: "pointer",
    display: "inline-block",
    textTransform: "uppercase",
  },
  displayInline: {
    display: "inline-block",
  },
  portfolio_radio: {
    display: "inline-block",
  },
  width140: {
    width: pxToRem(140),
  },
  width210: {
    width: pxToRem(210),
  },
  width170: {
    width: pxToRem(170),
  },
  width180: {
    width: pxToRem(180),
  },
  verticalTopAlign: {
    verticalAlign: "text-top",
  },
  listNone: {
    listStyleType: "none",
    paddingLeft: 0,
    // textAlign: "center",
  },
  width110: {
    width: pxToRem(110),
  },
  txtAlignTop: {
    marginBottom: pxToRem(24),
  },
  pointer_cursor: {
    cursor: "pointer",
  },
  bottomAlign: {
    marginBottom: pxToRem(7),
  },
  publicGreen: {
    color: color.form_colors.sucess_color,
  },
  notPublicBlue: {
    color: color.secondary_palette.blues.click_blue,
    "&:hover": {
      color: color.form_colors.sucess_word_color,
    },
  },
  flex_grow: {
    flexGrow: 1,
  },
  txtRight: {
    textAlign: "right",
  },
  marginTop6: {
    marginTop: pxToRem(6),
  },
  marginLeft4: {
    marginLeft: pxToRem(4),
  },
  colorPurple: {
    color: color.primary_palette.highlight_purple,
  },
  customScrollHeightCategory: {
    minHeight: `${pxToRem(330)} !important`,
    "& li": {
      overflow: "hidden",
      whiteSpace: "normal",
      textOverflow: "ellipsis",
    },
  },
  textCapital: {
    textTransform: "uppercase",
  },
  marginBottom8: {
    marginBottom: pxToRem(8),
  },
  alignTop: {
    display: "flex",
    alignItems: "flex-start",
  },
});

export default styles;
