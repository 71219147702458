import React, { useState, useEffect } from "react";
import { sortableHandle } from "react-sortable-hoc";
import { makeStyles } from "@material-ui/core";
import PropTypes from "prop-types";
import { Row } from ".";
import { pxToRem, color } from "../../../utilities/themes";

const useStyles = makeStyles({
  container: {
    boxShadow: `${pxToRem(2)} ${pxToRem(2)} ${pxToRem(
      2
    )} 0 rgba(0, 0, 0, 0.26)`,
    border: `solid ${pxToRem(1)} ${color.primary_palette.franklin_purple}`,
    backgroundColor: color.alpine_goat,
    padding: `${pxToRem(2)} 0`,
    marginBottom: pxToRem(12),
  },
  image: {
    width: pxToRem(25),
    height: pxToRem(25),
    marginLeft: pxToRem(10),
  },
  text: {
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
    width: pxToRem(380),
    marginLeft: pxToRem(10),
  },
  icon: {
    cursor: "pointer",
    marginRight: pxToRem(10),
    width: pxToRem(20),
    height: pxToRem(20),
  },
});

const DragHandle = sortableHandle(({ classes }) => (
  <img
    src="/assets/icons/burger.svg"
    className={classes.icon}
    style={{ cursor: "move" }}
    alt=""
  />
));

function Tile({
  image,
  text,
  children,
  open,
  onDelete,
  isNew,
  className,
  style,
  tilesCount,
  onExpandTile,
  type,
  index,
  tileRef,
}) {
  const [expand, setExpand] = useState(open || isNew);
  const classes = useStyles();

  useEffect(() => {
    setExpand(open || isNew);
  }, [open, isNew]);

  const toggle = () => {
    onExpandTile && onExpandTile(index, !expand);
    setExpand(!expand);
  };

  return (
    <div
      ref={tileRef}
      className={`${classes.container} ${className}`}
      style={{ ...(expand && { minHeight: pxToRem(100) }), ...style }}
    >
      <Row align="center">
        {/* show image, headline text and add icon when it's not expanded */}
        {!expand && (
          <>
            {image && <img src={image} className={classes.image} alt="" />}
            {text && <div className={classes.text}>{text}</div>}
            {/* {tilesCount > 1 && ( */}
            <img
              src="/assets/icons/add.svg"
              style={{ marginLeft: "auto" }}
              className={classes.icon}
              onClick={toggle}
              alt=""
            />
            {/* )} */}
          </>
        )}
        {/* show both minus, delete icons when expanded and it's not new tile */}
        {expand && !isNew && (
          <>
            <img
              src="/assets/icons/minus_circle.svg"
              style={{ marginLeft: "auto" }}
              className={classes.icon}
              onClick={toggle}
              alt=""
            />
            {tilesCount > 1 && (
              <img
                src="/assets/icons/Trash_Can.svg"
                className={classes.icon}
                onClick={onDelete}
                alt=""
              />
            )}
          </>
        )}
        {/* show only delete icon when it's not expanded */}
        {!expand && (
          <img
            src="/assets/icons/Trash_Can.svg"
            className={classes.icon}
            onClick={onDelete}
            alt=""
          />
        )}
        {/* show close icon when it's new tile */}
        {isNew && (
          <img
            src="/assets/icons/close.svg"
            alt=""
            style={{
              marginLeft: "auto",
              height: "14px",
              marginRight: "6px",
              marginTop: "6px",
            }}
            className={classes.icon}
            onClick={onDelete}
          />
        )}
        {/* show burger icon when it's not expanded */}
        {!expand && tilesCount > 1 && <DragHandle classes={classes} />}
      </Row>
      <div style={{ display: expand ? "unset" : "none" }}>{children}</div>
    </div>
  );
}

Tile.propTypes = {
  image: PropTypes.string,
  text: PropTypes.node,
  children: PropTypes.arrayOf(PropTypes.object),
  open: PropTypes.bool,
  onDelete: PropTypes.func,
  isNew: PropTypes.bool,
  className: PropTypes.string,
  style: PropTypes.object,
  tilesCount: PropTypes.number,
};

Tile.defaultProps = {
  open: false,
  isNew: false,
  className: "",
  tilesCount: 1,
};

export default Tile;
