import { TOKEN_KEY } from "../../constants";

const RequestInterceptor = (reqConfig) => {
  try {
    let token = localStorage.getItem(TOKEN_KEY);
    let inpersonateToken = localStorage.getItem("inpersonate_token");
    // let username = localStorage.getItem("username");
    // const viewType = localStorage.getItem("viewType");
    // if (viewType === "public") {
    //   reqConfig.headers = {
    //     ...reqConfig.headers,
    //     username,
    //   };
    // } else {
    reqConfig.headers = {
      ...reqConfig.headers,
      "x-auth-token": inpersonateToken || token,
    };
    // }

    return reqConfig;
  } catch (error) {
    return Promise.reject(error);
  }
};

export { RequestInterceptor };
