import { withStyles } from "@material-ui/core/styles";
import tradework_theme, { pxToRem, color } from "../../../utilities/themes";

const styles = withStyles({
  delete_company_width: {
    width: pxToRem(450),
    border: `solid ${pxToRem(2)} ${color.primary_palette.franklin_purple}`,
    backgroundColor: color.secondary_palette.blues.modal_blue,
    padding: pxToRem(16),
    position: "relative",
    textAlign: "center",
    "&:focus": {
      outline: "none !important",
    },
  },
  txtRight: {
    textAlign: "right",
  },
  cross_icon: {
    fontSize: pxToRem(15),
    cursor: "pointer",
    "& path": {
      stroke: color.primary_palette.black,
    },
  },
  txtCenter: {
    textAlign: "center",
  },
  truck: {
    width: pxToRem(100),
  },
  padding_30: {
    padding: `${pxToRem(0)} ${pxToRem(30)}`,
  },
  dropDown_blk: {
    textAlign: "left",
    paddingTop: pxToRem(15),
  },
  bold_text: {
    ...tradework_theme.typography.styles.avenir_bold,
    color: color.primary_palette.black,
  },
  dropDown: {
    ...tradework_theme.typography.styles.avenir_light,
    color: color.primary_palette.black,
    fontSize: pxToRem(14),
  },
  choose_number: {
    height: pxToRem(20),
    borderRadius: pxToRem(3),
    border: `${pxToRem(1)} solid ${color.secondary_palette.grays.shadow_gray}`,
    "& .MuiSvgIcon-root": {
      fontSize: pxToRem(14),
    },
    "& p": {
      paddingLeft: pxToRem(18),
    },
  },
  carryBtn: {
    width: pxToRem(150),
    height: pxToRem(25),
    padding: 0,
    fontSize: pxToRem(15),
    color: `${color.primary_palette.franklin_purple} !important`,
    backgroundColor: color.primary_palette.white,
    border: `solid ${pxToRem(1)} ${color.primary_palette.free_purple}`,
    borderRadius: pxToRem(15),
    marginTop: pxToRem(15),
    marginBottom: pxToRem(15),
  },
  alignMarketName: {
    marginBottom: pxToRem(10),
    marginTop: pxToRem(10),
  },
});

export default styles;
