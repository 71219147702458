import { withStyles } from "@material-ui/core/styles";
import tradework_theme, { pxToRem, color } from "../../../utilities/themes";

const styles = withStyles({
  upperText: {
    "& .MuiStepLabel-label.MuiStepLabel-alternativeLabel": {
      marginTop: `${pxToRem(-50)} !important`,
      // "&:hover":{
      //   color: "red !important"
      // }
    },
    "&:hover": {
      "& .MuiStepLabel-label.MuiStepLabel-alternativeLabel": {
        color: `${color.lemon_yellow} !important`,
      },
      // "& .MuiStepIcon-root": {
      //   border: `solid ${pxToRem(2)} ${color.primary_palette.black}`,
      //   backgroundImage: 'url("assets/icons/pencil.svg")',
      //   width: pxToRem(31),
      //   height: pxToRem(31),
      //   backgroundRepeat: "no-repeat",
      //   backgroundColor: "white",
      //   backgroundSize: "auto",
      //   maxHeight: pxToRem(31),
      //   backgroundPosition: `${pxToRem(6)} ${pxToRem(7)}`,
      //   borderRadius: 0,
      //   position: "relative",
      //   marginTop: pxToRem(-13),
      //   color: "transparent !important",
      //   zIndex: 9,
      //   "& path": {
      //     display: "none !important",
      //   },
      // },
    },
  },
  lowerText: {
    "& .MuiStepLabel-label.MuiStepLabel-alternativeLabel": {
      marginTop: `${pxToRem(7)} !important`,
    },
  },
  companyStepper: {
    position: "relative",
    // paddingTop: pxToRem(30),
    marginTop: pxToRem(94),
    "& .MuiPaper-elevation0": {
      width: "46%",
      marginLeft: pxToRem(10),
      // margin: "0 auto",
    },
    "& .MuiStepper-root": {
      paddingBottom: `${pxToRem(0)} !important`,
    },
    "& .MuiStepConnector-alternativeLabel": {
      left: "calc(-50% + 6px)",
      width: "96.5%",
    },
    "& .MuiStepper-alternativeLabel": {
      minHeight: pxToRem(36),
    },
  },
  lastStep: {
    "& .MuiStepIcon-root": {
      fontSize: `${pxToRem(7)} !important`,
      position: "relative !important",
      color: `${color.primary_palette.franklin_purple} !important`,
      border: `${pxToRem(0)} !important`,
      left: `${pxToRem(-5)} !important`,
      top: `${pxToRem(4)} !important`,
    },
    "& .MuiStepLabel-labelContainer": {
      position: "relative",
      top: pxToRem(-28),
      left: pxToRem(15),
      opacity: 0.2,
    },
  },
  completed_step: {
    "& .MuiStepIcon-root": {
      color: color.primary_palette.franklin_purple + "!important",
      backgroundColor: color.primary_palette.franklin_purple,
      border: `solid ${pxToRem(1)} ${color.primary_palette.franklin_purple}`,
      borderRadius: "100%",
      "&:hover": {
        border: `solid ${pxToRem(2)} ${color.primary_palette.black}`,
        backgroundImage: 'url("assets/icons/pencil.svg")',
        width: pxToRem(31),
        height: pxToRem(31),
        backgroundRepeat: "no-repeat",
        backgroundColor: "white",
        backgroundSize: "auto",
        maxHeight: pxToRem(31),
        backgroundPosition: `${pxToRem(6)} ${pxToRem(7)}`,
        borderRadius: 0,
        position: "relative",
        marginTop: pxToRem(-13),
        color: "transparent !important",
        zIndex: 9,
        "& path": {
          display: "none !important",
        },
      },
    },
    "&:hover": {
color: "red",
"& .MuiStepIcon-root": {
  border: `solid ${pxToRem(2)} ${color.primary_palette.black}`,
            backgroundImage: 'url("assets/icons/pencil.svg")',
            width: pxToRem(31),
            height: pxToRem(31),
            backgroundRepeat: "no-repeat",
            backgroundColor: "white",
            backgroundSize: "auto",
            maxHeight: pxToRem(31),
            backgroundPosition: `${pxToRem(6)} ${pxToRem(7)}`,
            borderRadius: 0,
            position: "relative",
            marginTop: pxToRem(-13),
            color: "transparent !important",
            zIndex: 9,
            "& path": {
              display: "none !important",
            }, 
}
    },
  },
  disabled_stp_bc: {
    "& .MuiStepIcon-root": {
      color: color.primary_palette.franklin_purple + "!important",
      border: `solid ${pxToRem(1)} ${color.primary_palette.franklin_purple}`,
      borderRadius: "100%",
    },
  },
  incomplete_step: {
    "& .MuiStepIcon-root": {
      color: color.primary_palette.white + "!important",
      border: `solid ${pxToRem(1)} ${color.primary_palette.franklin_purple}`,
      borderRadius: "100%",
    },
  },
  main_step: {
    "& .MuiStepIcon-text": {
      display: "none",
    },
    "& .MuiStepButton-root": {
      paddingTop: pxToRem(35),
      // color: "red",
      // "&:hover": {
      //   "& .MuiTypography-root": {
      //     color: "red",
      //   }
      // }
    },
    "& .Mui-disabled": {
      "& .MuiStepIcon-root": {
        border: `solid ${pxToRem(1)} ${color.primary_palette.franklin_purple}`,
        borderRadius: "100%",
      },
    },
    "& .MuiStepLabel-label": {
      color: color.primary_palette.franklin_purple,
      ...tradework_theme.typography.styles.avenir_sb,
      fontSize: pxToRem(12),
    },
    "& .MuiStepIcon-root": {
      fontSize: pxToRem(12),
    },
    "& .MuiStepLabel-iconContainer": {
      paddingRight: `${pxToRem(0)} !important`,
      marginTop: pxToRem(6),
      maxHeight: pxToRem(14),
      minHeight: pxToRem(14),
    },
    "& .MuiButtonBase-root.Mui-disabled": {
      cursor: "pointer !important",
    },
    "& .MuiStepIcon-active": {
      color: color.primary_palette.franklin_purple,
      maxHeight: pxToRem(14),
      backgroundColor: color.primary_palette.franklin_purple,
      borderRadius: "50%",
    },
    "& .MuiStepConnector-line": {
      width: "96%",
      background: color.primary_palette.black,
      height: pxToRem(1),
      marginTop: pxToRem(10),
    },
    "& .MuiStepIcon-completed": {
      color: color.primary_palette.franklin_purple,
      backgroundColor: color.primary_palette.franklin_purple,
      borderRadius: "50%",
      "&:hover": {
        border: `solid ${pxToRem(2)} ${color.primary_palette.black}`,
        backgroundImage: ' url("assets/icons/pencil.svg")',
        width: pxToRem(31),
        height: pxToRem(31),
        backgroundRepeat: "no-repeat",
        backgroundColor: "white",
        backgroundSize: "auto",
        maxHeight: pxToRem(31),
        backgroundPosition: pxToRem(7),
        borderRadius: 0,
        position: "relative",
        marginTop: pxToRem(-13),
        zIndex: 9,
        "& path": {
          display: "none !important",
        },
      },
    },
  },
  pencil_hover: {
    backgroundColor: color.primary_palette.white,
    border: `solid ${pxToRem(2)} ${color.primary_palette.black}`,
    width: pxToRem(31),
    height: pxToRem(31),
    margin: "0 auto",
    position: "relative",
    zIndex: 9,
    "& img": {
      marginTop: pxToRem(6),
      objectFit: "cover",
    },
  },
});

export default styles;
