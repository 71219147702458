import React from "react";
import { get } from "lodash";

import GridStyles from "./styles/gridStyles";
import Row from "../common/ui_kit/row";

function GridTwelve(props) {
  const { classes, imageData, imageSize } = props;

  return (
    <>
      <div className={`${classes.publicviewProject} ${classes.width_740}`}>
        <Row
          className={
            imageSize ? classes.grid_main_spacing_m : classes.grid_main_spacing
          }
        >
          <div>
            <div>
              <img
                alt=""
                className={imageSize ? classes.grid_12_1_m : classes.grid_12_1}
                src={
                  get(imageData[0], "imageUri", "") || "/assets/images/gray.PNG"
                }
              />
            </div>
            <div>
              <img
                alt=""
                className={imageSize ? classes.grid_12_2_m : classes.grid_12_2}
                src={
                  get(imageData[4], "imageUri", "") || "/assets/images/gray.PNG"
                }
              />
            </div>
            <div>
              <img
                alt=""
                className={imageSize ? classes.grid_12_3_m : classes.grid_12_3}
                src={
                  get(imageData[8], "imageUri", "") || "/assets/images/gray.PNG"
                }
              />
            </div>
          </div>
          <div>
            <div>
              <img
                alt=""
                className={imageSize ? classes.grid_12_4_m : classes.grid_12_4}
                src={
                  get(imageData[1], "imageUri", "") || "/assets/images/gray.PNG"
                }
              />
            </div>
            <div>
              <img
                alt=""
                className={imageSize ? classes.grid_12_5_m : classes.grid_12_5}
                src={
                  get(imageData[5], "imageUri", "") || "/assets/images/gray.PNG"
                }
              />
            </div>
            <div>
              <img
                alt=""
                className={imageSize ? classes.grid_12_6_m : classes.grid_12_6}
                src={
                  get(imageData[9], "imageUri", "") || "/assets/images/gray.PNG"
                }
              />
            </div>
          </div>
          <Row className={classes.wrap}>
            <Row>
              <div>
                <img
                  alt=""
                  className={
                    imageSize ? classes.grid_12_7_m : classes.grid_12_7
                  }
                  src={
                    get(imageData[2], "imageUri", "") ||
                    "/assets/images/gray.PNG"
                  }
                />
              </div>
              <div>
                <img
                  alt=""
                  className={
                    imageSize ? classes.grid_12_8_m : classes.grid_12_8
                  }
                  src={
                    get(imageData[3], "imageUri", "") ||
                    "/assets/images/gray.PNG"
                  }
                />
              </div>
            </Row>
            <Row>
              <div>
                <img
                  alt=""
                  className={
                    imageSize ? classes.grid_12_9_m : classes.grid_12_9
                  }
                  src={
                    get(imageData[6], "imageUri", "") ||
                    "/assets/images/gray.PNG"
                  }
                />
              </div>
              <div>
                <img
                  alt=""
                  className={
                    imageSize ? classes.grid_12_10_m : classes.grid_12_10
                  }
                  src={
                    get(imageData[7], "imageUri", "") ||
                    "/assets/images/gray.PNG"
                  }
                />
              </div>
            </Row>
            <Row>
              <div>
                <img
                  alt=""
                  className={
                    imageSize ? classes.grid_12_11_m : classes.grid_12_11
                  }
                  src={
                    get(imageData[10], "imageUri", "") ||
                    "/assets/images/gray.PNG"
                  }
                />
              </div>
              <div>
                <img
                  alt=""
                  className={
                    imageSize ? classes.grid_12_12_m : classes.grid_12_12
                  }
                  src={
                    get(imageData[11], "imageUri", "") ||
                    "/assets/images/gray.PNG"
                  }
                />
              </div>
            </Row>
          </Row>
        </Row>
      </div>
    </>
  );
}

export default GridStyles(GridTwelve);
