import React from "react";

import BusinessModalStyles from "./styles/businessModalStyles";
import BusinessCard from "./business_card";

function BusinessCardModal(props) {
  const { onClose } = props;
  return (
    <>
      <BusinessCard onClose={onClose} />
    </>
  );
}

export default BusinessModalStyles(BusinessCardModal);
