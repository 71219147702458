import { withStyles } from "@material-ui/core/styles";
import { pxToRem, color } from "../../../utilities/themes";

const styles = withStyles({
  nav_logo: {
    width: pxToRem(30),
    height: pxToRem(30),
    marginLeft: pxToRem(12),
    borderRadius: "50%",
  },
  portfolioNameHover: {
    cursor: "pointer",
    "&:hover": {
      color: color.portfolio_hover,
    },
  },
  flexCenter: {
    alignItems: "center",
    justifyContent: "flex-end",
    paddingBottom: pxToRem(10),
    position: "relative",
  },
  flexCenterInactive: {
    alignItems: "center",
    justifyContent: "flex-end",
    paddingBottom: pxToRem(10),
    opacity: "0.5",
    position: "relative",
    "&:hover": {
      opacity: 1,
    },
  },
  positionRelative: {
    position: "relative",
  },
  navSpacings: {
    margin: `${pxToRem(0)} ${pxToRem(20)}`,
    borderBottom: `solid ${pxToRem(1)} ${
      color.primary_palette.franklin_purple
    }`,
  },
  navfields: {
    margin: `${pxToRem(0)} ${pxToRem(32)}`,
    marginTop: pxToRem(22),
  },
  subText: {
    fontFamily: "avenir_sb",
    color: color.primary_palette.black,
    fontSize: pxToRem(8),
    textTransform: "capitalize",
  },
  txtRight: {
    textAlign: "right",
  },
  add_companyBtn: {
    color: `${color.primary_palette.franklin_purple} !important`,
    textTransform: "uppercase",
    fontFamily: "gillsans_sb",
    fontSize: pxToRem(15),
    "& .MuiSvgIcon-root": {
      fontSize: pxToRem(20),
      marginLeft: pxToRem(15),
      verticalAlign: "text-bottom",
      "& path": {
        "&:nth-child(1)": {
          fill: color.secondary_palette.grays.background_gray,
        },
      },
    },
    "&:hover": {
      color: `${color.form_colors.sucess_word_color} !important`,
      "& .MuiSvgIcon-root": {
        "& path": {
          "&:nth-child(1)": {
            fill: color.form_colors.sucess_word_color,
          },
        },
      },
    },
  },
  block: {
    display: "block",
    cursor: "pointer",
  },
  activeMark: {
    height: pxToRem(8),
    width: pxToRem(8),
    background: color.primary_palette.black,
    display: "inline-block",
    top: pxToRem(5),
    right: pxToRem(-14),
    position: "absolute",
  },
  blockPort: {
    display: "block",
    cursor: "pointer",
    padding: `${pxToRem(11)} ${pxToRem(0)}`,
  },
  flexCenterBlock: {
    display: "block",
    paddingBottom: pxToRem(16),
    textAlign: "right",
  },
  padding_top_12: {
    paddingTop: pxToRem(12),
  },
  padding_bottom_16: {
    paddingBottom: pxToRem(10),
  },
  margin_sides: {
    margin: `${pxToRem(0)} ${pxToRem(20)}`,
    marginTop: pxToRem(105),
    paddingBottom: pxToRem(50),
  },
  margin_left_153: {
    marginTop: pxToRem(2),
    marginBottom: pxToRem(2),
  },
  border_bottom: {
    borderBottom: `solid ${pxToRem(1)} ${
      color.primary_palette.franklin_purple
    }`,
  },
  border_right: {
    borderRight: `solid ${pxToRem(2)} ${color.primary_palette.franklin_purple}`,
    width: pxToRem(332),
    marginLeft: pxToRem(40),
    position: "relative",
  },
  logout_align: {
    width: pxToRem(16),
    marginLeft: pxToRem(6),
  },
  mainSettingsBlock: {
    marginTop: pxToRem(22),
    marginBottom: pxToRem(24),
  },
  settingsTxtAlign: {
    position: "relative",
    top: pxToRem(10),
  },
  companyImgAlign: {
    marginLeft: pxToRem(18),
  },
  settingsLogoAlign: {
    marginLeft: pxToRem(9),
    width: pxToRem(46),
    height: pxToRem(42),
  },
  headingAlign: {
    marginBottom: pxToRem(20),
    marginTop: pxToRem(94),
    marginLeft: pxToRem(40),
  },
  displayDataBlock: {
    display: "inline-block",
    width: "78%",
  },
  historyDisplayBlock: {
    display: "inline-block",
    width: "76%",
  },
  sideNavAlign: {
    width: "22%",
  },
  cursorPointer: {
    cursor: "pointer",
  },
  padding_left_10: {
    paddingLeft: pxToRem(10),
  },
  displayNone: {
    display: "none",
  },
  hoverTxt: {
    "&:hover": {
      color: color.form_colors.sucess_word_color,
      opacity: 1,
    },
  },
  padding_bottom_22: {
    paddingBottom: pxToRem(22),
  },
  betaStampIcon: {
    height: pxToRem(100),
    position: "absolute",
    right: pxToRem(30),
    // top: pxToRem(-34),
    cursor: "pointer",
  },
});

export default styles;
