import React from "react";
import { get, find } from "lodash";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";

import FlagShipStyles from "./styles/flagShipStyles";
import Row from "../common/ui_kit/row";
import Text from "../common/ui_kit/text";
import strings from "../../utilities/strings";
import { color } from "../../utilities/themes";
import CustomModal from "../inputs/custom_modal";
import DeleteProjectModal from "../add_new_project/delete_project";
import AddIcon from "../data_display/icons/add_default_hover";

import { ProfileActions } from "../../redux/actions";

function FlagShip(props) {
  const { classes, project, isPublicMode, idx } = props;
  const [values, setValues] = React.useState({
    openDeleteModal: false,
  });

  const history = useHistory();
  const dispatch = useDispatch();

  const align = get(props, "align", "label_left");

  const redirectToAddProject = () => {
    history.push({
      pathname: "/AddProjects",
      state: { edit: false },
    });
  };

  const redirectToProjectView = (projectData) => () => {
    history.push({
      pathname: "/ProjectSingleView",
      state: { ...projectData, idx, isPublicMode },
    });
  };

  const editProjectData = (projectId) => () => {
    dispatch(
      ProfileActions.getIndividualProjectData(projectId, () => {
        history.push({
          pathname: "/AddProjects",
          state: { edit: true, index: idx },
        });
      })
    );
  };

  const handleDeleteModal = (projectInfoData) => () => {
    setValues({
      ...values,
      openDeleteModal: !openDeleteModal,
      projectInfo: projectInfoData,
    });
  };

  const deleteProject = (id) => () => {
    dispatch(
      ProfileActions.deleteProject(id, () => {
        dispatch(
          ProfileActions.getPortfolioData(localStorage.getItem("portfolioId"))
        );
      })
    );
    setValues({
      ...values,
      openDeleteModal: false,
    });
  };

  const returnRightSection = () => {
    return (
      <Row className={classes.flagship_text}>
        <Row className={classes.description_text}>
          <Text
            className={
              project.isAdd
                ? classes.subTextCenterOpacity
                : classes.subTextCenter
            }
            transform="uppercase"
            onClick={
              !isPublicMode && project.isAdd
                ? redirectToAddProject
                : redirectToProjectView(project)
            }
          >
            <div className={classes.hr_line1}></div>
            {get(project, "subtitle", "Classic Revival")}
          </Text>
          <div className={classes.Tropical}>
            <Text
              className={
                project.isAdd
                  ? classes.mainText_bg_opacity
                  : classes.mainText_bg_right
              }
              transform="uppercase"
              onClick={
                !isPublicMode && project.isAdd
                  ? redirectToAddProject
                  : redirectToProjectView(project)
              }
            >
              {get(project, "title", "Tropical House By Sea")}
            </Text>
          </div>
          <Row
            className={
              project.isAdd ? classes.view_block_opacity : classes.view_block
            }
            onClick={!project.isAdd && redirectToProjectView(project)}
          >
            <div className={classes.image_br}>
              <Text
                size={10}
                color={color.primary_palette.black}
                family="Oswald_Light"
                transform="uppercase"
              >
                Images
              </Text>
              <Text
                size={45}
                color={color.primary_palette.black}
                family="ProximaNova_Thin"
                className={classes.textAlignTop}
              >
                {get(project, "projectImages.length", "0")}
              </Text>
            </div>
            <div className={classes.borderView}></div>
            <div className={classes.viewText}>
              <Text
                transform="uppercase"
                size={23}
                color={color.primary_palette.black}
                family="ProximaNova_Thin"
                className={!project.isAdd && classes.cursor}
              >
                View
              </Text>
            </div>
          </Row>
        </Row>
      </Row>
    );
  };

  const returnLeftSection = () => {
    return (
      <Row className={classes.flagship_text}>
        <Row className={classes.description_text}>
          <div className={classes.subTextCenter1_main}>
            <Text
              className={
                project.isAdd
                  ? classes.subTextCenter1Opacity
                  : classes.subTextCenter1
              }
              transform="uppercase"
              onClick={
                !isPublicMode && project.isAdd
                  ? redirectToAddProject
                  : redirectToProjectView(project)
              }
            >
              <div className={classes.hr_line}></div>
              {get(project, "subtitle", "Classic Revival")}{" "}
            </Text>
          </div>
          <div className={classes.TropicalLeft}>
            <Text
              className={
                project.isAdd ? classes.mainText_bg_opac : classes.mainText_bg
              }
              transform="uppercase"
              onClick={
                !isPublicMode && project.isAdd
                  ? redirectToAddProject
                  : redirectToProjectView(project)
              }
            >
              {get(project, "title", "Tropical House By Sea")}
            </Text>
          </div>
          <Row
            className={
              project.isAdd ? classes.view_block_opacity : classes.view_block
            }
            onClick={!project.isAdd && redirectToProjectView(project)}
          >
            <div className={classes.image_br}>
              <Text
                size={10}
                color={color.primary_palette.black}
                family="Oswald_Light"
                transform="uppercase"
              >
                Images
              </Text>
              <Text
                size={45}
                color={color.primary_palette.black}
                family="ProximaNova_Thin"
                className={classes.textAlignTop}
              >
                {get(project, "projectImages.length", "0")}
              </Text>
            </div>
            <div className={classes.borderView}></div>
            <div className={classes.viewText}>
              <Text
                transform="uppercase"
                size={23}
                color={color.primary_palette.black}
                family="ProximaNova_Thin"
                className={!project.isAdd && classes.cursor}
              >
                View
              </Text>
            </div>
          </Row>
        </Row>
      </Row>
    );
  };

  const { projectInfo, openDeleteModal } = values;
  return (
    <>
      <Row
        className={
          project.isAdd
            ? classes.flagShip_block_opacity
            : classes.flagShip_block
        }
      >
        {align === "label_left" && returnLeftSection()}
        <Row className={`${classes.position} ${classes.hover_img}`}>
          <Row
            className={
              isPublicMode
                ? `${classes.flagship_img}`
                : `${classes.flagship_img} ${classes.cursor}`
            }
          >
            <img
              alt=""
              src={get(project, "coverImageUri")}
              className={
                project.isAdd &&
                `${classes.block_opacity} ${project.className} `
              }
              onClick={
                !isPublicMode && project.isAdd
                  ? redirectToAddProject
                  : redirectToProjectView(project)
              }
            />
          </Row>
          {align === "label_right" && returnRightSection()}
          {!isPublicMode && (
            <Row>
              {project.isAdd ? (
                <>
                  <Text
                    size={18}
                    family="avenir_bold"
                    color={color.primary_palette.franklin_purple}
                    transform="uppercase"
                    className={
                      align === "label_right"
                        ? `${classes.headTitleAlignLeft} hover_icon`
                        : `${classes.headTitleAlignRight} hover_icon`
                    }
                    onClick={redirectToAddProject}
                  >
                    <AddIcon />
                    {strings.portfolio_userView.titles.add}
                    <br />
                    <span className={classes.addTxtAlign}>
                      {strings.portfolio_userView.titles.add_proj_title}{" "}
                    </span>
                    {project.isAdd && idx === 0 && (
                      <img
                        src="/assets/icons/Down Arrow.svg"
                        className={classes.arrowUpStyle}
                      />
                    )}
                  </Text>
                  {/* <div
                    className={
                      align === "label_right"
                        ? `${classes.add_icon_left}  ${classes.iconStyleAdd}`
                        : `${classes.add_icon_right}  ${classes.iconStyleAdd}`
                    }
                    onMouseUp={redirectToAddProject}
                  /> */}
                </>
              ) : (
                <>
                  <div
                    className={`${classes.iconStylePencil} ${
                      align === "label_right"
                        ? classes.edit_icon_left
                        : classes.edit_icon_right
                    }`}
                    onMouseUp={editProjectData(project._id)}
                  />
                  <img
                    alt=""
                    src="/assets/images/Trash Can.png"
                    className={
                      align === "label_right"
                        ? classes.trash_icon_left
                        : classes.trash_icon_right
                    }
                    onMouseUp={handleDeleteModal(project)}
                  />
                </>
              )}
            </Row>
          )}
        </Row>
      </Row>
      <CustomModal open={openDeleteModal} onClose={handleDeleteModal("")}>
        <DeleteProjectModal
          onClose={handleDeleteModal("")}
          projectInfo={projectInfo}
          deleteProject={deleteProject}
        />
      </CustomModal>
    </>
  );
}

export default FlagShipStyles(FlagShip);
