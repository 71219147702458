import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { get, map } from "lodash";

import MoneyModalStyles from "./styles/moneyModalStyles";
import Row from "../common/ui_kit/row";
import Text from "../common/ui_kit/text";
import strings from "../../utilities/strings";
import Close_Icon from "../data_display/icons/Close";
import CustomButton from "../navigations/custom_buttons";
import CustomTextField from "../inputs/custom_textfields";
import CustomModal from "../inputs/custom_modal";
import CustomCheckbox from "../inputs/custom_checkbox";
import PortfolioModal from "../../components/modals/portfolioCardModal";
import Ribbon from "../../components/data_display/icons/Ribbon";

import { color } from "../../utilities/themes";
import {
  ProfileActions,
  SettingsActions,
  LookUpActions,
} from "../../redux/actions";
// import PaypalButton from "./paypal-btn";
import { MARKET_PRICE, MARKET_DISCOUNT } from "../../constants";
import CustomScrollbars from "../data_display/custom_scroll";

function MoneyModal(props) {
  var prevKey = null;
  const {
    classes,
    onClose,
    open,
    portfolioDataObj,
    customerCategory,
    CompanyBasicData,
    toggleModal,
    eachRegionData,
    isAllPortfolioPublic,
  } = props;

  const [values, setValues] = useState({
    activeSubscription: false,
    cardNumber: "",
    cvc: "",
    expMonthYear: "",
    errorMessage: "",
    zip: "",
    openRateModal: false,
    selectedRegions: [],
    autoPay: true,
    saveInfo: true,
  });
  const portfolioData = useSelector((resData) => resData.Profile.portfolioData);
  const lookUpData = useSelector((resData) => resData.LookUp.LookUpData);

  const dispatch = useDispatch();
  const history = useHistory();
  const {
    activeSubscription,
    expirationDate,
    cardNumber,
    cvc,
    expMonthYear,
    cardName,
    selectedRegions,
    errorMessage,
    zip,
    openRateModal,
    publicDataLength,
    saveInfo,
    autoPay,
  } = values;

  useEffect(() => {
    if (localStorage.getItem("userType")) {
      dispatch(LookUpActions.getLookUpData());
    }
    let updatedData = [];
    if (isAllPortfolioPublic) {
      updatedData = portfolioDataObj?.displayedAt?.filter((each) => {
        return !each.madePublic;
      });
    } else {
      updatedData = [eachRegionData];
    }

    const publicData = portfolioDataObj?.displayedAt?.filter((each) => {
      return each.madePublic;
    });

    setValues({
      ...values,
      displayedAtId: localStorage.getItem("displayedAtId"),
      portfolioId: localStorage.getItem("portfolioId"),
      selectedRegions: updatedData,
      publicDataLength: get(publicData, "length"),
    });
    return;
  }, [portfolioData, portfolioDataObj, eachRegionData, isAllPortfolioPublic]);

  // validation handles disabling submit button
  const disableSubmit = () => {
    if (
      cardNumber.length > 16 ||
      !expMonthYear ||
      !cvc ||
      cvc.length > 4 ||
      !cardName
    ) {
      return true;
    }
    return false;
  };

  // format expiry month and year
  const formatExpMonthYear = (expMonthYear) => {
    let val = expMonthYear;
    if (val.length === 2 && prevKey !== 8 && !val.includes("/")) {
      // concatenating / after month i.e after numbers entered
      val = val + "/";
    }
    if (val.length === 3 && !val.includes("/")) {
      // adding / if month and year are not seperated (some cases)
      val = val.slice(0, 2) + "/" + val.slice(2, 3);
    }
    return val;
  };

  // handles input change
  const onInputChange = (e) => {
    let { name, value } = e.target;
    if (name === "cardNumber") {
      const NUMBER_ONLY_REGEX = /^[0-9]*$/gm;
      if (!NUMBER_ONLY_REGEX.test(value) || value.length > 16) {
        return;
      }
    }
    if (name === "expMonthYear") {
      const EXP_REGEX = /^[0-9 /]*$/gm;
      if (!EXP_REGEX.test(value)) {
        return;
      }
      if (value.length > 7) {
        return;
      }
      value = formatExpMonthYear(value);
    }
    if (name === "cvc") {
      if (value.length > 4) {
        return;
      }
    }
    setValues({ ...values, [name]: value });
  };

  // captures key pressed
  const handleCardKeyDown = (e) => {
    prevKey = e.keyCode;
  };

  const return6MonthsDate = () => {
    var newDate = new Date(new Date().setMonth(new Date().getMonth() + 6));
    return newDate;
  };

  const handleSubmitPayment = () => {
    const monthYear = expMonthYear.split("/");
    const dataToSubmit = {
      cardName,
      cardNumber,
      cvc: cvc,
      expMonth: Number(monthYear[0]),
      expYear: Number(monthYear[1]),
      portfolioId: localStorage.getItem("portfolioId"),
      displayedAtId: localStorage.getItem("displayedAtId"),
      displayedAt: returnInvoiceDisplayedAtData(),
      amount:
        customerCategory !== "VULCANS" &&
        customerCategory !== "REDS" &&
        customerCategory !== "LLAMA"
          ? returnSum()
          : returnSum(),
      dueAmount:
        customerCategory === "VULCANS" ||
        customerCategory === "REDS" ||
        customerCategory === "LLAMA"
          ? returnSum()
          : returnSum(),
    };
    dispatch(
      ProfileActions.submitPotfolioPayment(dataToSubmit, (res) => {
        if (!res.error.error) {
          dispatch(
            ProfileActions.getPortfolioData(localStorage.getItem("portfolioId"))
          );
          dispatch(
            SettingsActions.getCompanyPortfolioData(
              localStorage.getItem("companyId")
            )
          );
          setValues({
            ...values,
            cardNumber: "",
            cvc: "",
            expMonthYear: "",
            errorMessage: "",
          });
          onClose("showMoneyModal")();
          onGreatClick();
          localStorage.setItem("isPortfolioPublic", true);
          history.push("/portfolioView");
        } else {
          setValues({ ...values, errorMessage: res.error.message });
        }
      })
    );
  };

  const onGreatClick = () => {
    setValues({
      ...values,
      activeSubscription: false,
      expirationDate: "",
      displayedAtId: "",
      portfolioId: "",
    });
    onClose("showMoneyModalLimbo")();
  };

  const redirectToPage = () => {
    dispatch(SettingsActions.savePortfolioDataObj(portfolioDataObj));
    dispatch(SettingsActions.saveSelectedPortfolioData(eachRegionData));
    dispatch(SettingsActions.isSinglePortfolioSave(isAllPortfolioPublic));
    history.push("/PortfolioMoney");
  };

  const returnMultiMarketDisc = (idx) => {
    if (publicDataLength > 0) {
      if (publicDataLength === 1) {
        if (idx === 0) {
          return 10;
        }
        if (idx === 1) {
          return 20;
        }
      }
      if (publicDataLength === 2) {
        if (idx === 0) {
          return 20;
        }
      }
      return 30;
    } else {
      if (idx > 2) {
        return 30;
      }
      if (idx === 0) {
        return 0;
      }
      return idx * 10;
    }
  };

  const openRatePopup = () => {
    setValues({
      ...values,
      openRateModal: true,
    });
  };

  const handleClose = () => {
    setValues({
      ...values,
      openRateModal: false,
    });
  };

  const returnAfterDiscount = (price, discount) => {
    if (discount > 0) {
      return (price / 100) * discount;
    } else {
      return price;
    }
  };

  const returnDiscountPrice = (price, afterDiscount, multiDisc) => {
    if (customerCategory === "LUCKY") {
      return 0;
    } else if (MARKET_DISCOUNT == 0) {
      return price - multiDisc;
    } else {
      return price - afterDiscount - multiDisc;
    }
  };

  const returnSum = () => {
    const totalsArray = [];
    let total = 0;
    selectedRegions &&
      selectedRegions.map((eachRegion, idx) => {
        const eachSum = returnDiscountPrice(
          MARKET_PRICE,
          customerCategory !== "LLAMA"
            ? returnAfterDiscount(MARKET_PRICE, MARKET_DISCOUNT)
            : returnAfterDiscount(MARKET_PRICE, MARKET_DISCOUNT),
          selectedRegions.length > 1 || publicDataLength > 0
            ? returnMultiMarket(
                returnMultiMarketDisc(idx),
                customerCategory !== "LLAMA"
                  ? returnAfterDiscount(MARKET_PRICE, MARKET_DISCOUNT)
                  : MARKET_PRICE
              )
            : returnMultiMarket(
                returnMultiMarketDisc(idx),
                customerCategory !== "LLAMA"
                  ? returnAfterDiscount(MARKET_PRICE, MARKET_DISCOUNT)
                  : MARKET_PRICE
              )
        );
        totalsArray.push(eachSum);
        return eachSum;
      });
    totalsArray.map((each) => {
      total += each;
    });
    return total;
  };

  const returnMultiMarket = (multi, price) => {
    return (price / 100) * multi;
  };

  const handleInputChange = (e) => {
    const { name, checked } = e.target;
    if (name === "autoPay" && checked === true) {
      setValues({ ...values, [name]: checked, saveInfo: true });
      return;
    }
    if (name === "saveInfo" && checked === false) {
      setValues({ ...values, [name]: checked, autoPay: false });
      return;
    }
    setValues({ ...values, [name]: checked });
  };

  const printInvoice = () => {
    const dataToSubmit = {
      portfolioId: localStorage.getItem("portfolioId"),
      displayedAt: returnInvoiceDisplayedAtData(),
      amount: returnSum(),
      dueAmount:
        customerCategory === "VULCANS" ||
        customerCategory === "REDS" ||
        customerCategory === "LLAMA"
          ? returnSum()
          : returnSum(),
    };
    dispatch(
      ProfileActions.printPortfolioInvoice(dataToSubmit, (res) => {
        if (!res.error.error) {
          window.open(res.data.invoiceLink);
        } else {
          console.log(res.data);
        }
      })
    );
  };

  const returnInvoiceDisplayedAtData = () => {
    return selectedRegions.map((each, idx) => {
      // if (selectedRegions.length > 1) {
      return {
        category: each.category,
        region: each.region,
        displayedAtId:
          each.displayedAtId || localStorage.getItem("displayedAtId"),
        businessCardId: each.location ? get(each.location, "_id", "") : "",
        unitPrice:
          customerCategory === "LIMBO" || customerCategory === "LLAMA"
            ? "600"
            : "600",
        discounts: [
          {
            desc:
              customerCategory !== "LLAMA"
                ? `Pandemic Discount ${MARKET_DISCOUNT}%`
                : "",
            discountAmount:
              customerCategory === "LIMBO" && customerCategory !== "LLAMA"
                ? returnAfterDiscount(MARKET_PRICE, MARKET_DISCOUNT)
                : returnAfterDiscount(MARKET_PRICE, MARKET_DISCOUNT),
            discount:
              customerCategory === "LIMBO" && customerCategory !== "LLAMA"
                ? MARKET_DISCOUNT
                : MARKET_DISCOUNT,
          },
          {
            desc:
              customerCategory === "LIMBO" || customerCategory === "LLAMA"
                ? `Multimarket Discount - ${returnMultiMarketDisc(idx)}%`
                : `Multimarket Discount - ${returnMultiMarketDisc(idx)}%`,
            discountAmount:
              customerCategory === "LIMBO" || customerCategory === "LLAMA"
                ? returnMultiMarket(
                    returnMultiMarketDisc(idx),
                    customerCategory !== "LLAMA"
                      ? returnAfterDiscount(MARKET_PRICE, MARKET_DISCOUNT)
                      : MARKET_PRICE
                  )
                : returnMultiMarket(
                    returnMultiMarketDisc(idx),
                    customerCategory !== "LLAMA"
                      ? returnAfterDiscount(MARKET_PRICE, MARKET_DISCOUNT)
                      : MARKET_PRICE
                  ),
            discount:
              customerCategory === "LIMBO" || customerCategory === "LLAMA"
                ? returnMultiMarketDisc(idx)
                : returnMultiMarketDisc(idx),
          },
        ],
      };
      // }
      // return {
      //   category: each.category,
      //   region: each.region,
      //   displayedAtId:
      //     each.displayedAtId || localStorage.getItem("displayedAtId"),
      //   businessCardId: each.location ? get(each.location, "_id", "") : "",
      //   unitPrice: customerCategory === "LIMBO" ? "600" : 0,
      //   discounts: [
      //     {
      //       desc:
      //         customerCategory === "LIMBO" ? `Pandemic ${MARKET_DISCOUNT}%` : 0,
      //       discountAmount:
      //         customerCategory === "LIMBO"
      //           ? returnAfterDiscount(MARKET_PRICE, MARKET_DISCOUNT)
      //           : 0,
      //       discount: customerCategory === "LIMBO" ? MARKET_DISCOUNT : 0,
      //     },
      //   ],
      // };
    });
  };

  return (
    <>
      <CustomModal open={open}>
        <div className={classes.MoneyModalWidth}>
          <div className={classes.btnRight}>
            {/* <CustomButton className={classes.crossBtn} isX> */}
            <Close_Icon className={classes.crossBtn} onClick={onGreatClick} />
            {/* </CustomButton> */}
          </div>
          <CustomScrollbars
            className={classes.custom_scroll_height}
            isModalScroll={true}
          >
            <div className={classes.moenyModalScroll}>
              <div className={`${classes.textCenter}`}>
                <img
                  src="/assets/images/Party.png"
                  alt="Party"
                  className={`${classes.party_icon} ${classes.padding_bottom_19}`}
                />
              </div>
              {activeSubscription && (
                <div
                  className={`${classes.textCenter} ${classes.padding_bottom_50}`}
                >
                  <Text
                    size={20}
                    color={color.primary_palette.black}
                    className={classes.padding_bottom_5}
                  >
                    {`${strings.moneyModal.titles.description1_case_a} ${moment(
                      expirationDate
                    ).format("LL")}`}
                  </Text>
                  <Text size={20} color={color.primary_palette.black}>
                    {`${
                      strings.moneyModal.titles.description2_case_a1
                    } ${moment(expirationDate).format("LL")} ${
                      strings.moneyModal.titles.description2_case_a12
                    }`}
                  </Text>
                </div>
              )}
              {!activeSubscription && (
                <div className={classes.hidden}>
                  {(!customerCategory || customerCategory === "LIMBO") && (
                    <div className={`${classes.textCenter}`}>
                      <Text
                        size={18}
                        color={color.primary_palette.black}
                        family="gillsans_r"
                      >
                        Your current subscription expired.
                      </Text>
                      <Text
                        size={18}
                        color={color.primary_palette.black}
                        family="gillsans_r"
                      >
                        The great news is that subscription renewals
                        {/* and new
                        markets receive */}
                      </Text>
                      <Text
                        size={18}
                        color={color.primary_palette.black}
                        className={classes.padding_bottom_5}
                        family="gillsans_r"
                      >
                        {/* a 25% pandemic discount until{" "}
                        {moment(
                          get(lookUpData, "configData.offerEndDate")
                        ).format("LL")}
                        . */}
                      </Text>
                    </div>
                  )}
                  {customerCategory === "GREENS" && (
                    <div
                      className={`${classes.textCenter} ${classes.padding_92}`}
                    >
                      <Text
                        size={18}
                        color={color.primary_palette.black}
                        className={classes.padding_bottom_5}
                        family="gillsans_r"
                      >
                        Current Franklin Report subscribers will receive a free
                        upgrade to this enhanced portfolio for any existing
                        portfolio markets
                        {/* , and a 25% discount for any renewals
                        or new markets through September 1, 2021. */}
                      </Text>{" "}
                      <Text
                        size={18}
                        color={color.primary_palette.black}
                        className={classes.padding_bottom_5}
                        family="gillsans_r"
                      >
                        Your existing portfolio is paid through July 2021. So
                        this new, enhanced portfolio will display instead of
                        your legacy portfolio at no charge until your current
                        subscription expires.
                      </Text>{" "}
                    </div>
                  )}
                  {(customerCategory === "REDS" ||
                    customerCategory === "VULCANS") && (
                    <div className={`${classes.textCenter}`}>
                      <Text
                        size={18}
                        color={color.primary_palette.black}
                        family="gillsans_r"
                      >
                        So glad to see you back in our community!
                      </Text>{" "}
                      {/* <Text
                        size={18}
                        color={color.primary_palette.black}
                        family="gillsans_r"
                      >
                        The great news is that your subscription is free
                        until&nbsp;
                        {moment(
                          get(lookUpData, "configData.offerEndDate")
                        ).format("LL")}
                      </Text>{" "} */}
                      <Text
                        size={18}
                        color={color.primary_palette.black}
                        className={classes.padding_bottom_5}
                        family="gillsans_r"
                      >
                        {/* has a 25% pandemic discount. Expiration date of one year
                        after payment. */}
                      </Text>{" "}
                    </div>
                  )}
                  {customerCategory === "LLAMA" && (
                    <div className={`${classes.textCenter}`}>
                      <Text
                        size={18}
                        color={color.primary_palette.black}
                        family="gillsans_r"
                      >
                        Thank you for adding to the Franklin Report Community,
                        reaching upscale
                      </Text>
                      <Text
                        size={18}
                        color={color.primary_palette.black}
                        family="gillsans_r"
                        className={classes.padding_bottom_5}
                      >
                        homeowners on the verge of important buying decisions.
                      </Text>{" "}
                      {/* <Text
                        size={18}
                        color={color.primary_palette.black}
                        family="gillsans_r"
                      >
                        As a recent new member, your portfolio will be online
                        for the next six
                      </Text>
                      <Text
                        size={18}
                        color={color.primary_palette.black}
                        family="gillsans_r"
                        className={classes.padding_bottom_5}
                      >
                        months at no cost. Our contribution to the design
                        community at this time.
                      </Text>{" "} */}
                    </div>
                  )}
                  {customerCategory === "FREEFRIENDS" && (
                    <div className={`${classes.textCenter}`}>
                      <Text
                        size={18}
                        color={color.primary_palette.black}
                        className={classes.padding_bottom_5}
                      >
                        As a valued member of the Franklin Report Community, you
                        will receive a free upgrade to this enhanced portfolio
                        for any existing portfolio markets and new markets
                        through June 1, 2021.
                      </Text>{" "}
                      <Text
                        size={18}
                        color={color.primary_palette.black}
                        className={classes.padding_bottom_5}
                      >
                        To keep our lights on, we will charge your credit card
                        on{" "}
                        {moment(
                          get(lookUpData, "configData.offerEndDate")
                        ).format("LL")}{" "}
                        for an annual subscription at these rates. You will be
                        notified a week ahead, to update your information or
                        cancel.
                      </Text>{" "}
                    </div>
                  )}
                  {(customerCategory === "LIMBO" ||
                    customerCategory === "REDS" ||
                    customerCategory === "VULCANS" ||
                    customerCategory === "LLAMA" ||
                    (customerCategory === "GREENS" &&
                      get(CompanyBasicData, "companyBasics.legacyMarkets")
                        .length === 0)) && (
                    <div
                      className={`${classes.width_324} ${classes.padding_right_10}`}
                    >
                      {map(selectedRegions, (regionsData, idx) => {
                        return (
                          <Row className={classes.padding_top_20}>
                            <div className={classes.width_70}>
                              <Text
                                size={16}
                                color={color.primary_palette.black}
                                family="gillsans_light"
                                className={classes.txtRight}
                              >
                                {get(regionsData, "region", "")} New Market
                              </Text>
                              {customerCategory !== "LUCKY" && (
                                <>
                                  {customerCategory !== "LLAMA" && (
                                    <Text
                                      size={16}
                                      color={color.primary_palette.black}
                                      family="gillsans_light"
                                      className={classes.txtRight}
                                    >
                                      {MARKET_DISCOUNT !== 0 &&
                                        `Pandemic Discount - ${MARKET_DISCOUNT}%`}
                                    </Text>
                                  )}
                                  {((selectedRegions.length > 1 && idx != 0) ||
                                    publicDataLength > 0) && (
                                    <Text
                                      size={16}
                                      color={color.primary_palette.black}
                                      family="gillsans_light"
                                      className={classes.txtRight}
                                    >
                                      Multimarket Discount -
                                      {returnMultiMarketDisc(idx)}%
                                    </Text>
                                  )}
                                </>
                              )}
                            </div>
                            <div className={classes.width_18}>
                              <Text
                                size={16}
                                color={color.primary_palette.black}
                                family="gillsans_light"
                                className={classes.txtRight}
                              >
                                $
                                {customerCategory === "LUCKY"
                                  ? MARKET_PRICE
                                  : MARKET_PRICE}
                              </Text>
                              {customerCategory !== "LUCKY" && (
                                <>
                                  {customerCategory !== "LLAMA" &&
                                    MARKET_DISCOUNT !== 0 && (
                                      <Text
                                        size={16}
                                        color={color.primary_palette.black}
                                        family="gillsans_light"
                                        className={classes.txtRight}
                                      >
                                        - $
                                        {returnAfterDiscount(
                                          MARKET_PRICE,
                                          MARKET_DISCOUNT
                                        )}
                                      </Text>
                                    )}
                                  {((selectedRegions.length > 1 && idx != 0) ||
                                    publicDataLength > 0) && (
                                    <Text
                                      size={16}
                                      color={color.primary_palette.black}
                                      family="gillsans_light"
                                      className={classes.txtRight}
                                    >
                                      - $
                                      {returnMultiMarket(
                                        returnMultiMarketDisc(idx),
                                        customerCategory !== "LLAMA"
                                          ? returnAfterDiscount(
                                              MARKET_PRICE,
                                              MARKET_DISCOUNT
                                            )
                                          : MARKET_PRICE
                                      )}
                                    </Text>
                                  )}
                                </>
                              )}
                              <Text
                                size={16}
                                color={color.primary_palette.black}
                                family="gillsans_r"
                                className={classes.txtRight}
                              >
                                <span className={classes.total_amount}>
                                  $
                                  {returnDiscountPrice(
                                    MARKET_PRICE,
                                    customerCategory !== "LLAMA"
                                      ? returnAfterDiscount(
                                          MARKET_PRICE,
                                          MARKET_DISCOUNT
                                        )
                                      : 0,
                                    selectedRegions.length > 1 ||
                                      publicDataLength > 0
                                      ? returnMultiMarket(
                                          returnMultiMarketDisc(idx),
                                          customerCategory !== "LLAMA"
                                            ? returnAfterDiscount(
                                                MARKET_PRICE,
                                                MARKET_DISCOUNT
                                              )
                                            : MARKET_PRICE
                                        )
                                      : 0
                                  )}
                                </span>
                              </Text>
                            </div>
                          </Row>
                        );
                      })}
                      <Row className={classes.padding_top_15}>
                        <div className={classes.width_70}>
                          {(customerCategory === "REDS" ||
                            customerCategory === "VULCANS") && (
                            <Text
                              size={16}
                              color={color.primary_palette.franklin_purple}
                              family="gillsans_light"
                              className={classes.txtRight}
                            >
                              {strings.portfolioMoney.title.due_november}
                            </Text>
                          )}
                          {customerCategory === "LLAMA" && (
                            <Text
                              size={16}
                              color={color.primary_palette.franklin_purple}
                              family="gillsans_light"
                              className={classes.txtRight}
                            >
                              {strings.portfolioMoney.title.due_months}&nbsp;
                              {moment(return6MonthsDate()).format("LL")}
                            </Text>
                          )}
                          {customerCategory !== "REDS" &&
                            customerCategory !== "VULCANS" &&
                            customerCategory !== "LLAMA" && (
                              <Text
                                size={20}
                                color={color.primary_palette.franklin_purple}
                                family="gillsans_sb"
                                className={classes.txtRight}
                                transform="uppercase"
                              >
                                {strings.portfolioMoney.title.due}
                              </Text>
                            )}
                        </div>
                        <div className={classes.width_18}>
                          <Text
                            size={
                              customerCategory !== "REDS" &&
                              customerCategory !== "VULCANS" &&
                              customerCategory !== "LLAMA"
                                ? 20
                                : 16
                            }
                            color={color.primary_palette.franklin_purple}
                            family="gillsans_sb"
                            className={classes.txtRight}
                          >
                            {customerCategory !== "LUCKY"
                              ? `$${returnSum()}`
                              : "FREE"}
                          </Text>
                        </div>
                      </Row>
                      {(customerCategory === "REDS" ||
                        customerCategory === "VULCANS" ||
                        customerCategory === "LLAMA") && (
                        <Row className={classes.padding_top_15}>
                          <div className={classes.width_70}>
                            <Text
                              size={20}
                              color={color.primary_palette.franklin_purple}
                              family="gillsans_sb"
                              className={classes.txtRight}
                            >
                              {strings.portfolioMoney.title.due}
                            </Text>
                          </div>
                          <div className={classes.width_18}>
                            <Text
                              size={20}
                              color={color.primary_palette.franklin_purple}
                              family="gillsans_sb"
                              className={`${classes.txtRight}`}
                            >
                              ${returnSum()}
                            </Text>
                          </div>
                        </Row>
                      )}
                    </div>
                  )}
                  {(customerCategory === "LIMBO" ||
                    customerCategory === "REDS" ||
                    customerCategory === "VULCANS" ||
                    customerCategory === "LLAMA" ||
                    (customerCategory === "GREENS" &&
                      get(CompanyBasicData, "companyBasics.legacyMarkets")
                        .length === 0)) && (
                    <div className={`${classes.limboAddMarket}`}>
                      <Text
                        size={16}
                        family="avenir_sb"
                        color={color.primary_palette.franklin_purple}
                        onClick={redirectToPage}
                        className={`${classes.cursor} ${classes.underlineHover}`}
                      >
                        + Add Market?
                      </Text>
                    </div>
                  )}
                  {/* {customerCategory !== "LIMBO" && (
                    <Row
                      className={`${classes.padding_top_12} ${classes.img_data_spcaing}`}
                    >
                      <div>
                        <Text
                          size={16}
                          family="avenir_light"
                          color={color.primary_palette.black}
                          className={classes.textCapital}
                        >
                          {get(portfolioDataObj, "name", "")}
                        </Text>
                        <div className={classes.img_main_border}>
                          <div className={classes.img_sub_border}>
                            <img
                              src={
                                get(
                                  portfolioDataObj,
                                  "coverImgUri",
                                  "/assets/images/Default Duck.jpg"
                                ) || "/assets/images/Default Duck.jpg"
                              }
                              alt="Crisp"
                              className={classes.company_logo}
                            />
                            <Row>
                              <div className={classes.width_18}>
                                <img
                                  src={
                                    get(
                                      portfolioDataObj,
                                      "companyLogo",
                                      "/assets/images/Default Ducklin.jpg"
                                    ) || "/assets/images/Default Ducklin.jpg"
                                  }
                                  alt="Crisp"
                                  className={classes.user_logo}
                                />
                              </div>
                              <div className={classes.width_70}>
                                <Text
                                  size={12.1}
                                  family="millerdisplay_light"
                                  color={color.primary_palette.black}
                                  className={`${classes.txtCenter} ${classes.ellipsis_text}`}
                                >
                                  <span className={classes.text_decoration}>
                                    {get(portfolioDataObj, "companyName", "")}
                                  </span>
                                </Text>
                                <Text
                                  size={7.4}
                                  family="gillsans_sb"
                                  color={color.primary_palette.black}
                                  className={`${classes.textCapital} ${classes.txtCenter}`}
                                >
                                  {get(
                                    portfolioDataObj,
                                    "companyAddress.city",
                                    ""
                                  )}
                                  {get(
                                    portfolioDataObj,
                                    "companyAddress.state",
                                    ""
                                  ) && ","}
                                  &nbsp;
                                  {get(
                                    portfolioDataObj,
                                    "companyAddress.state",
                                    ""
                                  )}
                                </Text>
                              </div>
                            </Row>
                          </div>
                        </div>
                        <Row>
                          <Text
                            size={16}
                            color={color.secondary_palette.grays.shadow_gray}
                            family="gillsans_sb"
                            className={classes.future_rate_card}
                            onClick={openRatePopup}
                          >
                            {strings.build.titles.future_rate_card}
                          </Text>
                        </Row>
                      </div>

                      <Row className={classes.alignments_col}>
                        {map(
                          CompanyBasicData?.companyBasics?.legacyMarkets,
                          (eachRegion) => {
                            return (
                              <div
                                className={`${classes.padding_top_15} ${classes.padding_left_25}`}
                              >
                                <Text
                                  size={15}
                                  family="gillsans_sb"
                                  color={color.primary_palette.black}
                                >
                                  {get(eachRegion, "marketName", "")}
                                </Text>

                                <Text
                                  size={15}
                                  family="gillsans_light"
                                  color={color.primary_palette.black}
                                >
                                  {get(eachRegion, "serviceCodeName", "")}{" "}
                                  <br />
                                </Text>
                                {get(eachRegion, "expireDate", "") && (
                                  <Text
                                    size={15}
                                    family="gillsans_sb"
                                    color={color.primary_palette.black}
                                  >
                                    {
                                      strings.portfolioMoney.title
                                        .expiration_date
                                    }
                                    <span className={classes.gill_r}>
                                      {moment(
                                        get(eachRegion, "expireDate", "")
                                      ).format("MM/DD/yyyy")}{" "}
                                    </span>
                                  </Text>
                                )}
                              </div>
                            );
                          }
                        )}
                        {isAllPortfolioPublic &&
                          map(portfolioDataObj?.displayedAt, (eachRegion) => {
                            return (
                              <div
                                className={`${classes.padding_top_15} ${classes.padding_left_25}`}
                              >
                                <Text
                                  size={15}
                                  family="gillsans_sb"
                                  color={color.primary_palette.black}
                                >
                                  {eachRegion.region}
                                </Text>
                                {map(eachRegion?.category, (eachCategory) => {
                                  return (
                                    <Text
                                      size={15}
                                      family="gillsans_light"
                                      color={color.primary_palette.black}
                                    >
                                      {eachCategory} <br />
                                    </Text>
                                  );
                                })}
                              </div>
                            );
                          })}

                        {!isAllPortfolioPublic && (
                          <div
                            className={`${classes.padding_top_15} ${classes.padding_left_25}`}
                          >
                            <Text
                              size={15}
                              family="gillsans_sb"
                              color={color.primary_palette.black}
                            >
                              {eachRegionData.region}
                            </Text>
                            {map(eachRegionData?.category, (eachCategory) => {
                              return (
                                <Text
                                  size={15}
                                  family="gillsans_light"
                                  color={color.primary_palette.black}
                                >
                                  {eachCategory} <br />
                                </Text>
                              );
                            })}
                          </div>
                        )}

                        <div
                          className={`${classes.padding_top_15} ${classes.padding_left_25}`}
                        >
                          <Text
                            size={14}
                            family="avenir_sb"
                            color={color.primary_palette.franklin_purple}
                            onClick={redirectToPage}
                            className={`${classes.cursor} ${classes.underlineHover}`}
                          >
                            + Add Market?
                          </Text>
                        </div>
                      </Row>
                    </Row>
                  )} */}
                  <div>
                    {(customerCategory == "REDS" ||
                      customerCategory == "VULCANS") && (
                      <Text
                        size={12}
                        family="avenir_sb"
                        color={color.primary_palette.black}
                        className={classes.saveTxtAlign}
                      >
                        Your credit card will not be charged until the end of
                        the free period,{" "}
                        {moment(
                          get(lookUpData, "configData.offerEndDate")
                        ).format("LL")}
                        , with expiration a year later. You will receive email
                        notification ahead of time, to update or cancel
                      </Text>
                    )}
                    {/* {customerCategory == "LLAMA" && (
                      <Text
                        size={12}
                        family="avenir_sb"
                        color={color.primary_palette.black}
                        className={classes.saveTxtAlign}
                      >
                        You will not be charged for 6 months. You will receive
                        email notification ahead of time, to update or cancel.
                        This new annual subscription will expire one year after
                        payment date.
                      </Text>
                    )} */}
                  </div>
                  <Row
                    className={`${classes.clearBoth} ${classes.justifyCenter}`}
                  >
                    <div>
                      <Text
                        size={12}
                        color={color.primary_palette.franklin_purple}
                        family="gillsans_sb"
                      >
                        {strings.moneyModal.titles.name_card}
                      </Text>
                      <CustomTextField
                        className={`${classes.input_field} ${classes.cardName}`}
                        name="cardName"
                        defaultValue={cardName}
                        onBlur={onInputChange}
                      />
                      <Text
                        size={12}
                        color={color.primary_palette.franklin_purple}
                        family="Helvetica"
                        className={`${classes.printAlign} ${classes.padding_top_7}`}
                        onClick={printInvoice}
                      >
                        Print Invoice
                      </Text>
                    </div>
                    <div>
                      <Text
                        size={12}
                        color={color.primary_palette.franklin_purple}
                        family="gillsans_sb"
                      >
                        {strings.moneyModal.titles.card_num}
                      </Text>
                      <CustomTextField
                        className={`${classes.input_field} ${classes.cardName1}`}
                        name="cardNumber"
                        value={cardNumber}
                        onChange={onInputChange}
                      />
                    </div>
                    <div>
                      <Row>
                        <div>
                          <Text
                            size={12}
                            color={color.primary_palette.franklin_purple}
                            family="gillsans_sb"
                          >
                            {strings.moneyModal.titles.expiration_date}
                          </Text>
                          <CustomTextField
                            className={`${classes.input_field} ${classes.expMonthYear}`}
                            name="expMonthYear"
                            value={expMonthYear}
                            onChange={onInputChange}
                            onKeyDown={handleCardKeyDown}
                          />
                        </div>
                        <div>
                          <Text
                            size={12}
                            color={color.primary_palette.franklin_purple}
                            family="gillsans_sb"
                          >
                            {strings.moneyModal.titles.zip_code}
                          </Text>
                          <CustomTextField
                            className={`${classes.input_field} ${classes.zipCode}`}
                            name="zip"
                            value={zip}
                            onChange={onInputChange}
                          />
                        </div>
                        <div>
                          <Text
                            size={12}
                            color={color.primary_palette.franklin_purple}
                            family="gillsans_sb"
                          >
                            {strings.moneyModal.titles.cvv_code}
                          </Text>
                          <CustomTextField
                            className={`${classes.input_field} ${classes.cvv}`}
                            name="cvc"
                            value={cvc}
                            onChange={onInputChange}
                          />
                        </div>
                      </Row>
                      <Row
                        className={`${classes.check_box_spacingend} ${classes.width_50}`}
                      >
                        <CustomCheckbox
                          label={strings.portfolioMoney.title.save}
                          className={`${classes.check_save} ${classes.margin_right_2}`}
                          name="saveInfo"
                          checked={saveInfo}
                          onChange={handleInputChange}
                        />
                        <CustomCheckbox
                          label={strings.portfolioMoney.title.autorenew}
                          className={`${classes.check_save} ${classes.margin_right_0}`}
                          name="autoPay"
                          checked={autoPay}
                          onChange={handleInputChange}
                        />
                      </Row>
                    </div>
                  </Row>
                  <Row className={classes.btnsAlign}>
                    {!activeSubscription && (
                      <CustomButton
                        className={classes.renewBtn}
                        disabled={disableSubmit()}
                        onClick={handleSubmitPayment}
                        endIcon={
                          !disableSubmit() && (
                            <Ribbon
                              color={"primary"}
                              style={{
                                fontSize: "47px",
                                top: "-49px",
                                position: "absolute",
                                zIndex: 999,
                                right: "-4px",
                              }}
                            />
                          )
                        }
                      >
                        {strings.moneyModal.titles.go_public}
                      </CustomButton>
                    )}
                    {customerCategory !== "LUCKY" &&
                      customerCategory !== "VULCANS" &&
                      customerCategory !== "REDS" &&
                      customerCategory !== "LLAMA" && (
                        <>
                          {" "}
                          <Text
                            size={12}
                            color={color.primary_palette.black}
                            family="gillsans_sb"
                            className={`${classes.textCenter} ${classes.or_spacing}`}
                          >
                            {strings.moneyModal.titles.or}
                          </Text>
                          {/* <PaypalButton
                            onClose={onClose}
                            returnInvoiceDisplayedAtData={
                              returnInvoiceDisplayedAtData
                            }
                            finalAmount={
                              customerCategory !== "LUCKY" &&
                                customerCategory !== "VULCANS" &&
                                customerCategory !== "REDS" &&
                                customerCategory !== "LLAMA"
                                ? returnSum()
                                : 0
                            }
                          /> */}
                        </>
                      )}
                  </Row>
                </div>
              )}
              <Text
                color={color.primary_palette.christmas_red}
                family="gillsans_sb"
                size={15}
                className={classes.textCenter}
              >
                {errorMessage}
              </Text>

              <div className={classes.textCenter}>
                {activeSubscription && (
                  <CustomButton
                    className={classes.greatBtn}
                    onClick={onGreatClick}
                  >
                    {strings.moneyModal.titles.great}
                  </CustomButton>
                )}
              </div>
              <div className={classes.textCenter}>
                <Text
                  size={16}
                  color={color.primary_palette.black}
                  family="gillsans_light"
                  className={`${classes.textCenter} ${classes.footerTxtAlign}`}
                >
                  {strings.moneyModal.titles.renew_rights1}
                </Text>
              </div>
            </div>
          </CustomScrollbars>
        </div>
      </CustomModal>
      <CustomModal
        open={openRateModal}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <PortfolioModal onClose={handleClose} />
      </CustomModal>
    </>
  );
}

export default MoneyModalStyles(MoneyModal);
