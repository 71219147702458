import React, { useRef, useState, useEffect } from "react";
import { get } from "lodash";

import AddImageStyles from "./styles/addImageStyles";
import Row from "../common/ui_kit/row";
import Text from "../common/ui_kit/text";
import CustomModal from "../inputs/custom_modal";
import AlterImageCropper from "../add_new_project/alter_image_cropper";
import DeleteModal from "../add_new_project/delete_projectModal";
import AddImageHover from "../data_display/icons/add_default_hover";

import { MAX_UPLOAD_SIZE } from "../../constants";

function AddImageCropper(props) {
  const uploadRef = useRef(null);
  const {
    classes,
    defaultImage,
    MainText,
    MainTextStyle,
    subTextStyle,
    subText,
    sizeText,
    sizeStyle,
    subText1,
    subTextStyle1,
    addImageStyleImport,
    onClear,
    originalImg,
    type,
    hasImageData,
    isProductCropper,
    noRecrop,
    transparentColor,
  } = props;

  const [uploadedImage, setUploadedImage] = useState({ togglecropper: false });
  const [errors, setErrors] = useState({});
  const [state, setState] = useState({
    openDeleteModal: false,
  });
  const { togglecropper } = uploadedImage;

  useEffect(() => {
    const { croppedImage } = props;
    if (croppedImage) {
      setUploadedImage({
        ...uploadedImage,
        image: croppedImage,
        togglecropper: false,
      });
    }
  }, [props.croppedImage]);

  useEffect(() => {
    const { openImageExp } = props;
    if (openImageExp) {
      handleButtonClick();
    }
  }, [props.openImageExp]);

  const imageUpload = (e) => {
    e.preventDefault();
    let file = e.target.files[0];
    if (!file) {
      return;
    }
    uploadRef.current.value = "";
    if (!file.type.includes("image")) {
      setErrors({
        ...errors,
        image: "Upload JPG or PNG, Max 5 MB",
      });
      return;
    }
    if (file.size > MAX_UPLOAD_SIZE) {
      setErrors({
        ...errors,
        image: "Upload JPG or PNG, Max 5 MB",
      });
      return;
    }
    setUploadedImage({
      ...uploadedImage,
      initialImage: URL.createObjectURL(file),
      imageFile: file,
      togglecropper: true,
    });
    setErrors({
      image: "",
    });
  };

  const handleButtonClick = (openUpload) => {
    if (get(uploadedImage, "image", "") && openUpload !== true && !noRecrop) {
      recropImage();
      return;
    }
    uploadRef.current.click();
  };

  const openExplorer = () => {
    uploadRef.current.click();
  };

  const togglCropModal = () => {
    setUploadedImage({
      ...uploadedImage,
      togglecropper: !uploadedImage.togglecropper,
    });
  };

  const handleDeleteModal = () => {
    setState({
      ...state,
      openDeleteModal: !openDeleteModal,
    });
  };

  const recropImage = () => {
    setUploadedImage({
      ...uploadedImage,
      initialImage: originalImg,
      togglecropper: !uploadedImage.togglecropper,
    });
  };

  const setCroppedImage = (url, data) => {
    const { blobUrl, croppedImage } = data;
    setUploadedImage({
      ...uploadedImage,
      image: url,
      imageFile: blobUrl,
      togglecropper: false,
    });
    props.imageUpload &&
      props.imageUpload(
        {
          imageFile: blobUrl,
          imageUrl: croppedImage,
          id: props.id,
          originalImageUrl: uploadedImage.initialImage,
          originalImageFile: uploadedImage.imageFile,
        },
        props.name
      );
  };
  const { openDeleteModal } = state;

  return (
    <>
      <Row
        className={
          isProductCropper
            ? `${classes.addImage_pub} ${addImageStyleImport}`
            : `${classes.addImage} ${addImageStyleImport}`
        }
      >
        <div className={classes.img_block}>
          <label>
            <div
              className={props.className || classes.default_image_add}
              onClick={handleButtonClick}
            >
              {uploadedImage.image ? (
                <img
                  key={uploadedImage.image}
                  alt=""
                  src={uploadedImage.image || defaultImage}
                  style={{ width: "100%", height: "100%" }}
                />
              ) : (
                <AddImageHover
                  className={`${classes.addHover} addHover_effect`}
                />
              )}{" "}
            </div>
          </label>
          {hasImageData && (
            <div className={classes.icons_blk}>
              <img
                src="/assets/icons/Crop Icon Black 02@1x.svg"
                alt=""
                className={classes.trash_can}
                onClick={get(uploadedImage, "image", "") && recropImage}
              />
              <img
                src="/assets/icons/Swap Icon Black 02.svg"
                alt=""
                className={classes.trash_can}
                onClick={openExplorer}
              />
              <img
                alt=""
                src="/assets/icons/Trashcan Icon Black 02.svg"
                className={classes.trash_can}
                onClick={get(uploadedImage, "image", "") && handleDeleteModal}
              />
            </div>
          )}
          <input
            id="file"
            ref={uploadRef}
            onChange={imageUpload}
            type="file"
            accept="image/*"
            className={classes.input_circle}
          />
        </div>
        <div className={classes.textCenter}>
          <label>
            <Text className={MainTextStyle} onClick={handleButtonClick}>
              {MainText}
            </Text>
          </label>
          <Text className={subTextStyle}>{subText}</Text>
          <Text className={subTextStyle1}>{subText1}</Text>
          <Text className={sizeStyle}>{sizeText}</Text>
        </div>
      </Row>
      {get(errors, "image") && (
        <Row className={classes.errorMessageProjects}>
          <img src="/assets/icons/info_1.svg" class="info_img" alt="" />
          &nbsp;
          {get(errors, "image", "")}
        </Row>
      )}

      <CustomModal open={togglecropper}>
        <AlterImageCropper
          image={get(uploadedImage, "initialImage", "")}
          alterCroppedImage={setCroppedImage}
          onClose={togglCropModal}
          cropSize={props.cropSize}
          toggleImageExp={handleButtonClick}
          type={type}
          transparentColor={transparentColor}
        />
      </CustomModal>

      <CustomModal open={openDeleteModal} onClose={handleDeleteModal}>
        <DeleteModal
          onClose={handleDeleteModal}
          deleteProject={onClear && onClear(props.id, "projectImagesData")}
          imagesData={uploadedImage.image}
        />
      </CustomModal>
    </>
  );
}

export default AddImageStyles(AddImageCropper);
