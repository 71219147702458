import { withStyles } from "@material-ui/core/styles";
import tradework_theme, { pxToRem, color } from "../../../utilities/themes";

const styles = withStyles({
  modal_bussinesscard: {
    width: pxToRem(742),
    backgroundColor: color.secondary_palette.blues.modal_blue,
    border: `solid ${pxToRem(2)} ${color.primary_palette.franklin_purple}`,
  },
  main_text: {
    display: "block",
    paddingLeft: pxToRem(10),
  },
  align_right: {
    textAlign: "right",
  },
  cross_mark: {
    padding: pxToRem(3),
    minWidth: pxToRem(30),
    "& .MuiSvgIcon-root": {
      fontSize: pxToRem(9),
    },
  },
  radioBtn: {
    margin: pxToRem(3),
    "& .MuiTypography-body1": {
      color: color.primary_palette.free_purple,
      fontSize: pxToRem(14),
      ...tradework_theme.typography.styles.avenir_sb,
    },
    "& .MuiSvgIcon-fontSizeSmall": {
      fontSize: pxToRem(16),
    },
  },
  card: {
    display: "block",
    padding: `${pxToRem(10)} ${pxToRem(100)}`,
  },
  free_block: {
    padding: `${pxToRem(0)} ${pxToRem(65)}`,
  },
  stamp_width: {
    width: pxToRem(80),
  },
  slect_category: {
    paddingTop: pxToRem(20),
  },
  select_items: {
    width: pxToRem(155),
    height: pxToRem(20),
    borderRadius: pxToRem(2),
    marginRight: pxToRem(8),
    marginTop: pxToRem(6),
    backgroundColor: color.primary_palette.white,
    border: `solid ${pxToRem(1)} ${color.secondary_palette.grays.shadow_gray}`,
    "& .MuiSvgIcon-root": {
      fontSize: pxToRem(14),
    },
  },
  add_btn: {
    width: pxToRem(59),
    height: pxToRem(20),
    borderRadius: pxToRem(3),
    marginTop: pxToRem(6),
    padding: 0,
    border: `solid ${pxToRem(1)} ${color.primary_palette.free_purple}`,
    backgroundColor: color.secondary_palette.grays.btn_gray,
    "& .MuiButton-label": {
      fontSize: pxToRem(15),
      ...tradework_theme.typography.styles.avenir_sb,
      color: color.primary_palette.free_purple,
      lineHeight: pxToRem(15),
    },
  },
  customer_info: {
    textTransform: "uppercase",
  },
  customer_block: {
    paddingTop: pxToRem(14),
  },
  continue_btn: {
    width: pxToRem(186),
    height: pxToRem(43),
    borderRadius: pxToRem(27),
    marginTop: pxToRem(-10),
    marginBottom: pxToRem(45),
    marginRight: pxToRem(30),
    border: `solid ${pxToRem(2)} ${color.primary_palette.franklin_purple}`,
    backgroundColor: color.primary_palette.white,
    "& .MuiButton-label": {
      fontSize: pxToRem(20),
      ...tradework_theme.typography.styles.avenir_sb,
      color: color.primary_palette.franklin_purple,
    },
  },
});

export default styles;
