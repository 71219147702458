import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { ThemeProvider } from "@material-ui/core/styles";

import store from "./redux/store";
import "./index.css";
import Router from "./routes/router";
import * as serviceWorker from "./serviceWorker";
import TradeWork_ThemeBuilder from "./utilities/create_theme";

ReactDOM.render(
  <Provider store={store}>
    <ThemeProvider theme={TradeWork_ThemeBuilder}>
      <Router />
    </ThemeProvider>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
