import { withStyles } from "@material-ui/core/styles";
import tradework_theme, { pxToRem, color } from "../../../utilities/themes";

const styles = withStyles({
  choose_width: {
    width: pxToRem(590),
    height: pxToRem(694),
    border: `solid ${pxToRem(2)} ${color.primary_palette.free_purple}`,
    backgroundColor: color.secondary_palette.blues.modal_blue,
  },
  padding_space: {
    padding: `${pxToRem(0)} ${pxToRem(40)}`,
    position: "relative",
    marginTop: pxToRem(-20),
  },
  textRight: {
    textAlign: "right",
  },
  cross_mark: {
    fontSize: pxToRem(15),
  },
  cross_btn: {
    minWidth: pxToRem(40),
    padding: pxToRem(16),
  },
  title_text: {
    textAlign: "center",
    textTransform: "uppercase",
  },
  title_sub_text: {
    textAlign: "center",
  },
  cropProfile: {
    position: "relative",
    width: pxToRem(280),
    height: pxToRem(307),
    margin: "0 auto",
    border: `solid ${pxToRem(2)} ${color.primary_palette.free_purple}`,
    marginTop: pxToRem(10),
    marginBottom: pxToRem(10),
  },
  cropLogo: {
    position: "relative",
    width: pxToRem(447),
    height: pxToRem(287),
    margin: "0 auto",
    border: `solid ${pxToRem(2)} ${color.primary_palette.free_purple}`,
    marginTop: pxToRem(20),
    marginBottom: pxToRem(25),
  },
  cropCover: {
    position: "relative",
    width: pxToRem(409),
    height: pxToRem(296),
    margin: "0 auto",
    border: `solid ${pxToRem(2)} ${color.primary_palette.free_purple}`,
    marginTop: pxToRem(27),
    marginBottom: pxToRem(25),
  },
  drag_text: {
    textAlign: "center",
  },
  choose_btn: {
    width: pxToRem(208),
    height: pxToRem(35),
    borderRadius: pxToRem(22),
    border: `solid ${pxToRem(1.6)} ${color.primary_palette.franklin_purple}`,
    backgroundColor: color.primary_palette.white,
    "& .MuiButton-label": {
      fontSize: pxToRem(16.3),
      ...tradework_theme.typography.styles.avenir_sb,
      color: color.primary_palette.franklin_purple,
    },
  },
  justifyLeft: {
    flexGrow: 1,
  },
  justifyRight: {
    flexGrow: 1,
    justifyContent: "flex-end",
  },
  btn_spacing: {
    padding: `${pxToRem(33)} ${pxToRem(0)}`,
  },
  choose_black: {
    width: pxToRem(26),
    height: pxToRem(26),
    border: `solid ${pxToRem(0.5)} ${color.primary_palette.black}`,
    backgroundColor: color.primary_palette.black,
    margin: `${pxToRem(12)} ${pxToRem(12)}`,
    cursor: "pointer",
  },
  choose_gray: {
    width: pxToRem(26),
    height: pxToRem(26),
    border: `solid ${pxToRem(0.5)} ${
      color.secondary_palette.grays.background_gray
    }`,
    backgroundColor: color.secondary_palette.grays.background_gray,
    margin: `${pxToRem(12)} ${pxToRem(12)}`,
    cursor: "pointer",
  },
  choose_white: {
    width: pxToRem(26),
    height: pxToRem(26),
    border: `solid ${pxToRem(1)} ${
      color.secondary_palette.grays.background_gray
    }`,
    backgroundColor: color.primary_palette.white,
    margin: `${pxToRem(12)} ${pxToRem(12)}`,
    cursor: "pointer",
  },
  choose_bg_txt: {
    alignSelf: "center",
  },
  centre: {
    justifyContent: "center",
  },
  slider_width: {
    width: pxToRem(293),
    margin: "0 auto",
    "& .MuiSlider-thumb": {
      width: pxToRem(22),
      height: pxToRem(22),
      marginTop: pxToRem(-10),
    },
    "& .MuiSlider-track": {
      backgroundColor: color.secondary_palette.grays.shadow_gray,
    },
    "& .MuiSlider-rail": {
      backgroundColor: color.secondary_palette.grays.shadow_gray,
      opacity: 1,
    },
  },
  profile_usage: {
    paddingTop: pxToRem(12),
  },
  trash_can: {
    position: "absolute",
    right: pxToRem(26),
    top: pxToRem(342),
    height: pxToRem(20),
    cursor: "pointer",
  },
  choose_gray_active: {
    width: pxToRem(26),
    height: pxToRem(26),
    border: `solid ${pxToRem(2)} ${color.primary_palette.highlight_purple}`,
    backgroundColor: color.secondary_palette.grays.background_gray,
    margin: `${pxToRem(12)} ${pxToRem(12)}`,
    cursor: "pointer",
  },
  choose_white_active: {
    width: pxToRem(26),
    height: pxToRem(26),
    border: `solid ${pxToRem(2)} ${color.primary_palette.highlight_purple}`,
    backgroundColor: color.primary_palette.white,
    margin: `${pxToRem(12)} ${pxToRem(12)}`,
    cursor: "pointer",
  },
  choose_black_active: {
    width: pxToRem(26),
    height: pxToRem(26),
    border: `solid ${pxToRem(2)} ${color.primary_palette.highlight_purple}`,
    backgroundColor: color.primary_palette.black,
    margin: `${pxToRem(12)} ${pxToRem(12)}`,
    cursor: "pointer",
  },
  checkeredAlign: {
    height: pxToRem(26),
    width: pxToRem(26),
  },
});

export default styles;
