import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import MenuItem from "@material-ui/core/MenuItem";
import { map, get, filter, find } from "lodash";
import { useHistory } from "react-router-dom";

import Row from "../common/ui_kit/row";
import Text from "../common/ui_kit/text";
import CustomButton from "../navigations/custom_buttons";
import strings from "../../utilities/strings";
import PortFolioStyles from "./styles/newPortfolioStyes";
import { color } from "../../utilities/themes";
import CustomSelect from "../common/ui_kit/custom_select";
import Chevron_Down_Icon from "../data_display/icons/Arrow_Chevron_Down";
import CustomScrollbars from "../data_display/custom_scroll";
import CustomAccordion from "../data_display/customAccordion";
import CustomTextField from "../inputs/custom_textfields";
import Lil_Plus_filled from "../data_display/icons/Lil_Plus_filled";

import { SettingsActions, LookUpActions } from "../../redux/actions";
import Styled_cross from "../data_display/icons/styled_cross";
import CustomRadioButton from "../inputs/custom_radio_button";
import Feedback from "../modals/feedbackModal";
import CustomModal from "../inputs/custom_modal";

function NewPortfolio(props) {
  const { classes, router } = props;

  const [state, setState] = useState({
    displayedAt: [],
    portfolioName: "",
    isNewCompany: false,
    company: "",
    location: "",
    errorMessage: "",
    errors: {},
    selectedRegions: [],
    toggleFeedbackModal: false,
  });

  const {
    userInfo,
    region,
    category,
    list,
    portfolioName,
    isNewCompany,
    location,
    errorMessage,
    selectedPortfolio,
    locationObj,
    administrator,
    selectedRegions,
    toggleFeedbackModal,
  } = state;

  const history = useHistory();
  const [limitError, setLimitError] = useState(false);

  const locationsData = useSelector(
    (resData) => resData.Settings.CompanyLocationsData
  );
  const portfolioList = useSelector(
    (resData) => resData.Settings.CompanyPortfolioData
  );

  const lookUpData = useSelector((resData) => resData.LookUp.LookUpData);

  const CompanyListByUser = useSelector(
    (resData) => resData.Settings.CompanyListByUser
  );

  const saveDupeData = () => {
    if (selectedRegions && selectedRegions.length) {
      state.errors.displayedAt = { error: false, message: "" };
    } else {
      state.errors.displayedAt = { error: true, message: "" };
      setState({ ...state });
      return;
    }
    if (hasError) {
      handleValidations("portfolioName", state.portfolioName);
      if (!isNewCompany && !list) {
        state.errors.listData = { error: true, message: "" };
      } else {
        state.errors.listData = { error: false, message: "" };
      }
      return;
    }
    const dataToSubmit = {
      companyId: localStorage.getItem("companyId"),
      businessCardId: locationObj._id,
      portfolioName: portfolioName,
      portfolioId: get(list, "_id", ""),
      isNew: isNewCompany,
      displayedAt: selectedRegions,
    };
    dispatch(
      SettingsActions.addDupePortfolio(dataToSubmit, (res) => {
        if (!res.error.error) {
          localStorage.setItem("portfolioId", res.data._id);
          history.push("/portfolioView");
        } else {
          setState({ ...state, errorMessage: res.error.error.message });
        }
      })
    );
  };

  const redirectToNewCompany = () => {
    localStorage.removeItem("portfolioId");
    history.push("/portfolioView");
  };

  const dispatch = useDispatch();
  const fetchData = () => {
    dispatch(
      SettingsActions.getCompanyPortfolioData(localStorage.getItem("companyId"))
    );
    dispatch(
      SettingsActions.getCompanyLocationsData(localStorage.getItem("companyId"))
    );
    dispatch(LookUpActions.getLookUpData());
    dispatch(
      SettingsActions.getCompanyLocationsData(localStorage.getItem("companyId"))
    );
  };

  const handleValidations = (name, value) => {
    const { errors } = state;
    if (name === "portfolioName") {
      errors[name] = errors[name] || {};
      if (name === "portfolioName" && !value) {
        errors[name] = { error: true, message: "" };
        setState({
          ...state,
        });
        return;
      }
      errors[name] = { error: false, message: "" };
      setState({
        ...state,
      });
      return;
    }
  };

  // Method responsible to return categorized menu items
  const renderCategorizedMenu = (menuData, type) => {
    // list of color codes to be appied for category tiles based on index
    const colorCodes = get(lookUpData, "configData.dropdownColorCodes", []);
    // fetching category names
    const groups = Object.keys(menuData)
      .filter((each) => each !== "noParent")
      .sort();
    // pushing nonParent items to array
    let menuItems = menuData.noParent
      ? menuData.noParent.map((each) => each)
      : [];

    groups.map((eachGroup, index) => {
      // interating group titles and pushint to menu item array
      let subGroupItems = [{ name: eachGroup, color: colorCodes[index] }];
      menuData[eachGroup].map((eachGroupItem) => {
        // iterating subgroup items
        subGroupItems.push(eachGroupItem);
      });
      // concatinating menuItems which are returned and categorized items
      menuItems = [...menuItems, ...subGroupItems];
      return null;
    });

    // mapping menuItems (objects) and returning JSX
    return menuItems.map((eachItem) => {
      return (
        <MenuItem
          style={{ background: eachItem.color }}
          onClick={!eachItem.color && handleDropdownSelect(type, eachItem.name)}
          className={eachItem.color && classes.textCapital}
        >
          {eachItem.name}
        </MenuItem>
      );
    });
  };

  const handleFeedbackModal = () => {
    setState({
      ...state,
      toggleFeedbackModal: !toggleFeedbackModal,
    });
  };

  const hasFormErrors = () => {
    if (!portfolioName || !location || (!isNewCompany && !list)) {
      return true;
    }
    return false;
  };

  const hasError = hasFormErrors();

  // API
  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    let userData = localStorage.getItem("profile");
    if (userData !== "undefined") {
      userData = JSON.parse(userData);
    } else {
      userData = {};
    }
    if (CompanyListByUser) {
      const adminInfo = CompanyListByUser.filter((each) => {
        return each.name === localStorage.getItem("companyName");
      });
      setState({
        ...state,
        userInfo: userData,
        administrator: get(adminInfo[0], "companyAdmins[0]", ""),
        selectedRegions: [
          ...get(locationsData, "companyInfo.legacyMarkets", ""),
        ],
        selectedPortfolio: get(portfolioList, "portfolios[0]"),
      });
    }
  }, [CompanyListByUser, locationsData, portfolioList]);

  const handleAddRegionClick = () => {
    const { category, region, selectedRegions } = state;
    setLimitError(false);
    state.errors.selectedRegionsError = { error: false, message: "" };
    let foundRegion = find(selectedRegions, { region: region });
    if (foundRegion) {
      let foundCategory = filter(
        foundRegion.category,
        (each) => each === category
      )[0];
      if (foundCategory) {
        return;
      }
      foundRegion.category = foundRegion.category || [];
      foundRegion.category = foundRegion.category || [];
      if (foundRegion.category.length >= 4) {
        setLimitError(true);
        return;
      }
      foundRegion.category = [...foundRegion.category, category];
      setState({
        ...state,
        category: "",
        region: "",
      });
      setLimitError(false);
    } else {
      selectedRegions.category = selectedRegions.category || [];
      setState({
        ...state,
        selectedRegions: [
          ...selectedRegions,
          {
            region,
            category: [...selectedRegions.category, category],
          },
        ],
        category: "",
        region: "",
      });
    }
  };

  const deleteRegion = (region, categoryName) => () => {
    const { selectedRegions } = state;
    setLimitError(false);
    const foundRegion = find(selectedRegions, { region });

    if (foundRegion) {
      foundRegion.category = foundRegion.category.filter(
        (each) => each !== categoryName
      );
    }

    if (foundRegion.category.length === 0) {
      const filteredRegions = selectedRegions.filter(
        (each) => each.region !== region
      );
      setState({ ...state, selectedRegions: [...filteredRegions] });
      return;
    }

    setState({ ...state });
  };

  const handleDropdownSelect = (name, value) => (e) => {
    e.preventDefault();
    e.stopPropagation();
    // if (name === "selectedPortfolio" && value) {
    //   localStorage.setItem("portfolioId", value._id);
    //   history.push("/portfolioView");
    // } else {
    //   localStorage.removeItem("portfolioId");
    // }
    state.errors.listData = { error: false, message: "" };
    setState({ ...state, [name]: value });
  };

  const redirectPortfolio = (value) => () => {
    localStorage.setItem("portfolioId", value._id);
    history.push("/portfolioView");
  };

  const returnRegionsList = () => {
    const { selectedRegions } = state;
    return (
      <div>
        <ol className={classes.regionListAlign}>
          {map(selectedRegions, (location) => {
            return (
              <li>
                {get(location, "region", "")} -{" "}
                {map(location?.category, (category) => {
                  return (
                    <>
                      <span className={classes.categoryAlign}>
                        {category}{" "}
                        <Styled_cross
                          className={classes.coloredCrossStyle}
                          onClick={deleteRegion(location.region, category)}
                        />
                      </span>
                    </>
                  );
                })}
              </li>
            );
          })}
        </ol>
      </div>
    );
  };

  const handleInputChange = (e) => {
    if (e.target.name === "isNewCompany") {
      setState({
        ...state,
        [e.target.name]: e.target.value === "true",
        list: "",
      });
      return;
    }
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const handleValidationOnBlur = (e) => {
    handleValidations(e.target.name, e.target.value);
  };

  const selectedLocation = (location, name, locationObj) => () => {
    setState({ ...state, [name]: location, locationObj });
  };

  return (
    <>
      <div className={classes.portfolio_block}>
        <div className={classes.completed_align}>
          <Text
            size={16}
            color={color.primary_palette.black}
            family="gillsans_r"
          >
            The last Portfolio you built.
          </Text>
          <Text
            size={16}
            color={color.primary_palette.black}
            family="gillsans_r"
            transform="uppercase"
          >
            {get(portfolioList, "portfolios[0].name")}
          </Text>
          <Text
            size={16}
            color={color.primary_palette.tricks_red}
            family="gillsans_sb"
          >
            {get(portfolioList, "portfolios[0].completion", "0")}% Complete
          </Text>
        </div>
        <Row>
          <Row className={classes.left_side}>
            <Row className={classes.left_text}>
              <Text
                size={12}
                color={color.primary_palette.franklin_purple}
                family="avenir_book_r"
                className={classes.company_snapshot}
              >
                {strings.build.titles.company_snapshot}
              </Text>
              <Text
                size={12}
                color={color.primary_palette.franklin_purple}
                family="avenir_book_r"
                className={classes.profile_cover}
              >
                {strings.build.titles.profile_cover}
              </Text>
              <Text
                size={12}
                color={color.primary_palette.franklin_purple}
                family="avenir_book_r"
                className={classes.cover_photo}
              >
                {strings.build.titles.cover_photo}
              </Text>
              <Text
                size={12}
                color={color.primary_palette.franklin_purple}
                family="avenir_book_r"
                className={classes.phil_align}
              >
                {strings.build.titles.company_phil}
              </Text>
              <Text
                size={12}
                color={color.primary_palette.franklin_purple}
                family="avenir_book_r"
                className={classes.business_card}
              >
                {strings.build.titles.business_card}
              </Text>
              <Text
                size={12}
                color={color.primary_palette.franklin_purple}
                family="avenir_book_r"
                className={classes.vignettes}
              >
                {strings.build.titles.vignettes}
              </Text>
              <Text
                size={12}
                color={color.primary_palette.franklin_purple}
                family="avenir_book_r"
                className={classes.unliited_projects}
              >
                {strings.build.titles.unliited_projects}
              </Text>
              <Text
                size={12}
                color={color.primary_palette.franklin_purple}
                family="avenir_book_r"
                className={classes.on_summary}
              >
                {strings.build.titles.on_summary}
              </Text>
              <Text
                size={12}
                color={color.primary_palette.franklin_purple}
                family="avenir_book_r"
                className={classes.products}
              >
                {strings.build.titles.products}
              </Text>
              <Text
                size={12}
                color={color.primary_palette.franklin_purple}
                family="avenir_book_r"
                className={classes.instagram}
              >
                {strings.build.titles.instagram}
              </Text>
            </Row>
            <Row className={classes.static_img}>
              <img
                src="/assets/images/latest_sample_portfolio.jpg"
                alt=""
                className={classes.static_img}
              />
            </Row>
          </Row>
          <Row className={classes.right_side}>
            <img
              src="/assets/images/Beta_Stamp.png"
              className={classes.betaStampIcon}
              alt=""
              onClick={handleFeedbackModal}
            />
            <Row className={classes.block_group}>
              {localStorage.getItem("isCompletePortfolio") ? (
                <Text
                  size={30}
                  color={color.primary_palette.black}
                  family="avenir_light"
                  className={classes.build_text}
                  transform="uppercase"
                >
                  {strings.build.titles.another_portfolio}
                </Text>
              ) : (
                <Text
                  size={30}
                  color={color.primary_palette.black}
                  family="avenir_light"
                  className={classes.build_text}
                  transform="uppercase"
                >
                  {strings.build.titles.work_portfolio}
                </Text>
              )}

              <Text
                size={16}
                color={color.primary_palette.black}
                family="gillsans_r"
              >
                {strings.build.titles.other_companies_1}&nbsp;
                {localStorage.getItem("companyName")}.
              </Text>
              <Text
                size={16}
                color={color.primary_palette.black}
                family="gillsans_r"
                className={classes.build_des_1}
              >
                {strings.build.titles.other_companies}
              </Text>
              <Row className={classes.textsBlockAlign}>
                <div>
                  <div className={classes.dupeBlockAlign}>
                    <Text
                      size={20}
                      color={color.primary_palette.franklin_purple}
                      family="avenir_bold"
                      transform="uppercase"
                    >
                      {strings.build.titles.take_back}
                    </Text>
                    <Row>
                      <CustomSelect
                        className={`${classes.portfolio_items} ${classes.width_567}`}
                        IconComponent={Chevron_Down_Icon}
                        name="selectedPortfolio"
                        value={selectedPortfolio}
                        renderValue={(value) =>
                          get(value, "name", "Select a Portfolio")
                        }
                        MenuProps={{
                          getContentAnchorEl: null,
                          disableScrollLock: true,
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                        }}
                      >
                        <CustomScrollbars
                          className={classes.customScrollHeight}
                        >
                          <MenuItem
                            onClick={handleDropdownSelect(
                              "selectedPortfolio",
                              ""
                            )}
                          >
                            Select a Portfolio
                          </MenuItem>
                          {portfolioList &&
                            portfolioList.portfolios &&
                            map(portfolioList.portfolios, (list) => {
                              return (
                                <MenuItem
                                  onClick={handleDropdownSelect(
                                    "selectedPortfolio",
                                    list
                                  )}
                                >
                                  <div className={classes.alignPortfolioMenu}>
                                    {list.name} - {get(list, "completion")}%
                                    complete{" "}
                                  </div>
                                  {get(list, "isVisibleToPublic") ? (
                                    <div className={classes.public}>Public</div>
                                  ) : (
                                    <div className={classes.notPublic}>
                                      Not Public
                                    </div>
                                  )}
                                </MenuItem>
                              );
                            })}
                        </CustomScrollbars>
                      </CustomSelect>{" "}
                      <img
                        alt=""
                        src="/assets/icons/icon_happy_arrow.svg"
                        className={classes.saveFlipIcon}
                        onClick={redirectPortfolio(selectedPortfolio)}
                      />
                    </Row>
                  </div>
                </div>
                <Text
                  size={25}
                  color={color.primary_palette.franklin_purple}
                  family="avenir_bold"
                  className={`${classes.orAlign} ${classes.textCenter}`}
                  transform="uppercase"
                >
                  OR
                </Text>
                <div>
                  <div className={classes.dupeBlockAlign}>
                    <Text
                      size={20}
                      color={color.primary_palette.franklin_purple}
                      family="avenir_bold"
                      className={classes.orAlign}
                      transform="uppercase"
                    >
                      {strings.build.titles.build_new}
                    </Text>
                    <Text
                      size={16}
                      color={color.primary_palette.black}
                      family="gillsans_sb"
                      className={classes.headerRadioAlign}
                    >
                      {strings.build.titles.dupe_portfolio} &nbsp;
                      {localStorage.getItem("companyName")}
                      {strings.build.titles.dupe_port}
                    </Text>
                    <Row className={classes.marginLeft16}>
                      <CustomRadioButton
                        label={strings.build.titles.start_with}
                        labelPlacement="end"
                        size="large"
                        name="isNewCompany"
                        value={"false"}
                        checked={isNewCompany === false}
                        onChange={handleInputChange}
                        className={classes.radioBtn}
                        className={
                          get(state.errors, "listData.error", "")
                            ? classes.radioBtn_error
                            : classes.radioBtn
                        }
                      />
                      <CustomSelect
                        className={classes.portfolio_name_select}
                        IconComponent={Chevron_Down_Icon}
                        name="list"
                        value={list}
                        renderValue={(value) =>
                          get(value, "name", "Select a Portfolio")
                        }
                        MenuProps={{
                          getContentAnchorEl: null,
                          disableScrollLock: true,
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                        }}
                      >
                        <CustomScrollbars
                          className={classes.customScrollHeight}
                        >
                          {portfolioList &&
                            portfolioList.portfolios &&
                            map(portfolioList.portfolios, (list) => {
                              return (
                                <MenuItem
                                  onClick={handleDropdownSelect("list", list)}
                                >
                                  {list.name} - {get(list, "completion")}%
                                  complete-{" "}
                                  {get(list, "isVisibleToPublic") ? (
                                    <span className={classes.public}>
                                      Public
                                    </span>
                                  ) : (
                                    <span className={classes.notPublic}>
                                      Not Public
                                    </span>
                                  )}
                                </MenuItem>
                              );
                            })}
                        </CustomScrollbars>
                      </CustomSelect>
                    </Row>
                    <Row className={classes.marginLeft16}>
                      <CustomRadioButton
                        label={strings.build.titles.start_new}
                        labelPlacement="end"
                        size="large"
                        name="isNewCompany"
                        value={"true"}
                        checked={isNewCompany}
                        onChange={handleInputChange}
                        className={classes.radioBtn}
                      />
                    </Row>

                    <Text
                      size={16}
                      color={color.primary_palette.black}
                      family="gillsans_sb"
                      className={classes.headerTextAlign}
                    >
                      {strings.build.titles.pick_location}
                    </Text>

                    <CustomSelect
                      className={classes.portfolio_items}
                      IconComponent={Chevron_Down_Icon}
                      name="location"
                      value={location}
                      renderValue={(value) => value || "Location"}
                      MenuProps={{
                        getContentAnchorEl: null,
                        disableScrollLock: true,
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                      }}
                    >
                      <CustomScrollbars className={classes.customScrollHeight}>
                        {locationsData &&
                          map(locationsData.businessCards, (location) => {
                            return (
                              <MenuItem
                                onClick={selectedLocation(
                                  get(location, "nickname", "") ||
                                    get(location, "name", ""),
                                  "location",
                                  location
                                )}
                              >
                                {get(location, "nickname", "") ||
                                  get(location, "name", "")}
                              </MenuItem>
                            );
                          })}
                        <MenuItem
                          className={classes.txtRight}
                          onClick={redirectToNewCompany}
                        >
                          <Text
                            size={15}
                            color={color.primary_palette.black}
                            family="gillsans_sb"
                          >
                            <Lil_Plus_filled className={classes.lil_filled} />{" "}
                            {strings.build.titles.add_another_office}
                          </Text>
                        </MenuItem>
                      </CustomScrollbars>
                    </CustomSelect>

                    {/* {locationsData &&
                      locationsData.businessCards &&
                      locationsData.businessCards?.length > 0 && (
                        <CustomAccordion
                          className={classes.invoicing_accordion}
                          upIconDisplay={classes.upIconDispalyNone}
                          title={
                            get(location, "nickname", "") ||
                            get(location, "name", "")
                          }
                        >
                          <div className={classes.invoicing}>
                            {locationsData.businessCards?.map((location) => (
                              <Text
                                size={15}
                                color={color.primary_palette.black}
                                family="gillsans_r"
                                className={classes.txtRight}
                                onClick={selectedLocation(location, "location")}
                              >
                                <span className={classes.dropdown_nickname}>
                                  {get(location, "nickname", "") ||
                                    get(location, "name", "")}
                                </span>
                              </Text>
                            ))}

                            <div className={classes.txtRight}>
                              <Text
                                size={15}
                                color={color.primary_palette.black}
                                family="gillsans_sb"
                                className={classes.spacing_add_another_office}
                                onClick={redirectToNewCompany}
                              >
                                <Lil_Plus_filled
                                  className={classes.lil_filled}
                                />{" "}
                                {strings.build.titles.add_another_office}
                              </Text>
                            </div>
                          </div>
                        </CustomAccordion>
                      )} */}
                    <Text
                      size={16}
                      color={
                        get(state.errors, "displayedAt.error", "")
                          ? color.primary_palette.christmas_red
                          : color.primary_palette.black
                      }
                      family="gillsans_sb"
                      className={classes.headerTextAlign}
                    >
                      {strings.build.titles.services_showcased}
                    </Text>

                    <Row className={classes.marginLeft16}>
                      <CustomSelect
                        className={`${classes.select_items} ${classes.width_155}`}
                        IconComponent={Chevron_Down_Icon}
                        name="region"
                        value={region}
                        renderValue={(value) =>
                          value ? (
                            <span className={classes.colorPurple}>{value}</span>
                          ) : (
                            <span>Market</span>
                          )
                        }
                        MenuProps={{
                          getContentAnchorEl: null,
                          disableScrollLock: true,
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                        }}
                      >
                        <CustomScrollbars
                          className={classes.customScrollHeight}
                        >
                          {lookUpData &&
                            map(lookUpData.regions, (region) => {
                              return (
                                <MenuItem
                                  onClick={handleDropdownSelect(
                                    "region",
                                    region.name
                                  )}
                                >
                                  {region.name}
                                </MenuItem>
                              );
                            })}
                        </CustomScrollbars>
                      </CustomSelect>
                      <CustomSelect
                        className={`${classes.select_items} ${classes.width_270}`}
                        IconComponent={Chevron_Down_Icon}
                        name="category"
                        value={category}
                        renderValue={(value) =>
                          value ? (
                            <span className={classes.colorPurple}>{value}</span>
                          ) : (
                            <span>Category</span>
                          )
                        }
                        MenuProps={{
                          getContentAnchorEl: null,
                          disableScrollLock: true,
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                        }}
                      >
                        <CustomScrollbars
                          className={classes.customScrollHeightCategory}
                        >
                          {lookUpData &&
                            renderCategorizedMenu(
                              lookUpData.trades,
                              "category"
                            )}
                        </CustomScrollbars>
                      </CustomSelect>
                      <CustomButton
                        className={classes.add_btn}
                        onClick={category && region && handleAddRegionClick}
                      >
                        {strings.business_card.titles.add}
                      </CustomButton>
                    </Row>
                    {limitError && (
                      <Text
                        color={color.primary_palette.christmas_red}
                        size={15}
                        family="avenir_bold"
                        className={classes.marginTop6}
                      >
                        <img
                          src="/assets/icons/info_1.svg"
                          class="info_img"
                          alt=""
                          className={classes.marginLeft4}
                        />
                        Maximum four categories per market
                      </Text>
                    )}
                    {returnRegionsList()}
                    <Text
                      size={16}
                      color={
                        get(state.errors, "portfolioName.error", "")
                          ? color.primary_palette.christmas_red
                          : color.primary_palette.black
                      }
                      family="gillsans_sb"
                      className={classes.headerTextAlign}
                    >
                      {strings.build.titles.portfolio_name}
                    </Text>
                    <CustomTextField
                      name="portfolioName"
                      className={
                        get(state.errors, "portfolioName.error", "")
                          ? classes.portfolioNameStyle_error
                          : classes.portfolioNameStyle
                      }
                      placeholder={strings.business_card.titles.new_port_name}
                      value={portfolioName}
                      onFocus={(e) => (e.target.placeholder = "")}
                      onChange={handleInputChange}
                      onBlur={handleValidationOnBlur}
                    />

                    {get(userInfo, "firstName") !==
                      get(administrator, "firstName") && (
                      <Text
                        size={16}
                        color={color.primary_palette.black}
                        family="gillsans_sb"
                        className={classes.headerTextAlign}
                      >
                        {strings.build.titles.please_coordinate}{" "}
                        {get(administrator, "firstName")}{" "}
                        {get(administrator, "lastName")}, current administrator
                        of &nbsp;
                        {localStorage.getItem("companyName")}
                      </Text>
                    )}
                  </div>
                </div>
              </Row>
            </Row>

            {errorMessage && (
              <div className={classes.error}>{errorMessage}</div>
            )}
            <Row className={classes.justify}>
              <CustomButton
                className={classes.build_btn}
                onClick={saveDupeData}
              >
                {strings.build.titles.lets_go}
              </CustomButton>
            </Row>
          </Row>
        </Row>
      </div>
      <CustomModal
        open={toggleFeedbackModal}
        onClose={handleFeedbackModal}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Feedback
          open={toggleFeedbackModal}
          onClose={handleFeedbackModal}
          locationInfo="New Portfolio Page"
        />
      </CustomModal>
    </>
  );
}

export default PortFolioStyles(NewPortfolio);
