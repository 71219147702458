import React, { useRef } from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import { get } from "lodash";

import DefaultLanding from "../components/layouts/default_landing";
import Stepper from "../components/stepper/stepper";
import Header from "../components/landing_header/landing_header";
import RedirectRoute from "./RedirectRoute";
import { Loading } from "../components/common";
import AddProjects from "../components/add_new_project/add_project";
import PublicViewProjects from "../components/add_new_project/publicView_project";
import PublicView from "../components/product_publications/pro_pub_PublicView";
import FrView from "../components/build_portfolio/fr_view";
import ProjectSingleView from "../components/add_new_project/individual_project_view";
import PrivateRoute from "./private_route";
import PorfolioPublicView from "../components/build_portfolio/portfolio_publicView";
import SettingNav from "../components/settings/setting_nav";
import CompanyRedirect from "../components/settings/company_redirect";
import NewPortfolio from "../components/build_portfolio/new_portfolio";
import PortfolioMoney from "../components/portfolioMoneyModal/portfolioMoney";
import Invoice from "../components/invoice/invoice";
import EnterNewPassword from "../components/recover_password/newPassword";
import PortView from "../components/build_portfolio/port_view";
import PublicPortfolioView from "../components/publicNav/publicView";
import ActAsUser from "../pages/ActAsUser/ActAsUser";
import RenewalEmailGenerator from "../pages/RenewalEmailGenerator/RenewalEmailGenerator";
import HorseLoading from "../components/common/portfolio_loading";
import MobilePorfolio from "../components/mobile_device/Mobile_portfiolo";
import {
  FRANKLIN_DEFAULT_QA,
  FRANKLIN_DEFAULT_STAGE,
  FRANKLIN_DEFAULT_PROD,
} from "../constants";
import MobileProjects from "../components/mobile_device/MobileProjects";
import MobileProduct from "../components/mobile_device/MobileProduct";
import MobileIndividualProject from "../components/mobile_device/MobileIndividualProject";

export default function Router(props) {
  const loginRef = useRef(null);
  const loading = useSelector((state) => state.Api.loading);
  const horseLoading = useSelector((state) => state.Api.horseLoading);
  const hasExistingPortfolio = localStorage.getItem("hasExistingPortfolio");

  return (
    <BrowserRouter>
      <Loading loading={loading} />
      <HorseLoading loading={horseLoading} />
      {window.outerWidth > 400 && <Header loginRef={loginRef} />}
      <Switch>
        <PrivateRoute exact path="/">
          <DefaultLanding loginRef={loginRef} />
        </PrivateRoute>
        <PrivateRoute
          exact
          path="/home"
          component={DefaultLanding}
          state={{ from: props.location }}
        />
        <PrivateRoute
          exact
          path="/actAsUser"
          component={ActAsUser}
          state={{ from: props.location }}
        />
        <PrivateRoute
          exact
          path="/renewalEmailGenerator"
          component={RenewalEmailGenerator}
          state={{ from: props.location }}
        />
        <Route
          path="/frlogin"
          state={{ from: props.location }}
          component={FrView}
        />
        <Route
          path="/login"
          state={{ from: props.location }}
          component={FrView}
        />
        <Route
          path="/joinus"
          state={{ from: props.location }}
          component={FrView}
        />
        <Route
          path="/frlogin/review"
          state={{ from: props.location }}
          component={FrView}
        />
        <Route
          path="/frlogin/newPortfolio"
          state={{ from: props.location }}
          component={FrView}
        />
        <Route
          path="/frjoinus"
          state={{ from: props.location }}
          component={FrView}
        />

        <Route
          path="/resetPassword/:resetToken"
          state={{ from: props.location }}
          component={EnterNewPassword}
        />

        <PrivateRoute
          exact
          path="/logout"
          component={(logoutProps) => {
            localStorage.removeItem("userToken");
            localStorage.removeItem("active");
            localStorage.removeItem("user_data");
            localStorage.removeItem("isPublicMode");
            localStorage.removeItem("Review");
            localStorage.removeItem("NewPortfolio");
            localStorage.removeItem("profile");
            localStorage.removeItem("userType");
            localStorage.removeItem("frpage");
            localStorage.removeItem("companyId");
            localStorage.removeItem("companyName");
            localStorage.removeItem("portfolioId");
            localStorage.removeItem("customerCategory");
            // logoutProps.history.push("/");
            let host = get(window, "location.host", "");
            if (host) {
              if (host.includes("localhost")) {
                window.location.href = FRANKLIN_DEFAULT_QA;
              } else if (host.includes("-dev-")) {
                window.location.href = FRANKLIN_DEFAULT_QA;
              } else if (host.includes("-qa-")) {
                window.location.href = FRANKLIN_DEFAULT_QA;
              } else if (host.includes("-stage-")) {
                window.location.href = FRANKLIN_DEFAULT_STAGE;
              } else if (host.includes("franklinreport")) {
                window.location.href = FRANKLIN_DEFAULT_PROD;
              }
            }
          }}
          state={{ from: props.location }}
        />
        <PrivateRoute
          path="/portfolioView"
          state={{ from: props.location }}
          component={Stepper}
        />
        <PrivateRoute
          path="/AddProjects"
          state={{ from: props.location }}
          component={AddProjects}
        />

        <PrivateRoute
          path="/PublicView"
          state={{ from: props.location }}
          component={PublicViewProjects}
        />
        <PrivateRoute
          path="/PublicViewProducts"
          state={{ from: props.location }}
          component={PublicView}
        />
        <Route
          path="/ProjectSingleView"
          state={{ from: props.location }}
          component={ProjectSingleView}
        />
        <Route
          path="/public-portfolio/:market/:slug/:frReview/:vendorId"
          state={{ from: props.location }}
          component={
            window.outerWidth < 400 ? MobilePorfolio : PorfolioPublicView
          }
        />
        <Route
          path="/public-portfolio/:market/:slug"
          state={{ from: props.location }}
          component={
            window.outerWidth < 400 ? MobilePorfolio : PorfolioPublicView
          }
        />
        <Route
          path="/mobile-projects"
          state={{ from: props.location }}
          component={MobileProjects}
        />
        <Route
          path="/mobile-overview"
          state={{ from: props.location }}
          component={MobilePorfolio}
        />
        <Route
          path="/mobile-products-publications"
          state={{ from: props.location }}
          component={MobileProduct}
        />
        <Route
          path="/mobile-individual-project"
          state={{ from: props.location }}
          component={MobileIndividualProject}
        />
        <Route
          path="/mobile-view/:market/:slug"
          state={{ from: props.location }}
          component={MobilePorfolio}
        />
        <Route
          path="/publicPortfolioView"
          state={{ from: props.location }}
          component={PublicPortfolioView}
        />
        <Route
          path="/public-portfolio-view"
          state={{ from: props.location }}
          component={PortView}
        />
        <PrivateRoute
          path="/portfolio-redirect"
          state={{ from: props.location }}
          component={
            hasExistingPortfolio === "true" ? NewPortfolio : DefaultLanding
          }
        />
        <PrivateRoute
          path="/settings"
          state={{ from: props.location }}
          component={SettingNav}
          exact
        />
        <PrivateRoute
          path="/PortfolioMoney"
          state={{ from: props.location }}
          component={PortfolioMoney}
        />
        <PrivateRoute
          path="/settings/:type"
          state={{ from: props.location }}
          component={SettingNav}
          exact
        />
        <PrivateRoute
          path="/settings/company/:companyId/:companyName"
          state={{ from: props.location }}
          component={CompanyRedirect}
          exact
        />
        <PrivateRoute
          path="/NewPortfolio"
          state={{ from: props.location }}
          component={NewPortfolio}
        />
        <PrivateRoute exact path="/cpack/:token" component={RedirectRoute} />
        <PrivateRoute
          exact
          path="/lpack/profile/:token"
          component={RedirectRoute}
        />
        <Route
          exact
          path="/invoice"
          state={{ from: props.location }}
          component={Invoice}
        />
        <Redirect from="*" to="/" />
      </Switch>
    </BrowserRouter>
  );
}
