import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { get } from "lodash";
import { color } from "../../utilities/themes";
import EditInfoStyles from "./styles/editInfoModalStyles";
import Text from "../common/ui_kit/text";
import strings from "../../utilities/strings";
import Close_Icon from "../data_display/icons/Close";
import CustomTextField from "../../components/inputs/custom_textfields";
import CustomButton from "../navigations/custom_buttons";
import { InputAdornment } from "@material-ui/core";
import Chevron_Down_Icon from "../data_display/icons/Arrow_Chevron_Down";

import { ProfileActions } from "../../redux/actions";
import {
  REGEX_EMAIL,
  PHONE_NUMBER_FORMAT,
} from "../../validations/validations";
import Edit_Circle_Icon from "../data_display/icons/Edit";

function EditInfo(props) {
  const { classes, onClose, personalSettingsData } = props;
  const [state, setState] = useState({
    firstName: "",
    lastName: "",
    userName: "",
    email: "",
    phoneNumber: "",
    errors: {},
    errorMessage: "",
    countryCode: "USA",
  });

  const {
    firstName,
    lastName,
    userName,
    email,
    phoneNumber,
    errors,
    errorMessage,
    countryCode,
    showCountryDropdown,
  } = state;

  const dispatch = useDispatch();

  useEffect(() => {
    setState({
      firstName: get(personalSettingsData, "firstName", ""),
      lastName: get(personalSettingsData, "lastName", ""),
      userName:
        get(personalSettingsData, "userName") ||
        get(personalSettingsData, "email"),
      email: get(personalSettingsData, "email", ""),
      phoneNumber: get(personalSettingsData, "phoneNumber", ""),
      countryCode: get(personalSettingsData, "countryCode", "USA"),
      errors: {},
    });
  }, [personalSettingsData]);

  // input change event to get value of input
  const handleInputChange = (e) => {
    handleValidation(e.target.name, e.target.value);
    const { name, value } = e.target;
    if (name === "phoneNumber") {
      const val = deformatPhoneNumber(value);
      if (isNaN(val)) {
        if (!val) {
          setState({ ...state, phoneNumber: "" });
        }
        return;
      }
      setState({ ...state, phoneNumber: val });
      return;
    }
    setState({
      ...state,
      [name]: value,
      errorMessage: "",
    });
  };

  const triggerCountryDropdown = () => {
    const { showCountryDropdown } = state;
    setState({ ...state, showCountryDropdown: !showCountryDropdown });
  };

  const handleCountryChange = (value) => {
    const { showCountryDropdown } = state;
    setState({
      ...state,
      countryCode: value,
      phoneNumber: "",
      showCountryDropdown: !showCountryDropdown,
    });
  };

  const onPhoneNumberBlur = () => {
    // FORMATTING PHONE NUMBER
    const { phoneNumber } = state;
    if (phoneNumber) {
      const num = phoneNumber
        .toString()
        .replace(PHONE_NUMBER_FORMAT, "$1.$2.$3");
      setState({ ...state, phoneNumber: num });
    }
    return;
  };

  const formatPhoneNumber = () => {
    const { phoneNumber } = state;
    return phoneNumber.replace(PHONE_NUMBER_FORMAT, "$1.$2.$3");
  };

  const deformatPhoneNumber = (number) => {
    // DEFORMATTING
    if (!number) {
      return;
    }
    return number.replace(/[().\s/]/g, "");
  };

  const onPhoneNumberFocus = () => {
    // ON FOCUS REMOVING FORMAT
    const { phoneNumber } = state;
    if (phoneNumber) {
      setState({ ...state, phoneNumber: phoneNumber.replace(/[().\s/]/g, "") });
      return;
    }
  };

  const saveBasicDetails = () => {
    const dataToSubmit = {
      ...state,
    };

    dispatch(
      ProfileActions.savePersonalSettingData(dataToSubmit, (res) => {
        if (!res.error.error) {
          onClose();
          dispatch(ProfileActions.getPersonalSettingData());
        } else {
          let errors = {};
          if (res.error.message.code && res.error.message.code == 1031) {
            errors["userName"] = {
              error: true,
              message: res.error.message.message,
            };
          }
          setState({
            ...state,
            errorMessage: res.error.message.message,
            errors: errors,
          });
        }
      })
    );
  };

  const handleValidation = (name, value) => {
    if (name === "firstName" || name === "lastName") {
      // VALIDATION FOR FIRST NAME AND LAST NAME
      if (!value || value.length > 35) {
        errors[name] = { error: true, message: "" };
        setState({ ...state });
        return;
      } else {
        errors[name] = { error: false, message: "" };
        setState({ ...state });
        return;
      }
    }
    if (name === "userName") {
      if (!value && value.length > 35) {
        errors[name] = { error: true, message: "" };
        setState({ ...state });
        return;
      } else {
        errors[name] = { error: false, message: "" };
        setState({ ...state });
        return;
      }
    }
    if (name === "email") {
      // VALIDATION FOR EMAIL
      if (!value) {
        errors[name] = {
          error: true,
          // message: strings.join_us.errors.email_address_required,
          touched: true,
        };
        setState({ ...state });
        return;
      }
      if (value) {
        // IF EMAIL HAS VALUE
        const regexTest = REGEX_EMAIL.test(value);
        if (!regexTest) {
          errors[name] = {
            error: true,
            // message: strings.join_us.errors.invalid_email_address,
            touched: true,
          };
        } else {
          errors[name] = {
            error: false,
            message: "",
            touched: true,
          };
          setState({ ...state });
        }
      }
    }
    if (name === "phoneNumber") {
      if (
        (countryCode === "USA" && value.length !== 10) ||
        (countryCode === "nonUSA" && (value.length < 10 || value.length > 20))
      ) {
        errors[name] = {
          error: true,
          message: "",
          touched: true,
        };
      } else {
        errors[name] = {
          error: false,
          message: "",
          touched: true,
        };
      }
      setState({ ...state });
    }
  };

  const hasFormErrors = () => {
    const allErrors =
      errors &&
      Object.values(errors || {}).map((errObj) => errObj?.error || false);
    if (allErrors && allErrors.includes(true)) {
      return true;
    }
    return false;
  };

  const disableSubmit = hasFormErrors();
  return (
    <>
      <div className={classes.product_cover}>
        <Edit_Circle_Icon className={classes.edit_icon} />
        <div className={classes.textRight}>
          <CustomButton className={classes.cross_btn} onClick={onClose} isX>
            <Close_Icon className={classes.cross_mark} onClick={onClose} />
          </CustomButton>
        </div>
        <div className={classes.displayBlock}>
          <Text
            size={24}
            color={color.primary_palette.franklin_purple}
            family="avenir_bold"
          >
            {strings.settings.titles.edit_personal_info}
          </Text>
        </div>
        <div className={classes.inputBlocksAlign}>
          <div className={classes.textBlockAlign}>
            <Text
              size={15}
              color={
                get(errors, "firstName.error", false)
                  ? color.primary_palette.christmas_red
                  : color.primary_palette.black
              }
              family="gillsans_sb"
              className={classes.txtAlign}
            >
              {strings.settings.titles.first_name}
            </Text>
            <CustomTextField
              className={`${
                get(errors, "firstName.error", false)
                  ? classes.textFieldAlign_error
                  : classes.textFieldAlign
              }`}
              onChange={handleInputChange}
              name="firstName"
              value={firstName}
            />
          </div>
          <div className={classes.textBlockAlign}>
            <Text
              size={15}
              color={
                get(errors, "lastName.error", false)
                  ? color.primary_palette.christmas_red
                  : color.primary_palette.black
              }
              family="gillsans_sb"
              className={classes.txtAlign}
            >
              {strings.settings.titles.last_name}
            </Text>
            <CustomTextField
              className={`${
                get(errors, "lastName.error", false)
                  ? classes.textFieldAlign_error
                  : classes.textFieldAlign
              }`}
              onChange={handleInputChange}
              name="lastName"
              value={lastName}
            />
          </div>
          <div className={classes.textBlockAlign}>
            <Text
              size={15}
              color={
                get(errors, "userName.error", false)
                  ? color.primary_palette.christmas_red
                  : color.primary_palette.black
              }
              family="gillsans_sb"
              className={classes.txtAlign}
            >
              {strings.settings.titles.username}
            </Text>
            <CustomTextField
              className={`${
                get(errors, "userName.error", false)
                  ? classes.textFieldAlign_error
                  : classes.textFieldAlign
              }`}
              onChange={handleInputChange}
              name="userName"
              value={userName}
            />
          </div>
          <div className={classes.textBlockAlign}>
            <Text
              size={15}
              color={
                get(errors, "email.error", false)
                  ? color.primary_palette.christmas_red
                  : color.primary_palette.black
              }
              family="gillsans_sb"
              className={classes.txtAlign}
            >
              {strings.settings.titles.primaryEmail}
            </Text>
            <CustomTextField
              className={`${
                get(errors, "email.error", false)
                  ? classes.textFieldAlign_error
                  : classes.textFieldAlign
              }`}
              onChange={handleInputChange}
              name="email"
              value={email}
            />
          </div>
          <div
            className={`${classes.textBlockAlign} ${classes.margin_bottom_16}`}
          >
            <Text
              size={15}
              color={
                get(errors, "phoneNumber.error", false)
                  ? color.primary_palette.christmas_red
                  : color.primary_palette.black
              }
              family="gillsans_sb"
              className={classes.txtAlign}
            >
              {strings.settings.titles.mainPhone}
            </Text>
            <CustomTextField
              className={`${
                get(errors, "phoneNumber.error", false)
                  ? `${classes.textFieldAlign_error} ${classes.width172}`
                  : `${classes.textFieldAlign} ${classes.width172}`
              }`}
              name="phoneNumber"
              value={
                countryCode === "USA" && get(phoneNumber, "length", 0) >= 10
                  ? formatPhoneNumber()
                  : phoneNumber
              }
              inputProps={{
                maxLength: countryCode === "USA" ? 10 : 20,
                onFocus: onPhoneNumberFocus,
              }}
              onChange={handleInputChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment
                    position="end"
                    tabIndex="-1"
                    aria-label="toggle password visibility"
                    className={classes.password_icon}
                  >
                    <div className={classes.phoneNumberActions}>
                      <img
                        src={
                          countryCode === "USA"
                            ? "/assets/images/usa-flag-icon.png"
                            : "/assets/images/nonUSA.PNG"
                        }
                        className={classes.countryImage}
                        alt="USA"
                      />
                      <Chevron_Down_Icon
                        className={classes.dropDownIcon}
                        onClick={triggerCountryDropdown}
                      />
                      {showCountryDropdown && (
                        <div className={classes.dropdownOptions}>
                          <div
                            onClick={() => {
                              handleCountryChange("USA");
                            }}
                          >
                            USA
                          </div>
                          <div
                            onClick={() => {
                              handleCountryChange("nonUSA");
                            }}
                          >
                            Non-USA
                          </div>
                        </div>
                      )}
                    </div>
                  </InputAdornment>
                ),
              }}
              onBlur={onPhoneNumberBlur}
            />
          </div>
        </div>
        <Text
          size={12}
          color={color.primary_palette.christmas_red}
          family="gillsans_sb"
          className={`${errorMessage ? "" : classes.hideField} ${
            classes.txtAlignCenter
          }`}
        >
          <span>
            <img
              src="/assets/icons/info_1.svg"
              className={classes.infoIcon}
              alt=""
            />{" "}
            {errorMessage}
          </span>
        </Text>
        <div className={classes.textCenter}>
          <CustomButton
            className={
              disableSubmit ? classes.deleteBtnDisabled : classes.deleteBtn
            }
            onClick={saveBasicDetails}
            disabled={disableSubmit}
          >
            {strings.add_project.titles.save_edits}
          </CustomButton>
        </div>
      </div>
    </>
  );
}

export default EditInfoStyles(EditInfo);
