import { withStyles } from "@material-ui/core/styles";
import tradework_theme, { pxToRem, color } from "../../../utilities/themes";
import { WING_ARROWS } from "../../../constants";

const styles = withStyles({
  textCenter: {
    textAlign: "center",
    width: "100%",
  },
  snapshopTextCenter: {
    textAlign: "center",
    width: "100%",
    marginTop: pxToRem(14),
    wordBreak: "break-word",
  },
  userView: {
    display: "block",
  },
  edit_mode_switch: {
    marginLeft: pxToRem(-8),
    "& .MuiGrid-container ": {
      "& .MuiSwitch-root": {
        marginLeft: pxToRem(0),
        marginRight: pxToRem(0),
      },
      position: "relative",
      "& .checked_dot": {
        top: pxToRem(-9),
        left: pxToRem(-7),
      },
      "& .MuiGrid-item": {
        "& label": {
          fontSize: pxToRem(15),
          ...tradework_theme.typography.styles.gillsans_sb,
          color: color.primary_palette.franklin_purple,
        },
      },
    },
  },
  checked_dot_next: {
    left: pxToRem(-9),
    top: pxToRem(6),
  },
  uncheck_dot_next: {
    left: pxToRem(-9),
    top: pxToRem(6),
  },
  client_btn: {
    width: pxToRem(143),
    height: pxToRem(26),
    borderRadius: pxToRem(12),
    border: `solid ${pxToRem(1)} ${color.form_colors.sucess_color}`,
    backgroundColor: color.primary_palette.white,
    padding: 0,
    "& .MuiButton-label": {
      fontSize: pxToRem(14),
      ...tradework_theme.typography.styles.avenir_sb,
      color: color.primary_palette.free_purple,
    },
  },
  justify_right: {
    justifyContent: "center",
    flexGrow: 1,
  },
  flex_grow: {
    flexGrow: 1,
  },
  edit_icon: {
    fontSize: pxToRem(30),
    cursor: "pointer",
    zIndex: "999",
  },
  add_icon: {
    height: pxToRem(30),
    cursor: "pointer",
    zIndex: "999",
  },
  textRight: {
    textAlign: "right",
    width: "100%",
  },
  seeAllAlign: {
    position: "absolute",
    right: pxToRem(-110),
    bottom: pxToRem(2),
  },
  seeLink: {
    textDecoration: "underline",
    cursor: "pointer",
    color: color.primary_palette.franklin_purple,
    fontSize: pxToRem(18),
    fontWeight: 900,
    ...tradework_theme.typography.styles.avenir_sb,
  },
  cover_bg: {
    width: "100%",
    position: "relative",
    zIndex: 9,
    "&:before": {
      width: "100%",
      height: "100%",
      backgroundColor: `rgba(0, 0,0 ,0.6)`,
      content: "''",
      position: "absolute",
      top: 0,
      left: 0,
    },
  },
  bgHeight: {
    height: pxToRem(647),
  },
  bgHeightPublic: {
    height: pxToRem(630),
  },
  cover_img: {
    width: "100%",
    height: pxToRem(647),
    // height: "100%",
    position: "absolute",
    left: 0,
    objectFit: "cover",
  },
  cover_img_public: {
    width: "100%",
    height: pxToRem(630),
    // height: "100%",
    position: "absolute",
    left: 0,
    objectFit: "cover",
  },
  left_side: {
    display: "block",
    position: "relative",
    width: pxToRem(363),
    // padding: pxToRem(18),
    backgroundColor: color.white_opacity,
    margin: `${pxToRem(0)} ${pxToRem(143)}`,
  },
  left_side_hide: {
    display: "block",
    position: "relative",
    width: pxToRem(363),
    // padding: pxToRem(18),
    backgroundColor: color.white_opacity,
    margin: `${pxToRem(0)} ${pxToRem(143)}`,
    visibility: "hidden",
  },
  profile_img: {
    width: pxToRem(175),
    height: pxToRem(175),
    borderRadius: "50%",
    marginLeft: "26%",
    marginRight: "50%",
    objectFit: "cover",
  },
  profile_img_public: {
    width: pxToRem(175),
    height: pxToRem(175),
    borderRadius: "50%",
    marginLeft: "25%",
    marginRight: "50%",
  },
  profile_spacing: {
    padding: `${pxToRem(10)} ${pxToRem(0)}`,
    position: "relative",
  },
  company_philosophy: {
    "& p:not(:first-child)": {
      paddingTop: pxToRem(15),
    },
  },
  profile_text: {
    paddingTop: pxToRem(10),
  },
  company_logo: {
    height: pxToRem(100),
    marginTop: pxToRem(-54),
    objectFit: "cover",
    left: pxToRem(232),
    position: "relative",
    border: `solid ${pxToRem(1)} ${color.primary_palette.black}`,
  },
  company_logo_public: {
    height: pxToRem(100),
    marginTop: pxToRem(9),
    border: `solid ${pxToRem(1)} ${color.primary_palette.black}`,
    position: "absolute",
    right: pxToRem(-30),
    top: pxToRem(-30),
  },
  connectPencilAlign: {
    marginLeft: pxToRem(20),
    cursor: "pointer",
    position: "absolute",
  },
  connectPencilAlign_add: {
    cursor: "pointer",
    marginRight: pxToRem(6),
  },
  position: {
    position: "relative",
  },
  company_edit: {
    position: "absolute",
    top: pxToRem(130),
    right: pxToRem(-26),
    zIndex: 9999999,
  },
  logo_edit: {
    position: "absolute",
    top: pxToRem(130),
    right: pxToRem(-30),
  },
  edit_icon_profile: {
    position: "absolute",
    bottom: pxToRem(30),
    left: "26%",
    cursor: "pointer",
  },
  ribbon_icon: {
    fontSize: pxToRem(36),
    bottom: pxToRem(26),
    right: "30%",
    position: "absolute",
    "& path": {
      fill: color.primary_palette.franklin_purple,
    },
  },
  right_side: {
    alignItems: "center",
    width: "50%",
    justifyContent: "flex-end",
  },
  vignettes_img: {
    backgroundColor: color.secondary_palette.grays.fafa_gray,
    width: pxToRem(124),
    height: pxToRem(120),
    margin: `${pxToRem(10)} ${pxToRem(10)}`,
    "& img": {
      width: "100%",
      height: "100%",
      objectFit: "cover",
    },
  },
  vignettes: {
    backgroundColor: color.secondary_palette.grays.portfolio_view_gray,
    display: "block",
    padding: `${pxToRem(21)} ${pxToRem(0)}`,
    position: "relative",
  },
  edit_icon_vignettes: {
    marginLeft: pxToRem(20),
    verticalAlign: "middle",
    cursor: "pointer",
  },
  edit_icon_vignettes_add: {
    marginRight: pxToRem(7),
    cursor: "pointer",
  },
  cursor: {
    cursor: "pointer",
  },
  edit_icon_product: {
    marginLeft: pxToRem(10),
  },
  vignettes_flex: {
    display: "flex",
    justifyContent: "center",
    flexFlow: "row wrap",
    width: pxToRem(1000),
    margin: "0 auto",
    marginTop: pxToRem(10),
    opacity: 1,
  },
  vignettes_flex_opacity: {
    display: "flex",
    justifyContent: "center",
    flexFlow: "row wrap",
    width: "84%",
    margin: "0 auto",
    marginTop: pxToRem(10),
    opacity: 0.46,
  },
  ellipses: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  insta_block: {
    margin: "0 auto",
    textAlign: "center",
    paddingBottom: pxToRem(20),
  },
  insta_img: {
    width: pxToRem(180),
    margin: "0 auto",
    marginBottom: pxToRem(12),
    height: pxToRem(218),
    display: "inline-block",
    "& img": {
      width: "100%",
      height: "100%",
      objectFit: "cover",
    },
  },
  products_block: {
    backgroundColor: color.secondary_palette.grays.portfolio_view_gray,
    position: "relative",
  },
  padding_bottom_40: {
    paddingBottom: pxToRem(40),
  },
  padding_bottom_20: {
    paddingBottom: pxToRem(8),
    paddingTop: pxToRem(0),
  },
  flagship_project: {
    backgroundColor: color.primary_palette.white,
    padding: `${pxToRem(35)} ${pxToRem(0)}`,
    position: "relative",
  },
  insta_underline: {
    ...tradework_theme.typography.styles.avenir_sb,
    textAlign: "center",
    fontSize: pxToRem(20),
    color: color.primary_palette.franklin_purple,
    cursor: "pointer",
  },
  insta_link: {
    textAlign: "center",
    padding: `${pxToRem(25)} ${pxToRem(0)}`,
    width: pxToRem(573),
    margin: "0 auto",
  },
  padding_bottom_13: {
    paddingBottom: pxToRem(13),
  },
  edit_btn: {
    height: pxToRem(22),
    width: pxToRem(208),
    marginRight: pxToRem(20),
    borderRadius: pxToRem(4),
    border: `solid ${pxToRem(1.6)} ${color.primary_palette.franklin_purple}`,
    backgroundColor: color.primary_palette.white,
    "& .MuiButton-label": {
      color: color.primary_palette.black,
      fontSize: pxToRem(15),
      ...tradework_theme.typography.styles.avenir_sb,
      textTransform: "capitalize",
    },
  },
  padding_bottom_60: {
    paddingBottom: pxToRem(48),
    paddingTop: pxToRem(0),
  },
  addProjectBtnDiv: {
    margin: "0 auto",
    textAlign: "center",
    marginTop: pxToRem(-40),
    marginBottom: pxToRem(44),
  },
  productsPadding: {
    paddingBottom: pxToRem(25),
    paddingTop: pxToRem(24),
  },
  icons_placements: {
    position: "absolute",
    left: "55%",
  },
  arrow_icon: {
    width: pxToRem(31),
    height: pxToRem(71),
    textAlign: "center",
    top: pxToRem(12),
    position: "relative",
    zIndex: 99,
  },
  arrow_public: {
    width: pxToRem(31),
    height: pxToRem(71),
    textAlign: "center",
    top: pxToRem(28),
    position: "relative",
    zIndex: 99,
  },
  envelope_icon: {
    width: pxToRem(27),
    height: pxToRem(27),
    paddingLeft: pxToRem(10),
    position: "relative",
    top: pxToRem(-4),
    cursor: "pointer",
  },
  edit_icon_cover: {
    position: "absolute",
    top: pxToRem(17),
    right: pxToRem(14),
    cursor: "pointer",
  },
  add_icon_Philosophy: {
    position: "absolute",
    right: pxToRem(24),
    cursor: "pointer",
  },
  philosophyTitleAlign: {
    width: pxToRem(120),
    position: "absolute",
    left: pxToRem(-120),
  },
  padding_15: {
    padding: `${pxToRem(0)} ${pxToRem(15)}`,
  },
  background_image: {
    position: "absolute",
    width: "100%",
    height: "100%",
  },
  portfolio_visibility: {
    ...tradework_theme.typography.styles.Helvetica,
    fontWeight: "bold",
    paddingRight: pxToRem(2),
  },
  insta_icon: {
    verticalAlign: "bottom",
    marginBottom: pxToRem(-4),
    paddingRight: pxToRem(9),
  },
  descriptionData: {
    height: pxToRem(170),
    maxHeight: pxToRem(170),
    overflow: "hidden",
    fontSize: pxToRem(12),
    ...tradework_theme.typography.styles.avenir_sb,
    paddingLeft: pxToRem(18),
    paddingRight: pxToRem(18),
    wordBreak: "break-word",
  },
  descriptionDataHeading: {
    overflow: "hidden",
    ...tradework_theme.typography.styles.avenir_sb,
    paddingLeft: pxToRem(18),
    paddingRight: pxToRem(18),
  },
  slider_width: {
    width: pxToRem(180),
    //height: pxToRem(208),
    outline: "none",
    textAlign: "initial",
    "&:hover": {
      "& .hover_text": {
        color: color.primary_palette.franklin_purple,
      },
    },
    "& .hover_text": {
      "&:hover": {
        color: color.primary_palette.franklin_purple,
      },
    },
  },
  subTxtHover: {
    "&:hover": {
      "& .hover_text": {
        color: color.primary_palette.franklin_purple,
      },
    },
  },
  slider_public_style: {
    display: "inline-block",
    marginRight: pxToRem(70),
  },
  sliderImgStyle: {
    width: pxToRem(182),
    margin: "0 auto",
    marginBottom: pxToRem(12),
    height: pxToRem(218),
  },
  instaSliderImgStyle: {
    width: pxToRem(208),
    margin: "0 auto",
    marginBottom: pxToRem(12),
    height: pxToRem(208),
  },
  slider: {
    width: pxToRem(766),
    margin: "0 auto",
    position: "relative",
    "& .slick-next": {
      "&:before": {
        content: `url("/assets/images/element_wing_right.png")`,
        width: pxToRem(30),
        height: pxToRem(107),
        transform: "rotate(0deg)  translateY(-64px)",
        display: " inline-block",
      },
    },
    "& .slick-prev": {
      left: pxToRem(-39),
      "&:before": {
        content: `url("/assets/images/element_wing_left.png")`,
        width: pxToRem(30),
        height: pxToRem(107),
        transform: "translateY(-64px)",
        display: " inline-block",
      },
    },
    "& .slick-dots": {
      bottom: 0,
    },
  },
  slider2Images: {
    width: pxToRem(484),
    margin: "0 auto",
    position: "relative",
    "& .slick-next": {
      "&:before": {
        content: `url("/assets/images/element_wing_right.png")`,
        width: pxToRem(30),
        height: pxToRem(107),
        transform: "rotate(0deg)  translateY(-64px)",
        display: " inline-block",
      },
    },
    "& .slick-prev": {
      left: pxToRem(-39),
      "&:before": {
        content: `url("/assets/images/element_wing_left.png")`,
        width: pxToRem(30),
        height: pxToRem(107),
        transform: "translateY(-64px)",
        display: " inline-block",
      },
    },
    "& .slick-dots": {
      bottom: 0,
    },
  },
  slider3Images: {
    width: pxToRem(725),
    margin: "0 auto",
    position: "relative",
    "& .slick-next": {
      "&:before": {
        content: `url("/assets/images/element_wing_right.png")`,
        width: pxToRem(30),
        height: pxToRem(107),
        transform: "rotate(0deg)  translateY(-64px)",
        display: " inline-block",
      },
    },
    "& .slick-prev": {
      left: pxToRem(-39),
      "&:before": {
        content: `url("/assets/images/element_wing_left.png")`,
        width: pxToRem(30),
        height: pxToRem(107),
        transform: "translateY(-64px)",
        display: " inline-block",
      },
    },
    "& .slick-dots": {
      bottom: 0,
    },
  },
  instaSlider: {
    width: "72%",
    margin: "0 auto",
    display: "inline-block",
    "& .slick-next": {
      "&:before": {
        content: `url(${WING_ARROWS})`,
        width: pxToRem(30),
        height: pxToRem(107),
        transform: "rotate(0deg)  translateY(-64px)",
        display: " inline-block",
      },
    },
    "& .slick-prev": {
      "&:before": {
        content: `url(${WING_ARROWS})`,
        width: pxToRem(30),
        height: pxToRem(107),
        transform: "rotate(180deg) translateY(50px)  translateX(6px)",
        display: " inline-block",
      },
    },
    "& .slick-dots": {
      bottom: 0,
    },
  },
  alignTop: {
    marginTop: pxToRem(18),
  },
  connectAlign: {
    marginBottom: pxToRem(10),
  },
  socialAlign: {
    marginTop: pxToRem(56),
    textAlign: "center",
    marginBottom: pxToRem(46),
    position: "relative",
  },
  settingsAlign: {
    textAlign: "right",
    marginRight: pxToRem(82),
    marginTop: pxToRem(4),
  },
  insta_block_width: {
    width: pxToRem(1224),
    border: `solid ${pxToRem(2)} ${color.secondary_palette.grays.shadow_gray}`,
    padding: `${pxToRem(13)} ${pxToRem(0)}`,
  },
  insta_settings: {
    display: "inline-block",
    marginLeft: pxToRem(8),
    position: "relative",
    top: pxToRem(-4),
    cursor: "pointer",
  },
  instaBlockImg: {
    display: "inline-block",
    marginRight: pxToRem(40),
    marginLeft: pxToRem(-66),
  },
  instaImageBlock: {
    width: pxToRem(122),
    height: pxToRem(122),
    opacity: 0.57,
    borderRadius: "50%",
  },
  seeBtn: {
    width: pxToRem(97),
    height: pxToRem(26),
    borderRadius: pxToRem(13),
    border: `solid ${pxToRem(1)} ${color.primary_palette.franklin_purple}`,
    backgroundColor: color.primary_palette.white,
    padding: 0,
    "& .MuiButton-label": {
      fontSize: pxToRem(18),
      ...tradework_theme.typography.styles.avenir_sb,
      color: color.primary_palette.franklin_purple,
      textTransform: "capitalize",
    },
  },
  sliderTextWrap: {
    wordBreak: "break-word",
    padding: `${pxToRem(0)} ${pxToRem(10)}`,
  },
  toggleAlign: {
    position: "relative",
    top: pxToRem(8),
  },
  socialImageStyle: {
    width: pxToRem(35),
    height: pxToRem(35),
    marginRight: pxToRem(32),
    marginLeft: pxToRem(32),
  },
  width100: {
    width: "100%",
  },
  borderTopGray: {
    borderTop: `${pxToRem(1)} solid ${
      color.secondary_palette.grays.header_border
    }`,
  },
  coverPicTitleAlign: {
    width: pxToRem(79),
    position: "absolute",
    right: pxToRem(56),
    top: pxToRem(10),
  },
  supportTextStyle: {
    backgroundColor: "rgba(255, 255, 255, 0.5)",
    textAlign: "center",
  },
  snapTitleAlign: {
    width: pxToRem(140),
    left: pxToRem(-120),
    top: pxToRem(10),
    position: "absolute",
  },
  headTitleAlign: {
    position: "absolute",
    left: pxToRem(-120),
    top: pxToRem(90),
  },
  newHelpTxtAlign: {
    width: pxToRem(113),
    height: pxToRem(49),
    borderRadius: pxToRem(4),
    border: `solid ${pxToRem(1)} ${color.primary_palette.black}`,
    background: "rgb(234,234,234,0.6)",
    ...tradework_theme.typography.styles.avenir_sb,
    fontSize: pxToRem(15),
    color: color.primary_palette.franklin_purple,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    cursor: "pointer",
  },
  businessTxtAlign: {
    position: "absolute",
    top: pxToRem(-56),
    right: pxToRem(0),
  },
  businessHelperTxtAlign: {
    paddingTop: pxToRem(8),
  },
  textUnderline: {
    textDecoration: "underline",
    cursor: "pointer",
  },
  newHelpTxtHelperAlign: {
    width: pxToRem(340),
    borderRadius: pxToRem(4),
    border: `solid ${pxToRem(1)} ${color.primary_palette.black}`,
    background: "rgb(234,234,234,0.6)",
    ...tradework_theme.typography.styles.avenir_sb,
    fontSize: pxToRem(12),
    color: color.primary_palette.franklin_purple,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
  },
  logoTitleAlign: {
    position: "absolute",
    right: pxToRem(7),
    top: pxToRem(130),
  },
  headTitleAlignRight: {
    display: "inline-block",
    position: "relative",
    top: pxToRem(-6),
  },
  businessTitleAlign: {
    zIndex: "999",
    position: "absolute",
    top: pxToRem(335),
  },
  please_add_text: {
    width: "25%",
    //position: "absolute",
    textAlign: "left",
  },
  top_40: {
    top: pxToRem(40),
  },
  padding_right_10: {
    paddingRight: pxToRem(10),
  },
  relative: {
    position: "relative",
    marginRight: pxToRem(10),
  },
  iconStylePencil: {
    backgroundImage: `url("/assets/images/pencil_icon.png")`,
    backgroundSize: "cover",
    width: pxToRem(30),
    height: pxToRem(30),
    display: "inline-block",
    cursor: "pointer",
    "&:hover": {
      backgroundImage: `url("/assets/images/yellow hover.png")`,
      backgroundSize: "cover",
      width: pxToRem(30),
      height: pxToRem(30),
      display: "inline-block",
      cursor: "pointer",
    },
  },
  vignettePlaceholder: {
    backgroundImage: `url("/assets/images/Vignettes-Gray-2X.png")`,
    backgroundSize: "contain",
    width: "100%",
    height: pxToRem(315),
    cursor: "pointer",
    "&:hover": {
      backgroundImage: `url("/assets/images/Vignettes-Colored-2X.png")`,
      backgroundSize: "contain",
      width: "100%",
      height: pxToRem(315),
      cursor: "pointer",
    },
  },
  productsPlaceholder: {
    backgroundImage: `url("/assets/images/Products-Publications-initial.png")`,
    backgroundSize: "contain",
    width: pxToRem(480),
    height: pxToRem(176),
    cursor: "pointer",
    margin: "0 auto",
    backgroundRepeat: "no-repeat",
    "&:hover": {
      backgroundImage: `url("/assets/images/Products-Publications-Hover.png")`,
      backgroundSize: "contain",
      cursor: "pointer",
      backgroundRepeat: "no-repeat",
      margin: "0 auto",
    },
  },
  placeHolder1: {
    backgroundImage: `url("/assets/images/Country House BW.png")`,
    backgroundSize: "cover",
    "&:hover": {
      backgroundImage: `url("/assets/images/Country House Hover.jpg")`,
      backgroundSize: "cover",
    },
  },
  placeHolder2: {
    backgroundImage: `url("/assets/images/Fifth Ave BW.png")`,
    backgroundSize: "cover",
    "&:hover": {
      backgroundImage: `url("/assets/images/Fifth Ave Hover.jpg")`,
      backgroundSize: "cover",
    },
  },
  placeHolder3: {
    backgroundImage: `url("/assets/images/Haus BW.png")`,
    backgroundSize: "cover",
    "&:hover": {
      backgroundImage: `url("/assets/images/Haus Hover.jpg")`,
      backgroundSize: "cover",
    },
  },
  placeHolder4: {
    backgroundImage: `url("/assets/images/Jackson BW.png")`,
    backgroundSize: "cover",
    "&:hover": {
      backgroundImage: `url("/assets/images/Jackson Over.jpg")`,
      backgroundSize: "cover",
    },
  },
  placeHolder5: {
    backgroundImage: `url("/assets/images/Soho BW.png")`,
    backgroundSize: "cover",
    "&:hover": {
      backgroundImage: `url("/assets/images/Soho-Hover.jpg")`,
      backgroundSize: "cover",
    },
  },
  placeHolder6: {
    backgroundImage: `url("/assets/images/Tropical-House-BW.png")`,
    backgroundSize: "cover",
    "&:hover": {
      backgroundImage: `url("/assets/images/Tropical-House-Hover.jpg")`,
      backgroundSize: "cover",
    },
  },
  iconStyleAdd: {
    backgroundImage: `url("/assets/images/add.png")`,
    backgroundSize: "cover",
    width: pxToRem(30),
    height: pxToRem(30),
    display: "inline-block",
    cursor: "pointer",
    "&:hover": {
      backgroundImage: `url("/assets/images/plus hover.png")`,
      backgroundSize: "cover",
      width: pxToRem(30),
      height: pxToRem(30),
      display: "inline-block",
      cursor: "pointer",
    },
  },
  iconStyleAddProd: {
    backgroundImage: `url("/assets/images/add.png")`,
    backgroundSize: "cover",
    width: pxToRem(20),
    height: pxToRem(30),
    marginRight: pxToRem(6),
    display: "inline-block",
    cursor: "pointer",
    "&:hover": {
      backgroundImage: `url("/assets/images/plus hover.png")`,
      backgroundSize: "cover",
      width: pxToRem(20),
      marginRight: pxToRem(6),
      height: pxToRem(30),
      display: "inline-block",
      cursor: "pointer",
    },
  },
  regionsAlignPublic: {
    fontSize: pxToRem(12),
    ...tradework_theme.typography.styles.avenir_bold,
    color: color.form_colors.sucess_color,
  },
  regionsAlignNotPublic: {
    fontSize: pxToRem(12),
    ...tradework_theme.typography.styles.avenir_light,
    color: color.secondary_palette.blues.click_blue,
  },
  elevatorAlign: {
    position: "relative",
    top: pxToRem(20),
    right: pxToRem(322),
  },
  market_dropdown: {
    width: pxToRem(155),
    height: pxToRem(20),
  },
  category_dropdown: {
    width: pxToRem(207),
    height: pxToRem(20),
    "& p": {
      ...tradework_theme.typography.styles.avenir_sb,
      fontSize: pxToRem(15),
      textOverflow: "ellipsis",
      overflow: "hidden",
      whiteSpace: "nowrap",
      textTransform: "capitalize",
    },
  },
  customScrollHeight: {
    "& li": {
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "normal",
    },
  },
  dropdown: {
    border: `solid ${pxToRem(1)} ${color.secondary_palette.grays.shadow_gray}`,
    backgroundColor: color.primary_palette.white,
    borderRadius: pxToRem(2),
    "& .MuiSvgIcon-root": {
      fontSize: pxToRem(14),
    },
    "& p": {
      fontSize: pxToRem(15),
      ...tradework_theme.typography.styles.avenir_sb,
    },
  },
  margin_left_10: {
    marginLeft: pxToRem(10),
  },
  addBtn: {
    width: pxToRem(59),
    height: pxToRem(20),
    padding: 0,
    border: `solid ${pxToRem(1)} ${color.primary_palette.free_purple}`,
    backgroundColor: color.secondary_palette.grays.btn_gray,
    fontSize: pxToRem(15),
    color: `${color.primary_palette.franklin_purple} !important`,
    ...tradework_theme.typography.styles.avenir_sb,
    borderRadius: pxToRem(5),
    marginLeft: pxToRem(15),
  },
  list_data: {
    ...tradework_theme.typography.styles.avenir_sb,
    fontSize: pxToRem(15),
    color: color.primary_palette.black,
  },
  cross_styled_icon: {
    fontSize: pxToRem(8),
    "& path": {
      fill: color.secondary_palette.grays.shadow_gray,
    },
  },
  margin_right_5: {
    marginRight: pxToRem(5),
  },
  list_dropdowm: {
    paddingLeft: pxToRem(10),
    paddingRight: pxToRem(10),
    fontSize: pxToRem(14),
    ...tradework_theme.typography.styles.avenir_sb,
    color: color.primary_palette.black,
  },
  dropdown_ofc: {
    borderRadius: pxToRem(4),
    width: pxToRem(454),
    height: pxToRem(24),
    border: `solid ${pxToRem(1)} ${color.secondary_palette.grays.shadow_gray}`,
    "& .MuiSvgIcon-root": {
      fontSize: pxToRem(15),
      "& path": {
        stroke: color.secondary_palette.grays.shadow_gray,
      },
    },
    "& p": {
      fontSize: pxToRem(15),
      ...tradework_theme.typography.styles.avenir_sb,
    },
  },
  width_324: {
    width: pxToRem(324),
    margin: "0 auto",
    backgroundColor: color.primary_palette.white,
    border: `solid ${pxToRem(1)} ${color.secondary_palette.grays.shadow_gray}`,
    borderRadius: pxToRem(5),
    paddingBottom: pxToRem(20),
  },
  total_amount: {
    borderTop: `solid ${pxToRem(1)} ${color.primary_palette.black}`,
    padding: `${pxToRem(0)} ${pxToRem(0)} ${pxToRem(0)} ${pxToRem(10)}`,
  },
  padding_top_25: {
    paddingTop: pxToRem(25),
  },
  underline: {
    textDecoration: "underline",
  },
  print: {
    width: pxToRem(324),
    margin: "0 auto",
    cursor: "pointer",
  },
  padding_top_7: {
    paddingTop: pxToRem(7),
  },
  text_field: {
    borderRadius: pxToRem(3),
    border: `solid ${pxToRem(1)} ${color.secondary_palette.grays.shadow_gray}`,
    backgroundColor: color.primary_palette.white,
    height: pxToRem(20),
    "& .MuiInput-underline": {
      "&:after": {
        display: "none",
      },
      "&:before": {
        display: "none",
      },
    },
    "& .MuiInputBase-input": {
      height: pxToRem(20),
      padding: 0,
    },
  },
  card: {
    width: pxToRem(121),
  },
  name_card: {
    width: pxToRem(202),
  },
  exp: {
    width: pxToRem(50),
  },
  zip: {
    width: pxToRem(61),
  },
  security: {
    width: pxToRem(75),
  },
  padding_top_40: {
    paddingTop: pxToRem(40),
  },
  check_save: {
    "& .MuiTypography-root": {
      ...tradework_theme.typography.styles.gillsans_r,
      fontSize: pxToRem(12),
      color: color.primary_palette.black,
      // marginTop: pxToRem(-6)
    },
    "& .MuiButtonBase-root": {
      paddingTop: 0,
      paddingRight: 0,
      paddingBottom: 0,
    },
  },
  check_box_spacing: {
    justifyContent: "flex-end",
    paddingRight: pxToRem(40),
  },
  checkBtn: {
    width: pxToRem(256),
    fontSize: pxToRem(15),
    ...tradework_theme.typography.styles.avenir_sb,
    color: `${color.primary_palette.franklin_purple} !important`,
    height: pxToRem(25),
    padding: 0,
    border: `solid ${pxToRem(1)} ${color.form_colors.blueberry_purple}`,
    backgroundColor: color.primary_palette.white,
    borderRadius: pxToRem(20),
  },
  justifyCenter: {
    justifyContent: "center",
  },
  coloredCrossStyle: {
    width: pxToRem(14),
    marginLeft: pxToRem(6),
    marginRight: pxToRem(6),
    position: "relative",
    cursor: "pointer",
    top: pxToRem(3),
    "& path": {
      fill: color.secondary_palette.grays.shadow_gray,
    },
  },
  alignDropdown: {
    display: "inline-block",
  },
  which_office: {
    width: pxToRem(120),
    borderRadius: pxToRem(2),
    height: pxToRem(20),
    marginRight: pxToRem(21),
    marginLeft: pxToRem(10),
    position: "relative",
    bottom: pxToRem(2),
    "& .MuiSvgIcon-root": {
      fontSize: pxToRem(14),
    },
    "& p": {
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
    },
  },
  future_rate_card: {
    cursor: "pointer",
    textDecoration: "underline",
  },
  topHeaderBlock: {
    width: "50%",
    position: "absolute",
    top: pxToRem(9),
    right: pxToRem(0),
    display: "flex",
    // height: pxToRem(88),
    alignItems: "center",
  },
  regionBlock: {
    width: "100%",
    display: "inline-block",
    padding: `${pxToRem(10)} ${pxToRem(20)}`,
    borderRight: `solid ${pxToRem(1)} ${
      color.secondary_palette.grays.shadow_gray
    }`,
  },
  positionRelative: {
    position: "relative",
  },
  gary_data: {
    color: color.secondary_palette.grays.medium_gray,
  },
  publicTxtStyle: {
    textDecoration: "underline",
    cursor: "pointer",
  },
  displayInline: {
    display: "inline-block",
  },
  listNone: {
    listStyleType: "none",
    paddingLeft: 0,
  },
  txtAlignTop: {
    width: "40%",
    position: "relative",
    cursor: "pointer",
  },
  displayFlex: {
    display: "flex",
    alignItems: "center",
  },
  markerAlign: {
    height: pxToRem(25),
    width: pxToRem(18),
    marginLeft: pxToRem(6),
    verticalAlign: "text-bottom",
    cursor: "pointer",
  },
  envolopStyle: {
    textAlign: "center",
    width: "30%",
    alignItems: "center",
  },
  preview_btn: {
    height: pxToRem(16),
    width: pxToRem(179),
    marginRight: pxToRem(20),
    borderRadius: pxToRem(2),
    border: `solid ${pxToRem(0.6)} ${color.primary_palette.franklin_purple}`,
    backgroundColor: color.primary_palette.white,
    marginTop: pxToRem(100),
    marginBottom: pxToRem(-50),
    marginLeft: pxToRem(20),
    "& .MuiButton-label": {
      color: color.primary_palette.black,
      fontSize: pxToRem(12),
      ...tradework_theme.typography.styles.avenir_sb,
      position: "relative !important",
      textTransform: "none",
    },
    "&:hover": {
      border: `solid ${pxToRem(1.6)} ${color.primary_palette.highlight_purple}`,
      backgroundColor: `${color.primary_palette.white} !important`,
    },
  },
  preview_btn_large: {
    height: pxToRem(16),
    width: pxToRem(200),
    marginRight: pxToRem(20),
    borderRadius: pxToRem(2),
    border: `solid ${pxToRem(0.6)} ${color.primary_palette.franklin_purple}`,
    backgroundColor: color.primary_palette.white,
    marginTop: pxToRem(100),
    marginBottom: pxToRem(-50),
    marginLeft: pxToRem(20),
    "& .MuiButton-label": {
      color: color.primary_palette.black,
      fontSize: pxToRem(12),
      ...tradework_theme.typography.styles.avenir_sb,
      position: "relative !important",
      textTransform: "none",
    },
    "&:hover": {
      border: `solid ${pxToRem(1.6)} ${color.primary_palette.highlight_purple}`,
      backgroundColor: `${color.primary_palette.white} !important`,
    },
  },
  startTxt: {
    marginRight: pxToRem(8),
  },
  thenDo: {
    marginLeft: pxToRem(8),
  },
  AddProjectTxt: {
    position: "absolute",
    bottom: pxToRem(0),
    textAlign: "center",
    left: pxToRem(370),
    width: pxToRem(200),
  },
  addProjectArrow: {
    marginBottom: pxToRem(8),
  },
  textStartHere: {
    position: "absolute",
    right: pxToRem(176),
    top: pxToRem(24),
  },
  thenDoThisTxt: {
    position: "absolute",
    top: pxToRem(250),
    width: pxToRem(200),
    left: pxToRem(370),
  },
  editToggleTxt: {
    width: pxToRem(74),
    cursor: "pointer",
  },
  hover_content: {
    width: pxToRem(176.4),
    border: `solid ${pxToRem(1)} ${color.indigo_blue}`,
    padding: pxToRem(4),
    position: "absolute",
    right: pxToRem(94),
    borderRadius: pxToRem(4),
    backgroundColor: color.primary_palette.white,
    zIndex: 9,
    boxShadow: "0 1px 4px 0 rgba(0, 0, 0, 0.5)",
    "& .triangle": {
      borderBottomColor: color.indigo_blue,
      position: "absolute",
      display: "block",
      width: 0,
      height: 0,
      borderStyle: "solid",
      borderWidth: pxToRem(8),
      borderTopWidth: pxToRem(0),
      borderColor: "transparent",
      right: pxToRem(11),
      top: "-23%",
      transform: "rotate(0deg)",
      "&:after": {
        top: pxToRem(11.5),
        margin: pxToRem(-10),
        content: "''",
        borderBottomColor: color.primary_palette.white,
        position: "absolute",
        display: "block",
        width: 0,
        height: 0,
        borderStyle: "solid",
        borderWidth: pxToRem(10),
        borderTopWidth: pxToRem(0),
        borderColor: "transparent",
      },
    },
  },
  initial_txt: {
    textTransform: "initial",
  },
  hover_location: {
    "& .hover_display": {
      display: "none",
    },
    "&:hover": {
      "& .hover_display": {
        display: "block",
      },
      "& .txt_green": {
        color: color.form_colors.sucess_color,
      },
      "& .hover_icon": {
        "& path": {
          fill: color.form_colors.sucess_color,
        },
      },
    },
  },
  connect_accounts: {
    border: `solid ${pxToRem(1)} ${color.primary_palette.black}`,
    padding: `${pxToRem(5)} ${pxToRem(17)}`,
    backgroundColor: color.box_gray,
    width: pxToRem(248),
    margin: "0 auto",
    height: pxToRem(30),
    marginTop: pxToRem(35),
    borderRadius: pxToRem(4),
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
  },
  portfolioNameAlign: {
    position: "relative",
    top: pxToRem(18),
  },
  add_please: {
    cursor: "pointer",
    "& .MuiSvgIcon-root": {
      fontSize: pxToRem(30),
      marginRight: pxToRem(7),
      verticalAlign: "text-bottom",
    },
    "&:hover": {
      "& .MuiSvgIcon-root": {
        "& ellipse": {
          fill: color.primary_palette.franklin_purple,
        },
      },
    },
  },
  add_project_btn: {
    textAlign: "right",
    "&:hover": {
      "& ellipse": {
        fill: color.primary_palette.franklin_purple,
      },
    },
  },
  add_project: {
    marginRight: pxToRem(40),
    "& .MuiButton-label": {
      color: color.primary_palette.franklin_purple,
      fontSize: pxToRem(25),
      ...tradework_theme.typography.styles.avenir_black_r,
      textTransform: "capitalize",
    },
    "& .MuiSvgIcon-root": {
      fontSize: pxToRem(32),
      paddingRight: pxToRem(6),
      marginTop: pxToRem(13),
      "& path": {
        "&:nth-child(1)": {
          fill: color.secondary_palette.grays.background_gray,
        },
      },
    },
  },
  blk_hover_company_logo: {
    position: "absolute",
    bottom: pxToRem(132),
    right: 0,
    // height: pxToRem(100),
    // top: pxToRem(100),
  },
  blk_hover: {
    "&:hover": {
      "& .icon_plus": {
        backgroundImage: `url("/assets/images/plus hover.png")`,
        backgroundSize: "cover",
        width: pxToRem(30),
        height: pxToRem(30),
        display: "inline-block",
        cursor: "pointer",
      },
      "& .icon_edit": {
        backgroundImage: `url("/assets/images/yellow hover.png")`,
        backgroundSize: "cover",
        width: pxToRem(30),
        height: pxToRem(30),
        display: "inline-block",
        cursor: "pointer",
      },
    },
  },
  prodPubTxtAlign1: {
    textAlign: "center",
  },
  prodPubTxtAlign2: {
    textAlign: "center",
    marginLeft: pxToRem(0),
  },
  vignette_placeholder_hover: {
    "& .add_please_hover": {
      "&:hover": {
        "& .hover_effect": {
          backgroundImage: `url("/assets/images/Vignettes-Colored-2X.png")`,
          backgroundSize: "contain",
          width: "100%",
          height: pxToRem(315),
          cursor: "pointer",
        },
        "& .add_please": {
          "& .MuiSvgIcon-root": {
            "& ellipse": {
              fill: color.primary_palette.franklin_purple,
            },
          },
        },
      },
    },
  },
  margin_top_25: {
    marginTop: `${pxToRem(-25)} !important`,
  },
  vignette_width_1000: {
    width: pxToRem(1030),
    margin: "0 auto",
  },
  margin_left_80: {
    marginLeft: pxToRem(80),
    position: "absolute",
  },
  height100: {
    height: pxToRem(100),
  },
  add_please_small: {
    cursor: "pointer",
    marginTop: pxToRem(10),
    fontWeight: "bold",
    "& .MuiSvgIcon-root": {
      fontSize: pxToRem(20),
      marginRight: pxToRem(7),
      verticalAlign: "text-bottom",
    },
    "&:hover": {
      "& .MuiSvgIcon-root": {
        "& ellipse": {
          fill: color.primary_palette.franklin_purple,
        },
      },
    },
  },
  pencilAlign: {
    position: "relative",
    top: pxToRem(4),
  },
  toggleStyle: {
    cursor: "pointer",
  },
  logoBlockAlign: {
    // top: pxToRem(302),
    // // left: pxToRem(230),
    // zIndex: 9,
    position: "relative",
  },
  hover_des: {
    "&:hover": {
      "& .icon_plus": {
        backgroundImage: `url("/assets/images/plus hover.png")`,
        backgroundSize: "cover",
        width: pxToRem(30),
        height: pxToRem(30),
        display: "inline-block",
        cursor: "pointer",
      },
      "& .icon_edit": {
        backgroundImage: `url("/assets/images/yellow hover.png")`,
        backgroundSize: "cover",
        width: pxToRem(30),
        height: pxToRem(30),
        display: "inline-block",
        cursor: "pointer",
      },
    },
  },
  snap_hover: {
    "&:hover": {
      "& .icon_plus": {
        backgroundImage: `url("/assets/images/plus hover.png")`,
        backgroundSize: "cover",
        width: pxToRem(30),
        height: pxToRem(30),
        display: "inline-block",
        cursor: "pointer",
      },
      "& .icon_edit": {
        backgroundImage: `url("/assets/images/yellow hover.png")`,
        backgroundSize: "cover",
        width: pxToRem(30),
        height: pxToRem(30),
        display: "inline-block",
        cursor: "pointer",
      },
    },
  },
  margin_top_10: {
    marginTop: pxToRem(-10),
  },
  profile_hover: {
    "&:hover": {
      "& .icon_plus": {
        backgroundImage: `url("/assets/images/plus hover.png")`,
        backgroundSize: "cover",
        width: pxToRem(30),
        height: pxToRem(30),
        display: "inline-block",
        cursor: "pointer",
      },
      "& .icon_edit": {
        backgroundImage: `url("/assets/images/yellow hover.png")`,
        backgroundSize: "cover",
        width: pxToRem(30),
        height: pxToRem(30),
        display: "inline-block",
        cursor: "pointer",
      },
    },
  },
  card_hover: {
    "&:hover": {
      "& .icon_plus": {
        backgroundImage: `url("/assets/images/plus hover.png")`,
        backgroundSize: "cover",
        width: pxToRem(30),
        height: pxToRem(30),
        display: "inline-block",
        cursor: "pointer",
      },
      "& .icon_edit": {
        backgroundImage: `url("/assets/images/yellow hover.png")`,
        backgroundSize: "cover",
        width: pxToRem(30),
        height: pxToRem(30),
        display: "inline-block",
        cursor: "pointer",
      },
    },
  },
  right30: {
    right: pxToRem(30),
  },
  publicIconAlign: {
    position: "relative",
    top: pxToRem(20),
    "& .hover_icon": {
      cursor: "pointer",
    },
    "&:hover": {
      "& .hover_icon": {
        "& path": {
          fill: color.form_colors.sucess_color,
        },
      },
    },
  },
  betaStampIcon: {
    height: pxToRem(100),
    position: "absolute",
    right: pxToRem(10),
    bottom: pxToRem(30),
    cursor: "pointer",
  },
});

export default styles;
