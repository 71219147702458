import React from "react";
import { get } from "lodash";

import GridmsStyles from "./styles/gridmsStyles";
import Row from "../common/ui_kit/row";

function GridTenMs(props) {
  const { classes, imageData } = props;

  return (
    <>
      <div className={classes.publicviewProject}>
        <Row className={classes.grid_main_spacing}>
          <div>
            <div className={classes.imgDiv}>
              <img
                className={classes.grid_10ms_1}
                alt=""
                src={
                  get(
                    imageData[0],
                    "alterImageUri.croppedImage",
                    "/assets/images/dark_pink.PNG"
                  ) || "/assets/images/dark_pink.PNG"
                }
              />
              {!get(imageData[0], "alterImageUri.croppedImage") && (
                <div className={classes.numberAlign}>1</div>
              )}
            </div>
            <div className={classes.imgDiv}>
              <img
                className={classes.grid_10ms_2}
                alt=""
                src={
                  get(
                    imageData[6],
                    "alterImageUri.croppedImage",
                    "/assets/images/blue.PNG"
                  ) || "/assets/images/blue.PNG"
                }
              />
              {!get(imageData[6], "alterImageUri.croppedImage") && (
                <div className={classes.numberAlign}>7</div>
              )}
            </div>
          </div>
          <div>
            <div className={classes.imgDiv}>
              <img
                className={classes.grid_10ms_3}
                alt=""
                src={
                  get(
                    imageData[1],
                    "alterImageUri.croppedImage",
                    "/assets/images/Light Blue Grid Box.png"
                  ) || "/assets/images/Light Blue Grid Box.png"
                }
              />
              {!get(imageData[1], "alterImageUri.croppedImage") && (
                <div className={classes.numberAlign}>2</div>
              )}
            </div>
            <div className={classes.imgDiv}>
              <img
                className={classes.grid_10ms_4}
                alt=""
                src={
                  get(
                    imageData[2],
                    "alterImageUri.croppedImage",
                    "/assets/images/blue.PNG"
                  ) || "/assets/images/blue.PNG"
                }
              />
              {!get(imageData[3], "alterImageUri.croppedImage") && (
                <div className={classes.numberAlign}>3</div>
              )}
            </div>
            <div className={classes.imgDiv}>
              <img
                className={classes.grid_10ms_5}
                alt=""
                src={
                  get(
                    imageData[7],
                    "alterImageUri.croppedImage",
                    "/assets/images/Light Blue Grid Box.png"
                  ) || "/assets/images/Light Blue Grid Box.png"
                }
              />
              {!get(imageData[7], "alterImageUri.croppedImage") && (
                <div className={classes.numberAlign}>8</div>
              )}
            </div>
          </div>
          <div>
            <div className={classes.imgDiv}>
              <img
                className={classes.grid_10ms_6}
                alt=""
                src={
                  get(
                    imageData[3],
                    "alterImageUri.croppedImage",
                    "/assets/images/dark_pink.PNG"
                  ) || "/assets/images/dark_pink.PNG"
                }
              />
              {!get(imageData[2], "alterImageUri.croppedImage") && (
                <div className={classes.numberAlign}>4</div>
              )}
            </div>
            <div className={classes.imgDiv}>
              <img
                className={classes.grid_10ms_7}
                alt=""
                src={
                  get(
                    imageData[8],
                    "alterImageUri.croppedImage",
                    "/assets/images/blue.PNG"
                  ) || "/assets/images/blue.PNG"
                }
              />
              {!get(imageData[8], "alterImageUri.croppedImage") && (
                <div className={classes.numberAlign}>9</div>
              )}
            </div>
          </div>
          <div>
            <div className={classes.imgDiv}>
              <img
                className={classes.grid_10ms_8}
                alt=""
                src={
                  get(
                    imageData[4],
                    "alterImageUri.croppedImage",
                    "/assets/images/Light Blue Grid Box.png"
                  ) || "/assets/images/Light Blue Grid Box.png"
                }
              />
              {!get(imageData[4], "alterImageUri.croppedImage") && (
                <div className={classes.numberAlign}>5</div>
              )}
            </div>
            <div className={classes.imgDiv}>
              <img
                className={classes.grid_10ms_9}
                alt=""
                src={
                  get(
                    imageData[5],
                    "alterImageUri.croppedImage",
                    "/assets/images/Light Blue Grid Box.png"
                  ) || "/assets/images/Light Blue Grid Box.png"
                }
              />
              {!get(imageData[5], "alterImageUri.croppedImage") && (
                <div className={classes.numberAlign}>6</div>
              )}
            </div>
            <div className={classes.imgDiv}>
              <img
                className={classes.grid_10ms_10}
                alt=""
                src={
                  get(
                    imageData[9],
                    "alterImageUri.croppedImage",
                    "/assets/images/blue.PNG"
                  ) || "/assets/images/blue.PNG"
                }
              />
              {!get(imageData[9], "alterImageUri.croppedImage") && (
                <div className={classes.numberAlign}>10</div>
              )}
            </div>
          </div>
        </Row>
      </div>
    </>
  );
}

export default GridmsStyles(GridTenMs);
