import React, { useState, useEffect } from "react";
import { Grid, InputAdornment } from "@material-ui/core";
import { get } from "lodash";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import styleSheet from "../styles/login_styles";
import Text from "../../components/common/ui_kit/text";
import Close_Icon from "../../components/data_display/icons/Close";
import CustomButton from "../../components/navigations/custom_buttons";
import {
  ATLEAST_ONE_CAPITAL_LETTER,
  NUM_OR_SYMBOL,
  PASSWORD_REGEX,
  REGEX_EMIAL_END_WITH_DOT,
  REGEX_EMAIL,
} from "../../validations/validations";
import strings from "../../utilities/strings";
import CustomInputLabel from "../../components/data_display/custom_label";
import HidePassword_Icon from "../../components/data_display/icons/HidePassword";
import Ribbon from "../../components/data_display/icons/Ribbon";
import CustomCheckbox from "../../components/inputs/custom_checkbox";
import CustomTextField from "../../components/inputs/custom_textfields";
import CustomLink from "../../components/navigations/custom_links";
import CustomModal from "../../components/inputs/custom_modal";
import { LoginActions } from "../../redux/actions";
import { color } from "../../utilities/themes";
import { FRANKLIN_QA, FRANKLIN_PROD, FRANKLIN_STAGE } from "../../constants";

function Login(props) {
  const [values, setValues] = useState({
    email: "",
    password: "",
    errors: {},
    isRememberMe: false,
  });
  const {
    loggingIn,
    loginError,
    classes,
    openJoinusModal,
    signUpError,
    errorMessage,
    emailId,
    clearError,
    toggleModal,
  } = props;
  const history = useHistory();
  const dispatch = useDispatch();
  const loginErr = useSelector((resData) => resData.Login.loginError);

  const {
    showPassword,
    showEmailSuggestion,
    email,
    password,
    errors,
    isRememberMe,
  } = values;

  useEffect(() => {
    dispatch(LoginActions.clearLogInProps());
    if (signUpError) {
      setValues({ ...values, email: emailId });
    }
    if (localStorage.getItem("isRememberMe") === "true") {
      setValues({
        ...values,
        email: localStorage.getItem("userEmail"),
        password: localStorage.getItem("userPassword"),
        isRememberMe: localStorage.getItem("isRememberMe"),
      });
    }
    return () => {
      clearError();
    };
  }, []);
  const hasEightChars = (passwordVal) => {
    return passwordVal.length >= 8;
  };

  const hasCapitalLetter = (passwordVal) => {
    return ATLEAST_ONE_CAPITAL_LETTER.test(passwordVal);
  };

  const hasNumberOrSymbol = (passwordVal) => {
    return NUM_OR_SYMBOL.test(passwordVal);
  };

  const onEmailSuggestionChecked = (value) => (e) => {
    handleValidation("email", value);
    setValues({ ...values, email: value, showEmailSuggestion: false });
  };

  const onChangeRememberMe = (e) => {
    const { checked } = e.target;
    setValues({ ...values, isRememberMe: checked });
  };

  const handleValidation = (name, value) => {
    if (name === "email") {
      if (!value) {
        // IF NO VALUE RETURNING ERROR TRUE
        errors[name] = { error: true, touched: true };
        setValues({ ...values });
        return;
      }
      const checkType = PASSWORD_REGEX.test(value);
      if (checkType) {
        // NUMBER TYPE VALIDATION
        return;
      } else {
        if (value.includes("@")) {
          // EMAIL TYPE VALIDATION
          const regexTest = REGEX_EMAIL.test(value);
          if (!regexTest) {
            errors[name] = { error: true, touched: true };
            setValues({ ...values });
          } else {
            errors[name] = { error: false, touched: true };
            setValues({
              ...values,
            });
          }
        } else {
          errors[name] = { error: false, touched: true };
          setValues({
            ...values,
          });
        }
        return;
      }
    }
    if (name === "password") {
      const check1 = hasEightChars(value);
      const check2 = hasCapitalLetter(value);
      const check4 = hasNumberOrSymbol(value);
      if (check1 && check2 && check4) {
        errors[name] = { error: false, touched: true };
        setValues({ ...values });
        return;
      } else {
        errors[name] = { error: true, touched: true };
        setValues({ ...values });
        return;
      }
    }
  };

  const onInputChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    if (name === "email") {
      const checkType = PASSWORD_REGEX.test(value);
      if (checkType) {
        // NUMBER TYPE VALIDATION
        setValues({ ...values, [name]: value });
        return;
      } else {
        // EMAIL TYPE VALIDATION
        const showSuggestion = REGEX_EMIAL_END_WITH_DOT.test(value);
        setValues({
          ...values,
          [name]: value,
          showEmailSuggestion: showSuggestion,
        });
        return;
      }
    }
    if (name === "password") {
      handleValidation(name, value);
      setValues({ ...values, [name]: value });
    }
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !showPassword });
  };

  const hasFormErrors = () => {
    const hadLoginError = get(loginErr, "error.code", "") ? true : false;
    const errs = Object.values(errors).map((each) => each.error);
    if (!errs.includes(true) && email && password && !hadLoginError) {
      return false;
    }
    return true;
  };

  const closeModal = () => {
    setValues({ ...values, email: "", password: "", errors: {} });
    dispatch(LoginActions.clearLogInProps());
    localStorage.removeItem("Review");
    props.closeModal();
  };

  const onClearInput = (name) => () => {
    setValues({ ...values, [name]: "", errors: {} });
  };

  const submitForm = () => {
    handleValidation("email", email);
    handleValidation("password", password);
    dispatch(
      LoginActions.login({ ...values }, (res) => {
        if (res.token) {
          const isReview = localStorage.getItem("Review");
          if (isReview) {
            let host = get(window, "location.host", "");
            if (host) {
              if (host.includes("localhost")) {
                window.location.href = `${FRANKLIN_QA}/NavigateUsers.aspx?Token=${res.token}&URL=MemberReview`;
              } else if (host.includes("-dev-")) {
                window.location.href = `${FRANKLIN_QA}/NavigateUsers.aspx?Token=${res.token}&URL=MemberReview`;
              } else if (host.includes("-qa-")) {
                window.location.href = `${FRANKLIN_QA}/NavigateUsers.aspx?Token=${res.token}&URL=MemberReview`;
              } else if (host.includes("-stage-")) {
                window.location.href = `${FRANKLIN_STAGE}/NavigateUsers.aspx?Token=${res.token}&URL=MemberReview`;
              } else if (host.includes("franklinreport")) {
                window.location.href = `${FRANKLIN_PROD}/NavigateUsers.aspx?Token=${res.token}&URL=MemberReview`;
              }
            }
            window.location.href = `${FRANKLIN_PROD}/NavigateUsers.aspx?Token=${res.token}&URL=MemberReview`;
            localStorage.removeItem("redirectFranklin");
          }
          const isFrRedirect = localStorage.getItem("redirectFranklin");
          if (isFrRedirect === "true") {
            let host = get(window, "location.host", "");
            if (host) {
              if (host.includes("localhost")) {
                window.location.href = `${FRANKLIN_QA}/NavigateUsers.aspx?Token=${res.token}&URL=default`;
              } else if (host.includes("-dev-")) {
                window.location.href = `${FRANKLIN_QA}/NavigateUsers.aspx?Token=${res.token}&URL=default`;
              } else if (host.includes("-qa-")) {
                window.location.href = `${FRANKLIN_QA}/NavigateUsers.aspx?Token=${res.token}&URL=default`;
              } else if (host.includes("-stage-")) {
                window.location.href = `${FRANKLIN_STAGE}/NavigateUsers.aspx?Token=${res.token}&URL=default`;
              } else if (host.includes("franklinreport")) {
                window.location.href = `${FRANKLIN_PROD}/NavigateUsers.aspx?Token=${res.token}&URL=default`;
              }
            }
            window.location.href = `${FRANKLIN_PROD}/NavigateUsers.aspx?Token=${res.token}&URL=default`;
          }
          localStorage.setItem("userToken", res.token);
          localStorage.setItem(
            "hasExistingPortfolio",
            res.hasExistingPortfolio
          );
          localStorage.setItem("companyId", res.companyId);
          localStorage.setItem("isPublicMode", false);
          localStorage.setItem("companyName", res.companyName);
          localStorage.setItem("isAllPublic", res.isAllPublic);
          localStorage.setItem("active", "personal");
          localStorage.setItem("userEmail", values.email);
          localStorage.setItem(
            "impersonate",
            get(res, "user.impersonate", false)
          );
          localStorage.setItem("userPassword", values.password);
          localStorage.setItem("isRememberMe", values.isRememberMe);
          localStorage.removeItem("frpage");
          localStorage.removeItem("portfolioId");
          localStorage.setItem("Review", false);
          props.closeModal();
          if (res.hasExistingPortfolio) {
            history.push("/NewPortfolio");
          } else {
            history.push("/");
          }
        }
      })
    );
  };

  const openForgotPassword = () => {
    toggleModal("displayLogin", "showRecoverCredentials");
    localStorage.setItem("loggedInEmail", values.email);
  };

  return (
    <React.Fragment>
      <CustomModal
        open
        aria-labelledby="modal-title"
        disablePortal
        aria-describedby="modal-description"
        disableAutoFocus
        className={
          localStorage.getItem("Review") === "true" && classes.backgroundBlack
        }
      >
        <Grid container justify="center" className={classes.popup}>
          <Grid container justify="flex-end" md={12}>
            <CustomButton
              onClick={props.closePopup}
              className={classes.closeBtn}
              isX
            >
              <Close_Icon onClick={closeModal} />
            </CustomButton>
          </Grid>
          <Grid container justify="center" md={12}>
            <img src="/assets/icons/signin_new_logo.svg" alt="" />
          </Grid>
          {/* <Grid container justify="center" md={12}>
            <Text
              family="gillsans_sb"
              size={12}
              color={color.primary_palette.black}
            >
              for existing members
            </Text>
          </Grid> */}
          {signUpError && (
            <div className={classes.signUpErrorMessage}>{errorMessage}</div>
          )}
          {get(loginErr, "error.message.code", "") === 1001 && (
            <Text
              family="gillsans_sb"
              size={10}
              color={color.primary_palette.christmas_red}
              className={classes.errorMessageAlign}
            >
              <img
                src="/assets/icons/info_1.svg"
                className={classes.info_img}
                alt=""
              />{" "}
              Email does not match password
            </Text>
          )}
          <Grid className={classes.formContainer}>
            <form className={classes.form} autocomplete="off">
              {showEmailSuggestion && (
                <div className={classes.auto_email}>
                  <CustomCheckbox
                    label={`Do you mean ${email}com?`}
                    labelPlacement="start"
                    text_transform="none"
                    onChange={onEmailSuggestionChecked(`${email}com`)}
                  />
                  <CustomInputLabel
                    is_input_label={true}
                    color={color.primary_palette.black}
                    label_name="Yes"
                  />
                </div>
              )}
              <div className={classes.contentCenter}>
                <p className={classes.errorText} style={{ margin: 0 }}>
                  {loginError && loginError.error
                    ? loginError.error.message.message
                    : null}
                  {get(loginErr, "error.code", "") === 1029 ||
                    get(loginErr, "error.code", "") === 1001 ||
                    get(loginErr, "error.code", "") === 1034 ||
                    (get(loginErr, "error.code", "") === 1010 &&
                      strings.login.errors.match_not_found)}
                </p>
                <CustomTextField
                  label={
                    (!showEmailSuggestion &&
                      !signUpError &&
                      (!loginError || !loginError.error) &&
                      get(loginErr, "error.code", "") !== 1029) ||
                    get(loginErr, "error.code", "") !== 1034 ||
                    (get(loginErr, "error.code", "") === 1010 &&
                      !get(errors, "email.error"))
                      ? strings.login.titles.email_phone_username
                      : get(errors, "email.error")
                      ? strings.login.titles.valid_user
                      : null
                  }
                  name="email"
                  value={email}
                  onClear={onClearInput("email")}
                  onChange={onInputChange}
                  clearIcon
                  onBlur={(e) =>
                    handleValidation(e.target.name, e.target.value)
                  }
                  className={`${
                    !get(errors, "email.touched", false)
                      ? classes.inp_initial
                      : get(errors, "email.error", false) ||
                        get(loginErr, "error.message.code", "") === 1001
                      ? classes.inp_error
                      : classes.inp_success
                  } ${classes.textFieldSuccess}`}
                />
              </div>

              <div className={classes.contentCenter}>
                <CustomTextField
                  label={strings.join_us.titles.password}
                  onChange={onInputChange}
                  onBlur={(e) =>
                    handleValidation(e.target.name, e.target.value)
                  }
                  clearIcon
                  clearIcon={true}
                  value={password}
                  name="password"
                  className={`${
                    !get(errors, "password.touched", false)
                      ? classes.inp_initial
                      : get(errors, "password.error", false) ||
                        get(loginErr, "error.message.code", "") === 1001
                      ? classes.inp_error
                      : classes.inp_success
                  } ${classes.textFieldSuccess}`}
                  type={showPassword ? "text" : "password"}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        tabIndex="-1"
                        aria-label="toggle password visibility"
                        className={classes.password_icon}
                        onClick={handleClickShowPassword}
                      >
                        {password.length > 0 && (
                          <Close_Icon
                            onMouseUp={onClearInput("password")}
                            style={{
                              height: "7px",
                              width: "7px",
                              cursor: "pointer",
                              zIndex: "1000",
                              marginRight: "10px",
                            }}
                          />
                        )}
                        {showPassword ? (
                          <img
                            src="/assets/images/View Password .svg"
                            alt=""
                            className={classes.cursor}
                          />
                        ) : (
                          <HidePassword_Icon
                            style={{
                              fontSize: "1.3125rem",
                              cursor: "pointer",
                            }}
                          />
                        )}
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              <Text
                size={12}
                family="avenir_light"
                color={color.form_colors.textfield_color}
                className={`${classes.loginMessageAlign} ${classes.contentCenter}`}
              >
                {strings.join_us.messages.login_message}
              </Text>
              <Grid
                container
                item
                justify="flex-start"
                className={classes.rememberMe}
              >
                <CustomCheckbox
                  label="Remember me"
                  className={classes.rememberMeText}
                  onChange={onChangeRememberMe}
                  checked={isRememberMe}
                />
              </Grid>
              {signUpError && (
                <Grid container item justify="center" alignItems="center">
                  <a className={classes.forgotPasswordLarge}>
                    Forgot your Credentials?
                  </a>
                </Grid>
              )}
              <Grid container item justify="center" alignItems="center">
                <CustomButton
                  variant="outlined"
                  color="primary"
                  size="large"
                  custom_type="submit"
                  className={classes.submit_button}
                  onMouseUp={() => submitForm()}
                  isX
                  endIcon={
                    !hasFormErrors() && (
                      // <Fade timeout={600} in={!hasFormErrors()}>
                      //   <Paper elevation={4} className={classes.paper}>
                      <Ribbon
                        color={loggingIn ? "" : "primary"}
                        style={{
                          fontSize: "35px",
                          marginTop: "2px",
                          marginRight: "8px",
                        }}
                      />
                      //   </Paper>
                      // </Fade>
                    )
                  }
                >
                  {!signUpError
                    ? strings.landing_page.titles.welcome
                    : "SIGN IN"}
                </CustomButton>
              </Grid>
            </form>
          </Grid>
          {!signUpError && (
            <Grid
              container
              justify="space-between"
              className={classes.footer_spacing}
            >
              <Grid
                item
                xs={5}
                className={classes.footerContainer}
                style={{
                  color:
                    get(loginError, "error", false) ||
                    get(loginErr, "error.code", "") === 1029 ||
                    get(loginErr, "error.code", "") === 1034 ||
                    get(loginErr, "error.message.code", "") === 1001 ||
                    showEmailSuggestion ||
                    get(loginErr, "error.code", "") === 1010
                      ? color.primary_palette.christmas_red
                      : "",
                }}
              >
                {strings.login.titles.forgot_your}{" "}
                <CustomLink
                  underline="always"
                  color={
                    get(loginErr, "error.message.code", "") === 1001 ||
                    get(loginErr, "error.code", "") === 1010 ||
                    get(loginErr, "error.code", "") === 1029 ||
                    get(loginErr, "error.code", "") === 1034 ||
                    (loginError && loginError.error)
                      ? "error"
                      : ""
                  }
                  onClick={openForgotPassword}
                >
                  <label className={classes.cursor}>
                    {strings.login.titles.credentials}?
                  </label>
                </CustomLink>
              </Grid>

              <Grid
                item
                xs={
                  get(loginErr, "error.message.code", "") === 1001 ||
                  get(loginErr, "error.code", "") === 1029 ||
                  get(loginErr, "error.code", "") === 1034 ||
                  get(loginErr, "error.code", "") === 1010
                    ? 6
                    : 5
                }
                className={classes.footerContainer}
              >
                <p style={{ margin: 0 }}>
                  {get(loginErr, "error.message.code", "") === 1001 ||
                  get(loginErr, "error.code", "") === 1029 ||
                  get(loginErr, "error.code", "") === 1034 ||
                  get(loginErr, "error.code", "") === 1010
                    ? strings.login.titles.dont_have
                    : strings.login.titles.not_member}
                </p>
                <CustomLink underline="always" onClick={openJoinusModal}>
                  <label className={classes.cursor}>
                    {strings.login.titles.join_here}!
                  </label>
                </CustomLink>
              </Grid>
            </Grid>
          )}
        </Grid>
      </CustomModal>
    </React.Fragment>
  );
}

export default styleSheet(Login);
