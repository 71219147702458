import { withStyles } from "@material-ui/core/styles";
import tradework_theme, { pxToRem, color } from "../../../utilities/themes";

const styles = withStyles({
  txtRight: {
    textAlign: "right",
  },
  txtCenter: {
    textAlign: "center",
  },
  footerTxtAlign: {
    width: "66%",
    margin: "0 auto",
    lineHeight: 1.2,
  },
  border_gray: {
    textDecoration: "underline",
    paddingBottom: pxToRem(1),
  },
  portfolioMoneyModal: {
    width: pxToRem(641),
    border: `solid ${pxToRem(2)} ${color.primary_palette.free_purple}`,
    backgroundColor: color.secondary_palette.blues.modal_blue,
    margin: "0 auto",
    padding: pxToRem(19),
    "&:focus": {
      outline: "none",
    },
  },
  cross_icon: {
    fontSize: pxToRem(15),
    cursor: "pointer",
    "& path": {
      stroke: color.pedagogical_bronze,
    },
  },
  party_icon: {
    width: pxToRem(70),
    height: pxToRem(70),
  },
  margin_top_25: {
    marginTop: pxToRem(-25),
  },
  padding_top_10: {
    paddingTop: pxToRem(10),
  },
  textCapital: {
    textTransform: "uppercase",
  },
  img_main_border: {
    border: `solid ${pxToRem(2.5)} ${color.primary_palette.black}`,
    width: pxToRem(217),
    padding: pxToRem(5),
    height: pxToRem(167),
    // maxHeight: pxToRem(243),
  },
  img_sub_border: {
    border: `solid ${pxToRem(0.8)} ${color.primary_palette.black}`,
    width: "100%",
    height: "100%",
    position: "relative",
  },
  company_logo: {
    width: "100%",
    height: "75%",
  },
  padding_top_12: {
    paddingTop: pxToRem(12),
  },
  user_logo: {
    width: pxToRem(48),
    height: pxToRem(48),
    borderRadius: " 100%",
    border: `solid ${pxToRem(1.5)} ${color.primary_palette.black}`,
    left: pxToRem(12),
    bottom: pxToRem(12),
    position: "absolute",
  },
  text_decoration: {
    fontSize: pxToRem(10.3),
    ...tradework_theme.typography.styles.TrajanPro3,
  },
  width_30: {
    width: "30%",
  },
  width_70: {
    width: "70%",
  },
  ellipsis_text: {
    width: pxToRem(144),
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    margin: "0 auto",
  },
  alignments_col: {
    display: "flex",
    height: pxToRem(260),
    width: pxToRem(162),
    flexDirection: "column !important",
    marginTop: pxToRem(10),
    paddingLeft: pxToRem(15),
  },
  gill_sb: {
    ...tradework_theme.typography.styles.gillsans_sb,
  },
  padding_top_15: {
    paddingTop: pxToRem(15),
  },
  img_data_spcaing: {
    paddingLeft: pxToRem(35),
    paddingRight: pxToRem(35),
    marginBottom: pxToRem(20),
  },
  padding_left_25: {
    paddingLeft: pxToRem(25),
  },
  textCenter: {
    textAlign: "center",
  },
  lineHeightMin: {
    lineHeight: 1.2,
  },
  greatBtn: {
    width: pxToRem(122),
    height: pxToRem(30),
    padding: 0,
    borderRadius: pxToRem(20),
    border: `solid ${pxToRem(1)} ${color.form_colors.blueberry_purple}`,
    backgroundColor: color.primary_palette.white,
    ...tradework_theme.typography.styles.avenir_black_r,
    fontSize: pxToRem(15),
    color: `${color.primary_palette.franklin_purple} !important`,
  },
  text_decoration_franklin_purple: {
    textDecoration: "underline",
  },
  cursor: {
    cursor: "pointer",
  },
  future_rate_card: {
    textDecoration: "underline",
    cursor: "pointer",
  },
  width_120: {
    width: pxToRem(120),
  },
  overFlow_x_auto: {
    overflowX: "auto",
  },
  text_underline: {
    textDecoration: "underline",
  },
  underlineHover: {
    "&:hover": {
      textDecoration: "underline",
    },
  },
  greatBtnAlign: {
    textAlign: "center",
    marginBottom: pxToRem(10),
  },
});

export default styles;
