import React from "react";

import { color } from "../../utilities/themes";
import DeleteCompanyStyles from "./styles/deleteCompanyStyles";
import Text from "../common/ui_kit/text";
import strings from "../../utilities/strings";
import Close_Icon from "../data_display/icons/Close";
import CustomButton from "../navigations/custom_buttons";

function RemovePerson(props) {
  const { classes, onClose, onSure } = props;

  return (
    <>
      <div className={classes.delete_company_width}>
        <div className={classes.txtRight}>
          <Close_Icon className={classes.cross_icon} onClick={onClose} />
        </div>
        <div className={classes.textCenter}>
          <img
            src="/assets/images/Pigeon.svg"
            alt="paper"
          />
          <Text
            size={20}
            family="avenir_sb"
            color={color.primary_palette.franklin_purple}
            className={classes.textHeading}
          >
            {strings.modal.titles.remove_person}
          </Text>
          <Text
            size={18}
            family="avenir_sb"
            color={color.primary_palette.black}
            className={classes.subTextHeading}
          >
            {strings.modal.titles.cannot_undo}
          </Text>
          <CustomButton className={classes.sureBtn} onClick={onSure}>
            {strings.modal.titles.sure}
          </CustomButton>
        </div>
      </div>
    </>
  );
}

export default DeleteCompanyStyles(RemovePerson);
