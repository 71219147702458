import React, { useState, useEffect } from "react";
import { get } from "lodash";
import { useDispatch } from "react-redux";

import { color } from "../../utilities/themes";
import { Slider } from "@material-ui/core";
import VignetteCropperStyles from "./styles/vignetteCropperStyles";
import Row from "../common/ui_kit/row";
import Text from "../common/ui_kit/text";
import strings from "../../utilities/strings";
import Close_Icon from "../data_display/icons/Close";
import CustomButton from "../navigations/custom_buttons";
import CustomRadioButton from "../inputs/custom_radio_button";
import CustomCropper from "../cropper/custom_cropper";
import getCroppedImg from "../cropper/crop_image";
import CustomInputCount from "../inputs/custom_input_count";

import { ApiActions } from "../../redux/actions";
import { REGEX_URL } from "../../validations/validations";

function VignetteCropper(props) {
  const {
    classes,
    onClose,
    image,
    setCroppedImage,
    handleInputChange,
    cropperData,
  } = props;
  const dispatch = useDispatch();

  const [values, setValues] = useState({
    errors: {},
    portfolioId: localStorage.getItem("portfolioId"),
    zoom: 1,
    crop: { x: 0, y: 0 },
    colorValue: "#F5EFEC",
  });

  useEffect(() => {
    if (cropperData) {
      setValues({
        ...values,
        croppedImage: cropperData.actualImageUri || cropperData.img,
      });
    }
  }, [cropperData]);

  const showCroppedImage = async () => {
    const { croppedAreaPixels } = values;
    if (hasError) {
      values.errors.error = true;
      setValues({ ...values });
      return;
    } else {
      values.errors.error = false;
      setValues({ ...values });
    }
    try {
      ApiActions.request(dispatch);
      let urlObj = "";
      urlObj = image || cropperData.actualImageUri || cropperData.img;
      const croppedImage = await getCroppedImg(
        urlObj,
        croppedAreaPixels,
        values.colorValue
      );
      const obj = {
        showCropper: false,
      };
      obj.croppedImage = URL.createObjectURL(croppedImage);
      obj.blobUrl = croppedImage;
      setValues({ ...values, obj });
      setCroppedImage(obj.croppedImage, obj);
      onClose();
      ApiActions.success(dispatch);
    } catch (e) {
      ApiActions.failure(dispatch);
      console.error(e);
    }
  };

  const onCropComplete = (coords) => {
    const croppedAreaPixels = get(coords, "main.croppedAreaPixels", "");
    setValues({ ...values, croppedAreaPixels });
  };

  const setCrop = (value) => {
    setValues({ ...values, crop: value });
  };

  const setZoom = (value) => {
    setValues({ ...values, zoom: value });
  };

  const hasFormErrors = () => {
    const { productLink, isLinkedToProject } = cropperData;
    if (
      (!isLinkedToProject && !productLink) ||
      (productLink && !REGEX_URL.test(productLink))
    ) {
      return true;
    }
    return false;
  };

  const changeBgColor = (colorVal) => () => {
    setValues({ ...values, colorValue: colorVal });
  };

  const hasError = hasFormErrors();
  const { zoom, crop, colorValue, productLink, croppedImage } = values;
  return (
    <>
      <div className={classes.copperModal}>
        <div className={classes.btnRight}>
          <CustomButton className={classes.crossBtn} isX>
            <Close_Icon className={classes.cross_mark} onClick={onClose} />
          </CustomButton>
        </div>
        <div className={classes.copperModalContent}>
          <Text
            size={30}
            family="avenir_light"
            color={color.primary_palette.black}
            className={`${classes.textCenter} ${classes.margin_top_20}`}
            transform="uppercase"
          >
            {strings.vignettes.titles.compose_vignette}
          </Text>

          <div
            className={`${classes.cropperDiv}`}
            style={{ backgroundColor: values.colorValue }}
          >
            <CustomCropper
              image={image || croppedImage}
              crop={crop}
              zoom={zoom}
              cropShape={"rect"}
              aspect={1}
              onCropChange={setCrop}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
              showGrid={false}
            />
          </div>
          <div className={classes.slider_width}>
            <Slider
              value={zoom}
              max={3}
              min={0.4}
              step={0.1}
              aria-labelledby="Zoom"
              classes={classes.slider}
              onChange={(e, zoomVal) => setZoom(zoomVal)}
            />
          </div>
          <div className={classes.textCenter}>
            <Text
              size={15}
              color={color.secondary_palette.grays.drag_text}
              family="avenir_light"
            >
              {strings.vignettes.titles.drag_img}
            </Text>
            <Text
              size={15}
              color={color.secondary_palette.grays.drag_text}
              family="avenir_light"
            >
              {strings.vignettes.titles.drag_zoom}
            </Text>
          </div>
          <Row>
            <div
              className={`${classes.vignette_link} ${classes.vignette_linkAlign}`}
            >
              <Text
                size={15}
                color={color.secondary_palette.grays.drag_text}
                family="avenir_sb"
              >
                {strings.vignettes.titles.vignette_link}
              </Text>
            </div>
            <div className={classes.vignette_link_radio}>
              <CustomRadioButton
                className={classes.linkRadio}
                label={strings.vignettes.titles.vignette_radio1}
                name="isProduct"
                checked={cropperData && cropperData.isLinkedToProject}
                onChange={handleInputChange(cropperData)}
              />
              <Row>
                <CustomRadioButton
                  className={
                    get(values, "errors.error") && hasError
                      ? classes.linkRadioRed
                      : classes.linkRadio
                  }
                  label={strings.vignettes.titles.vignette_radio2}
                  name="isProduct1"
                  checked={cropperData && !cropperData.isLinkedToProject}
                  onChange={handleInputChange(cropperData)}
                />
                <div
                  className={`${classes.vignette_link} ${classes.margin_left_12}`}
                >
                  <CustomInputCount
                    className={`${classes.inputField} ${
                      (productLink && !REGEX_URL.test(productLink)) ||
                      (get(values, "errors.error") && hasError)
                        ? classes.bodereRed
                        : classes.borderBlack
                    }`}
                    placeholder={
                      strings.vignettes.titles.vignette_radio2_placeholder
                    }
                    onBlur={handleInputChange(cropperData)}
                    name="productLink"
                    defaultValue={cropperData && cropperData.productLink}
                    error={
                      (productLink && !REGEX_URL.test(productLink)) ||
                      (get(values, "errors.error") && hasError)
                    }
                  />
                </div>
              </Row>
            </div>
          </Row>
          <Row className={classes.textCenter}>
            <Text
              size={15}
              color={color.secondary_palette.grays.choose_cover}
              family="avenir_light"
              className={classes.choose_bg_txt}
            >
              {strings.add_image.titles.chosse_bg}
            </Text>
            <span
              className={
                colorValue === "#000000"
                  ? classes.choose_black_active
                  : classes.choose_black
              }
              onClick={changeBgColor("#000000")}
            ></span>
            <span
              className={
                colorValue === "#F5EFEC"
                  ? classes.choose_gray_active
                  : classes.choose_gray
              }
              onClick={changeBgColor("#F5EFEC")}
            >
              <img
                src="/assets/images/Transparency Tile.svg"
                alt=""
                className={classes.checkeredAlign}
              />
            </span>
            <span
              className={
                colorValue === "#ffffff"
                  ? classes.choose_white_active
                  : classes.choose_white
              }
              onClick={changeBgColor("#ffffff")}
            ></span>
          </Row>
          <Row className={classes.justifyCenter}>
            <CustomButton
              className={classes.save_btn}
              onClick={showCroppedImage}
            >
              {strings.vignettes.titles.save_continue}
            </CustomButton>
          </Row>
        </div>
      </div>
    </>
  );
}

export default VignetteCropperStyles(VignetteCropper);
