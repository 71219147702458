import React, { useRef, useState, useEffect } from "react";
import { get } from "lodash";

import AddImageStyles from "./styles/addImageStyles";
import Row from "../common/ui_kit/row";
import Text from "../common/ui_kit/text";

import { MAX_UPLOAD_SIZE } from "../../constants";

function AddImage(props) {
  const uploadRef = useRef(null);
  const {
    classes,
    className,
    defaultImage,
    MainText,
    MainTextStyle,
    subTextStyle,
    subText,
    sizeText,
    sizeStyle,
    subText1,
    subTextStyle1,
    addImageStyleImport,
    marginRight_6,
    isProductCropper,
    ImageDimensions,
    originalImg,
    transparentColor
  } = props;

  const [uploadedImage, setUploadedImage] = useState({});
  const [errors, setErrors] = useState({});

  useEffect(() => {
    const { croppedImage } = props;
    if (croppedImage) {
      setUploadedImage({ ...uploadedImage, image: croppedImage });
    } else {
      setUploadedImage({ ...uploadedImage, image: "" });
    }
  }, [props.croppedImage]);

  useEffect(() => {
    const { openImageExp } = props;
    if (openImageExp) {
      handleButtonClick();
    }
  }, [props.openImageExp]);

  const imageUpload = (e) => {
    e.preventDefault();
    let file = e.target.files[0];
    if (!file) {
      return;
    }
    uploadRef.current.value = "";
    if (!file.type.includes("image")) {
      setErrors({
        ...errors,
        image: "Upload JPG or PNG, Max 5 MB",
      });
      return;
    }
    if (file.size > MAX_UPLOAD_SIZE) {
      setErrors({
        ...errors,
        image: "Upload JPG or PNG, Max 5 MB",
      });
      return;
    }
    setUploadedImage({ image: URL.createObjectURL(file), imageFile: file });
    setErrors({
      image: "",
    });
    props.imageUpload &&
      props.imageUpload(
        {
          imageData: { imageFile: file, imageUrl: URL.createObjectURL(file) },
          id: props.id,
          index: props.idx,
          type: props.type,
        },
        props.name
      );
  };

  const handleButtonClick = (e) => {
    uploadRef.current.click();
  };

  const addDefaultSrc = (ev) => {
    ev.target.src = "/assets/images/Blank Eagle Ribbon.svg";
  };

  const handleImageClick = () => {
    if (uploadedImage.image) {
      props.imageUpload &&
        props.imageUpload(
          {
            imageData: {
              imageFile: uploadedImage.file,
              imageUrl: uploadedImage.image,
            },
            id: props.id,
            index: props.idx,
            type: props.type,
          },
          props.name
        );
      return;
    }
    if (originalImg) {
      props.imageUpload(
        {
          imageData: {
            imageUrl: originalImg,
          },
          id: props.id,
          index: props.idx,
          type: props.type,
        },
        props.name
      );
      return;
    }
    handleButtonClick();
  };
  return (
    <>
      <Row
        className={
          isProductCropper
            ? `${classes.addImage_pub} ${addImageStyleImport} ${marginRight_6}`
            : `${classes.addImage} ${addImageStyleImport} ${marginRight_6}`
        }
      >
        <div className={`${classes.img_block} ${ImageDimensions}`}>
          <label>
            <img
              src={uploadedImage.image || defaultImage}
              className={className}
              onClick={handleImageClick}
              alt=""
              onError={addDefaultSrc}
            />
          </label>
          <input
            id="file"
            ref={uploadRef}
            onChange={imageUpload}
            type="file"
            accept="image/*"
            className={classes.input_circle}
          />
        </div>
        <div className={classes.textCenter}>
          <label>
            <Text className={MainTextStyle} onClick={handleImageClick}>
              {MainText}
            </Text>
          </label>
          <Text className={subTextStyle}>{subText}</Text>
          <Text className={subTextStyle1}>{subText1}</Text>
          <Text className={sizeStyle}>{sizeText}</Text>
        </div>
      </Row>
      <Row className={classes.errorMessage}>{get(errors, "image", "")}</Row>
    </>
  );
}

export default AddImageStyles(AddImage);
