import { withStyles } from "@material-ui/core/styles";
import tradework_theme, { pxToRem, color } from "../../../utilities/themes";

const styles = withStyles({
  portfolio_block: {
    width: pxToRem(1218),
    margin: "0 auto",
    paddingTop: pxToRem(24),
    paddingBottom: pxToRem(40),
    marginTop: pxToRem(80),
  },
  left_side: {
    width: "40%",
  },
  right_side: {
    width: "60%",
    display: "block",
  },
  block_group: {
    display: "block",
    textAlign: "center",
  },
  free: {
    ...tradework_theme.typography.styles.gillsans_sb,
    color: color.primary_palette.free_purple,
  },
  free_portfolio: {
    textAlign: "left",
    paddingLeft: pxToRem(12),
  },
  build_des: {
    width: "60%",
    margin: "0 auto",
    paddingTop: pxToRem(11),
  },
  free_block: {
    margin: "0 auto",
    paddingTop: pxToRem(40),
    display: "inline-block",
  },
  current_franklin: {
    margin: "0 auto",
    paddingTop: pxToRem(30),
    marginRight: pxToRem(0),
    textAlign: "center",
  },
  new_port_style: {
    marginTop: pxToRem(-90),
  },
  current_franklin_style: {
    marginTop: pxToRem(-10),
  },
  current_franklin_style1: {
    marginTop: pxToRem(-30),
  },
  build_text: {
    paddingTop: pxToRem(13),
    ...tradework_theme.typography.styles.OptimaLT,
  },
  graphicImage: {
    marginLeft: pxToRem(10),
  },
  build_btn: {
    width: pxToRem(236),
    height: pxToRem(40),
    borderRadius: pxToRem(51),
    border: `solid ${pxToRem(2)} ${color.primary_palette.franklin_purple}`,
    color: `${color.primary_palette.franklin_purple} !important`,
    marginTop: pxToRem(34),
    // marginBottom: pxToRem(348),
    "& .MuiButton-label": {
      fontSize: pxToRem(20),
      ...tradework_theme.typography.styles.avenir_bold,
    },
  },
  justify: {
    justifyContent: "center",
  },
  static_img: {
    width: pxToRem(316),
    height: pxToRem(720),
  },
  left_text: {
    display: "block",
    textAlign: "right",
    paddingRight: pxToRem(16),
  },
  profile_cover: {
    paddingTop: pxToRem(10),
    width: pxToRem(85),
    textAlign: "center",
    marginLeft: "40%",
  },
  cover_photo: {
    width: pxToRem(85),
    textAlign: "center",
    marginLeft: "40%",
  },
  company_snapshot: {
    paddingTop: pxToRem(36),
  },
  business_card: {
    paddingTop: pxToRem(18),
  },
  vignettes: {
    paddingTop: pxToRem(106),
  },
  unliited_projects: {
    paddingTop: pxToRem(90),
    width: pxToRem(131),
  },
  on_summary: {
    paddingTop: pxToRem(0),
    width: pxToRem(131),
  },
  products: {
    paddingTop: pxToRem(228),
  },
  instagram: {
    paddingTop: pxToRem(50),
  },
  arrow_img: {
    width: pxToRem(166),
    height: pxToRem(46),
  },
  greeting_img: {
    position: "relative",
    top: pxToRem(14),
    height: pxToRem(104),
    width: pxToRem(100),
  },
  future_rate_card: {
    position: "relative",
    cursor: "pointer",
    textDecoration: "underline",
    display: "inline-block",
    lineHeight: pxToRem(20),
    marginLeft: pxToRem(10),
  },
  phil_align: {
    width: pxToRem(115),
    marginLeft: pxToRem(15),
    marginTop: pxToRem(6),
  },
  categoryAlign: {
    fontSize: pxToRem(14),
    ...tradework_theme.typography.styles.avenir_sb,
    color: color.primary_palette.black,
  },
  list_dropdowm: {
    fontSize: pxToRem(14),
    ...tradework_theme.typography.styles.avenir_sb,
    color: color.primary_palette.black,
  },
  marginTop34: {
    marginTop: pxToRem(34),
  },
  marginBottom34: {
    marginBottom: pxToRem(34),
  },
});

export default styles;
