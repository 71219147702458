import { withStyles } from "@material-ui/core/styles";
import tradework_theme, { pxToRem, color } from "../../../utilities/themes";

const styles = withStyles({
  elevator_width: {
    width: pxToRem(1000),
    border: `solid ${pxToRem(1)} ${color.primary_palette.free_purple}`,
    backgroundColor: color.secondary_palette.blues.modal_blue,
    position: "relative",
  },
  textRight: {
    textAlign: "right",
  },
  cross_mark: {
    fontSize: pxToRem(15),
    cursor: "pointer",
  },
  cross_mark_phil: {
    fontSize: pxToRem(7),
    position: "absolute",
    top: pxToRem(110),
    right: pxToRem(0),
    cursor: "pointer",
  },
  snap_clear: {
    fontSize: pxToRem(7),
    position: "absolute",
    top: pxToRem(56),
    right: pxToRem(0),
    cursor: "pointer",
  },
  cross_mark_exp: {
    fontSize: pxToRem(7),
    position: "absolute",
    top: pxToRem(208),
    right: pxToRem(0),
    cursor: "pointer",
  },
  cross_btn: {
    minWidth: pxToRem(40),
    padding: pxToRem(16),
  },
  edit_pencil: {
    width: pxToRem(55),
    position: "absolute",
    left: 0,
    right: 0,
    top: pxToRem(-20),
    margin: "0 auto",
    "& .MuiSvgIcon-root": {
      fontSize: pxToRem(55),
    },
  },
  textCenter: {
    textAlign: "center",
  },
  elevator_spacing: {
    padding: `${pxToRem(0)} ${pxToRem(40)} ${pxToRem(10)} ${pxToRem(40)}`,
  },
  company_snapshop: {
    position: "relative",
    paddingTop: pxToRem(20),
  },
  paddingLeft6: {
    paddingLeft: pxToRem(5),
  },
  company_snapshop_input: {
    width: "100%",
    "& .MuiInputBase-input": {
      fontSize: pxToRem(16),
      paddingLeft: pxToRem(5),
      ...tradework_theme.typography.styles.NeutraText,
      width: "98%",
    },
    "& .MuiInputBase-input::placeholder": {
      // fontStyle: "oblique",
      color: color.secondary_palette.grays.shadow_gray,
      ...tradework_theme.typography.styles.avenir_light,
      fontSize: pxToRem(16),
      opacity: 1,
    },
  },
  company_snapshop_input2: {
    width: "100%",
    "& .MuiInputBase-input": {
      fontSize: pxToRem(16),
      ...tradework_theme.typography.styles.NeutraText,
      paddingTop: pxToRem(0),
      width: "98%",
      paddingLeft: pxToRem(5),
    },
    "& .MuiInputBase-input::placeholder": {
      // fontStyle: "oblique",
      color: color.secondary_palette.grays.shadow_gray,
      ...tradework_theme.typography.styles.avenir_light,
      fontSize: pxToRem(16),
      opacity: 1,
    },
  },

  wordBuild: {
    height: pxToRem(96),
    "& .ql-editor": {
      paddingTop: pxToRem(20),
      paddingLeft: pxToRem(5),
    },
    "& .ql-picker": {
      display: "none !important",
    },
    "& .ql-link": {
      display: "none !important",
    },
    "& .ql-clean": {
      display: "none !important",
    },
    "& .ql-container.ql-snow": {
      backgroundColor: color.primary_palette.white,
    },
  },
  countAlign: {
    marginTop: pxToRem(46),
    textAlign: "right",
    color: color.secondary_palette.grays.shadow_gray,
  },
  company_philosophy: {
    paddingTop: pxToRem(16),
    position: "relative",
  },
  save_btn: {
    width: pxToRem(91),
    position: "relative",
    top: pxToRem(-4),
    height: pxToRem(25),
    borderRadius: pxToRem(27),
    border: `solid ${pxToRem(1)} ${color.primary_palette.franklin_purple}`,
    backgroundColor: color.primary_palette.white,
    "& .MuiButton-label": {
      ...tradework_theme.typography.styles.avenir_sb,
      color: color.primary_palette.franklin_purple,
      fontSize: pxToRem(15),
    },
  },
  save_btn_disable: {
    width: pxToRem(91),
    position: "relative",
    top: pxToRem(-4),
    height: pxToRem(25),
    borderRadius: pxToRem(27),
    backgroundColor: color.primary_palette.white,
    border: `solid ${pxToRem(1)} ${color.primary_palette.disable_border}`,
    "& .MuiButton-label": {
      ...tradework_theme.typography.styles.avenir_sb,
      // color: color.secondary_palette.grays.fafa_gray,
      fontSize: pxToRem(15),
    },
  },
  alignCenter: {
    justifyContent: "center",
  },
  countAlign_negative: {
    color: color.primary_palette.christmas_red,
    marginTop: pxToRem(46),
    textAlign: "right",
  },
  countAlign_warning: {
    color: color.accent_colors_and_one_offs.links_orange,
    marginTop: pxToRem(46),
    textAlign: "right",
  },
  edit_icon: {
    fontSize: pxToRem(60),
    position: "absolute",
    top: pxToRem(-28),
    left: "47%",
  },
  conceptAlign: {
    marginTop: pxToRem(12),
  },
  concept2Align: {
    marginBottom: pxToRem(12),
  },
  posRelative: {
    position: "relative",
  },
  cross_mark_exp_quil: {
    fontSize: pxToRem(7),
    position: "absolute",
    top: pxToRem(52),
    right: pxToRem(22),
    cursor: "pointer",
  },
  cross_mark_desc_quil: {
    fontSize: pxToRem(7),
    position: "absolute",
    top: pxToRem(66),
    right: pxToRem(22),
    cursor: "pointer",
  },
  placeholder1Align: {
    position: "absolute",
    top: pxToRem(20),
    right: pxToRem(16),
    width: pxToRem(319),
  },
  placeholder2Align: {
    position: "absolute",
    width: pxToRem(334),
    top: pxToRem(4),
    right: pxToRem(16),
  },
  errorAlign: {
    textAlign: "center",
    marginBottom: pxToRem(20),
  },
});

export default styles;
