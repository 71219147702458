import { withStyles } from "@material-ui/core/styles";
import { pxToRem, color } from "../../../utilities/themes";

const styles = withStyles({
  publicviewProject: {
    width: pxToRem(404),
    margin: "0 auto",
  },
  grid_1ms_1: {
    width: pxToRem(404),
    height: pxToRem(209),
    backgroundColor: color.secondary_palette.grays.gary_shade,
  },
  grid_2ms_1: {
    width: pxToRem(198),
    height: pxToRem(209),
    backgroundColor: color.secondary_palette.grays.gary_shade,
    marginRight: pxToRem(8),
  },
  grid_2ms_2: {
    width: pxToRem(198),
    height: pxToRem(209),
    backgroundColor: color.secondary_palette.grays.gary_shade,
  },
  grid_3ms_1: {
    width: pxToRem(266),
    height: pxToRem(102),
    backgroundColor: color.secondary_palette.grays.gary_shade,
  },
  grid_3ms_2: {
    width: pxToRem(266),
    height: pxToRem(102),
    backgroundColor: color.secondary_palette.grays.gary_shade,
  },
  grid_3ms_3: {
    width: pxToRem(129),
    height: pxToRem(209),
    backgroundColor: color.secondary_palette.grays.gary_shade,
    marginLeft: pxToRem(9),
  },
  grid_4ms_1: {
    width: pxToRem(129),
    height: pxToRem(209),
    backgroundColor: color.secondary_palette.grays.gary_shade,
    marginRight: pxToRem(9),
  },
  grid_4ms_2: {
    width: pxToRem(128),
    height: pxToRem(100),
    backgroundColor: color.secondary_palette.grays.gary_shade,
    marginBottom: pxToRem(5),
  },
  grid_4ms_3: {
    width: pxToRem(128),
    height: pxToRem(100),
    backgroundColor: color.secondary_palette.grays.gary_shade,
  },
  grid_4ms_4: {
    width: pxToRem(129),
    height: pxToRem(209),
    backgroundColor: color.secondary_palette.grays.gary_shade,
    marginLeft: pxToRem(9),
  },
  grid_5ms_1: {
    width: pxToRem(131),
    height: pxToRem(62),
    backgroundColor: color.secondary_palette.grays.gary_shade,
    marginBottom: pxToRem(4),
  },
  grid_5ms_2: {
    width: pxToRem(131),
    height: pxToRem(139),
    backgroundColor: color.secondary_palette.grays.gary_shade,
    marginBottom: pxToRem(6),
  },
  grid_5ms_3: {
    width: pxToRem(129),
    height: pxToRem(209),
    backgroundColor: color.secondary_palette.grays.gary_shade,
    marginLeft: pxToRem(6),
    marginRight: pxToRem(6),
  },
  grid_5ms_4: {
    width: pxToRem(131),
    height: pxToRem(139),
    backgroundColor: color.secondary_palette.grays.gary_shade,
    marginBottom: pxToRem(4),
  },
  grid_5ms_5: {
    width: pxToRem(131),
    height: pxToRem(62),
    backgroundColor: color.secondary_palette.grays.gary_shade,
  },
  grid_6ms_1: {
    width: pxToRem(131),
    height: pxToRem(139),
    backgroundColor: color.secondary_palette.grays.gary_shade,
    marginBottom: pxToRem(5),
  },
  grid_6ms_2: {
    width: pxToRem(131),
    height: pxToRem(62),
    backgroundColor: color.secondary_palette.grays.gary_shade,
  },
  grid_6ms_3: {
    width: pxToRem(128),
    height: pxToRem(100),
    backgroundColor: color.secondary_palette.grays.gary_shade,
    marginLeft: pxToRem(7),
    marginBottom: pxToRem(6),
    marginRight: pxToRem(6),
  },
  grid_6ms_4: {
    width: pxToRem(128),
    height: pxToRem(100),
    backgroundColor: color.secondary_palette.grays.gary_shade,
    marginLeft: pxToRem(7),
    marginRight: pxToRem(6),
  },
  grid_6ms_5: {
    width: pxToRem(131),
    height: pxToRem(62),
    backgroundColor: color.secondary_palette.grays.gary_shade,
    marginBottom: pxToRem(6),
  },
  grid_6ms_6: {
    width: pxToRem(131),
    height: pxToRem(139),
    backgroundColor: color.secondary_palette.grays.gary_shade,
  },
  grid_7ms_1: {
    width: pxToRem(95),
    height: pxToRem(75),
    backgroundColor: color.secondary_palette.grays.gary_shade,
    marginRight: pxToRem(7),
  },
  grid_7ms_2: {
    width: pxToRem(95),
    height: pxToRem(130),
    backgroundColor: color.secondary_palette.grays.gary_shade,
    marginRight: pxToRem(7),
  },
  grid_7ms_3: {
    width: pxToRem(97),
    height: pxToRem(103),
    backgroundColor: color.secondary_palette.grays.gary_shade,
    marginRight: pxToRem(5),
  },
  grid_7ms_4: {
    width: pxToRem(97),
    height: pxToRem(103),
    backgroundColor: color.secondary_palette.grays.gary_shade,
    marginRight: pxToRem(8),
  },
  grid_7ms_5: {
    width: pxToRem(198),
    height: pxToRem(103),
    backgroundColor: color.secondary_palette.grays.gary_shade,
    marginRight: pxToRem(8),
  },
  grid_7ms_6: {
    width: pxToRem(95),
    height: pxToRem(75),
    backgroundColor: color.secondary_palette.grays.gary_shade,
  },
  grid_7ms_7: {
    width: pxToRem(95),
    height: pxToRem(130),
    backgroundColor: color.secondary_palette.grays.gary_shade,
  },
  grid_8ms_1: {
    width: pxToRem(95),
    height: pxToRem(75),
    backgroundColor: color.secondary_palette.grays.gary_shade,
    marginRight: pxToRem(4),
  },
  grid_8ms_2: {
    width: pxToRem(95),
    height: pxToRem(130),
    backgroundColor: color.secondary_palette.grays.gary_shade,
    marginRight: pxToRem(4),
  },
  grid_8ms_3: {
    width: pxToRem(95),
    height: pxToRem(130),
    backgroundColor: color.secondary_palette.grays.gary_shade,
    marginRight: pxToRem(4),
  },
  grid_8ms_4: {
    width: pxToRem(97),
    height: pxToRem(50),
    backgroundColor: color.secondary_palette.grays.gary_shade,
    marginRight: pxToRem(4),
  },
  grid_8ms_5: {
    width: pxToRem(97),
    height: pxToRem(76),
    backgroundColor: color.secondary_palette.grays.gary_shade,
  },
  grid_8ms_6: {
    width: pxToRem(196),
    height: pxToRem(75),
    backgroundColor: color.secondary_palette.grays.gary_shade,
  },
  grid_8ms_7: {
    width: pxToRem(95),
    height: pxToRem(130),
    backgroundColor: color.secondary_palette.grays.gary_shade,
  },
  grid_8ms_8: {
    width: pxToRem(95),
    height: pxToRem(75),
    backgroundColor: color.secondary_palette.grays.gary_shade,
  },
  grid_9ms_1: {
    width: pxToRem(95),
    height: pxToRem(75),
    backgroundColor: color.secondary_palette.grays.gary_shade,
    marginRight: pxToRem(5),
  },
  grid_9ms_2: {
    width: pxToRem(95),
    height: pxToRem(62),
    backgroundColor: color.secondary_palette.grays.gary_shade,
    marginRight: pxToRem(5),
  },
  grid_9ms_3: {
    width: pxToRem(95),
    height: pxToRem(62),
    backgroundColor: color.secondary_palette.grays.gary_shade,
    marginRight: pxToRem(5),
  },
  grid_9ms_4: {
    width: pxToRem(196),
    height: pxToRem(75),
    backgroundColor: color.secondary_palette.grays.gary_shade,
    marginRight: pxToRem(5),
  },
  grid_9ms_5: {
    width: pxToRem(95),
    height: pxToRem(130),
    backgroundColor: color.secondary_palette.grays.gary_shade,
    marginRight: pxToRem(5),
  },
  grid_9ms_6: {
    width: pxToRem(95),
    height: pxToRem(62),
    backgroundColor: color.secondary_palette.grays.gary_shade,
  },
  grid_9ms_7: {
    width: pxToRem(95),
    height: pxToRem(62),
    backgroundColor: color.secondary_palette.grays.gary_shade,
  },
  grid_9ms_8: {
    width: pxToRem(95),
    height: pxToRem(130),
    backgroundColor: color.secondary_palette.grays.gary_shade,
  },
  grid_9ms_9: {
    width: pxToRem(95),
    height: pxToRem(75),
    backgroundColor: color.secondary_palette.grays.gary_shade,
  },
  grid_10ms_1: {
    width: pxToRem(95),
    height: pxToRem(130),
    backgroundColor: color.secondary_palette.grays.gary_shade,
    marginRight: pxToRem(5),
  },
  grid_10ms_2: {
    width: pxToRem(95),
    height: pxToRem(75),
    backgroundColor: color.secondary_palette.grays.gary_shade,
    marginRight: pxToRem(5),
  },
  grid_10ms_3: {
    width: pxToRem(95),
    height: pxToRem(62),
    backgroundColor: color.secondary_palette.grays.gary_shade,
    marginRight: pxToRem(5),
    marginBottom: pxToRem(1),
  },
  grid_10ms_4: {
    width: pxToRem(95),
    height: pxToRem(75),
    backgroundColor: color.secondary_palette.grays.gary_shade,
    marginRight: pxToRem(5),
    marginBottom: pxToRem(1),
  },
  grid_10ms_5: {
    width: pxToRem(95),
    height: pxToRem(62),
    backgroundColor: color.secondary_palette.grays.gary_shade,
    marginRight: pxToRem(5),
  },
  grid_10ms_6: {
    width: pxToRem(95),
    height: pxToRem(130),
    backgroundColor: color.secondary_palette.grays.gary_shade,
    marginRight: pxToRem(5),
  },
  grid_10ms_7: {
    width: pxToRem(95),
    height: pxToRem(75),
    backgroundColor: color.secondary_palette.grays.gary_shade,
    marginRight: pxToRem(5),
  },
  grid_10ms_8: {
    width: pxToRem(95),
    height: pxToRem(62),
    backgroundColor: color.secondary_palette.grays.gary_shade,
    marginBottom: pxToRem(1),
  },
  grid_10ms_9: {
    width: pxToRem(95),
    height: pxToRem(62),
    backgroundColor: color.secondary_palette.grays.gary_shade,
    marginBottom: pxToRem(1),
  },
  grid_10ms_10: {
    width: pxToRem(95),
    height: pxToRem(75),
    backgroundColor: color.secondary_palette.grays.gary_shade,
  },
  grid_11ms_1: {
    width: pxToRem(97),
    height: pxToRem(103),
    backgroundColor: color.secondary_palette.grays.gary_shade,
    marginRight: pxToRem(5),
  },
  grid_11ms_2: {
    width: pxToRem(97),
    height: pxToRem(103),
    backgroundColor: color.secondary_palette.grays.gary_shade,
    marginRight: pxToRem(5),
  },
  grid_11ms_3: {
    width: pxToRem(95),
    height: pxToRem(62),
    backgroundColor: color.secondary_palette.grays.gary_shade,
    marginRight: pxToRem(5),
    marginBottom: pxToRem(1),
  },
  grid_11ms_4: {
    width: pxToRem(95),
    height: pxToRem(75),
    backgroundColor: color.secondary_palette.grays.gary_shade,
    marginBottom: pxToRem(1),
    marginRight: pxToRem(5),
  },
  grid_11ms_5: {
    width: pxToRem(95),
    height: pxToRem(62),
    backgroundColor: color.secondary_palette.grays.gary_shade,
    marginRight: pxToRem(5),
  },
  grid_11ms_6: {
    width: pxToRem(95),
    height: pxToRem(62),
    backgroundColor: color.secondary_palette.grays.gary_shade,
    marginRight: pxToRem(5),
  },
  grid_11ms_7: {
    width: pxToRem(95),
    height: pxToRem(62),
    backgroundColor: color.secondary_palette.grays.gary_shade,
    marginRight: pxToRem(5),
  },
  grid_11ms_8: {
    width: pxToRem(58),
    height: pxToRem(62),
    backgroundColor: color.secondary_palette.grays.gary_shade,
    marginRight: pxToRem(5),
  },
  grid_11ms_9: {
    width: pxToRem(131),
    height: pxToRem(62),
    backgroundColor: color.secondary_palette.grays.gary_shade,
    marginRight: pxToRem(5),
  },
  grid_11ms_10: {
    width: pxToRem(95),
    height: pxToRem(75),
    backgroundColor: color.secondary_palette.grays.gary_shade,
    marginRight: pxToRem(5),
  },
  grid_11ms_11: {
    width: pxToRem(95),
    height: pxToRem(75),
    backgroundColor: color.secondary_palette.grays.gary_shade,
  },
  wrap: {
    flexWrap: "wrap",
  },
  grid_12ms_1: {
    width: pxToRem(95),
    height: pxToRem(75),
    backgroundColor: color.secondary_palette.grays.gary_shade,
    marginRight: pxToRem(5),
  },
  grid_12ms_2: {
    width: pxToRem(95),
    height: pxToRem(50),
    backgroundColor: color.secondary_palette.grays.gary_shade,
    marginRight: pxToRem(5),
  },
  grid_12ms_3: {
    width: pxToRem(95),
    height: pxToRem(75),
    backgroundColor: color.secondary_palette.grays.gary_shade,
    marginRight: pxToRem(5),
  },
  grid_12ms_4: {
    width: pxToRem(95),
    height: pxToRem(62),
    backgroundColor: color.secondary_palette.grays.gary_shade,
    marginRight: pxToRem(5),
  },
  grid_12ms_5: {
    width: pxToRem(95),
    height: pxToRem(75),
    backgroundColor: color.secondary_palette.grays.gary_shade,
    marginRight: pxToRem(5),
  },
  grid_12ms_6: {
    width: pxToRem(95),
    height: pxToRem(62),
    backgroundColor: color.secondary_palette.grays.gary_shade,
    marginRight: pxToRem(5),
  },
  grid_12ms_7: {
    width: pxToRem(95),
    height: pxToRem(62),
    backgroundColor: color.secondary_palette.grays.gary_shade,
    marginRight: pxToRem(5),
  },
  grid_12ms_8: {
    width: pxToRem(95),
    height: pxToRem(62),
    backgroundColor: color.secondary_palette.grays.gary_shade,
  },
  grid_12ms_9: {
    width: pxToRem(95),
    height: pxToRem(62),
    backgroundColor: color.secondary_palette.grays.gary_shade,
    marginRight: pxToRem(5),
  },
  grid_12ms_10: {
    width: pxToRem(95),
    height: pxToRem(62),
    backgroundColor: color.secondary_palette.grays.gary_shade,
  },
  grid_12ms_11: {
    width: pxToRem(71),
    height: pxToRem(75),
    backgroundColor: color.secondary_palette.grays.gary_shade,
    marginRight: pxToRem(10),
  },
  grid_12ms_12: {
    width: pxToRem(115),
    height: pxToRem(75),
    backgroundColor: color.secondary_palette.grays.gary_shade,
  },
  imgDiv: {
    position: "relative",
  },
  numberAlign: {
    width: pxToRem(10),
    height: pxToRem(22),
    fontFamily: "avenir_bold",
    fontSize: pxToRem(16),
    fontWeight: "900",
    color: color.primary_palette.white,
    position: "absolute",
    bottom: pxToRem(4),
    // left: pxToRem(11),
    background: color.primary_palette.black,
    borderRadius: "50%",
    width: pxToRem(22),
    textAlign: "center",
    marginLeft: pxToRem(3),
    marginBottom: pxToRem(3),
  },
  numberAlign3_3: {
    width: pxToRem(10),
    height: pxToRem(22),
    fontFamily: "avenir_bold",
    fontSize: pxToRem(16),
    fontWeight: "900",
    color: color.primary_palette.white,
    position: "absolute",
    bottom: pxToRem(4),
    left: pxToRem(9),
    background: color.primary_palette.black,
    borderRadius: "50%",
    width: pxToRem(22),
    textAlign: "center",
    marginLeft: pxToRem(3),
    marginBottom: pxToRem(3),
  },
  numberAlign4_2: {
    width: pxToRem(10),
    height: pxToRem(22),
    fontFamily: "avenir_bold",
    fontSize: pxToRem(16),
    fontWeight: "900",
    color: color.primary_palette.white,
    position: "absolute",
    bottom: pxToRem(10),
    left: pxToRem(6),
    background: color.primary_palette.black,
    borderRadius: "50%",
    width: pxToRem(22),
    textAlign: "center",
    marginLeft: pxToRem(3),
    marginBottom: pxToRem(3),
  },
});

export default styles;
