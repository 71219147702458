import { withStyles } from "@material-ui/core/styles";
import tradework_theme, { pxToRem, color } from "../../../utilities/themes";

const styles = withStyles({
  alter_confirmation_width: {
    width: pxToRem(545),
    border: `solid ${pxToRem(2)} ${color.primary_palette.free_purple}`,
    backgroundColor: color.secondary_palette.blues.modal_blue,
    "&:focus": {
      outline: "none",
    },
  },
  altergrid_choose: {
    width: pxToRem(542),
    border: `solid ${pxToRem(2)} ${color.primary_palette.free_purple}`,
    backgroundColor: color.secondary_palette.blues.modal_blue,
    "&:focus": {
      outline: "none",
    },
  },
  textRight: {
    textAlign: "right",
  },
  cross_mark: {
    fontSize: pxToRem(15),
  },
  cross_btn: {
    minWidth: pxToRem(40),
    padding: pxToRem(16),
  },
  textCenter: {
    textAlign: "center",
  },
  spacing: {
    padding: `${pxToRem(0)} ${pxToRem(25)}`,
  },
  pleaseBtn: {
    width: pxToRem(194),
    height: pxToRem(30),
    borderRadius: pxToRem(30),
    border: `solid ${pxToRem(2)} ${color.form_colors.blueberry_purple}`,
    backgroundColor: color.primary_palette.white,
    fontSize: pxToRem(16),
    paddingTop: pxToRem(8),
    color: `${color.form_colors.royal_purple_1} !important`,
    ...tradework_theme.typography.styles.avenir_sb,
    marginTop: pxToRem(40),
    marginBottom: pxToRem(28),
  },
  choose_number: {
    width: pxToRem(50),
    marginTop: pxToRem(7),
    "& p": {
      fontSize: pxToRem(16),
      ...tradework_theme.typography.styles.avenir_roman,
      color: color.primary_palette.black,
    },
    "& .MuiSvgIcon-root": {
      fontSize: pxToRem(10),
    },
  },
  radio_button: {
    "& .MuiSvgIcon-root": {
      fontSize: pxToRem(20),
    },
    "& .MuiTypography-root": {
      color: color.greyish_brown,
      fontSize: pxToRem(25),
    },
  },
  image_spacing: {
    marginTop: pxToRem(4),
    marginLeft: pxToRem(16),
  },
  radio_padding: {
    paddingLeft: pxToRem(57),
    "& .MuiFormControlLabel-root": {
      textTransform: "inherit",
    },
  },
  padding_bottom: {
    paddingBottom: pxToRem(7),
  },
  imageAlign: {
    marginTop: pxToRem(-14),
  },
});

export default styles;
