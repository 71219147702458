import moment from "moment";
import { groupBy } from "lodash";

const getMonthIndex = (month = "") => {
  return month && moment().month(month).format("M");
};

const getMonthNameFromDate = (date = "", format = "MMMM") => {
  let monthNum = moment(date).month();
  monthNum = monthNum < 1 ? 1 : monthNum;
  return date && moment(monthNum, "MM").format(format);
};

const getYearFromDate = (date = "") => {
  return date && moment(date).year();
};

const getGroupedArray = (data, groupByKey) => {
  return groupBy(
    data.map((each) => {
      if (!each[groupByKey]) {
        return { ...each, [groupByKey]: "noParent" };
      }
      return each;
    }),
    groupByKey
  );
};

export {
  getMonthIndex,
  getMonthNameFromDate,
  getYearFromDate,
  getGroupedArray,
};
