import React, { useState } from "react";

import { color } from "../../utilities/themes";
import DeleteCompanyStyles from "./styles/deleteCompanyStyles";
import Text from "../common/ui_kit/text";
import strings from "../../utilities/strings";
import Close_Icon from "../data_display/icons/Close";
import CustomButton from "../navigations/custom_buttons";
import CustomTextField from "../inputs/custom_textfields";
import CustomTextArea from "../inputs/custom_text_area";
import { SettingsActions } from "../../redux/actions";
import { useDispatch } from "react-redux";

function Feedback(props) {
  const { classes, onClose, locationInfo } = props;
  const [state, setState] = useState({
    name: "",
    email: "",
    feedback: "",
  });
  const dispatch = useDispatch();

  const { name, email, feedback } = state;

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setState({
      ...state,
      [name]: value,
    });
  };


  const sendFeedback = () => {
    const dataToSubmit = {
      ...state,
      feedbackUrl: locationInfo,
    };
    dispatch(
      SettingsActions.sendFeedback(dataToSubmit, (res) => {
        if (!res.error.error) {
          onClose();
        }
      })
    );
  };

  return (
    <>
      <div className={classes.feedback_modal}>
        <div className={classes.txtRight}>
          <Close_Icon className={classes.cross_icon} onClick={onClose} />
        </div>
        <div className={classes.textCenter}>
          <img
            src="/assets/images/Beta_Stamp.png"
            alt="paper"
            className={classes.betaIcon}
          />
          <Text
            size={20}
            family="avenir_sb"
            color={color.primary_palette.franklin_purple}
            className={classes.styleChangesTxt}
          >
            {strings.modal.titles.your_feedback}
          </Text>
          <div className={classes.paddingBottom20}>
            <Text
              size={12}
              family="avenir_sb"
              color={color.primary_palette.franklin_purple}
              className={classes.labelChangesText}
            >
              {strings.modal.titles.your_name}
            </Text>
            <CustomTextField
              className={classes.invite_textField}
              onBlur={handleInputChange}
              defaultValue={name}
              name="name"
            />
          </div>
          <div className={classes.paddingBottom20}>
            <Text
              size={12}
              family="avenir_sb"
              color={color.primary_palette.franklin_purple}
              className={classes.labelChangesText}
            >
              {strings.modal.titles.your_email}
            </Text>
            <CustomTextField
              className={classes.invite_textField}
              defaultValue={email}
              name="email"
              onBlur={handleInputChange}
            />
          </div>
          <div className={classes.paddingBottom20}>
            <Text
              size={12}
              family="avenir_sb"
              color={color.primary_palette.franklin_purple}
              className={classes.labelChangesText}
            >
              {strings.modal.titles.your_comments}
            </Text>
            <CustomTextArea
              className={classes.invite_textArea}
              defaultValue={feedback}
              name="feedback"
              onBlur={handleInputChange}
            />
          </div>
          <CustomButton className={classes.dispatchBtn} onClick={sendFeedback}>
            {strings.modal.titles.dispatch}
          </CustomButton>
        </div>
      </div>
    </>
  );
}

export default DeleteCompanyStyles(Feedback);
