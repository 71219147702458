import { withStyles } from "@material-ui/core/styles";
import tradework_theme, { pxToRem, color } from "../../../utilities/themes";

const styles = withStyles({
  publicviewProject: {
    width: pxToRem(1023),
    margin: "0 auto",
    position: "relative",
  },
  textCenter: {
    textAlign: "center",
  },
  horizontal_DB: {
    width: pxToRem(210),
  },
  padding_top_25: {
    paddingTop: pxToRem(25),
  },
  padding_top_30: {
    paddingTop: pxToRem(30),
  },
  textupper: {
    textTransform: "uppercase",
  },
  folder: {
    width: pxToRem(25),
  },
  heart: {
    width: pxToRem(25),
  },
  grid_icon: {
    flexGrow: 1,
    width: "10%",
    textAlign: "right",
    borderRight: `solid ${pxToRem(1)} ${color.primary_palette.free_purple}`,
    paddingRight: pxToRem(10),
    alignSelf: "center",
  },
  grid_heading: {
    flexGrow: 1,
    width: "70%",
    alignSelf: "flex-end",
  },
  description: {
    flexGrow: 1,
    width: "20%",
    textAlign: "right",
  },
  grid_block: {
    paddingTop: pxToRem(26),
  },
  grid_first: {
    width: pxToRem(1023),
    backgroundColor: color.secondary_palette.grays.gary_shade,
    height: pxToRem(529),
    marginTop: pxToRem(10),
    marginBottom: pxToRem(10),
  },
  hash_tags: {
    fontSize: pxToRem(16),
    paddingBottom: pxToRem(4),
    color: color.primary_palette.black,
    ...tradework_theme.typography.styles.gillsans_r,
    textAlign: "right",
    borderBottom: `solid ${pxToRem(1)} ${
      color.secondary_palette.grays.gary_shade
    }`,
  },
  betaStampIcon: {
    height: pxToRem(100),
    position: "absolute",
    right: pxToRem(-238),
    top: pxToRem(-34),
    cursor: "pointer",
  },
});

export default styles;
