import React from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";

import CustomButton from "../navigations/custom_buttons";

import publicStyles from "./styles/portfolioUserStyles";
import PublicNav from "../publicNav/publicNav";
import strings from "../../utilities/strings";
import { ProfileActions } from "../../redux/actions";

function PortView(props) {
  const { classes } = props;

  const history = useHistory();
  const dispatch = useDispatch();

  const editProjectData = (projectId) => () => {
    dispatch(
      ProfileActions.getIndividualProjectData(projectId, () => {
        history.push({
          pathname: "/AddProjects",
          state: { edit: true },
        });
      })
    );
  };

  const redirectToPortfolioPage = () => {
    history.push("/portfolioView");
  };

  return (
    <>
      {" "}
      <CustomButton
        className={classes.preview_btn}
        onMouseUp={editProjectData(localStorage.getItem("projectId"))}
      >
        {strings.add_project.titles.back_to_project}
      </CustomButton>
      <CustomButton
        className={classes.preview_btn_large}
        onMouseUp={redirectToPortfolioPage}
      >
        {strings.add_project.titles.back_to_portfolio}
      </CustomButton>
      <PublicNav />
    </>
  );
}

export default publicStyles(PortView);
