import React from "react";
import { get } from "lodash";

import GridmsStyles from "./styles/gridmsStyles";
import Row from "../common/ui_kit/row";

function GridElevenMs(props) {
  const { classes, imageData } = props;

  return (
    <>
      <div className={classes.publicviewProject}>
        <Row className={classes.grid_main_spacing}>
          <div>
            <div className={classes.imgDiv}>
              <img
                className={classes.grid_11ms_1}
                alt=""
                src={
                  get(
                    imageData[0],
                    "alterImageUri.croppedImage",
                    "/assets/images/green.PNG"
                  ) || "/assets/images/green.PNG"
                }
              />
              {!get(imageData[0], "alterImageUri.croppedImage") && (
                <div className={classes.numberAlign}>1</div>
              )}
            </div>
            <div className={classes.imgDiv}>
              <img
                className={classes.grid_11ms_2}
                alt=""
                src={
                  get(
                    imageData[4],
                    "alterImageUri.croppedImage",
                    "/assets/images/green.PNG"
                  ) || "/assets/images/green.PNG"
                }
              />
              {!get(imageData[4], "alterImageUri.croppedImage") && (
                <div className={classes.numberAlign}>5</div>
              )}
            </div>
          </div>
          <div>
            <div className={classes.imgDiv}>
              <img
                className={classes.grid_11ms_3}
                alt=""
                src={
                  get(
                    imageData[1],
                    "alterImageUri.croppedImage",
                    "/assets/images/Light Blue Grid Box.png"
                  ) || "/assets/images/Light Blue Grid Box.png"
                }
              />
              {!get(imageData[1], "alterImageUri.croppedImage") && (
                <div className={classes.numberAlign}>2</div>
              )}
            </div>
            <div className={classes.imgDiv}>
              <img
                className={classes.grid_11ms_4}
                alt=""
                src={
                  get(
                    imageData[5],
                    "alterImageUri.croppedImage",
                    "/assets/images/blue.PNG"
                  ) || "/assets/images/blue.PNG"
                }
              />
              {!get(imageData[5], "alterImageUri.croppedImage") && (
                <div className={classes.numberAlign}>6</div>
              )}
            </div>
            <div className={classes.imgDiv}>
              <img
                className={classes.grid_11ms_5}
                alt=""
                src={
                  get(
                    imageData[8],
                    "alterImageUri.croppedImage",
                    "/assets/images/Light Blue Grid Box.png"
                  ) || "/assets/images/Light Blue Grid Box.png"
                }
              />
              {!get(imageData[8], "alterImageUri.croppedImage") && (
                <div className={classes.numberAlign}>9</div>
              )}
            </div>
          </div>
          <Row className={classes.wrap}>
            <Row>
              <div className={classes.imgDiv}>
                <img
                  className={classes.grid_11ms_6}
                  alt=""
                  src={
                    get(
                      imageData[2],
                      "alterImageUri.croppedImage",
                      "/assets/images/Light Blue Grid Box.png"
                    ) || "/assets/images/Light Blue Grid Box.png"
                  }
                />
                {!get(imageData[2], "alterImageUri.croppedImage") && (
                  <div className={classes.numberAlign}>3</div>
                )}
              </div>
              <div className={classes.imgDiv}>
                <img
                  className={classes.grid_11ms_7}
                  alt=""
                  src={
                    get(
                      imageData[3],
                      "alterImageUri.croppedImage",
                      "/assets/images/Light Blue Grid Box.png"
                    ) || "/assets/images/Light Blue Grid Box.png"
                  }
                />
                {!get(imageData[3], "alterImageUri.croppedImage") && (
                  <div className={classes.numberAlign}>4</div>
                )}
              </div>
            </Row>
            <Row>
              <div className={classes.imgDiv}>
                <img
                  className={classes.grid_11ms_8}
                  alt=""
                  src={
                    get(
                      imageData[6],
                      "alterImageUri.croppedImage",
                      "/assets/images/green.PNG"
                    ) || "/assets/images/green.PNG"
                  }
                />
                {!get(imageData[6], "alterImageUri.croppedImage") && (
                  <div className={classes.numberAlign}>7</div>
                )}
              </div>
              <div className={classes.imgDiv}>
                <img
                  className={classes.grid_11ms_9}
                  alt=""
                  src={
                    get(
                      imageData[7],
                      "alterImageUri.croppedImage",
                      "/assets/images/gray.PNG"
                    ) || "/assets/images/gray.PNG"
                  }
                />
                {!get(imageData[7], "alterImageUri.croppedImage") && (
                  <div className={classes.numberAlign}>8</div>
                )}
              </div>
            </Row>
            <Row>
              <div className={classes.imgDiv}>
                <img
                  className={classes.grid_11ms_10}
                  alt=""
                  src={
                    get(
                      imageData[9],
                      "alterImageUri.croppedImage",
                      "/assets/images/blue.PNG"
                    ) || "/assets/images/blue.PNG"
                  }
                />
                {!get(imageData[9], "alterImageUri.croppedImage") && (
                  <div className={classes.numberAlign}>10</div>
                )}
              </div>
              <div className={classes.imgDiv}>
                <img
                  className={classes.grid_11ms_11}
                  alt=""
                  src={
                    get(
                      imageData[10],
                      "alterImageUri.croppedImage",
                      "/assets/images/blue.PNG"
                    ) || "/assets/images/blue.PNG"
                  }
                />
                {!get(imageData[10], "alterImageUri.croppedImage") && (
                  <div className={classes.numberAlign}>11</div>
                )}
              </div>
            </Row>
          </Row>
        </Row>
      </div>
    </>
  );
}

export default GridmsStyles(GridElevenMs);
