import { withStyles } from "@material-ui/core/styles";
import tradework_theme, { pxToRem, color } from "../../../utilities/themes";

const styles = withStyles({
  business_width: {
    width: pxToRem(499),
    // backgroundColor: color.primary_palette.white,
    // border: `solid ${pxToRem(1)} ${color.gray_white}`,
    display: "block",
    paddingBottom: pxToRem(0),
  },
  main_text: {
    display: "block",
    paddingLeft: pxToRem(10),
  },
  inner_block: {
    border: `solid ${pxToRem(2)} ${color.primary_palette.black}`,
    padding: `${pxToRem(0)} ${pxToRem(11)}`,
    display: "block",
    position: "relative",
    // margin: pxToRem(16),
  },
  display_blk: {
    width: "100%",
    display: "block",
    textAlign: "center",
    "& .MuiFormControl-root": {
      width: "100%",
      margin: "0 auto",
    },
    "& .MuiInputBase-input": {
      textAlign: "center",
      fontSize: pxToRem(37),
      paddingTop: pxToRem(24),
      ...tradework_theme.typography.styles.millerdisplay_light,
    },
  },
  cover_display_blk: {
    width: "100%",
    display: "block",
    textAlign: "center",
    "& .MuiFormControl-root": {
      width: "100%",
      margin: "0 auto",
    },
    "& .MuiInputBase-input": {
      textAlign: "center",
      fontSize: pxToRem(37),
      ...tradework_theme.typography.styles.millerdisplay_light,
    },
  },
  flex: {
    display: "flex",
    flexFlow: "row wrap",
    padding: `${pxToRem(21)} ${pxToRem(0)} ${pxToRem(26)} ${pxToRem(0)}`,
  },
  companyNameStyle: {
    borderBottom: `solid ${pxToRem(1)} ${color.primary_palette.black}`,
    wordBreak: "break-word",
    paddingTop: pxToRem(30),
    paddingBottom: pxToRem(13),
    paddingRight: pxToRem(26),
    paddingLeft: pxToRem(26),
  },
  textFiled: {
    width: "50%",
    position: "relative",
  },
  textFiled_last: {
    position: "relative",
  },
  email_field: {
    width: `${pxToRem(218)} !important`,
  },
  email_field_business: {
    width: `${pxToRem(218)} !important`,
    "& .MuiFormLabel-root": {
      paddingLeft: pxToRem(14),
    },
    "& .MuiInputBase-input": {
      paddingLeft: pxToRem(14),
    },
  },
  phone_field: {
    width: `${pxToRem(165)} !important`,
    "& .MuiFormLabel-root": {
      paddingLeft: pxToRem(14),
    },
    "& .MuiInputBase-input": {
      paddingLeft: pxToRem(14),
      paddingRight: pxToRem(41),
    },
  },
  website_field: {
    width: `${pxToRem(217)} !important`,
  },
  city_field_card: {
    width: `${pxToRem(175)} !important`,
    // "& .MuiFormLabel-root": {
    //   paddingLeft: pxToRem(14),
    // },
    // "& .MuiInputBase-input": {
    //   paddingLeft: pxToRem(14),
    // },
  },
  st_field: {
    width: `${pxToRem(28)} !important`,
    "& .MuiInputBase-input": {
      textTransform: "uppercase",
    },
  },
  zip_field: {
    width: `${pxToRem(46)} !important`,
  },
  error_field: {
    "& .MuiFormLabel-root": {
      width: "100%",
      fontSize: pxToRem(14),
      ...tradework_theme.typography.styles.avenir_light,
      // fontStyle: "oblique",
      color: `${color.primary_palette.christmas_red} !important`,
      fontWeight: "bold !important",
    },
    "& .MuiInputLabel-shrink": {
      fontSize: pxToRem(10),
      marginTop: pxToRem(5),
      color: `${color.primary_palette.christmas_red} !important`,
      ...tradework_theme.typography.styles.avenir_black_r,
    },
    "& .MuiInput-underline": {
      "&:before": {
        borderBottom: `0 !important`,
      },
      "&:hover": {
        borderBottom: `${pxToRem(1)} solid ${
          color.primary_palette.black
        } !important`,
      },
      borderBottom: `${pxToRem(1)} solid ${
        color.primary_palette.christmas_red
      } !important`,
      "&:after": {
        borderBottom: `${pxToRem(2)} solid ${
          color.primary_palette.christmas_red
        } !important`,
      },
    },
  },
  success_field: {
    "& .MuiFormLabel-root": {
      width: "100%",
      fontSize: pxToRem(14),
      ...tradework_theme.typography.styles.avenir_light,
      // fontStyle: "oblique",
      color: `${color.primary_palette.franklin_purple} !important`,
      fontWeight: "bold !important",
    },
    "& .MuiInputLabel-shrink": {
      fontSize: pxToRem(10),
      marginTop: pxToRem(5),
      color: `${color.primary_palette.franklin_purple} !important`,
      ...tradework_theme.typography.styles.avenir_black_r,
    },
    "& .MuiInput-underline": {
      borderBottom: `${pxToRem(1)} solid ${
        color.form_colors.sucess_color
      } !important`,
      "&:before": {
        borderBottom: `0 !important`,
      },
      "&:after": {
        borderBottom: `${pxToRem(2)} solid ${
          color.form_colors.sucess_color
        } !important`,
      },
      "&:hover": {
        borderBottom: `${pxToRem(1)} solid ${
          color.primary_palette.black
        } !important`,
      },
    },
  },
  input_field: {
    width: pxToRem(215),
    position: "relative",
    textAlign: "left",
    "& .MuiInputBase-input": {
      textAlign: "left",
      ...tradework_theme.typography.styles.NeutraText,
      fontSize: pxToRem(16),
      color: color.primary_palette.black,
    },
    "& .MuiInput-underline": {
      borderBottom: `${pxToRem(1)} solid ${
        color.secondary_palette.grays.shadow_gray
      }`,
      "&:before": {
        borderBottom: `0 !important`,
      },
      "&:hover": {
        borderBottom: `${pxToRem(1)} solid ${color.primary_palette.black}`,
      },
    },
    "& .MuiFormLabel-root": {
      width: "100%",
      fontSize: pxToRem(14),
      ...tradework_theme.typography.styles.avenir_light,
      // fontStyle: "oblique",
    },
    "& .MuiInputLabel-shrink": {
      fontSize: pxToRem(10),
      marginTop: pxToRem(5),
      color: color.primary_palette.franklin_purple,
      ...tradework_theme.typography.styles.avenir_black_r,
    },
  },
  company_name: {
    "& .MuiInput-underline": {
      "&:before": {
        borderBottom: `0 !important`,
      },
    },
    "& .MuiInputBase-input": {
      ...tradework_theme.typography.styles.NeutraText,
      fontSize: pxToRem(26),
      borderBottom: `${pxToRem(1)} solid ${
        color.secondary_palette.grays.shadow_gray
      }`,
      color: color.primary_palette.black,
      "&::placeholder": {
        ...tradework_theme.typography.styles.avenir_light,
        paddingTop: pxToRem(4),
        // fontStyle: "oblique",
        color: color.wizard_box_colors.shadow_gray,
        opacity: 1,
      },
    },
  },
  company_name_error: {
    "& .MuiInput-underline": {
      "&:before": {
        borderBottom: `0 !important`,
      },
    },
    "& .MuiInputBase-input": {
      ...tradework_theme.typography.styles.NeutraText,
      fontSize: pxToRem(26),
      borderBottom: `${pxToRem(1)} solid ${
        color.primary_palette.christmas_red
      }`,
      color: color.primary_palette.black,
      "&::placeholder": {
        ...tradework_theme.typography.styles.avenir_light,
        paddingTop: pxToRem(4),
        // fontStyle: "oblique",
        color: color.primary_palette.christmas_red,
        fontWeight: "bold",
        opacity: 1,
      },
    },
  },
  company_name_success: {
    "& .MuiInput-underline": {
      "&:before": {
        borderBottom: `0 !important`,
      },
    },
    "& .MuiInputBase-input": {
      ...tradework_theme.typography.styles.NeutraText,
      fontSize: pxToRem(26),
      borderBottom: `${pxToRem(1)} solid ${color.form_colors.sucess_color}`,
      color: color.primary_palette.black,
      "&::placeholder": {
        ...tradework_theme.typography.styles.avenir_light,
        paddingTop: pxToRem(4),
        // fontStyle: "oblique",
        color: color.form_colors.sucess_color,
        fontWeight: "bold",
        opacity: 1,
      },
    },
  },
  last_field: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    marginTop: pxToRem(10),
  },
  modal_bussinesscard: {
    width: pxToRem(1142),
    backgroundColor: color.secondary_palette.blues.modal_blue,
    border: `solid ${pxToRem(2)} ${color.primary_palette.franklin_purple}`,
    position: "relative",
  },
  businessBlockWidth: {
    width: pxToRem(520),
    display: "inline-block",
  },
  nickNameBlock: {
    width: pxToRem(576),
    display: "inline-block",
    float: "right",
    flexDirection: "column",
  },
  align_right: {
    textAlign: "right",
  },
  cross_mark: {
    padding: pxToRem(3),
    minWidth: pxToRem(30),
    "& .MuiSvgIcon-root": {
      fontSize: pxToRem(15),
    },
  },
  radioBtn: {
    margin: pxToRem(3),
    "& .MuiTypography-body1": {
      color: color.primary_palette.free_purple,
      fontSize: pxToRem(16),
      ...tradework_theme.typography.styles.avenir_sb,
    },
    "& .MuiSvgIcon-fontSizeSmall": {
      fontSize: pxToRem(16),
    },
  },
  card: {
    display: "block",
    padding: `${pxToRem(10)} ${pxToRem(20)}`,
  },
  free_block: {
    padding: `${pxToRem(0)} ${pxToRem(20)}`,
    justifyContent: "center",
  },
  stamp_width: {
    width: pxToRem(69),
    position: "absolute",
    top: pxToRem(-36),
  },
  slect_category: {
    paddingTop: pxToRem(20),
  },
  width_270: {
    width: pxToRem(270),
  },
  width_155: {
    width: pxToRem(155),
  },
  width_130: {
    width: pxToRem(130),
  },
  select_items: {
    height: pxToRem(20),
    borderRadius: pxToRem(2),
    marginRight: pxToRem(8),
    marginTop: pxToRem(6),
    backgroundColor: color.primary_palette.white,
    border: `solid ${pxToRem(1)} ${color.secondary_palette.grays.shadow_gray}`,
    "& .MuiSvgIcon-root": {
      fontSize: pxToRem(14),
    },
    "& p": {
      ...tradework_theme.typography.styles.avenir_book_r,
      fontSize: pxToRem(14),
      color: color.primary_palette.black,
      textOverflow: "ellipsis",
      overflow: "hidden",
      whiteSpace: "nowrap",
      textTransform: "capitalize",
    },
  },
  add_btn: {
    width: pxToRem(59),
    height: pxToRem(20),
    borderRadius: pxToRem(3),
    marginTop: pxToRem(6),
    padding: 0,
    border: `solid ${pxToRem(1)} ${color.primary_palette.free_purple}`,
    backgroundColor: color.secondary_palette.grays.btn_gray,
    "& .MuiButton-label": {
      fontSize: pxToRem(15),
      ...tradework_theme.typography.styles.avenir_sb,
      color: color.primary_palette.free_purple,
      lineHeight: pxToRem(15),
    },
  },
  customer_info: {
    display: "inline-block",
  },
  customer_block: {
    paddingTop: pxToRem(14),
  },
  continue_btn: {
    width: pxToRem(120),
    height: pxToRem(25),
    borderRadius: pxToRem(27),
    marginTop: pxToRem(-40),
    marginRight: pxToRem(30),
    border: `solid ${pxToRem(1)} ${color.primary_palette.franklin_purple}`,
    backgroundColor: color.primary_palette.white,
    "& .MuiButton-label": {
      fontSize: pxToRem(15),
      ...tradework_theme.typography.styles.avenir_sb,
      color: color.primary_palette.franklin_purple,
    },
  },
  continue_btn_disable: {
    width: pxToRem(120),
    height: pxToRem(25),
    borderRadius: pxToRem(27),
    marginTop: pxToRem(-10),
    marginBottom: pxToRem(45),
    marginRight: pxToRem(30),
    border: `solid ${pxToRem(1)} ${color.primary_palette.disable_border}`,
    backgroundColor: color.primary_palette.white,
    "& .MuiButton-label": {
      fontSize: pxToRem(15),
      ...tradework_theme.typography.styles.avenir_sb,
    },
  },
  customScrollHeight: {
    minHeight: `${pxToRem(50)} !important`,
    "& li": {
      overflow: "hidden",
      whiteSpace: "normal",
      textOverflow: "ellipsis",
    },
  },
  customScrollHeightCategory: {
    minHeight: `${pxToRem(330)} !important`,
    "& li": {
      overflow: "hidden",
      whiteSpace: "normal",
      textOverflow: "ellipsis",
    },
  },
  required_ele: {
    fontSize: pxToRem(13),
    paddingLeft: pxToRem(2),
    color: color.form_colors.blueberry_purple,
    left: pxToRem(-3),
    top: pxToRem(2),
    position: "relative",
  },
  required_ele_error: {
    fontSize: pxToRem(13),
    paddingLeft: pxToRem(2),
    color: color.primary_palette.christmas_red,
    left: pxToRem(-3),
    top: pxToRem(2),
    position: "relative",
  },
  portfolio_block: {
    display: "block",
    paddingTop: pxToRem(20),
    paddingBottom: pxToRem(20),
  },
  portfolio_text: {
    ...tradework_theme.typography.styles.avenir_sb,
    marginRight: pxToRem(9),
  },
  portfolio_field: {
    marginTop: pxToRem(-5),
    paddingLeft: pxToRem(5),
    position: "relative",
    "& .MuiInputBase-input": {
      ...tradework_theme.typography.styles.NeutraText,
      fontSize: pxToRem(18),
      color: color.primary_palette.black,
      paddingBottom: 0,
      borderBottom: `solid ${pxToRem(1)} ${
        color.secondary_palette.grays.shadow_gray
      }`,
    },
    // "& .MuiInput-underline": {
    //   "&:before": {
    //     borderBottom: `${pxToRem(0)} !important`,
    //   },
    //   "&:after": {
    //     borderBottom: `${pxToRem(0)} !important`,
    //   },
    // },
    "& .MuiFormLabel-root": {
      width: "100%",
      position: "absolute",
      top: pxToRem(-20),
      ...tradework_theme.typography.styles.avenir_light,
      fontSize: pxToRem(16),
      color: color.secondary_palette.grays.shadow_gray,
      // fontStyle: "oblique",
      left: pxToRem(6),
    },
    "& .MuiInputLabel-shrink": {
      display: "none",
    },
    "& .MuiInputBase-root": {
      marginTop: pxToRem(0),
    },
  },
  portfolio_field_error: {
    marginTop: pxToRem(-5),
    paddingLeft: pxToRem(5),
    position: "relative",
    "& .MuiInputBase-input": {
      ...tradework_theme.typography.styles.NeutraText,
      fontSize: pxToRem(16),
      color: color.primary_palette.black,
      paddingBottom: 0,
      borderBottom: `solid ${pxToRem(1)} ${
        color.primary_palette.christmas_red
      }`,
    },
    "& .MuiInput-underline": {
      "&:before": {
        borderBottom: `solid ${pxToRem(1)} ${
          color.primary_palette.christmas_red
        }`,
      },
      "&:after": {
        borderBottom: `solid ${pxToRem(1)} ${
          color.primary_palette.christmas_red
        }`,
      },
    },
    "& .MuiFormLabel-root": {
      width: "100%",
      position: "absolute",
      top: pxToRem(-20),
      ...tradework_theme.typography.styles.avenir_light,
      fontSize: pxToRem(16),
      color: color.secondary_palette.grays.shadow_gray,
      // fontStyle: "oblique",
      left: pxToRem(6),
    },
    "& .MuiInputLabel-shrink": {
      display: "none",
    },
    "& .MuiInputBase-root": {
      marginTop: pxToRem(0),
    },
  },
  portfolio_field_success: {
    marginTop: pxToRem(-5),
    paddingLeft: pxToRem(5),
    position: "relative",
    "& .MuiInputBase-input": {
      ...tradework_theme.typography.styles.NeutraText,
      fontSize: pxToRem(16),
      color: color.primary_palette.black,
      paddingBottom: 0,
      borderBottom: `solid ${pxToRem(1)} ${color.form_colors.sucess_color}`,
    },
    "& .MuiInput-underline": {
      "&:before": {
        borderBottom: `solid ${pxToRem(1)} ${color.form_colors.sucess_color}`,
      },
      "&:after": {
        borderBottom: `solid ${pxToRem(1)} ${color.form_colors.sucess_color}`,
      },
    },
    "& .MuiFormLabel-root": {
      width: "100%",
      position: "absolute",
      top: pxToRem(-20),
      ...tradework_theme.typography.styles.avenir_light,
      fontSize: pxToRem(16),
      color: color.secondary_palette.grays.shadow_gray,
      // fontStyle: "oblique",
      left: pxToRem(6),
    },
    "& .MuiInputLabel-shrink": {
      display: "none",
    },
    "& .MuiInputBase-root": {
      marginTop: pxToRem(0),
    },
  },
  width_334: {
    width: pxToRem(334),
  },
  width_356: {
    width: pxToRem(356),
  },
  field_icon: {
    fontSize: pxToRem(13),
    color: color.form_colors.blueberry_purple,
    position: "absolute",
    bottom: pxToRem(4),
  },
  field_icon_email: {
    fontSize: pxToRem(13),
    color: color.form_colors.blueberry_purple,
    position: "absolute",
    bottom: pxToRem(4),
  },
  select_field_required: {
    fontSize: pxToRem(14),
    color: color.primary_palette.franklin_purple,
    position: "absolute",
    left: pxToRem(0),
    top: pxToRem(32),
    zIndex: 9,
  },
  select_field_required_another: {
    fontSize: pxToRem(14),
    color: color.primary_palette.franklin_purple,
    position: "absolute",
    top: pxToRem(10),
    zIndex: 9,
  },
  select_field_required_error: {
    fontSize: pxToRem(14),
    color: color.primary_palette.christmas_red,
    position: "absolute",
    left: pxToRem(0),
    top: pxToRem(32),
    zIndex: 9,
  },
  select_field_required_error_another: {
    fontSize: pxToRem(14),
    color: color.primary_palette.christmas_red,
    position: "absolute",
    top: pxToRem(10),
    zIndex: 9,
  },
  required_error: {
    fontSize: pxToRem(13),
    color: color.primary_palette.christmas_red,
    position: "absolute",
    bottom: pxToRem(4),
  },
  email_required_error: {
    fontSize: pxToRem(13),
    color: color.primary_palette.christmas_red,
    position: "absolute",
    bottom: pxToRem(4),
  },
  company_name_required: {
    fontSize: pxToRem(13),
    color: color.form_colors.blueberry_purple,
    position: "absolute",
    left: pxToRem(0),
    top: pxToRem(10),
  },
  company_name_required_error: {
    fontSize: pxToRem(13),
    color: color.primary_palette.christmas_red,
    position: "absolute",
    left: pxToRem(0),
    top: pxToRem(10),
  },
  error: {
    color: color.primary_palette.christmas_red,
  },
  coloredCrossStyle: {
    width: pxToRem(14),
    marginLeft: pxToRem(6),
    marginRight: pxToRem(6),
    position: "relative",
    top: pxToRem(6),
    cursor: "pointer",
    "& path": {
      fill: color.secondary_palette.grays.shadow_gray,
    },
  },
  regionListAlign: {
    paddingLeft: pxToRem(12),
    margin: 0,
    paddingBottom: pxToRem(7),
    "& li": {
      fontSize: pxToRem(15),
      ...tradework_theme.typography.styles.avenir_sb,
      color: color.primary_palette.black,
    },
  },
  info_img: {
    verticalAlign: "middle",
    marginTop: pxToRem(-2),
    paddingRight: pxToRem(3),
    width: pxToRem(9),
    height: pxToRem(9),
  },
  error_text_email: {
    position: "absolute",
    top: pxToRem(-8),
  },
  phn_error: {
    top: pxToRem(-13),
  },
  customer_info_data: {
    paddingLeft: pxToRem(7),
    display: "inline-block",
  },
  select_field: {
    width: "100%",
    position: "relative",
    "& .MuiFormControl-root": {
      marginTop: pxToRem(32),
      margin: 0,
      width: "99%",
    },
    "& .MuiInputBase-root": {
      paddingRight: 0,
    },
    "& .MuiInputBase-input": {
      ...tradework_theme.typography.styles.NeutraText,
      fontSize: pxToRem(26),
      paddingRight: `${pxToRem(30)} !important`,
      borderBottom: `${pxToRem(1)} solid ${
        color.secondary_palette.grays.shadow_gray
      }`,
      "&::placeholder": {
        ...tradework_theme.typography.styles.avenir_sb,
        color: color.pubic_green,
        opacity: 1,
        fontSize: pxToRem(22),
        // fontStyle: "oblique",
      },
    },
    "& .MuiInput-underline": {
      "&:before": {
        borderBottom: `0 !important`,
      },
    },
    "& .MuiSvgIcon-root": {
      fontSize: pxToRem(22),
      height: pxToRem(11),
      position: "absolute",
      right: pxToRem(13),
    },
  },
  select_field_error: {
    width: "100%",
    position: "relative",
    "& .MuiFormControl-root": {
      marginTop: pxToRem(32),
      margin: 0,
      width: "99%",
    },
    "& .MuiInput-underline": {
      "&:before": {
        borderBottom: `0 !important`,
      },
    },
    "& .MuiSvgIcon-root": {
      fontSize: pxToRem(22),
      height: pxToRem(11),
      position: "absolute",
      right: pxToRem(13),
    },
    "& .MuiInputBase-input": {
      borderBottom: `${pxToRem(1)} solid ${
        color.primary_palette.christmas_red
      }`,
      ...tradework_theme.typography.styles.NeutraText,
      color: color.primary_palette.black,
    },
  },
  scroll_options: {
    borderBottom: `${pxToRem(1)} solid ${color.brown_grey_two}`,
    borderTop: `${pxToRem(1)} solid ${color.brown_grey_two}`,
    position: "relative",
    padding: `${pxToRem(5)} ${pxToRem(0)}`,
    paddingLeft: pxToRem(5),
    alignItems: "center",
  },
  addressBlock: {
    ...tradework_theme.typography.styles.gillsans_light,
    fontSize: pxToRem(14),
    color: color.greyish_brown,
    position: "absolute",
    left: pxToRem(55),
    top: pxToRem(27),
  },
  defaultCompLogo: {
    height: pxToRem(40),
    width: pxToRem(40),
    display: "inline-block",
    verticalAlign: "middle",
  },
  width_100: {
    width: "100%",
  },
  companyNameBlock: {
    ...tradework_theme.typography.styles.gillsans_sb,
    color: color.primary_palette.black,
  },
  check_box: {
    marginRight: pxToRem(0),
    "& .MuiTypography-root": {
      ...tradework_theme.typography.styles.gillsans_sb,
      color: color.primary_palette.franklin_purple,
      fontSize: pxToRem(14),
      marginRight: pxToRem(30),
    },
    "& .MuiButtonBase-root": {
      paddingTop: pxToRem(0),
      paddingBottom: pxToRem(0),
    },
  },
  txtTransform: {
    textTransform: "uppercase",
  },
  alignItems: {
    alignItems: "flex-end",
  },
  dropdown: {
    marginRight: pxToRem(10),
    marginLeft: pxToRem(10),
    height: pxToRem(20),
    borderRadius: pxToRem(3),
    border: `solid ${pxToRem(1)} ${color.secondary_palette.grays.shadow_gray}`,
    backgroundColor: color.primary_palette.white,
    "& .MuiSvgIcon-root": {
      fontSize: pxToRem(14),
    },
  },
  txtRight: {
    textAlign: "right",
  },
  padding_bottom_16: {
    paddingBottom: pxToRem(16),
    paddingTop: pxToRem(16),
    position: "relative",
  },
  city_field: {
    marginRight: pxToRem(10),
    marginLeft: pxToRem(10),
    width: pxToRem(310),
    fontSize: pxToRem(16),
    paddingRight: pxToRem(16),
    "& .MuiInputBase-input": {
      paddingBottom: pxToRem(0),
      ...tradework_theme.typography.styles.NeutraText,
      color: color.primary_palette.black,
    },
    "&::placeholder": {
      ...tradework_theme.typography.styles.avenir_light,
      paddingTop: pxToRem(4),
      // fontStyle: "oblique",
      color: color.wizard_box_colors.shadow_gray,
    },
  },
  padding_bottom_21: {
    paddingBottom: pxToRem(21),
  },
  continueBtn: {
    width: pxToRem(120),
    height: pxToRem(25),
    borderRadius: pxToRem(27),
    border: `solid ${pxToRem(1)} ${color.primary_palette.franklin_purple}`,
    backgroundColor: color.primary_palette.white,
    padding: 0,
    color: `${color.primary_palette.franklin_purple} !important`,
    ...tradework_theme.typography.styles.avenir_sb,
    fontSize: pxToRem(15),
    marginBottom: pxToRem(15),
    marginRight: pxToRem(30),
    marginTop: pxToRem(10),
  },
  continueBtn_disable: {
    width: pxToRem(186),
    height: pxToRem(43),
    borderRadius: pxToRem(27),
    fontSize: pxToRem(20),
    marginBottom: pxToRem(15),
    marginRight: pxToRem(30),
    border: `solid ${pxToRem(2)} ${color.primary_palette.disable_border}`,
    backgroundColor: color.primary_palette.white,
    "& .MuiButton-label": {
      fontSize: pxToRem(20),
      ...tradework_theme.typography.styles.avenir_sb,
    },
  },
  width_block: {
    width: pxToRem(540),
    margin: "auto",
  },
  border_none: {
    borderBottom: `${pxToRem(0)} !important`,
  },
  padding_bottom_23: {
    paddingBottom: pxToRem(23),
  },
  close_mark: {
    fontSize: pxToRem(15),
    paddingTop: pxToRem(11),
    paddingRight: pxToRem(11),
    cursor: "pointer",
  },
  business_cover: {
    width: pxToRem(515),
    // border: `solid ${pxToRem(2)} ${color.gray_white}`,
    display: "block",
    paddingBottom: pxToRem(0),
    position: "relative",
    backgroundColor: "rgba(255,255,255, 0.7)",
  },
  edit_circle: {
    fontSize: pxToRem(30),
    position: " absolute",
    right: pxToRem(6),
    top: pxToRem(6),
    zIndex: 9,
    cursor: "pointer",
  },
  cover_select_field: {
    width: "100%",
    position: "relative",
    "& .MuiFormControl-root": {
      marginTop: pxToRem(20),
      margin: 0,
      width: "99%",
    },
    "& .MuiInput-underline": {
      "&:before": {
        borderBottom: `0 !important`,
      },
    },
    "& .MuiSvgIcon-root": {
      fontSize: pxToRem(22),
      height: pxToRem(11),
      position: "absolute",
      right: pxToRem(13),
    },
    "& .MuiInputBase-input ": {
      fontSize: pxToRem(37),
      ...tradework_theme.typography.styles.millerdisplay_light,
    },
  },
  margin_top_0: {
    marginTop: pxToRem(0),
  },
  labelTxtAlign: {
    position: "relative",
    bottom: pxToRem(5),
  },
  filled_arrow: {
    position: "relative",
    top: pxToRem(4),
    paddingRight: pxToRem(4),
    height: pxToRem(28),
    width: pxToRem(30),
    "& use": {
      fill: color.primary_palette.franklin_purple,
    },
  },
  alignCenter: {
    alignItems: "center",
  },
  blockHeight: {
    height: `${pxToRem(320)} !important`,
    borderBottom: `solid ${pxToRem(1.5)} ${color.border_light_blue}`,
    borderTop: `solid ${pxToRem(1.5)} ${color.border_light_blue}`,
    marginTop: pxToRem(10),
    background: color.primary_palette.white,
    marginBottom: pxToRem(10),
  },
  blockHeightinFr: {
    height: `${pxToRem(370)} !important`,
    borderBottom: `solid ${pxToRem(1.5)} ${color.border_light_blue}`,
    marginBottom: pxToRem(10),
    borderTop: `solid ${pxToRem(1.5)} ${color.border_light_blue}`,
    marginTop: pxToRem(10),
    background: color.primary_palette.white,
  },
  primaryCompanyStyle: {
    marginTop: pxToRem(6),
  },
  listAlign: {
    paddingTop: pxToRem(6),
    cursor: "pointer",
    ...tradework_theme.typography.styles.avenir_sb,
    fontSize: pxToRem(15),
    "& img": {
      paddingLeft: pxToRem(5),
      paddingRight: pxToRem(5),
      height: pxToRem(10),
      width: pxToRem(8),
      position: "relative",
      objectFit: "cover",
    },
  },
  padding_top_6: {
    paddingTop: pxToRem(6),
  },
  descTxtAlign: {
    padding: `${pxToRem(4)} ${pxToRem(0)}`,
  },
  paddingBottom: {
    paddingTop: pxToRem(18),
  },
  txtCenter: {
    textAlign: "center",
  },
  txtCenterEllipses: {
    textAlign: "center",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  height17: {
    height: pxToRem(17),
  },
  flag_block: {
    position: "absolute",
    bottom: 0,
    right: 0,
  },
  downArrowIcon: {
    fontSize: pxToRem(10),
    cursor: "pointer",
  },
  usaFlagStyle: {
    width: pxToRem(20),
    height: pxToRem(15),
    paddingRight: pxToRem(5),
  },
  // nickNameStyle: {
  //   marginTop: pxToRem(36),
  // },
  dropdown_content: {
    backgroundColor: color.primary_palette.white,
    width: pxToRem(74),
    border: `solid ${pxToRem(1)} ${color.notorious_ivory}`,
    cursor: "pointer",
    borderRadius: pxToRem(4),
    fontSize: pxToRem(14),
    padding: `${pxToRem(5)} ${pxToRem(2)} ${pxToRem(5)}  ${pxToRem(2)}`,
    position: "absolute",
    right: pxToRem(-28),
    zIndex: 9,
    "& li": {
      listStyle: "none",
    },
  },
  chkBxAlign: {
    marginLeft: pxToRem(120),
    marginTop: pxToRem(4),
  },
  pos_relative: {
    position: "relative",
  },
  iconStylePencil: {
    backgroundImage: `url("/assets/images/pencil_icon.png")`,
    backgroundSize: "cover",
    width: pxToRem(30),
    height: pxToRem(30),
    display: "inline-block",
    cursor: "pointer",
    "&:hover": {
      backgroundImage: `url("/assets/images/yellow hover.png")`,
      backgroundSize: "cover",
      width: pxToRem(30),
      height: pxToRem(30),
      display: "inline-block",
      cursor: "pointer",
    },
  },
  rightRadio: {
    marginLeft: pxToRem(50),
    "& .MuiButtonBase-root": {
      marginLeft: `${pxToRem(0)} !important`,
    },
  },
  textLeft12: {
    marginLeft: pxToRem(14),
  },
  textLeft15: {
    marginLeft: pxToRem(15),
  },
  regionError: {
    marginLeft: pxToRem(12),
    marginTop: pxToRem(4),
    fontSize: pxToRem(11),
    ...tradework_theme.typography.styles.avenir_black_r,
  },
  infoImageStyle: {
    position: "relative",
    top: pxToRem(3),
  },
  snap_clear: {
    fontSize: pxToRem(7),
    position: "absolute",
    top: pxToRem(34),
    right: pxToRem(4),
    cursor: "pointer",
  },
  nickname_clear: {
    fontSize: pxToRem(7),
    position: "absolute",
    top: pxToRem(30),
    right: pxToRem(80),
    cursor: "pointer",
  },
  cross_mark_icon: {
    fontSize: pxToRem(15),
    padding: pxToRem(16),
    cursor: "pointer",
  },
  width_231: {
    width: pxToRem(231),
  },
  width_309: {
    width: pxToRem(309),
  },
  element_requried: {
    fontSize: pxToRem(13),
    color: color.form_colors.blueberry_purple,
  },
  required_error_nickname: {
    fontSize: pxToRem(13),
    color: color.primary_palette.christmas_red,
  },
  addBtn: {
    width: pxToRem(59),
    height: pxToRem(20),
    padding: 0,
    border: `solid ${pxToRem(1)} ${color.primary_palette.free_purple}`,
    backgroundColor: color.secondary_palette.grays.btn_gray,
    borderRadius: pxToRem(5),
    color: `${color.primary_palette.free_purple} !important`,
    ...tradework_theme.typography.styles.avenir_sb,
    fontSize: pxToRem(15),
  },
  portfolio_items: {
    width: pxToRem(200),
    height: pxToRem(20),
    borderRadius: pxToRem(2),
    marginRight: pxToRem(16),
    marginTop: pxToRem(6),
    marginLeft: pxToRem(16),
    backgroundColor: color.primary_palette.white,
    border: `solid ${pxToRem(1)} ${color.secondary_palette.grays.shadow_gray}`,
    "& .MuiSvgIcon-root": {
      fontSize: pxToRem(14),
    },
    "& p": {
      ...tradework_theme.typography.styles.avenir_sb,
      fontSize: pxToRem(12),
      color: color.primary_palette.franklin_purple,
    },
  },
  lil_filled: {
    fontSize: pxToRem(16),
    verticalAlign: "middle",
    paddingRight: pxToRem(7),
    "& path": {
      "&:nth-child(1)": {
        fill: color.secondary_palette.grays.shadow_gray,
      },
    },
  },
  floatRight: {
    float: "right",
  },
  width_50: {
    width: "50%",
  },
  flex_grow_1: {
    flexGrow: 1,
  },
  companyDescAlign: {
    marginBottom: pxToRem(2),
    marginTop: pxToRem(6),
  },
  BusinessBtnStyle: {
    width: pxToRem(245),
    height: pxToRem(35),
    borderRadius: pxToRem(2),
    backgroundColor: color.primary_palette.white,
    color: color.secondary_palette.grays.shadow_gray,
    border: `solid ${pxToRem(1)} ${color.secondary_palette.grays.shadow_gray}`,
    ...tradework_theme.typography.styles.gillsans_sb,
    fontSize: pxToRem(14),
    alignItems: "center",
    textAlign: "center",
    textTransform: "uppercase",
    marginLeft: pxToRem(16),
    cursor: "pointer",
    display: "grid",
  },
  isInFR: {
    "& .MuiTypography-root": {
      ...tradework_theme.typography.styles.avenir_sb,
      fontSize: pxToRem(14),
      color: color.primary_palette.highlight_purple,
      marginLeft: pxToRem(4),
      textTransform: "uppercase",
      marginTop: pxToRem(3),
    },
    "& .MuiButtonBase-root": {
      paddingTop: 0,
      paddingRight: 0,
      paddingBottom: 0,
    },
    "& .MuiSvgIcon-root": {
      "& path": {
        "&:nth-child(2)": {
          fill: color.pubic_green,
        },
      },
    },
  },
  BusinessBtnActiveStyle: {
    width: pxToRem(245),
    height: pxToRem(35),
    borderRadius: pxToRem(2),
    backgroundColor: color.primary_palette.white,
    color: color.primary_palette.franklin_purple,
    border: `solid ${pxToRem(1)} ${color.primary_palette.franklin_purple}`,
    ...tradework_theme.typography.styles.gillsans_sb,
    fontSize: pxToRem(14),
    alignItems: "center",
    textAlign: "center",
    textTransform: "uppercase",
    marginLeft: pxToRem(16),
    cursor: "pointer",
  },
  btnDivAlign: {
    marginBottom: pxToRem(20),
    // marginTop: pxToRem(20),
    justifyContent: "center",
  },
  width_300: {
    width: pxToRem(300),
  },
  newHelpTxtAlign: {
    width: pxToRem(113),
    height: pxToRem(49),
    borderRadius: pxToRem(4),
    border: `solid ${pxToRem(1)} ${color.primary_palette.black}`,
    background: "rgb(234,234,234,0.6)",
    ...tradework_theme.typography.styles.avenir_sb,
    fontSize: pxToRem(15),
    color: color.primary_palette.franklin_purple,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    cursor: "pointer",
  },
  businessTxtAlign: {
    position: "absolute",
    top: pxToRem(-62),
    right: 0,
  },
  pencil_hover_text: {
    "&:hover": {
      "& .pencil_icon": {
        backgroundImage: `url("/assets/images/yellow hover.png")`,
        backgroundSize: "cover",
        width: pxToRem(30),
        height: pxToRem(30),
        display: "inline-block",
        cursor: "pointer",
      },
    },
  },
  marginTop6: {
    marginTop: pxToRem(6),
  },
  marginLeft4: {
    marginLeft: pxToRem(4),
  },
  errorTxtAlign: {
    margin: "0 auto",
  },
  business_width_office: {
    width: pxToRem(499),
    backgroundColor: color.primary_palette.white,
    border: `solid ${pxToRem(1)} ${color.gray_white}`,
    display: "block",
    paddingBottom: pxToRem(0),
    margin: "auto",
  },
  modal_bussinesscard_new: {
    width: pxToRem(742),
    backgroundColor: color.secondary_palette.blues.modal_blue,
    border: `solid ${pxToRem(2)} ${color.primary_palette.franklin_purple}`,
    padding: pxToRem(6),
    position: "relative",
  },
  free_block_new: {
    padding: `${pxToRem(20)} ${pxToRem(75)}`,
    justifyContent: "center",
  },
  textRight: {
    textAlign: "right",
  },
  cover_card_hover: {
    "&:hover": {
      "& .pencil_icon": {
        backgroundImage: `url("/assets/images/yellow hover.png")`,
        backgroundSize: "cover",
        width: pxToRem(30),
        height: pxToRem(30),
        display: "inline-block",
        cursor: "pointer",
      },
    },
  },
  colorPurple: {
    color: color.primary_palette.highlight_purple,
  },
  customScrollHeightCategory: {
    minHeight: `${pxToRem(330)} !important`,
    "& li": {
      overflow: "hidden",
      whiteSpace: "normal",
      textOverflow: "ellipsis",
    },
  },
  colorPurple: {
    color: color.primary_palette.highlight_purple,
  },
  textCapital: {
    textTransform: "uppercase",
  },
  marginLeft14: {
    marginLeft: pxToRem(14),
  },
  marginRight26: {
    marginRight: pxToRem(26),
  },
  noCompanyText: {
    marginTop: pxToRem(10),
    marginLeft: pxToRem(15),
    marginBottom: pxToRem(10),
    fontWeight: 700,
  },
  textCenter: {
    textAlign: "center",
  },
  websiteTxt: {
    textDecoration: "none !important",
  },
  emailActiveStyle: {
    cursor: "pointer",
    color: color.primary_palette.franklin_purple,
  },
});

export default styles;
