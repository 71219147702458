import { withStyles } from "@material-ui/core/styles";
import tradework_theme, { pxToRem, color } from "../../../utilities/themes";

const styles = withStyles({
  add_project_sections: {
    justifyContent: "center",
    marginTop: pxToRem(80),
  },
  add_project: {
    width: pxToRem(880),
    marginLeft: pxToRem(24),
    marginTop: pxToRem(82),
  },
  sample_image_block: {
    marginTop: pxToRem(82),
  },
  add_project_block: {
    width: pxToRem(798),
    border: `solid ${pxToRem(1)} ${color.primary_palette.franklin_purple}`,
    padding: `${pxToRem(24)} ${pxToRem(24)} ${pxToRem(13)} ${pxToRem(24)}`,
  },
  line: {
    width: pxToRem(277),
    height: pxToRem(1),
    backgroundColor: color.primary_palette.franklin_purple,
    alignSelf: "center",
  },
  project_text: {
    padding: `${pxToRem(0)} ${pxToRem(24)}`,
    width: pxToRem(246),
  },
  textCenter: {
    textAlign: "center",
  },
  marginTop: {
    marginTop: pxToRem(16),
  },
  required_icon: {
    color: color.form_colors.blueberry_purple,
    paddingLeft: pxToRem(2),
    height: pxToRem(17),
    width: pxToRem(17),
  },
  error_red_icon: {
    color: color.primary_palette.christmas_red,
    paddingRight: pxToRem(2),
    height: pxToRem(15),
    width: pxToRem(15),
    position: "relative",
    top: pxToRem(2),
  },
  red_required: {
    color: color.primary_palette.christmas_red,
    paddingLeft: pxToRem(2),
    height: pxToRem(17),
    width: pxToRem(17),
  },
  required: {
    fontSize: pxToRem(14),
    color: color.form_colors.blueberry_purple,
    verticalAlign: "super",
    paddingLeft: pxToRem(2),
  },
  project_title_input: {
    width: "100%",
    borderBottom: `solid ${pxToRem(1)} ${
      color.secondary_palette.grays.shadow_gray
    }`,
    "&:before": {
      borderBottom: `0 !important`,
      display: "none !important",
    },
    "&:focus": {
      backgroundColor: "transparent !important",
    },
    "& .MuiInputBase-input": {
      fontSize: pxToRem(16),
      ...tradework_theme.typography.styles.NeutraText,
      paddingBottom: pxToRem(0),
    },
    "& .MuiInputBase-input::placeholder": {
      // fontStyle: "oblique",
      fontFamily: "NeutraText",
      fontSize: pxToRem(16),
      color: color.secondary_palette.grays.shadow_gray,
      opacity: 1,
    },
  },
  red_project_title_input: {
    width: "100%",
    borderBottom: `solid ${pxToRem(1)} ${color.primary_palette.christmas_red}`,
    "&:before": {
      borderBottom: `0 !important`,
      display: "none !important",
    },
    "&:after": {
      display: "none",
    },
    "&:focus": {
      backgroundColor: "transparent !important",
    },
    "& .MuiInputBase-input": {
      fontSize: pxToRem(15),
      ...tradework_theme.typography.styles.NeutraText,
    },
    "& .MuiInputBase-input::placeholder": {
      // fontStyle: "oblique",
      fontFamily: "NeutraText",
      fontSize: pxToRem(16),
      color: color.secondary_palette.grays.shadow_gray,
      opacity: 1,
    },
  },
  positionRelative: {
    position: "relative",
  },
  placeholder1Align: {
    position: "absolute",
    top: pxToRem(4),
    right: pxToRem(0),
    width: pxToRem(390),
  },
  upperCase: {
    "& .MuiInputBase-input": {
      textTransform: "uppercase",
      fontSize: pxToRem(22),
    },
  },
  project_subtitle: {
    flexGrow: 1,
    width: "24%",
  },
  project_subtitle_spacing: {
    paddingRight: pxToRem(20),
  },
  subtile_spacing: {
    paddingTop: pxToRem(17),
  },
  textArea: {
    height: pxToRem(142),
    "& .ql-editor": {
      ...tradework_theme.typography.styles.NeutraText,
      fontSize: pxToRem(16),
    },
    "& .ql-picker": {
      display: "none !important",
    },
    "& .ql-link": {
      display: "none !important",
    },
    "& .ql-clean": {
      display: "none !important",
    },
    "& .ql-container": {
      height: pxToRem(110),
    },
  },
  spacing_17: {
    paddingTop: pxToRem(17),
  },
  dropdownWidth: {
    flexGrow: 1,
    border: 0,
    width: pxToRem(318),
    padding: 0,
    borderBottom: `solid ${pxToRem(1)} ${
      color.secondary_palette.grays.shadow_gray
    }`,
    "& p": {
      fontSize: pxToRem(14),
      color: color.primary_palette.black,
      ...tradework_theme.typography.styles.avenir_light,
      position: "relative",
      top: pxToRem(4),
    },
    "& .MuiSvgIcon-root": {
      fontSize: pxToRem(10),
      position: "relative",
      top: pxToRem(4),
    },
  },
  dropdownWidthSub: {
    flexGrow: 1,
    border: 0,
    width: pxToRem(288),
    padding: 0,
    borderBottom: `solid ${pxToRem(1)} ${
      color.secondary_palette.grays.shadow_gray
    }`,
    "& p": {
      fontSize: pxToRem(14),
      color: color.primary_palette.black,
      ...tradework_theme.typography.styles.avenir_light,
      position: "relative",
      top: pxToRem(4),
    },
    "& .MuiSvgIcon-root": {
      fontSize: pxToRem(10),
      position: "relative",
      top: pxToRem(4),
    },
  },
  customScrollHeight: {
    minHeight: `${pxToRem(328)} !important`,
    "& li": {
      overflow: "hidden",
      whiteSpace: "normal",
      textOverflow: "ellipsis",
    },
  },
  marginLeft30: {
    marginLeft: pxToRem(12),
    paddingTop: pxToRem(7),
  },
  paddingDropdown: {
    paddingRight: pxToRem(0),
  },
  field: {
    width: "85%",
  },
  field1: {
    width: "85%",
  },
  hashtags: {
    paddingTop: pxToRem(11),
  },
  justifyEnd: {
    justifyContent: "flex-end",
  },
  justifyCenter: {
    justifyContent: "center",
  },
  add_project_space: {
    paddingTop: pxToRem(5),
  },
  coverRadio: {
    justifyContent: "center",
    "& .MuiTypography-root": {
      ...tradework_theme.typography.styles.avenir_bold,
      color: color.primary_palette.franklin_purple,
      fontSize: pxToRem(15),
    },
  },
  draft_btn: {
    width: pxToRem(170),
    height: pxToRem(35),
    marginRight: pxToRem(20),
    borderRadius: pxToRem(22),
    border: `solid ${pxToRem(1.6)} ${color.primary_palette.franklin_purple}`,
    backgroundColor: color.primary_palette.white,
    "& .MuiButton-label": {
      color: color.primary_palette.franklin_purple,
      fontSize: pxToRem(16.3),
      ...tradework_theme.typography.styles.avenir_bold,
    },
  },
  back_btn: {
    width: pxToRem(128),
    height: pxToRem(35),
    marginRight: pxToRem(20),
    borderRadius: pxToRem(22),
    border: `solid ${pxToRem(1.6)} ${color.primary_palette.franklin_purple}`,
    backgroundColor: color.primary_palette.white,
    "& .MuiButton-label": {
      color: color.primary_palette.franklin_purple,
      fontSize: pxToRem(16.3),
      ...tradework_theme.typography.styles.avenir_bold,
    },
  },
  preview_btn: {
    height: pxToRem(22),
    width: pxToRem(157),
    marginRight: pxToRem(20),
    borderRadius: pxToRem(4),
    border: `solid ${pxToRem(1.6)} ${color.primary_palette.franklin_purple}`,
    backgroundColor: color.primary_palette.white,
    position: "absolute",
    right: pxToRem(20),
    top: pxToRem(140),
    "& .MuiButton-label": {
      color: color.primary_palette.black,
      fontSize: pxToRem(15),
      ...tradework_theme.typography.styles.avenir_sb,
      textTransform: "capitalize",
    },
  },
  preview_btn_entire: {
    height: pxToRem(22),
    width: pxToRem(181),
    marginRight: pxToRem(20),
    borderRadius: pxToRem(4),
    border: `solid ${pxToRem(1.6)} ${color.primary_palette.franklin_purple}`,
    backgroundColor: color.primary_palette.white,
    position: "absolute",
    right: pxToRem(20),
    top: pxToRem(110),
    "& .MuiButton-label": {
      color: color.primary_palette.black,
      fontSize: pxToRem(15),
      ...tradework_theme.typography.styles.avenir_sb,
      textTransform: "capitalize",
    },
  },
  back_btn_disable: {
    width: pxToRem(138),
    height: pxToRem(35),
    marginRight: pxToRem(20),
    borderRadius: pxToRem(22),
    backgroundColor: color.primary_palette.white,
    border: `solid ${pxToRem(2)} ${color.primary_palette.disable_border}`,
    "& .MuiButton-label": {
      ...tradework_theme.typography.styles.avenir_bold,
      fontSize: pxToRem(15),
    },
  },
  save_btn: {
    width: pxToRem(208),
    height: pxToRem(35),
    borderRadius: pxToRem(22),
    border: `solid ${pxToRem(1.6)} ${color.primary_palette.franklin_purple}`,
    backgroundColor: color.primary_palette.white,
    marginRight: pxToRem(13),
    "& .MuiButton-label": {
      color: color.primary_palette.franklin_purple,
      fontSize: pxToRem(16.3),
      ...tradework_theme.typography.styles.avenir_bold,
    },
  },
  save_btn_disable: {
    width: pxToRem(208),
    height: pxToRem(35),
    borderRadius: pxToRem(22),
    backgroundColor: color.primary_palette.white,
    border: `solid ${pxToRem(2)} ${color.primary_palette.disable_border}`,
    "& .MuiButton-label": {
      ...tradework_theme.typography.styles.avenir_bold,
      fontSize: pxToRem(15),
    },
  },
  edit_save_btn: {
    width: pxToRem(132),
    height: pxToRem(35),
    borderRadius: pxToRem(22),
    border: `solid ${pxToRem(1.6)} ${color.primary_palette.franklin_purple}`,
    backgroundColor: color.primary_palette.white,
    marginRight: pxToRem(13),
    "& .MuiButton-label": {
      color: color.primary_palette.franklin_purple,
      fontSize: pxToRem(16.3),
      ...tradework_theme.typography.styles.avenir_bold,
    },
  },
  edit_save_btn_disable: {
    width: pxToRem(132),
    height: pxToRem(35),
    borderRadius: pxToRem(22),
    backgroundColor: color.primary_palette.white,
    border: `solid ${pxToRem(2)} ${color.primary_palette.disable_border}`,
    "& .MuiButton-label": {
      ...tradework_theme.typography.styles.avenir_bold,
      fontSize: pxToRem(15),
    },
  },
  btnAlign: {
    paddingTop: pxToRem(49),
    paddingBottom: pxToRem(25),
  },
  flex_grow: {
    flexGrow: 1,
  },
  margin_left: {
    marginLeft: pxToRem(110),
  },
  padding_top_13: {
    paddingTop: pxToRem(13),
  },
  padding_top_33: {
    paddingTop: pxToRem(33),
  },
  add_next: { cursor: "pointer" },
  add_next_icon: {
    fontSize: pxToRem(15),
    backgroundColor: color.secondary_palette.grays.background_gray,
    marginRight: pxToRem(5),
    "& path": {
      "&:nth-child(1)": {
        fill: color.secondary_palette.grays.background_gray,
      },
    },
  },
  inputContainer: {
    marginBottom: pxToRem(-10),
    marginTop: pxToRem(10),
    height: pxToRem(26),
  },
  inputField: {
    width: "100%",
    border: "none",
    paddingLeft: pxToRem(8),
    borderTop: `${pxToRem(1)} solid`,
    height: pxToRem(22),

    "&::placeholder": {
      color: color.primary_palette.highlight_purple,
      fontSize: pxToRem(12),
      ...tradework_theme.typography.styles.NeutraText,
      // fontStyle: "oblique !important",
      opacity: 1,
    },
    "&:focus": {
      outline: "none",
    },
  },
  dropdown_margin: {
    flexWrap: "wrap",
  },
  plus: {
    fontSize: pxToRem(20),
    color: color.primary_palette.highlight_purple,
    cursor: "pointer",
  },
  crossIconStyle: {
    marginLeft: pxToRem(6),
    marginRight: pxToRem(6),
    cursor: "pointer",
    width: pxToRem(13),
    height: pxToRem(15),
    position: "relative",
    top: pxToRem(4),
  },
  listAlign: {
    fontSize: pxToRem(14),
    ...tradework_theme.typography.styles.avenir_light,
  },
  countAlign_negative: {
    color: color.primary_palette.christmas_red,
    marginTop: pxToRem(12),
    textAlign: "right",
  },
  countAlign_warning: {
    color: color.accent_colors_and_one_offs.links_orange,
    marginTop: pxToRem(12),
    textAlign: "right",
  },
  countAlign: {
    marginTop: pxToRem(12),
    textAlign: "right",
    fontSize: pxToRem(15),
    color: color.secondary_palette.grays.shadow_gray,
  },
  space_padding: {
    paddingTop: pxToRem(18),
    paddingBottom: pxToRem(8),
  },
  space_padding_edit: {
    paddingTop: pxToRem(7),
  },
  line_spacing: {
    paddingBottom: pxToRem(20),
  },
  line_spacing_sub: {
    paddingTop: pxToRem(23),
  },
  edit_line: {
    width: pxToRem(277),
    height: pxToRem(1),
    backgroundColor: color.primary_palette.franklin_purple,
    alignSelf: "center",
  },
  inlineBlock: {
    display: "inline-block",
  },
  edit_project: {
    paddingTop: pxToRem(23),
  },
  error: {
    color: color.primary_palette.christmas_red,
    fontSize: pxToRem(15),
    ...tradework_theme.typography.styles.avenir_black_r,
  },
  errorDiv: {
    color: color.primary_palette.christmas_red,
    textAlign: "right",
    marginBottom: pxToRem(-34),
    marginRight: pxToRem(34),
  },
  errorDivLeft: {
    color: color.primary_palette.christmas_red,
    textAlign: "right",
    marginRight: pxToRem(34),
  },
  edit_icon: {
    fontSize: pxToRem(60),
    position: "absolute",
    top: pxToRem(158),
    left: "60%",
  },
  rectangle: {
    width: pxToRem(266),
    height: pxToRem(157),
    border: `solid ${pxToRem(2)} ${color.primary_palette.franklin_purple}`,
    margin: `${pxToRem(5)} ${pxToRem(0)}`,
    backgroundImage: `url("/assets/images/Country House Hover.jpg")`,
    backgroundSize: "cover",
    cursor: "pointer",
    "& ellipse": {
      fill: color.green_world,
    },
    "&:hover": {
      backgroundImage: `url("/assets/images/Country House BW.png")`,
      backgroundSize: "cover",
      "& .addHover_effect": {
        "& ellipse": {
          fill: color.secondary_palette.grays.background_gray,
        },
      },
    },
    "& svg": {
      width: `${pxToRem(96)} !important`,
      height: `${pxToRem(99)} !important`,
    },
  },
  ballpark_cost: {
    width: "30%",
  },
  dropdown_selection_width: {
    width: "70%",
  },
  required_cover: {
    height: pxToRem(17),
    width: pxToRem(17),
    marginRight: pxToRem(6),
    color: color.form_colors.blueberry_purple,
  },
  required_cover_error: {
    height: pxToRem(17),
    width: pxToRem(17),
    marginRight: pxToRem(6),
    color: color.primary_palette.christmas_red,
  },
  neutra_text: {
    ...tradework_theme.typography.styles.NeutraText_bold,
    position: "relative",
  },
  step1_des_spacing: {
    paddingLeft: pxToRem(6),
    paddingBottom: pxToRem(20),
  },
  num: {
    position: "relative",
    display: "block",
    textAlign: "right",
  },
  choose_number: {
    width: pxToRem(84),
    borderRadius: pxToRem(2),
    marginLeft: pxToRem(17),
    marginTop: pxToRem(11),
    border: `solid ${pxToRem(1)} ${color.secondary_palette.grays.shadow_gray}`,
    "& .MuiSelect-select.MuiSelect-select": {
      paddingTop: pxToRem(2),
      paddingBottom: pxToRem(3),
      paddingRight: `${pxToRem(15)} !important`,
      paddingLeft: pxToRem(10),
      "&:focus": {
        backgroundColor: "transparent",
      },
    },
    "& p": {
      ...tradework_theme.typography.styles.avenir_roman,
      fontSize: pxToRem(16),
    },
    "& .MuiSvgIcon-root": {
      fontSize: pxToRem(10),
      top: pxToRem(6),
      right: pxToRem(7),
    },
  },
  step2_des_spacing: {
    paddingLeft: pxToRem(3),
    paddingBottom: pxToRem(35),
  },
  grid_spacing: {
    paddingTop: pxToRem(75),
  },
  grid_display: {
    marginTop: pxToRem(30),
  },
  grid_text: {
    width: "8%",
  },
  gird_block: {
    border: `solid ${pxToRem(1)} ${color.secondary_palette.grays.shadow_gray}`,
    paddingTop: pxToRem(7),
    paddingBottom: pxToRem(3),
  },
  grid_button: {
    width: pxToRem(204),
    height: pxToRem(43),
    borderRadius: pxToRem(27),
    border: `solid ${pxToRem(2)} ${color.primary_palette.franklin_purple}`,
    backgroundColor: color.primary_palette.white,
    color: `${color.primary_palette.franklin_purple} !important`,
    ...tradework_theme.typography.styles.avenir_bold,
    fontSize: pxToRem(20),
    marginTop: pxToRem(30),
  },
  color_purple: {
    color: color.primary_palette.franklin_purple,
  },
  optimal_font: {
    ...tradework_theme.typography.styles.OptimaLT,
    fontWeight: "bold",
  },
  gridstyle1_color: {
    backgroundColor: "#9c7fb0",
    width: "100%",
  },
  gridstyle2_color: {
    backgroundColor: "#69986c",
  },
  gridstyle1_colordiv: {
    textAlign: "center",
    width: "100%",
    marginTop: pxToRem(16),
    marginBottom: pxToRem(16),
  },
  gridstyle2_colordiv: {
    textAlign: "center",
    width: "135px",
    marginLeft: pxToRem(38),
    marginRight: pxToRem(40),
    "& ellipse": {
      fill: "#69986c",
    },
  },
  gridstyle3_color: {
    backgroundColor: "#81d8d0",
  },
  gridstyle3_colordiv: {
    textAlign: "center",
    width: "88px",
    margin: "0 auto",
    "& ellipse": {
      fill: "#81d8d0",
    },
  },
  gridstyle4_color: {
    backgroundColor: "#ffe98a",
    width: "100%",
  },
  gridstyle4_colordiv: {
    textAlign: "center",
    margin: "30px 0px",
    width: "100%",
    "& ellipse": {
      fill: "#ffe98a",
    },
  },
  gridstyle5_color: {
    backgroundColor: "#b1b1b1",
    width: "100%",
  },
  gridstyle5_colordiv: {
    textAlign: "center",
    margin: "21px 0px",
    width: "100%",
    "& ellipse": {
      fill: "#b1b1b1",
    },
  },
  gridstyle8_color: {
    backgroundColor: "#9c7fb0",
    width: "100%",
  },
  gridstyle8_colordiv: {
    textAlign: "center",
    margin: "21px 0px",
    width: "100%",
    "& ellipse": {
      fill: "#9c7fb0",
    },
  },
  gridstyle6_color: {
    backgroundColor: "#ef6b74",
  },
  gridstyle6_colordiv: {
    textAlign: "center",
    width: "104px",
    margin: "0 auto",
    "& ellipse": {
      fill: "#ef6b74",
    },
  },
  gridstyle7_color: {
    backgroundColor: "#4e73b4",
    width: "100%",
  },
  gridstyle7_colordiv: {
    // position: "relative",
    textAlign: "center",
    width: "100%",
    "& ellipse": {
      fill: "#4e73b4",
    },
  },
  gridstyle9_color: {
    backgroundColor: "#c6dcff",
    width: "100%",
  },
  gridstyle9_colordiv: {
    // position: "relative",
    textAlign: "center",
    width: "100%",
    "& ellipse": {
      fill: "#c6dcff",
    },
  },
  gridstyle1_icon: { marginTop: pxToRem(20) },
  gridstyle2_icon: { marginTop: pxToRem(35) },
  gridstyle3_icon: { marginTop: pxToRem(35) },
  gridstyle4_icon: { marginTop: pxToRem(5) },
  gridstyle5_icon: { marginTop: pxToRem(15) },
  gridstyle6_icon: { marginTop: pxToRem(35) },
  gridstyle7_icon: { marginTop: pxToRem(35) },
  rectangle_place_1: {
    width: pxToRem(213),
    height: pxToRem(142),
    objectFit: "cover",
    verticalAlign: "middle",
  },
  rectangle_place: {
    width: pxToRem(95),
    height: pxToRem(142),
    verticalAlign: "middle",
  },
  rectangle_place_95_130: {
    width: pxToRem(95),
    height: pxToRem(130),
    verticalAlign: "middle",
  },
  small_square: {
    width: pxToRem(95),
    height: pxToRem(110),
    verticalAlign: "middle",
  },
  small_square_128: {
    width: pxToRem(128),
    height: pxToRem(110),
    verticalAlign: "middle",
  },
  rectangle_place_129: {
    width: pxToRem(129),
    height: pxToRem(142),
    verticalAlign: "middle",
  },
  mini_square: {
    width: pxToRem(95),
    height: pxToRem(95),
    verticalAlign: "middle",
  },
  small_rect: {
    width: pxToRem(98),
    height: pxToRem(95),
    verticalAlign: "middle",
  },
  small_rect_97_103: {
    width: pxToRem(97),
    height: pxToRem(103),
    verticalAlign: "middle",
  },
  large_square: {
    width: pxToRem(110),
    height: pxToRem(126),
    verticalAlign: "middle",
  },
  square_131: {
    width: pxToRem(131),
    height: pxToRem(140),
    verticalAlign: "middle",
  },
  rectangle_horiz: {
    width: pxToRem(131),
    height: pxToRem(62),
    verticalAlign: "middle",
  },
  rectangle_horiz_103: {
    width: pxToRem(97),
    height: pxToRem(103),
    verticalAlign: "middle",
  },
  small_square_95_62: {
    width: pxToRem(95),
    height: pxToRem(62),
    verticalAlign: "middle",
  },
  small_square_58_62: {
    width: pxToRem(58),
    height: pxToRem(62),
    verticalAlign: "middle",
  },
  rectangle_horiz_76: {
    width: pxToRem(97),
    height: pxToRem(76),
    verticalAlign: "middle",
  },
  small_square_71_75: {
    width: pxToRem(71),
    height: pxToRem(75),
    verticalAlign: "middle",
  },
  placeholder_square: {
    height: pxToRem(140),
    width: pxToRem(120),
    verticalAlign: "middle",
  },
  square_131_139: {
    height: pxToRem(131),
    width: pxToRem(139),
    verticalAlign: "middle",
  },
  square_128_100: {
    height: pxToRem(128),
    width: pxToRem(100),
    marginTop: pxToRem(22),
    verticalAlign: "middle",
  },
  mini_square_95_75: {
    width: pxToRem(95),
    height: pxToRem(75),
    verticalAlign: "middle",
  },
  borderAlign: {
    border: `${pxToRem(1)} solid`,
  },
  iconPlusStyle: {
    backgroundImage: `url("/assets/icons/Purple Plus 26x26.svg")`,
    backgroundSize: "cover",
    width: pxToRem(26),
    height: pxToRem(26),
    display: "inline-block",
    cursor: "pointer",
    "&:hover": {
      backgroundImage: `url("/assets/icons/Green Plus 26x26.svg")`,
      backgroundSize: "cover",
      width: pxToRem(26),
      height: pxToRem(26),
      display: "inline-block",
      cursor: "pointer",
    },
  },
  custom_ballparkCost: {
    minHeight: `${pxToRem(265)} !important`,
    "& li": {
      fontSize: pxToRem(15),
      ...tradework_theme.typography.styles.avenir_book_r,
      overflow: "hidden",
      whiteSpace: "normal",
      textOverflow: "ellipsis",
    },
  },
  chooseNumber: {
    minHeight: `${pxToRem(262)} !important`,
    "& li": {
      fontSize: pxToRem(15),
      ...tradework_theme.typography.styles.avenir_book_r,
      overflow: "hidden",
      whiteSpace: "normal",
      textOverflow: "ellipsis",
    },
  },
  settings_vibes: {
    "& li": {
      fontSize: pxToRem(15),
      ...tradework_theme.typography.styles.avenir_book_r,
    },
  },
  clientele: {
    "& li": {
      fontSize: pxToRem(15),
      ...tradework_theme.typography.styles.avenir_book_r,
    },
  },
  styles_dropdown: {
    "& li": {
      fontSize: pxToRem(15),
      ...tradework_theme.typography.styles.avenir_book_r,
    },
  },
  leftDraftBtnAlign: {
    textAlign: "center",
    marginTop: pxToRem(30),
  },
  editModeBlock: {
    width: pxToRem(174),
  },
  editBlockStyle: {
    textAlign: "center",
    borderBottom: `solid ${pxToRem(0.5)} ${color.primary_palette.black}`,
  },
  custom_edit_btn_styles: {
    height: pxToRem(22),
    width: pxToRem(83),
    borderRadius: pxToRem(4),
    border: `solid ${pxToRem(1.6)} ${color.primary_palette.black}`,
    backgroundColor: color.primary_palette.white,
    marginTop: pxToRem(5),
    marginBottom: pxToRem(10),
    "& .MuiButton-label": {
      color: color.primary_palette.black,
      fontSize: pxToRem(12),
      ...tradework_theme.typography.styles.avenir_sb,
      textTransform: "capitalize",
    },
    "&:hover": {
      border: `solid ${pxToRem(1.6)} ${color.button_yellow}`,
      backgroundColor: `${color.primary_palette.white} !important`,
    },
  },
  custom_public_btn_styles: {
    height: pxToRem(22),
    width: pxToRem(83),
    borderRadius: pxToRem(4),
    border: `solid ${pxToRem(1.6)} ${color.primary_palette.black}`,
    backgroundColor: color.primary_palette.white,
    marginTop: pxToRem(5),
    marginBottom: pxToRem(10),
    "& .MuiButton-label": {
      color: color.primary_palette.black,
      fontSize: pxToRem(12),
      ...tradework_theme.typography.styles.avenir_sb,
      textTransform: "capitalize",
    },
    "&:hover": {
      border: `solid ${pxToRem(1.6)} ${color.primary_palette.highlight_purple}`,
      backgroundColor: `${color.primary_palette.white} !important`,
    },
  },
  custom_public_btn_styles_center: {
    height: pxToRem(22),
    width: pxToRem(83),
    borderRadius: pxToRem(4),
    border: `solid ${pxToRem(1.6)} ${color.primary_palette.black}`,
    backgroundColor: color.primary_palette.white,
    margin: `${pxToRem(5)} auto`,
    marginBottom: pxToRem(10),
    "& .MuiButton-label": {
      color: color.primary_palette.black,
      fontSize: pxToRem(12),
      ...tradework_theme.typography.styles.avenir_sb,
      textTransform: "capitalize",
    },
    "&:hover": {
      border: `solid ${pxToRem(1.6)} ${color.primary_palette.highlight_purple}`,
      backgroundColor: `${color.primary_palette.white} !important`,
    },
  },
  current_btn_style: {
    height: pxToRem(22),
    width: pxToRem(83),
    borderRadius: pxToRem(4),
    border: `solid ${pxToRem(1.6)} ${color.primary_palette.black}`,
    backgroundColor: color.button_yellow,
    marginTop: pxToRem(5),
    marginBottom: pxToRem(10),
    "& .MuiButton-label": {
      color: color.primary_palette.black,
      fontSize: pxToRem(12),
      ...tradework_theme.typography.styles.avenir_sb,
      textTransform: "capitalize",
    },
    "&:hover": {
      border: `solid ${pxToRem(1.6)} ${color.button_yellow}`,
      backgroundColor: `${color.button_yellow} !important`,
    },
  },
  marginRight7: {
    marginRight: pxToRem(7),
  },
  publicAlign: {
    marginLeft: "auto",
    marginRight: pxToRem(32),
  },
  choose_number_error: {
    width: pxToRem(84),
    borderRadius: pxToRem(2),
    marginLeft: pxToRem(17),
    marginTop: pxToRem(11),
    border: `solid ${pxToRem(1)} ${color.primary_palette.christmas_red}`,
    "& .MuiSelect-select.MuiSelect-select": {
      paddingTop: pxToRem(2),
      paddingBottom: pxToRem(3),
      paddingRight: `${pxToRem(15)} !important`,
      paddingLeft: pxToRem(10),
      "&:focus": {
        backgroundColor: "transparent",
      },
    },
    "& p": {
      ...tradework_theme.typography.styles.avenir_roman,
      fontSize: pxToRem(16),
    },
    "& .MuiSvgIcon-root": {
      fontSize: pxToRem(10),
      top: pxToRem(6),
      right: pxToRem(7),
    },
  },
  textCapital: {
    textTransform: "uppercase",
  },
  marginLeft4: {
    marginLeft: pxToRem(4),
  },
  leftGraphicArrow: {
    marginLeft: pxToRem(10),
    marginBottom: pxToRem(14),
  },
  fixedBlock: {
    position: "sticky",
    top: pxToRem(100),
  },
  gridTextStyle: {
    background: color.primary_palette.black,
    borderRadius: "50%",
    width: pxToRem(22),
    textAlign: "center",
    color: color.primary_palette.white,
    // marginLeft: pxToRem(3),
    // marginBottom: pxToRem(3),
  },
  hoverIconBlock: {
    "& .hover_icon": {
      cursor: "pointer",
    },
    "&:hover": {
      "& .hover_icon": {
        "& path": {
          fill: color.form_colors.sucess_color,
        },
      },
    },
  },
  displayProjectTxtBlock: {
    marginTop: pxToRem(100),
    marginLeft: pxToRem(120),
    display: "inline-block",
  },
  betaStampIcon: {
    height: pxToRem(100),
    position: "absolute",
    right: pxToRem(188),
    cursor: "pointer",
    top: pxToRem(100),
  },
  displayInline: {
    display: "inline-block",
  },
});

export default styles;
