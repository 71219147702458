import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core";
import tradework_theme, { color, pxToRem } from "../../../utilities/themes";

const useStyles = makeStyles({
  section_title: {
    ...tradework_theme.typography.styles.gillsans_sb,
    fontSize: pxToRem(22),
    color: color.form_colors.royal_purple_1,
    marginTop: pxToRem(18),
  },
  title_with_hr: {
    marginTop: pxToRem(29),
    marginBottom: pxToRem(14),
    borderBottom: `solid 1px ${color.cape_hope}`,
    cursor: "pointer",
    textTransform: "uppercase",
  },
});

function SectionHeader({
  style,
  className,
  horizontal_line,
  onClick,
  children,
}) {
  const classes = useStyles();
  return (
    <p
      className={`${classes.section_title} ${
        horizontal_line && classes.title_with_hr
      } ${className}`}
      style={style}
      onClick={onClick}
    >
      {children}
    </p>
  );
}

SectionHeader.propTypes = {
  style: PropTypes.object,
  className: PropTypes.string,
  horizontal_line: PropTypes.bool,
  onClick: PropTypes.func,
  children: PropTypes.arrayOf(PropTypes.element),
};

SectionHeader.defaultProps = {
  horizontal_line: true,
};

export default SectionHeader;
