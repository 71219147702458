import { withStyles } from "@material-ui/core/styles";
import tradework_theme, { pxToRem, color } from "../../../utilities/themes";

const styles = withStyles({
  link_text: {
    textTransform: "uppercase",
    color: color.primary_palette.black,
    ...tradework_theme.typography.styles.gillsans_r,
    fontSize: pxToRem(16),
    cursor: "pointer",
    "&:hover": {
      textDecoration: "none",
      color: color.primary_palette.highlight_purple,
    },
  },
  portfolioNameHover: {
    "&:hover": {
      color: color.portfolio_hover,
    },
  },
  active_link_text: {
    textTransform: "uppercase",
    color: color.primary_palette.highlight_purple,
    ...tradework_theme.typography.styles.gillsans_r,
    fontSize: pxToRem(16),
    textDecoration: "none",
  },
  soflo: {
    backgroundImage: `url("/assets/images/Pay & Publish Soflo Initial.png")`,
    content: `url('data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7')`,
    backgroundSize: "cover",
    cursor: "pointer",
    width: pxToRem(130),
    "&:hover": {
      backgroundImage: `url("/assets/images/Pay & Publish Soflo Hover.png")`,
      backgroundSize: "cover",
      width: pxToRem(200),
    },
  },
  payPublish: {
    backgroundImage: `url("/assets/images/Pay & Publish Initial.png")`,
    backgroundSize: "cover",
    content: `url('data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7')`,
    cursor: "pointer",
    width: pxToRem(130),
    "&:hover": {
      backgroundImage: `url("/assets/images/Pay & Publish Hover.png")`,
      backgroundSize: "cover",
      width: pxToRem(200),
    },
  },
  nycPublish: {
    backgroundImage: `url("/assets/images/Pay & Publish NYC Initial.png")`,
    backgroundSize: "cover",
    content: `url('data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7')`,
    cursor: "pointer",
    width: pxToRem(130),
    "&:hover": {
      backgroundImage: `url("/assets/images/Pay & Publish NYC Hover.png")`,
      backgroundSize: "cover",
      width: pxToRem(200),
    },
  },
  losAngels: {
    backgroundImage: `url("/assets/images/Pay & Publish LA Initial.png")`,
    backgroundSize: "cover",
    content: `url('data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7')`,
    cursor: "pointer",
    width: pxToRem(130),
    "&:hover": {
      backgroundImage: `url("/assets/images/Pay & Publish LA Hover.png")`,
      backgroundSize: "cover",
      width: pxToRem(200),
    },
  },
  ctWest: {
    backgroundImage: `url("/assets/images/Pay & Publish CT Initial.png")`,
    backgroundSize: "cover",
    cursor: "pointer",
    content: `url('data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7')`,
    width: pxToRem(130),
    "&:hover": {
      backgroundImage: `url("/assets/images/Pay & Publish CT Initial.png")`,
      backgroundSize: "cover",
      width: pxToRem(200),
    },
  },
  chicago: {
    backgroundImage: `url("/assets/images/Pay & Publish Chicago Initial.png")`,
    backgroundSize: "cover",
    cursor: "pointer",
    width: pxToRem(130),
    content: `url('data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7')`,
    "&:hover": {
      backgroundImage: `url("/assets/images/Pay & Publish Chicago Hover.png")`,
      backgroundSize: "cover",
      width: pxToRem(200),
    },
  },
  santaBarbarar: {
    backgroundImage: `url("/assets/images/Pay & Publish SB Initial.png")`,
    backgroundSize: "cover",
    cursor: "pointer",
    width: pxToRem(130),
    content: `url('data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7')`,
    "&:hover": {
      backgroundImage: `url("/assets/images/Pay & Publish SB Hover.png")`,
      backgroundSize: "cover",
      width: pxToRem(200),
    },
  },
  another: {
    backgroundImage: `url("/assets/images/Pay & Publish Another Initial.png")`,
    backgroundSize: "cover",
    cursor: "pointer",
    width: pxToRem(130),
    content: `url('data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7')`,
    "&:hover": {
      backgroundImage: `url("/assets/images/Pay & Publish Another Hover.png")`,
      backgroundSize: "cover",
      width: pxToRem(200),
    },
  },
  addPortfolioRibbon: {
    backgroundImage: `url("/assets/images/Pay & Publish Initial.png")`,
    content: `url('data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7')`,
    backgroundSize: "cover",
    cursor: "pointer",
    width: pxToRem(130),
    "&:hover": {
      backgroundImage: `url("/assets/images/Pay & Publish Hover.png")`,
      backgroundSize: "cover",
      width: pxToRem(130),
    },
  },
  paddingRight0: {
    paddingRight: `${pxToRem(4)} !important`,
  },
  alignCenter: {
    alignItems: "center",
    width: pxToRem(105),
    justifyContent: "center",
    textAlign: "center",
    padding: `${pxToRem(0)} ${pxToRem(23)}`,
    cursor: "pointer",
    "& .MuiLink-underlineHover": {
      "&:hover": {
        textDecoration: "none",
      },
    },
  },
  alignCenterNew: {
    alignItems: "center",
    width: pxToRem(200),
    justifyContent: "center",
    textAlign: "center",
    padding: `${pxToRem(0)} ${pxToRem(23)}`,
    cursor: "pointer",
    "& .MuiLink-underlineHover": {
      "&:hover": {
        textDecoration: "none",
      },
    },
  },
  alignCenterRibbon: {
    alignItems: "center",
    width: pxToRem(105),
    justifyContent: "center",
    textAlign: "center",
    padding: `${pxToRem(0)}`,
  },
  alignCenterIcon: {
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    padding: `${pxToRem(0)}`,
    // width: pxToRem(200),
  },
  ribbonAlign: {
    height: pxToRem(50),
  },
  ribbonNewAlign: {
    height: pxToRem(36),
  },
  divider: {
    width: pxToRem(1),
    height: pxToRem(30),
    backgroundColor: color.primary_palette.black,
    marginTop: pxToRem(6),
    margin: `${pxToRem(0)} ${pxToRem(23)}`,
  },
  user_blk: {
    width: "100%",
    justifyContent: "flex-end",
    padding: `${pxToRem(0)} ${pxToRem(10)}`,
  },
  userName: {
    alignItems: "center",
    display: "inline-table",
    // width: pxToRem(65),
    textAlign: "center",
    textTransform: "uppercase",
    paddingRight: pxToRem(7),
  },
  loginBtns: {
    alignItems: "center",
    display: "flex",
    width: pxToRem(50),
    textAlign: "center",
    textTransform: "uppercase",
    cursor: "pointer",
  },
  centerLine: {
    alignItems: "center",
    display: "flex",
    textAlign: "center",
    textTransform: "uppercase",
    paddingRight: pxToRem(7),
    paddingLeft: pxToRem(7),
  },
  arrow: {
    fontSize: pxToRem(14),
  },
  search: {
    paddingRight: pxToRem(30),
    width: pxToRem(23),
    height: pxToRem(23),
    marginTop: pxToRem(17),
    cursor: "pointer",
    position: "absolute",
    right: pxToRem(120),
    bottom: 0,
  },
  tw_logo_icon: {
    paddingRight: pxToRem(30),
    width: pxToRem(23),
    height: pxToRem(23),
    marginTop: pxToRem(17),
    cursor: "pointer",
    position: "absolute",
    right: pxToRem(90),
    bottom: 0,
  },
  flipbook_icon: {
    paddingRight: pxToRem(30),
    width: pxToRem(23),
    height: pxToRem(23),
    marginTop: pxToRem(17),
    cursor: "pointer",
    position: "absolute",
    right: pxToRem(64),
    bottom: 0,
  },
  profieImage: {
    width: pxToRem(70),
    height: pxToRem(70),
    marginTop: pxToRem(22),
    cursor: "pointer",
    position: "fixed",
  },
  header_border: {
    borderBottom: `solid ${pxToRem(2)} ${
      color.secondary_palette.grays.header_border
    }`,
    padding: `${pxToRem(10)} ${pxToRem(13)}`,
    flexDirection: "row",
    backgroundColor: "rgba(255, 255, 255, 0.85)",
    position: "fixed",
    top: 0,
    width: "99%",
    zIndex: "999",
  },
  coloredBorder: {
    borderBottom: `solid ${pxToRem(2)} ${
      color.secondary_palette.blues.modal_blue
    }`,
    padding: `${pxToRem(10)} ${pxToRem(13)}`,
    flexDirection: "row",
    backgroundColor: "rgba(255, 255, 255, 0.5)",
    position: "fixed",
    top: 0,
    width: "99%",
    zIndex: "999",
  },
  stamp: {
    "& img": {
      width: pxToRem(80),
      height: pxToRem(80),
      cursor: "pointer",
      objectFit: "cover",
      position: "absolute",
    },
  },
  marginLeftAlign: {
    marginLeft: pxToRem(50),
  },
  settings: {
    cursor: "pointer",
    "& ul": {
      border: `${pxToRem(1)} solid ${
        color.secondary_palette.grays.shadow_gray
      }`,
      paddingBottom: pxToRem(5),
      paddingTop: pxToRem(5),
      marginTop: pxToRem(11),
      position: "fixed",
      top: pxToRem(16),
      paddingLeft: 0,
      backgroundColor: color.primary_palette.white,
      zIndex: "9999",
      width: pxToRem(217),
      right: 0,
      borderRadius: pxToRem(10),
      boxShadow: "2px 2px 4px 0 rgba(0, 0, 0, 0.5)",
      marginRight: pxToRem(12),
    },
    "& li": {
      listStyleType: "none",
      padding: pxToRem(5),
      cursor: "pointer",
      color: color.primary_palette.white,
      position: "relative",
      zIndex: 999,
      borderTop: `${pxToRem(1)} solid ${
        color.secondary_palette.grays.shadow_gray
      }`,
      textAlign: "right",
    },
  },
  border_top_gray: {
    borderTop: `${pxToRem(1)} solid ${
      color.secondary_palette.grays.shadow_gray
    }`,
  },
  logout_align: {
    width: pxToRem(16),
    height: pxToRem(15),
    paddingLeft: pxToRem(10),
    position: "relative",
    top: pxToRem(4),
  },
  pos_relative: {
    position: "relative",
    cursor: "pointer",
  },
  nav_logo: {
    height: pxToRem(19),
    width: pxToRem(19),
    borderRadius: "50%",
    marginLeft: pxToRem(7),
  },
  txtRight: {
    // width: pxToRem(80),
    textAlign: "right",
  },
  spacing_plus: {
    paddingRight: pxToRem(10),
    marginBottom: pxToRem(4),
  },
  spacing_plus_company: {
    lineHeight: pxToRem(24),
    paddingRight: pxToRem(10),
  },
  text_upper: {
    textTransform: "uppercase",
  },
  text_caps: {
    textTransform: "capitalize",
  },
  flexCenter: {
    marginBottom: pxToRem(3),
    justifyContent: "flex-end",
    paddingRight: pxToRem(10),
    fontWeight: 900,
    "& p": {
      ...tradework_theme.typography.styles.avenir_black_r,
      color: color.primary_palette.black,
    },
  },
  flexCenterInactive: {
    marginBottom: pxToRem(3),
    opacity: "0.8",
    justifyContent: "flex-end",
    paddingRight: pxToRem(10),
    "& p": {
      ...tradework_theme.typography.styles.avenir_sb,
      color: color.secondary_palette.grays.medium_gray,
    },
  },
  logoutTxt: {
    display: "contents",
    width: pxToRem(80),
    textAlign: "right",
  },
  arrowIcon: {
    width: pxToRem(11),
    height: pxToRem(9),
    position: "relative",
    top: pxToRem(4),
    right: pxToRem(4),
    paddingLeft: pxToRem(4),
  },
  signInStyles: {
    fontSize: pxToRem(13),
    ...tradework_theme.typography.styles.gillsans_sb,
    color: color.primary_palette.black,
  },
  hoverPurpleTxt: {
    "&:hover": {
      color: color.primary_palette.highlight_purple,
    },
  },
  hoverTxt: {
    "&:hover": {
      color: color.form_colors.sucess_word_color,
    },
  },
  fr_stamp: {
    backgroundImage: `url("/assets/icons/fr_stamp.svg")`,
    content: `url('data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7')`,
    backgroundSize: "cover",
    cursor: "pointer",
    width: pxToRem(80),
    height: pxToRem(80),
    position: "absolute",
    "&:hover": {
      backgroundImage: `url("/assets/icons/fr-logo-Hover.svg")`,
    },
  },
  portfoliosBlock: {
    textAlign: "right",
    paddingRight: pxToRem(10),
  },
});

export default styles;
