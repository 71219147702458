import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row, Text } from "../../components/common/ui_kit";
import CustomAutocomplete from "../../components/inputs/custom_auto_complete";
import CustomScrollbars from "../../components/data_display/CustomScrollbars";
import { get, map } from "lodash";
import MenuItem from "@material-ui/core/MenuItem";

import { ProfileActions } from "../../redux/actions";
import Stylesheet from "./styles";
import { color } from "../../utilities/themes";
import Chevron_Down_Icon from "../../components/data_display/icons/Arrow_Chevron_Down";
import CustomButton from "../../components/navigations/custom_buttons";
import { useHistory } from "react-router-dom";
import CustomSelect from "../../components/common/ui_kit/custom_select";

function ActAsUser(props) {
  const { classes } = props;
  const [values, setValues] = useState({
    selectedCompany: null,
    email: null,
    companyAdmins: [],
    adminEmail: "",
  });
  const history = useHistory();
  const { selectedCompany, email, companyAdmins, adminEmail } = values;

  const companyData = useSelector((resData) => resData.Profile.companyList);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(ProfileActions.getCompanyData());
  }, []);

  const handleCompanySelect = (event, company) => {
    setValues({
      ...values,
      selectedCompany: company,
      companyAdmins: get(company, "companyAdmins", []),
    });
  };

  const handleDropdownSelectValue = (name, value) => (e) => {
    e.preventDefault();
    e.stopPropagation();
    setValues({ ...values, [name]: value });
  };

  const handleSubmit = () => {
    if (!selectedCompany || !adminEmail) {
      return;
    }
    const dataToSubmit = {
      companyId: get(selectedCompany, "_id", ""),
      email: get(adminEmail, "email", ""),
    };
    dispatch(
      ProfileActions.actAsUser(dataToSubmit, (res) => {
        localStorage.setItem("inpersonate_token", res.token);
        localStorage.removeItem("active");
        localStorage.removeItem("Review");
        localStorage.removeItem("NewPortfolio");
        localStorage.removeItem("profile");
        localStorage.removeItem("frpage");
        localStorage.removeItem("companyId");
        localStorage.removeItem("companyName");
        localStorage.removeItem("portfolioId");
        localStorage.removeItem("customerCategory");
        history.push("/settings/company");
      })
    );
  };

  return (
    <div className={classes.wrapper}>
      <h1>Act As User</h1>
      <div>
        <Row className={classes.field_wrapper}>
          <div style={{ width: "320px", marginRight: "30px" }}>
            <CustomAutocomplete
              className={classes.select_field}
              placeholder="Company Name"
              disableOpenOnFocus
              value={selectedCompany}
              company
              onChange={(event, newInputValue) => {
                handleCompanySelect(event, newInputValue);
              }}
              freeSolo
              options={companyData || []}
              getOptionLabel={(option) => option.name}
              ListboxComponent={React.forwardRef(
                ({ children, ...rest }, ref, itemsLength) => (
                  <div>
                    <Text
                      size={14}
                      color={color.primary_palette.highlight_purple}
                      family="gillsans_r"
                      className={classes.dropdown_headings}
                    ></Text>
                    <div className={`${classes.scroll}`}>
                      <CustomScrollbars
                        ref={ref}
                        {...rest}
                        className={classes.scroll_bar}
                      >
                        <Row className={classes.scroll_options}>
                          <div className={classes.width_100}>
                            <span className={classes.companyNameBlock}>
                              {children}
                            </span>
                            <br />
                          </div>
                        </Row>
                      </CustomScrollbars>
                    </div>
                    <Text
                      size={14}
                      color={color.primary_palette.black}
                      family="gillsans_r"
                      className={classes.dropdown_headings}
                    ></Text>
                    <Text
                      size={14}
                      color={color.primary_palette.black}
                      family="gillsans_r"
                    >
                      No Existing Company Profile? Just keep on typing.
                    </Text>
                  </div>
                )
              )}
            />
          </div>
          <div>
            <CustomSelect
              className={classes.dropdownWidth}
              IconComponent={Chevron_Down_Icon}
              name="adminEmail"
              value={adminEmail}
              renderValue={(value) =>
                get(value, "email", "Select Admin") || "Select Admin"
              }
              MenuProps={{
                getContentAnchorEl: null,
                disableScrollLock: true,
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
              }}
            >
              <CustomScrollbars className={`${classes.custom_ballparkCost}`}>
                {companyAdmins &&
                  map(companyAdmins, (adminDetails) => {
                    return (
                      <MenuItem
                        onClick={handleDropdownSelectValue(
                          "adminEmail",
                          adminDetails
                        )}
                      >
                        {adminDetails.email}
                      </MenuItem>
                    );
                  })}
              </CustomScrollbars>
            </CustomSelect>
          </div>
          <CustomButton onClick={handleSubmit} className={classes.grid_button}>
            Submit
          </CustomButton>{" "}
        </Row>
      </div>
    </div>
  );
}

export default Stylesheet(ActAsUser);
