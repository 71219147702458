import React from "react";

import { color } from "../../utilities/themes";
import DeleteProjetStyles from "./styles/delete_projectStyles";
import Text from "../common/ui_kit/text";
import strings from "../../utilities/strings";
import Close_Icon from "../data_display/icons/Close";
import CustomButton from "../navigations/custom_buttons";

function DeleteModal(props) {
  const { classes, onClose, deleteProject, imagesData } = props;

  return (
    <>
      <div className={classes.product_cover}>
        <div className={classes.textRight}>
          <CustomButton className={classes.cross_btn} onClick={onClose}>
            <Close_Icon className={classes.cross_mark} onClick={onClose} />
          </CustomButton>
        </div>
        <div className={classes.product_cover_inner}>
          {!imagesData && <div className={classes.img_block}></div>}
          {imagesData && (
            <img src={imagesData} className={classes.image_block} alt=""/>
          )}
          <Text
            size={20}
            color={color.primary_palette.black}
            family="avenir_light"
            className={`${classes.textCenter} ${classes.padding_20}`}
            transform="uppercase"
          >
            {strings.add_project.titles.Interior}
          </Text>
        </div>
        <Text
          size={25}
          color={color.greyish_brown}
          className={`${classes.textCenter} ${classes.padding_sides}`}
        >
          {strings.add_project.titles.confirmation}
        </Text>
        <Text
          size={20}
          color={color.primary_palette.black}
          family="gillsans_light"
          className={classes.textCenter}
        >
          {strings.add_project.titles.action}
        </Text>
        <div className={classes.textCenter}>
          <CustomButton className={classes.deleteBtn} onClick={deleteProject}>
            {strings.add_project.titles.delete_item_image}
          </CustomButton>
        </div>
      </div>
    </>
  );
}

export default DeleteProjetStyles(DeleteModal);
