import { withStyles } from "@material-ui/core/styles";
import tradework_theme, { pxToRem, color } from "../../../utilities/themes";

const styles = withStyles({
  product_width: {
    width: pxToRem(840),
    border: `solid ${pxToRem(2)} ${color.primary_palette.free_purple}`,
    backgroundColor: color.secondary_palette.blues.modal_blue,
    position: "relative",
  },
  btnRight: {
    textAlign: "right",
  },
  crossBtn: {
    minWidth: pxToRem(30),
    padding: pxToRem(16),
    "& .MuiSvgIcon-root": {
      fontSize: pxToRem(15),
    },
  },
  textCenter: {
    textAlign: "center",
  },
  edit_icon: {
    fontSize: pxToRem(60),
    position: "absolute",
    top: pxToRem(-35),
    left: "47%",
  },
  product_input: {
    width: pxToRem(715),
    margin: "0 auto",
    padding: `${pxToRem(14)} ${pxToRem(50)}`,
    paddingBottom: pxToRem(0),
  },
  buttonAlign: {
    position: "absolute",
    bottom: 0,
    right: 0,
    marginRight: pxToRem(40),
    marginBottom: pxToRem(39),
  },
  required_icon: {
    fontSize: pxToRem(15),
    paddingRight: pxToRem(4),
    color: color.form_colors.blueberry_purple,
  },
  red_required_icon: {
    fontSize: pxToRem(15),
    paddingRight: pxToRem(4),
    color: color.primary_palette.christmas_red,
  },
  product_radio: {
    paddingLeft: pxToRem(20),
    marginRight: pxToRem(0),
    "& .MuiFormControlLabel-label": {
      ...tradework_theme.typography.styles.avenir_sb,
      fontSize: pxToRem(16),
      color: color.primary_palette.franklin_purple,
    },
    "& .MuiSvgIcon-root": {
      fontSize: pxToRem(20),
    },
  },
  justify_left: {
    flexGrow: 1,
  },
  justify_right: {
    flexGrow: 1,
    justifyContent: "flex-end",
    marginTop: pxToRem(-10),
  },
  product_inputfield: {
    width: "100%",
    fontSize: pxToRem(16),
    borderBottom: `solid ${pxToRem(1)} ${
      color.secondary_palette.grays.shadow_gray
    }`,
    ...tradework_theme.typography.styles.NeutraText,
    color: color.primary_palette.black,
    "&:before": {
      borderBottom: `0 !important`,
      display: "none !important",
    },
    "&:after": {
      borderBottom: `0 !important`,
      display: "none !important",
    },

    "& .MuiInputBase-input": {
      paddingTop: pxToRem(0),
    },
    "& .MuiInputBase-input::placeholder": {
      // fontStyle: "oblique",
      color: color.secondary_palette.grays.shadow_gray,
      fontSize: pxToRem(16),
      opacity: 1,
    },
  },
  red_product_inputfield: {
    width: "100%",
    fontSize: pxToRem(16),
    borderBottom: `solid ${pxToRem(1)} ${color.primary_palette.christmas_red}`,
    ...tradework_theme.typography.styles.NeutraText,
    color: color.primary_palette.black,
    "&:before": {
      borderBottom: `0 !important`,
      display: "none !important",
    },
    "&:after": {
      borderBottom: `0 !important`,
      display: "none !important",
    },
    "& .MuiInputBase-input::placeholder": {
      // fontStyle: "oblique",
      color: color.secondary_palette.grays.shadow_gray,
      fontSize: pxToRem(16),
      opacity: 1,
    },
  },
  font25: {
    fontSize: pxToRem(25),
  },
  font22: {
    fontSize: pxToRem(22),
  },
  pos_relative: {
    position: "relative",
  },
  padding_top_24: {
    paddingTop: pxToRem(8),
  },
  text_area: {
    backgroundColor: color.primary_palette.white,
    height: `${pxToRem(72)} !important`,
    width: "100%",
    resize: "none",
    padding: pxToRem(10),
    ...tradework_theme.typography.styles.NeutraText,
    fontSize: pxToRem(16),
    "&::placeholder": {
      // fontStyle: "oblique !important",
      color: color.secondary_palette.grays.shadow_gray,
      ...tradework_theme.typography.styles.avenir_light,
      opacity: 1,
    },
  },
  number: {
    "& span": {
      fontSize: pxToRem(16),
      ...tradework_theme.typography.styles.avenir_sb,
      color: color.secondary_palette.grays.shadow_gray,
    },
  },
  number_textare: {
    "& span": {
      fontSize: pxToRem(16),
      ...tradework_theme.typography.styles.avenir_sb,
      color: color.secondary_palette.grays.shadow_gray,
      marginRight: pxToRem(-6),
    },
  },
  image_block: {
    width: "36%",
    // margin: "0 auto",
    position: "relative",
    display: "inline-block",
    padding: `${pxToRem(27)}  ${pxToRem(0)} ${pxToRem(14)} ${pxToRem(0)}`,
  },
  hashTagStyle: {
    display: "inline-block",
    width: "60%",
    marginTop: pxToRem(20),
  },
  mainTextsize: {
    fontSize: pxToRem(16),
    ...tradework_theme.typography.styles.avenir_sb,
    color: color.primary_palette.free_purple,
    "&:hover": {
      cursor: "pointer",
      textDecoration: "underline",
    },
  },
  mainTextsizeError: {
    fontSize: pxToRem(16),
    ...tradework_theme.typography.styles.avenir_sb,
    color: color.primary_palette.christmas_red,
    "&:hover": {
      cursor: "pointer",
      textDecoration: "underline",
    },
  },
  absolute_required: {
    position: "absolute",
    left: pxToRem(-20),
    fontSize: pxToRem(17),
    fill: color.form_colors.blueberry_purple,
  },
  red_absolute_required: {
    position: "absolute",
    left: pxToRem(-20),
    fontSize: pxToRem(17),
    fill: color.primary_palette.christmas_red,
  },
  product_cover: {
    width: pxToRem(646),
    border: `solid ${pxToRem(2)} ${color.primary_palette.free_purple}`,
    paddingBottom: pxToRem(20),
    backgroundColor: color.secondary_palette.blues.modal_blue,
  },
  product_cover_inner: {
    width: pxToRem(360),
    margin: "0 auto",
    textAlign: "center",
    border: `solid ${pxToRem(1)} ${color.primary_palette.franklin_purple}`,
  },
  img_block: {
    width: pxToRem(103),
    height: pxToRem(148),
    backgroundColor: color.secondary_palette.grays.shadow_gray,
    margin: `${pxToRem(14)} auto`,
  },
  deleteImgBlock: {
    width: pxToRem(98),
    height: pxToRem(148),
    margin: `${pxToRem(14)} auto`,
  },
  padding_sides: {
    paddingTop: pxToRem(30),
    paddingBottom: pxToRem(13),
  },
  deleteBtn: {
    width: pxToRem(140),
    height: pxToRem(25),
    borderRadius: pxToRem(20),
    border: `solid ${pxToRem(1)} ${color.form_colors.blueberry_purple}`,
    backgroundColor: color.primary_palette.white,
    marginTop: pxToRem(22),
    "& .MuiButton-label": {
      ...tradework_theme.typography.styles.avenir_sb,
      fontSize: pxToRem(15),
      color: color.form_colors.royal_purple_1,
    },
  },
  save_item: {
    marginBottom: pxToRem(0),
    width: pxToRem(130),
    height: pxToRem(25),
    borderRadius: pxToRem(27),
    border: `solid ${pxToRem(1)} ${color.primary_palette.franklin_purple}`,
    backgroundColor: color.primary_palette.white,
    marginTop: pxToRem(10),
    "& .MuiButton-label": {
      ...tradework_theme.typography.styles.avenir_sb,
      fontSize: pxToRem(15),
      color: color.primary_palette.franklin_purple,
    },
  },
  save_btn_disable: {
    marginBottom: pxToRem(10),
    width: pxToRem(209),
    height: pxToRem(40),
    borderRadius: pxToRem(27),
    marginTop: pxToRem(10),
    backgroundColor: color.primary_palette.white,
    border: `solid ${pxToRem(2)} ${color.primary_palette.disable_border}`,
    "& .MuiButton-label": {
      ...tradework_theme.typography.styles.avenir_sb,
      fontSize: pxToRem(20),
    },
  },
  padding_20: {
    padding: `${pxToRem(0)} ${pxToRem(20)}`,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  margin_top_20: {
    marginTop: pxToRem(-20),
  },
  addImage_default: {
    width: pxToRem(130),
    height: pxToRem(162),
    textAlign: "center",
  },
  errorTxt: {
    color: color.primary_palette.christmas_red,
    float: "right",
    position: "relative",
    right: pxToRem(48),
    fontSize: pxToRem(11),
    ...tradework_theme.typography.styles.avenir_black_r,
  },
  errorImage: {
    position: "relative",
    top: pxToRem(3),
  },
});

export default styles;
