import { getGroupedArray } from "../../utilities/utils";
import { ProfileTypes } from "../actionTypes";
const initialState = {
  config: null,
};

export default (state = initialState, action) => {//NOSONAR
  switch (action.type) {
    case ProfileTypes.FETCH_ALL_REGIONS: {
      return {
        ...state,
        regions: [...action.payload],
      };
    }
    case ProfileTypes.FETCH_ALL_CATEGORIES: {
      return {
        ...state,
        categories: [...action.payload],
      };
    }
    case ProfileTypes.SAVE_PROFILE_IMAGE_DATA: {
      return {
        ...state,
        imageData: [...action.payload],
      };
    }
    case ProfileTypes.SAVE_ELEVATOR_PITCH_DATA: {
      return {
        ...state,
        elevatorPitchData: { ...action.payload },
      };
    }
    case ProfileTypes.FETCH_PROJECT_SELECTION: {
      const data = action.payload;
      if (data.settings) {
        data.settings = getGroupedArray(data.settings, "parent");
      }
      if (data.venue) {
        data.venue = getGroupedArray(data.venue, "parent");
      }
      return {
        ...state,
        projectSelectionData: { ...action.payload },
      };
    }
    case ProfileTypes.FETCH_PROJECT_IMAGE_SELECTION: {
      const data = action.payload;
      if (data.trade) {
        data.trade = getGroupedArray(data.trade, "parent");
      }
      if (data.features) {
        data.features = getGroupedArray(data.features, "parent");
      }
      if (data.room) {
        data.room = getGroupedArray(data.room, "parent");
      }
      return {
        ...state,
        projectSelectionImageData: { ...action.payload },
      };
    }
    case ProfileTypes.SAVE_UPDATE_PROJECT: {
      return {
        ...state,
        projectData: { ...action.payload },
      };
    }
    case ProfileTypes.SAVE_PRODUCT_DATA: {
      return {
        ...state,
        savedProductData: { ...action.payload },
      };
    }
    case ProfileTypes.GET_PRODUCT_PUBLICATION_DATA: {
      return {
        ...state,
        productsAndPublicationData: { ...action.payload },
      };
    }
    case ProfileTypes.FETCH_ALL_PROJECTS: {
      return {
        ...state,
        projectsData: [...action.payload],
      };
    }
    case ProfileTypes.PROJECTS_BY_PORTFOLIO: {
      return {
        ...state,
        projectsByPortfolio: { ...action.payload },
      };
    }
    case ProfileTypes.GET_PORTFOLIO_DATA: {
      return {
        ...state,
        portfolioData: { ...action.payload.portfolio },
        displayedAtData: { ...action.payload.displayedAtData },
      };
    }
    // case ProfileTypes.GET_PORTFOLIO_DATA_BY_SLUG: {
    //   return {
    //     ...state,
    //     portfolioData: { ...action.payload.portfolio },
    //     displayedAtData: { ...action.payload.displayedAtData },
    //   };
    // }
    case ProfileTypes.GET_INDIVIDUAL_PROJECT_DATA: {
      return {
        ...state,
        individualProjectData: { ...action.payload },
      };
    }
    case ProfileTypes.INSTAGRAM_FEEDS: {
      return {
        ...state,
        instaFeeds: [...action.payload],
      };
    }
    case ProfileTypes.GET_COVER_IMAGE_DATA: {
      return {
        ...state,
        coverImageData: { ...action.payload },
      };
    }
    case ProfileTypes.PERSONAL_SETTING_DATA: {
      return {
        ...state,
        personalSettingData: { ...action.payload },
      };
    }
    case ProfileTypes.COMPANY_SETTING_DATA: {
      return {
        ...state,
        companySettingData: [...action.payload],
      };
    }
    case ProfileTypes.ALL_TRADES_LIST: {
      return {
        ...state,
        allTradesData: [...action.payload],
      };
    }
    case ProfileTypes.GET_ACCOLADES_LIST: {
      return {
        ...state,
        accoladesData: [...action.payload],
      };
    }
    case ProfileTypes.GET_CONFIG_DATA: {
      return {
        ...state,
        configData: { ...action.payload },
      };
    }
    case ProfileTypes.GET_VIBES_DATA: {
      return {
        ...state,
        vibesData: [...action.payload],
      };
    }
    case ProfileTypes.GET_CLIENTEL_DATA: {
      return {
        ...state,
        clientelData: [...action.payload],
      };
    }
    case ProfileTypes.GET_VENUE_DATA: {
      return {
        ...state,
        venueData: [...action.payload],
      };
    }
    case ProfileTypes.GET_COMPANY_DATA: {
      return {
        ...state,
        companyList: [...action.payload],
      };
    }
    case ProfileTypes.GET_PORTFOLIO_LIST: {
      return {
        ...state,
        portfolioList: [...action.payload],
      };
    }
    case ProfileTypes.COMPANY_DATA_BY_USER: {
      return {
        ...state,
        CompanyListByUser: [...action.payload],
      };
    }
    default:
      return state;
  }
};
