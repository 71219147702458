import React, { useEffect } from "react";
import { styled } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import { get } from "lodash";

const Loading = ({ loading, match, location }) => {
  let history = useHistory();

  useEffect(() => {
    if (!match.params.token || match.params.token === ":token") {
      redirectToSpack();
    } else {
      localStorage.setItem("userType", match.params.token);
      history.push("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const redirectToSpack = () => {
    let host = get(window, "location.host", "");
    if (host) {
      if (host.includes("localhost")) {
        host = "localhost:3000";
        window.location.href = `http://${host}`;
      } else {
        if (host.includes("-dev-")) {
          host = "tradeworks-spack-dev-fe.azurewebsites.net";
        }
        if (host.includes("-qa-")) {
          host = "tradeworks-spack-qa-fe.azurewebsites.net";
        }
        if (host.includes("-stage-")) {
          host = "tradeworks-spack-stage-fe.azurewebsites.net";
        }
        window.location.href = `https://${host}`;
      }
    }
  };

  return (
    <LoadingWheel>
      <img src="/assets/images/loading.gif" alt="loading" />
    </LoadingWheel>
  );
};

const LoadingWheel = styled("div")({
  position: "fixed",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
  height: "100%",
  opacity: 0.8,
  zIndex: 2000,
});

export default Loading;
