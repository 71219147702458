import React, { useState, useEffect } from "react";
import ReactQuill from "react-quill";
import { useDispatch, useSelector } from "react-redux";
import { map, get, find, filter, every } from "lodash";
import MenuItem from "@material-ui/core/MenuItem";
import { useHistory } from "react-router-dom";
import uuidv4 from "uuid/v4";
// git commit test
import { color } from "../../utilities/themes";
import AddprojectStyles from "./styles/addprojectStyles";
import Row from "../common/ui_kit/row";
import Text from "../common/ui_kit/text";
import strings from "../../utilities/strings";
import CustomButton from "../navigations/custom_buttons";
import Edit_Circle_Icon from "../data_display/icons/Edit";
import Element_Required_Icon from "../data_display/icons/ElementRequiered";
import CustomInputCount from "../inputs/custom_input_count";
import CustomSelect from "../common/ui_kit/custom_select";
import CustomSelectProj from "../common/ui_kit/custom_select_proj";
import Chevron_Down_Icon from "../data_display/icons/Arrow_Chevron_Down";
import ProjectAddImag from "./project_imag";
import CustomScrollbars from "../data_display/custom_scroll";
import AddImage from "../add_images/add_image_cropper";
import CustomModal from "../inputs/custom_modal";
import GridTwoMS from "../grid_sm/grid_2ms";
import GridThreeMS from "../grid_sm/grid_3ms";
import GridFourMS from "../grid_sm/grid_4ms";
import GridFiveMS from "../grid_sm/grid_5ms";
import GridSixMS from "../grid_sm/grid_6ms";
import GridsevenMS from "../grid_sm/grid_7ms";
import GridEightMS from "../grid_sm/grid_8ms";
import GridNineMS from "../grid_sm/grid_9ms";
import GridTenMS from "../grid_sm/grid_10ms";
import GridElevenMS from "../grid_sm/grid_11ms";
import GridTwelveMS from "../grid_sm/grid_12ms";
import GridOneMs from "../grid_sm/grid_1ms";
import AlterConfirmation from "../add_new_project/alter_grid_confirmation";
import getCroppedImg from "../cropper/project_cropper";

import {
  LookUpActions,
  ProfileActions,
  SettingsActions,
} from "../../redux/actions";
import { REMOVE_TAGS } from "../../validations/validations";
import { ApiActions } from "../../redux/actions";
import Location from "../data_display/icons/location";
import Feedback from "../modals/feedbackModal";

function AddProjects(props) {
  const [state, setState] = useState({
    errors: {},
    openCroppingModal: false,
    openAlterCroppingModal: false,
    openAlterGridChoose: false,
    openRadioAlter: false,
    portfolioId: localStorage.getItem("portfolioId"),
    style: [],
    venue: [],
    setting: [],
    ballparkCost: [],
    title: "",
    subtitle: "",
    imageData: {},
    alterImageData: {},
    streetAddress: "",
    description: "",
    tags: [],
    isEditAlter: false,
    projectImages: [],
    newOptionValue: "",
    toggleFeedbackModal: false,
  });
  const [description, setDescription] = useState("");
  const {
    projectImages,
    errors,
    imageData,
    newOptionValue,
    projectImages_quantity,
    title,
    style,
    venue,
    setting,
    ballparkCost,
    streetAddress,
    subtitle,
    tags,
    isCover,
    errorMessage,
    openAlterGridChoose,
    isEditAlter,
    indexVal,
    toggleFeedbackModal,
  } = state;

  const history = useHistory();

  const projectSelectionData = useSelector(
    (resData) => resData.Profile.projectSelectionData
  );

  const individualProjectData = useSelector(
    (resData) => resData.Profile.individualProjectData
  );

  const lookUpData = useSelector((resData) => resData.LookUp.LookUpData);

  const portfolioData = useSelector((resData) => resData.Profile.portfolioData);

  const dispatch = useDispatch();
  const fetchData = () => {
    dispatch(ProfileActions.fetchProjectSelection());
    dispatch(ProfileActions.fetchProjectImageSelection());
    dispatch(LookUpActions.getLookUpData());
    dispatch(
      ProfileActions.getPortfolioData(localStorage.getItem("portfolioId"))
    );
  };

  const projectImageUpload = (data) => {
    const { imageFile, imageUrl, id, originalImageUrl, originalImageFile } =
      data;
    let section = find(projectImages, { _id: id });
    errors.projectImage = { error: false, message: "" };
    section.alterImageUri = {
      croppedImage: imageUrl,
      blobUrl: imageFile,
      originalProjImgUrl: originalImageUrl,
      originalProjImgFile: originalImageFile,
    };
    errors.gridErrors = "";
    setState({ ...state, projectImages });
  };

  const setCroppedImage = (data) => {
    const { imageFile, imageUrl, originalImageUrl, originalImageFile } = data;
    errors.imageData = { error: false, message: "" };
    imageData.imageUrl = imageUrl;
    imageData.blobFile = imageFile;
    imageData.originalImageUrl = originalImageUrl;
    imageData.originalImageFile = originalImageFile;
    setState({ ...state, imageData });
  };

  //API
  useEffect(() => {
    window.scrollTo(0, 0);
    fetchData();
    const isEdit = get(props.location, "state.edit", false);
    const indexVal = get(props.location, "state.index", 0);
    setState({ ...state, isEdit, indexVal });
  }, []);

  useEffect(() => {
    let interval = null;
    interval = setInterval(() => {
      saveProjectImageData("draft", false)();
    }, 3 * 60 * 1000);
    return () => clearInterval(interval);
  }, [state]);

  useEffect(() => {
    if (individualProjectData && state.isEdit) {
      const formattedProjectImages = map(
        individualProjectData.projectImages,
        async (each) => {
          const features = await map(each.features, (feature) => {
            return { name: feature };
          });
          const trade = await map(each.trade, (tradeVal) => {
            return { name: tradeVal };
          });
          const room = await map(each.room, (roomVal) => {
            return { name: roomVal };
          });
          const mainColor = await map(each.mainColor, (mainColorVal) => {
            return { color: mainColorVal };
          });
          const materialsOrTechniques = await map(
            each.materialsOrTechniques,
            (materialsOrTechniquesVal) => {
              return { name: materialsOrTechniquesVal };
            }
          );
          return {
            ...each,
            alterImageUri: {
              croppedImage: each.imageUri,
              originalProjImgUrl: each.actualImageUri,
            },
            imageUri: {
              imageUrl: each.imageUri,
            },
            features,
            trade,
            room,
            mainColor,
            materialsOrTechniques,
          };
        }
      );

      const styleArray = map(individualProjectData.style, (styleVal) => {
        return { name: styleVal };
      });
      const venueArray = map(individualProjectData.venue, (venueVal) => {
        return { name: venueVal };
      });
      const settingArray = map(individualProjectData.setting, (settingVal) => {
        return { name: settingVal };
      });

      Promise.all(formattedProjectImages).then((res) => {
        setState({
          ...state,
          ...individualProjectData,
          style: [...styleArray],
          venue: [...venueArray],
          setting: [...settingArray],
          ballparkCost: { name: individualProjectData.ballparkCost },
          imageData: {
            imageUrl: individualProjectData.coverImageUri,
            originalImageUrl: individualProjectData.actualCoverImageUri,
          },
          projectImages: [...res],
        });
        setDescription(individualProjectData.description);
      });
    }
  }, [individualProjectData]);

  const handleDropdownSelectValue = (name, value) => (e) => {
    e.preventDefault();
    e.stopPropagation();
    setState({ ...state, [name]: value });
  };

  const handleDropdownSelect = (name, value, del) => (e) => {
    e.preventDefault();
    e.stopPropagation();
    const checkForDuplicate = find(state[name], {
      name: value.name,
    });
    if (state[name] && state[name].length > 4 && !del) {
      return;
    }
    if (!checkForDuplicate) {
      setState({ ...state, [name]: [...state[name], value] });
    } else {
      if (!del) {
        return;
      }
      const updatedData = filter(state[name], (each) => {
        return each.name != value.name;
      });
      setState({ ...state, [name]: [...updatedData] });
    }
  };

  const returnRemainingLimit = (currentCount, maxCount) => {
    return maxCount - currentCount;
  };

  const handleProjectImgDropdown = (name, value, sectionId, del) => (e) => {
    e.preventDefault();
    e.stopPropagation();
    const foundRecord = find(projectImages, {
      _id: sectionId,
    });
    if (foundRecord) {
      foundRecord[name] = foundRecord[name] || [];
      if (foundRecord[name] && foundRecord[name].length > 4 && !del) {
        return;
      }
      const checkForDuplicate =
        name === "mainColor"
          ? find(foundRecord[name], { color: value.color }, false)
          : find(foundRecord[name], { name: value.name }, false);
      if (!checkForDuplicate) {
        foundRecord[name] = [...foundRecord[name], { ...value }];
        setState({ ...state });
      } else {
        if (!del) {
          return;
        }
        const updatedData = filter(foundRecord[name], (each) => {
          if (name === "mainColor") {
            return each.color !== value.color;
          }
          return each.name !== value.name;
        });
        foundRecord[name] = [...updatedData];
        setState({ ...state });
      }
    }
  };

  const redirectToPortfolio = () => {
    history.push("/portfolioView");
  };

  const handleFeedbackModal = () => {
    setState({
      ...state,
      toggleFeedbackModal: !toggleFeedbackModal,
    });
  };

  const addTitle = (name) => () => {
    if (!newOptionValue) {
      return;
    }
    const option = { name: newOptionValue, _id: uuidv4() };
    if (state[name] && state[name].length > 4) {
      return;
    }
    setState({
      ...state,
      [name]: [...state[name], { ...option }],
      newOptionValue: "",
    });
  };

  const closeAlterModal = () => {
    setState({
      ...state,
      openAlterGridChoose: false,
    });
  };

  const addProjectTitle = (name, value, id) => () => {
    let section = find(projectImages, { _id: id });
    if (!value || (section[name] && section[name].length > 4)) {
      return;
    }
    const option = {
      _id: uuidv4(),
      [name === "mainColor" ? "color" : "name"]: value || newOptionValue,
    };
    section[name] = section[name] || [];
    section[name] = [...section[name], { ...option }];
    setState({ ...state, projectImages });
  };

  const deleteProject = (id) => {
    const updatedData = projectImages.filter((each) => {
      return each._id != id;
    });
    setState({ ...state, projectImages: [...updatedData] });
  };

  const handleInputChange = (e) => {
    const { id, name, value } = e.target;
    let updatedData = [];
    if (e.target.name === "isCover") {
      updatedData = projectImages.map((each) => {
        if (each._id === id) {
          return { ...each, isCover: true };
        }
        return { ...each, isCover: false };
      });
      setState({ ...state, projectImages: [...updatedData] });
      return;
    }
    if (e.target.name === "projectImgTags") {
      updatedData = projectImages.map((each) => {
        if (each._id === id) {
          if (e.target.value) {
            const tagsData = e.target.value;
            const tagsArray = tagsData.split(",");
            for (var i = 0; i < tagsArray.length; i++) {
              tagsArray[i] = tagsArray[i].includes("#")
                ? tagsArray[i]
                : " #" + tagsArray[i];
            }
            return { ...each, projectImgTags: tagsArray };
          }
        }
        return { ...each };
      });
      setState({ ...state, projectImages: [...updatedData] });
      return;
    }
    updatedData = map(state.projectImages, (each) => {
      if (each._id === id) {
        each[name] = value;
      }
      return { ...each };
    });
    setState({ ...state, projectImages: [...updatedData] });
  };

  const onClear = (id, name) => () => {
    const updatedProjectImages = map(projectImages, (project) => {
      if (project._id === id && name == "projectImgTags") {
        return { ...project, projectImgTags: "" };
      } else if (project._id === id && name == "projectImagesData") {
        return {
          ...project,
          alterImageUri: {},
          actualImageUri: "",
          imageUri: "",
        };
      } else if (project._id === id && name == "all") {
        return { _id: id };
      }
      return project;
    });
    setState({ ...state, projectImages: [...updatedProjectImages] });
  };

  const handleInputValueChange = (e) => {
    handleValidations(e.target.name, e.target.value);
    if (e.target.name === "tags") {
      if (e.target.value) {
        const tagsData = e.target.value;
        const tagsArray = tagsData.split(",");
        for (var i = 0; i < tagsArray.length; i++) {
          tagsArray[i] = tagsArray[i].includes("#")
            ? tagsArray[i]
            : " #" + tagsArray[i];
        }
        setState({ ...state, [e.target.name]: tagsArray });
        return;
      }
    }
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const saveProjectImageData = (type, redirectionVal) => () => {
    if (projectImages) {
      const descError = projectImages.filter((each) => {
        return (
          each.projectImgDescription && each.projectImgDescription.length > 300
        );
      });
      if (descError.length > 0) {
        return;
      }
    }
    if ((type === "save" && hasError) || (type === "draft" && hasDraftError)) {
      handleValidations("title", state.title);
      if (!imageData.imageUrl) {
        state.errors.imageData = { error: true, message: "" };
        setState({
          ...state,
        });
        return;
      } else {
        state.errors.imageData = { error: false, message: "" };
        setState({
          ...state,
        });
      }
      const isProjectImage = projectImages.map((proj) => {
        if (proj.alterImageUri && proj.alterImageUri.croppedImage) {
          returnErrorGrid();
          return true;
        }
        return false;
      });
      if (!isProjectImage.includes(true)) {
        returnErrorGrid();
        state.errors.projectImage = { error: true, message: "" };
        return;
      } else {
        state.errors.projectImage = { error: false, message: "" };
      }
      state.errors.description = { error: false, message: "" };
      return;
    }
    if (description.replace(REMOVE_TAGS, "").length > 1000) {
      state.errors.description = { error: true, message: "" };
      return;
    } else {
      state.errors.description = { error: false, message: "" };
    }

    const updatedProjectImages = map(projectImages, (projectImg) => {
      return {
        ...projectImg,
        _id: "",
        features: map(projectImg.features, "name"),
        trade: map(projectImg.trade, "name"),
        mainColor: map(projectImg.mainColor, "color"),
        materialsOrTechniques: map(projectImg.materialsOrTechniques, "name"),
        room: map(projectImg.room, "name"),
        // projectImgTags: projectImg.tags,
      };
    });
    const dataToSubmit = {
      ...state,
      style: map(state.style, "name"),
      venue: map(state.venue, "name"),
      setting: map(state.setting, "name"),
      ballparkCost: state.ballparkCost.name,
      projectImages: [...updatedProjectImages],
      description,
    };

    dispatch(
      ProfileActions.saveProjectData(dataToSubmit, (res) => {
        if (!res.error.error && redirectionVal) {
          if (type === "draft" && individualProjectData !== undefined) {
            setState({
              ...state,
              _id: get(res, "data._id"),
            });
            return;
          }
          history.push("/portfolioView");
          window.scrollTo(0, 1700);
        } else {
          setState({
            ...state,
            errorMessage: res.error.error && res.error.message,
            _id: get(res, "data._id"),
          });
        }
      })
    );
  };

  const goBack = () => {
    history.push("/portfolioView");
  };

  const returnTotalUploadedImages = () => {
    if (projectImages.length) {
      const isImageUploaded = projectImages.filter((each) => {
        return each.alterImageUri && each.alterImageUri.croppedImage;
      });
      return isImageUploaded.length;
    }
    return 0;
  };

  const handleValidations = (name, value) => {
    if (name === "title") {
      errors[name] = errors[name] || {};
      if (value.length > 45 || !value) {
        errors[name] = { error: true, message: "" };
        setState({
          ...state,
        });
        return;
      }
      errors[name] = { error: false, message: "" };
      setState({
        ...state,
      });
      return;
    }
    if (name === "description") {
      errors[name] = errors[name] || {};
      if (value.replace(REMOVE_TAGS, "").length > 1000 || !value) {
        errors[name] = { error: true, message: "" };
        setState({
          ...state,
        });
        return;
      }
      errors[name] = { error: false, message: "" };
      setState({
        ...state,
      });
      return;
    }
  };

  const showCroppedImage = async (imageDataVal, dimensions) => {
    if (!imageDataVal) {
      return;
    }
    const croppedAreaPixels = dimensions;
    try {
      ApiActions.request(dispatch);
      let obj = {};
      const croppedImage = await getCroppedImg(imageDataVal, croppedAreaPixels);
      obj.croppedImage = URL.createObjectURL(croppedImage);
      obj.blobUrl = croppedImage;
      ApiActions.success(dispatch);
      return obj;
    } catch (e) {
      ApiActions.failure(dispatch);
      console.error(e);
    }
  };

  const returnDimensions = (quantity) => {
    switch (quantity) {
      case 1:
        return { 0: { width: 300, height: 300, x: 250, y: 150 } };
      case 2:
        return {
          0: { width: 213, height: 320, x: 280, y: 160 },
          1: { width: 213, height: 320, x: 280, y: 160 },
        };
      case 3:
        return {
          0: { width: 400, height: 133, x: 200, y: 233 },
          1: { width: 400, height: 133, x: 200, y: 233 },
          2: { width: 160, height: 320, x: 400, y: 160 },
        };
      case 4:
        return {
          0: { height: 208, width: 104, x: 318, y: 104 },
          1: { width: 250, height: 300, x: 275, y: 150 },
          2: { width: 267, height: 320, x: 347, y: 160 },
          3: { width: 160, height: 320, x: 400, y: 160 },
        };
      case 5:
        return {
          0: { width: 480, height: 144, x: 240, y: 248 },
          1: { width: 160, height: 320, x: 400, y: 160 },
          2: { width: 160, height: 320, x: 400, y: 160 },
          3: { width: 160, height: 320, x: 400, y: 160 },
          4: { width: 480, height: 144, x: 240, y: 248 },
        };
      case 6:
        return {
          0: { width: 160, height: 320, x: 400, y: 160 },
          1: { width: 480, height: 144, x: 240, y: 248 },
          2: { width: 347, height: 208, x: 196, y: 104 },
          3: { width: 347, height: 208, x: 196, y: 104 },
          4: { width: 445, height: 134, x: 222, y: 183 },
          5: { width: 175, height: 350, x: 263, y: 175 },
        };
      case 7:
        return {
          0: { width: 150, height: 300, x: 325, y: 150 },
          1: { width: 150, height: 300, x: 325, y: 150 },
          2: { width: 160, height: 320, x: 400, y: 160 },
          3: { width: 160, height: 320, x: 400, y: 160 },
          4: { width: 150, height: 300, x: 325, y: 150 },
          5: { width: 400, height: 133, x: 200, y: 233 },
          6: { width: 150, height: 300, x: 325, y: 150 },
        };
      case 8:
        return {
          0: { width: 400, height: 250, x: 200, y: 175 },
          1: { width: 480, height: 300, x: 240, y: 170 },
          2: { width: 150, height: 300, x: 325, y: 150 },
          3: { width: 150, height: 300, x: 325, y: 150 },
          4: { width: 150, height: 300, x: 325, y: 150 },
          5: { width: 150, height: 300, x: 325, y: 150 },
          6: { width: 400, height: 133, x: 200, y: 233 },
          7: { width: 150, height: 300, x: 325, y: 150 },
        };
      case 9:
        return {
          0: { width: 400, height: 250, x: 200, y: 175 },
          1: { width: 400, height: 250, x: 200, y: 175 },
          2: { width: 150, height: 300, x: 325, y: 150 },
          3: { width: 150, height: 300, x: 325, y: 150 },
          4: { width: 150, height: 300, x: 325, y: 150 },
          5: { width: 150, height: 300, x: 325, y: 150 },
          6: { width: 150, height: 300, x: 325, y: 150 },
          7: { width: 150, height: 300, x: 325, y: 150 },
          8: { width: 150, height: 300, x: 325, y: 150 },
        };
      case 10:
        return {
          0: { width: 400, height: 250, x: 200, y: 175 },
          1: { width: 400, height: 250, x: 200, y: 175 },
          2: { width: 150, height: 300, x: 325, y: 150 },
          3: { width: 150, height: 300, x: 325, y: 150 },
          4: { width: 150, height: 300, x: 325, y: 150 },
          5: { width: 150, height: 300, x: 325, y: 150 },
          6: { width: 150, height: 300, x: 325, y: 150 },
          7: { width: 150, height: 300, x: 325, y: 150 },
          8: { width: 150, height: 300, x: 325, y: 150 },
          9: { width: 150, height: 300, x: 325, y: 150 },
        };
      case 11:
        return {
          0: { width: 400, height: 250, x: 200, y: 175 },
          1: { width: 400, height: 250, x: 200, y: 175 },
          2: { width: 150, height: 300, x: 325, y: 150 },
          3: { width: 150, height: 300, x: 325, y: 150 },
          4: { width: 150, height: 300, x: 325, y: 150 },
          5: { width: 150, height: 300, x: 325, y: 150 },
          6: { width: 150, height: 300, x: 325, y: 150 },
          7: { width: 400, height: 133, x: 200, y: 233 },
          8: { width: 150, height: 300, x: 325, y: 150 },
          9: { width: 150, height: 300, x: 325, y: 150 },
          10: { width: 150, height: 300, x: 325, y: 150 },
        };
      case 12:
        return {
          0: { width: 400, height: 250, x: 200, y: 175 },
          1: { width: 400, height: 250, x: 200, y: 175 },
          2: { width: 150, height: 300, x: 325, y: 150 },
          3: { width: 150, height: 300, x: 325, y: 150 },
          4: { width: 400, height: 133, x: 200, y: 233 },
          5: { width: 400, height: 250, x: 200, y: 175 },
          6: { width: 150, height: 300, x: 325, y: 150 },
          7: { width: 150, height: 300, x: 325, y: 150 },
          8: { width: 400, height: 250, x: 200, y: 175 },
          9: { width: 150, height: 300, x: 325, y: 150 },
          10: { width: 150, height: 300, x: 325, y: 150 },
          11: { width: 150, height: 300, x: 325, y: 150 },
        };

      default:
        return {
          0: { width: 400, height: 250, x: 200, y: 175 },
          1: { width: 400, height: 250, x: 200, y: 175 },
          2: { width: 150, height: 300, x: 325, y: 150 },
          3: { width: 150, height: 300, x: 325, y: 150 },
          4: { width: 400, height: 133, x: 200, y: 233 },
          5: { width: 400, height: 250, x: 200, y: 175 },
          6: { width: 150, height: 300, x: 325, y: 150 },
          7: { width: 150, height: 300, x: 325, y: 150 },
          8: { width: 400, height: 250, x: 200, y: 175 },
          9: { width: 150, height: 300, x: 325, y: 150 },
          10: { width: 150, height: 300, x: 325, y: 150 },
          11: { width: 400, height: 250, x: 200, y: 175 },
        };
    }
  };

  const loadImage = (url) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.addEventListener("load", () => resolve(img));
      img.addEventListener("error", reject);
      img.src = url;
    });
  };

  const handleLayoutPopup1 = async () => {
    const dimensions = returnDimensions(projectImages_quantity);
    let newProjects = [];
    if (get(projectImages, "length", 0) < projectImages_quantity) {
      newProjects = [...projectImages];
      const toAddCount =
        Number(projectImages_quantity) -
        Number(get(projectImages, "length", 0));
      for (let i = 0; i < toAddCount; i++) {
        newProjects.push({ _id: uuidv4() });
      }
      const imgObj = projectImages.filter((each) => each.alterImageUri)[0];
      const imgSrc = get(imgObj, "alterImageUri.originalProjImgUrl", "");
      let img = await loadImage(imgSrc);
      for (let i = 0; i < newProjects.length; i++) {
        const newImg = await showCroppedImage(
          {
            url: get(newProjects[i], "alterImageUri.originalProjImgUrl", ""),
            width: img.width,
            height: img.height,
          },
          {
            ...dimensions[i],
            x: dimensions[i].width < 320 ? img.width / 4 : img.width / 3,
            y: img.height,
          }
        );
        if (newImg) {
          newProjects[i].alterImageUri.croppedImage = newImg.croppedImage;
        }
      }
      setState({
        ...state,
        chooseNumber: Number(projectImages_quantity),
        projectImages: [...newProjects],
        openAlterGridChoose: !openAlterGridChoose,
      });
    } else {
      newProjects = filter(projectImages, (each, index) => {
        return index < projectImages_quantity;
      });
      const imgObj = projectImages.filter((each) => each.alterImageUri)[0];
      const imgSrc = get(imgObj, "alterImageUri.originalProjImgUrl");
      let img = await loadImage(imgSrc);

      for (let i = 0; i < newProjects.length; i++) {
        const newImg = await showCroppedImage(
          {
            url: get(newProjects[i], "alterImageUri.originalProjImgUrl", ""),
            width: img.width,
            height: img.height,
          },
          {
            ...dimensions[i],
            x: dimensions[i].width < 320 ? img.width / 4 : img.width / 3,
            y: img.height,
          }
        );
        if (newImg) {
          newProjects[i].alterImageUri.croppedImage = newImg.croppedImage;
        }
      }
      setState({
        ...state,
        chooseNumber: Number(projectImages_quantity),
        projectImages: [...newProjects],
        openAlterGridChoose: !openAlterGridChoose,
      });
    }
  };

  const handleLayoutPopup = (type, quantity) => async () => {
    let newProjects = [];
    const dimensions = returnDimensions(quantity);
    if (type === "stickWithCurrent") {
      setState({
        ...state,
        openAlterGridChoose: !openAlterGridChoose,
      });
      return;
    }
    if (get(projectImages, "length", 0) < quantity) {
      newProjects = [...projectImages];
      const toAddCount =
        Number(quantity) - Number(get(projectImages, "length", 0));
      for (let i = 0; i < toAddCount; i++) {
        newProjects.push({ _id: uuidv4() });
      }

      const imgSrc = projectImages[0].alterImageUri.originalProjImgUrl;
      let img = await loadImage(imgSrc);
      for (let i = 0; i < newProjects.length; i++) {
        const newImg = await showCroppedImage(
          {
            url: get(newProjects[i], "alterImageUri.originalProjImgUrl", ""),
            width: img.width,
            height: img.height,
          },
          {
            ...dimensions[i],
            x: dimensions[i].width < 320 ? img.width / 4 : img.width / 3,
            y: img.height,
          }
        );
        if (newImg) {
          newProjects[i].alterImageUri.croppedImage = newImg.croppedImage;
        }
      }
      setState({
        ...state,
        chooseNumber: Number(quantity),
        projectImages: [...newProjects],
        openAlterGridChoose: !openAlterGridChoose,
      });
    } else {
      newProjects = filter(projectImages, (each, index) => {
        return index < quantity;
      });

      const imgSrc = projectImages[0].alterImageUri.originalProjImgUrl;
      let img = await loadImage(imgSrc);
      for (let i = 0; i < newProjects.length; i++) {
        const newImg = await showCroppedImage(
          {
            url: get(newProjects[i], "alterImageUri.originalProjImgUrl", ""),
            width: img.width,
            height: img.height,
          },
          {
            ...dimensions[i],
            x: dimensions[i].width < 320 ? img.width / 4 : img.width / 3,
            y: img.height,
          }
        );
        if (newImg) {
          newProjects[i].alterImageUri.croppedImage = newImg.croppedImage;
        }
      }
      setState({
        ...state,
        chooseNumber: Number(quantity),
        projectImages: [...newProjects],
        openAlterGridChoose: !openAlterGridChoose,
      });
    }
  };

  const handleNumberDropdown = (name, value) => (e) => {
    e.preventDefault();
    e.stopPropagation();
    const newProjects = [];
    if (projectImages.length) {
      const isImageUploaded = projectImages.filter((each) => {
        return each.alterImageUri;
      });
      if (isImageUploaded.length) {
        // IF PROJECT IMAGES > 0 && IMAGE IS UPLOADED TOGGLE POPUP
        setState({
          ...state,
          openAlterGridChoose: !openAlterGridChoose,
          projectImages_quantity: Number(value),
          isEditAlter: false,
        });
        return;
      }
    }
    // IF PROJECT IMAGES < 0 || IMAGE NOT UPLOADED
    for (let i = 0; i < Number(value); i++) {
      newProjects.push({ _id: uuidv4() });
    }
    setState({
      ...state,
      [name]: value,
      projectImages: [...newProjects],
      isEditAlter: false,
    });
  };

  const redirectIndividual = (projectInfo, val) => () => {
    saveProjectImageData("draft", false)();
    setTimeout(function () {
      dispatch(
        ProfileActions.getIndividualProjectData(projectInfo._id, (res) => {
          const projectData = res.data.data;
          history.push({
            pathname: "/ProjectSingleView",
            state: { ...projectData, val: false },
          });
        })
      );
    }, 2000);
  };

  const previewEntirePort = (proj) => () => {
    saveProjectImageData("draft", false)();
    localStorage.setItem("projectId", proj._id);
    history.push("/public-portfolio-view");
  };

  const handleopenRadioAlter = () => {
    setState({
      ...state,
      openAlterGridChoose: true,
      isEditAlter: true,
    });
  };

  const projectInfoData = (data) => {
    switch (data) {
      case 1:
        return <GridOneMs imageData={projectImages} />;
      case 2:
        return <GridTwoMS imageData={projectImages} />;
      case 3:
        return <GridThreeMS imageData={projectImages} />;
      case 4:
        return <GridFourMS imageData={projectImages} />;
      case 5:
        return <GridFiveMS imageData={projectImages} />;
      case 6:
        return <GridSixMS imageData={projectImages} />;
      case 7:
        return <GridsevenMS imageData={projectImages} />;
      case 8:
        return <GridEightMS imageData={projectImages} />;
      case 9:
        return <GridNineMS imageData={projectImages} />;
      case 10:
        return <GridTenMS imageData={projectImages} />;
      case 11:
        return <GridElevenMS imageData={projectImages} />;
      case 12:
        return <GridTwelveMS imageData={projectImages} />;
    }
  };

  const returnCropperSize = (length, position) => {
    switch (length) {
      case 1:
        return 4 / 2;
      case 2:
        return 4 / 5;
      case 3:
        switch (position) {
          case 1:
          case 2:
            return 9 / 3;
          case 3:
            return 1 / 2;
          default:
            return 1 / 2;
        }
      case 4:
        switch (position) {
          case 1:
          case 4:
            return 1 / 2;
          case 2:
          case 3:
            return 7 / 5;
          default:
            return 1 / 2;
        }
      case 5:
        switch (position) {
          case 1:
          case 5:
            return 10 / 4;
          case 3:
            return 1 / 2;
          case 2:
          case 4:
            return 4 / 5;
          default:
            return 1 / 2;
        }
      case 6:
        switch (position) {
          case 2:
          case 5:
            return 10 / 4;
          case 1:
          case 6:
            return 4 / 5;
          case 3:
          case 4:
            return 7 / 5;
          default:
            return 1 / 2;
        }
      case 7:
        switch (position) {
          case 1:
          case 4:
            return 7 / 5;
          case 5:
          case 7:
            return 16 / 30;
          case 6:
            return 4 / 2;
          default:
            return 4 / 5;
        }
      case 8:
        switch (position) {
          case 1:
          case 4:
          case 8:
            return 7 / 5;
          case 2:
            return 1 / 2;
          case 7:
            return 9 / 3;
          case 3:
            return 4 / 2;
          default:
            return 1 / 2;
        }
      case 9:
        switch (position) {
          case 2:
            return 9 / 3;
          case 3:
          case 6:
            return 1 / 2;
          default:
            return 7 / 5;
        }
      case 10:
        switch (position) {
          case 1:
          case 4:
            return 1 / 2;
          default:
            return 7 / 5;
        }
      case 11:
        switch (position) {
          case 1:
          case 5:
          case 7:
            return 4 / 5;
          case 8:
            return 10 / 4;
          default:
            return 7 / 5;
        }
      case 12:
        switch (position) {
          case 5:
            return 4 / 2;
          case 11:
            return 4 / 5;
          default:
            return 7 / 5;
        }
      default:
        return 7 / 5;
    }
  };

  //TODO: Replace images after recieving default images from client
  // const returnPlaceHolder = (length, position) => {
  //   switch (length) {
  //     case 1:
  //       return "/assets/images/a image holder@2x.jpg";
  //     case 2:
  //       return "/assets/images/b image holder@2x.jpg";
  //     case 3:
  //       switch (position) {
  //         case 1:
  //         case 2:
  //           return "/assets/images/d image holder@2x.jpg";
  //         case 3:
  //           return "/assets/images/c image holder@2x.jpg";
  //         default:
  //           return "/assets/images/square_placeholder.jpg";
  //       }
  //     case 4:
  //       switch (position) {
  //         case 1:
  //         case 4:
  //           return "/assets/images/b image holder@2x.jpg";
  //         case 2:
  //         case 3:
  //           return "/assets/images/b image holder@2x.jpg";
  //         default:
  //           return "/assets/images/square_placeholder.jpg";
  //       }
  //     case 5:
  //       switch (position) {
  //         case 1:
  //         case 5:
  //           return "/assets/images/d image holder@2x.jpg";
  //         case 3:
  //           return "/assets/images/square_placeholder.jpg";
  //         case 2:
  //         case 4:
  //           return "/assets/images/square_placeholder.jpg";
  //         default:
  //           return "/assets/images/square_placeholder.jpg";
  //       }
  //     case 6:
  //       switch (position) {
  //         case 2:
  //         case 5:
  //           return "/assets/images/d image holder@2x.jpg";
  //         case 1:
  //         case 6:
  //           return "/assets/images/square_placeholder.jpg";
  //         case 3:
  //         case 4:
  //           return "/assets/images/square_placeholder.jpg";
  //         default:
  //           return "/assets/images/square_placeholder.jpg";
  //       }
  //     case 7:
  //       switch (position) {
  //         case 1:
  //         case 4:
  //           return "/assets/images/square_placeholder.jpg";
  //         case 5:
  //         case 7:
  //           return "/assets/images/square_placeholder.jpg";
  //         case 6:
  //           return "/assets/images/d image holder@2x.jpg";
  //         default:
  //           return "/assets/images/square_placeholder.jpg";
  //       }
  //     case 8:
  //       switch (position) {
  //         case 1:
  //         case 2:
  //           return "/assets/images/square_placeholder.jpg";
  //         case 7:
  //           return "/assets/images/d image holder@2x.jpg";
  //         case 3:
  //           return "/assets/images/d image holder@2x.jpg";
  //         default:
  //           return "/assets/images/square_placeholder.jpg";
  //       }
  //     case 9:
  //       switch (position) {
  //         case 1:
  //           return "/assets/images/square_placeholder.jpg";
  //         case 2:
  //           return "/assets/images/d image holder@2x.jpg";
  //         case 3:
  //           return "/assets/images/square_placeholder.jpg";
  //         default:
  //           return "/assets/images/square_placeholder.jpg";
  //       }
  //     case 10:
  //       switch (position) {
  //         case 1:
  //         case 2:
  //           return "/assets/images/square_placeholder.jpg";
  //         case 3:
  //           return "/assets/images/square_placeholder.jpg";
  //         default:
  //           return "/assets/images/square_placeholder.jpg";
  //       }
  //     case 11:
  //       switch (position) {
  //         case 1:
  //         case 2:
  //           return "/assets/images/square_placeholder.jpg";
  //         case 3:
  //           return "/assets/images/square_placeholder.jpg";
  //         case 8:
  //           return "/assets/images/d image holder@2x.jpg";
  //         default:
  //           return "/assets/images/square_placeholder.jpg";
  //       }
  //     case 12:
  //       switch (position) {
  //         case 1:
  //         case 2:
  //         case 6:
  //         case 9:
  //           return "/assets/images/square_placeholder.jpg";
  //         case 3:
  //           return "/assets/images/square_placeholder.jpg";
  //         case 5:
  //           return "/assets/images/square_placeholder.jpg";
  //         default:
  //           return "/assets/images/square_placeholder.jpg";
  //       }
  //     default:
  //       return "/assets/images/square_placeholder.jpg";
  //   }
  // };

  const hasFormErrors = () => {
    const allErrors = Object.values(errors).map(
      (errObj) => errObj.error || false
    );
    let projectImgFlag = every(projectImages, "alterImageUri.croppedImage");
    const projectImage = projectImages.map((proj) => {
      if (proj.alterImageUri && proj.alterImageUri.croppedImage) {
        return true;
      }
      return false;
    });
    if (
      allErrors.includes(true) ||
      !title ||
      !projectImgFlag ||
      !imageData.imageUrl ||
      !projectImage.includes(true)
    ) {
      return true;
    }
    return false;
  };

  const hadDraftErrors = () => {
    const allErrors = Object.values(errors).map(
      (errObj) => errObj.error || false
    );
    const projectImage = projectImages.map((proj) => {
      if (proj.alterImageUri && proj.alterImageUri.croppedImage) {
        return true;
      }
      return false;
    });
    if (
      allErrors.includes(true) ||
      !title ||
      !imageData.imageUrl ||
      !projectImage.includes(true)
    ) {
      return true;
    }
    return false;
  };

  const returnErrorGrid = () => {
    let str = "";
    const emptyGrids = state.projectImages
      .map((each, index) => {
        if (!each.alterImageUri || !each.alterImageUri.croppedImage) {
          return index + 1;
        }
      })
      .filter((each) => each);

    emptyGrids.map((each, idx) => {
      if (idx === emptyGrids.length - 1) {
        return (str += each);
      }
      return (str += each + ",");
    });
    if (emptyGrids.length > 0) {
      errors.gridErrors = "Grid Image " + str;
      setState({
        ...state,
      });
    }
  };

  const clearCoverImg = () => () => {
    setState({ ...state, imageData: {} });
  };

  // Method responsible to return categorized menu items
  const renderCategorizedMenu = (menuData, type) => {
    // list of color codes to be appied for category tiles based on index
    const colorCodes = get(lookUpData, "configData.dropdownColorCodes", []);
    // fetching category names
    const groups = Object.keys(menuData).filter((each) => each !== "noParent");
    // pushing nonParent items to array
    let menuItems = menuData.noParent
      ? menuData.noParent.map((each) => each)
      : [];

    groups.map((eachGroup, index) => {
      // interating group titles and pushint to menu item array
      let subGroupItems = [{ name: eachGroup, color: colorCodes[index + 1] }];
      menuData[eachGroup].map((eachGroupItem) => {
        // iterating subgroup items
        subGroupItems.push(eachGroupItem);
      });
      // concatinating menuItems which are returned and categorized items
      menuItems = [...menuItems, ...subGroupItems];
      return null;
    });

    // mapping menuItems (objects) and returning JSX
    return menuItems.map((eachItem) => {
      return (
        <MenuItem
          style={{ background: eachItem.color }}
          onClick={!eachItem.color && handleDropdownSelect(type, eachItem)}
          className={eachItem.color && classes.textCapital}
        >
          {eachItem.name}
        </MenuItem>
      );
    });
  };

  const chooseNumber = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

  const hasError = hasFormErrors();
  const hasDraftError = hadDraftErrors();
  const { classes } = props;

  const gridLayouts = {
    a: {
      color: classes.gridstyle1_color,
      div: classes.gridstyle1_colordiv,
      icon: classes.gridstyle1_icon,
      top: 22,
    },
    b: {
      color: classes.gridstyle2_color,
      div: classes.gridstyle2_colordiv,
      icon: classes.gridstyle2_icon,
      top: 5,
    },
    c: {
      color: classes.gridstyle3_color,
      div: classes.gridstyle3_colordiv,
      icon: classes.gridstyle3_icon,
      top: 5,
    },
    d: {
      color: classes.gridstyle4_color,
      div: classes.gridstyle4_colordiv,
      icon: classes.gridstyle4_icon,
      top: 5,
    },
    e: {
      color: classes.gridstyle5_color,
      div: classes.gridstyle5_colordiv,
      icon: classes.gridstyle5_icon,
      top: 25,
    },
    f: {
      color: classes.gridstyle6_color,
      div: classes.gridstyle6_colordiv,
      icon: classes.gridstyle6_icon,
      top: 5,
    },
    g: {
      color: classes.gridstyle7_color,
      div: classes.gridstyle7_colordiv,
      icon: classes.gridstyle7_icon,
      top: 5,
    },
    h: {
      color: classes.gridstyle8_color,
      div: classes.gridstyle8_colordiv,
      icon: classes.gridstyle5_icon,
      top: 25,
    },
    i: {
      color: classes.gridstyle9_color,
      div: classes.gridstyle9_colordiv,
      icon: classes.gridstyle7_icon,
      top: 5,
    },
  };

  //TODO: Replace class names after recieving dimensions from client
  const returnPlaceHolderClass = (length, index, type) => {
    switch (length) {
      case 1:
        switch (index) {
          case 1:
            return gridLayouts.a[type];
          case 2:
            return gridLayouts.d[type];
          default:
            return;
        }
      case 2:
        switch (index) {
          case 1:
            return gridLayouts.b[type];
          case 2:
            return gridLayouts.b[type];
          default:
            return;
        }
      case 3:
        switch (index) {
          case 1:
            return gridLayouts.d[type];
          case 2:
            return gridLayouts.d[type];
          case 3:
            return gridLayouts.c[type];
          default:
            return;
        }
      case 4:
        switch (index) {
          case 1:
            return gridLayouts.c[type];
          case 2:
            return gridLayouts.g[type];
          case 3:
            return gridLayouts.g[type];
          case 4:
            return gridLayouts.c[type];
          default:
            return;
        }

      case 5:
        switch (index) {
          case 1:
            return gridLayouts.e[type];
          case 2:
            return gridLayouts.b[type];
          case 3:
            return gridLayouts.c[type];
          case 4:
            return gridLayouts.b[type];
          case 5:
            return gridLayouts.e[type];
          default:
            return;
        }

      case 6:
        switch (index) {
          case 1:
            return gridLayouts.b[type];
          case 2:
            return gridLayouts.e[type];
          case 3:
            return gridLayouts.g[type];
          case 4:
            return gridLayouts.g[type];
          case 5:
            return gridLayouts.e[type];
          case 6:
            return gridLayouts.b[type];
          default:
            return;
        }
      case 7:
        switch (index) {
          case 1:
            return gridLayouts.g[type];
          case 2:
            return gridLayouts.b[type];
          case 3:
            return gridLayouts.b[type];
          case 4:
            return gridLayouts.g[type];
          case 5:
            return gridLayouts.f[type];
          case 6:
            return gridLayouts.h[type];
          case 7:
            return gridLayouts.f[type];
          default:
            return;
        }
      case 8:
        switch (index) {
          case 1:
            return gridLayouts.g[type];
          case 2:
            return gridLayouts.f[type];
          case 3:
            return gridLayouts.a[type];
          case 4:
            return gridLayouts.g[type];
          case 5:
            return gridLayouts.f[type];
          case 6:
            return gridLayouts.f[type];
          case 7:
            return gridLayouts.d[type];
          case 8:
            return gridLayouts.g[type];
          default:
            return;
        }
      case 9:
        switch (index) {
          case 1:
            return gridLayouts.g[type];
          case 2:
            return gridLayouts.d[type];
          case 3:
            return gridLayouts.f[type];
          case 4:
            return gridLayouts.i[type];
          case 5:
            return gridLayouts.i[type];
          case 6:
            return gridLayouts.f[type];
          case 7:
            return gridLayouts.i[type];
          case 8:
            return gridLayouts.i[type];
          case 9:
            return gridLayouts.g[type];
          default:
            return;
        }
      case 10:
        switch (index) {
          case 1:
            return gridLayouts.f[type];
          case 2:
            return gridLayouts.i[type];
          case 3:
            return gridLayouts.g[type];
          case 4:
            return gridLayouts.f[type];
          case 5:
            return gridLayouts.i[type];
          case 6:
            return gridLayouts.i[type];
          case 7:
            return gridLayouts.g[type];
          case 8:
            return gridLayouts.i[type];
          case 9:
            return gridLayouts.g[type];
          case 10:
            return gridLayouts.g[type];
          default:
            return;
        }
      case 11:
        switch (index) {
          case 1:
            return gridLayouts.b[type];
          case 2:
            return gridLayouts.i[type];
          case 3:
            return gridLayouts.i[type];
          case 4:
            return gridLayouts.i[type];
          case 5:
            return gridLayouts.b[type];
          case 6:
            return gridLayouts.g[type];
          case 7:
            return gridLayouts.b[type];
          case 8:
            return gridLayouts.e[type];
          case 9:
            return gridLayouts.i[type];
          case 10:
            return gridLayouts.g[type];
          case 11:
            return gridLayouts.g[type];
          default:
            return;
        }
      case 12:
        switch (index) {
          case 1:
            return gridLayouts.g[type];
          case 2:
            return gridLayouts.i[type];
          case 3:
            return gridLayouts.i[type];
          case 4:
            return gridLayouts.i[type];
          case 5:
            return gridLayouts.a[type];
          case 6:
            return gridLayouts.g[type];
          case 7:
            return gridLayouts.i[type];
          case 8:
            return gridLayouts.i[type];
          case 9:
            return gridLayouts.g[type];
          case 10:
            return gridLayouts.i[type];
          case 11:
            return gridLayouts.b[type];
          case 12:
            return gridLayouts.i[type];
          default:
            return;
        }
      default:
        return classes.rectangle_place;
    }
  };

  const openMarketModal = () => {
    dispatch(SettingsActions.isOpenMarketModal(true));
    history.push("/portfolioView");
    localStorage.setItem("openMarketModal", true);
  };

  return (
    <>
      {individualProjectData && state.isEdit ? (
        <div className={classes.displayProjectTxtBlock}>
          {!get(portfolioData, "isVisibleToPublic") ? (
            <Row className={classes.hoverIconBlock}>
              <Text
                size={16}
                color={color.primary_palette.black}
                family="avenir_sb"
              >
                This is Project #{indexVal + 1} in Your Portfolio{" "}
                <span>{get(portfolioData, "name", "")}</span>. To make Public,
                click here
              </Text>
              <Location
                className={`${classes.markerAlign} hover_icon`}
                onClick={openMarketModal}
              />{" "}
            </Row>
          ) : (
            <Text
              size={16}
              color={color.primary_palette.black}
              family="avenir_sb"
            >
              This is Project #{indexVal + 1} in Your Portfolio{" "}
              <span>{get(portfolioData, "name", "")}</span>. To make Public,
              click here
            </Text>
          )}
        </div>
      ) : (
        <div className={classes.displayProjectTxtBlock}>
          <Row className={classes.hoverIconBlock}>
            <Text
              size={16}
              color={color.primary_palette.black}
              family="avenir_sb"
            >
              This is Project #{get(portfolioData, "projects.length", 0) + 1} in
              Your Portfolio <span>{get(portfolioData, "name", "")}</span>. To
              make Public, click here
            </Text>
            <Location
              className={`${classes.markerAlign} hover_icon`}
              onClick={openMarketModal}
            />{" "}
          </Row>
        </div>
      )}
      <Row className={classes.justifyCenter}>
        <div>
          <div className={classes.sample_image_block}>
            <div className={classes.editModeBlock}>
              <Text
                size={12}
                color={color.primary_palette.black}
                family="avenir_black_r"
                className={classes.editBlockStyle}
              >
                {strings.add_project.titles.edit_mode}
              </Text>
              <Row>
                <CustomButton
                  className={`${classes.custom_edit_btn_styles} ${classes.marginRight7}`}
                  onClick={redirectToPortfolio}
                >
                  {strings.add_project.titles.main_portfolio}
                </CustomButton>
                <CustomButton className={classes.current_btn_style}>
                  {strings.add_project.titles.this_project}
                </CustomButton>
              </Row>
            </div>
            <img src="/assets/images/sample project.PNG" alt="" />
          </div>
          <div className={classes.fixedBlock}>
            {get(state, "chooseNumber", "") && (
              <div className={classes.grid_display}>
                <div className={classes.marginLeft30}>
                  <Text
                    size={20}
                    color={color.greyish_brown}
                    family="avenir_sb"
                  >
                    {strings.add_project.titles.my_grid}
                  </Text>
                </div>
                <div className={classes.gird_block}>
                  {projectInfoData(state.chooseNumber)}
                </div>
                {state.isEdit && individualProjectData ? (
                  ""
                ) : (
                  <div className={classes.marginLeft30}>
                    <Text
                      size={16}
                      color={color.primary_palette.black}
                      family="avenir_bold"
                      className={classes.inlineBlock}
                      transform="UPPERCASE"
                    >
                      You have filled in&nbsp;
                      <Text
                        size={16}
                        color={color.primary_palette.franklin_purple}
                        family="avenir_bold"
                        className={`${classes.inlineBlock} ${classes.color_purple}`}
                        transform="UPPERCASE"
                      >
                        {returnTotalUploadedImages()}/
                        {get(state, "chooseNumber", "")}
                      </Text>{" "}
                      pictures in your grid
                    </Text>
                  </div>
                )}
                {state.isEdit && individualProjectData && (
                  <div className={classes.textCenter}>
                    <CustomButton
                      className={classes.grid_button}
                      onClick={handleopenRadioAlter}
                    >
                      ALTER MY GRID
                    </CustomButton>
                  </div>
                )}
              </div>
            )}
            <div className={classes.leftDraftBtnAlign}>
              <Row className={classes.error}>{errorMessage}</Row>
              {(get(state, "errors.title.error") ||
                get(state, "errors.imageData.error") ||
                (get(state, "errors.gridErrors") &&
                  get(state, "errors.gridErrors") !== "Grid Image")) && (
                <div className={classes.errorDivLeft}>
                  <div>
                    <span>REQUIRED</span>{" "}
                    <img
                      src="/assets/icons/info_1.svg"
                      class="info_img"
                      alt=""
                    />
                  </div>
                  {get(state, "errors.title.error") && <div>Project Title</div>}
                  {get(state, "errors.imageData.error") && (
                    <div>Project Cover Image </div>
                  )}
                  <div>{get(state, "errors.gridErrors")}</div>
                  {get(state, "errors.gridErrors") && (
                    <div className={`${classes.error} ${classes.textCenter}`}>
                      <Element_Required_Icon
                        className={classes.error_red_icon}
                      />
                      Please add the required pictures, or choose a different
                      grid with fewer images.
                    </div>
                  )}
                </div>
              )}
            </div>
            <div className={classes.leftDraftBtnAlign}>
              <CustomButton
                onMouseUp={saveProjectImageData("draft", true)}
                className={classes.draft_btn}
              >
                {strings.add_project.titles.save_draft}
              </CustomButton>
            </div>
          </div>
        </div>
        <img
          src="/assets/images/Beta_Stamp.png"
          className={classes.betaStampIcon}
          alt=""
          onClick={handleFeedbackModal}
        />
        <div className={classes.add_project}>
          <div className={`${classes.editModeBlock} ${classes.publicAlign}`}>
            <Text
              size={12}
              color={color.primary_palette.black}
              family="avenir_black_r"
              className={classes.editBlockStyle}
            >
              {strings.add_project.titles.public_previews}
            </Text>
            <Row>
              <CustomButton
                className={`${
                  individualProjectData && state.isEdit
                    ? classes.custom_public_btn_styles
                    : classes.custom_public_btn_styles_center
                } ${
                  individualProjectData && state.isEdit && classes.marginRight7
                }`}
                onClick={previewEntirePort(individualProjectData)}
              >
                {strings.add_project.titles.this_portfolio}
              </CustomButton>
              {individualProjectData && state.isEdit && (
                <CustomButton
                  className={classes.custom_public_btn_styles}
                  onClick={redirectIndividual(individualProjectData, false)}
                >
                  {strings.add_project.titles.this_project}
                </CustomButton>
              )}
            </Row>
          </div>
          {individualProjectData && state.isEdit && (
            <Edit_Circle_Icon className={classes.edit_icon} />
          )}
          <Row className={`${classes.line_spacing} ${classes.marginTop}`}>
            <span className={classes.line}></span>
            <Text
              size={25}
              color={color.primary_palette.franklin_purple}
              family="OptimaLT_bold"
              className={`${classes.project_text} ${classes.optimal_font}`}
            >
              {individualProjectData && state.isEdit
                ? strings.add_project.titles.project_summary
                : strings.add_project.titles.add_project}
            </Text>
            <span className={classes.line}></span>
          </Row>
          <div className={classes.add_project_block}>
            <div className={classes.project_title}>
              <Text
                size={18}
                color={
                  get(state.errors, "title.error", "")
                    ? color.primary_palette.christmas_red
                    : color.primary_palette.franklin_purple
                }
                family="avenir_bold"
              >
                <span>
                  <Element_Required_Icon
                    className={
                      get(state.errors, "title.error", "")
                        ? classes.red_required
                        : classes.required_icon
                    }
                  />
                </span>
                <span className={classes.marginLeft4}>
                  {strings.add_project.titles.project_title}
                </span>
              </Text>
              <CustomInputCount
                placeholder={
                  strings.add_project.titles.project_titlePlaceholder
                }
                enableCount
                countValue={45}
                placementnumber={classes.num}
                className={`${
                  get(state.errors, "title.error", "")
                    ? classes.red_project_title_input
                    : classes.project_title_input
                } ${classes.upperCase}`}
                onBlur={handleInputValueChange}
                name="title"
                defaultValue={title}
                error={get(state.errors, "title.error", "")}
              />
            </div>
            <Row>
              <div
                className={`${classes.project_subtitle} ${classes.project_subtitle_spacing}`}
              >
                <Text
                  size={18}
                  color={color.primary_palette.franklin_purple}
                  family="avenir_bold"
                >
                  {strings.add_project.titles.style_monkier}
                  <Text
                    size={18}
                    color={color.primary_palette.franklin_purple}
                    family="avenir_sb"
                    className={classes.displayInline}
                  >
                    {strings.add_project.titles.subtitle_sugg}
                  </Text>
                </Text>
                <CustomInputCount
                  placeholder={
                    strings.add_project.titles.project_subtitlePlaceholder
                  }
                  className={classes.project_title_input}
                  onBlur={handleInputValueChange}
                  name="subtitle"
                  defaultValue={subtitle}
                />
              </div>
              <div className={classes.project_subtitle}>
                <Text
                  size={18}
                  color={color.primary_palette.franklin_purple}
                  family="avenir_bold"
                >
                  {strings.add_project.titles.project_location}
                </Text>
                <CustomInputCount
                  placeholder={
                    strings.add_project.titles.project_locationPlaceholder
                  }
                  className={classes.project_title_input}
                  onBlur={handleInputValueChange}
                  name="streetAddress"
                  defaultValue={streetAddress}
                />
              </div>
            </Row>
            <div className={classes.subtile_spacing}>
              <Text
                size={18}
                color={
                  get(state.errors, "description.error", "")
                    ? color.primary_palette.christmas_red
                    : color.primary_palette.franklin_purple
                }
                family="avenir_bold"
              >
                {strings.add_project.titles.project_description}
              </Text>
              <div className={classes.positionRelative}>
                <Text
                  size={12}
                  family="avenir_sb"
                  color={color.quil_placeholder_color}
                  className={classes.placeholder1Align}
                >
                  {strings.add_project.titles.editor_placeholder}
                </Text>
                <ReactQuill
                  theme={"snow"}
                  className={classes.textArea}
                  name={"description"}
                  value={description}
                  onChange={setDescription}
                />
                <div
                  className={
                    returnRemainingLimit(
                      get(description.replace(REMOVE_TAGS, ""), "length", 0),
                      1000
                    ) <= 50
                      ? returnRemainingLimit(
                          get(
                            description.replace(REMOVE_TAGS, ""),
                            "length",
                            0
                          ),
                          1000
                        ) <= 0
                        ? classes.countAlign_negative
                        : classes.countAlign_warning
                      : classes.countAlign
                  }
                >
                  {returnRemainingLimit(
                    get(description.replace(REMOVE_TAGS, ""), "length", 0),
                    1000
                  )}
                </div>
              </div>
            </div>
            <Row>
              <div className={classes.dropdown_selection_width}>
                <Row className={`${classes.field} ${classes.spacing_17}`}>
                  <Text
                    size={18}
                    color={color.primary_palette.franklin_purple}
                    family="avenir_bold"
                    className={classes.project_subtitle}
                  >
                    {strings.add_project.titles.venue}
                  </Text>
                  <CustomSelectProj
                    className={classes.dropdownWidthSub}
                    IconComponent={Chevron_Down_Icon}
                    onChange={handleInputChange}
                    name="venue"
                    value={venue}
                    isProjPage={true}
                    renderValue={(value) =>
                      get(value, "name", "Choose up to 5")
                    }
                    MenuProps={{
                      getContentAnchorEl: null,
                      disableScrollLock: true,
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                    }}
                  >
                    <CustomScrollbars
                      className={`${classes.customScrollHeight} ${classes.clientele}`}
                    >
                      {projectSelectionData &&
                        projectSelectionData.venue &&
                        renderCategorizedMenu(
                          projectSelectionData.venue,
                          "venue"
                        )}

                      {/* {get(projectSelectionData, "venue", "") &&
                        map(projectSelectionData.venue, (venueItem) => {
                          return (
                            <MenuItem
                              onClick={handleDropdownSelect("venue", venueItem)}
                            >
                              {venueItem.name}
                            </MenuItem>
                          );
                        })} */}
                    </CustomScrollbars>
                    <Row className={classes.inputContainer}>
                      <input
                        type="text"
                        placeholder="ADD YOUR OWN"
                        className={classes.inputField}
                        value={newOptionValue}
                        onChange={(e) => {
                          if (e.target.value.length > 25) {
                            return;
                          }
                          setState({
                            ...state,
                            newOptionValue: e.target.value,
                          });
                        }}
                      />
                      <p className={classes.plus} onClick={addTitle("venue")}>
                        <div className={`${classes.iconPlusStyle}`} />
                      </p>
                    </Row>
                  </CustomSelectProj>
                </Row>
                <Row className={classes.dropdown_margin}>
                  {map(venue, (eachVenue) => {
                    return (
                      <div className={classes.listAlign}>
                        {eachVenue.name}
                        <img
                          src="/assets/images/sketched-x.png"
                          onClick={handleDropdownSelect(
                            "venue",
                            eachVenue,
                            "del"
                          )}
                          className={classes.crossIconStyle}
                          alt=""
                        />
                      </div>
                    );
                  })}
                </Row>

                <Row className={`${classes.field} ${classes.spacing_17}`}>
                  <Text
                    size={18}
                    color={color.primary_palette.franklin_purple}
                    family="avenir_bold"
                    className={classes.project_subtitle}
                  >
                    {strings.add_project.titles.style}
                  </Text>

                  <CustomSelectProj
                    className={classes.dropdownWidthSub}
                    IconComponent={Chevron_Down_Icon}
                    onChange={handleInputChange}
                    name="style"
                    isProjPage={true}
                    value={style}
                    renderValue={(value) =>
                      get(value, "name", "Choose up to 5")
                    }
                    MenuProps={{
                      getContentAnchorEl: null,
                      disableScrollLock: true,
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                    }}
                  >
                    <CustomScrollbars
                      className={`${classes.customScrollHeight} ${classes.styles_dropdown}`}
                    >
                      {get(projectSelectionData, "styles", "") &&
                        map(projectSelectionData.styles, (styleItem) => {
                          return (
                            <MenuItem
                              onClick={handleDropdownSelect("style", styleItem)}
                            >
                              {styleItem.name}
                            </MenuItem>
                          );
                        })}
                    </CustomScrollbars>
                    <Row className={classes.inputContainer}>
                      <input
                        type="text"
                        placeholder="ADD YOUR OWN"
                        className={classes.inputField}
                        value={newOptionValue}
                        onChange={(e) => {
                          if (e.target.value.length > 25) {
                            return;
                          }
                          setState({
                            ...state,
                            newOptionValue: e.target.value,
                          });
                        }}
                      />
                      <p className={classes.plus} onClick={addTitle("style")}>
                        <div className={`${classes.iconPlusStyle}`} />
                      </p>
                    </Row>
                  </CustomSelectProj>
                </Row>
                <Row className={classes.dropdown_margin}>
                  {map(style, (eachStyle) => {
                    return (
                      <div className={classes.listAlign}>
                        {eachStyle.name}
                        <img
                          src="/assets/images/sketched-x.png"
                          onClick={handleDropdownSelect(
                            "style",
                            eachStyle,
                            "del"
                          )}
                          className={classes.crossIconStyle}
                          alt=""
                        />
                      </div>
                    );
                  })}
                </Row>
                <Row className={`${classes.field} ${classes.spacing_17}`}>
                  <Text
                    size={18}
                    color={color.primary_palette.franklin_purple}
                    family="avenir_bold"
                    className={classes.ballpark_cost}
                  >
                    {strings.add_project.titles.setting}
                  </Text>
                  <CustomSelectProj
                    className={classes.dropdownWidth}
                    IconComponent={Chevron_Down_Icon}
                    onChange={handleInputChange}
                    name="setting"
                    isProjPage={true}
                    value={setting}
                    renderValue={(value) =>
                      get(value, "name", "Choose up to 5")
                    }
                    MenuProps={{
                      getContentAnchorEl: null,
                      disableScrollLock: true,
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                    }}
                  >
                    <CustomScrollbars
                      className={`${classes.customScrollHeight} ${classes.settings_vibes}`}
                    >
                      {projectSelectionData &&
                        projectSelectionData.settings &&
                        renderCategorizedMenu(
                          projectSelectionData.settings,
                          "setting"
                        )}
                    </CustomScrollbars>
                    <Row className={classes.inputContainer}>
                      <input
                        type="text"
                        placeholder="ADD YOUR OWN"
                        className={classes.inputField}
                        value={newOptionValue}
                        onChange={(e) => {
                          if (e.target.value.length > 25) {
                            return;
                          }
                          setState({
                            ...state,
                            newOptionValue: e.target.value,
                          });
                        }}
                      />
                      <p className={classes.plus} onClick={addTitle("setting")}>
                        <div className={`${classes.iconPlusStyle}`} />
                      </p>
                    </Row>
                  </CustomSelectProj>
                </Row>
                <Row className={classes.dropdown_margin}>
                  {map(setting, (eachSetting) => {
                    return (
                      <div className={classes.listAlign}>
                        {eachSetting.name}
                        <img
                          src="/assets/images/sketched-x.png"
                          onClick={handleDropdownSelect(
                            "setting",
                            eachSetting,
                            "del"
                          )}
                          className={classes.crossIconStyle}
                          alt=""
                        />
                      </div>
                    );
                  })}
                </Row>
                <Row className={`${classes.field1} ${classes.spacing_17}`}>
                  <Text
                    size={18}
                    color={color.primary_palette.franklin_purple}
                    family="avenir_bold"
                    className={`${classes.project_subtitle} ${classes.ballpark_cost}`}
                  >
                    {strings.add_project.titles.ballpark_cost}
                  </Text>
                  <CustomSelect
                    className={classes.dropdownWidth}
                    IconComponent={Chevron_Down_Icon}
                    onChange={handleInputChange}
                    name="ballparkCost"
                    value={ballparkCost}
                    renderValue={(value) =>
                      get(value, "name", "Choose") || "Choose"
                    }
                    MenuProps={{
                      getContentAnchorEl: null,
                      disableScrollLock: true,
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                    }}
                  >
                    <CustomScrollbars
                      className={`${classes.custom_ballparkCost}`}
                    >
                      {projectSelectionData &&
                        projectSelectionData.ballparkCost &&
                        map(
                          projectSelectionData.ballparkCost,
                          (ballparkCostVal) => {
                            return (
                              <MenuItem
                                onClick={handleDropdownSelectValue(
                                  "ballparkCost",
                                  ballparkCostVal
                                )}
                              >
                                {ballparkCostVal.name}
                              </MenuItem>
                            );
                          }
                        )}
                    </CustomScrollbars>
                  </CustomSelect>
                </Row>
              </div>
              <div>
                <AddImage
                  key={get(imageData, "imageUrl", "")}
                  defaultImage="/assets/images/projectcover.PNG"
                  className={classes.rectangle}
                  name="coverimageUri"
                  croppedImage={get(imageData, "imageUrl", "")}
                  imageUpload={setCroppedImage}
                  type="coverImage"
                  onClear={clearCoverImg}
                  originalImg={get(imageData, "originalImageUrl", "")}
                  hasImageData={get(imageData, "imageUrl", "")}
                  cropSize={1.7 / 1}
                  transparentColor={color.primary_palette.white}
                />
                <Text
                  size={16}
                  color={
                    get(state.errors, "imageData.error", "")
                      ? color.primary_palette.christmas_red
                      : color.primary_palette.black
                  }
                  family="gillsans_sb"
                >
                  <Element_Required_Icon
                    className={
                      get(state.errors, "imageData.error", "")
                        ? classes.required_cover_error
                        : classes.required_cover
                    }
                  />
                  {strings.add_project.titles.your_project_cover_image}
                </Text>
              </div>
            </Row>
            <div className={`${classes.hashtags} ${classes.padding_top_33}`}>
              <Text
                size={18}
                color={color.primary_palette.franklin_purple}
                family="avenir_bold"
              >
                {strings.add_project.titles.hashtags}
              </Text>
              <CustomInputCount
                placeholder={strings.add_project.titles.hashtags_placeholder}
                className={classes.project_title_input}
                onBlur={handleInputValueChange}
                name="tags"
                defaultValue={tags}
              />
            </div>
          </div>

          <div className={classes.add_project_space}>
            {/* done for the edit add project  */}
            <div className={classes.edit_project}>
              <Row className={classes.edit_line_spacing}>
                <span className={classes.edit_line}></span>
                <Text
                  size={25}
                  color={color.primary_palette.franklin_purple}
                  family="OptimaLT"
                  className={`${classes.project_text} ${classes.optimal_font}`}
                >
                  {strings.add_project.titles.edit_project_images}
                </Text>
                <span className={classes.edit_line}></span>
              </Row>
              <Text
                size={16}
                color={color.primary_palette.black}
                family="avenir_roman"
                className={classes.textCenter}
              >
                {strings.add_project.titles.edit_project_images_des}
              </Text>
            </div>
            <Text
              size={18}
              color={
                get(state.errors, "projectImage.error", "")
                  ? color.primary_palette.christmas_red
                  : color.primary_palette.franklin_purple
              }
              family="avenir_bold"
              className={
                state.isEdit
                  ? classes.space_padding_edit
                  : classes.space_padding
              }
            >
              <Element_Required_Icon
                className={
                  get(state.errors, "projectImage.error", "")
                    ? classes.required_cover_error
                    : classes.required_cover
                }
              />
              {strings.add_project.titles.add_project_des}
            </Text>
            <Row>
              <Text
                size={16}
                color={color.primary_palette.franklin_purple}
                family="NeutraText_bold"
                className={classes.neutra_text}
              >
                {strings.add_project.titles.step1}
              </Text>
              <div className={classes.step1_des_spacing}>
                <Text
                  size={16}
                  color={color.primary_palette.franklin_purple}
                  family="avenir_bold"
                >
                  {strings.add_project.titles.step1_des1}
                </Text>
                <Text
                  size={16}
                  color={color.primary_palette.black}
                  family="gillsans_r"
                >
                  {strings.add_project.titles.step1_des2}
                </Text>
              </div>
              <div>
                <CustomSelect
                  className={
                    get(state.errors, "projectImage.error", "")
                      ? classes.choose_number_error
                      : classes.choose_number
                  }
                  renderValue={(value) => value}
                  value={state.chooseNumber || "Choose"}
                  isAddProj={true}
                  IconComponent={Chevron_Down_Icon}
                  MenuProps={{
                    getContentAnchorEl: null,
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                  }}
                >
                  <CustomScrollbars className={`${classes.chooseNumber}`}>
                    {chooseNumber.map((chooseNumberVal) => (
                      <MenuItem
                        // onClick={closeAlterGridChoose}
                        onMouseUp={handleNumberDropdown(
                          "chooseNumber",
                          chooseNumberVal
                        )}
                      >
                        {chooseNumberVal}
                      </MenuItem>
                    ))}
                  </CustomScrollbars>
                </CustomSelect>
              </div>
              {!get(state, "chooseNumber", "") && (
                <img
                  src="/assets/icons/Purple Arrow Graphic Left.svg"
                  className={classes.leftGraphicArrow}
                  alt=""
                />
              )}
            </Row>

            {get(state, "chooseNumber", "") && (
              <Row>
                <Text
                  size={16}
                  color={color.primary_palette.franklin_purple}
                  family="NeutraText_bold"
                  className={classes.neutra_text}
                >
                  {strings.add_project.titles.step2}
                </Text>
                <div className={classes.step2_des_spacing}>
                  <Text
                    size={16}
                    color={color.primary_palette.black}
                    family="gillsans_r"
                  >
                    {strings.add_project.titles.step2_desc}
                  </Text>
                  <Text
                    size={16}
                    color={color.primary_palette.black}
                    family="gillsans_r"
                  >
                    {strings.add_project.titles.step2_desc_line}
                  </Text>
                </div>
              </Row>
            )}
            {map(projectImages, (section, index) => {
              return (
                <Row>
                  <Text
                    size={16}
                    color={color.primary_palette.black}
                    family="avenir_bold"
                    className={classes.grid_text}
                  >
                    GRID
                    <br /> IMAGE <br />{" "}
                    <div className={classes.gridTextStyle}>{index + 1}</div>
                  </Text>
                  <ProjectAddImag
                    key={section}
                    data={section}
                    handleChange={handleInputChange}
                    handleDropDownChange={handleProjectImgDropdown}
                    isCover={isCover}
                    deleteProject={deleteProject}
                    isProjImage={get(state.errors, "projectImage.error")}
                    addTitle={addProjectTitle}
                    stateValue={state}
                    uploadImageLength={projectImages.length}
                    onClear={onClear}
                    idx={index + 1}
                    imageUpload={projectImageUpload}
                    cropSize={returnCropperSize(
                      projectImages.length,
                      index + 1
                    )}
                    // defaltPlaceholder={returnPlaceHolder(
                    //   projectImages.length,
                    //   index + 1
                    // )}
                    plus_bgcolor={returnPlaceHolderClass(
                      projectImages.length,
                      index + 1,
                      "color"
                    )}
                    color_div={returnPlaceHolderClass(
                      projectImages.length,
                      index + 1,
                      "div"
                    )}
                    iconStyle={returnPlaceHolderClass(
                      projectImages.length,
                      index + 1,
                      "icon"
                    )}
                    requiredIconStyle={returnPlaceHolderClass(
                      projectImages.length,
                      index + 1,
                      "top"
                    )}
                  />
                </Row>
              );
            })}
            <Row className={classes.error}>{errorMessage}</Row>
            {(get(state, "errors.title.error") ||
              get(state, "errors.imageData.error") ||
              (get(state, "errors.gridErrors") &&
                get(state, "errors.gridErrors") !== "Grid Image")) && (
              <div className={classes.errorDiv}>
                <div>
                  <span>REQUIRED</span>{" "}
                  <img src="/assets/icons/info_1.svg" class="info_img" alt="" />
                </div>
                {get(state, "errors.title.error") && <div>Project Title</div>}
                {get(state, "errors.imageData.error") && (
                  <div>Project Cover Image </div>
                )}
                <div>{get(state, "errors.gridErrors")}</div>
                {get(state, "errors.gridErrors") && (
                  <div className={`${classes.error} ${classes.textCenter}`}>
                    <Element_Required_Icon className={classes.error_red_icon} />
                    Please add the required pictures, or choose a different grid
                    with fewer images.
                  </div>
                )}
              </div>
            )}
            {!(individualProjectData && state.isEdit) ? (
              <Row className={classes.btnAlign}>
                <Row className={`${classes.flex_grow}`}>
                  <CustomButton className={classes.back_btn} onMouseUp={goBack}>
                    {strings.add_project.titles.back}
                  </CustomButton>
                </Row>
                <Row className={`${classes.flex_grow} ${classes.justifyEnd}`}>
                  <CustomButton
                    onMouseUp={saveProjectImageData("draft", true)}
                    className={classes.draft_btn}
                  >
                    {strings.add_project.titles.save_draft}
                  </CustomButton>
                  <CustomButton
                    onMouseUp={saveProjectImageData("save", true)}
                    className={classes.save_btn}
                  >
                    {strings.add_project.titles.save_continue}
                  </CustomButton>
                </Row>
              </Row>
            ) : (
              <Row className={classes.btnAlign}>
                <Row
                  className={`${classes.flex_grow} ${classes.justifyCenter}`}
                >
                  <CustomButton
                    onMouseUp={saveProjectImageData("draft", true)}
                    className={classes.draft_btn}
                  >
                    {strings.add_project.titles.save_draft}
                  </CustomButton>
                  <CustomButton
                    onMouseUp={saveProjectImageData("save", true)}
                    className={classes.edit_save_btn}
                  >
                    {strings.add_project.titles.save}
                  </CustomButton>
                </Row>
              </Row>
            )}
          </div>
        </div>
      </Row>
      <CustomModal open={openAlterGridChoose} onClose={closeAlterModal}>
        <AlterConfirmation
          handleLayoutPopup={handleLayoutPopup}
          handleLayoutPopup1={handleLayoutPopup1}
          onClose={closeAlterModal}
          chooseNumber={state.projectImages_quantity}
          isAlterEdit={isEditAlter}
          chooseNumber={state.projectImages_quantity}
          layoutlength={projectImages.length}
          type="altergrid"
        />
      </CustomModal>
      <CustomModal
        open={toggleFeedbackModal}
        onClose={handleFeedbackModal}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Feedback
          open={toggleFeedbackModal}
          onClose={handleFeedbackModal}
          locationInfo="Add Project Page"
        />
      </CustomModal>
    </>
  );
}

export default AddprojectStyles(AddProjects);
