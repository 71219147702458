import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { get, map } from "lodash";
import { useHistory, useRouteMatch } from "react-router-dom";

import SettingNavStyles from "./styles/settingNavStyles";
import Row from "../common/ui_kit/row";
import Text from "../common/ui_kit/text";
import strings from "../../utilities/strings";
import { color } from "../../utilities/themes";
import CustomButton from "../navigations/custom_buttons";
import Lil_Plus_filled from "../data_display/icons/Lil_Plus_filled";
import PersonalSettings from "./personal_settings";
import CompanyBasics from "./company_basics";
import PortfolioSetting from "./portfolio_setting";
import OfficeLocations from "./office_locations";
import PurchaseHistory from "./purchase_history";

import { SettingsActions, ProfileActions } from "../../redux/actions";
import {
  FRANKLIN_QA,
  FRANKLIN_STAGE,
  LLAMA_DEV_URL,
  LLAMA_QA_URL,
  LLAMA_STAGE_URL,
  LLAMA_PROD_URL,
  FRANKLIN_PROD,
} from "../../constants";
import CustomModal from "../inputs/custom_modal";
import Feedback from "../modals/feedbackModal";

function SettingNav(props) {
  const { classes } = props;
  const { params } = useRouteMatch();
  const historyTable = useRef(null);
  const [state, setState] = useState({
    companyInfo: "",
    selectedType: "",
    toggleFeedbackModal: false,
  });
  const {
    companyInfo,
    portfolioLength,
    publicPortfoliosLength,
    selectedType,
    toggleFeedbackModal,
  } = state;

  const dispatch = useDispatch();
  const history = useHistory();

  const CompanyListByUser = useSelector(
    (resData) => resData.Settings.CompanyListByUser
  );

  const personalSettingsData = useSelector(
    (resData) => resData.Profile.personalSettingData
  );

  const CompanyBasicData = useSelector(
    (resData) => resData.Settings.CompanyBasicData
  );

  const CompanyPortfolioData = useSelector(
    (resData) => resData.Settings.CompanyPortfolioData
  );

  const lookUpData = useSelector((resData) => resData.LookUp.LookUpData);

  const impersonate = localStorage.getItem("impersonate");
  //API
  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (CompanyPortfolioData && CompanyPortfolioData.portfolios) {
      let publicPortfolios =
        CompanyPortfolioData &&
        CompanyPortfolioData.portfolios.filter(
          (each) => each.isVisibleToPublic
        );
      setState({
        ...state,
        portfolioLength: get(CompanyPortfolioData, "portfolios.length", 0),
        publicPortfoliosLength: get(publicPortfolios, "length", 0),
      });
    }
  }, [CompanyPortfolioData]);

  const fetchData = () => {
    dispatch(SettingsActions.getCompanyDataByUser());
    dispatch(ProfileActions.getPersonalSettingData());
    dispatch(
      SettingsActions.getCompanyBasicsData(localStorage.getItem("companyId"))
    );
  };

  const returnComponent = () => {
    const { type } = params;
    switch (type) {
      case "personal":
        return <PersonalSettings />;
      case "company":
        return <CompanyBasics companyInfo={companyInfo} />;
      case "portfolios":
        return <PortfolioSetting historyTable={historyTable} />;
      case "locations":
        return <OfficeLocations />;
      case "history":
        return <PurchaseHistory />;
      default:
        return <PersonalSettings />;
    }
  };

  const redirectNewPortfolo = () => {
    history.push("/NewPortfolio");
  };

  const redirectToPortfolio = (id, name) => () => {
    localStorage.setItem("portfolioId", id);
    localStorage.setItem("portfolioName", name);
    localStorage.removeItem("isPortfolioPublic");
    history.push("/portfolioView");
  };

  const scrollToHistoy = () => {
    historyTable.current.scrollIntoView();
  };

  const addDefaultSrc = (ev) => {
    ev.target.src = "/assets/images/Gray Star.svg";
  };

  const handleMenuClick = () => {
    let host = get(window, "location.host", "");

    let frLoginHost = "";
    let llamaLoginHost = "";
    if (host) {
      if (host.includes("localhost")) {
        frLoginHost = FRANKLIN_QA;
        llamaLoginHost = "localhost:3005";
      } else if (host.includes("-dev-")) {
        frLoginHost = FRANKLIN_QA;
        llamaLoginHost = LLAMA_DEV_URL;
      } else if (host.includes("-qa-")) {
        frLoginHost = FRANKLIN_QA;
        llamaLoginHost = LLAMA_QA_URL;
      } else if (host.includes("-stage-")) {
        frLoginHost = FRANKLIN_STAGE;
        llamaLoginHost = LLAMA_STAGE_URL;
      } else if (host.includes("franklinreport")) {
        frLoginHost = FRANKLIN_PROD;
        llamaLoginHost = LLAMA_PROD_URL;
      }
    }
    const isLoggedIn = localStorage.getItem("userType");
    window.location.href = !isLoggedIn
      ? `${frLoginHost}/vendorjoin.aspx`
      : `${frLoginHost}/NavigateUsers.aspx?Token=${isLoggedIn}&URL=vendorjoin`;
  };

  const setType = (type, companyData) => () => {
    if (companyData) {
      localStorage.setItem("companyId", get(companyData, "_id", ""));
      localStorage.setItem("companyName", get(companyData, "name", ""));
      localStorage.setItem(
        "activeCompanyLogo",
        get(companyData, "companyLogo")
          ? get(companyData, "companyLogo")
          : `${get(lookUpData, "configData.eaglesPath")}/${get(
              companyData,
              "FRCompanyId"
            )}.jpg`
      );
    }
    if (type === "personal") {
      localStorage.setItem(
        "activeCompanyLogo",
        get(
          personalSettingsData,
          "profileUrl",
          "/assets/images/Blank Eagle Ribbon.svg"
        )
      );
      localStorage.setItem(
        "companyName",
        `${get(personalSettingsData, "firstName", "")} ${get(
          personalSettingsData,
          "lastName",
          ""
        )}`
      );
      localStorage.setItem("active", "personal");
    } else {
      localStorage.setItem("active", "company");
    }
    setState({
      ...state,
      companyInfo: companyData,
      selectedType: type,
    });
    history.push(`/settings/${type}`);
  };

  const handleFeedbackModal = () => {
    setState({
      ...state,
      toggleFeedbackModal: !toggleFeedbackModal,
    });
  };

  return (
    <>
      {params.type === "personal" ? (
        <Row className={classes.headingAlign}>
          <Text
            size={30}
            color={color.primary_palette.free_purple}
            family="avenir_sb"
            transform="uppercase"
            className={`${classes.settingsTxtAlign} ${classes.padding_left_10}`}
          >
            {strings.settings.titles.heading_title}
          </Text>
          <img
            alt=""
            src="/assets/images/personal_settings.png"
            className={classes.settingsLogoAlign}
          />
        </Row>
      ) : (
        <Row className={classes.headingAlign}>
          <Text
            size={30}
            color={color.primary_palette.franklin_purple}
            family="avenir_sb"
            className={classes.settingsTxtAlign}
          >
            {strings.settings.titles.company_basics_label}{" "}
          </Text>
          <img
            alt=""
            src="/assets/images/little-company.png"
            className={classes.companyImgAlign}
          />
        </Row>
      )}
      <Row>
        <Row className={classes.sideNavAlign}>
          <div className={classes.border_right}>
            <div className={classes.navSpacings}>
              <Row
                className={
                  localStorage.getItem("active") === "personal"
                    ? `${classes.flexCenter} ${classes.cursorPointer}`
                    : `${classes.flexCenterInactive} ${classes.cursorPointer}`
                }
                onClick={setType("personal")}
              >
                <Text
                  size={15}
                  color={color.primary_palette.black}
                  family="gillsans_sb"
                  transform="uppercase"
                  className={
                    localStorage.getItem("active") !== "personal" &&
                    `${classes.hoverTxt}`
                  }
                >
                  {get(personalSettingsData, "firstName", "")}{" "}
                  {get(personalSettingsData, "lastName", "")}
                </Text>
                <img
                  alt=""
                  src={
                    get(
                      personalSettingsData,
                      "profileUrl",
                      "/assets/images/Circle Plus 1.svg"
                    ) || "/assets/images/Circle Plus 1.svg"
                  }
                  className={classes.nav_logo}
                />
              </Row>
              {CompanyListByUser &&
                map(CompanyListByUser, (company) => {
                  return (
                    <Row
                      key={get(company, "_id", "")}
                      className={
                        company._id === localStorage.getItem("companyId") &&
                        localStorage.getItem("active") === "company"
                          ? `${classes.flexCenter} ${classes.cursorPointer}`
                          : `${classes.flexCenterInactive} ${classes.cursorPointer}`
                      }
                      onClick={setType("company", company)}
                    >
                      <div>
                        <Text
                          size={15}
                          color={color.primary_palette.black}
                          family="gillsans_sb"
                          transform="uppercase"
                          className={`${classes.txtRight} ${
                            company._id === localStorage.getItem("companyId") &&
                            localStorage.getItem("active") === "company"
                              ? classes.textInitial
                              : classes.hoverTxt
                          }`}
                        >
                          {get(company, "name", "")} <br />
                        </Text>
                        <Text
                          className={`${classes.subText} ${classes.txtRight}`}
                        >
                          Admin Access
                        </Text>
                      </div>
                      <div>
                        <img
                          alt=""
                          onError={addDefaultSrc}
                          src={get(
                            company,
                            "companyLogo",
                            `${get(lookUpData, "configData.eaglesPath")}/${get(
                              company,
                              "FRCompanyId"
                            )}.jpg`
                          )}
                          className={classes.nav_logo}
                        />
                      </div>
                    </Row>
                  );
                })}
              <Row className={classes.flexCenter}>
                <CustomButton
                  className={classes.add_companyBtn}
                  isX
                  onClick={handleMenuClick}
                >
                  {strings.settings.titles.add_company_profile}
                  <Lil_Plus_filled className={classes.filled_plus} />
                </CustomButton>
              </Row>
            </div>
            {localStorage.getItem("active") === "company" && (
              <>
                <div className={classes.navfields}>
                  <Row
                    className={`${classes.flexCenter} ${classes.padding_bottom_22}`}
                  >
                    <Text
                      size={16}
                      color={
                        selectedType === "company"
                          ? color.primary_palette.black
                          : color.primary_palette.franklin_purple
                      }
                      family="gillsans_sb"
                      transform="uppercase"
                      className={`${classes.cursorPointer} ${classes.hoverTxt}`}
                      onClick={setType("company", {
                        _id: localStorage.getItem("companyId"),
                        name: localStorage.getItem("companyName"),
                        companyLogo: localStorage.getItem("activeCompanyLogo"),
                      })}
                    >
                      {strings.settings.titles.company_basics}
                      {selectedType === "company" && (
                        <div className={classes.activeMark}></div>
                      )}
                    </Text>
                  </Row>
                  <Row
                    className={`${classes.flexCenter} ${classes.padding_bottom_22}`}
                  >
                    <Text
                      size={16}
                      color={
                        selectedType === "locations"
                          ? color.primary_palette.black
                          : color.primary_palette.franklin_purple
                      }
                      family="gillsans_sb"
                      transform="uppercase"
                      onClick={setType("locations")}
                      className={`${classes.cursorPointer} ${classes.hoverTxt}`}
                    >
                      {strings.settings.titles.office_locations}
                      {selectedType === "locations" && (
                        <div className={classes.activeMark}></div>
                      )}
                    </Text>
                  </Row>
                  <Row className={classes.flexCenterBlock}>
                    <Text
                      size={16}
                      color={
                        selectedType === "portfolios"
                          ? color.primary_palette.black
                          : color.primary_palette.franklin_purple
                      }
                      family="gillsans_sb"
                      transform="uppercase"
                      onClick={setType("portfolios")}
                      className={`${classes.cursorPointer} ${classes.hoverTxt} ${classes.positionRelative}`}
                    >
                      {strings.settings.titles.your_portfolio}{" "}
                      {selectedType === "portfolios" && (
                        <div className={classes.activeMark}></div>
                      )}
                    </Text>
                    {portfolioLength > 0 && (
                      <>
                        <Text
                          size={12}
                          color={color.secondary_palette.grays.medium_gray}
                          family="gillsans_r"
                        >
                          Number of Public Portfolios: {publicPortfoliosLength}/
                          {portfolioLength}
                        </Text>
                        {CompanyPortfolioData &&
                          CompanyPortfolioData.portfolios &&
                          map(CompanyPortfolioData.portfolios, (portfolios) => {
                            return (
                              <Text
                                size={12}
                                className={classes.portfolioNameHover}
                                color={
                                  color.secondary_palette.grays.medium_gray
                                }
                                family="gillsans_r"
                                onClick={redirectToPortfolio(
                                  get(portfolios, "_id", ""),
                                  get(portfolios, "name", "")
                                )}
                              >
                                View/Edit Portfolio: {portfolios.name}
                              </Text>
                            );
                          })}
                      </>
                    )}
                    <Text
                      size={16}
                      transform="uppercase"
                      color={color.primary_palette.franklin_purple}
                      family="gillsans_sb"
                      className={`${classes.blockPort} ${classes.hoverTxt}`}
                      onClick={redirectNewPortfolo}
                    >
                      {strings.settings.titles.add_portfolios_new_plus}
                    </Text>
                    <Text
                      size={16}
                      color={color.primary_palette.franklin_purple}
                      family="gillsans_sb"
                      className={`${classes.block} ${classes.hoverTxt}`}
                      onClick={redirectNewPortfolo}
                      transform="uppercase"
                    >
                      {strings.settings.titles.add_portfolios}
                    </Text>
                    <Text
                      size={16}
                      color={
                        selectedType === "history"
                          ? color.primary_palette.black
                          : color.primary_palette.franklin_purple
                      }
                      family="gillsans_sb"
                      className={`${classes.block} ${classes.hoverTxt}  ${classes.positionRelative}`}
                      onClick={setType("history")}
                      transform="uppercase"
                      // onClick={scrollToHistoy}
                    >
                      {strings.settings.titles.portfolio_history}
                      {selectedType === "history" && (
                        <div className={classes.activeMark}></div>
                      )}
                    </Text>
                  </Row>
                  <Row
                    className={`${classes.flexCenter} ${classes.padding_bottom_22} ${classes.displayNone}`}
                  >
                    <Text
                      size={15}
                      color={color.primary_palette.franklin_purple}
                      family="gillsans_sb"
                      transform="uppercase"
                    >
                      {strings.settings.titles.tradeWorks_company}
                    </Text>
                  </Row>
                </div>
                <img
                  src="/assets/images/Beta_Stamp.png"
                  className={classes.betaStampIcon}
                  alt=""
                  onClick={handleFeedbackModal}
                />
                <div className={`${classes.margin_sides} ${classes.txtRight}`}>
                  <Text
                    size={16}
                    family="gillsans_sb"
                    color={color.primary_palette.franklin_purple}
                  >
                    {strings.settings.titles.admins}
                  </Text>

                  {CompanyBasicData &&
                    CompanyBasicData?.companyBasics?.companyAdmins &&
                    map(
                      CompanyBasicData?.companyBasics?.companyAdmins,
                      (admin) => {
                        return (
                          <Text
                            key={admin._id}
                            size={16}
                            family="gillsans_r"
                            color={color.primary_palette.black}
                            className={classes.margin_left_153}
                          >
                            {get(admin, "firstName", "")}{" "}
                            {get(admin, "lastName", "")}{" "}
                            {get(admin, "email") ===
                              localStorage.getItem("userEmail") && (
                              <span>(You)</span>
                            )}
                          </Text>
                        );
                      }
                    )}
                </div>
              </>
            )}
            {impersonate && impersonate !== "false" && (
              <div className={`${classes.margin_sides} ${classes.txtRight}`}>
                <Row
                  className={`${classes.flexCenter} ${classes.padding_bottom_16}`}
                >
                  <Text
                    size={15}
                    color={color.primary_palette.franklin_purple}
                    family="gillsans_sb"
                    transform="uppercase"
                    onClick={() => history.push("/actAsUser")}
                    className={classes.cursorPointer}
                  >
                    Act as user
                  </Text>
                </Row>
                <Row
                  className={`${classes.flexCenter} ${classes.padding_bottom_16}`}
                >
                  <Text
                    size={15}
                    color={color.primary_palette.franklin_purple}
                    family="gillsans_sb"
                    transform="uppercase"
                    onClick={() => history.push("/renewalEmailGenerator")}
                    className={classes.cursorPointer}
                  >
                    Portfolio Renewal Email Generator
                  </Text>
                </Row>
              </div>
            )}
          </div>
        </Row>
        <Row
          className={
            get(params, "type") === "history"
              ? classes.historyDisplayBlock
              : classes.displayDataBlock
          }
        >
          {returnComponent()}
        </Row>
      </Row>
      <CustomModal
        open={toggleFeedbackModal}
        onClose={handleFeedbackModal}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Feedback
          open={toggleFeedbackModal}
          onClose={handleFeedbackModal}
          locationInfo="Settings Page"
        />
      </CustomModal>
    </>
  );
}

export default SettingNavStyles(SettingNav);
