import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { get, map } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

import { color } from "../../utilities/themes";
import StyleSheet from "./styles/portfolioMoneyModalStyles";
import { Text, Row } from "../common/ui_kit";
import strings from "../../utilities/strings";
import Close_Icon from "../data_display/icons/Close";
import CustomButton from "../navigations/custom_buttons";
import CustomModal from "../inputs/custom_modal";
import PortfolioModal from "../../components/modals/portfolioCardModal";

import { SettingsActions } from "../../redux/actions";

function PortfolioMoneyModal(props) {
  const {
    classes,
    open,
    onClose,
    portfolioDataObj,
    customerCategory,
    CompanyBasicData,
    eachRegionData,
    isAllPortfolioPublic,
  } = props;
  const [state, setState] = useState({
    openRateModal: false,
    nonLegacyMarketsData: [],
    legacyMarketsData: [],
  });

  const { openRateModal, legacyMarketsData, nonLegacyMarketsData } = state;

  const lookUpData = useSelector((resData) => resData.LookUp.LookUpData);

  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    const initialLegacyMarkets = [];

    get(CompanyBasicData, "companyBasics.legacyMarkets", []).map((each) => {
      initialLegacyMarkets.push(each.marketName);
    });
    if (isAllPortfolioPublic) {
      const selectedLegacyMarkets = get(portfolioDataObj, "displayedAt").filter(
        (each) => initialLegacyMarkets.includes(each.region)
      );
      const selectedNonLegacyMarkets = get(
        portfolioDataObj,
        "displayedAt"
      ).filter((each) => !initialLegacyMarkets.includes(each.region));
      setState({
        ...state,
        legacyMarketsData: selectedLegacyMarkets,
        nonLegacyMarketsData: selectedNonLegacyMarkets,
      });
    } else {
      const isLegacyMarket = initialLegacyMarkets.includes(
        eachRegionData.region
      );
      if (isLegacyMarket) {
        setState({
          ...state,
          legacyMarketsData: [eachRegionData],
          nonLegacyMarketsData: {},
        });
      } else {
        setState({
          ...state,
          nonLegacyMarketsData: eachRegionData,
          legacyMarketsData: [],
        });
      }
    }
  }, [
    CompanyBasicData,
    portfolioDataObj,
    eachRegionData,
    isAllPortfolioPublic,
  ]);

  const redirectToPage = () => {
    dispatch(SettingsActions.savePortfolioDataObj(portfolioDataObj));
    dispatch(SettingsActions.saveSelectedPortfolioData(eachRegionData));
    dispatch(SettingsActions.isSinglePortfolioSave(isAllPortfolioPublic));
    history.push("/PortfolioMoney");
  };

  const openRatePopup = () => {
    setState({
      ...setState,
      openRateModal: true,
    });
  };

  const handleClose = () => {
    setState({
      ...setState,
      openRateModal: false,
    });
  };

  const checkExpirationDate = (expDate) => {
    const today = new Date();
    const expiration = new Date(expDate);
    if (!expiration) {
      return "expired 2017";
    } else if (today < expiration) {
      return "non expired";
    } else if (today > expiration) {
      return "expired";
    }
    return "expired";
  };

  return (
    <>
      <CustomModal open={open}>
        <div className={classes.portfolioMoneyModal}>
          <div className={classes.txtRight}>
            <Close_Icon
              className={classes.cross_icon}
              onClick={onClose("showMoneyModal", "isPortfolioVisible")}
            />
          </div>
          <div className={`${classes.txtCenter} ${classes.margin_top_25}`}>
            <img
              src="/assets/images/Party.png"
              alt="Party"
              className={`${classes.party_icon} ${classes.padding_bottom_19}`}
            />
          </div>
          {(customerCategory === "GREENS" || !customerCategory) && (
            <div className={classes.txtCenter}>
              {checkExpirationDate(
                get(
                  CompanyBasicData,
                  "companyBasics.legacyMarkets[0].expireDate",
                  ""
                )
              ) === "non expired" && (
                <>
                  <Text
                    size={16}
                    family="gillsans_r"
                    color={color.primary_palette.black}
                    className={classes.padding_top_10}
                  >
                    Current Franklin Report subscribers will receive a free
                    upgrade to the
                  </Text>
                  <Text
                    size={16}
                    family="gillsans_r"
                    color={color.primary_palette.black}
                  >
                    enhanced portfolios for any existing portfolio
                    {/* markets and a
                    25% discount
                  </Text>
                  <Text
                    size={16}
                    family="gillsans_r"
                    color={color.primary_palette.black}
                  >
                    for any renewals or new markets through{" "}
                    {moment(get(lookUpData, "configData.offerEndDate")).format(
                      "LL"
                    )}
                    . */}
                  </Text>
                  {get(legacyMarketsData, "length") > 0 && (
                    <>
                      <Text
                        size={16}
                        family="gillsans_r"
                        color={color.primary_palette.black}
                        className={classes.padding_top_10}
                      >
                        Your current subscription is paid through{" "}
                        {moment(
                          get(
                            CompanyBasicData,
                            "companyBasics.legacyMarkets[0].expireDate",
                            ""
                          )
                        ).format("LL")}
                        . So any
                      </Text>
                      <Text
                        size={16}
                        family="gillsans_r"
                        color={color.primary_palette.black}
                      >
                        new, enhanced portfolios will appear instead of your
                        legacy portfolios at no
                      </Text>
                      <Text
                        size={16}
                        family="gillsans_r"
                        color={color.primary_palette.black}
                      >
                        additional charge until your current subscription
                        expires.
                      </Text>
                    </>
                  )}
                </>
              )}
              {checkExpirationDate(
                get(
                  CompanyBasicData,
                  "companyBasics.legacyMarkets[0].expireDate",
                  ""
                )
              ) === "expired 2017" &&
                get(legacyMarketsData, "length") > 0 && (
                  <>
                    <Text
                      size={16}
                      family="gillsans_r"
                      color={color.primary_palette.black}
                      className={classes.padding_top_10}
                    >
                      So glad to see you back in our community!
                    </Text>
                    {/* <Text
                      size={16}
                      family="gillsans_r"
                      color={color.primary_palette.black}
                    >
                      The great news is that your subscription is free until{" "}
                      {moment(
                        get(
                          CompanyBasicData,
                          "companyBasics.legacyMarkets[0].expireDate",
                          ""
                        )
                      ).format("LL")}{" "}
                    </Text> */}
                    {/* <Text
                      size={16}
                      family="gillsans_r"
                      color={color.primary_palette.black}
                    >
                      has a 25% pandemic discount. Expiration date of one year
                      after payment.
                    </Text> */}
                  </>
                )}
              {checkExpirationDate(
                get(
                  CompanyBasicData,
                  "companyBasics.legacyMarkets[0].expireDate",
                  ""
                )
              ) === "expired" &&
                get(legacyMarketsData, "length") > 0 && (
                  <>
                    <Text
                      size={16}
                      family="gillsans_r"
                      color={color.primary_palette.black}
                      className={classes.padding_top_10}
                    >
                      So glad to see you back in our community!
                    </Text>
                    <Text
                      size={16}
                      family="gillsans_r"
                      color={color.primary_palette.black}
                    >
                      The great news is that subscription renewals 
                      {/* and new
                      markets receive */}
                    </Text>
                    <Text
                      size={16}
                      family="gillsans_r"
                      color={color.primary_palette.black}
                    >
                      {/* a 25% pandemic discount until{" "}
                      {moment(
                        get(lookUpData, "configData.offerEndDate")
                      ).format("LL")} */}
                      .
                    </Text>
                  </>
                )}
            </div>
          )}
          {customerCategory === "VULCANS" && (
            <div className={classes.txtCenter}>
              <Text
                size={16}
                family="gillsans_r"
                color={color.primary_palette.black}
                className={classes.padding_top_10}
              >
                Your past Franklin Report portfolios subscription expired
                January 31, 2021. The great news is that current subscriptions
                are free through{" "}
                {moment(get(lookUpData, "configData.offerEndDate")).format(
                  "LL"
                )}
                .
              </Text>
            </div>
          )}
          {/* {customerCategory === "REDS" && (
            <div className={classes.txtCenter}>
              <Text
                size={16}
                family="gillsans_r"
                color={color.primary_palette.black}
                className={classes.padding_top_10}
              >
                So glad to see you back in our community! The great news is that
                your subscription is free until{" "}
                {moment(get(lookUpData, "configData.offerEndDate")).format(
                  "LL"
                )}
                .
              </Text>
            </div>
          )} */}
          <Row
            className={`${classes.padding_top_12} ${classes.img_data_spcaing} ${classes.overFlow_x_auto}`}
          >
            <div>
              <Text
                size={14}
                family="avenir_light"
                color={color.primary_palette.black}
                className={classes.textCapital}
              >
                {get(portfolioDataObj, "name", "")}
              </Text>
              <div className={classes.img_main_border}>
                <div className={classes.img_sub_border}>
                  <img
                    src={
                      get(
                        portfolioDataObj,
                        "projects[0].coverImageUri",
                        "/assets/images/Cover Image BW.jpg"
                      ) || "/assets/images/Cover Image BW.jpg"
                    }
                    alt="Crisp"
                    className={classes.company_logo}
                  />
                  <Row>
                    <div className={classes.width_30}>
                      <img
                        src={
                          get(
                            portfolioDataObj,
                            "companyLogo",
                            "https://trablobdev.blob.core.windows.net/tra-con-use-dev/temp/1613725095533.png"
                          ) ||
                          "https://trablobdev.blob.core.windows.net/tra-con-use-dev/temp/1613725095533.png"
                        }
                        alt="Crisp"
                        className={classes.user_logo}
                      />
                    </div>
                    <div className={classes.width_70}>
                      <Text
                        size={18}
                        family="millerdisplay_light"
                        color={color.primary_palette.black}
                        className={`${classes.txtCenter} ${classes.ellipsis_text}`}
                      >
                        <span className={classes.text_decoration}>
                          {get(portfolioDataObj, "companyName", "")}
                        </span>
                      </Text>
                      <Text
                        size={11}
                        family="gillsans_sb"
                        color={color.primary_palette.black}
                        className={`${classes.textCapital} ${classes.txtCenter}`}
                      >
                        {get(portfolioDataObj, "companyAddress.city", "")}
                        {get(portfolioDataObj, "companyAddress.state", "") &&
                          ","}
                        &nbsp;
                        {get(portfolioDataObj, "companyAddress.state", "")}
                      </Text>
                    </div>
                  </Row>
                </div>
              </div>
              <Row>
                <Text
                  size={14}
                  color={color.secondary_palette.grays.shadow_gray}
                  family="gillsans_r"
                  className={classes.future_rate_card}
                  onClick={openRatePopup}
                >
                  {strings.build.titles.future_rate_card}
                </Text>
              </Row>
            </div>
            {get(legacyMarketsData, "length") > 0 && (
              <Row className={classes.alignments_col}>
                <Text
                  size={20}
                  family="gillsans_r"
                  color={color.primary_palette.black}
                  className={classes.border_gray}
                >
                  Legacy Markets
                </Text>
                {map(legacyMarketsData, (eachRegion) => {
                  return (
                    <div
                      className={`${classes.padding_top_15}  ${classes.width_120}`}
                    >
                      <Text
                        size={15}
                        family="gillsans_sb"
                        color={color.primary_palette.black}
                      >
                        {get(eachRegion, "region", "")}
                      </Text>
                      {map(eachRegion.category, (trade) => {
                        return (
                          <Text
                            size={15}
                            family="gillsans_light"
                            color={color.primary_palette.black}
                            className={classes.lineHeightMin}
                          >
                            {trade}
                          </Text>
                        );
                      })}
                      {get(eachRegion, "expireDate", "") && (
                        <Text
                          size={15}
                          family="gillsans_sb"
                          color={color.primary_palette.black}
                        >
                          {strings.portfolioMoney.title.expiration_date}
                          <span className={classes.gill_r}>
                            {moment(get(eachRegion, "expireDate", "")).format(
                              "MM/DD/yyyy"
                            )}{" "}
                          </span>
                        </Text>
                      )}
                    </div>
                  );
                })}
              </Row>
            )}
            <Row className={classes.alignments_col}>
              {isAllPortfolioPublic && (
                <>
                  <Text
                    size={20}
                    family="gillsans_r"
                    color={color.primary_palette.black}
                    className={classes.border_gray}
                  >
                    New Markets
                  </Text>
                  {map(nonLegacyMarketsData, (eachRegion) => {
                    return (
                      <>
                        <div className={`${classes.padding_top_15} `}>
                          <Text
                            size={15}
                            family="gillsans_sb"
                            color={color.primary_palette.black}
                          >
                            {get(eachRegion, "region", "")}
                          </Text>
                          {map(eachRegion?.category, (eachCategory) => {
                            return (
                              <Text
                                size={15}
                                family="gillsans_light"
                                color={color.primary_palette.black}
                                className={classes.lineHeightMin}
                              >
                                {eachCategory} <br />
                              </Text>
                            );
                          })}
                        </div>
                      </>
                    );
                  })}
                </>
              )}
              {!isAllPortfolioPublic && (
                <>
                  {get(nonLegacyMarketsData, "region") && (
                    <Text
                      size={20}
                      family="gillsans_r"
                      color={color.primary_palette.black}
                      className={classes.border_gray}
                    >
                      New Markets
                    </Text>
                  )}
                  <div className={`${classes.padding_top_15} `}>
                    <Text
                      size={15}
                      family="gillsans_sb"
                      color={color.primary_palette.black}
                    >
                      {get(nonLegacyMarketsData, "region")}
                    </Text>
                    {map(nonLegacyMarketsData?.category, (eachCategory) => {
                      return (
                        <Text
                          size={15}
                          family="gillsans_light"
                          color={color.primary_palette.black}
                          className={classes.lineHeightMin}
                        >
                          {eachCategory} <br />
                        </Text>
                      );
                    })}
                  </div>
                </>
              )}
              <div className={`${classes.padding_top_15} `}>
                <Text
                  size={16}
                  family="avenir_sb"
                  color={color.primary_palette.franklin_purple}
                  onClick={redirectToPage}
                  className={`${classes.cursor} ${classes.underlineHover}`}
                >
                  + Add Market?
                </Text>
              </div>
            </Row>
          </Row>
          <div className={`${classes.greatBtnAlign} ${classes.marginTop10}`}>
            <CustomButton className={classes.greatBtn} onClick={redirectToPage}>
              {strings.moneyModal.titles.great}
            </CustomButton>
          </div>
          <div className={classes.textCenter}>
            <Text
              size={16}
              color={color.primary_palette.black}
              family="gillsans_light"
              className={`${classes.textCenter} ${classes.footerTxtAlign}`}
            >
              {strings.moneyModal.titles.renew_rights1}
            </Text>
          </div>
        </div>
      </CustomModal>
      <CustomModal
        open={openRateModal}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <PortfolioModal onClose={handleClose} />
      </CustomModal>
    </>
  );
}

export default StyleSheet(PortfolioMoneyModal);
