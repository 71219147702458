import { combineReducers } from "redux";
import ApiReducer from "./api.reducer";
import ProfileReducer from "./profile_reducer";
import SettingsReducer from "./settings_reducer";
import JoinUsReducer from "./joinus_reducer";
import LoginReducer from "./login_reducer";
import LookUpReducer from "./lookup_reducer";
import { LoginTypes } from "../actionTypes";

const appReducer = combineReducers({
  Api: ApiReducer,
  Profile: ProfileReducer,
  Settings: SettingsReducer,
  JoinUs: JoinUsReducer,
  Login: LoginReducer,
  LookUp: LookUpReducer,
});

const RootReducer = (state, action) => {
  if (action.type === LoginTypes.LOGOUT) {
    localStorage.removeItem("userToken");
    localStorage.removeItem("active");
    localStorage.removeItem("user_data");
    localStorage.removeItem("Review"); 
    localStorage.removeItem("NewPortfolio"); 
    localStorage.removeItem("profile");
    localStorage.removeItem("userType");
    localStorage.removeItem("frpage");
    localStorage.removeItem("companyId");
    localStorage.removeItem("companyName");
    localStorage.removeItem("portfolioId");
    localStorage.removeItem("isPortfolioPublic");
    localStorage.removeItem("customerCategory");
    state = undefined;
  }
  return appReducer(state, action);
};

export default RootReducer;
