import store from "../store";
import { get } from "lodash";
import { LoginActions } from "../actions";

const ResponseInterceptor = (error) => {
  const status = get(error, "response.status");
  if (status === 401) {
    store.dispatch(LoginActions.logOut("Session Expired!"));
    return;
  }
  return Promise.reject(error);
};

export { ResponseInterceptor };
