import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core";
import Text from "./text";
import { pxToRem, color } from "../../../utilities/themes";

const useStyles = makeStyles({
  container: props => ({
    display: "inline-flex",
    alignItems: "center",
    padding: `0 ${pxToRem(10)}`,
    borderRadius: pxToRem(15.5),
    boxShadow: `${pxToRem(2)} ${pxToRem(2)} ${pxToRem(2)} 0 rgba(0, 0, 0, 0.2)`,
    border: `solid ${pxToRem(1)} ${color.primary_palette.black}`,
    ...(props.height && { height: pxToRem(props.height) }),
    ...(props.width && { width: pxToRem(props.width) }),
    ...(props.alignCenter && {
      justifyContent: "center"
    }),
    cursor: 'pointer',
    background: `linear-gradient(to bottom, white, white, 20%, ${color.pearl}, 80%, ${color.silver})`
  })
});

const Chip = ({ label, iconLeft, className, style, onClick, ...props }) => {
  const classes = useStyles(props);
  return (
    <div className={`${classes.container} ${className}`} style={style} onClick={onClick}>
      {iconLeft}
      <Text
        family="gillsans_sb"
        size={12}
        style={{ marginLeft: pxToRem(10) }}
      >
        {label}
      </Text>
    </div>
  );
};

Chip.propTypes = {
  label: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number,
  iconLeft: PropTypes.oneOf([PropTypes.element, ""]),
  alignCenter: PropTypes.oneOf(["center"]),
  className: PropTypes.string,
  style: PropTypes.object
};

Chip.defaultProps = {
  label: "",
  height: 25,
  iconLeft: <img src="/assets/icons/Lil_Plus_filled.svg" alt=""/>
};

export default Chip;
