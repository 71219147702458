import { withStyles } from "@material-ui/core/styles";
import tradework_theme, { pxToRem, color } from "../../../utilities/themes";

const styles = withStyles({
  addImagewidth: {
    width: pxToRem(738),
    padding: pxToRem(15),
    border: `solid ${pxToRem(1)} ${color.secondary_palette.grays.shadow_gray}`,
    backgroundColor: color.primary_palette.fafa_gray,
    marginBottom: pxToRem(20),
    paddingRight: pxToRem(20),
    marginLeft: pxToRem(-8),
  },
  rectangle_place: {
    width: pxToRem(95),
    height: pxToRem(142),
    marginLeft: pxToRem(30),
    marginTop: pxToRem(14),
  },
  rectangle_horiz: {
    width: pxToRem(128),
    height: pxToRem(62),
    marginTop: pxToRem(68),
  },
  placeholder_square: {
    height: pxToRem(110),
    width: pxToRem(110),
    marginTop: pxToRem(38),
  },
  rectangle_icons: {
    width: pxToRem(312),
    height: pxToRem(142),
    marginRight: pxToRem(24),
    // border: `${pxToRem(1)} solid ${color.secondary_palette.grays.shadow_gray}`,
    position: "relative",
    display: "flex",
    alignItems: "center",
    alignSelf: "center",
    marginTop: pxToRem(10),
  },
  description: {
    ...tradework_theme.typography.styles.avenir_bold,
    color: color.primary_palette.free_purple,
    fontSize: pxToRem(10),
    position: "absolute",
    top: pxToRem(-6),
    zIndex: 9,
    left: pxToRem(20),
    padding: `${pxToRem(0)} ${pxToRem(5)}`,
    backgroundColor: color.primary_palette.white,
  },
  textarea: {
    width: "97%",
    height: `${pxToRem(66)} !important`,
    resize: "none",
    backgroundColor: color.primary_palette.white,
    border: `solid ${pxToRem(0)} ${color.primary_palette.black}`,
    paddingLeft: pxToRem(10),
    ...tradework_theme.typography.styles.NeutraText,
    fontSize: pxToRem(16),
    position: "relative",
    overflowY: "auto !important",
    "&::-webkit-scrollbar": {
      width: pxToRem(12),
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: color.secondary_palette.grays.background_gray,
      borderRadius: pxToRem(6),
    },
    "& .MuiInput-root": {
      height: pxToRem(72),
    },
    "& .MuiInput-underline": {
      "&:before": {
        borderBottom: `${pxToRem(0)} !important`,
      },
    },
    "&::placeholder": {
      fontSize: pxToRem(14),
      color: color.secondary_palette.grays.shadow_gray,
      // fontStyle: "oblique",
      ...tradework_theme.typography.styles.avenir_light,
      opacity: 1,
    },
    "&:hover": {
      border: `${pxToRem(0)} !important`,
    },
    "&:focus": {
      outline: "none !important",
      border: `${pxToRem(0)} !important`,
    },
  },
  textArea_scroll: {
    border: `solid ${pxToRem(1)} ${color.primary_palette.black}`,
    width: "98%",
    paddingTop: `${pxToRem(13)}`,
    height: `${pxToRem(70)} !important`,
    paddingBottom: pxToRem(5),
    backgroundColor: color.primary_palette.white,
    "&:hover": {
      border: "1px solid #C6DDFF",
    },
  },
  fields: {
    width: "100%",
    position: "relative",
  },
  textAreaText: {
    position: "relative",
    marginTop: pxToRem(15),
    "& .MuiButton-root": {
      minWidth: pxToRem(30),
      right: 0,
      "& .MuiSvgIcon-root": {
        fontSize: pxToRem(9),
        position: "absolute",
        top: 0,
        right: pxToRem(12),
      },
    },
  },
  hashtags_field: {
    width: "99%",
    backgroundColor: color.primary_palette.white,
    margin: 0,
    ...tradework_theme.typography.styles.avenir_light,
    "& .MuiInputBase-root": {
      width: "100%",
      height: pxToRem(34),
      border: `solid ${pxToRem(1)} ${color.primary_palette.black}`,
    },
    "& .MuiSvgIcon-root": {
      marginRight: `${pxToRem(-2)} !important`,
      fontSize: `${pxToRem(9)} !important`,
    },
    "& .MuiInputBase-input": {
      ...tradework_theme.typography.styles.NeutraText,
      fontSize: pxToRem(16),
      "&::placeholder": {
        fontSize: pxToRem(14),
        color: color.governmental_oyster,
        // fontStyle: "oblique !important",
        ...tradework_theme.typography.styles.avenir_light,
        opacity: 1,
      },
    },
  },
  slect_section: {
    display: "flex",
    // justifyContent: "center",
    flexFlow: "row wrap",
  },
  drpopdowns: {
    width: "30%",
    paddingRight: pxToRem(20),
  },
  dropdownWidth: {
    border: 0,
    width: pxToRem(200),
    borderBottom: `solid ${pxToRem(1)} ${
      color.secondary_palette.grays.shadow_gray
    }`,
    backgroundColor: "transparent",
    padding: 0,
    "& p": {
      fontSize: pxToRem(14),
      color: color.primary_palette.black,
      ...tradework_theme.typography.styles.avenir_light,
      position: "relative",
      top: pxToRem(4),
    },
    "& .MuiSvgIcon-root": {
      fontSize: pxToRem(10),
      position: "relative",
      top: pxToRem(4),
    },
  },
  dropdownWidthRoom: {
    border: 0,
    width: pxToRem(202),
    marginLeft: pxToRem(19),
    padding: 0,
    borderBottom: `solid ${pxToRem(1)} ${
      color.secondary_palette.grays.shadow_gray
    }`,
    backgroundColor: "transparent",
    "& p": {
      fontSize: pxToRem(14),
      color: color.primary_palette.black,
      ...tradework_theme.typography.styles.avenir_light,
      position: "relative",
      top: pxToRem(4),
    },
    "& .MuiSvgIcon-root": {
      fontSize: pxToRem(10),
      position: "relative",
      top: pxToRem(4),
    },
  },
  dropdownWidth_first: {
    border: 0,
    width: pxToRem(300),
    padding: 0,
    borderBottom: `solid ${pxToRem(1)} ${
      color.secondary_palette.grays.shadow_gray
    }`,
    backgroundColor: "transparent",
    "& p": {
      fontSize: pxToRem(14),
      color: color.primary_palette.black,
      ...tradework_theme.typography.styles.avenir_light,
      position: "relative",
      top: pxToRem(4),
    },
    "& .MuiSvgIcon-root": {
      fontSize: pxToRem(10),
      position: "relative",
      top: pxToRem(4),
    },
  },
  field1: {
    width: "100%",
  },
  drop_down_field: {
    flexGrow: 1,
    maxWidth: pxToRem(345),
  },
  flexWrap: {
    flexWrap: "wrap",
  },
  justifyEnd: {
    justifyContent: "flex-end",
  },
  margin_r_9: {
    marginRight: pxToRem(9),
  },
  word_spacing: {
    paddingRight: pxToRem(7),
    marginTop: pxToRem(4),
  },
  field_spacing: {
    paddingTop: pxToRem(15),
  },
  main_colors_text: {
    textAlign: "center",
    paddingRight: pxToRem(10),
    marginTop: pxToRem(4),
  },
  trade_spacing: {
    paddingTop: pxToRem(13),
  },
  field2_spacing: {
    paddingTop: pxToRem(20),
  },
  coverRadio: {
    justifyContent: "center",
    "& .MuiTypography-root": {
      ...tradework_theme.typography.styles.avenir_bold,
      color: color.primary_palette.black,
      fontSize: pxToRem(15),
    },
    "& .MuiSvgIcon-root": {
      fontSize: pxToRem(14),
    },
  },
  icons: {
    position: "absolute",
    right: pxToRem(-18),
    top: pxToRem(0),
  },
  delete_font: {
    width: pxToRem(15),
    display: "block",
    padding: `${pxToRem(5)} ${pxToRem(0)}`,
    cursor: "move",
  },
  inputContainer: {
    marginBottom: pxToRem(-10),
    marginTop: pxToRem(10),
    height: pxToRem(26),
  },
  inputField: {
    width: "100%",
    border: "none",
    paddingLeft: pxToRem(8),
    borderTop: `${pxToRem(1)} solid`,
    height: pxToRem(22),
    "&:focus": {
      outline: "none",
    },
    "&::placeholder": {
      color: color.primary_palette.highlight_purple,
      fontSize: pxToRem(12),
      ...tradework_theme.typography.styles.avenir_light,
      opacity: 1,
    },
  },
  customScrollHeight: {
    minHeight: `${pxToRem(330)} !important`,
    "& li": {
      overflow: "hidden",
      whiteSpace: "normal",
      textOverflow: "ellipsis",
    },
  },
  customScrollHeightColor: {
    minHeight: `${pxToRem(332)} !important`,
    "& li": {
      overflow: "hidden",
      whiteSpace: "normal",
      textOverflow: "ellipsis",
    },
  },
  custommainColorScrollHeight: {
    minHeight: `${pxToRem(50)} !important`,
    "& li": {
      overflow: "hidden",
      whiteSpace: "normal",
      textOverflow: "ellipsis",
      fontSize: pxToRem(15),
      ...tradework_theme.typography.styles.avenir_book_r,
    },
  },
  plus: {
    fontSize: pxToRem(20),
    color: color.primary_palette.franklin_purple,
    cursor: "pointer",
  },
  iconPlusStyle: {
    backgroundImage: `url("/assets/icons/Purple Plus 26x26.svg")`,
    backgroundSize: "cover",
    width: pxToRem(26),
    height: pxToRem(26),
    display: "inline-block",
    cursor: "pointer",
    "&:hover": {
      backgroundImage: `url("/assets/icons/Green Plus 26x26.svg")`,
      backgroundSize: "cover",
      width: pxToRem(26),
      height: pxToRem(26),
      display: "inline-block",
      cursor: "pointer",
    },
  },
  crossIconStyle: {
    marginLeft: pxToRem(6),
    marginRight: pxToRem(6),
    cursor: "pointer",
    position: "relative",
    top: pxToRem(4),
  },
  trash_can: {
    width: pxToRem(13),
    height: pxToRem(16),
    cursor: "pointer",
    position: "relative",
    top: pxToRem(-8),
    right: pxToRem(6),
  },
  icons_blk: {
    position: "absolute",
    right: pxToRem(6),
    bottom: 0,
  },
  dropdownWidthColor: {
    border: 0,
    width: pxToRem(276),
    marginLeft: pxToRem(11),
    padding: 0,
    borderBottom: `solid ${pxToRem(1)} ${
      color.secondary_palette.grays.shadow_gray
    }`,
    backgroundColor: "transparent",
    "& p": {
      fontSize: pxToRem(14),
      color: color.primary_palette.black,
      ...tradework_theme.typography.styles.avenir_light,
      position: "relative",
      top: pxToRem(4),
    },
    "& .MuiSvgIcon-root": {
      fontSize: pxToRem(10),
      position: "relative",
      top: pxToRem(4),
    },
  },
  required_icon: {
    height: pxToRem(19),
    width: pxToRem(17),
    color: color.form_colors.blueberry_purple,
    verticalAlign: "super",
    paddingLeft: pxToRem(2),
    position: "absolute",
    top: pxToRem(2),
    zIndex: "99",
  },
  required_icon_error: {
    height: pxToRem(19),
    width: pxToRem(17),
    color: color.primary_palette.christmas_red,
    verticalAlign: "super",
    paddingLeft: pxToRem(2),
    position: "absolute",
    top: pxToRem(2),
    zIndex: "99",
  },
  spanIconAlign: {
    width: pxToRem(40),
    height: pxToRem(20),
    marginRight: pxToRem(13),
    marginLeft: pxToRem(13),
  },
  spanIconAlignWhite: {
    width: pxToRem(40),
    height: pxToRem(20),
    marginRight: pxToRem(13),
    marginLeft: pxToRem(13),
    border: `solid ${pxToRem(1)} ${
      color.primary_palette.black
    }`,
  },
  colorLiAlign: {
    marginBottom: pxToRem(7),
  },
  colorTxt: {
    textTransform: "capitalize",
  },
  num: {
    ...tradework_theme.typography.styles.avenir_sb,
    color: color.secondary_palette.grays.drag_text,
    fontSize: pxToRem(12),
    marginRight: pxToRem(6),
  },
  materialsOrTechniques: {
    "& li": {
      fontSize: pxToRem(15),
      ...tradework_theme.typography.styles.avenir_book_r,
    },
  },
  dropdownWidthTrade: {
    border: 0,
    width: pxToRem(276),
    borderBottom: `solid ${pxToRem(1)} ${
      color.secondary_palette.grays.shadow_gray
    }`,
    backgroundColor: "transparent",
    padding: 0,
    "& p": {
      fontSize: pxToRem(14),
      color: color.primary_palette.black,
      ...tradework_theme.typography.styles.avenir_light,
      position: "relative",
      top: pxToRem(4),
    },
    "& .MuiSvgIcon-root": {
      fontSize: pxToRem(10),
      position: "relative",
      top: pxToRem(4),
    },
  },
  drop_down_field_large: {
    flexGrow: 1,
    maxWidth: pxToRem(392),
  },
  listAlign: {
    fontSize: pxToRem(14),
  },
  textCapital: {
    textTransform: "uppercase",
  },
});

export default styles;
