import { withStyles } from "@material-ui/core/styles";
import tradework_theme, { pxToRem, color } from "../../../utilities/themes";

const styles = withStyles({
  product_cover: {
    width: pxToRem(516),
    border: `solid ${pxToRem(2)} ${color.primary_palette.free_purple}`,
    paddingBottom: pxToRem(20),
    backgroundColor: color.secondary_palette.blues.modal_blue,
  },
  textRight: {
    textAlign: "right",
  },
  cross_mark: {
    fontSize: pxToRem(15),
  },
  cross_btn: {
    minWidth: pxToRem(40),
    padding: pxToRem(16),
  },
  product_cover_inner_project: {
    width: pxToRem(360),
    margin: "0 auto",
    textAlign: "center",
    border: `solid ${pxToRem(1)} ${color.primary_palette.franklin_purple}`,
    backgroundColor: color.secondary_palette.blues.tile_blue,
  },
  img_block: {
    width: pxToRem(177),
    height: pxToRem(118),
    backgroundColor: color.secondary_palette.grays.shadow_gray,
    margin: `${pxToRem(0)} auto`,
    marginTop: pxToRem(27),
    marginBottom: pxToRem(11),
  },
  padding_20: {
    padding: `${pxToRem(0)} ${pxToRem(20)}`,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  image_block: {
    width: pxToRem(177),
    height: pxToRem(118),
    margin: `${pxToRem(0)} auto`,
    marginTop: pxToRem(27),
    marginBottom: pxToRem(11),
  },
  padding_sides: {
    paddingTop: pxToRem(30),
    paddingBottom: pxToRem(13),
  },
  deleteBtn: {
    width: pxToRem(135),
    height: pxToRem(25),
    borderRadius: pxToRem(12.5),
    border: `solid ${pxToRem(1)} ${color.primary_palette.franklin_purple}`,
    backgroundColor: color.primary_palette.white,
    marginTop: pxToRem(40),
    marginBottom: pxToRem(30),
    "& .MuiButton-label": {
      ...tradework_theme.typography.styles.gillsans_r,
      fontSize: pxToRem(15),
      color: color.form_colors.blueberry_purple,
    },
  },
  disabled_deleteBtn: {
    width: pxToRem(135),
    height: pxToRem(25),
    borderRadius: pxToRem(12.5),
    border: `solid ${pxToRem(1)} ${color.primary_palette.disable_border}`,
    backgroundColor: color.primary_palette.white,
    marginTop: pxToRem(40),
    marginBottom: pxToRem(30),
    "& .MuiButton-label": {
      ...tradework_theme.typography.styles.gillsans_r,
      fontSize: pxToRem(15),
    },
  },
  textCenter: {
    textAlign: "center",
  },
  btnDivAlign: {
    textAlign: "center",
    height: pxToRem(70),
    marginTop: pxToRem(-28),
  },
  portfolio_items: {
    width: pxToRem(183),
    height: pxToRem(21),
    borderRadius: pxToRem(3),
    marginRight: pxToRem(8),
    marginTop: pxToRem(6),
    backgroundColor: color.primary_palette.white,
    border: `solid ${pxToRem(1)} ${color.secondary_palette.grays.shadow_gray}`,
    "& .MuiSvgIcon-root": {
      fontSize: pxToRem(14),
    },
    "& p": {
      ...tradework_theme.typography.styles.avenir_light,
      fontSize: pxToRem(16),
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
    },
  },
  txtCaptial: {
    textTransform: "uppercase",
  },
  txtTransform: {
    "& .MuiFormControlLabel-root": {
      textTransform: "inherit",
    },
  },
  radio_button: {
    "& .MuiTypography-root": {
      ...tradework_theme.typography.styles.avenir_light,
      fontSize: pxToRem(18),
    },
  },
  portfolioNameStyle: {
    "& .MuiInputBase-input": {
      ...tradework_theme.typography.styles.NeutraText,
      fontSize: pxToRem(16),
      paddingBottom: 0,
      marginTop: pxToRem(-6),
      color: color.primary_palette.black,
      "&::placeholder": {
        // fontStyle: "oblique",
        color: color.wizard_box_colors.shadow_gray,
        ...tradework_theme.typography.styles.avenir_light,
        opacity: 1,
      },
    },
  },
  alignSelf: {
    alignItems: "center",
  },
  padding_bottom_40: {
    paddingBottom: pxToRem(40),
  },
  padding_left_70: {
    paddingLeft: pxToRem(70),
  },
  dupeNameAlign: {
    textAlign: "center",
  },
  dupeTxtAlign: {
    display: "inline-block",
    marginRight: pxToRem(10),
  },
  error: {
    color: color.primary_palette.christmas_red,
    fontSize: pxToRem(16),
    textAlign: "center",
    marginTop: pxToRem(30),
  },
});

export default styles;
