import React, { useState, useEffect } from "react";
import { get } from "lodash";
import {
  REGEX_EMAIL,
  PASSWORD_REGEX,
} from "../../validations/validations";
import RecoverCredentialsStyles from "./styles/recoverCredentialsStyle";
import Text from "../../components/common/ui_kit/text";
import CustomTextField from "../../components/inputs/custom_textfields";
import strings from "../../utilities/strings";
import CustomButton from "../../components/navigations/custom_buttons";
import Close_Icon from "../../components/data_display/icons/Close";
import CustomModal from "../../components/inputs/custom_modal";
import { color } from "../../utilities/themes";
import Row from "../../components/common/ui_kit/row";
import CustomLink from "../../components/navigations/custom_links";
import Ribbon_Icon from "../../components/data_display/icons/Ribbon";
import { RecoverActions } from "../../redux/actions";
import { useDispatch } from "react-redux";
import PasswordLinkMail from "../../components/recover_password/passwordLink";
import NotThatLink from "../../components/recover_password/notThatLink";

function RecoverCredentials(props) {
  const [values, setValues] = useState({
    email: "",
    errors: {},
    openPasswordLinkModal: false,
    openNotThatOneModal: false,
  });
  const { classes, showRecoverCredentials, toggleModal } = props;
  const dispatch = useDispatch();
  const redirectTosignIn = () => {
    toggleModal("showRecoverCredentials", "displayLogin");
  };
  const { email, errors, openPasswordLinkModal, openNotThatOneModal } = values;

  const redirectToJoinUs = () => {
    toggleModal("showRecoverCredentials", "displayJoinUs");
  };

  const onInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "email") {
      const checkType = PASSWORD_REGEX.test(value);
      if (checkType) {
        // NUMBER TYPE VALIDATION
        setValues({ ...values, [name]: value });
        return;
      } else {
        // EMAIL TYPE VALIDATION
        setValues({
          ...values,
          [name]: value,
        });
        return;
      }
    }
  };

  const onClose = () => {
    localStorage.removeItem("frpage");
    setValues({
      ...values,
      openPasswordLinkModal: false,
    });
    toggleModal("showRecoverCredentials", "displayLogin");
  };

  useEffect(() => {
    setValues({
      ...values,
      email: localStorage.getItem("loggedInEmail"),
    });
  }, [localStorage.getItem("loggedInEmail")]);

  const onCloseNotThatOne = () => {
    localStorage.removeItem("frpage");
    setValues({
      ...values,
      openNotThatOneModal: false,
    });
  };

  const handleValidation = (name, value) => {
    if (name === "email") {
      if (!value) {
        // IF NO VALUE RETURNING ERROR TRUE
        errors[name] = { error: true, touched: true };
        setValues({ ...values });
        return;
      }
      const checkType = PASSWORD_REGEX.test(value);
      if (checkType) {
        // NUMBER TYPE VALIDATION
        return;
      } else {
        // EMAIL TYPE VALIDATION
        const regexTest = REGEX_EMAIL.test(value);
        if (!regexTest) {
          errors[name] = { error: true, touched: true };
          setValues({ ...values });
        } else {
          errors[name] = errors[name] || {};
          errors[name] = { error: false, touched: true };
          setValues({
            ...values,
          });
        }
        return;
      }
    }
  };

  const hasFormErrors = () => {
    if (errors) {
      const errs = Object.values(errors).map((each) => each.error);
      if (!errs.includes(true) && email) {
        return false;
      }
      return true;
    }
    return true;
  };

  const submitForm = () => {
    dispatch(
      RecoverActions.recoverLink({ ...values }, (res) => {
        if (res && res.error) {
          localStorage.removeItem("frpage");
          setValues({
            ...values,
            openNotThatOneModal: true,
          });
        } else {
          localStorage.removeItem("frpage");
          setValues({
            ...values,
            openPasswordLinkModal: true,
          });
        }
      })
    );
  };

  return (
    <>
      <CustomModal
        open={showRecoverCredentials}
        aria-labelledby="modal-title"
        disablePortal
        aria-describedby="modal-description"
        disableAutoFocus
      >
        <div className={classes.recover_credentials}>
          <div className={classes.textRight}>
            <CustomButton
              className={classes.crossBtn}
              onClick={() => toggleModal("showRecoverCredentials")}
              isX
            >
              <Close_Icon />
            </CustomButton>
          </div>
          <div className={classes.textCenter}>
            <Ribbon_Icon color="primary" className={classes.ribbon_icon} />
            <Text
              size={20}
              family="gillsans_sb"
              color={color.primary_palette.franklin_purple}
              className={classes.txtCapital}
            >
              {strings.passwords.titles.recover_credentials}
            </Text>
            <Text
              size={15}
              family="gillsans_sb"
              color={color.primary_palette.black}
            >
              {strings.passwords.titles.recover_credentials1}
            </Text>
            <Text
              size={15}
              family="gillsans_sb"
              color={color.primary_palette.black}
              className={classes.inputAlign}
            >
              {strings.passwords.titles.recover_credentials2}
            </Text>
            <div>
              <CustomTextField
                label={strings.passwords.titles.emai_username}
                name="email"
                value={get(values, "email", "")}
                onChange={onInputChange}
                onBlur={(e) => handleValidation(e.target.name, e.target.value)}
                className={`${
                  !get(errors, "email.touched", false)
                    ? classes.inp_initial
                    : get(errors, "email.error", false)
                    ? classes.inp_error
                    : classes.inp_success
                } ${classes.textFieldSuccess}`}
              />
            </div>
            <CustomButton
              className={classes.set_new_password}
              onMouseUp={() => submitForm()}
              disabled={hasFormErrors()}
              endIcon={
                !hasFormErrors() && (
                  <Ribbon_Icon
                    color="primary"
                    style={{
                      fontSize: "47px",
                      marginTop: "-14px",
                      marginLeft: "-38px",
                      position: "absolute",
                    }}
                  />
                )
              }
            >
              {strings.passwords.titles.password_link}
            </CustomButton>
          </div>
          <div className={classes.textCenter}>
            <Text
              size={15}
              family="gillsans_sb"
              color={color.primary_palette.black}
            >
              {strings.passwords.titles.call_me}
            </Text>
            <Text
              size={15}
              family="gillsans_sb"
              color={color.primary_palette.black}
            >
              {strings.passwords.titles.call_me2}
            </Text>
          </div>
          <Row className={classes.footer_spacing}>
            <div className={classes.sign_in}>
              <Text
                size={14}
                color={color.primary_palette.franklin_purple}
                family="gillsans_sb"
              >
                {strings.passwords.titles.Wait_password}
                <CustomLink
                  underline="always"
                  className={`${classes.recover} ${classes.margin_left_3}`}
                  onClick={redirectTosignIn}
                >
                  <label className={classes.cursor}>
                    {strings.passwords.titles.sign_in}
                  </label>
                </CustomLink>
              </Text>
            </div>
            <div className={classes.join_here}>
              <Text
                size={14}
                color={color.primary_palette.franklin_purple}
                family="gillsans_sb"
              >
                {strings.passwords.titles.not_member}
              </Text>
              <CustomLink
                underline="always"
                className={classes.recover}
                onClick={redirectToJoinUs}
              >
                <label className={classes.cursor}>
                  {strings.passwords.titles.join_here}
                </label>
              </CustomLink>
            </div>
          </Row>
        </div>
      </CustomModal>
      <CustomModal
        open={openPasswordLinkModal}
        onClose={onClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <PasswordLinkMail
          onClose={onClose}
          dataFromRecover={get(values, "email", "")}
        />
      </CustomModal>
      <CustomModal
        open={openNotThatOneModal}
        onClose={onCloseNotThatOne}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <NotThatLink
          onClose={onCloseNotThatOne}
          dataFromRecover={get(values, "email", "")}
        />
      </CustomModal>
    </>
  );
}

export default RecoverCredentialsStyles(RecoverCredentials);
