import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import strings from "../../utilities/strings";
import { Text, Row } from "../common/ui_kit";
import { color } from "../../utilities/themes";
import { get } from "lodash";
import MobilePorfolioStyles from "./Styles/MobileportfioloStyles";

import { ProfileActions } from "../../redux/actions";

import MobileOptionsFooter from "./MobileOptionsFooter";
import { useHistory } from "react-router";

function MobileProjects(props) {
  const { classes } = props;
  const [state, setState] = useState({
    projectImages: [],
    selectedTab: "",
  });

  const { descriptionTxt, selectedTab, projectImages } = state;

  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    const projectImages = get(props.location.state, "projectImages", []);
    const coverImageUri = get(props.location.state, "actualCoverImageUri", {});
    const projectImagesWithCover = [
      { actualImageUri: coverImageUri },
      ...projectImages,
    ];
    setState({
      ...state,
      projectImages: [...projectImagesWithCover],
      title: get(props.location.state, "title", ""),
      clientele: get(props.location.state, "venue", []),
      settingDisplay: get(props.location.state, "setting", []),
      ballParkCost: get(props.location.state, "ballparkCost", []),
      tagsList: get(props.location.state, "tags", []),
      styleDisplay: get(props.location.state, "style", []),
      index: get(props.location.state, "idx", ""),
      isNonEdit: get(props.location.state, "isPublicMode", true),
      isProj: get(props.location.state, "val", ""),
      description: get(props.location.state, "description", ""),
    });
  }, []);

  const allProjectsData = useSelector(
    (resData) => resData.Profile.projectsByPortfolio
  );
  const portfolioData = useSelector((resData) => resData.Profile.portfolioData);

  //API
  useEffect(() => {
    window.scrollTo(0, 0);
    fetchData();
  }, []);

  const fetchData = () => {
    dispatch(
      ProfileActions.getProjectsByPortfolio(localStorage.getItem("portfolioId"))
    );
    dispatch(
      ProfileActions.getPortfolioData(localStorage.getItem("portfolioId"))
    );
  };

  const redirectTo = (type) => () => {
    const { index } = state;
    if (!allProjectsData) {
      return;
    }
    const { projects } = allProjectsData;
    if (projects) {
      if (
        (type === "previous" && index === 0) ||
        (type === "next" && index === projects.length - 1)
      ) {
        return null;
      }
      const data = type === "next" ? projects[index + 1] : projects[index - 1];
      const projectImages = get(data, "projectImages", []);
      const coverImageUri = get(data, "actualCoverImageUri", {});
      const projectImagesWithCover = [
        { actualImageUri: coverImageUri },
        ...projectImages,
      ];
      setState({
        ...state,
        projectImages: [...projectImagesWithCover],
        title: get(data, "title", ""),
        clientele: get(data, "venue", []),
        styleDisplay: get(data, "style", []),
        settingDisplay: get(data, "setting", []),
        ballParkCost: get(data, "ballparkCost", []),
        description: get(data, "description", ""),
        tagsList: get(data, "tags", []),
        index: type === "next" ? index + 1 : index - 1,
      });
    }
  };

  const renderSlides = () => {
    return projectImages.map((image) => (
      <div>
        <img
          src={
            get(image, "actualImageUri", "/assets/images/gray.PNG") ||
            "/assets/images/gray.PNG"
          }
          className={classes.imageWidth}
          alt=""
        />
        <Text
          size={14}
          color={color.primary_palette.black}
          family="avenir_light"
          className={classes.textLeft}
        >
          {get(image, "projectImgDescription", "")}
        </Text>
      </div>
    ));
  };

  return (
    <>
      <div className={classes.headerAlign}>
        <img src="/assets/images/fr_header.PNG" />
      </div>
      <div className={classes.textCenter}>
        <Text size={14} color={color.primary_palette.black} family="TrajanPro3">
          {get(portfolioData, "name", "")}
        </Text>
        <img
          src="/assets/images/Horizontal_DB.jpg"
          className={classes.horizontal_DB}
          alt=""
        />
        <Text
          size={20}
          color={color.primary_palette.free_purple}
          family="avenir_light"
          transform="uppercase"
          className={classes.titleStyle}
        >
          {get(state, "title", "")}
        </Text>
      </div>
      <div className={classes.padding16}>
        <Row className={classes.buttons_align}>
          {get(state, "index") !== 0 && (
            <Row className={classes.left_previous}>
              <Text
                size={12}
                color={color.primary_palette.black}
                family="avenir_sb"
                onClick={redirectTo("previous")}
                className={classes.clickBtnStyle}
              >
                {strings.add_project.titles.previous_project}
              </Text>
            </Row>
          )}
          {get(state, "index") !==
            get(allProjectsData, "projects.length") - 1 && (
            <Row className={classes.right_previous}>
              <Text
                size={12}
                color={color.primary_palette.black}
                family="avenir_sb"
                onClick={redirectTo("next")}
                className={classes.clickBtnStyle}
              >
                {strings.add_project.titles.next_project}
              </Text>
            </Row>
          )}
        </Row>
        <div className={`${classes.textCenter} ${classes.marginTop34}`}>
          <div>{renderSlides()}</div>
          <Text
            size={20}
            color={color.primary_palette.black}
            family="avenir_light"
            transform="uppercase"
            className={classes.insightAlign}
          >
            {strings.add_project.titles.design_insights}
          </Text>
          <Row>
            <img
              src="/assets/images/Horizontal_DB.jpg"
              className={`${classes.horizontal_DB} ${classes.marginAuto}`}
              alt=""
            />
          </Row>
          <Row className={classes.imageDesc}>
            <Row className={classes.text1}>
              <Text
                size={14}
                color={color.primary_palette.black}
                family="avenir_light"
                className={`${classes.marginSides} ${classes.font_four}`}
              >
                <div
                  dangerouslySetInnerHTML={{
                    __html: get(state, "description", ""),
                  }}
                ></div>
              </Text>
            </Row>
          </Row>
        </div>
      </div>
      <div>
        <MobileOptionsFooter />
      </div>
      <div className={classes.footerAlign}>
        <img src="/assets/images/fr_footer.PNG" />
      </div>
    </>
  );
}

export default MobilePorfolioStyles(MobileProjects);
