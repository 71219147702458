import { get } from "lodash";
import { SettingsTypes } from "../actionTypes";
const initialState = {
  config: null,
  isOpenMarketModal: false,
};

export default (state = initialState, action) => {
  //NOSONAR
  switch (action.type) {
    case SettingsTypes.COMPANY_DATA_BY_USER: {
      return {
        ...state,
        CompanyListByUser: [...action.payload],
      };
    }
    case SettingsTypes.GET_COMPANY_BASICS_DATA: {
      return {
        ...state,
        CompanyBasicData: { ...action.payload },
      };
    }
    case SettingsTypes.PORTFOLIOS_BY_LOCATION: {
      return {
        ...state,
        portfolioByLocation: [...action.payload],
      };
    }
    case SettingsTypes.GET_COMPANY_PORTFOLIO_DATA: {
      return {
        ...state,
        CompanyPortfolioData: { ...action.payload },
      };
    }
    case SettingsTypes.GET_COMPANY_LOCATIONS_DATA: {
      return {
        ...state,
        CompanyLocationsData: { ...action.payload },
      };
    }
    case SettingsTypes.SAVED_SELECTED_PORTFOLIO: {
      return {
        ...state,
        savedSelectedPortfolio: { ...action.payload },
      };
    }
    case SettingsTypes.PORTFOLIO_SAVE: {
      return {
        ...state,
        isMultiplePortfolioSave: action.payload,
      };
    }
    case SettingsTypes.MARKET_MODAL_OPEN: {
      return {
        ...state,
        isOpenMarketModal: action.payload,
      };
    }
    case SettingsTypes.SAVED_PORTFOLIO_OBJECT: {
      const customerCategory = localStorage.getItem("customerCategory");

      const initialLegacyMarkets = [];

      get(state, "CompanyPortfolioData.legacyMarkets", []).map((each) => {
        if (each.isNonExpiredLegacyMarkets) {
          initialLegacyMarkets.push(each.marketName);
        }
      });
      const selectedLegacyMarkets = get(
        action,
        "payload.displayedAt",
        []
      ).filter(
        (each) =>
          initialLegacyMarkets.includes(each.region) &&
          customerCategory !== "LIMBO"
      );
      const selectedNonLegacyMarkets = get(
        action,
        "payload.displayedAt",
        []
      ).filter(
        (each) =>
          !initialLegacyMarkets.includes(each.region) ||
          customerCategory === "LIMBO"
      );
      return {
        ...state,
        savedPortfolioObject: {
          ...action.payload,
          selectedLegacyMarkets,
          selectedNonLegacyMarkets,
        },
      };
    }
    case SettingsTypes.GET_EMAIL_GENERATORS: {
      return {
        ...state,
        emailGenerators: [...action.payload],
      };
    }
    default:
      return state;
  }
};
