import React from "react";
import { find, get } from "lodash";

import { color } from "../../utilities/themes";
import DeleteProjetStyles from "./styles/delete_projectStyles";
import Text from "../common/ui_kit/text";
import strings from "../../utilities/strings";
import Close_Icon from "../data_display/icons/Close";
import CustomButton from "../navigations/custom_buttons";

function DeleteProjectModal(props) {
  const { classes, onClose, deleteProject, projectInfo } = props;

  return (
    <>
      <div className={classes.product_cover}>
        <div className={classes.textRight}>
          <CustomButton className={classes.cross_btn} onClick={onClose} isX>
            <Close_Icon className={classes.cross_mark} onClick={onClose} />
          </CustomButton>
        </div>
        <div className={classes.product_cover_inner_project}>
          <img
            src={get(
              projectInfo,
              "coverImageUri",
              "/assets/images/outsourcing.jpg"
            )}
            className={classes.image_block}
            alt=""
          />
          <Text
            size={20}
            color={color.primary_palette.black}
            family="avenir_light"
            className={`${classes.textCenter} ${classes.padding_20}`}
            transform="uppercase"
          >
            {projectInfo.title}
          </Text>
        </div>
        <Text
          size={25}
          color={color.greyish_brown}
          className={`${classes.textCenter} ${classes.padding_sides}`}
        >
          {strings.add_project.titles.confirmation_project}
        </Text>
        <Text
          size={20}
          color={color.primary_palette.black}
          family="gillsans_light"
          className={classes.textCenter}
        >
          {strings.add_project.titles.action}
        </Text>
        <div className={classes.textCenter}>
          <CustomButton
            className={classes.deleteBtn}
            onClick={deleteProject(projectInfo._id)}
          >
            {strings.add_project.titles.delete_item}
          </CustomButton>
        </div>
      </div>
    </>
  );
}

export default DeleteProjetStyles(DeleteProjectModal);
