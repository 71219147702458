import React from "react";
import { get } from "lodash";
import { TextField, Grid } from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import Close_Icon from "../../components/data_display/icons/Close";

/**
 * TextField component with 3 variations i.e., Standard, Outlined, Filled and the props should be passed from the conatiner/wrapper component
 * @param {*} props
 */
function CustomTextInput(props) {
  const value = get(props, "defaultValue", "") || get(props, "value", "");
  return (
    <>
      {props.icon ? (
        <Grid container spacing={1} alignItems="flex-end">
          <Grid item>{props.icon}</Grid>
          <Grid item>
            <TextField
              autoComplete="nofill"
              {...props}
              InputProps={
                props.InputProps
                  ? { ...props.InputProps }
                  : {
                      endAdornment:
                        props.clearIcon && value.length > 0 ? (
                          <InputAdornment position="end">
                            <Close_Icon
                              onMouseUp={props.onClear}
                              style={{
                                height: "7px",
                                width: "7px",
                                cursor: "pointer",
                                zIndex: "1000",
                                marginRight: "5px",
                              }}
                            />
                          </InputAdornment>
                        ) : null,
                    }
              }
            />
          </Grid>
        </Grid>
      ) : (
        <TextField {...props} autoComplete="nofill"/>
      )}
    </>
  );
}

export default CustomTextInput;
