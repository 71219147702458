import React from "react";
import { get } from "lodash";

import GridStyles from "./styles/gridStyles";
import Row from "../common/ui_kit/row";

function GridNine(props) {
  const { classes, imageData, imageSize } = props;

  return (
    <>
      <div className={classes.publicviewProject}>
        <Row
          className={
            imageSize ? classes.grid_main_spacing_m : classes.grid_main_spacing
          }
        >
          <div>
            <div>
              <img
                alt=""
                className={imageSize ? classes.grid_9_1_m : classes.grid_9_1}
                src={
                  get(imageData[0], "imageUri", "") || "/assets/images/gray.PNG"
                }
              />
            </div>
            <div>
              <img
                alt=""
                className={imageSize ? classes.grid_9_2_m : classes.grid_9_2}
                src={
                  get(imageData[3], "imageUri", "") || "/assets/images/gray.PNG"
                }
              />
            </div>
            <div>
              <img
                alt=""
                className={imageSize ? classes.grid_9_3_m : classes.grid_9_3}
                src={
                  get(imageData[4], "imageUri", "") || "/assets/images/gray.PNG"
                }
              />
            </div>
          </div>

          <div>
            <div>
              <img
                alt=""
                className={imageSize ? classes.grid_9_4_m : classes.grid_9_4}
                src={
                  get(imageData[1], "imageUri", "") || "/assets/images/gray.PNG"
                }
              />
            </div>
            <Row>
              <div>
                <img
                  alt=""
                  className={imageSize ? classes.grid_9_5_m : classes.grid_9_5}
                  src={
                    get(imageData[5], "imageUri", "") ||
                    "/assets/images/gray.PNG"
                  }
                />
              </div>
              <div>
                <div>
                  <img
                    alt=""
                    className={
                      imageSize ? classes.grid_9_6_m : classes.grid_9_6
                    }
                    src={
                      get(imageData[6], "imageUri", "") ||
                      "/assets/images/gray.PNG"
                    }
                  />
                </div>
                <div>
                  <img
                    alt=""
                    className={
                      imageSize ? classes.grid_9_7_m : classes.grid_9_7
                    }
                    src={
                      get(imageData[7], "imageUri", "") ||
                      "/assets/images/gray.PNG"
                    }
                  />
                </div>
              </div>
            </Row>
          </div>
          <div>
            <div>
              <img
                alt=""
                className={imageSize ? classes.grid_9_8_m : classes.grid_9_8}
                src={
                  get(imageData[2], "imageUri", "") || "/assets/images/gray.PNG"
                }
              />
            </div>
            <div>
              <img
                alt=""
                className={imageSize ? classes.grid_9_9_m : classes.grid_9_9}
                src={
                  get(imageData[8], "imageUri", "") || "/assets/images/gray.PNG"
                }
              />
            </div>
          </div>
        </Row>
      </div>
    </>
  );
}

export default GridStyles(GridNine);
