import React from "react";

import { color } from "../../utilities/themes";
import VignetteDeleteStyles from "./styles/deleteModalStyles";
import Text from "../common/ui_kit/text";
import strings from "../../utilities/strings";
import CustomButton from "../navigations/custom_buttons";
import Close_Icon from "../data_display/icons/Close";

function VignetteDelete(props) {
  const { classes, onClose, cropperData, deleteVignette } = props;

  return (
    <>
      <div className={classes.product_cover}>
        <div className={classes.btnRight}>
          <CustomButton className={classes.crossBtn} isX>
            <Close_Icon className={classes.cross_mark} onClick={onClose} />
          </CustomButton>
        </div>
        <div className={classes.textCenter}>
          <img
            alt=""
            src={cropperData.imageUri}
            className={classes.img_block}
          />
        </div>
        <Text
          size={25}
          color={color.greyish_brown}
          className={`${classes.textCenter} ${classes.padding_top_17}`}
        >
          {strings.vignettes.titles.delete_vignette}
        </Text>
        <Text
          size={20}
          color={color.primary_palette.black}
          family="gillsans_light"
          className={classes.textCenter}
        >
          {strings.product_publication.titles.action}
        </Text>
        <div className={classes.textCenter}>
          <CustomButton
            className={classes.deleteBtn}
            onClick={deleteVignette(cropperData._id)}
          >
            {strings.product_publication.titles.delete_item}
          </CustomButton>
        </div>
      </div>
    </>
  );
}

export default VignetteDeleteStyles(VignetteDelete);
