import React from "react";
import { get } from "lodash";
import { color } from "../../utilities/themes";
import { Text } from "../common/ui_kit";
import MobilePorfolioStyles from "./Styles/MobileportfioloStyles";

function MobileCompanyDes(props) {
  const { classes, portfolioData } = props;

  return (
    <div className={classes.company_Bg}>
      {/* get(portfolioData, "coverImgUri", "") */}
      <div>
        <img
          src={get(portfolioData, "coverImgUri", "")}
          className={classes.cover_img}
        />
      </div>
      <div className={classes.company_inner_blk}>
        <Text
          size={15}
          color={color.primary_palette.black}
          family="gillsans_light"
          className={`${classes.descriptionTextStyle} ${classes.txtCenter}`}
        >
          {/* Dramatic,stylish,lushinter */}
          {get(portfolioData, "elevatorPitch.snapshop")}
        </Text>
        <div className={classes.txtCenter}>
          <img
            src={
              get(portfolioData, "profileImgUri", "") ||
              "/assets/images/Gplumbin.jpg"
            }
            className={classes.company_img}
          />
        </div>
        <div>
          <Text
            size={20}
            color={color.primary_palette.black}
            family="avenir_sb"
            transform="uppercase"
            className={classes.descriptionTextStyle}
          >
            {get(portfolioData, "elevatorPitch.phylosophyTitle")}
          </Text>
          <Text
            size={15}
            className={classes.padding_top_5}
            color={color.primary_palette.black}
            family="avenir_sb"
            className={classes.descriptionTextStyle}
          >
            <div
              dangerouslySetInnerHTML={{
                __html: get(portfolioData, "elevatorPitch.description"),
              }}
              className={classes.descriptionTextStyle}
            ></div>
          </Text>
        </div>
        <div className={classes.padding_top_15}>
          <Text
            size={20}
            color={color.primary_palette.black}
            family="avenir_sb"
            transform="uppercase"
            className={classes.descriptionTextStyle}
          >
            {get(portfolioData, "elevatorPitch.expertiseTitle")}
          </Text>
          <Text
            size={15}
            className={classes.padding_top_5}
            color={color.primary_palette.black}
            family="avenir_sb"
            className={classes.descriptionTextStyle}
          >
            <div
              dangerouslySetInnerHTML={{
                __html: get(portfolioData, "elevatorPitch.expertise"),
              }}
            ></div>
          </Text>
        </div>
      </div>
    </div>
  );
}

export default MobilePorfolioStyles(MobileCompanyDes);
