const path = "/api/v1/";
const TOKEN_KEY = "userType";
const queryString = window.location.href;
const SERVER_URL = queryString.includes("stage")
  ? process.env.REACT_APP_SERVER_URL_STAGE
  : process.env.REACT_APP_SERVER_URL;
// const SERVER_URL = process.env.REACT_APP_SERVER_URL;
const SPACK_SERVER_URL = process.env.REACT_APP_SPACK_SERVER_URL;
const LOOKUP_SERVER_URL = process.env.REACT_APP_LOOKUP_SERVER_URL;
const INSTAGRAM_AUTHENTICATION_URL = {
  P1: "https://api.instagram.com/oauth/authorize?client_id=659954428184724",
  P2_DEV:
    "&redirect_uri=https://tradeworks-llama-dev-fe.azurewebsites.net/portfolioView/&scope=user_profile,user_media&response_type=code",
  P2_QA:
    "&redirect_uri=https://tradeworks-llama-qa-fe.azurewebsites.net/portfolioView/&scope=user_profile,user_media&response_type=code",
  P2_STAGE:
    "&redirect_uri=https://tradeworks-llama-stage-fe.azurewebsites.net/portfolioView/&scope=user_profile,user_media&response_type=code",
};
const SPACK_DEV_URL = "tradeworks-spack-dev-fe.azurewebsites.net";
const SPACK_QA_URL = "tradeworks-spack-qa-fe.azurewebsites.net";
const SPACK_STAGE_URL = "tradeworks-spack-stage-fe.azurewebsites.net";
const FACEBOOK_URL = "https://www.facebook.com/";
const TWITTER_URL = "https://twitter.com/";
const INSTAGRAM_URL = "https://instagram.com/";
const FRANKLIN_QA = "http://52.170.129.20:8087";
const FRANKLIN_STAGE = "http://52.170.129.20:44330";
const FRANKLIN_PROD = "https://www.franklinreport.com";
const FRANKLIN_DEFAULT_STAGE = "http://52.170.129.20:44330/Logout.aspx";
const FRANKLIN_DEFAULT_QA = "http://52.170.129.20:8087/Logout.aspx";
const FRANKLIN_DEFAULT_PROD = "https://www.franklinreport.com/Logout.aspx";
const LLAMA_DEV_URL = "tradeworks-llama-dev-fe.azurewebsites.net";
const LLAMA_QA_URL = "tradeworks-llama-qa-fe.azurewebsites.net";
const LLAMA_STAGE_URL = "tradeworks-llama-stage-fe.azurewebsites.net";
const LLAMA_PROD_URL = "https://llama.franklinreport.com";

const URI = {
  GET_REGIONS: `${path}regions`,
  GET_CATEGORIES: `${path}categories`,
  SAVE_PROFILE_IMAGES: `${path}update-profile-cover-logo-photo`,
  SAVE_COMPANY_BASICS_PROFILE_IMAGES: `${path}update-company-logo-headshot`,
  UPDATE_COMPANY_LOGO: `${path}update-company-logo-headshot`,
  SAVE_ELEVATOR_PITCH: `${path}create-or-update-elevatorpitch`,
  PROJECT_SELECTION: `${path}project-selections`,
  PROJECT_IMAGE_SELECTION: `${path}project-image-selections`,
  CREATE_UPDATE_PROJECT: `${path}create-or-update-project`,
  CREATE_PRODUCT_PUBLICATION: `${path}create-product-or-publication`,
  GET_PRODUCTS_PUBLICATION_DATA: `${path}list-products-publications/`,
  DELETE_PRODUCT_PUBLICATION: `${path}product-or-publications/`,
  UPLOAD: `${path}upload-files`,
  UPDATE_PRODUCTS_PUBLICATIONS: `${path}update-products-or-publications`,
  GET_ALL_PROJECT_DATA: `${path}all-projects`,
  PORTFOLIO_DATA: `${path}portfolio/`,
  PORTFOLIO_DATA_BY_SLUG: `${path}portfolio-slug/`,
  CREATE_UPDATE_VIGNETTE: `${path}create-update-vignette`,
  DELETE_VIGNETTE: `${path}vignette-image/`,
  GET_PROJECT_DATA: `${path}project/`,
  DELETE_PROJECT: `${path}project/`,
  JOINUS: `${path}joinus/`,
  LOGIN: `${path}login/`,
  RECOVER: `${path}forgot-password`,
  RESET_PASSWORD: `${path}reset-password`,
  PROJECT_SORT: `${path}change-project-order`,
  PRODUCT_SORT: `${path}change-product-publication-order`,
  INSTA_VERIFICATION_CODE: `${path}save-instagram-credentials`,
  GET_INSTA_FEEDS: `${path}get-instagram-feeds`,
  ASSIGN_PORTFOLIO_BUSINESSCARD: `${path}assign-businesscard-for-portfolio`,
  GET_COVER_PROFILE_PHOTOS: `${path}get-profile-or-cover-photo/`,
  GET_COMPANIES: `${path}list-companies`,
  GET_PORTFOLIOS: `${path}get-portfolios`,
  COMPANY_BY_USER: `${path}companies-by-user`,
  COMPANY_BASICS_DATA: `${path}company-basics/`,
  PORTFOLIOS_BY_LOCATION: `${path}portfolios-by-office-locationId/`,
  COMPANY_PORTFOLIOS_DATA: `${path}portfolios-by-company/`,
  COMPANY_LOCATIONS_DATA: `${path}office-locations-by-company/`,
  UPDATE_PORTFOLIO_DATA: `${path}update-portfolio-basics/`,
  SORT_VIGNETTES: `${path}change-vignette-order`,
  UPDATE_COMPANY_INFO: `${path}update-company/`,
  PORTFOLIO_DUPE: `${path}portfolio-dupe`,
  GET_LOOKUPS: `${path}lookups`,
  GET_PROFILE_DATA: `${path}user-info`,
  UPDATE_PROFILE_DATA: `${path}update-user-info`,
  PAYMENT_PORTFOLIO: `${path}portfolio-payment`,
  PRINT_PORTFOLIO_INVOICE: `${path}generate-invoice`,
  UPDATE_OFFICE_LOCATIONS: `${path}add-or-update-businesscard/`,
  GET_PROJECTS_DATA_BY_PORTFOLIO: `${path}projects-by-portfolio/`,
  CONNECT_PORTFOLIO: `${path}connect-portfolio`,
  PAYPAL_PAYMENT: `${path}portfolio-paypal-payment`,
  SHARE_INVITE: `${path}share-portfolio`,
  DELETE_COMPANY_ADMIN: `${path}delete-company-admin/`,
  GENERATE_INVOICE: `${path}generate-invoice/`,
  DELETE_PORTFOLIO: `${path}delete-portfolio`,
  TRANSACTION_RENEW: `${path}transaction-autorenew`,
  PORTFOLIO_OFFICE_LOCATION_UPDATE: `${path}update-portfolio-office-locations`,
  DELETE_PORTFOLIO_IMAGES: `${path}delete-portfolio-images/`,
  ACT_AS_USER: `${path}/inpersonate-user`,
  DELETE_BUSINESS_MARKET_CATEGORY: `${path}/delete-businesscard-market-category/`,
  SEND_FEEDBACK: `${path}send-feedback/`,
  EMAIL_GENERATOR: `${path}send-portfolio-email-generator`,
  GET_ALL_EMAIL_GENERATORS: `${path}get-all-email-generators`,
};

const SPACK_URI = {
  GET_PROFILE_DATA: `${path}get-user-info`,
  UPDATE_PROFILE_DATA: `${path}update-user-info`,
  JOINUS: `${path}joinus/`,
  LOGIN: `${path}login/`,
};

const LOOKUP_URI = {
  GET_COMPANY_PROFILE_DATA: `${path}get-company-info`,
  TRADES_LIST: `${path}trade/all`,
  GET_ACCOLADES: `${path}get-accolades`,
  GET_CONFIG: `${path}get-config-value`,
  GET_VIBES: `${path}get-company-vibe`,
  GET_CLIENTEL: `${path}get-clientel`,
  GET_VENUE: `${path}get-venue`,
  UPDATE_COMPANY_INFO: `${path}update-company-info`,
  REMOVE_COMPANY_ADMIN: `${path}remove-company-admin`,
};

const MAX_INPUT_COUNT = 200;
const MARKET_PRICE = 600;
const MARKET_DISCOUNT = 0;
const MAX_UPLOAD_SIZE = 5 * 1024 * 1024; //   5 MB
const COVER_IMAGE_URI_HOVER =
  "https://trablobdev.blob.core.windows.net/tra-con-use-dev/temp/1605017420889.jpg";
const COVER_IMAGE_URI =
  "https://trablobdev.blob.core.windows.net/tra-con-use-dev/temp/1613645189867.jpg";
const WING_ARROWS =
  "https://trablobdev.blob.core.windows.net/tra-con-use-dev/temp/1596625442309.png";
const PROD_PLACEHOLDER =
  "https://trablobdev.blob.core.windows.net/tra-con-use-dev/temp/1597222562698.PNG";
const ARROW_IMG =
  "https://trablobdev.blob.core.windows.net/tra-con-use-dev/temp/1616493337377.png";
const ENVELOP_ICON =
  "https://trablobdev.blob.core.windows.net/tra-con-use-dev/temp/1596625259789.png";
const BW_SNAPSHOT =
  "https://trablobdev.blob.core.windows.net/tra-con-use-dev/temp/1635145788330.png";
const COLOR_SNAPSHOT =
  "https://trablobdev.blob.core.windows.net/tra-con-use-dev/temp/1635145730314.png";

export {
  URI,
  SERVER_URL,
  TOKEN_KEY,
  SPACK_SERVER_URL,
  LOOKUP_SERVER_URL,
  MAX_INPUT_COUNT,
  MAX_UPLOAD_SIZE,
  INSTAGRAM_AUTHENTICATION_URL,
  COVER_IMAGE_URI,
  WING_ARROWS,
  PROD_PLACEHOLDER,
  ARROW_IMG,
  ENVELOP_ICON,
  SPACK_URI,
  LOOKUP_URI,
  SPACK_DEV_URL,
  SPACK_QA_URL,
  SPACK_STAGE_URL,
  FACEBOOK_URL,
  TWITTER_URL,
  INSTAGRAM_URL,
  FRANKLIN_QA,
  LLAMA_DEV_URL,
  LLAMA_QA_URL,
  LLAMA_STAGE_URL,
  FRANKLIN_DEFAULT_QA,
  MARKET_PRICE,
  MARKET_DISCOUNT,
  FRANKLIN_STAGE,
  FRANKLIN_DEFAULT_STAGE,
  COVER_IMAGE_URI_HOVER,
  BW_SNAPSHOT,
  COLOR_SNAPSHOT,
  LLAMA_PROD_URL,
  FRANKLIN_PROD,
  FRANKLIN_DEFAULT_PROD,
};
