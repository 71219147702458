import React, { useState } from "react";
import { get } from "lodash";
import { useDispatch } from "react-redux";

import { color } from "../../utilities/themes";
import { Slider } from "@material-ui/core";
import ProjectImageCropperStyles from "./styles/projectImageCropperStyles";
import Row from "../common/ui_kit/row";
import Text from "../common/ui_kit/text";
import strings from "../../utilities/strings";
import Close_Icon from "../data_display/icons/Close";
import CustomButton from "../navigations/custom_buttons";
import CustomCropper from "../cropper/custom_cropper";
import getCroppedImg from "../cropper/crop_image";

import { ApiActions } from "../../redux/actions";

function AlterImageCropper(props) {
  const {
    classes,
    onClose,
    image,
    alterCroppedImage,
    cropSize,
    toggleImageExp,
    type,
    transparentColor,
    errors,
  } = props;
  const dispatch = useDispatch();

  const [values, setValues] = useState({
    portfolioId: localStorage.getItem("portfolioId"),
    zoom: 1,
    crop: { x: 0, y: 0 },
    colorValue: transparentColor,
  });

  const showCroppedImage = async () => {
    const { croppedAreaPixels } = values;
    try {
      ApiActions.request(dispatch);
      let urlObj = "";
      urlObj = image;
      const croppedImage = await getCroppedImg(
        urlObj,
        croppedAreaPixels,
        values.colorValue
      );
      const obj = {
        showCropper: false,
      };
      obj.croppedImage = URL.createObjectURL(croppedImage);
      obj.blobUrl = croppedImage;
      setValues({ ...values, obj });
      alterCroppedImage(obj.croppedImage, obj);
      onClose && onClose();
      ApiActions.success(dispatch);
    } catch (e) {
      ApiActions.failure(dispatch);
      console.error(e);
    }
  };

  const onCropComplete = (coords) => {
    const croppedAreaPixels = get(coords, "main.croppedAreaPixels", "");
    setValues({ ...values, croppedAreaPixels });
  };

  const setCrop = (value) => {
    setValues({ ...values, crop: value });
  };

  const changeBgColor = (colorVal) => () => {
    setValues({ ...values, colorValue: colorVal });
  };

  const setZoom = (value) => {
    setValues({ ...values, zoom: value });
  };

  const { zoom, crop, colorValue } = values;
  return (
    <>
      <div className={classes.copperModal}>
        <div className={classes.btnRight}>
          <CustomButton className={classes.crossBtn} isX>
            <Close_Icon onClick={onClose} />
          </CustomButton>
        </div>
        <div className={classes.copperModalContent}>
          {type == "projectImage" ? (
            <Text
              size={22.5}
              color={color.form_colors.blueberry_purple}
              className={`${classes.textCenter}`}
              family="avenir_light"
              transform="uppercase"
            >
              • COMPOSE YOUR IMAGE •
            </Text>
          ) : (
            <Text
              size={22.5}
              color={color.form_colors.blueberry_purple}
              className={`${classes.textCenter} ${classes.optimal_text}`}
              transform="uppercase"
            >
              This cover photo will set the tone for your portfolio
            </Text>
          )}
          <div style={{ backgroundColor: colorValue }}>
            <div className={classes.cropperDiv}>
              <CustomCropper
                image={image}
                crop={crop}
                zoom={zoom}
                cropShape={"rect"}
                aspect={cropSize}
                onCropChange={setCrop}
                onCropComplete={onCropComplete}
                onZoomChange={setZoom}
                showGrid={false}
              />
            </div>
          </div>
          <div className={classes.slider_width}>
            <Slider
              value={zoom}
              min={0.4}
              max={3}
              step={0.1}
              aria-labelledby="Zoom"
              classes={classes.slider}
              onChange={(e, zoomVal) => setZoom(zoomVal)}
            />
          </div>
          <div className={classes.textCenter}>
            <Text
              size={15}
              color={color.secondary_palette.grays.drag_text}
              family="avenir_light"
            >
              {strings.add_image.titles.drag_img}
            </Text>
            <Text
              size={15}
              color={color.secondary_palette.grays.drag_text}
              family="avenir_light"
            >
              {strings.add_image.titles.drag_zoom}
            </Text>
          </div>
          <Row className={classes.textCenter}>
            <Text
              size={15}
              color={color.secondary_palette.grays.choose_cover}
              family="avenir_light"
              className={classes.choose_bg_txt}
            >
              {strings.add_image.titles.chosse_bg}
            </Text>
            <span
              className={
                colorValue === "#000000"
                  ? classes.choose_black_active
                  : classes.choose_black
              }
              onClick={changeBgColor("#000000")}
            ></span>
            <span
              className={
                colorValue === "#d8d8d8"
                  ? classes.choose_gray_active
                  : classes.choose_gray
              }
              onClick={changeBgColor("#d8d8d8")}
            ></span>
            <span
              className={
                colorValue === transparentColor
                  ? classes.choose_white_active
                  : classes.choose_white
              }
              onClick={changeBgColor(transparentColor)}
            >
              <img
                src="/assets/images/Transparency Tile.svg"
                alt=""
                className={classes.checkeredAlign}
              />{" "}
            </span>
          </Row>
        </div>
        {get(errors, "image") && (
          <Row className={`${classes.textCenter} ${classes.error}`}>
            <div className={classes.marginAuto}>
              <img
                src="/assets/icons/info_1.svg"
                class="info_img"
                alt=""
                style={{ verticalAlign: "middle", marginRight: "4px" }}
              />
              {get(errors, "image")}
            </div>
          </Row>
        )}
        <Row className={classes.justifyCenter}>
          <CustomButton
            className={classes.save_btn}
            name="openUpload"
            onClick={() => toggleImageExp(true)}
          >
            {strings.add_image.titles.chooseimg}
          </CustomButton>
          <CustomButton className={classes.save_btn} onClick={showCroppedImage}>
            {strings.vignettes.titles.save_continue}
          </CustomButton>
        </Row>
      </div>
    </>
  );
}

export default ProjectImageCropperStyles(AlterImageCropper);
