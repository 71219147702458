import { withStyles } from "@material-ui/core/styles";
import tradework_theme, { pxToRem, color } from "../../../utilities/themes";

const styles = withStyles({
  BusinessCard_width: {
    width: pxToRem(416),
    border: `solid ${pxToRem(2)} ${color.primary_palette.franklin_purple}`,
    backgroundColor: color.secondary_palette.blues.modal_blue,
    paddingRight: pxToRem(20),
    paddingLeft: pxToRem(20),
    paddingTop: pxToRem(30),
    display: "block",
    marginTop: pxToRem(10),
    boxShadow: `${pxToRem(2)} ${pxToRem(2)} ${pxToRem(4)} ${pxToRem(
      0
    )} rgba(0, 0, 0, 0.5)`,
    position: "relative",
  },
  companies: {
    width: `100%`,
    "& .MuiFormControl-root": {
      "&:hover": {
        border: `${pxToRem(0)} !important`,
      },
    },
    "& .MuiInputBase-root": {
      paddingRight: `${pxToRem(0)} !important`,
    },
    "& .MuiAutocomplete-input": {
      padding: `${pxToRem(0)} !important`,
      fontSize: `${pxToRem(37)} !important`,
      textAlign: "center",
      ...tradework_theme.typography.styles.millerdisplay_light,
      "&::placeholder": {
        color: color.secondary_palette.grays.shadow_gray,
        ...tradework_theme.typography.styles.millerdisplay_light,
        opacity: 1,
      },
    },
    "& .MuiInputBase-input": {
      padding: `${pxToRem(0)} !important`,
      fontSize: pxToRem(37),
      textAlign: "center",
    },
    "& .MuiFormControl-marginNormal": {
      marginTop: `${pxToRem(0)} !important`,
      marginBottom: pxToRem(0),
    },
    "& .MuiInput-underline": {
      "&:before": {
        borderBottom: `0 !important`,
      },
    },
    "& .MuiSvgIcon-root": {
      display: "none",
    },
  },
  close_mark: {
    fontSize: pxToRem(15),
    paddingTop: pxToRem(16),
    paddingRight: pxToRem(16),
    cursor: "pointer",
  },
  country_dropdown: {
    width: pxToRem(69),
    bottom: 0,
    position: "absolute",
    right: 0,
    paddingBottom: pxToRem(2),
    backgroundColor: "transparent !important",
    "& :before": {
      borderBottom: `${pxToRem(1)} solid rgba(0, 0, 0, 0.42)`,
    },
    "& :after": {
      borderBottom: `${pxToRem(2)} solid ${
        color.primary_palette.franklin_purple
      }`,
    },
    border: "none !important",
    "& .MuiSvgIcon-root": {
      height: pxToRem(5),
      width: pxToRem(11),
      marginBottom: pxToRem(3),
    },
  },
  country_item_row: {
    textOverflow: "ellipsis",
    width: "100%",
  },
  position_relative: {
    position: "relative",
  },
  flag_blk: {
    height: pxToRem(17),
    borderBottom: `solid ${pxToRem(1)} ${color.primary_palette.black}`,
  },
  width_50: {
    width: "50%",
    position: "relative",
  },
  padding_right_15: {
    paddingRight: pxToRem(15),
  },
  address_input: {
    width: "100%",
    "& .MuiInputBase-input": {
      textAlign: "center",
      ...tradework_theme.typography.styles.avenir_light,
      fontSize: pxToRem(16),
    },
    "& .MuiInput-underline": {
      "&:before": {
        display: "none",
      },
      "&:after": {
        display: "none",
      },
    },
  },
  input_field: {
    width: "100%",
    "& .MuiInputBase-input": {
      textAlign: "center",
      ...tradework_theme.typography.styles.avenir_light,
      fontSize: pxToRem(16),
    },
    "& .MuiInput-underline": {
      "&:before": {
        display: "none",
      },
      "&:after": {
        display: "none",
      },
    },
  },
  card_spacing: {
    padding: `${pxToRem(20)} ${pxToRem(0)}`,
  },
  pencil_icon: {
    width: pxToRem(12),
    height: pxToRem(12),
    position: "absolute",
    padding: pxToRem(3),
    border: `solid ${pxToRem(1)} ${color.primary_palette.black}`,
    right: pxToRem(0),
    backgroundColor: color.primary_palette.white,
    marginBottom: pxToRem(10),
    cursor: "pointer",
  },
  margin_bottom_9: {
    marginBottom: pxToRem(9),
  },
  txtCenter: {
    textAlign: "center",
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
  },
  txtRight: {
    textAlign: "right",
  },
  flag_icon: {
    width: pxToRem(14),
    height: pxToRem(9),
    paddingRight: pxToRem(3),
  },
  drpDown: {
    fontSize: pxToRem(10),
    verticalAlign: "middle",
    paddingLeft: pxToRem(5),
  },
  modal_bussinesscard: {
    width: pxToRem(742),
    backgroundColor: color.primary_palette.fafa_gray,
    border: `solid ${pxToRem(2)} ${color.primary_palette.franklin_purple}`,
  },
  main_text: {
    display: "block",
    paddingLeft: pxToRem(10),
  },
  align_right: {
    textAlign: "right",
  },
  cross_mark: {
    padding: pxToRem(3),
    minWidth: pxToRem(30),
    "& .MuiSvgIcon-root": {
      fontSize: pxToRem(9),
    },
  },
  office_edit_bussinesscard: {
    width: pxToRem(620),
    backgroundColor: color.secondary_palette.blues.modal_blue,
    border: `solid ${pxToRem(2)} ${color.primary_palette.franklin_purple}`,
    position: "relative",
  },
  close_mark_edit: {
    fontSize: pxToRem(9),
    paddingTop: pxToRem(15),
    paddingRight: pxToRem(15),
    cursor: "pointer",
    "& path": {
      stroke: color.pedagogical_bronze,
    },
  },
  edit_icon: {
    fontSize: pxToRem(55),
    position: "absolute",
    top: pxToRem(-30),
    left: "48%",
  },
  BusinessCard_width_edit: {
    width: pxToRem(499),
    margin: "0 auto",
    border: `solid ${pxToRem(1)} ${color.secondary_palette.grays.shadow_gray}`,
    paddingTop: 0,
    display: "block",
    position: "relative",
    backgroundColor: color.primary_palette.white,
  },
  BusinessCard_inner_edit: {
    padding: `${pxToRem(0)} ${pxToRem(11)}`,
    margin: pxToRem(14),
    border: `solid ${pxToRem(2)} ${color.secondary_palette.grays.shadow_gray}`,
    paddingBottom: pxToRem(13),
  },
  txtUppercase: {
    textTransform: "uppercase",
  },
  padding_left_20: {
    paddingLeft: pxToRem(20),
  },
  border_bottom: {
    width: pxToRem(215),
    paddingTop: pxToRem(6),
    "& .MuiInputBase-input": {
      textAlign: "left",
      // ...tradework_theme.typography.styles.avenir_sb,
      ...tradework_theme.typography.styles.NeutraText,
      // paddingLeft: pxToRem(14),
    },
    "& .MuiInput-underline": {
      borderBottom: `${pxToRem(1)} solid ${
        color.secondary_palette.grays.shadow_gray
      }`,
      "&:before": {
        // borderBottom: `0 !important`,
        bottom: pxToRem(-1),
      },
      "&:after": {
        // borderBottom: `0 !important`,
        bottom: pxToRem(-1),
      },
      "&:hover": {
        "&:not(.Mui-disabled)": {
          "&:before": {
            borderBottom: "0 !important",
          },
        },
      },
    },
    "& .MuiFormLabel-root": {
      width: "100%",
      fontSize: pxToRem(14),
      // ...tradework_theme.typography.styles.avenir_light,
      ...tradework_theme.typography.styles.avenir_sb,
      // fontStyle: "oblique",
    },
    "& .MuiInputLabel-shrink": {
      fontSize: pxToRem(10),
      color: color.primary_palette.franklin_purple,
      ...tradework_theme.typography.styles.avenir_black_r,
      transform: "translate(0, 12.5px) scale(0.75)",
    },
  },
  margin_top_10: {
    marginTop: pxToRem(10),
  },
  padding_right_35: {
    paddingRight: pxToRem(35),
  },
  padding_bottom_15: {
    paddingBottom: pxToRem(15),
  },
  select_field: {
    borderBottom: `solid ${pxToRem(1)} ${
      color.secondary_palette.grays.shadow_gray
    }`,
    width: "100%",
    "& .MuiInputBase-root": {
      borderBottom: 0,
    },
    "& .MuiInput-underline": {
      "&:before": {
        display: "none",
      },
      "&:after": {
        display: "none",
      },
    },
    "& .MuiInputBase-input": {
      fontSize: pxToRem(25),
      // ...tradework_theme.typography.styles.TrajanPro3,
      ...tradework_theme.typography.styles.NeutraText,
      color: color.primary_palette.black,
      textTransform: "uppercase",
      textAlign: "center",
    },
    "& .MuiFormLabel-root": {
      width: "100%",
      fontSize: pxToRem(25),
      color: color.secondary_palette.grays.shadow_gray,
      // fontStyle: "oblique",
      textAlign: "center",
      ...tradework_theme.typography.styles.avenir_sb,
    },
    "& .MuiInputLabel-shrink": {
      display: "none",
    },
  },
  select_field_error: {
    width: "100%",
    "& .MuiInputBase-root": {
      borderBottom: 0,
    },
    "& .MuiInput-underline": {
      "&:before": {
        display: "none",
      },
      "&:after": {
        display: "none",
      },
    },
    "& .MuiInputBase-input": {
      fontSize: pxToRem(25),
      ...tradework_theme.typography.styles.NeutraText,
      color: color.primary_palette.black,
      textTransform: "uppercase",
      textAlign: "center",
      borderBottom: `${pxToRem(1)} solid ${
        color.primary_palette.christmas_red
      }`,
    },
    "& .MuiFormLabel-root": {
      width: "100%",
      fontSize: pxToRem(25),
      ...tradework_theme.typography.styles.avenir_sb,
      color: color.secondary_palette.grays.shadow_gray,
      // fontStyle: "oblique",
      textAlign: "center",
    },
    "& .MuiInputLabel-shrink": {
      display: "none",
    },
  },
  border_top: {
    borderTop: `solid ${pxToRem(1)} ${
      color.secondary_palette.grays.shadow_gray
    }`,
  },
  field_icon: {
    fontSize: pxToRem(10),
    color: color.form_colors.blueberry_purple,
    position: "absolute",
    left: pxToRem(0),
    bottom: pxToRem(5),
  },
  required_error: {
    fontSize: pxToRem(10),
    color: color.primary_palette.christmas_red,
    position: "absolute",
    left: pxToRem(0),
    bottom: pxToRem(5),
  },
  pos_relative: {
    position: "relative",
  },
  lil_filled: {
    fontSize: pxToRem(16),
    verticalAlign: "middle",
    paddingLeft: pxToRem(11),
    "& path": {
      "&:nth-child(1)": {
        fill: color.secondary_palette.grays.shadow_gray,
      },
    },
  },
  width_482: {
    width: pxToRem(482),
    margin: "0 auto",
  },
  padding_right_50: {
    paddingRight: pxToRem(50),
    paddingTop: pxToRem(10),
  },
  continue_btn: {
    width: pxToRem(120),
    height: pxToRem(25),
    borderRadius: pxToRem(27),
    marginBottom: pxToRem(30),
    border: `solid ${pxToRem(1)} ${color.primary_palette.franklin_purple}`,
    backgroundColor: color.primary_palette.white,
    marginTop: pxToRem(20),
    "& .MuiButton-label": {
      fontSize: pxToRem(15),
      ...tradework_theme.typography.styles.avenir_sb,
      color: color.primary_palette.franklin_purple,
    },
  },
  continue_btn_disable: {
    width: pxToRem(186),
    height: pxToRem(43),
    borderRadius: pxToRem(27),
    marginBottom: pxToRem(30),
    border: `solid ${pxToRem(2)} ${color.primary_palette.disable_border}`,
    backgroundColor: color.primary_palette.white,
    marginTop: pxToRem(20),
    "& .MuiButton-label": {
      fontSize: pxToRem(20),
      ...tradework_theme.typography.styles.avenir_sb,
    },
  },
  padding_top_15: {
    paddingTop: pxToRem(15),
  },
  cursor: {
    cursor: "pointer",
  },
  text_info: {
    ...tradework_theme.typography.styles.avenir_roman,
    fontSize: pxToRem(15),
    color: color.primary_palette.black,
  },
  error_text_email: {
    position: "absolute",
    top: pxToRem(2),
  },
  last_field: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  },
  city_field_card: {
    width: `${pxToRem(175)} !important`,
    "& .MuiFormLabel-root": {
      paddingLeft: pxToRem(14),
    },
    "& .MuiInputBase-input": {
      paddingLeft: pxToRem(14),
    },
  },
  st_field: {
    width: `${pxToRem(28)} !important`,
  },
  zip_field: {
    width: `${pxToRem(46)} !important`,
  },
  phone_field: {
    width: `${pxToRem(165)} !important`,
    "& .MuiFormLabel-root": {
      paddingLeft: pxToRem(14),
    },
    "& .MuiInputBase-input": {
      paddingLeft: pxToRem(14),
      paddingRight: pxToRem(41),
    },
  },
  email_field: {
    width: `${pxToRem(218)} !important`,
    "& .MuiFormLabel-root": {
      paddingLeft: pxToRem(14),
    },
    "& .MuiInputBase-input": {
      paddingLeft: pxToRem(14),
    },
  },
  downArrowIcon: {
    fontSize: pxToRem(10),
    cursor: "pointer",
  },
  flag_block: {
    position: "absolute",
    bottom: 0,
    right: 0,
  },
  usaFlagStyle: {
    width: pxToRem(20),
    height: pxToRem(15),
    paddingRight: pxToRem(5),
  },
  dropdown_content: {
    backgroundColor: color.primary_palette.white,
    width: pxToRem(74),
    border: `solid ${pxToRem(1)} ${color.notorious_ivory}`,
    cursor: "pointer",
    borderRadius: pxToRem(4),
    fontSize: pxToRem(14),
    padding: `${pxToRem(5)} ${pxToRem(2)} ${pxToRem(5)}  ${pxToRem(2)}`,
    position: "absolute",
    right: pxToRem(-28),
    zIndex: 9,
    "& li": {
      listStyle: "none",
    },
  },
  padding_left_57: {
    paddingLeft: pxToRem(57),
  },
  select_field_required: {
    fontSize: pxToRem(14),
    color: color.primary_palette.franklin_purple,
    position: "absolute",
    left: pxToRem(0),
    top: pxToRem(10),
  },
  select_field_required_error: {
    fontSize: pxToRem(14),
    color: color.primary_palette.christmas_red,
    position: "absolute",
    left: pxToRem(0),
    top: pxToRem(10),
  },
  error_field: {
    "& .MuiFormLabel-root": {
      width: "100%",
      fontSize: pxToRem(14),
      ...tradework_theme.typography.styles.avenir_light,
      // fontStyle: "oblique",
      color: `${color.primary_palette.christmas_red} !important`,
    },
    "& .MuiInputLabel-shrink": {
      fontSize: pxToRem(10),
      color: `${color.primary_palette.christmas_red} !important`,
      ...tradework_theme.typography.styles.avenir_black_r,
    },
    "& .MuiInput-underline": {
      borderBottom: `${pxToRem(1)} solid ${
        color.primary_palette.christmas_red
      } !important`,
      "&:after": {
        borderBottom: `${pxToRem(2)} solid ${
          color.primary_palette.christmas_red
        } !important`,
      },
    },
  },
  txtCapitalise: {
    textTransform: "capitalize",
  },
  bold_text: {
    ...tradework_theme.typography.styles.avenir_bold,
    color: color.primary_palette.black,
  },
  dropDown: {
    ...tradework_theme.typography.styles.avenir_light,
    color: color.primary_palette.black,
    fontSize: pxToRem(14),
  },
  choose_number: {
    width: pxToRem(422),
    height: pxToRem(20),
    borderRadius: pxToRem(3),
    border: `${pxToRem(1)} solid ${color.secondary_palette.grays.shadow_gray}`,
    backgroundColor: color.primary_palette.white,
    "& .MuiSvgIcon-root": {
      fontSize: pxToRem(14),
    },
    "& p": {
      paddingLeft: pxToRem(18),
    },
  },
  padding_bottom_5: {
    paddingBottom: pxToRem(5),
    paddingTop: pxToRem(10),
  },
  padding_bottom_10: {
    paddingBottom: pxToRem(10),
  },
  padding_top_5: {
    paddingTop: pxToRem(5),
  },
  add_new_office: {
    paddingTop: pxToRem(10),
    paddingBottom: pxToRem(24),
  },
  add_new: {
    "&:hover": {
      "& .MuiSvgIcon-root": {
        "& path": {
          "&:nth-child(1)": {
            fill: color.primary_palette.franklin_purple,
          },
        },
      },
    },
  },
  flexRight: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  stateZipAlign: {
    display: "inline-block",
    width: pxToRem(100),
  },
  websiteTxt: {
    textDecoration: "none !important",
  },
  emailActiveStyle: {
    cursor: "pointer",
    color: color.primary_palette.franklin_purple,
  },
});

export default styles;
