import { ApiActionTypes } from "../actionTypes";

const initialState = {
  loading: false,
  horseLoading: false,
};

export default (state = initialState, action) => {
  //NOSONAR
  switch (action.type) {
    case ApiActionTypes.REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case ApiActionTypes.SUCCESS:
    case ApiActionTypes.FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }
    case ApiActionTypes.HORSEREQUEST: {
      return {
        ...state,
        horseLoading: true,
      };
    }
    case ApiActionTypes.HORSESUCCESS:
    case ApiActionTypes.HORSEFAILURE: {
      return {
        ...state,
        horseLoading: false,
      };
    }

    default:
      return state;
  }
};
