import { withStyles } from "@material-ui/core/styles";
import tradework_theme, { pxToRem, color } from "../../../utilities/themes";

const styles = withStyles({
  project_dashboard: {
    width: pxToRem(1180),
    border: `solid ${pxToRem(2)} ${color.primary_palette.free_purple}`,
    backgroundColor: color.secondary_palette.blues.modal_blue,
    position: "relative",
  },
  btnRight: {
    textAlign: "right",
  },
  add_project_btn: {
    textAlign: "right",
    "&:hover": {
      "& ellipse": {
        fill: color.primary_palette.franklin_purple,
      },
    },
  },
  txtEllipses: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  ovalNumber: {
    backgroundColor: color.primary_palette.franklin_purple,
    height: pxToRem(16),
    width: pxToRem(16),
    textAlign: "center",
    borderRadius: "50%",
    color: color.primary_palette.white,
    fontSize: pxToRem(14),
    ...tradework_theme.typography.styles.Helvetica_bold,
    marginTop: pxToRem(19),
  },
  ovalDisabledNumber: {
    backgroundColor: color.secondary_palette.grays.shadow_gray,
    height: pxToRem(16),
    width: pxToRem(16),
    textAlign: "center",
    borderRadius: "50%",
    color: color.primary_palette.white,
    fontSize: pxToRem(14),
    ...tradework_theme.typography.styles.Helvetica_bold,
    marginTop: pxToRem(19),
  },
  crossBtn: {
    minWidth: pxToRem(30),
    padding: pxToRem(16),
    "& .MuiSvgIcon-root": {
      fontSize: pxToRem(15),
    },
  },
  textCenter: {
    textAlign: "center",
  },
  edit_icon: {
    fontSize: pxToRem(60),
    position: "absolute",
    top: pxToRem(-35),
    left: "47%",
  },
  flagship_blk: {
    width: pxToRem(298),
    border: `solid ${pxToRem(1)} ${color.primary_palette.free_purple}`,
    backgroundColor: color.secondary_palette.blues.tile_blue,
    position: "relative",
    padding: pxToRem(8),
    paddingBottom: pxToRem(0),
    marginBottom: pxToRem(20),
    marginRight: pxToRem(37),
    zIndex: 99999,
    display: "inline-block",
    "&:hover": {
      transform: "scale(1.05)",
    },
  },
  flagship_blk_active: {
    width: pxToRem(328),
    border: `solid ${pxToRem(1.1)} ${color.primary_palette.free_purple}`,
    backgroundColor: color.secondary_palette.blues.tile_blue,
    position: "relative",
    padding: `${pxToRem(6)} ${pxToRem(6)} ${pxToRem(5)} ${pxToRem(8)}`,
    height: pxToRem(231),
    marginBottom: pxToRem(20),
    marginRight: pxToRem(37),
    zIndex: 99999,
    display: "inline-block",
    boxShadow: `${pxToRem(5)} ${pxToRem(5)} ${pxToRem(5)} 0 rgba(0, 0, 0, 0.4)`,
  },
  flagship_img: {
    width: pxToRem(274),
    height: pxToRem(182),
    // backgroundColor: color.secondary_palette.grays.background_gray,
    "& img": {
      width: "100%",
      height: "100%",
      objectFit: "cover",
    },
  },
  flagship_img_active: {
    width: pxToRem(301),
    height: pxToRem(200),
    "& img": {
      width: "100%",
      height: "100%",
      objectFit: "cover",
    },
  },
  trash_icon: {
    fontSize: pxToRem(17),
    display: "block",
    paddingBottom: pxToRem(6),
    cursor: "pointer",
  },
  hamburger_icon: {
    width: pxToRem(16),
    display: "block",
    paddingBottom: pxToRem(6),
    cursor: "move",
    "& path": {
      fill: color.primary_palette.franklin_purple,
    },
  },
  pencil_icon: {
    width: pxToRem(17),
    display: "block",
    paddingBottom: pxToRem(6),
    cursor: "pointer",
  },
  icons_list: {
    position: "absolute",
    top: pxToRem(10),
    right: pxToRem(8),
  },
  number_indication: {
    width: pxToRem(16),
    height: pxToRem(16),
    border: `solid ${pxToRem(1)} ${color.primary_palette.free_purple}`,
    backgroundColor: color.primary_palette.free_purple,
    color: color.primary_palette.white,
    fontSize: pxToRem(14),
    ...tradework_theme.typography.styles.Helvetica,
    fontWeight: "bold",
    textAlign: "center",
    borderRadius: "100%",
    marginTop: pxToRem(10),
  },
  number_indication_gray: {
    width: pxToRem(16),
    height: pxToRem(16),
    border: `solid ${pxToRem(1)} ${color.secondary_palette.grays.shadow_gray}`,
    backgroundColor: color.secondary_palette.grays.shadow_gray,
    color: color.primary_palette.white,
    fontSize: pxToRem(14),
    ...tradework_theme.typography.styles.Helvetica,
    fontWeight: "bold",
    textAlign: "center",
    borderRadius: "100%",
    marginTop: pxToRem(10),
  },
  add_project: {
    marginRight: pxToRem(40),
    "& .MuiButton-label": {
      color: color.primary_palette.franklin_purple,
      fontSize: pxToRem(25),
      ...tradework_theme.typography.styles.gillsans_sb,
      textTransform: "capitalize",
    },
    "& .MuiSvgIcon-root": {
      fontSize: pxToRem(25),
      marginBottom: pxToRem(-2),
      paddingRight: pxToRem(6),
      marginTop: pxToRem(13),
      "& path": {
        "&:nth-child(1)": {
          fill: color.secondary_palette.grays.background_gray,
        },
      },
    },
  },
  blks: {
    padding: `${pxToRem(0)} ${pxToRem(34)}`,
  },
  blks_additional: {
    paddingLeft: pxToRem(0),
    paddingRight: pxToRem(46),
  },
  listTextArea: {
    marginTop: pxToRem(0),
    marginLeft: pxToRem(67),
    padding: 0,
  },
  border_blk: {
    borderTop: `solid ${pxToRem(5)} ${color.blessed_platinum}`,
  },
  spacing_blk: {
    padding: `${pxToRem(0)} ${pxToRem(34)}`,
  },
  padding_bottom_8: {
    paddingBottom: pxToRem(12),
    paddingTop: pxToRem(12),
  },
  flagship_align: {
    marginLeft: pxToRem(67),
  },
  saveBtn: {
    width: pxToRem(91),
    height: pxToRem(25),
    borderRadius: pxToRem(27),
    marginBottom: pxToRem(31),
    border: `solid ${pxToRem(1)} ${color.primary_palette.franklin_purple}`,
    backgroundColor: color.primary_palette.white,
    "& .MuiButton-label": {
      color: color.primary_palette.franklin_purple,
      fontSize: pxToRem(15),
      ...tradework_theme.typography.styles.avenir_sb,
    },
  },
  flex_wrap: {
    flexWrap: "wrap",
  },
  scroll_bar: {
    minHeight: pxToRem(350),
  },
  padding_right: {
    paddingRight: pxToRem(20),
  },
  margin_top_16: {
    marginTop: pxToRem(-16),
  },
  margin_top_6: {
    marginTop: pxToRem(-6),
  },
  addFlagshipSpacing: {
    paddingTop: pxToRem(28),
    paddingBottom: pxToRem(15),
  },
  project_dashboard_text: {
    paddingTop: pxToRem(19),
    paddingBottom: pxToRem(24),
  },
});

export default styles;
