import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";
import { makeStyles, withStyles } from "@material-ui/core/styles";

import { color } from "../../../utilities/themes";

const useStyles = makeStyles((theme) => ({
  root: {
    color: color.primary_palette.franklin_purple,
    "& :hover": {},
  },
}));

const getColor = (propsVal, line_type) => {
  let colorValue = propsVal.color
    ? propsVal.color
    : color.secondary_palette.grays.light_gray;

  if (propsVal && propsVal.color === "primary") {
    if (line_type === "stroke") {
      colorValue = color.primary_palette.franklin_purple;
    }
    if (line_type === "fill") {
      colorValue = color.primary_palette.franklin_purple;
    }
  }
  return colorValue;
};

function Chevron_Down_Icon(props) {
  const classes = useStyles(props);
  function styledIcon(styleProps) {
    return (
      <span className={classes.root}>
        <SvgIcon {...styleProps} viewBox="0 0 12 6">
          <path
            stroke={getColor(styleProps, "stroke")}
            d="M0 0l6 6 6-6"
            fill="none"
            fillRule="evenodd"
            strokeLinecap="round"
          />
        </SvgIcon>
      </span>
    );
  }

  /**
   * Custom styling the colors
   */
  const Custom_Chevron_Down_Icon = withStyles({
    root: {
      color: color.primary_palette.franklin_purple,
      "& > svg": {},
      "& :hover": {},
    },
  })(styledIcon);
  return (
    <>
      <Custom_Chevron_Down_Icon {...props} color="primary" />
    </>
  );
}

export default Chevron_Down_Icon;
