export const pxToRem = (px) => {
  return `${px / 16}rem`; //16px set as fontSize to html in index.css
};

export const color = {
  primary_palette: {
    franklin_purple: "#410166",
    highlight_purple: "#801FB8",
    christmas_red: "#C01F2A",
    tricks_red: "#c0202a",
    pine_green: "#426618",
    fafa_gray: "#FAFAFA",
    gray:"#c3c3c3",
    black: "#000000",
    white: "#FFFFFF",
    dark_purple: "#415cca",
    border_opacity: "rgba(25,0,40,0.3)",
    free_purple: "#401660",
    disable_border: "rgba(0, 0, 0, 0.26)",
    sky_blue:'#e7f0fe',
    brick_orange:'#feedea'
    
  },
  secondary_palette: {
    blues: {
      modal_blue: "#FBFDFE",
      tile_blue: "#F7FAFB",
      baby_blue: "#C8DBF1",
      click_blue: "#5E94E0",
      hover_blue: "#C6DDFF",
    },
    grays: {
      fafa_gray: "#FAFAFA",
      light_gray_color: "#949494",
      fog_gray: "#EFEFEF",
      shadow_gray: "#979797",
      medium_gray: "#5f5f5f",
      light_gray: "#9B9B9B",
      background_gray: "#d8d8d8",
      bg_gray: "#f7f7f7",
      ping_gray: "#9c9c9c",
      text_gray: "#4B4B4B",
      drag_text: "#696969",
      gary_shade: "#6d6d6d",
      social_gray: "#6e6e6e",
      header_border: "#d0d0d0",
      btn_gray: "#ececec",
      dramatic_gray: "#898989",
      portfolio_view_gray: "#F5EFEC",
      choose_cover: "#626262",
    },
    purples: {
      basic: "#D6D3E1",
      familiar: "#B9A9C9",
      proficient: "#9B80B0",
      advance: "#7C5697",
      franklin_purple: "#410166",
    },
  },
  accent_colors_and_one_offs: {
    mustard: "#E2C236",
    star_gold: "#FFD000",
    benjamin_green: "#99C05C",
    links_orange: "#F87B1C",
    programmer_notes: "#FF00FF",
  },
  wizard_box_colors: {
    franklin_purple: "#410166",
    pine_green: "#426618",
    benjamin_green: "#99C05C",
    shadow_gray: "#979797",
    wizard_blue: "#4E72B3",
    gray_light: "#313131",
  },
  benjamin_colors: {
    benjamin_green: "#99C05C",
    baby_blue: "#C8DBF1",
    warrior_blue: "#2D459D",
    franklin_purple: "#410166",
  },
  form_colors: {
    textfield_color: "#7e7e7e",
    blueberry_purple: "#57307a",
    royal_purple_1: "#410260",
    gray: "#f9f9f9",
    sucess_color: "#0d802e",
    sucess_word_color: "#426618",
    army_green: "#426618",
  },
  social_media: {
    highlight_black: "#292929",
    business_black: "#040303",
  },
  wax: "#2a2a2a",
  froast_grey: "#838383",
  cape_hope: "#d7d7d7",
  silver: "#c0c0c0",
  pearl: "#f8f8f8",
  amethyst_purple: "#56307a",
  light_lilac_crystal: "#d7d3e1",
  greyish_brown: "#4a4a4a",
  brown_grey_two: "#9b9b9b",
  alpine_goat: "#f0f4f6",
  weathered_stone: "#c3c3c3",
  boysenberry_shadow: "#f0f5f8",
  brown_grey_eight: "#7a7a7a",
  scarabaeus_sacer: "#404042",
  gray_white: "#dcdcdc",
  borderbottom_color: "#7d7e7f",
  card_black: "#555555",
  white_opacity: "rgba(255, 255, 255, 0.7)",
  portfolio_gray: "#8a8a8a",
  insta_black: "#3f3f3f",
  elevator_placeholder: "#b3b3b3",
  governmental_oyster: "#b2b2b2",
  wide_open_satin: "#1c1a1a",
  blessed_platinum: "#CECECE",
  clammy_pearl: "#171717",
  vicious_oyster: "#7f35b1",
  bordered_gray: "#cbcbcb",
  background_gray: "#fbfbfb",
  fb_button_bg_color: "#3A5896",
  dark_grayed_text_color: "#8B8780",
  light_gray_text: "#585858",
  knight_armour: "#5c5c5c",
  pedagogical_bronze: "#7f7f7f",
  beneficial_silver: "#b4b4b4",
  inter_steel: "#7d5697",
  active_purple: "#8B3AC1",
  operative_pearl: "#f5f5f5",
  metabolic_steel: "#422063",
  box_gray: "#eaeaea",
  button_hover: "#E4E4E4",
  cover_border: "#808080",
  view_border: "#666666",
  green_world: "#69986c",
  public_color: "#0d802e",
  not_public_color: "#5eb7e0",
  indigo_blue: "#596da7",
  notorious_ivory: "#bfbfbf",
  purple_pirate: "#419166",
  inactive_color: "#afafaf",
  print_color: "#3e3e3e",
  pubic_green: "#128040",
  paid_green: "#519C23",
  add_more_color: "#7c7c7c",
  public_you_color: "#9c7fb0",
  quil_placeholder_color: "#606060",
  button_yellow: "#ffc100",
  button_purple: "#b9a9c8",
  border_light_blue: "#d6dcdf",
  lemon_yellow: "#fcbf10",
  products_background: "#f4eeeb",
  portfolio_hover: "#426617",
};

//Sizes for icons
export const size_options = {
  x_small: "0.75rem",
  small: "0.875rem",
  medium: "1.25rem",
  default: "1.5rem",
  large: "1.875em",
  x_large: "3.25rem",
};

// PX to rem conversion on the base of 16px
export const px_to_rem_sizes = {
  size_20: "1.25rem",
  size_1: "0.0625em",
  size_1_3: "0.08125em",
  size_2: "0.125em",
  size_3: "0.1875em",
};

// PX to rem conversion on the base of 16px
export const font_sizes = {
  font_9: "0.585rem",
  font_10: "0.625rem",
  font_12: "0.75rem",
  font_12_3: "0.76875em",
  font_13: "0.8125em",
  font_14: "0.875rem",
  font_15: "0.9375rem",
  font_16: "1rem",
  font_17: "1.0625em",
  font_18: "1.125em",
  font_20: "1.25rem",
  font_21: "1.3125em",
  font_22: "1.375rem",
  font_24: "1.5rem",
  font_25: "1.5625em",
  font_26: "1.625em",
  font_27: "1.6875em",
  font_28: "1.75em",
  font_29: "1.8125em",
  font_30: "1.875em",
  font_35: "2.1875rem",
  font_50: "3.125rem",
};

// Tradeworks material ui theme object
const tradework_theme = {
  palette: {
    primary: {
      light: color.primary_palette.franklin_purple,
      main: color.primary_palette.franklin_purple,
      dark: color.primary_palette.franklin_purple,
      contrastText: color.primary_palette.white,
    },
    secondary: {
      light: color.form_colors.sucess_color,
      main: color.form_colors.sucess_color,
      dark: color.form_colors.sucess_color,
      contrastText: color.primary_palette.white,
    },
    error: {
      light: color.primary_palette.christmas_red,
      main: color.primary_palette.christmas_red,
      dark: color.primary_palette.christmas_red,
      contrastText: color.primary_palette.white,
    },
  },
  typography: {
    styles: {
      gillsans_sb: {
        fontFamily: "gillsans_sb",
        fontStyle: "normal",
        fontWeight: 600,
      },
      gillsans_r: {
        fontFamily: "gillsans_r",
        fontStyle: "normal",
        fontWeight: 400,
      },
      gillsans_light: {
        fontFamily: "gillsans_light",
        fontStyle: "normal",
        fontWeight: 300,
        lineHeight: 1.47,
      },
      avenir_light: {
        fontFamily: "avenir_light",
        fontStyle: "normal",
        fontWeight: 300,
        lineHeight: 1.47,
      },
      avenir_black_r: {
        fontFamily: "avenir_black_r",
        fontStyle: "normal",
        fontWeight: 900,
        lineHeight: 1.47,
      },
      avenir_roman: {
        fontFamily: "avenir_roman",
        fontStyle: "normal",
        fontWeight: 300,
        lineHeight: 1.47,
      },
      avenir_sb: {
        fontFamily: "avenir_sb",
        fontStyle: "normal",
        fontWeight: 500,
        lineHeight: 1.47,
      },
      avenir_bold: {
        fontFamily: "avenir_bold",
        fontStyle: "normal",
        fontWeight: "bold",
        lineHeight: 1.47,
      },
      avenir_book_r: {
        fontFamily: "avenir_book_r",
        fontStyle: "normal",
        fontWeight: 400,
      },
      Zapfino: {
        fontFamily: "Zapfino",
        fontStyle: "normal",
        fontWeight: "normal",
      },
      Pacifico: {
        fontFamily: "Pacifico",
        fontStyle: "normal",
        fontWeight: "normal",
      },
      OptimaLT: {
        fontFamily: "OptimaLT",
        fontStyle: "normal",
        fontWeight: "normal",
      },
      Helvetica: {
        fontFamily: "Helvetica",
        fontStyle: "normal",
        fontWeight: "normal",
      },
      Helvetica_bold: {
        fontFamily: "Helvetica",
        fontStyle: "normal",
        fontWeight: "bold",
      },
      millerdisplay_light: {
        fontFamily: "millerdisplay_light",
        fontStyle: "normal",
        fontWeight: 300,
      },
      NeutraText: {
        fontFamily: "NeutraText",
        fontStyle: "normal",
        fontWeight: "normal",
      },
      NeutraText_bold: {
        fontFamily: "NeutraText_bold",
        fontStyle: "normal",
        fontWeight: "bold",
      },
      TrajanPro3: {
        fontFamily: "TrajanPro3",
        fontStyle: "normal",
        fontWeight: "normal",
      },
      Oswald_Regular: {
        fontFamily: "Oswald_Regular",
        fontStyle: "normal",
        fontWeight: "normal",
      },
      Oswald_Light: {
        fontFamily: "Oswald_Light",
        fontStyle: "normal",
        fontWeight: "normal",
      },
      ProximaNova_Regular: {
        fontFamily: "ProximaNova_Regular",
        fontStyle: "normal",
        fontWeight: "normal",
      },
      ProximaNova_Thin: {
        fontFamily: "ProximaNova_Thin",
        fontStyle: "normal",
        fontWeight: "normal",
      },
    },
    fontFamily: [
      "gillsans_r",
      "gillsans_sb",
      "gillsans_light",
      "gillsans_bold",
      "avenir_black_r",
      "avenir_book_r",
      "avenir_bold",
      "avenir_light",
      "avenir_sb",
      "avenir_roman",
      '"Helvetica Neue"',
      "Zapfino_bold",
      "Zapfino",
      "Pacifico",
      "Pacifico_bold",
      "ACaslonPro",
      "ACaslonPro_bold",
      "OptimaLT",
      "OptimaLT_bold",
      "NeutraText",
      "NeutraText_bold",
      "Helvetica",
      "LucidaGrande",
      "American_Typewriter_Medium_BT",
      "millerdisplay_light",
      "Miller_DisplayRoman",
      "TrajanPro3",
      "ProximaNova_Regular",
      "ProximaNova_Thin",
      "Oswald_Regular",
      "Oswald_Light",
    ].join(","),
    h1: {
      fontSize: font_sizes.font_50,
    },
    h3: {
      fontSize: font_sizes.font_30,
      textTransform: "capitalize",
    },
    h4: {
      fontSize: font_sizes.font_24,
    },
    subtitle1: {
      fontSize: font_sizes.font_16,
    },
  },

  //overrides key of the theme to potentially change every single style injected by Material-UI into the DOM
  overrides: {
    MuiButton: {
      root: {
        borderRadius: 50,
        "&:hover": {
          backgroundColor: "none",
        },
      },
    },
    MuiFormLabel: {
      root: {
        color: color.wizard_box_colors.shadow_gray,
        fontSize: "18px",
        fontWeight: "300",
        lineHeight: "0.5",
        fontFamily: "gillsans_light",
      },
    },
    MuiFormControlLabel: {
      root: {
        textTransform: "capitalize",
      },
    },
    MuiInputBase: {
      input: {
        paddingBottom: "0px",
        focus: {
          placeholder: {
            color: "transparent",
          },
        },
      },
    },
    MuiStepLabel: {
      label: {
        color: color.primary_palette.franklin_purple + " !important",
        marginTop: "0px !important",
        "&:nth-child(2n)": {
          marginTop: "-30px",
          color: "red !important",
        },
      },
    },
    MuiInputAdornment: {
      root: {
        height: "auto",
        "&:focus": {
          outline: "none",
        },
      },
    },

    MuiGrid: {
      container: {
        outline: "inherit",
      },
      root: {
        "&:focus": {
          outline: "none",
        },
      },
    },
    MuiOutlinedInput: {
      input: {
        textAlign: "center",
      },
    },
    MuiListItem: {
      gutters: {
        paddingLeft: "8px !important",
        paddingRight: "8px !important",
      },
      root: {
        padding: 0 + `!important`,
      },
    },
    MuiPopover: {
      paper: {
        overflowY: "hidden",
      },
    },
    MuiMenu: {
      paper: {
        height: "auto",
        borderRadius: 0,
        border: `1px solid` + color.secondary_palette.grays.shadow_gray,
      },
    },

    MuiAutocomplete: {
      listbox: {
        padding: "0 0 !important",
      },
      groupLabel: {
        fontWeight: "bold",
        background: "#edecfb !important",
        fontSize: "11px",
        width: "98% !important",
        color: "black",
        lineHeight: "20px",
        top: "0px !important",
        padding: "0 10px",
      },
      paper: {
        borderRadius: 0,
        margin: 0,
        border: `1px solid` + color.secondary_palette.grays.shadow_gray,
      },
      option: {
        paddingTop: 0 + `!important`,
        paddingBottom: 0 + `!important`,
        paddingRight: "8px" + `!important`,
        paddingLeft: "8px" + `!important`,
      },
      groupUl: {
        margin: 0 + "!important",
      },
    },
    MuiTooltip: {
      tooltip: {
        width: pxToRem(107),
        height: pxToRem(25),
        boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.5)",
        border: `solid ${pxToRem(1)} ${
          color.secondary_palette.grays.medium_gray
        }`,
        backgroundColor: "white",
        fontFamily: "gillsans_light",
        color: "black",
        textAlign: "center",
      },
    },
  },
};

export default tradework_theme;
