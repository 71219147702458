import _ from "lodash";
import { RecoverApi } from "../api";
import { ApiActions } from ".";

export default {
  recoverLink: (body, cb) => {
    return async (dispatch) => {
      ApiActions.request(dispatch);
      let res = null;
      try {
        res = await RecoverApi.recover_link(body);
        if (!_.get(res, "data.error.error")) {
          cb && cb({error: false});
        } else {
          cb && cb({error: true});
        }
        ApiActions.success(dispatch);
      } catch (err) {
        ApiActions.failure(dispatch, err);
      }
    };
  },
  setNewPassword: (body, cb) => {
    return async (dispatch) => {
      ApiActions.request(dispatch);
      let res = null;
      try {
        res = await RecoverApi.setNewPassword(body);
        if (!_.get(res, "data.error.error")) {
          cb && cb({error: false});
        } else {
          cb && cb({error: true});
        }
        ApiActions.success(dispatch);
      } catch (err) {
        ApiActions.failure(dispatch, err);
      }
    };
  },
//   logOut: () => {
//     return {
//       type: RecoverTypes.LOGOUT,
//     };
//   },
//   clearLogInProps: () => {
//     return {
//       type: RecoverTypes.CLEAR_LOGIN_PROPS,
//     };
//   },
//   toggleRecoverModal: (show) => {
//     return {
//       type: RecoverTypes.TOGGLE_LOGIN_MODAL,
//       payload: show,
//     };
//   },
};
