import React, { useState, useEffect } from "react";
import MenuItem from "@material-ui/core/MenuItem";
import { map, get, filter, find } from "lodash";
import { useSelector, useDispatch } from "react-redux";

import BusinessStyles from "./styles/businessStyle";
import Row from "../common/ui_kit/row";
import Text from "../common/ui_kit/text";
import strings from "../../utilities/strings";
import CustomTextField from "../inputs/custom_textfields";
import CustomSelect from "../common/ui_kit/custom_select";
import Chevron_Down_Icon from "../data_display/icons/Arrow_Chevron_Down";
import CustomButton from "../navigations/custom_buttons";
import CustomAutocomplete from "../inputs/custom_auto_complete";
import CustomScrollbars from "../data_display/custom_scroll";
import { color } from "../../utilities/themes";
import Close_Icon from "../data_display/icons/Close";
import ChevronDownIcon from "../data_display/icons/Arrow_Chevron_Down";
import CustomCheckbox from "../inputs/custom_checkbox";
import {
  REGEX_EMAIL,
  REGEX_URL,
  PHONE_NUMBER_FORMAT,
} from "../../validations/validations";
import Lil_Plus_filled from "../data_display/icons/Lil_Plus_filled";
import { ApiActionTypes } from "../../redux/actionTypes";

import {
  ProfileActions,
  LookUpActions,
  SettingsActions,
} from "../../redux/actions";
import Element_Required_Icon from "../data_display/icons/ElementRequiered";
import Styled_cross from "../data_display/icons/styled_cross";
import FilledArrow from "../data_display/icons/filledArrow";

function BusinessCard(props) {
  const [state, setState] = useState({
    companyId: "",
    portfolioName: "",
    nickName: "",
    companyName: "",
    streetAddr1: "",
    streetAddr2: "",
    city: "",
    state: "",
    zipCode: "",
    email: "",
    webSite: "",
    userEmail: "",
    phoneNumber: "",
    isNewCompany: true,
    selectedCompany: "",
    errors: {},
    selectedRegions: [],
    userData: {},
    showCountryDD: false,
    isUSA: true,
    location: "",
    locationInfo: "",
    companySelectionVal: "newCompany",
    showSavedError: false,
  });
  const [open, setOpen] = useState(true);
  const [limitError, setLimitError] = useState(false);

  const companyData = useSelector((resData) => resData.Profile.companyList);
  const lookUpData = useSelector((resData) => resData.LookUp.LookUpData);
  const locationsData = useSelector(
    (resData) => resData.Settings.CompanyLocationsData
  );

  const dispatch = useDispatch();
  const fetchData = () => {
    dispatch(ProfileActions.getCompanyData());
    dispatch(LookUpActions.getLookUpData());
  };

  //API
  useEffect(() => {
    fetchData();
    let userData = localStorage.getItem("profile");
    let portfolioNewName = localStorage.getItem("portfolioNewName");
    let portfolioDupeName = "";
    if (portfolioNewName) {
      portfolioDupeName = portfolioNewName;
    }
    if (userData !== "undefined") {
      userData = JSON.parse(userData);
    } else {
      userData = {};
    }
    setState({
      ...state,
      userData: userData || {},
      portfolioName: portfolioDupeName,
      userEmail: userData?.email,
      phoneNumber: userData?.phoneNumber,
      email: userData?.email,
    });
  }, []);

  const handleInputChange = (e) => {
    if (e.target.name === "isNewCompany") {
      setState({
        ...state,
        [e.target.name]: e.target.value === "true",
        companyId: "",
        portfolioName: "",
        nickName: "",
        companyName: "",
        streetAddr1: "",
        streetAddr2: "",
        city: "",
        state: "",
        selectedCompany: "",
        zipCode: "",
        webSite: "",
        phoneNumber: "",
      });
      return;
    }
    if (e.target.name === "phoneNumber") {
      handleValidations(e.target.name, e.target.value);
      const val = deformatPhoneNumber(e.target.value);
      if (!isUSA) {
        setState({ ...state, phoneNumber: val });
        return;
      } else if (isNaN(val)) {
        if (!val) {
          setState({ ...state, phoneNumber: "" });
        }
        return;
      }
      setState({ ...state, phoneNumber: val });
      return;
    }
    if (e.target.name === "zipCode") {
      if (isNaN(e.target.value)) {
        if (!e.target.value) {
          setState({ ...state, zipCode: "" });
        }
        return;
      }
    }
    if (e.target.name === "portfolioName") {
      handleValidations(e.target.name, e.target.value);
      setState({ ...state, [e.target.name]: e.target.value });
    }
    if (e.target.name === "nickName") {
      handleValidations(e.target.name, e.target.value);
      setState({ ...state, [e.target.name]: e.target.value });
    }
    if (e.target.name === "companyName") {
      handleValidations(e.target.name, e.target.value);
      setState({ ...state, [e.target.name]: e.target.value });
    }
    if (e.target.name === "city") {
      handleValidations(e.target.name, e.target.value);
      setState({ ...state, [e.target.name]: e.target.value });
    }
    if (e.target.name === "email") {
      handleValidations(e.target.name, e.target.value);
      setState({ ...state, [e.target.name]: e.target.value });
    }
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const handleValidationOnBlur = (e) => {
    handleValidations(e.target.name, e.target.value);
  };

  const onPhoneNumberBlur = (e) => {
    // FORMATTING PHONE NUMBER
    handleValidations(e.target.name, e.target.value);
    const { phoneNumber } = state;
    if (phoneNumber) {
      const num = phoneNumber
        .toString()
        .replace(PHONE_NUMBER_FORMAT, "$1.$2.$3");
      setState({ ...state, phoneNumber: num });
    }
    return;
  };

  const formatPhoneNumber = () => {
    const { phoneNumber } = state;
    return phoneNumber.replace(PHONE_NUMBER_FORMAT, "$1.$2.$3");
  };

  const deformatPhoneNumber = (number) => {
    // DEFORMATTING
    if (!number) {
      return;
    }
    return number.replace(/[().\s/]/g, "");
  };

  const onPhoneNumberFocus = () => {
    // ON FOCUS REMOVING FORMAT
    const { phoneNumber } = state;
    if (phoneNumber) {
      setState({ ...state, phoneNumber: phoneNumber.replace(/[().\s/]/g, "") });
      return;
    }
  };

  const handleDropdownSelect = (name, value) => (e) => {
    e.preventDefault();
    e.stopPropagation();
    setState({ ...state, [name]: value });
  };

  const onClerInput = (nameVal) => () => {
    setState({ ...state, [nameVal]: "" });
  };

  const handleAddRegionClick = () => {
    const { category, region, selectedRegions } = state;
    setLimitError(false);
    state.errors.selectedRegionsError = { error: false, message: "" };
    let foundRegion = find(selectedRegions, { region: region });
    if (foundRegion) {
      let foundCategory = filter(
        foundRegion.category,
        (each) => each === category
      )[0];
      if (foundCategory) {
        return;
      }
      foundRegion.category = foundRegion.category || [];
      if (foundRegion.category.length >= 4) {
        setLimitError(true);
        return;
      }
      foundRegion.category = [...foundRegion.category, category];
      setState({
        ...state,
        category: "",
        region: "",
      });
      setLimitError(false);
    } else {
      selectedRegions.category = selectedRegions.category || [];
      setState({
        ...state,
        selectedRegions: [
          ...selectedRegions,
          {
            region,
            category: [...selectedRegions.category, category],
          },
        ],
        category: "",
        region: "",
      });
    }
  };

  const deleteRegion = (region, categoryName) => () => {
    setLimitError(false);
    const { selectedRegions } = state;
    const foundRegion = find(selectedRegions, { region });

    if (foundRegion) {
      foundRegion.category = foundRegion.category.filter(
        (each) => each !== categoryName
      );
    }

    if (foundRegion.category.length === 0) {
      const filteredRegions = selectedRegions.filter(
        (each) => each.region !== region
      );
      setState({ ...state, selectedRegions: [...filteredRegions] });
      return;
    }

    setState({ ...state });
  };

  const handleValidations = (name, value) => {
    const { errors } = state;
    if (
      name === "email" ||
      name === "webSite" ||
      name === "phoneNumber" ||
      name === "portfolioName" ||
      name === "nickName" ||
      name === "city" ||
      name === "companyName"
    ) {
      errors[name] = errors[name] || {};
      if (
        (name === "webSite" && value && !REGEX_URL.test(value)) ||
        (name === "email" && (!value || !REGEX_EMAIL.test(value))) ||
        (name === "portfolioName" && !value) ||
        (name === "nickName" && !value) ||
        (name === "companyName" &&
          !value &&
          companySelectionVal === "newCompany") ||
        (name === "phoneNumber" && value && value.length < 10) ||
        (name === "phoneNumber" && !value)
      ) {
        errors[name] = { error: true, message: "", touched: true };
        setState({
          ...state,
        });
        return;
      }
      errors[name] = { error: false, message: "", touched: true };
      setState({
        ...state,
      });
      return;
    }
  };

  const selectedLocation = (location, name, locationData) => () => {
    setState({
      ...state,
      [name]: location,
      locationInfo: locationData,
      streetAddr1: get(locationData, "address", "") || "",
      streetAddr2: get(locationData, "address1", "") || "",
      city: get(locationData, "city", "") || "",
      zipCode: get(locationData, "zip", "") || "",
      stateCode: get(locationData, "state", "") || "",
      webSite: get(locationData, "webSite", "") || "",
      phoneNumber: get(locationData, "phoneNumber", "") || "",
    });
  };

  const hasFormErrors = () => {
    const {
      errors,
      portfolioName,
      phoneNumber,
      selectedRegions,
      nickName,
      email,
      region,
      category,
    } = state;
    const allErrors = Object.values(errors).map(
      (errObj) => errObj.error || false
    );
    if (
      allErrors.includes(true) ||
      !portfolioName ||
      !nickName ||
      !phoneNumber ||
      !email ||
      region ||
      category ||
      selectedRegions?.length === 0
    ) {
      return true;
    }
    return false;
  };

  const hasError = hasFormErrors();

  const submitBusinessCard = () => {
    const { selectedRegions } = state;
    if (selectedRegions && selectedRegions.length) {
      state.errors.selectedRegionsError = { error: false, message: "" };
    } else {
      state.errors.selectedRegionsError = { error: true, message: "" };
    }
    if (region && category) {
      state.errors.selectedRegionsError = { error: true, message: "" };
    }
    if (hasError) {
      handleValidations("email", state.email);
      handleValidations("webSite", state.webSite);
      handleValidations("phoneNumber", state.phoneNumber);
      handleValidations("portfolioName", state.portfolioName);
      handleValidations("nickName", state.nickName);
      handleValidations("companyName", state.companyName);
      setState({
        ...state,
        showSavedError: true,
      });
      return;
    }
    const dataToSubmit = {
      ...state,
      companyName:
        companySelectionVal === "newCompany"
          ? companyName
          : selectedCompany.name,
      companyId:
        companySelectionVal === "newCompany" ? "" : selectedCompany._id,
      selectedBusinessCardId: get(locationsData, "_id", ""),
      existingBusinessCardId: get(selectedCompany, "businessCards[0]._id", ""),
    };
    dispatch(
      ProfileActions.saveBusinessCardToPortfolio(dataToSubmit, (res) => {
        if (!res.error.error) {
          localStorage.removeItem("portfolioNewName");
          localStorage.setItem("portfolioId", res.data._id);
          localStorage.setItem("portfolioName", res.data.name);
          localStorage.setItem("companyId", res.data.companyId);
          localStorage.setItem("companyName", res.data.companyName);
          onClose();
          dispatch({ type: ApiActionTypes.HORSEREQUEST });
          dispatch(
            ProfileActions.getPortfolioData(localStorage.getItem("portfolioId"))
          );
          dispatch(SettingsActions.getCompanyDataByUser());
        } else {
          setState({ ...state, errorMessage: res.error.message.message });
        }
      })
    );
  };

  const returnRegionsList = () => {
    const { selectedRegions } = state;
    return (
      <div>
        <ol className={classes.regionListAlign}>
          {map(selectedRegions, (location) => {
            return (
              <li>
                {get(location, "region", "")} -{" "}
                {map(location?.category, (category) => {
                  return (
                    <>
                      <span className={classes.categoryAlign}>
                        {category}{" "}
                        <Styled_cross
                          className={classes.coloredCrossStyle}
                          onClick={deleteRegion(location.region, category)}
                        />
                      </span>
                    </>
                  );
                })}
              </li>
            );
          })}
        </ol>
      </div>
    );
  };

  // method to handle company selection
  const handleCompanySelect = (event, company) => {
    if (company) {
      state.errors.companyName = { error: false, message: "" };
      dispatch(
        SettingsActions.getCompanySelectedLocationsData(
          get(company, "_id"),
          (res) => {
            setState({
              ...state,
              selectedCompany: company,
              streetAddr1:
                get(company, "businessCards[0].address", "") ||
                get(res, "companyInfo.address.address", ""),
              streetAddr2:
                get(company, "businessCards[0].address1", "") ||
                get(res, "companyInfo.address.address1", ""),
              city:
                get(company, "businessCards[0].city", "") ||
                get(res, "companyInfo.address.city", ""),
              zipCode:
                get(company, "businessCards[0].zip", "") ||
                get(res, "companyInfo.address.zip", ""),
              stateCode:
                get(company, "businessCards[0].state", "") ||
                get(res, "companyInfo.address.state", ""),
              webSite: get(company, "businessCards[0].webSite", ""),
              phoneNumber: get(company, "businessCards[0].phoneNumber", ""),
              selectedRegions: [...get(res, "companyInfo.legacyMarkets", "")],
              email: get(res, "companyInfo.publicCompanyEmail", ""),
            });
          }
        )
      );
    }
  };

  const handleUSADropdown = () => {
    setState({ ...state, showCountryDD: !showCountryDD });
  };

  const handleCountrySelect = (value) => () => {
    setState({
      ...state,
      isUSA: value === "USA",
      showCountryDD: false,
      phoneNumber: "",
    });
  };

  // Method responsible to return categorized menu items
  const renderCategorizedMenu = (menuData, type) => {
    // list of color codes to be appied for category tiles based on index
    const colorCodes = get(lookUpData, "configData.dropdownColorCodes", []);
    // fetching category names
    const groups = Object.keys(menuData)
      .filter((each) => each !== "noParent")
      .sort();
    // pushing nonParent items to array
    let menuItems = menuData.noParent
      ? menuData.noParent.map((each) => each)
      : [];

    groups.map((eachGroup, index) => {
      // interating group titles and pushint to menu item array
      let subGroupItems = [{ name: eachGroup, color: colorCodes[index] }];
      menuData[eachGroup].map((eachGroupItem) => {
        // iterating subgroup items
        subGroupItems.push(eachGroupItem);
      });
      // concatinating menuItems which are returned and categorized items
      menuItems = [...menuItems, ...subGroupItems];
      return null;
    });

    // mapping menuItems (objects) and returning JSX
    return menuItems.map((eachItem) => {
      return (
        <MenuItem
          style={{ background: eachItem.color }}
          onClick={!eachItem.color && handleDropdownSelect(type, eachItem.name)}
          className={eachItem.color && classes.textCapital}
        >
          {eachItem.name}
        </MenuItem>
      );
    });
  };

  const companySelection = (e) => {
    const { checked } = e.target;
    setState({
      ...state,
      companyId: "",
      portfolioName: "",
      nickName: "",
      companySelectionVal: checked ? "inFr" : "newCompany",
      companyName: "",
      streetAddr1: "",
      streetAddr2: "",
      city: "",
      state: "",
      email: userEmail,
      stateCode: "",
      selectedCompany: "",
      zipCode: "",
      webSite: "",
      phoneNumber: "",
    });
    return;
  };

  const { classes, onClose } = props;
  const {
    companyName,
    streetAddr1,
    streetAddr2,
    city,
    email,
    selectedCompany,
    webSite,
    phoneNumber,
    isNewCompany,
    portfolioName,
    nickName,
    region,
    category,
    errorMessage,
    userData,
    zipCode,
    showCountryDD,
    stateCode,
    isUSA,
    userEmail,
    location,
    companySelectionVal,
    showSavedError,
  } = state;

  return (
    <>
      <div className={classes.modal_bussinesscard}>
        <div className={classes.align_right}>
          <Close_Icon
            onMouseUp={submitBusinessCard}
            className={classes.cross_mark_icon}
          />
        </div>
        <Row className={classes.free_block}>
          <img
            src="/assets/icons/fr_stamp.svg"
            className={classes.stamp_width}
            alt=""
          />
          <Row className={classes.main_text}>
            <Text
              size={20}
              color={color.primary_palette.black}
              family="avenir_sb"
            >
              {strings.business_card.titles.welcome}
            </Text>
            <Text
              size={20}
              color={color.primary_palette.black}
              family="avenir_sb"
              className={classes.textCenter}
            >
              {strings.business_card.titles.lets}
            </Text>
          </Row>
        </Row>
        {showSavedError && hasError && (
          <Row>
            <Text
              size={16}
              color={color.primary_palette.christmas_red}
              family="avenir_sb"
              className={classes.errorTxtAlign}
            >
              {strings.business_card.titles.save_error}
            </Text>
          </Row>
        )}
        <CustomScrollbars
          className={
            companySelectionVal === "inFr"
              ? classes.blockHeightinFr
              : classes.blockHeight
          }
          isModalScroll={true}
        >
          <Row className={classes.card}>
            {/* <Row className={`${classes.alignCenter} ${classes.btnDivAlign}`}>
              <div
                className={
                  companySelectionVal === "inFr"
                    ? classes.BusinessBtnActiveStyle
                    : classes.BusinessBtnStyle
                }
                // onClick={companySelection("inFr")}
              >
                {companySelectionVal === "inFr" && (
                  <img
                    src="/assets/icons/Green Arrow.svg"
                    className={classes.filled_arrow}
                    alt=""
                  />
                )}
                <span
                  className={
                    companySelectionVal === "inFr" && classes.labelTxtAlign
                  }
                >
                  {strings.business_card.titles.company_current}
                </span>
              </div>
              <div
                className={
                  companySelectionVal === "newCompany"
                    ? classes.BusinessBtnActiveStyle
                    : classes.BusinessBtnStyle
                }
                // onClick={companySelection("newCompany")}
              >
                {companySelectionVal === "newCompany" && (
                  <img
                    src="/assets/icons/Green Arrow.svg"
                    className={classes.filled_arrow}
                    alt=""
                  />
                )}
                <span
                  className={
                    companySelectionVal === "newCompany" &&
                    classes.labelTxtAlign
                  }
                >
                  {" "}
                  {strings.business_card.titles.new_company}{" "}
                </span>
              </div>
            </Row> */}
            <Row className={classes.alignCenter}>
              <CustomCheckbox
                className={classes.isInFR}
                label={strings.business_card.titles.already_in_fr}
                checked={companySelectionVal === "inFr"}
                name="inFr"
                onChange={companySelection}
              />
              <img
                src="/assets/icons/Purple Arrow Graphic Left.svg"
                className={classes.leftGraphicArrow}
                alt=""
              />
            </Row>
            {companySelectionVal === "inFr" && (
              <Text
                size={12}
                color={color.primary_palette.black}
                family="avenir_sb"
                className={classes.companyDescAlign}
              >
                {strings.business_card.titles.company_des}
              </Text>
            )}
            <Row className={classes.businessBlockWidth}>
              <Row className={classes.business_width}>
                <Row className={classes.inner_block}>
                  <Row className={classes.display_blk}>
                    {companySelectionVal === "inFr" ? (
                      <div className={classes.pos_relative}>
                        <Element_Required_Icon
                          className={
                            get(state.errors, "companyName.error", "")
                              ? classes.select_field_required_error
                              : classes.select_field_required
                          }
                        />
                        <CustomAutocomplete
                          className={classes.select_field}
                          placeholder="Type or choose Company Name"
                          disableOpenOnFocus
                          open={open}
                          onOpen={() => setOpen(true)}
                          onClose={() => setOpen(false)}
                          value={selectedCompany}
                          company
                          onChange={(event, newInputValue) => {
                            handleCompanySelect(event, newInputValue);
                          }}
                          freeSolo
                          options={companyData || []}
                          getOptionLabel={(option) => option.name}
                          ListboxComponent={React.forwardRef(
                            ({ children, ...rest }, ref, itemsLength) => (
                              <div>
                                <Text
                                  size={14}
                                  color={color.primary_palette.highlight_purple}
                                  family="gillsans_r"
                                  className={classes.dropdown_headings}
                                ></Text>
                                <div className={`${classes.scroll}`}>
                                  <CustomScrollbars
                                    ref={ref}
                                    {...rest}
                                    className={classes.scroll_bar}
                                  >
                                    <Row className={classes.scroll_options}>
                                      <div className={classes.width_100}>
                                        <span
                                          className={classes.companyNameBlock}
                                        >
                                          {children}
                                        </span>
                                        <br />
                                      </div>
                                    </Row>
                                  </CustomScrollbars>
                                </div>
                                <Text
                                  size={14}
                                  color={color.primary_palette.black}
                                  family="gillsans_r"
                                  className={classes.dropdown_headings}
                                ></Text>
                                <Text
                                  size={16}
                                  color={color.pubic_green}
                                  family="gillsans_r"
                                  className={classes.noCompanyText}
                                >
                                  No Existing Company Profile? Just keep on
                                  typing.
                                </Text>
                              </div>
                            )
                          )}
                        />
                      </div>
                    ) : (
                      <div className={classes.pos_relative}>
                        <Element_Required_Icon
                          className={
                            get(state.errors, "companyName.error", "")
                              ? classes.company_name_required_error
                              : classes.company_name_required
                          }
                        />
                        <CustomTextField
                          className={`${
                            !get(state.errors, "companyName.touched", false)
                              ? `${classes.company_name}`
                              : get(state.errors, "companyName.error", false)
                              ? `${classes.company_name_error}`
                              : `${classes.company_name_success}`
                          }`}
                          placeholder={
                            strings.business_card.titles.company_name
                          }
                          name="companyName"
                          value={companyName}
                          onChange={handleInputChange}
                        />
                        <Close_Icon
                          className={classes.snap_clear}
                          onClick={onClerInput("companyName")}
                        />
                      </div>
                    )}
                  </Row>
                  <Row className={classes.flex}>
                    <div className={classes.textFiled}>
                      <CustomTextField
                        className={classes.input_field}
                        label={strings.business_card.titles.company_add1}
                        name="streetAddr1"
                        value={streetAddr1}
                        onChange={handleInputChange}
                        clearIcon={true}
                        onClear={onClerInput("streetAddr1")}
                      />
                    </div>
                    <div className={`${classes.textFiled}`}>
                      <Element_Required_Icon
                        className={
                          get(state.errors, "email.error", "")
                            ? classes.email_required_error
                            : classes.field_icon_email
                        }
                      />
                      <CustomTextField
                        className={`${
                          !get(state.errors, "email.touched", false)
                            ? classes.input_field
                            : get(state.errors, "email.error", false)
                            ? classes.error_field
                            : classes.success_field
                        } ${classes.email_field_business}`}
                        label={strings.business_card.titles.email}
                        name="email"
                        value={email}
                        onChange={handleInputChange}
                        onBlur={handleValidationOnBlur}
                        clearIcon={true}
                        onClear={onClerInput("email")}
                      />
                    </div>
                    <div className={classes.textFiled}>
                      <CustomTextField
                        className={classes.input_field}
                        label={strings.business_card.titles.company_add2}
                        name="streetAddr2"
                        value={streetAddr2}
                        onChange={handleInputChange}
                        clearIcon={true}
                        onClear={onClerInput("streetAddr2")}
                      />
                    </div>
                    <div className={`${classes.textFiled}`}>
                      <CustomTextField
                        className={`${
                          get(state.errors, "webSite.error", "") &&
                          classes.error_field
                        } ${classes.input_field} ${classes.website_field}`}
                        label={strings.business_card.titles.website}
                        name="webSite"
                        value={webSite}
                        onChange={handleInputChange}
                        onBlur={handleValidationOnBlur}
                        clearIcon={true}
                        onClear={onClerInput("webSite")}
                      />
                    </div>
                    <div className={classes.last_field}>
                      <div className={classes.textFiled_last}>
                        {/* <Element_Required_Icon
                          className={
                            get(state.errors, "city.error", "")
                              ? classes.required_error
                              : classes.field_icon
                          }
                        /> */}
                        <CustomTextField
                          className={`${
                            !get(state.errors, "city.touched", false)
                              ? classes.input_field
                              : get(state.errors, "city.error", false)
                              ? classes.error_field
                              : classes.success_field
                          } ${classes.city_field_card}`}
                          label={strings.business_card.titles.city}
                          name="city"
                          value={city}
                          onChange={handleInputChange}
                          onBlur={handleValidationOnBlur}
                          clearIcon={true}
                          onClear={onClerInput("city")}
                        />
                      </div>
                      <div className={classes.textFiled_last}>
                        <CustomTextField
                          className={`${classes.input_field} ${classes.st_field}`}
                          label={strings.business_card.titles.st}
                          name="stateCode"
                          value={stateCode ? stateCode : ""}
                          onChange={handleInputChange}
                          inputProps={{
                            maxLength: 2,
                          }}
                        />
                      </div>
                      <div className={classes.textFiled_last}>
                        <CustomTextField
                          className={`${classes.input_field} ${classes.zip_field}`}
                          label={strings.business_card.titles.zip}
                          name="zipCode"
                          value={zipCode}
                          onChange={handleInputChange}
                          inputProps={{
                            maxLength: 5,
                          }}
                        />
                      </div>
                      <div className={classes.textFiled_last}>
                        <Element_Required_Icon
                          className={
                            get(state.errors, "phoneNumber.error", "")
                              ? classes.required_error
                              : classes.field_icon
                          }
                        />
                        <CustomTextField
                          className={`${
                            !get(state.errors, "phoneNumber.touched", false)
                              ? classes.input_field
                              : get(state.errors, "phoneNumber.error", false)
                              ? classes.error_field
                              : classes.success_field
                          } ${classes.phone_field}`}
                          label={strings.business_card.titles.phone}
                          name="phoneNumber"
                          onChange={handleInputChange}
                          value={
                            isUSA && get(phoneNumber, "length", 0) >= 10
                              ? formatPhoneNumber()
                              : phoneNumber
                          }
                          onBlur={onPhoneNumberBlur}
                          inputProps={{
                            maxLength: isUSA ? 10 : 20,
                            onFocus: onPhoneNumberFocus,
                          }}
                        />
                        <div className={classes.flag_block}>
                          <div
                            className={`${classes.usaDivAlign} ${classes.txtRight}`}
                          >
                            {" "}
                            <span className={classes.usaStyle}>
                              {" "}
                              <img
                                src={
                                  isUSA
                                    ? "/assets/images/usa-flag-icon.png"
                                    : "/assets/images/nonUSA.PNG"
                                }
                                alt="USA"
                                className={classes.usaFlagStyle}
                              />
                            </span>
                            <ChevronDownIcon
                              className={classes.downArrowIcon}
                              onClick={handleUSADropdown}
                            />
                          </div>
                          {showCountryDD && (
                            <div className={classes.dropdown_content}>
                              <li
                                value={"USA"}
                                onClick={handleCountrySelect("USA")}
                              >
                                USA
                              </li>
                              <li
                                value={"NONUSA"}
                                onClick={handleCountrySelect("NONUSA")}
                              >
                                NON-USA
                              </li>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </Row>
                </Row>
              </Row>
              <Row className={classes.floatRight}>
                {get(locationsData, "businessCards.length", 0) > 0 &&
                  companySelectionVal === "inFr" && (
                    <CustomSelect
                      className={classes.portfolio_items}
                      IconComponent={Chevron_Down_Icon}
                      name="location"
                      value={location}
                      renderValue={(value) => value || "CHOOSE ANOTHER OFFICE"}
                      MenuProps={{
                        getContentAnchorEl: null,
                        disableScrollLock: true,
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                      }}
                    >
                      <CustomScrollbars className={classes.customScrollHeight}>
                        {locationsData &&
                          map(locationsData.businessCards, (location) => {
                            return (
                              <MenuItem
                                onClick={selectedLocation(
                                  get(location, "nickname", "") ||
                                    get(location, "name", ""),
                                  "location",
                                  location
                                )}
                              >
                                {get(location, "nickname", "") ||
                                  get(location, "name", "")}
                              </MenuItem>
                            );
                          })}
                        <MenuItem className={classes.txtRight}>
                          <Text
                            size={15}
                            color={color.primary_palette.black}
                            family="gillsans_sb"
                            className={classes.spacing_add_another_office}
                          >
                            <Lil_Plus_filled className={classes.lil_filled} />{" "}
                            {strings.build.titles.add_another_office}
                          </Text>
                        </MenuItem>
                      </CustomScrollbars>
                    </CustomSelect>
                  )}
              </Row>
              <Text
                size={12}
                color={color.primary_palette.black}
                family="gillsans_sb"
                className={classes.primaryCompanyStyle}
              >
                {strings.build.titles.primary_company_email}
              </Text>
            </Row>
            <Row className={classes.nickNameBlock}>
              <Row className={classes.nickNameStyle}>
                <Element_Required_Icon
                  className={
                    get(state.errors, "nickName.error", "")
                      ? classes.required_ele_error
                      : classes.required_ele
                  }
                />
                <Text
                  size={16}
                  color={
                    get(state.errors, "nickName.error", "")
                      ? color.primary_palette.christmas_red
                      : color.primary_palette.free_purple
                  }
                  family="avenir_book_r"
                  className={classes.portfolio_text}
                >
                  {strings.business_card.titles.office_nick}
                </Text>
                <CustomTextField
                  className={`${
                    !get(state.errors, "nickName.touched", false)
                      ? `${classes.portfolio_field} ${classes.width_356}`
                      : get(state.errors, "nickName.error", false)
                      ? `${classes.portfolio_field_error} ${classes.width_356}`
                      : `${classes.portfolio_field_success} ${classes.width_356}`
                  }`}
                  name="nickName"
                  onChange={handleInputChange}
                  value={nickName}
                  label={strings.business_card.titles.new_nick_placeholder}
                  clearIcon={true}
                  onClear={onClerInput("nickName")}
                />
              </Row>
              <Row className={classes.portfolio_block}>
                <Text
                  size={16}
                  color={color.primary_palette.free_purple}
                  family="avenir_book_r"
                  className={`${classes.portfolio_text} ${classes.textLeft12}`}
                >
                  {strings.business_card.titles.new_portfolio}
                </Text>
                <Row>
                  <Element_Required_Icon
                    className={
                      get(state.errors, "portfolioName.error", "")
                        ? classes.required_ele_error
                        : classes.required_ele
                    }
                  />
                  <Text
                    size={16}
                    color={
                      get(state.errors, "portfolioName.error", "")
                        ? color.primary_palette.christmas_red
                        : color.primary_palette.free_purple
                    }
                    family="avenir_book_r"
                    className={classes.portfolio_text}
                  >
                    {strings.business_card.titles.new_portfolio_field}
                  </Text>
                  <CustomTextField
                    className={`${
                      !get(state.errors, "portfolioName.touched", false)
                        ? `${classes.portfolio_field} ${classes.width_334}`
                        : get(state.errors, "portfolioName.error", false)
                        ? `${classes.portfolio_field_error} ${classes.width_334}`
                        : `${classes.portfolio_field_success} ${classes.width_334}`
                    }`}
                    name="portfolioName"
                    onChange={handleInputChange}
                    value={portfolioName}
                    clearIcon={true}
                    onClear={onClerInput("portfolioName")}
                    label={
                      strings.business_card.titles.new_portfolio_placeholder
                    }
                  />
                </Row>
              </Row>
              <Text
                size={15}
                color={color.primary_palette.free_purple}
                family="avenir_sb"
              >
                <Element_Required_Icon className={classes.required_ele} />
                {strings.business_card.titles.portfolio_display}
              </Text>
              <Row className={classes.textLeft15}>
                <CustomSelect
                  className={`${classes.select_items} ${classes.width_130}`}
                  IconComponent={Chevron_Down_Icon}
                  onChange={handleInputChange}
                  name="region"
                  renderValue={(value) =>
                    value ? (
                      <span className={classes.colorPurple}>{value}</span>
                    ) : (
                      <span>Market</span>
                    )
                  }
                  value={region}
                  MenuProps={{
                    getContentAnchorEl: null,
                    disableScrollLock: true,
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                  }}
                >
                  <CustomScrollbars className={classes.customScrollHeight}>
                    {lookUpData &&
                      map(lookUpData.regions, (region) => {
                        return (
                          <MenuItem
                            onClick={handleDropdownSelect(
                              "region",
                              region.name
                            )}
                          >
                            {region.name}
                          </MenuItem>
                        );
                      })}
                  </CustomScrollbars>
                </CustomSelect>
                <CustomSelect
                  className={`${classes.select_items} ${classes.width_300}`}
                  IconComponent={Chevron_Down_Icon}
                  name="category"
                  value={category}
                  renderValue={(value) =>
                    value ? (
                      <span className={classes.colorPurple}>{value}</span>
                    ) : (
                      <span>Category</span>
                    )
                  }
                  MenuProps={{
                    getContentAnchorEl: null,
                    disableScrollLock: true,
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                  }}
                >
                  <CustomScrollbars
                    className={classes.customScrollHeightCategory}
                  >
                    {lookUpData &&
                      renderCategorizedMenu(lookUpData.trades, "category")}
                  </CustomScrollbars>
                </CustomSelect>
                <CustomButton
                  className={classes.add_btn}
                  onClick={category && region && handleAddRegionClick}
                >
                  {strings.business_card.titles.add}
                </CustomButton>
              </Row>
              <Text
                color={color.secondary_palette.grays.medium_gray}
                size={14}
                family="avenir_light"
                className={`${classes.marginTop6} ${classes.textRight} ${classes.marginRight26}`}
              >
                Don’t forget to click +ADD to continue!
              </Text>
              {limitError && (
                <Text
                  color={color.primary_palette.christmas_red}
                  size={15}
                  family="avenir_bold"
                  className={`${classes.marginTop6}`}
                >
                  <img
                    src="/assets/icons/info_1.svg"
                    class="info_img"
                    alt=""
                    className={classes.marginLeft4}
                  />
                  Maximum four categories per market
                </Text>
              )}
              {get(state.errors, "selectedRegionsError.error", false) ? (
                <div className={`${classes.error} ${classes.regionError}`}>
                  <img
                    src="/assets/icons/info_1.svg"
                    className={classes.infoImageStyle}
                    alt=""
                  />{" "}
                  Please click the +ADD Button on the right to include this
                  Category
                </div>
              ) : (
                ""
              )}
              {returnRegionsList()}
            </Row>
          </Row>
        </CustomScrollbars>
        <div className={` ${classes.free_block}`}>
          <Row>
            <div>
              <Text
                size={15}
                color={color.primary_palette.free_purple}
                family="avenir_black_r"
                transform="uppercase"
              >
                {strings.business_card.titles.you}
              </Text>
            </div>
            <div className={classes.marginLeft14}>
              <Text
                size={15}
                color={color.primary_palette.free_purple}
                family="avenir_sb"
                className={classes.customer_info}
              >
                {strings.business_card.titles.name}{" "}
              </Text>
              <Text
                size={15}
                color={color.primary_palette.black}
                family="avenir_roman"
                className={classes.customer_info_data}
              >
                {`${get(userData, "firstName", "")} ${get(
                  userData,
                  "lastName",
                  ""
                )}`}
              </Text>
            </div>
            <div className={classes.marginLeft14}>
              <Text
                size={15}
                color={color.primary_palette.free_purple}
                family="avenir_sb"
                className={classes.customer_info}
              >
                {strings.business_card.titles.mail}
              </Text>
              <Text
                size={15}
                color={color.primary_palette.black}
                family="avenir_roman"
                className={classes.customer_info_data}
              >
                {userEmail}
              </Text>
            </div>
          </Row>
          {errorMessage && (
            <div className={classes.error}>
              <img
                src="/assets/icons/info_1.svg"
                class="info_img"
                alt=""
                className={classes.infoImageStyle}
              />{" "}
              {errorMessage}
            </div>
          )}
        </div>
        <div className={classes.align_right}>
          <CustomButton
            className={classes.continue_btn}
            onMouseUp={submitBusinessCard}
          >
            {strings.business_card.titles.continue}
          </CustomButton>
        </div>
      </div>
    </>
  );
}

export default BusinessStyles(BusinessCard);
