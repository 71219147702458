import React, { useState, useEffect } from "react";
import uuidv4 from "uuid/v4";

import MenuItem from "@material-ui/core/MenuItem";

import { color } from "../../utilities/themes";
import AlterConfirmationStyles from "./styles/alter_confiramtionStyles";
import Text from "../common/ui_kit/text";
import strings from "../../utilities/strings";
import Close_Icon from "../data_display/icons/Close";
import CustomButton from "../navigations/custom_buttons";
import CustomRadioButton from "../inputs/custom_radio_button";
import Row from "../common/ui_kit/row";
import CustomSelect from "../common/ui_kit/custom_select";
import CustomScrollbars from "../data_display/custom_scroll";
import Chevron_Down_Icon from "../data_display/icons/Arrow_Chevron_Down";

function AlterConfirmation(props) {
  const [state, setState] = useState({
    projectImages: [],
    imageData: {},
    selectedRadio: "stickWithCurrent",
  });

  useEffect(() => {
    if (props.type === "altergrid") {
      setState({ ...state, chooseNumber: layoutlength });
    }
  }, [props.layoutlength]);

  const handleNumberDropdown = (name, value) => (e) => {
    e.preventDefault();
    e.stopPropagation();
    const newProjects = [];
    for (let i = 0; i < Number(value); i++) {
      newProjects.push({ _id: uuidv4() });
    }
    setState({
      ...state,
      [name]: value,
      projectImages: [...newProjects],
    });
  };

  const projectInfoData = (data) => {
    switch (data) {
      case 1:
        return <img src="/assets/images/grid_1.png" alt="" />;
      case 2:
        return <img src="/assets/images/grid_2.png" alt="" />;
      case 3:
        return <img src="/assets/images/grid_3.png" alt="" />;
      case 4:
        return <img src="/assets/images/grid_4.png" alt="" />;
      case 5:
        return <img src="/assets/images/grid_5.png" alt="" />;
      case 6:
        return <img src="/assets/images/grid_6.png" alt="" />;
      case 7:
        return <img src="/assets/images/grid_7.png" alt="" />;
      case 8:
        return <img src="/assets/images/grid_8.png" alt="" />;
      case 9:
        return <img src="/assets/images/grid_9.png" alt="" />;
      case 10:
        return (
          <img
            src="/assets/images/grid_10.png"
            style={{ width: "300px" }}
            alt=""
          />
        );
      case 11:
        return <img src="/assets/images/grid_11.png" alt="" />;
      case 12:
        return (
          <img
            src="/assets/images/grid_12.png"
            style={{ width: "300px" }}
            alt=""
          />
        );
    }
  };

  const onInputChange = (e) => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value });
  };

  const chooseNumber_data = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  const {
    classes,
    onClose,
    handleLayoutPopup,
    isAlterEdit,
    layoutlength,
    handleLayoutPopup1,
  } = props;
  const { selectedRadio, chooseNumber } = state;
  return (
    <>
      <div
        className={
          isAlterEdit
            ? classes.altergrid_choose
            : classes.alter_confirmation_width
        }
      >
        <div className={classes.textRight}>
          <CustomButton className={classes.cross_btn} onMouseUp={onClose} isX>
            <Close_Icon className={classes.cross_mark} onClick={onClose} />
          </CustomButton>
        </div>

        {!isAlterEdit && (
          <div className={classes.textCenter}>
            {projectInfoData(props.chooseNumber)}
          </div>
        )}
        {isAlterEdit && (
          <div className={classes.textCenter}>
            {projectInfoData(state.chooseNumber)}
          </div>
        )}
        <div className={`${classes.textCenter} ${classes.spacing}`}>
          <Text
            size={23}
            color={color.greyish_brown}
            className={isAlterEdit && classes.padding_bottom}
          >
            {isAlterEdit
              ? `You currently have a grid that includes ${props.layoutlength} images. If change your grid shape, you will most likely need to recrop your images`
              : strings.add_project.titles.num_grid}
          </Text>
        </div>

        {isAlterEdit && (
          <div className={classes.radio_padding}>
            <CustomRadioButton
              className={classes.radio_button}
              label={`Stick with ${props.layoutlength} images`}
              checked={selectedRadio === "stickWithCurrent"}
              name="selectedRadio"
              value="stickWithCurrent"
              onChange={onInputChange}
            />
            <Row>
              <CustomRadioButton
                className={classes.radio_button}
                label={strings.add_project.titles.choose_grid_with}
                checked={selectedRadio === "change"}
                name="selectedRadio"
                value="change"
                onChange={onInputChange}
              />
              <CustomSelect
                className={classes.choose_number}
                IconComponent={Chevron_Down_Icon}
                name="choose"
                value={state.chooseNumber}
                renderValue={(value) => value || "choose"}
                MenuProps={{
                  getContentAnchorEl: null,
                  disableScrollLock: true,
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                }}
              >
                <CustomScrollbars className={classes.customScrollHeight}>
                  {chooseNumber_data.map((chooseNumberVal) => (
                    <MenuItem
                      onClick={handleNumberDropdown(
                        "chooseNumber",
                        chooseNumberVal
                      )}
                    >
                      {chooseNumberVal}
                    </MenuItem>
                  ))}
                </CustomScrollbars>
              </CustomSelect>
              <Text
                size={25}
                color={color.greyish_brown}
                className={classes.image_spacing}
              >
                {strings.add_project.titles.image}
              </Text>
            </Row>
          </div>
        )}

        <div className={`${classes.textCenter} ${classes.imageAlign}`}>
          {isAlterEdit && (
            <CustomButton
              className={classes.pleaseBtn}
              onClick={handleLayoutPopup(selectedRadio, chooseNumber)}
            >
              {strings.add_project.titles.please}
            </CustomButton>
          )}
          {!isAlterEdit && (
            <CustomButton
              className={classes.pleaseBtn}
              onClick={handleLayoutPopup1}
            >
              {strings.add_project.titles.please}
            </CustomButton>
          )}
        </div>
      </div>
    </>
  );
}

export default AlterConfirmationStyles(AlterConfirmation);
