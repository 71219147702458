import React from "react";
import _ from "lodash";
import PropTypes from "prop-types";
import { sortableContainer, sortableElement } from "react-sortable-hoc";
import Tile from "./tile";

const SortableItem = sortableElement(({ tile }) => {
  return <div style={{ zIndex: "999999" }}>{tile}</div>;
});

const SortableContainer = sortableContainer(
  ({ style, className, children }) => {
    return (
      <div className={className} style={style}>
        {children}
      </div>
    );
  }
);

function Tiles({ onSortEnd, className, style, children }) {
  return (
    <SortableContainer
      onSortEnd={onSortEnd}
      className={className}
      style={style}
      useDragHandle
    >
      {_.map(children, (tile, idx) => {
        return <SortableItem index={idx} tile={tile} />;
      })}
    </SortableContainer>
  );
}

Tiles.propTypes = {
  onSortEnd: PropTypes.func,
  className: PropTypes.string,
  style: PropTypes.object,
  children: PropTypes.arrayOf(PropTypes.objectOf(Tile)).isRequired
};

Tiles.defaultProps = {
  className: ""
};

export default Tiles;
