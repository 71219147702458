import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { map, get } from "lodash";

import MenuItem from "@material-ui/core/MenuItem";

import { color } from "../../utilities/themes";
import ProjectImagStyles from "./styles/projectImagStyle";
import Row from "../common/ui_kit/row";
import Text from "../common/ui_kit/text";
import strings from "../../utilities/strings";
import CustomSelect from "../common/ui_kit/custom_select";
import Chevron_Down_Icon from "../data_display/icons/Arrow_Chevron_Down";
import CustomPlainInputField from "../inputs/custom_plain_input_field";
import CustomScrollbars from "../data_display/custom_scroll";
import CustomTextArea from "../inputs/custom_text_area";
import Trash_can from "../data_display/icons/Trash_can";
import CustomModal from "../inputs/custom_modal";
import DeleteModal from "./delete_projectModal";
import UploadImgColor from "../add_images/UploadImgColor";

function ProjectAddImag(props) {
  const [state, setState] = useState({
    projectImgDescription: "",
    materialsOrTechniques: "",
    openDeleteModal: false,
    features: "",
    trade: "",
    room: "",
    mainColor: "",
    projectImgTags: [],
  });

  const projectSelectionImageData = useSelector(
    (resData) => resData.Profile.projectSelectionImageData
  );

  const lookUpData = useSelector((resData) => resData.LookUp.LookUpData);

  useEffect(() => {
    const { data } = props;
    setState({ ...data });
  }, [props.data]);

  const handleInputChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const handleDeleteModal = () => {
    setState({
      ...state,
      openDeleteModal: !openDeleteModal,
    });
  };

  const handleAddOption = (name, value, id) => () => {
    props.addTitle(name, value, id)();
    setState({ ...state, newOptionValue: "" });
  };

  // Method responsible to return categorized menu items
  const renderCategorizedMenu = (menuData, type) => {
    // list of color codes to be appied for category tiles based on index
    const colorCodes = get(lookUpData, "configData.dropdownColorCodes", []);
    // fetching category names
    const groups = Object.keys(menuData).filter((each) => each !== "noParent");
    // pushing nonParent items to array
    let menuItems = menuData.noParent
      ? menuData.noParent.map((each) => each)
      : [];

    groups.map((eachGroup, index) => {
      // interating group titles and pushint to menu item array
      let subGroupItems = [{ name: eachGroup, color: colorCodes[index + 1] }];
      menuData[eachGroup].map((eachGroupItem) => {
        // iterating subgroup items
        subGroupItems.push(eachGroupItem);
      });
      // concatinating menuItems which are returned and categorized items
      menuItems = [...menuItems, ...subGroupItems];
      return null;
    });

    // mapping menuItems (objects) and returning JSX
    return menuItems.map((eachItem) => {
      return (
        <MenuItem
          style={{ background: eachItem.color }}
          onClick={
            !eachItem.color &&
            props.handleDropDownChange(type, eachItem, props.data._id)
          }
          className={eachItem.color && classes.textCapital}
        >
          {eachItem.name}
        </MenuItem>
      );
    });
  };

  const {
    classes,
    data,
    handleChange,
    deleteProject,
    handleDropDownChange,
    imageUpload,
    onClear,
  } = props;
  const {
    materialsOrTechniques,
    room,
    mainColor,
    features,
    trade,
    projectImgDescription,
    projectImgTags,
    openDeleteModal,
    newOptionValue,
  } = state;
  return (
    <>
      <Row>
        <div className={classes.addImagewidth}>
          <Row>
            <div className={classes.rectangle_icons}>
              <UploadImgColor
                requiredIconStyle={props.requiredIconStyle}
                plus_bgcolor={props.plus_bgcolor}
                color_div={props.color_div}
                iconStyle={props.iconStyle}
                cropSize={props.cropSize}
                imageUpload={imageUpload}
                croppedImage={get(data, "alterImageUri.croppedImage", "")}
                originalImg={get(data, "alterImageUri.originalProjImgUrl", "")}
                hasImageData={get(data, "alterImageUri.croppedImage", "")}
                id={data._id}
                onClear={onClear}
                type="projectImage"
                transparentColor={color.primary_palette.white}
              />
              {/* kept it for reference will remove once done */}
              {/* <AddImage
                id={data._id}
                key={get(data, "alterImageUri.croppedImage", "")}
                className={placeholderStyleClass}
                name="imageUri"
                imageUpload={imageUpload}
                croppedImage={get(data, "alterImageUri.croppedImage", "")}
                originalImg={get(data, "alterImageUri.originalProjImgUrl", "")}
                defaultImage={props.defaltPlaceholder}
                cropSize={props.cropSize}
                type="projectImage"
                onClear={onClear}
                hasImageData={get(data, "alterImageUri.croppedImage", "")}
              /> */}
            </div>
            <div className={classes.fields}>
              <div className={classes.textAreaText}>
                <Text className={classes.description}>
                  {strings.add_project.titles.description}
                </Text>
                <div className={classes.textArea_scroll}>
                  <CustomTextArea
                    countValue={300}
                    enableCount
                    enableClear
                    id={data._id}
                    className={classes.textarea}
                    onBlur={handleChange}
                    name="projectImgDescription"
                    defaultValue={projectImgDescription}
                    placeholder={strings.add_project.titles.textareaPlaceholder}
                    placementnumber={classes.num}
                  />
                </div>
              </div>
              <div className={classes.icons}>
                <Trash_can
                  className={classes.trash_can}
                  onMouseUp={onClear(data._id, "all")}
                />
              </div>
              <div className={classes.textAreaText}>
                <Text className={classes.description}>
                  {strings.add_project.titles.hashtags1}
                </Text>
                <CustomPlainInputField
                  className={classes.hashtags_field}
                  id={data._id}
                  onClear={onClear(data._id, "projectImgTags")}
                  onFocus={(e) => (e.target.placeholder = "")}
                  onBlur={(e) => {
                    handleChange(e);
                    e.target.placeholder =
                      strings.add_project.titles.hashtags_placeholder1;
                  }}
                  name="projectImgTags"
                  defaultValue={projectImgTags}
                  placeholder={strings.add_project.titles.hashtags_placeholder1}
                />
              </div>
            </div>
          </Row>
          <Row className={classes.field_spacing}>
            <div className={classes.field1}>
              <Row className={classes.drop_down_field}>
                <Text
                  size={15}
                  color={color.primary_palette.franklin_purple}
                  family="avenir_bold"
                  className={classes.word_spacing}
                >
                  {strings.add_project.titles.materials}
                </Text>
                <CustomSelect
                  className={classes.dropdownWidth_first}
                  id={data._id}
                  IconComponent={Chevron_Down_Icon}
                  onChange={handleInputChange}
                  name="materialsOrTechniques"
                  isProjPage={true}
                  value={materialsOrTechniques}
                  renderValue={(value) =>
                    get(value, "name", "Choose Highlights")
                  }
                  MenuProps={{
                    getContentAnchorEl: null,
                    disableScrollLock: true,
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                  }}
                >
                  <CustomScrollbars
                    className={`${classes.customScrollHeight} ${classes.materialsOrTechniques} `}
                  >
                    {projectSelectionImageData &&
                      projectSelectionImageData.materialsOrTechniques &&
                      map(
                        projectSelectionImageData.materialsOrTechniques,
                        (materialsOrTechniquesVal) => {
                          return (
                            <MenuItem
                              onClick={handleDropDownChange(
                                "materialsOrTechniques",
                                materialsOrTechniquesVal,
                                data._id
                              )}
                            >
                              {materialsOrTechniquesVal.name}
                            </MenuItem>
                          );
                        }
                      )}
                  </CustomScrollbars>
                  <Row className={classes.inputContainer}>
                    <input
                      type="text"
                      placeholder="ADD YOUR OWN"
                      className={classes.inputField}
                      value={newOptionValue}
                      onChange={(e) => {
                        if (e.target.value.length > 25) {
                          return;
                        }
                        setState({ ...state, newOptionValue: e.target.value });
                      }}
                    />
                    <p
                      className={classes.plus}
                      onClick={handleAddOption(
                        "materialsOrTechniques",
                        newOptionValue,
                        data._id
                      )}
                    >
                      <div className={`${classes.iconPlusStyle}`} />
                    </p>
                  </Row>
                </CustomSelect>
              </Row>
              <Row>
                {map(
                  get(data, "materialsOrTechniques", []),
                  (eachMaterialsOrTechnieques) => {
                    return (
                      <div className={classes.listAlign}>
                        {eachMaterialsOrTechnieques.name}
                        <img
                          src="/assets/images/sketched-x.png"
                          onClick={handleDropDownChange(
                            "materialsOrTechniques",
                            eachMaterialsOrTechnieques,
                            data._id,
                            "del"
                          )}
                          className={classes.crossIconStyle}
                          alt=""
                        />
                      </div>
                    );
                  }
                )}
              </Row>
              <Row className={classes.trade_spacing}>
                <Row className={classes.drop_down_field}>
                  <Text
                    size={15}
                    color={color.primary_palette.franklin_purple}
                    family="avenir_bold"
                    className={classes.word_spacing}
                  >
                    {strings.add_project.titles.features}
                  </Text>
                  <CustomSelect
                    className={classes.dropdownWidth}
                    IconComponent={Chevron_Down_Icon}
                    isProjPage={true}
                    onChange={handleInputChange}
                    name="features"
                    value={features}
                    renderValue={(value) =>
                      get(value, "name", "Choose Features")
                    }
                    MenuProps={{
                      getContentAnchorEl: null,
                      disableScrollLock: true,
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                    }}
                  >
                    <CustomScrollbars
                      className={`${classes.customScrollHeight} ${classes.materialsOrTechniques} `}
                    >
                      {projectSelectionImageData &&
                        projectSelectionImageData.features &&
                        renderCategorizedMenu(
                          projectSelectionImageData.features,
                          "features"
                        )}
                    </CustomScrollbars>
                    <Row className={classes.inputContainer}>
                      <input
                        type="text"
                        placeholder="ADD YOUR OWN"
                        value={newOptionValue}
                        className={classes.inputField}
                        onChange={(e) => {
                          if (e.target.value.length > 25) {
                            return;
                          }
                          setState({
                            ...state,
                            newOptionValue: e.target.value,
                          });
                        }}
                      />
                      <p
                        className={classes.plus}
                        onClick={handleAddOption(
                          "features",
                          newOptionValue,
                          data._id
                        )}
                      >
                        <div className={`${classes.iconPlusStyle}`} />
                      </p>
                    </Row>
                  </CustomSelect>
                </Row>

                <Row className={`${classes.drop_down_field_large}`}>
                  <Text
                    size={15}
                    color={color.primary_palette.franklin_purple}
                    family="avenir_bold"
                    className={classes.word_spacing}
                  >
                    {strings.add_project.titles.featured_trade}
                  </Text>
                  <CustomSelect
                    className={classes.dropdownWidthTrade}
                    IconComponent={Chevron_Down_Icon}
                    onChange={handleInputChange}
                    name="trade"
                    value={trade}
                    renderValue={(value) => get(value, "name", "Choose Trades")}
                    MenuProps={{
                      getContentAnchorEl: null,
                      disableScrollLock: true,
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                    }}
                  >
                    <CustomScrollbars
                      className={`${classes.customScrollHeight} ${classes.materialsOrTechniques} `}
                    >
                      {projectSelectionImageData &&
                        projectSelectionImageData.trade &&
                        renderCategorizedMenu(
                          projectSelectionImageData.trade,
                          "trade"
                        )}
                    </CustomScrollbars>
                  </CustomSelect>
                </Row>
              </Row>
              <Row>
                <Row
                  className={`${classes.drop_down_field} ${classes.flexWrap}`}
                >
                  {map(get(data, "features", []), (eachfeature) => {
                    return (
                      <div className={classes.listAlign}>
                        {eachfeature.name}
                        <img
                          src="/assets/images/sketched-x.png"
                          onClick={handleDropDownChange(
                            "features",
                            eachfeature,
                            data._id,
                            "del"
                          )}
                          className={classes.crossIconStyle}
                          alt=""
                        />
                      </div>
                    );
                  })}
                </Row>
                <Row
                  className={`${classes.drop_down_field_large} ${classes.flexWrap}`}
                >
                  {map(get(data, "trade", []), (eachfeaturedTrade) => {
                    return (
                      <div className={classes.listAlign}>
                        {eachfeaturedTrade.name}
                        <img
                          src="/assets/images/sketched-x.png"
                          onClick={handleDropDownChange(
                            "trade",
                            eachfeaturedTrade,
                            data._id,
                            "del"
                          )}
                          className={classes.crossIconStyle}
                          alt=""
                        />
                      </div>
                    );
                  })}
                </Row>
              </Row>
              <Row className={classes.trade_spacing}>
                <Row className={`${classes.drop_down_field}`}>
                  <Text
                    size={15}
                    color={color.primary_palette.franklin_purple}
                    family="avenir_bold"
                    className={classes.word_spacing}
                  >
                    {strings.add_project.titles.room}
                  </Text>
                  <CustomSelect
                    className={classes.dropdownWidthRoom}
                    IconComponent={Chevron_Down_Icon}
                    onChange={handleInputChange}
                    name="room"
                    value={room}
                    renderValue={(value) => get(value, "name", "Choose Room")}
                    MenuProps={{
                      getContentAnchorEl: null,
                      disableScrollLock: true,
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                    }}
                  >
                    <CustomScrollbars
                      className={`${classes.customScrollHeight} ${classes.materialsOrTechniques} `}
                    >
                      {projectSelectionImageData &&
                        projectSelectionImageData.room &&
                        renderCategorizedMenu(
                          projectSelectionImageData.room,
                          "room"
                        )}
                    </CustomScrollbars>
                  </CustomSelect>
                </Row>
                <Row className={`${classes.drop_down_field_large}`}>
                  <Text
                    size={15}
                    color={color.primary_palette.franklin_purple}
                    family="avenir_bold"
                    className={classes.main_colors_text}
                  >
                    {strings.add_project.titles.main_colors}
                  </Text>
                  <CustomSelect
                    className={classes.dropdownWidthColor}
                    IconComponent={Chevron_Down_Icon}
                    onChange={handleInputChange}
                    isProjPage={true}
                    name="mainColor"
                    value={mainColor}
                    renderValue={(value) => get(value, "color", "Pick Color")}
                    MenuProps={{
                      getContentAnchorEl: null,
                      disableScrollLock: true,
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                    }}
                  >
                    <CustomScrollbars
                      className={classes.customScrollHeightColor}
                    >
                      {projectSelectionImageData &&
                        projectSelectionImageData.mainColor &&
                        map(
                          projectSelectionImageData.mainColor,
                          (mainColorVal) => {
                            return (
                              <MenuItem
                                onClick={handleDropDownChange(
                                  "mainColor",
                                  mainColorVal,
                                  data._id
                                )}
                                className={classes.colorLiAlign}
                              >
                                <span
                                  style={{
                                    backgroundColor: mainColorVal.color,
                                  }}
                                  className={
                                    mainColorVal.color === "White"
                                      ? classes.spanIconAlignWhite
                                      : classes.spanIconAlign
                                  }
                                ></span>
                                <span className={classes.colorTxt}>
                                  {mainColorVal.color}
                                </span>
                              </MenuItem>
                            );
                          }
                        )}
                    </CustomScrollbars>
                  </CustomSelect>
                </Row>
              </Row>
              <Row>
                <Row className={`${classes.drop_down_field}`}>
                  <Row className={classes.flexWrap}>
                    {map(get(data, "room", []), (eachRoom) => {
                      return (
                        <div className={classes.listAlign}>
                          {eachRoom.name}
                          <img
                            src="/assets/images/sketched-x.png"
                            onClick={handleDropDownChange(
                              "room",
                              eachRoom,
                              data._id,
                              "del"
                            )}
                            className={classes.crossIconStyle}
                            alt=""
                          />
                        </div>
                      );
                    })}
                  </Row>
                </Row>
                <Row className={`${classes.drop_down_field_large}`}>
                  <Row className={classes.flexWrap}>
                    {map(get(data, "mainColor", []), (eachmainColor) => {
                      return (
                        <div className={classes.listAlign}>
                          {eachmainColor.color}
                          <img
                            src="/assets/images/sketched-x.png"
                            onClick={handleDropDownChange(
                              "mainColor",
                              eachmainColor,
                              data._id,
                              "del"
                            )}
                            className={classes.crossIconStyle}
                            alt=""
                          />
                        </div>
                      );
                    })}
                  </Row>
                </Row>
              </Row>
            </div>
          </Row>
        </div>
      </Row>
      <React.Fragment>
        <CustomModal
          open={openDeleteModal}
          aria-labelledby="modal-title"
          aria-describedby="modal-description"
          onClose={handleDeleteModal}
        >
          <DeleteModal
            onClose={handleDeleteModal}
            deleteProject={deleteProject}
            id={data._id}
            imagesData={data.imageUri}
          />
        </CustomModal>
      </React.Fragment>
    </>
  );
}

export default ProjectImagStyles(ProjectAddImag);
