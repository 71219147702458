import React, { useState } from "react";
import { get } from "lodash";
import { InputAdornment } from "@material-ui/core";
import {
  ATLEAST_ONE_CAPITAL_LETTER,
  NUM_OR_SYMBOL,
} from "../../validations/validations";
import Ribbon from "../../components/data_display/icons/Ribbon";
import newPasswordStyles from "./styles/newPasswordStyles";
import Text from "../../components/common/ui_kit/text";
import CustomTextField from "../../components/inputs/custom_textfields";
import strings from "../../utilities/strings";
import CustomButton from "../../components/navigations/custom_buttons";
import Close_Icon from "../../components/data_display/icons/Close";
import HidePassword_Icon from "../../components/data_display/icons/HidePassword";
import CustomModal from "../../components/inputs/custom_modal";
import { color } from "../../utilities/themes";
import { Row } from "../common/ui_kit";
import CustomLink from "../navigations/custom_links";
import { RecoverActions } from "../../redux/actions";
import { useDispatch } from "react-redux";
import AllSet from "../../components/recover_password/allSet";
import { useHistory } from "react-router-dom";

function EnterNewPassword(props) {
  let tokenString = props.location.pathname;
  if (tokenString) {
    let res = tokenString.split("/resetPassword/")[1];
    localStorage.setItem("resetToken", res);
  }
  const history = useHistory();
  const { classes } = props;
  const [values, setValues] = useState({
    showPassword: false,
    openAllSetModal: false,
    showNewPassword: true,
    password: "",
    errors: {},
  });
  const dispatch = useDispatch();

  const { showPassword, password, errors, openAllSetModal, showNewPassword } =
    values;

  const handleValidation = (name, value) => {
    if (name === "password" && value !== "") {
      const hasEight = hasEightChars(value);
      const numOrSymbol = hasNumOrSymbol(value);
      const hasCaps = hasUpperCaseLetter(value);
      if (!value) {
        errors[name] = {
          error: true,
          touched: true,
          message: strings.join_us.errors.password_required,
          hasEight,
          numOrSymbol,
          hasCaps,
        };
        setValues({ ...values, errors, [name]: value });
        return;
      } else {
        if (hasEight && numOrSymbol && hasCaps) {
          errors[name] = {
            error: false,
            message: "",
            hasEight,
            numOrSymbol,
            hasCaps,
            touched: true,
          };
        } else {
          errors[name] = {
            error: true,
            message: "",
            hasEight,
            numOrSymbol,
            hasCaps,
            touched: true,
          };
        }
        setValues({ ...values, errors, [name]: value });
      }
    } else {
      setValues({ ...values, [name]: value, errors });
    }
  };

  const hasEightChars = (passwordVal) => {
    return passwordVal.length >= 8;
  };

  const hasUpperCaseLetter = (value) => {
    return ATLEAST_ONE_CAPITAL_LETTER.test(value);
  };

  const hasNumOrSymbol = (value) => {
    return NUM_OR_SYMBOL.test(value);
  };

  const onInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "password") {
      setValues({ ...values, [name]: value });
    }
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !showPassword });
  };

  const hasFormErrors = () => {
    const errs = Object.values(errors).map((each) => each.error);
    if (!errs.includes(true) && password) {
      return false;
    }
    return true;
  };

  const redirectToSignIn = () => {
    history.push("/frlogin");
    window.location.reload();
  };

  const redirectToJoinUs = () => {
    history.push("/frjoinus");
    window.location.reload();
  };

  const onClearInput = (name) => () => {
    handleValidation(name, "");
    setValues({ ...values, password: "" });
  };

  const closeModal = () => {
    setValues({ ...values, password: "", errors: {}, showNewPassword: false });
    history.push("/");
  };

  const submitForm = () => {
    const reqParams = {
      password: values.password,
      token: localStorage.getItem("resetToken"),
    };
    dispatch(
      RecoverActions.setNewPassword(reqParams, (res) => {
        setValues({
          ...values,
          openAllSetModal: true,
        });
      })
    );
  };
  return (
    <>
      <CustomModal
        open={showNewPassword}
        aria-labelledby="modal-title"
        disablePortal
        aria-describedby="modal-description"
        disableAutoFocus
      >
        <div className={classes.recover_password_width}>
          <div className={classes.textRight}>
            <CustomButton
              className={classes.crossBtn}
              onClick={() => closeModal()}
              isX
            >
              <Close_Icon />
            </CustomButton>
          </div>
          <div className={classes.textCenter}>
            <img
              src="/assets/images/lock.png"
              className={classes.lock_img_new}
              alt=""
            />
          </div>
          <Text
            size={24}
            color={color.primary_palette.franklin_purple}
            family="gillsans_sb"
            transform="uppercase"
            className={classes.textCenter}
          >
            {strings.passwords.titles.enter_new_password}
          </Text>
          <form>
            <div className={classes.textCenter}>
              <CustomTextField
                className={classes.password_width}
                clearIcon
                label={strings.passwords.titles.enter_password}
                type={showPassword ? "text" : "password"}
                value={password}
                name="password"
                // onChange={onInputChange}
                clearIcon={true}
                onChange={(e) =>
                  handleValidation(e.target.name, e.target.value)
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      position="end"
                      tabIndex="-1"
                      aria-label="toggle password visibility"
                      className={classes.password_icon}
                      onClick={handleClickShowPassword}
                    >
                      {password.length > 0 && (
                        <Close_Icon
                          onMouseUp={onClearInput("password")}
                          style={{
                            height: "7px",
                            width: "7px",
                            cursor: "pointer",
                            zIndex: "1000",
                            marginRight: "10px",
                          }}
                        />
                      )}
                      {showPassword ? (
                        <img
                          src="/assets/images/View Password .svg"
                          alt=""
                          className={classes.cursor}
                        />
                      ) : (
                        <HidePassword_Icon
                          style={{
                            fontSize: "1.3125rem",
                            cursor: "pointer",
                          }}
                        />
                      )}
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <div style={{ textAlign: "center" }} className={classes.errorAlign}>
              <Text size={12} family="avenir_light">
                <span
                  className={
                    !get(errors, "password.touched", false)
                      ? classes.password_validation
                      : get(errors, "password.hasEight", false)
                      ? classes.successText
                      : classes.errorText
                  }
                >
                  {strings.join_us.messages.eight_chars}
                </span>
                <span
                  className={
                    !get(errors, "password.touched", false)
                      ? classes.password_validation
                      : get(errors, "password.hasCaps", false)
                      ? classes.successText
                      : classes.errorText
                  }
                >
                  {strings.join_us.messages.one_letter}
                </span>
                <span
                  className={
                    !get(errors, "password.touched", false)
                      ? classes.password_validation
                      : get(errors, "password.numOrSymbol", false)
                      ? classes.successText
                      : classes.errorText
                  }
                >
                  {strings.join_us.messages.number_symbol}
                </span>
              </Text>
            </div>
            <div className={classes.textCenter}>
              <CustomButton
                className={classes.set_new_password}
                onMouseUp={() => submitForm()}
                disabled={hasFormErrors()}
                endIcon={
                  !hasFormErrors() && (
                    <Ribbon
                      color="primary"
                      style={{
                        fontSize: "47px",
                        marginTop: "-10px",
                        marginRight: "-5px",
                      }}
                    />
                  )
                }
              >
                {strings.passwords.titles.set_new_password}
              </CustomButton>
            </div>
          </form>
          <Row className={classes.footer_spacing}>
            <div className={classes.sign_in}>
              <Text
                size={14}
                color={color.primary_palette.franklin_purple}
                family="gillsans_sb"
              >
                {strings.passwords.titles.Wait_password}
                <CustomLink
                  underline="always"
                  className={`${classes.recover} ${classes.margin_left_3}`}
                  onClick={redirectToSignIn}
                >
                  <label className={classes.cursor}>
                    {strings.passwords.titles.sign_in}
                  </label>
                </CustomLink>
              </Text>
            </div>
            <div className={classes.join_here}>
              <Text
                size={14}
                color={color.primary_palette.franklin_purple}
                family="gillsans_sb"
              >
                {strings.passwords.titles.not_member}
              </Text>
              <CustomLink
                underline="always"
                className={classes.recover}
                onClick={redirectToJoinUs}
              >
                <label className={classes.cursor}>
                  {strings.passwords.titles.join_here}
                </label>
              </CustomLink>
            </div>
          </Row>
        </div>
      </CustomModal>
      <CustomModal
        open={openAllSetModal}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <AllSet />
      </CustomModal>
    </>
  );
}

export default newPasswordStyles(EnterNewPassword);
