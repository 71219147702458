import { withStyles } from "@material-ui/core/styles";
import tradework_theme, { pxToRem, color } from "../../../utilities/themes";
// import tradework_theme, { pxToRem, color } from "../../../utilities/themes";

const styles = withStyles({
  mobile_business: {
    backgroundColor: color.mobile_white,
    border: `solid ${pxToRem(2)} ${color.primary_palette.black}`,
    padding: pxToRem(3),
    paddingBottom: pxToRem(20),
    margin: pxToRem(7),
  },
  company_name: {
    width: "100%",
    paddingTop: pxToRem(15),
    paddingBottom: pxToRem(15),
    "& .MuiInputBase-input": {
      fontSize: pxToRem(25),
      ...tradework_theme.typography.styles.TrajanPro3,
      color: color.primary_palette.black,
      borderBottom: 0,
      textAlign: "center",
      padding: 0,
    },
    "& .MuiInput-underline": {
      "&:before": {
        display: "none",
      },
      "&:after": {
        display: "none",
      },
    },
    "& .MuiInput-root": {
      width: "100%",
    },
  },
  text_field: {
    width: "100%",
    "& .MuiInputBase-input": {
      fontSize: pxToRem(14),
      ...tradework_theme.typography.styles.avenir_sb,
      color: color.primary_palette.black,
      borderBottom: 0,
      textAlign: "center",
      padding: 0,
    },
    "& .MuiInput-underline": {
      "&:before": {
        display: "none",
      },
      "&:after": {
        display: "none",
      },
    },
    "& .MuiInput-root": {
      width: "100%",
    },
  },
  width_50: {
    width: "50%",
    textAlign: "center",
  },
  padding_top_9: {
    paddingTop: pxToRem(9),
  },
  border_top_gray: {
    borderTop: `solid ${pxToRem(1)} ${
      color.secondary_palette.grays.shadow_gray
    }`,
  },
  relative: {
    position: "relative",
  },
  rc_img: {
    width: pxToRem(74),
    height: pxToRem(74),
    right: pxToRem(12),
    top: "88%",
    position: "absolute",
    border: `solid ${pxToRem(1.5)} ${color.primary_palette.black}`,
  },
  flag_img: {
    width: "65%",
    height: pxToRem(170),
    backgroundColor: color.secondary_palette.grays.shadow_gray,
  },
  flagSpacing: {
    paddingTop: pxToRem(25),
    paddingBottom: pxToRem(17),
    marginLeft: pxToRem(28),
  },
  paddingTop25: {
    paddingTop: pxToRem(25),
  },
  txtCenter: {
    textAlign: "center",
  },
  width_35: {
    width: "35%",
  },
  flagLeft: {
    alignItems: "center",
  },
  border_top: {
    position: "relative",
    "&:after": {
      position: "absolute",
      content: "''",
      left: "38%",
      backgroundColor: color.primary_palette.black,
      height: pxToRem(1),
      width: pxToRem(36),
      bottom: pxToRem(4),
    },
  },
  pos_relative: {
    position: "relative",
  },
  flag_title: {
    position: "relative",
    backgroundColor: color.primary_palette.white,
    left: pxToRem(-44),
    width: "100%",
    paddingLeft: pxToRem(20),
    paddingRight: pxToRem(5),
    paddingTop: pxToRem(5),
  },
  view_data: {
    border: `solid ${pxToRem(0.5)} ${color.primary_palette.black}`,
    width: "70%",
    textAlign: "center",
    justifyContent: "space-around",
    alignItems: "center",
    position: "relative",
    paddingTop: pxToRem(5),
    paddingBottom: pxToRem(5),
    height: pxToRem(38),
    marginLeft: pxToRem(10),
  },
  border_right: {
    borderRight: `solid ${pxToRem(0.5)} ${color.primary_palette.black}`,
    paddingRight: pxToRem(3),
  },
  flag_title_right: {
    position: "relative",
    backgroundColor: color.primary_palette.white,
    width: "100%",
    paddingLeft: pxToRem(20),
    paddingRight: pxToRem(5),
    paddingTop: pxToRem(5),
  },
  alignLeft: {
    margin: "0 0 0 auto",
    marginRight: pxToRem(5),
  },
  txtRight: {
    textAlign: "right",
  },
  border_top_left: {
    position: "relative",
    "&:after": {
      position: "absolute",
      content: "''",
      left: "40%",
      backgroundColor: color.primary_palette.black,
      height: pxToRem(1),
      width: pxToRem(36),
      bottom: pxToRem(4),
    },
  },
  textWidthLeft: {
    width: "95%",
  },
  margin_7: {
    margin: pxToRem(7),
  },
  flagship_margins: {
    margin: pxToRem(7),
    marginBottom: pxToRem(-18),
  },
  company_Bg: {
    backgroundColor: color.secondary_palette.grays.shadow_gray,
    paddingTop: pxToRem(20),
    paddingBottom: pxToRem(20),
    marginTop: pxToRem(25),
    position: "relative",
  },
  company_inner_blk: {
    backgroundColor: "rgba(255, 255, 255, 0.75)",
    width: pxToRem(340),
    margin: "0 auto",
    paddingRight: pxToRem(10),
    paddingBottom: pxToRem(15),
    paddingLeft: pxToRem(10),
    paddingTop: pxToRem(28),
    position: "relative",
  },
  cover_img: {
    position: "absolute",
    top: 0,
    height: "100%",
    width: "100%",
    objectFit: "cover",
  },
  company_img: {
    width: pxToRem(175),
    height: pxToRem(175),
    borderRadius: "50%",
    padding: `${pxToRem(20)} ${pxToRem(0)}`,
  },
  padding_top_5: {
    paddingTop: pxToRem(5),
  },
  padding_top_15: {
    paddingTop: pxToRem(15),
  },
  txtCenterEllipses: {
    textAlign: "center",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  websiteTxt: {
    textDecoration: "none !important",
  },
  emailActiveStyle: {
    cursor: "pointer",
    color: color.primary_palette.franklin_purple,
  },
  descriptionData: {
    // height: pxToRem(170),
    // maxHeight: pxToRem(170),
    overflow: "hidden",
    fontSize: pxToRem(12),
    ...tradework_theme.typography.styles.avenir_sb,
    // paddingLeft: pxToRem(18),
    // paddingRight: pxToRem(18),
  },
  descriptionTextStyle: {
    width: pxToRem(320),
    wordBreak: "break-word",
  },
  slider_width: {
    width: pxToRem(180),
    //height: pxToRem(208),
    outline: "none",
    textAlign: "initial",
    "&:hover": {
      "& .hover_text": {
        color: color.primary_palette.franklin_purple,
      },
    },
    "& .hover_text": {
      "&:hover": {
        color: color.primary_palette.franklin_purple,
      },
    },
  },
  subTxtHover: {
    "&:hover": {
      "& .hover_text": {
        color: color.primary_palette.franklin_purple,
      },
    },
  },
  slider_public_style: {
    display: "inline-block",
    marginRight: pxToRem(70),
  },
  sliderImgStyle: {
    width: pxToRem(182),
    margin: "0 auto",
    marginBottom: pxToRem(12),
    height: pxToRem(218),
  },
  instaSliderImgStyle: {
    width: pxToRem(208),
    margin: "0 auto",
    marginBottom: pxToRem(12),
    height: pxToRem(208),
  },
  slider: {
    width: pxToRem(190),
    margin: "0 auto",
    position: "relative",
    "& .slick-next": {
      "&:before": {
        content: `url("/assets/images/element_wing_right.png")`,
        width: pxToRem(30),
        height: pxToRem(107),
        transform: "rotate(0deg)  translateY(-64px)",
        display: " inline-block",
      },
    },
    "& .slick-prev": {
      left: pxToRem(-39),
      "&:before": {
        content: `url("/assets/images/element_wing_left.png")`,
        width: pxToRem(30),
        height: pxToRem(107),
        transform: "translateY(-64px)",
        display: " inline-block",
      },
    },
    "& .slick-dots": {
      bottom: 0,
    },
  },
  slider2Images: {
    width: pxToRem(190),
    margin: "0 auto",
    position: "relative",
    "& .slick-next": {
      "&:before": {
        content: `url("/assets/images/element_wing_right.png")`,
        width: pxToRem(30),
        height: pxToRem(107),
        transform: "rotate(0deg)  translateY(-64px)",
        display: " inline-block",
      },
    },
    "& .slick-prev": {
      left: pxToRem(-39),
      "&:before": {
        content: `url("/assets/images/element_wing_left.png")`,
        width: pxToRem(30),
        height: pxToRem(107),
        transform: "translateY(-64px)",
        display: " inline-block",
      },
    },
    "& .slick-dots": {
      bottom: 0,
    },
  },
  slider3Images: {
    width: pxToRem(190),
    margin: "0 auto",
    position: "relative",
    "& .slick-next": {
      "&:before": {
        content: `url("/assets/images/element_wing_right.png")`,
        width: pxToRem(30),
        height: pxToRem(107),
        transform: "rotate(0deg)  translateY(-64px)",
        display: " inline-block",
      },
    },
    "& .slick-prev": {
      left: pxToRem(-39),
      "&:before": {
        content: `url("/assets/images/element_wing_left.png")`,
        width: pxToRem(30),
        height: pxToRem(107),
        transform: "translateY(-64px)",
        display: " inline-block",
      },
    },
    "& .slick-dots": {
      bottom: 0,
    },
  },
  ppBackgroundStyle: {
    backgroundColor: color.products_background,
    paddingBottom: pxToRem(20),
  },
  socialImageStyle: {
    width: pxToRem(35),
    height: pxToRem(35),
    marginRight: pxToRem(32),
    marginLeft: pxToRem(32),
  },
  cursor: {
    cursor: "pointer",
  },
  textCenter: {
    textAlign: "center",
  },
  footerAlign: {
    textAlign: "center",
    margin: `${pxToRem(10)} ${pxToRem(0)}`,
  },
  headerAlign: {
    marginTop: pxToRem(10),
  },
  horizontal_DB: {
    width: pxToRem(210),
  },
  optionsBorder: {
    borderTop: `solid ${pxToRem(1)} ${
      color.secondary_palette.grays.shadow_gray
    }`,
    borderBottom: `solid ${pxToRem(1)} ${
      color.secondary_palette.grays.shadow_gray
    }`,
    padding: pxToRem(14),
    width: pxToRem(200),
    margin: "0 auto",
    marginTop: pxToRem(20),
  },
  marginBottom9: {
    marginBottom: pxToRem(9),
  },
  img_block: {
    width: pxToRem(190),
    height: pxToRem(222),
  },
  product_spacing: {
    textAlign: "center",
    padding: pxToRem(20),
  },
  more_info: {
    textAlign: "right",
  },
  padding7: {
    padding: pxToRem(7),
  },
  productsHeaderStyle: {
    padding: pxToRem(7),
    background: color.primary_palette.fafa_gray,
    borderTop: `solid ${pxToRem(1)} ${
      color.secondary_palette.grays.shadow_gray
    }`,
    marginTop: pxToRem(20),
  },
  imageWidth: {
    width: "100%",
    objectFit: "scale-down",
    height: "auto",
  },
  marginAuto: {
    margin: "0 auto",
  },
  imageDesc: {
    margin: "0 auto",
  },
  padding16: {
    padding: pxToRem(16),
  },
  textLeft: {
    textAlign: "left",
  },
  insightAlign: {
    marginTop: pxToRem(20),
  },
  left_previous: {
    flexGrow: 1,
  },
  right_previous: {
    flexGrow: 1,
    justifyContent: "flex-end",
  },
  marginTop34: {
    marginTop: pxToRem(34),
  },
  breakLine: {
    borderBottom: `solid ${pxToRem(1)} ${
      color.secondary_palette.grays.shadow_gray
    }`,
    margin: `${pxToRem(10)} auto`,
    width: pxToRem(320),
  },
  marginTop38: {
    marginTop: pxToRem(38),
  },
});

export default styles;
