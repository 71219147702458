import { withStyles } from "@material-ui/core/styles";
import tradework_theme, { pxToRem, color } from "../../../utilities/themes";

const styles = withStyles({
  product_cover: {
    width: pxToRem(745),
    border: `solid ${pxToRem(2)} ${color.primary_palette.free_purple}`,
    paddingBottom: pxToRem(20),
    backgroundColor: color.secondary_palette.blues.modal_blue,
  },
  textRight: {
    textAlign: "right",
  },
  cross_mark: {
    fontSize: pxToRem(15),
  },
  cross_btn: {
    minWidth: pxToRem(40),
    padding: pxToRem(16),
  },
  product_cover_inner: {
    width: pxToRem(360),
    margin: "0 auto",
    textAlign: "center",
  },
  product_cover_inner_project: {
    width: pxToRem(360),
    margin: "0 auto",
    textAlign: "center",
    border: `solid ${pxToRem(1)} ${color.primary_palette.franklin_purple}`,
    backgroundColor: color.secondary_palette.blues.tile_blue,
  },
  img_block: {
    width: pxToRem(177),
    height: pxToRem(118),
    backgroundColor: color.secondary_palette.grays.shadow_gray,
    margin: `${pxToRem(0)} auto`,
    marginTop: pxToRem(27),
    marginBottom: pxToRem(11),
  },
  padding_20: {
    padding: `${pxToRem(0)} ${pxToRem(20)}`,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  image_block: {
    width: pxToRem(177),
    height: pxToRem(118),
    margin: `${pxToRem(0)} auto`,
    marginTop: pxToRem(27),
    marginBottom: pxToRem(11),
  },
  padding_sides: {
    paddingTop: pxToRem(30),
    paddingBottom: pxToRem(13),
  },
  deleteBtn: {
    width: pxToRem(194),
    height: pxToRem(40),
    borderRadius: pxToRem(20),
    border: `solid ${pxToRem(2)} ${color.form_colors.blueberry_purple}`,
    backgroundColor: color.primary_palette.white,
    marginTop: pxToRem(22),
    "& .MuiButton-label": {
      ...tradework_theme.typography.styles.avenir_sb,
      fontSize: pxToRem(16),
      color: color.form_colors.royal_purple_1,
    },
  },
  textCenter: {
    textAlign: "center",
  },
});

export default styles;
