import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { get } from "lodash";
import { useHistory } from "react-router-dom";

import { color } from "../../utilities/themes";
import PublicviewStyles from "./styles/publicView_projectsStyles";
import Text from "../common/ui_kit/text";
import strings from "../../utilities/strings";
import Row from "../common/ui_kit/row";
import GridTwo from "../grids/grid_2";
import GridThree from "../grids/grid_3";
import GridFour from "../grids/grid_4";
import GridFive from "../grids/grid_5";
import GridSix from "../grids/grid_6";
import Gridseven from "../grids/grid_7";
import GridEight from "../grids/grid_8";
import GridNine from "../grids/grid_9";
import GridTen from "../grids/grid_10";
import GridEleven from "../grids/grid_11";
import GridTwelve from "../grids/grid_12";
import GridOne from "../grids/gird_1";
import Feedback from "../modals/feedbackModal";
import { ProfileActions } from "../../redux/actions";
import CustomModal from "../inputs/custom_modal";

const projectInfoData = (data) => {
  switch (data.length) {
    case 1:
      return <GridOne imageData={data} />;
    case 2:
      return <GridTwo imageData={data} />;
    case 3:
      return <GridThree imageData={data} />;
    case 4:
      return <GridFour imageData={data} />;
    case 5:
      return <GridFive imageData={data} />;
    case 6:
      return <GridSix imageData={data} />;
    case 7:
      return <Gridseven imageData={data} />;
    case 8:
      return <GridEight imageData={data} />;
    case 9:
      return <GridNine imageData={data} />;
    case 10:
      return <GridTen imageData={data} />;
    case 11:
      return <GridEleven imageData={data} />;
    case 12:
      return <GridTwelve imageData={data} />;
  }
};

function PublicViewProjects(props) {
  const { classes } = props;
  const [state, setState] = useState({
    toggleFeedbackModal: false,
  });
  
  const { toggleFeedbackModal } = state;

  //API
  useEffect(() => {
    window.scrollTo(0, 0);
    fetchData();
  }, []);

  const dispatch = useDispatch();
  const fetchData = () => {
    dispatch(
      ProfileActions.getProjectsByPortfolio(localStorage.getItem("portfolioId"))
    );
  };

  const projectsData = useSelector(
    (resData) => resData.Profile.projectsByPortfolio
  );

  const history = useHistory();
  const redirectIndividual = (projectInfo, idx) => () => {
    history.push({
      pathname: "/ProjectSingleView",
      state: { ...projectInfo, idx },
    });
    window.location.reload();
  };

  const handleFeedbackModal = () => {
    setState({
      ...state,
      toggleFeedbackModal: !toggleFeedbackModal,
    });
  };

  return (
    <>
      <div className={classes.publicviewProject}>
        <img
          src="/assets/images/Beta_Stamp.png"
          className={classes.betaStampIcon}
          alt=""
          onClick={handleFeedbackModal}
        />
        <div className={`${classes.textCenter} ${classes.padding_top_30}`}>
          <Text
            size={20}
            color={color.primary_palette.black}
            family="TrajanPro3"
          >
            {strings.publicView_p.titles.project_heading}
          </Text>
          <img
            src="/assets/images/Horizontal_DB.jpg"
            className={classes.horizontal_DB}
            alt=""
          />
          <Text
            size={45}
            color={color.primary_palette.black}
            family="TrajanPro3"
          >
            {/* {strings.publicView_p.titles.productName} */}
            {projectsData && get(projectsData, "name", "")}
          </Text>
        </div>
        {projectsData &&
          projectsData.projects &&
          projectsData.projects.map((project, index) => (
            <>
              <div onClick={redirectIndividual(project, index)}>
                <div className={classes.grid_block}>
                  <Row>
                    <div className={classes.grid_heading}>
                      <Text
                        size={36}
                        color={color.primary_palette.franklin_purple}
                        family="gillsans_light"
                        className={classes.textupper}
                      >
                        {get(project, "title", "project name")}
                      </Text>
                    </div>
                    {/* commented for now need to get clarity on implementation */}
                    {/* <div className={classes.grid_icon}>
                      <div>
                        <img
                          src="/assets/images/Heart.png"
                          className={classes.heart}
                          alt=""
                        />
                      </div>
                      <div>
                        <img
                          src="/assets/images/Folder.png"
                          className={classes.folder}
                          alt=""
                        />
                      </div>
                    </div> */}
                    <div className={classes.description}>
                      <Text
                        size={14}
                        color={color.primary_palette.black}
                        family="avenir_sb"
                      >
                        {get(project, "streetAddress", "")}
                      </Text>
                      <Text
                        size={14}
                        color={color.primary_palette.black}
                        family="avenir_sb"
                      >
                        {get(project, "style[0]", "")}
                      </Text>
                      <Text
                        size={14}
                        color={color.primary_palette.black}
                        family="avenir_sb"
                      >
                        {get(project, "venue[0]", "")}
                      </Text>
                    </div>
                  </Row>
                </div>
                {projectInfoData(project.projectImages)}
                <div className={classes.hash_tags}>
                  {project.tags.map((tag) => (
                    <span>{tag}</span>
                  ))}
                </div>
              </div>
            </>
          ))}
      </div>
      <CustomModal
        open={toggleFeedbackModal}
        onClose={handleFeedbackModal}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Feedback
          open={toggleFeedbackModal}
          onClose={handleFeedbackModal}
          locationInfo="Projects View Page"
        />
      </CustomModal>
    </>
  );
}

export default PublicviewStyles(PublicViewProjects);
