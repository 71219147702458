import { withStyles } from "@material-ui/core/styles";
import tradework_theme, { pxToRem, color } from "../../../utilities/themes";

const styles = withStyles({
  flagship_img: {
    width: pxToRem(588),
    height: pxToRem(346),
    // cursor: "pointer",
    "& img": {
      width: "100%",
      border: `${pxToRem(2)} solid ${color.primary_palette.white}`,
    },
  },
  subTextCenter: {
    fontSize: pxToRem(12),
    ...tradework_theme.typography.styles.avenir_sb,
    color: color.primary_palette.black,
    paddingBottom: pxToRem(6),
    width: "80%",
    textAlign: "center",
    opacity: "1",
    cursor: "pointer",
  },
  subTextCenterOpacity: {
    fontSize: pxToRem(12),
    ...tradework_theme.typography.styles.avenir_sb,
    color: color.primary_palette.black,
    paddingBottom: pxToRem(6),
    width: "80%",
    textAlign: "center",
    opacity: "0.65",
  },
  subTextCenter1: {
    fontSize: pxToRem(12),
    ...tradework_theme.typography.styles.avenir_sb,
    color: color.primary_palette.black,
    paddingBottom: pxToRem(6),
    width: "80%",
    margin: "0 auto",
    textAlign: "center",
    opacity: "1",
    cursor: "pointer",
  },
  subTextCenter1Opacity: {
    fontSize: pxToRem(12),
    ...tradework_theme.typography.styles.avenir_sb,
    color: color.primary_palette.black,
    paddingBottom: pxToRem(6),
    width: "80%",
    margin: "0 auto",
    textAlign: "center",
    opacity: "0.65",
  },
  hr_line: {
    width: pxToRem(60),
    height: pxToRem(1),
    backgroundColor: color.primary_palette.black,
    marginBottom: pxToRem(7),
    margin: "0 auto",
  },
  hr_lineLeft: {
    width: pxToRem(50),
    height: pxToRem(1),
    backgroundColor: color.primary_palette.black,
  },
  hr_main: {
    width: "80%",
  },
  hr_line1: {
    width: pxToRem(60),
    height: pxToRem(1),
    backgroundColor: color.primary_palette.black,
    margin: "0 auto",
    marginBottom: pxToRem(7),
  },
  description_text: {
    display: "block",
    textAlign: "center",
    alignSelf: "center",
  },
  mainText_bg: {
    backgroundColor: color.primary_palette.white,
    textAlign: "right",
    fontSize: pxToRem(30),
    ...tradework_theme.typography.styles.avenir_bold,
    color: color.primary_palette.black,
    width: pxToRem(400),
    opacity: "1",
    cursor: "pointer",
  },
  mainText_bg_opac: {
    textAlign: "right",
    fontSize: pxToRem(30),
    ...tradework_theme.typography.styles.avenir_sb,
    color: color.secondary_palette.grays.shadow_gray,
    width: pxToRem(303),
    opacity: "0.65",
  },
  TropicalLeft: {
    backgroundColor: color.primary_palette.white,
    position: "relative",
    width: "100%",
    paddingLeft: pxToRem(20),
    paddingRight: pxToRem(9),
    display: "flex",
    justifyContent: "flex-end",
    zIndex: 9,
  },
  mainText_bg_right: {
    backgroundColor: color.primary_palette.white,
    textAlign: "left",
    fontSize: pxToRem(30),
    ...tradework_theme.typography.styles.avenir_bold,
    color: color.primary_palette.black,
    width: pxToRem(400),
    opacity: "1",
    cursor: "pointer",
  },
  mainText_bg_opacity: {
    backgroundColor: color.primary_palette.white,
    textAlign: "left",
    fontSize: pxToRem(30),
    ...tradework_theme.typography.styles.avenir_sb,
    color: color.secondary_palette.grays.shadow_gray,
    width: pxToRem(303),
    opacity: "0.65",
  },
  Tropical: {
    backgroundColor: color.primary_palette.white,
    paddingLeft: pxToRem(9),
    width: "100%",
    paddingRight: pxToRem(76),
    position: "relative",
    right: pxToRem(30),
  },
  view_block: {
    border: `solid ${pxToRem(1)} ${color.primary_palette.black}`,
    width: pxToRem(130),
    justifyContent: "space-around",
    alignItems: "center",
    margin: " 0 auto",
    textAlign: "center",
    height: pxToRem(65),
    opacity: "1",
    marginTop: pxToRem(18),
    cursor: "pointer",
  },
  view_block_opacity: {
    border: `solid ${pxToRem(1)} ${color.primary_palette.black}`,
    width: pxToRem(130),
    justifyContent: "space-around",
    alignItems: "center",
    textAlign: "center",
    height: pxToRem(65),
    opacity: "0.65",
    marginLeft: pxToRem(20),
    marginTop: pxToRem(18),
  },
  view_block_right: {
    border: `solid ${pxToRem(1)} ${color.primary_palette.black}`,
    width: pxToRem(130),
    justifyContent: "space-around",
    alignItems: "center",
    margin: " 0 auto",
    textAlign: "center",
    height: pxToRem(65),
    opacity: "1",
    marginTop: pxToRem(18),
  },
  view_block_opacity_right: {
    border: `solid ${pxToRem(1)} ${color.primary_palette.black}`,
    width: pxToRem(130),
    justifyContent: "space-around",
    alignItems: "center",
    margin: " 0 auto",
    textAlign: "center",
    height: pxToRem(65),
    opacity: "0.65",
    marginTop: pxToRem(18),
  },
  image_br: {
    height: pxToRem(65),
    width: "50%",
  },
  iconStyleAdd: {
    backgroundImage: `url("/assets/images/add.png")`,
    backgroundSize: "cover",
    width: pxToRem(30),
    height: pxToRem(30),
    display: "inline-block",
    cursor: "pointer",
    "&:hover": {
      backgroundImage: `url("/assets/images/plus hover.png")`,
      backgroundSize: "cover",
      width: pxToRem(30),
      height: pxToRem(30),
      display: "inline-block",
      cursor: "pointer",
    },
  },
  borderView: {
    height: pxToRem(43),
    borderRight: `${pxToRem(1)} solid ${color.view_border}`,
  },
  viewText: {
    width: "50%",
  },
  flagShip_block: {
    position: "relative",
    margin: "0 auto",
    padding: `${pxToRem(0)} ${pxToRem(35)}`,
    marginTop: pxToRem(-20),
    justifyContent: "center",
    opacity: "1",
  },
  flagShip_block_opacity: {
    position: "relative",
    margin: "0 auto",
    padding: `${pxToRem(0)} ${pxToRem(35)}`,
    marginTop: pxToRem(-20),
    justifyContent: "center",
  },
  edit_icon: {
    fontSize: pxToRem(30),
    paddingLeft: pxToRem(10),
  },
  trash_icon: {
    fontSize: pxToRem(16),
    height: pxToRem(20),
    verticalAlign: "middle",
    paddingLeft: pxToRem(13),
    marginTop: pxToRem(6),
    "& path": {
      fill: color.secondary_palette.grays.shadow_gray,
    },
  },
  text_block: {},
  icons_placements: {
    position: "absolute",
    top: 0,
  },
  position: {
    position: "relative",
  },
  subTextCenter1_main: {
    width: "50%",
    margin: "0 auto",
  },
  add_icon_left: {
    position: "absolute",
    left: pxToRem(-136),
    fontSize: pxToRem(30),
    opacity: "1",
    cursor: "pointer",
    "& circle": {
      stroke: color.primary_palette.black,
    },
    "& path": {
      fill: color.primary_palette.black,
    },
  },
  headTitleAlignLeft: {
    position: "absolute",
    left: pxToRem(-139),
    top: pxToRem(-8),
    cursor: "pointer",
    "& .MuiSvgIcon-root": {
      fontSize: pxToRem(30),
      marginRight: pxToRem(7),
      verticalAlign: "text-bottom",
      position: "relative",
      top: pxToRem(14),
    },
    "&:hover": {
      "& .MuiSvgIcon-root": {
        "& ellipse": {
          fill: color.primary_palette.franklin_purple,
        },
      },
    },
  },
  arrowUpStyle: {
    position: "absolute",
    top: pxToRem(66),
    right: pxToRem(48),
    transform: "rotate(180deg)",
  },
  headTitleAlignRight: {
    position: "absolute",
    right: pxToRem(-140),
    top: pxToRem(-8),
    cursor: "pointer",
    "& .MuiSvgIcon-root": {
      fontSize: pxToRem(30),
      marginRight: pxToRem(7),
      verticalAlign: "text-bottom",
      position: "relative",
      top: pxToRem(14),
    },
    "&:hover": {
      "& .MuiSvgIcon-root": {
        "& ellipse": {
          fill: color.primary_palette.franklin_purple,
        },
      },
    },
  },
  edit_icon_left: {
    position: "absolute",
    right: "41%",
    left: pxToRem(-36),
    fontSize: pxToRem(30),
    cursor: "pointer",
  },
  trash_icon_left: {
    position: "absolute",
    left: pxToRem(-62),
    height: pxToRem(26),
    width: pxToRem(21),
    top: pxToRem(2),
    cursor: "pointer",
    "& path": {
      fill: color.secondary_palette.grays.shadow_gray,
    },
  },
  add_icon_right: {
    right: pxToRem(-45),
    fontSize: pxToRem(30),
    position: "absolute",
    cursor: "pointer",
    opacity: "1",
    "& circle": {
      stroke: color.primary_palette.black,
    },
    "& path": {
      fill: color.primary_palette.black,
    },
  },
  edit_icon_right: {
    fontSize: pxToRem(30),
    right: pxToRem(-38),
    position: "absolute",
    cursor: "pointer",
  },
  trash_icon_right: {
    position: "absolute",
    right: pxToRem(-64),
    top: pxToRem(2),
    height: pxToRem(26),
    width: pxToRem(21),
    cursor: "pointer",
    "& path": {
      fill: color.secondary_palette.grays.shadow_gray,
    },
  },
  cursor: {
    cursor: "pointer",
  },
  block_opacity: {
    opacity: "0.65",
  },
  iconStylePencil: {
    backgroundImage: `url("/assets/images/pencil_icon.png")`,
    backgroundSize: "cover",
    width: pxToRem(30),
    height: pxToRem(30),
    display: "inline-block",
    cursor: "pointer",
    "&:hover": {
      backgroundImage: `url("/assets/images/yellow hover.png")`,
      backgroundSize: "cover",
      width: pxToRem(30),
      height: pxToRem(30),
      display: "inline-block",
      cursor: "pointer",
    },
  },
  textAlignTop: {
    marginTop: pxToRem(-10),
  },
  hover_img: {
    // "& .hover_icon"
    "&:hover": {
      "& .MuiSvgIcon-root": {
        "& ellipse": {
          fill: color.primary_palette.franklin_purple,
        },
      },
      "& .placeHolder1": {
        backgroundImage: `url("/assets/images/Country House Hover.jpg")`,
        backgroundSize: "cover",
      },
      "& .placeHolder2": {
        backgroundImage: `url("/assets/images/Fifth Ave Hover.jpg")`,
        backgroundSize: "cover",
      },
      "& .placeHolder3": {
        backgroundImage: `url("/assets/images/Haus Hover.jpg")`,
        backgroundSize: "cover",
      },
      "& .placeHolder4": {
        backgroundImage: `url("/assets/images/Jackson Over.jpg")`,
        backgroundSize: "cover",
      },
      "& .placeHolder5": {
        backgroundImage: `url("/assets/images/Soho-Hover.jpg")`,
        backgroundSize: "cover",
      },
      "& .placeHolder6": {
        backgroundImage: `url("/assets/images/Tropical-House-Hover.jpg")`,
        backgroundSize: "cover",
      },
    },
  },
  addTxtAlign: {
    marginLeft: pxToRem(36),
  },
});

export default styles;
