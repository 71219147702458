const types = {
  COMPANY_DATA_BY_USER: "COMPANY_DATA_BY_USER",
  GET_COMPANY_BASICS_DATA: "GET_COMPANY_BASICS_DATA",
  GET_COMPANY_PORTFOLIO_DATA: "GET_COMPANY_PORTFOLIO_DATA",
  GET_COMPANY_LOCATIONS_DATA: "GET_COMPANY_LOCATIONS_DATA",
  DELETE_COMPANY_ADMIN: "DELETE_COMPANY_ADMIN",
  SAVED_PORTFOLIO_OBJECT: "SAVED_PORTFOLIO_OBJECT",
  SAVED_SELECTED_PORTFOLIO: "SAVED_SELECTED_PORTFOLIO",
  PORTFOLIO_SAVE: "PORTFOLIO_SAVE",
  PORTFOLIOS_BY_LOCATION: "PORTFOLIOS_BY_LOCATION",
  MARKET_MODAL_OPEN: "MARKET_MODAL_OPEN",
  GET_EMAIL_GENERATORS: "GET_EMAIL_GENERATORS",
};
export default types;
