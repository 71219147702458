import { withStyles } from "@material-ui/core/styles";
import tradework_theme, { pxToRem, color } from "../../../utilities/themes";

const styles = withStyles({
  product_width: {
    width: pxToRem(503),
    border: `solid ${pxToRem(2)} ${color.primary_palette.free_purple}`,
    backgroundColor: color.secondary_palette.fafa_gray,
  },
  btnRight: {
    textAlign: "right",
  },
  crossBtn: {
    minWidth: pxToRem(30),
    padding: pxToRem(16),
    "& .MuiSvgIcon-root": {
      fontSize: pxToRem(15),
    },
  },
  textCenter: {
    textAlign: "center",
  },
  product_input: {
    width: pxToRem(715),
    border: `solid ${pxToRem(1)} ${color.primary_palette.franklin_purple}`,
    backgroundColor: color.secondary_palette.blues.tile_blue,
    margin: "0 auto",
    padding: `${pxToRem(30)} ${pxToRem(50)}`,
    marginTop: pxToRem(50),
  },
  required_icon: {
    fontSize: pxToRem(15),
    paddingRight: pxToRem(4),
  },
  product_radio: {
    paddingLeft: pxToRem(20),
    "& .MuiFormControlLabel-label": {
      ...tradework_theme.typography.styles.avenir_sb,
      fontSize: pxToRem(16),
      color: color.primary_palette.franklin_purple,
    },
    "& .MuiSvgIcon-root": {
      fontSize: pxToRem(20),
    },
  },
  justify_left: {
    flexGrow: 1,
  },
  justify_right: {
    flexGrow: 1,
    justifyContent: "flex-end",
  },
  product_inputfield: {
    width: "100%",
    fontSize: pxToRem(16),
    ...tradework_theme.typography.styles.avenir_sb,
    color: color.primary_palette.black,
    "& .MuiInputBase-input::placeholder": {
      // fontStyle: "oblique",
      color: color.secondary_palette.grays.shadow_gray,
      fontSize: pxToRem(16),
      opacity: 1,
    },
  },
  pos_relative: {
    position: "relative",
  },
  padding_top_24: {
    paddingTop: pxToRem(24),
  },
  text_area: {
    backgroundColor: color.primary_palette.white,
    height: `${pxToRem(104)} !important`,
    width: "100%",
    resize: "none",
    marginTop: pxToRem(10),
    ...tradework_theme.typography.styles.avenir_light,
    fontSize: pxToRem(16),
    "&::placeholder": {
      // fontStyle: "oblique !important",
      color: color.secondary_palette.grays.shadow_gray,
      ...tradework_theme.typography.styles.avenir_light,
      opacity: 1,
    },
  },
  image_block: {
    width: "36%",
    margin: "0 auto",
    position: "relative",
  },
  mainTextsize: {
    fontSize: pxToRem(16),
    ...tradework_theme.typography.styles.avenir_sb,
    color: color.primary_palette.free_purple,
    "&:hover": {
      cursor: "pointer",
      textDecoration: "underline",
    },
  },
  absolute_required: {
    position: "absolute",
    right: 0,
    top: "50%",
    fontSize: pxToRem(10),
    fill: color.form_colors.blueberry_purple,
  },

  product_cover: {
    width: pxToRem(503),
    height: pxToRem(348),
    border: `solid ${pxToRem(2)} ${color.primary_palette.free_purple}`,
    paddingBottom: pxToRem(20),
    backgroundColor: color.secondary_palette.blues.modal_blue,
  },
  product_cover_inner: {
    width: pxToRem(360),
    margin: "0 auto",
    textAlign: "center",
    border: `solid ${pxToRem(1)} ${color.primary_palette.franklin_purple}`,
  },
  img_block: {
    width: pxToRem(137),
    height: pxToRem(137),
    backgroundColor: color.secondary_palette.grays.shadow_gray,
    // margin: `${pxToRem(14)} auto`,
    margin: "0 auto",
  },
  deleteBtn: {
    width: pxToRem(225),
    height: pxToRem(35),
    borderRadius: pxToRem(20),
    border: `solid ${pxToRem(2)} ${color.form_colors.blueberry_purple}`,
    backgroundColor: color.primary_palette.white,
    marginTop: pxToRem(22),
    "& .MuiButton-label": {
      ...tradework_theme.typography.styles.avenir_sb,
      fontSize: pxToRem(16),
      color: color.form_colors.royal_purple_1,
    },
  },
  save_item: {
    marginBottom: pxToRem(30),
    width: pxToRem(209),
    height: pxToRem(40),
    borderRadius: pxToRem(27),
    border: `solid ${pxToRem(2)} ${color.primary_palette.franklin_purple}`,
    backgroundColor: color.primary_palette.white,
    marginTop: pxToRem(22),
    "& .MuiButton-label": {
      ...tradework_theme.typography.styles.avenir_sb,
      fontSize: pxToRem(20),
      color: color.primary_palette.franklin_purple,
    },
  },
  padding_20: {
    padding: `${pxToRem(0)} ${pxToRem(20)}`,
  },
  padding_top_17: {
    paddingTop: pxToRem(17),
  },
});

export default styles;
