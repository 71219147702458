import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { map, get, filter, find, every, size } from "lodash";
import { MenuItem } from "@material-ui/core";
import uuidv4 from "uuid/v4";

import companyBasicStyles from "./styles/companyBasicStyles";
import Row from "../common/ui_kit/row";
import Text from "../common/ui_kit/text";
import CustomScrollbars from "../data_display/custom_scroll";
import strings from "../../utilities/strings";
import { color } from "../../utilities/themes";
import { InputAdornment } from "@material-ui/core";
import CustomCheckbox from "../../components/inputs/custom_checkbox";
import CustomInputCount from "../inputs/custom_input_count";
import Trash_can from "../data_display/icons/Trash_can";
import CustomSelect from "../common/ui_kit/custom_select";
import Chevron_Down_Icon from "../data_display/icons/Arrow_Chevron_Down";
import CustomRadioButton from "../inputs/custom_radio_button";
import Lil_Plus_filled from "../data_display/icons/Lil_Plus_filled";
import PlusIcon from "../data_display/icons/add_default_hover";
import CustomButton from "../navigations/custom_buttons";
import AddImage from "../add_images/add_image";
import ChoosePicture from "../add_images/choose_picture";
import CustomModal from "../inputs/custom_modal";
import CustomTextField from "../../components/inputs/custom_textfields";
import RemovePerson from "../modals/removePerson";
import RemoveAdmin from "../modals/removeAdmin";
import DeleteCompany from "../modals/DeleteCompany";
import CustomSelectProj from "../common/ui_kit/custom_select_proj";

import {
  ProfileActions,
  SettingsActions,
  LookUpActions,
} from "../../redux/actions";
import {
  REGEX_EMAIL,
  PHONE_NUMBER_FORMAT,
} from "../../validations/validations";

function CompanyBasics(props) {
  const { classes, companyInfo } = props;
  const [values, setValues] = useState({
    removeAdminData: {},
    OpenRemovePersonModal: false,
    DeleteCompanyModal: false,
    deleteCompanyError: "",
    errors: {},
    initialValues: {},
    year: "",
    employee: "",
    projectCost: "",
    venue: [],
    clientele: [],
    license: [],
    licensed: true,
    ownerName: "",
    contactName: "",
    imageData: {},
    openImageExp: {},
    croppingModalType: null,
    openCroppingModal: false,
    adminInfo: [
      {
        _id: uuidv4(),
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber: "",
        toBill: false,
        countryCode: "USA",
        active: false,
        isUserCreated: true,
      },
    ],
    FrAdminInfo: [],
  });
  const dispatch = useDispatch();
  const history = useHistory();

  const CompanyBasicData = useSelector(
    (resData) => resData.Settings.CompanyBasicData
  );
  //Will get the data from look up table
  const lookUpData = useSelector((resData) => resData.LookUp.LookUpData);

  //API
  useEffect(() => {
    fetchData();
  }, [companyInfo]);

  const fetchData = () => {
    dispatch(LookUpActions.getLookUpData());
    dispatch(
      SettingsActions.getCompanyBasicsData(localStorage.getItem("companyId"))
    );
    dispatch(SettingsActions.getCompanyDataByUser());
    dispatch(
      SettingsActions.getCompanyPortfolioData(localStorage.getItem("companyId"))
    );
  };

  // handle Dropdown data on change
  const handleDropdownSelectValue = (name, value) => (e) => {
    e.preventDefault();
    e.stopPropagation();
    setValues({ ...values, [name]: value });
  };

  const { initialValues } = values;

  useEffect(() => {
    if (CompanyBasicData && CompanyBasicData.companyBasics) {
      setInitialData();
      const venue = map(CompanyBasicData.companyBasics.venueInfo, (venue) => {
        return { name: venue };
      });
      const clientele = map(
        CompanyBasicData.companyBasics.clientele,
        (clientel) => {
          return { name: clientel };
        }
      );
      const license = map(
        CompanyBasicData.companyBasics.licensedByWhom,
        (license) => {
          return { name: license };
        }
      );
      const licensed = get(CompanyBasicData, "companyBasics.licensed", false);
      const year = get(CompanyBasicData, "companyBasics.yearEstablished", "");
      const employee = get(CompanyBasicData, "companyBasics.employees", "");
      const projectCost = get(
        CompanyBasicData,
        "companyBasics.costOfProject",
        ""
      );
      const ownerName = get(CompanyBasicData, "companyBasics.ownerName", "");
      const contactName = get(
        CompanyBasicData,
        "companyBasics.contactName",
        ""
      );
      let userData = localStorage.getItem("profile");
      if (userData !== "undefined") {
        userData = JSON.parse(userData);
      } else {
        userData = {};
      }
      const adminInfo = get(
        CompanyBasicData,
        "companyBasics.companyAdmins",
        []
      );
      const FrAdminInfo = get(
        CompanyBasicData,
        "companyBasics.companyAdmins",
        []
      ).filter((each) => each.frAdmin === true);
      adminInfo.map((each) => {
        if (each.email === userData?.email) {
          each.isAdminPerson = true;
        } else {
          each.isAdminPerson = false;
        }
      });
      adminInfo.map((each) => {
        if (!each.countryCode) {
          each.countryCode = "USA";
        }
      });

      setValues({
        ...values,
        venue: [...venue],
        clientele: [...clientele],
        license: [...license],
        licensed,
        year,
        projectCost,
        employee: { name: employee },
        ownerName,
        contactName,
        adminInfo,
        FrAdminInfo,
        imageData: {},
        companyBio: get(CompanyBasicData, "companyBasics.companyBio", ""),
      });
    }
  }, [CompanyBasicData]);

  const triggerCountryDropdown = (adminInfo) => () => {
    adminInfo.showCountryDropdown = !adminInfo.showCountryDropdown;
    setValues({
      ...values,
    });
  };

  const handleTradeSelect = (sectionId, value, idx) => (e) => {
    /**
     * UPDATING SELECTED TRADE ID IN THE TRADES ARRAY WITH INDEX AS REF
     */
    e.preventDefault();
    const { trades } = values.initialValues;
    const selectedTrades = map(trades, (eachTrade) => {
      return eachTrade.tradeId;
    });
    if (selectedTrades.includes(value.tradeId)) {
      // IF EXISTING TRADE SELECTED
      return;
    } else {
      const updatedTrades = map(trades, (trade) => {
        if (trade._id === sectionId) {
          return value;
        }
        idx === 0 && checkValidation("trades", value);
        return trade;
      });
      setValues({
        ...values,
        initialValues: { ...values.initialValues, trades: [...updatedTrades] },
      });
    }
  };

  const setInitialData = () => {
    const companyTrades = CompanyBasicData?.companyBasics?.trades || [];
    if (get(companyTrades, "length", 0) === 0) {
      /**
       * TO MATCH UI DESIGN
       * IF NO TRADES
       * ADDING TWO EMPTY TRADE SECTIONS
       */
      initialValues.trades = [{ _id: uuidv4() }, { _id: uuidv4() }];
    } else if (get(companyTrades, "length", 0) % 2 !== 0) {
      /**
       * TO MATCH UI DESIGN
       * IF LENGTH OF TRADES IS ODD
       * ADDING ONE EMPTY TRADE SECTION
       */
      initialValues.trades = [...companyTrades, { _id: uuidv4() }];
    } else {
      initialValues.trades = [...companyTrades];
    }
    if (!companyTrades) {
      initialValues.trades = [{ _id: uuidv4() }, { _id: uuidv4() }];
    }
    setValues({
      ...values,
    });
  };

  const handleClose = () => {
    setValues({
      ...values,
      openCroppingModal: false,
    });
  };

  const handleRemovePerson = () => {
    setValues({
      ...values,
      OpenRemovePersonModal: !OpenRemovePersonModal,
    });
  };

  const openAdminDelete = () => {
    setValues({
      ...values,
      openAdminDeleteModal: !openAdminDeleteModal,
    });
  };

  const handleDeleteCompanyModal = () => {
    setValues({
      ...values,
      DeleteCompanyModal: !DeleteCompanyModal,
    });
  };
  const deleteCompany = () => {
    dispatch(
      SettingsActions.deleteCompanyAdmin(
        localStorage.getItem("companyId"),
        (data) => {
          if (data.error.error) {
            values.deleteCompanyError = data.error.message;
          } else {
            localStorage.setItem("companyName", "");
            localStorage.setItem("companyId", "");
            localStorage.setItem("active", "personal");
            history.push("/settings");
            dispatch(SettingsActions.getCompanyDataByUser());
          }
          setValues({ ...values });
          handleDeleteCompanyModal();
        }
      )
    );
  };

  const openImageExp = (type) => () => {
    setValues({
      ...values,
      openImageExp: { ...openImageExp, [type]: Date.now() },
      openCroppingModal: false,
    });
  };

  const updateCroppedImage = (imageObject, type) => {
    const { croppedImage, blobUrl } = imageObject;
    const { imageData } = values;

    imageData[type] = imageData[type] || {};
    imageData[type].imageUrl = croppedImage;
    imageData[type].blobFile = blobUrl;
    setValues({ ...values, imageData });
  };

  const toggleCroppingModal = (obj) => {
    const { imageData, type } = obj;
    setValues({
      ...values,
      openCroppingModal: true,
      croppingModalType: type,
      imageData: { ...values.imageData, [type]: imageData },
    });
  };

  const handleCountryChange = (value, adminInfo) => {
    adminInfo.countryCode = value;
    adminInfo.phoneNumber = "";
    adminInfo.showCountryDropdown = !adminInfo.showCountryDropdown;
    setValues({
      ...values,
    });
  };

  const handleDisableMoreTrades = (trades) => {
    // IF A ROW IS NOT SELECTED DISABLE ADD TRADES FUNCTIONALITY
    if (trades) {
      for (let i = 0; i < trades.length; i++) {
        if (
          !trades[i].tradeId ||
          (trades[i].tradeId && trades[i].name === "Select")
        ) {
          return true;
        }
      }
    }
    return false;
  };

  const isPrimaryTradeSelected = (trades) => {
    // DISABLE OTHER TRADES IF PRIMARY TRADE IS EMPTY/N-A
    if (trades) {
      if (trades[0].tradeId && trades[0].name !== "Select") {
        return true;
      }
      return false;
    }
    return false;
  };

  const addNewAdminBlock = () => {
    if (!formHasErrors) {
      setValues({
        ...values,
        adminInfo: [
          ...values.adminInfo,
          {
            _id: uuidv4(),
            isUserCreated: true,
            firstName: "",
            lastName: "",
            email: "",
            active: true,
            countryCode: "USA",
          },
        ],
      });
    }
  };

  const removeAdmin = () => {
    const { removeAdminData, adminInfo } = values;
    if (size(removeAdminData)) {
      const updatedData = filter(adminInfo, (each) => {
        return (
          removeAdminData._id != each._id || removeAdminData.email != each.email
        );
      });
      values.adminInfo = [...updatedData];
      setValues({ ...values });
    }
    saveCompanyData();
    handleRemovePerson();
  };

  const removeAdminDataStore = (data) => () => {
    values.removeAdminData = data;
    setValues({ ...values });
    handleRemovePerson();
  };

  const addTradeBtn = () => {
    // ADD MORE TRADES
    const { trades } = values.initialValues;
    if (!trades) {
      values.initialValues.trades = [];
    }
    setValues({
      ...values,
      initialValues: {
        ...values.initialValues,
        trades: [
          ...values.initialValues.trades,
          { _id: uuidv4() },
          { _id: uuidv4() },
        ],
      },
    });
  };

  const returnTradeLabel = (index) => {
    // RETURNING DYNAMIC TRADE LABELS
    switch (index) {
      case 0:
        return "Primary Trade";
      case 1:
        return "Secondary Trade";
      case 2:
        return "3rd Trade";
      default:
        return `${index + 1}th Trade`;
    }
  };

  // Method responsible to return categorized menu items
  const renderCategorizedMenu = (menuData, sectionId, value, idx) => {
    // list of color codes to be appied for category tiles based on index
    const colorCodes = get(lookUpData, "configData.dropdownColorCodes", []);
    // fetching category names
    const groups = Object.keys(menuData)
      .filter((each) => each !== "noParent")
      .sort();
    // pushing nonParent items to array
    let menuItems = menuData.noParent
      ? menuData.noParent.map((each) => each)
      : [];

    groups.map((eachGroup, index) => {
      // interating group titles and pushint to menu item array
      let subGroupItems = [{ name: eachGroup, color: colorCodes[index] }];
      menuData[eachGroup].map((eachGroupItem) => {
        // iterating subgroup items
        subGroupItems.push(eachGroupItem);
      });
      // concatinating menuItems which are returned and categorized items
      menuItems = [...menuItems, ...subGroupItems];
      return null;
    });

    // mapping menuItems (objects) and returning JSX
    return menuItems.map((eachItem) => {
      return (
        <MenuItem
          style={{ background: eachItem.color }}
          onClick={
            !eachItem.color &&
            handleTradeSelect(
              sectionId,
              {
                ...eachItem,
                tradeId: eachItem._id,
              },
              idx
            )
          }
          className={eachItem.color && classes.textCapital}
        >
          {eachItem.name}
        </MenuItem>
      );
    });
  };

  const checkValidation = (name, value) => {
    // VALIDATION CHECK FOR REQUIRED FIELDS
    if (name === "trades") {
      if (!value || value.name === "Select") {
        errors.trades = { error: true, message: "Required Field" };
        setValues({ values: { ...values, errors } });
        return;
      }
      errors.trades = { error: false, message: "" };
      setValues({ values: { ...values, errors } });
    }
  };

  const handleDropdownSelect = (name, value, del) => (e) => {
    e.preventDefault();
    e.stopPropagation();
    const checkForDuplicate = find(values[name], {
      name: value.name,
    });
    if (!checkForDuplicate) {
      setValues({ ...values, [name]: [...values[name], value] });
    } else {
      if (!del) {
        return;
      }
      const updatedData = filter(values[name], (each) => {
        return each.name != value.name;
      });
      setValues({ ...values, [name]: [...updatedData] });
    }
  };

  const handleInputChange = (e) => {
    if (e.target.name === "licensed") {
      setValues({ ...values, [e.target.name]: e.target.value === "true" });
      return;
    }
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const handleValidation = (name, value, admin) => {
    const adminId = get(admin, "id", null)
      ? get(admin, "id", null)
      : get(admin, "_id", null);
    if ((name === "firstName" && !value) || (name === "lastName" && !value)) {
      const { errors } = values;
      errors[adminId] = errors[adminId] || {};
      errors[adminId] = { ...errors[adminId], [name]: true };
      setValues({ ...values });
      return;
    }

    const regexTest = name === "email" ? REGEX_EMAIL.test(value) : true;
    if (!regexTest) {
      const { errors } = values;
      errors[adminId] = errors[adminId] || {};
      errors[adminId] = { ...errors[adminId], [name]: true };
      setValues({ ...values });
      return;
    }
    errors[adminId] = errors[adminId] || {};
    errors[adminId][name] = false;

    setValues({ ...values, errors: {} });
  };

  const handleAdminInputChange = (adminInfo) => (e) => {
    const { name, value, checked } = e.target;
    if (name === "email" || name === "firstName" || name === "lastName") {
      // VALIDATIONG EMAIL AND WEBSITE FIELDS
      handleValidation(name, value, adminInfo);
    }
    if (name === "active" || name === "toBill") {
      if (name === "toBill") {
        values.adminInfo.map((adminDataInfo) => {
          adminDataInfo[name] = false;
        });
      }
      adminInfo[name] = checked;
    } else if (name === "phoneNumber") {
      const val = deformatPhoneNumber(value);
      // DEFORMATTING PHONE NUMBER AND SENDING TO VALIDATION
      if (isNaN(val)) {
        /**returning if input is NaN */
        if (!val) {
          adminInfo[name] = "";
          setValues({ ...values });
        }
        return;
      }
      /**saving deformatted value to phone number in businessadminInfo (formatted) */
      adminInfo[name] = val;
    } else {
      adminInfo[name] = value;
    }
    setValues({ ...values });
  };

  const formatPhoneNumber = (value, adminInfo) => {
    return value.replace(PHONE_NUMBER_FORMAT, "$1.$2.$3");
  };

  const onPhoneNumberBlur = (adminInfo) => () => {
    if (adminInfo.phoneNumber) {
      adminInfo.phoneNumber = adminInfo.phoneNumber
        .toString()
        .replace(PHONE_NUMBER_FORMAT, "$1.$2.$3");
    }
    setValues({ ...values, activeField: {} });
  };

  const deformatPhoneNumber = (number) => {
    // DEFORMATTING
    if (!number) {
      return;
    }
    return number.replace(/[().\s/]/g, "");
  };
  const onPhoneNumberFocus = (adminInfo) => (event) => {
    // ON FOCUS REMOVING FORMAT
    const { name, value } = event.target;
    if (adminInfo && adminInfo.phoneNumber) {
      adminInfo.phoneNumber = adminInfo.phoneNumber.replace(/[().\s/]/g, "");
      setValues({
        ...values,
        activeField: { name: "phone", id: adminInfo.id },
      });
      return;
    }
    if (!adminInfo && value) {
      setValues({
        ...values,
        aboutMe: { ...values.aboutMe, [name]: deformatPhoneNumber(value) },
      });
      return;
    }
  };

  const addTitle = (name) => () => {
    const { newOptionValue } = values;
    if (!newOptionValue) {
      return;
    }
    const option = { name: newOptionValue, _id: uuidv4() };
    setValues({
      ...values,
      [name]: [...values[name], { ...option }],
      newOptionValue: "",
    });
  };

  const yearsEstablished = (startYear) => {
    var currentYear = new Date().getFullYear(),
      years = [];
    startYear = startYear || 1920;
    while (startYear <= currentYear) {
      years.push(startYear++);
    }
    return years;
  };

  const saveCompanyData = () => {
    const dataToSubmit = {
      licensed: values.licensed,
      licensedByWhom: "",
      ownerName: values.ownerName,
      contactName: values.contactName,
      venues: map(values.venue, "name"),
      licensedByWhom: map(values.license, "name"),
      clientel: map(values.clientele, "name"),
      costOfProject: values.projectCost,
      employees: values.employee.name,
      yearEstablished: values.year,
      trades: values.initialValues.trades,
      admins: values.adminInfo,
      imageData: values.imageData,
      companyLogo: CompanyBasicData.companyBasics.companyLogo,
      companyBio: values.companyBio,
      deletedAdmins: [values.removeAdminData],
    };
    dispatch(
      ProfileActions.saveCompanySettingData(dataToSubmit, () => {
        fetchData();
      })
    );
  };

  const hasFormErrors = () => {
    const { errors, adminInfo } = values;
    const errValues = Object.values(errors).map((each) => {
      if (each.email || each.firstName || each.lastName) {
        return true;
      }
      return false;
    });
    const hasFirstName = every(adminInfo, "firstName");
    const hasLastName = every(adminInfo, "lastName");
    const hasEmail = every(adminInfo, "email");
    if (
      errValues.includes(true) ||
      !hasFirstName ||
      !hasLastName ||
      !hasEmail
    ) {
      return true;
    }
    return false;
  };

  const formHasErrors = hasFormErrors();

  const { trades } = values.initialValues;
  const {
    errors,
    year,
    employee,
    projectCost,
    venue,
    clientele,
    license,
    licensed,
    ownerName,
    contactName,
    adminInfo,
    FrAdminInfo,
    showCountryDropdown,
    isBill,
    openCroppingModal,
    imageData,
    croppingModalType,
    OpenRemovePersonModal,
    openAdminDeleteModal,
    DeleteCompanyModal,
    deleteCompanyError,
  } = values;
  const disableMoreTrades = handleDisableMoreTrades(trades);
  const primaryTradeSelected = isPrimaryTradeSelected(trades);

  return (
    <>
      <Row>
        <Row className={classes.mainBlock}>
          <div>
            <Row className={classes.mainSettingsBlock}>
              <Text
                size={25}
                color={color.primary_palette.franklin_purple}
                family="gillsans_sb"
                transform="uppercase"
                className={classes.settingsTxtAlign}
              >
                {strings.settings.titles.company_basics}
              </Text>
            </Row>
            <div className={classes.profileBlockAlign}>
              <Row className={classes.photos_wrapper}>
                <div>
                  <AddImage
                    defaultImage={get(
                      CompanyBasicData,
                      "companyBasics.companyLogo",
                      `${get(lookUpData, "configData.eaglesPath")}/${get(
                        CompanyBasicData,
                        "companyBasics.FRCompanyId"
                      )}.jpg`
                    )}
                    MainText="Company Logo"
                    MainTextStyle={classes.mainTextUnderline}
                    className={classes.profileImgStyleAlign}
                    type="companyBasicsLogo"
                    key={CompanyBasicData}
                    croppedImage={get(imageData, "logo.imageUrl", "")}
                    imageUpload={toggleCroppingModal}
                    originalImg={get(
                      CompanyBasicData,
                      "companyBasics.originalCompanyLogo",
                      ""
                    )}
                    isProductCropper={true}
                    openImageExp={get(
                      values.openImageExp,
                      "companyBasicsLogo",
                      false
                    )}
                  />
                </div>
                <div>
                  <AddImage
                    defaultImage={get(
                      CompanyBasicData,
                      "companyBasics.headShotOrTeamPhoto",
                      "/assets/images/default_companyheadshot.png"
                    )}
                    MainText="Company Headshot or Team Photo"
                    MainTextStyle={`${classes.mainTextUnderline} ${classes.width_144}`}
                    className={classes.profileImgStyleAlign}
                    type="headShot"
                    key={CompanyBasicData}
                    croppedImage={get(imageData, "headShot.imageUrl", "")}
                    imageUpload={toggleCroppingModal}
                    isProductCropper={true}
                    openImageExp={get(values.openImageExp, "headShot", false)}
                    originalImg={get(
                      CompanyBasicData,
                      "companyBasics.originalHeadShotOrTeamPhotoImgUri",
                      ""
                    )}
                  />
                </div>
                <div>
                  <CustomButton
                    className={classes.edit_save_btn}
                    onClick={saveCompanyData}
                    disabled={formHasErrors}
                  >
                    {" "}
                    Save{" "}
                  </CustomButton>
                </div>
              </Row>

              {/* to be used when facebook profile is used */}
              {/* <div className={classes.btnBlockAlign}>
                <div className={classes.blueButtonStyle}>
                  {strings.settings.titles.fb_login_txt}
                </div>
                <div className={classes.orTxtColor}>
                  <div className={classes.leftLine}></div>
                  <span>OR</span>
                  <div className={classes.rightLine}></div>
                </div>
                <div className={classes.grayButtonStyle}>
                  {strings.settings.titles.profile_pic_normal}
                </div>
              </div> */}
            </div>
            <Row className={classes.mainAdminSettingsBlock}>
              <Text
                size={20}
                color={color.primary_palette.franklin_purple}
                family="gillsans_sb"
                transform="uppercase"
              >
                {strings.settings.titles.company_admin}
              </Text>
            </Row>
            <div className={classes.adminDisplayBlock}>
              <Text
                size={15}
                color={color.primary_palette.black}
                family="Helvetica"
                className={classes.adminTextStyle}
              >
                {strings.settings.titles.new_admin_contact}
              </Text>

              {map(adminInfo, (section) => {
                return (
                  <div key={section._id} className={classes.addAdminBlock}>
                    <Row className={classes.rowAlign}>
                      <Row>
                        <CustomCheckbox
                          className={classes.chkBoxAlign}
                          onChange={handleAdminInputChange(section)}
                          checked={section?.active}
                          name="active"
                        />
                        <Text
                          size={14}
                          color={color.primary_palette.black}
                          family="Helvetica"
                          className={classes.checkBoxHeading}
                        >
                          {strings.settings.titles.active}
                        </Text>
                      </Row>
                      <Row>
                        <Text
                          size={15}
                          color={color.primary_palette.black}
                          family="avenir_sb"
                          className={classes.labelText}
                        >
                          <span className={classes.element_required}>*</span>
                          {strings.settings.titles.first_name}
                        </Text>
                        <CustomInputCount
                          className={`${classes.input_style} ${classes.input1}`}
                          name="firstName"
                          defaultValue={section?.firstName}
                          onBlur={handleAdminInputChange(section)}
                        />
                      </Row>
                      <Row>
                        <Text
                          size={15}
                          color={color.primary_palette.black}
                          family="avenir_sb"
                          className={classes.labelText}
                        >
                          <span className={classes.element_required}>*</span>
                          {strings.settings.titles.last_name}
                        </Text>
                        <CustomInputCount
                          className={`${classes.input_style} ${classes.input1}`}
                          name="lastName"
                          defaultValue={section?.lastName}
                          onBlur={handleAdminInputChange(section)}
                        />
                      </Row>
                    </Row>
                    <Row className={classes.rowAlign}>
                      <Row>
                        <CustomCheckbox
                          onChange={handleAdminInputChange(section)}
                          checked={section?.toBill}
                          name="toBill"
                        />
                        <Text
                          size={14}
                          color={color.primary_palette.black}
                          family="Helvetica"
                          className={`${classes.checkBoxHeading} ${classes.billingAlign}`}
                        >
                          {strings.settings.titles.to_bill}
                        </Text>
                      </Row>
                      <Row>
                        <Text
                          size={15}
                          color={color.primary_palette.black}
                          family="avenir_sb"
                          className={classes.labelText}
                        >
                          <span className={classes.element_required}>*</span>
                          {strings.settings.titles.company_email}
                        </Text>
                        <CustomInputCount
                          className={`${classes.input_style} ${classes.input1}`}
                          name="email"
                          defaultValue={section?.email}
                          onBlur={handleAdminInputChange(section)}
                        />
                      </Row>
                      <Row className={classes.phoneBlock}>
                        <Text
                          size={15}
                          color={color.primary_palette.black}
                          family="avenir_sb"
                          className={classes.labelText}
                        >
                          <span className={classes.element_required}>*</span>
                          {strings.settings.titles.company_phone}
                        </Text>
                        <CustomTextField
                          className={`${classes.input_style} ${classes.input_field} ${classes.input1}`}
                          name="phoneNumber"
                          onChange={handleAdminInputChange(section)}
                          value={
                            section.countryCode === "USA" &&
                            get(section.phoneNumber, "length", 0) >= 10
                              ? formatPhoneNumber(
                                  get(section, "phoneNumber", ""),
                                  section
                                )
                              : get(section, "phoneNumber", "")
                          }
                          onBlur={onPhoneNumberBlur(section)}
                          inputProps={{
                            maxLength:
                              section.countryCode === "nonUSA" ? 20 : 10,
                            onFocus: onPhoneNumberFocus(section),
                          }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment
                                position="end"
                                tabIndex="-1"
                                aria-label="toggle password visibility"
                                className={classes.password_icon}
                              >
                                <div className={classes.phoneNumberActions}>
                                  <img
                                    src={
                                      section.countryCode === "nonUSA"
                                        ? "/assets/images/nonUSA.PNG"
                                        : "/assets/images/usa-flag-icon.png"
                                    }
                                    className={classes.countryImage}
                                    alt="USA"
                                  />
                                  <Chevron_Down_Icon
                                    className={classes.dropDownIcon}
                                    onClick={triggerCountryDropdown(section)}
                                  />
                                  {section.showCountryDropdown && (
                                    <div className={classes.dropdownOptions}>
                                      <div
                                        onClick={() => {
                                          handleCountryChange("USA", section);
                                        }}
                                      >
                                        USA
                                      </div>
                                      <div
                                        onClick={() => {
                                          handleCountryChange(
                                            "nonUSA",
                                            section
                                          );
                                        }}
                                      >
                                        Non-USA
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Row>
                      <Row className={classes.iconsAlign}>
                        {!section.isAdminPerson && (
                          <Row
                            onClick={removeAdminDataStore(section)}
                            className={classes.pointerNone}
                          >
                            <Trash_can className={classes.trash_icon} />
                            <Text
                              size={14}
                              color={color.primary_palette.franklin_purple}
                              family="Helvetica_bold"
                              className={classes.eraseTxt}
                            >
                              {strings.settings.titles.erase}
                            </Text>
                          </Row>
                        )}
                        <Row
                          onClick={!formHasErrors ? saveCompanyData : () => {}}
                          className={
                            formHasErrors
                              ? classes.disabled_opacity
                              : classes.no_opacity
                          }
                        >
                          <img
                            src="/assets/images/Save.png"
                            className={classes.save_icon}
                            alt=""
                          />
                          <Text
                            size={14}
                            color={color.primary_palette.franklin_purple}
                            family="Helvetica_bold"
                            className={classes.eraseTxt}
                          >
                            {strings.settings.titles.save}
                          </Text>
                        </Row>
                      </Row>
                    </Row>
                    {section.isAdminPerson && (
                      <Text
                        size={12}
                        color={color.secondary_palette.grays.medium_gray}
                        family="avenir_sb"
                      >
                        {strings.settings.titles.contact_admin}
                      </Text>
                    )}
                  </div>
                );
              })}

              <div
                className={
                  !formHasErrors
                    ? classes.addAdminBlockAlign
                    : classes.disabledAdminBlockAlign
                }
              >
                {" "}
                <PlusIcon className={classes.plusAlign} />
                {/* <Lil_Plus_filled className={classes.plusAlign} /> */}
                <Text
                  size={14}
                  color={color.primary_palette.franklin_purple}
                  family="Helvetica_bold"
                  className={classes.newAdminAlign}
                  onClick={addNewAdminBlock}
                >
                  {strings.settings.titles.add_admin}
                </Text>
              </div>
            </div>

            {get(FrAdminInfo, "length") > 0 && (
              <div className={classes.adminDisplayBlock}>
                <Text
                  size={15}
                  color={color.primary_palette.black}
                  family="Helvetica"
                  className={classes.adminTextStyle}
                >
                  {strings.settings.titles.new_fr_contact}
                </Text>

                {map(FrAdminInfo, (section) => {
                  return (
                    <div
                      key={section._id}
                      className={classes.addFrAdminOpacity}
                    >
                      <Row className={classes.rowAlign}>
                        <Row>
                          <CustomCheckbox
                            className={classes.chkBoxAlign}
                            onChange={handleAdminInputChange(section)}
                            checked={section?.active}
                            name="active"
                          />
                          <Text
                            size={14}
                            color={color.primary_palette.black}
                            family="Helvetica"
                            className={classes.checkBoxHeading}
                          >
                            {strings.settings.titles.active}
                          </Text>
                        </Row>
                        <Row>
                          <Text
                            size={15}
                            color={color.primary_palette.black}
                            family="avenir_sb"
                            className={classes.labelText}
                          >
                            {strings.settings.titles.first_name}
                          </Text>
                          <CustomInputCount
                            className={`${classes.input_style} ${classes.input1}`}
                            name="firstName"
                            defaultValue={section?.firstName}
                            onBlur={handleAdminInputChange(section)}
                          />
                        </Row>
                        <Row>
                          <Text
                            size={15}
                            color={color.primary_palette.black}
                            family="avenir_sb"
                            className={classes.labelText}
                          >
                            {strings.settings.titles.last_name}
                          </Text>
                          <CustomInputCount
                            className={`${classes.input_style} ${classes.input1}`}
                            name="lastName"
                            defaultValue={section?.lastName}
                            onBlur={handleAdminInputChange(section)}
                          />
                        </Row>
                      </Row>
                      <Row className={classes.rowAlign}>
                        <Row>
                          <CustomCheckbox
                            onChange={handleAdminInputChange(section)}
                            checked={section?.toBill}
                            name="toBill"
                          />
                          <Text
                            size={14}
                            color={color.primary_palette.black}
                            family="Helvetica"
                            className={`${classes.checkBoxHeading} ${classes.billingAlign}`}
                          >
                            {strings.settings.titles.to_bill}
                          </Text>
                        </Row>
                        <Row>
                          <Text
                            size={15}
                            color={color.primary_palette.black}
                            family="avenir_sb"
                            className={classes.labelText}
                          >
                            {strings.settings.titles.company_email}
                          </Text>
                          <CustomInputCount
                            className={`${classes.input_style} ${classes.input1}`}
                            name="email"
                            defaultValue={section?.email}
                            onBlur={handleAdminInputChange(section)}
                          />
                        </Row>
                        <Row className={classes.phoneBlock}>
                          <Text
                            size={15}
                            color={color.primary_palette.black}
                            family="avenir_sb"
                            className={classes.labelText}
                          >
                            {strings.settings.titles.company_phone}
                          </Text>
                          <CustomTextField
                            className={`${classes.input_style} ${classes.input_field} ${classes.input1}`}
                            name="phoneNumber"
                            onChange={handleAdminInputChange(section)}
                            value={
                              section.countryCode === "USA" &&
                              get(section.phoneNumber, "length", 0) >= 10
                                ? formatPhoneNumber(
                                    get(section, "phoneNumber", ""),
                                    section
                                  )
                                : get(section, "phoneNumber", "")
                            }
                            onBlur={onPhoneNumberBlur(section)}
                            inputProps={{
                              maxLength:
                                section.countryCode === "nonUSA" ? 20 : 10,
                              onFocus: onPhoneNumberFocus(section),
                            }}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment
                                  position="end"
                                  tabIndex="-1"
                                  aria-label="toggle password visibility"
                                  className={classes.password_icon}
                                >
                                  <div className={classes.phoneNumberActions}>
                                    <img
                                      src={
                                        section.countryCode === "nonUSA"
                                          ? "/assets/images/nonUSA.PNG"
                                          : "/assets/images/usa-flag-icon.png"
                                      }
                                      className={classes.countryImage}
                                      alt="USA"
                                    />
                                    <Chevron_Down_Icon
                                      className={classes.dropDownIcon}
                                      onClick={triggerCountryDropdown(section)}
                                    />
                                    {showCountryDropdown && (
                                      <div className={classes.dropdownOptions}>
                                        <div
                                          onClick={() => {
                                            handleCountryChange("USA");
                                          }}
                                        >
                                          USA
                                        </div>
                                        <div
                                          onClick={() => {
                                            handleCountryChange("nonUSA");
                                          }}
                                        >
                                          Non-USA
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Row>
                        <Row className={classes.iconsAlign}>
                          <Row
                            onClick={openAdminDelete}
                            className={classes.pointerNone}
                          >
                            <Trash_can className={classes.trash_icon} />
                            <Text
                              size={14}
                              color={color.primary_palette.franklin_purple}
                              family="Helvetica_bold"
                              className={classes.eraseTxt}
                            >
                              {strings.settings.titles.erase}
                            </Text>
                          </Row>
                        </Row>
                      </Row>
                    </div>
                  );
                })}
              </div>
            )}

            <div>
              <Row>
                <Text
                  size={20}
                  color={color.primary_palette.franklin_purple}
                  family="gillsans_sb"
                  transform="uppercase"
                >
                  {strings.settings.titles.distinguishing_characterstics}
                </Text>
              </Row>
              <Row className={classes.companySettBlock}>
                <Text
                  size={15}
                  color={color.primary_palette.black}
                  family="avenir_sb"
                  className={`${classes.settingsTxtAlign} ${classes.marginTop4}`}
                >
                  {strings.settings.titles.principal_owner}{" "}
                </Text>
                <CustomInputCount
                  className={`${classes.input_style} ${classes.principalInput} ${classes.padding_bottom_1}`}
                  name="ownerName"
                  defaultValue={ownerName}
                  onBlur={handleInputChange}
                />
                <Text
                  size={15}
                  color={color.primary_palette.black}
                  family="avenir_sb"
                  className={`${classes.settingsTxtAlign} ${classes.marginTop4}`}
                >
                  {strings.settings.titles.public_contact_name}{" "}
                </Text>
                <CustomInputCount
                  className={`${classes.input_style} ${classes.principalInput} ${classes.padding_bottom_1}`}
                  name="contactName"
                  defaultValue={contactName}
                  onBlur={handleInputChange}
                />
              </Row>
              <div>
                {errors.trades && errors.trades.error && (
                  <Row className={`${classes.trade_error} ${classes.cw_error}`}>
                    <img
                      src="assets/icons/info_1.svg"
                      className={classes.info}
                      alt=""
                    />
                    {errors.trades.message}
                  </Row>
                )}
                {trades &&
                  map(trades, (tradeSection, index) => {
                    return (
                      <>
                        <Row className={`${classes.primary_field}`}>
                          <Text className={`${classes.sub_fields}`}>
                            {returnTradeLabel(index)}
                          </Text>
                          <CustomSelect
                            IconComponent={Chevron_Down_Icon}
                            className={`${classes.dropdownWidth} ${classes.tradeWidth}`}
                            value={tradeSection}
                            disabled={index > 0 ? !primaryTradeSelected : false}
                            renderValue={(value) => value.name || "Select"}
                            MenuProps={{
                              getContentAnchorEl: null,
                              disableScrollLock: true,
                              anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left",
                              },
                            }}
                          >
                            <CustomScrollbars
                              className={classes.employee_scroll}
                            >
                              <MenuItem
                                onMouseUp={handleTradeSelect(
                                  tradeSection._id,
                                  {
                                    name: "",
                                    tradeId: uuidv4(),
                                    _id: uuidv4(),
                                  },
                                  index
                                )}
                              >
                                Select
                              </MenuItem>
                              {/* {lookUpData &&
                                lookUpData.trades &&
                                map(lookUpData.trades, (eachTrade) => {
                                  return (
                                    <MenuItem
                                      key={tradeSection._id}
                                      onMouseUp={handleTradeSelect(
                                        tradeSection._id,
                                        {
                                          ...eachTrade,
                                          tradeId: eachTrade._id,
                                        },
                                        index
                                      )}
                                    >
                                      {eachTrade.name}
                                    </MenuItem>
                                  );
                                })} */}

                              {lookUpData &&
                                renderCategorizedMenu(
                                  lookUpData.trades,
                                  tradeSection._id,
                                  index
                                )}
                            </CustomScrollbars>
                          </CustomSelect>
                        </Row>
                        {index === trades.length - 1 && (
                          <CustomButton
                            className={`${
                              index > 2
                                ? classes.addtrade_btn
                                : classes.addtrade_btn_2
                            } ${classes.margin_top_neg_12}`}
                            disabled={disableMoreTrades}
                            onClick={addTradeBtn}
                            isX
                          >
                            <Lil_Plus_filled
                              className={classes.plusAlignTrade}
                            />
                            <Text
                              size={16}
                              color={color.primary_palette.black}
                              family="avenir_sb"
                              className={`${classes.settingsTxtAlign} ${classes.camelCase} ${classes.alignItemsBottom}`}
                            >
                              {strings.settings.titles.more_trade}{" "}
                            </Text>
                          </CustomButton>
                        )}
                      </>
                    );
                  })}
              </div>

              <Row
                className={`${classes.companySettBlock} ${classes.tradesBottomAlignStyle}`}
              >
                <Row className={classes.fieldsAlign}>
                  {/* <span className={classes.ovalCircle}></span> */}
                  <Text
                    size={16}
                    color={color.primary_palette.black}
                    family="avenir_sb"
                    className={classes.settingsTxtAlign}
                  >
                    {strings.settings.titles.your_establishment}{" "}
                  </Text>
                  <CustomSelect
                    className={`${classes.dropdownWidth} ${classes.yearWidth}`}
                    IconComponent={Chevron_Down_Icon}
                    name="year"
                    value={year}
                    renderValue={(value) => value || "Year"}
                    MenuProps={{
                      getContentAnchorEl: null,
                      disableScrollLock: true,
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                    }}
                  >
                    <CustomScrollbars className={classes.yearDropdownHeight}>
                      {lookUpData &&
                        lookUpData.configData &&
                        map(yearsEstablished().sort().reverse(), (year) => {
                          return (
                            <MenuItem
                              className={classes.menu_items}
                              onClick={handleDropdownSelectValue("year", year)}
                            >
                              {year}
                            </MenuItem>
                          );
                        })}
                    </CustomScrollbars>
                  </CustomSelect>
                </Row>
                <Row className={classes.fieldsAlign}>
                  {/* <span className={classes.ovalCircle}></span> */}
                  <Text
                    size={16}
                    color={color.primary_palette.black}
                    family="avenir_sb"
                    className={classes.settingsTxtAlign}
                  >
                    {strings.settings.titles.employees}{" "}
                  </Text>
                  <CustomSelect
                    className={`${classes.dropdownWidth} ${classes.employeeWidth}`}
                    IconComponent={Chevron_Down_Icon}
                    name="employee"
                    value={employee}
                    renderValue={(value) => value.name || "Select"}
                    MenuProps={{
                      getContentAnchorEl: null,
                      disableScrollLock: true,
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                    }}
                  >
                    <CustomScrollbars>
                      {lookUpData &&
                        lookUpData.configData &&
                        map(lookUpData.configData.employees, (employee) => {
                          return (
                            <MenuItem
                              className={classes.menu_items}
                              onClick={handleDropdownSelectValue(
                                "employee",
                                employee
                              )}
                            >
                              {employee.name}
                            </MenuItem>
                          );
                        })}
                    </CustomScrollbars>
                  </CustomSelect>
                </Row>
                <Row className={classes.fieldsAlign}>
                  {/* <span className={classes.ovalCircle}></span> */}
                  <Text
                    size={16}
                    color={color.primary_palette.black}
                    family="avenir_sb"
                    className={classes.settingsTxtAlign}
                  >
                    {strings.settings.titles.typical_cost}{" "}
                  </Text>
                  <CustomSelect
                    className={`${classes.dropdownWidth} ${classes.costWidth}`}
                    IconComponent={Chevron_Down_Icon}
                    MenuProps={{
                      getContentAnchorEl: null,
                      disableScrollLock: true,
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                    }}
                    name="projectCost"
                    value={projectCost}
                    renderValue={(value) => value || "Select"}
                  >
                    <CustomScrollbars>
                      {lookUpData &&
                        lookUpData.configData &&
                        map(
                          lookUpData.configData.costOfProject,
                          (projectCost) => {
                            return (
                              <MenuItem
                                className={classes.menu_items}
                                onClick={handleDropdownSelectValue(
                                  "projectCost",
                                  projectCost
                                )}
                              >
                                {projectCost}
                              </MenuItem>
                            );
                          }
                        )}
                    </CustomScrollbars>
                  </CustomSelect>
                </Row>
              </Row>
              <Row
                className={`${classes.companySettBlock} ${classes.margin_bottom_0}`}
              >
                <Row className={classes.fieldsAlign}>
                  {/* <span className={classes.ovalCircle}></span> */}
                  <Text
                    size={16}
                    color={color.primary_palette.black}
                    family="avenir_sb"
                    className={classes.settingsTxtAlign}
                  >
                    {strings.settings.titles.clientele}{" "}
                  </Text>
                  <CustomSelectProj
                    className={`${classes.dropdownWidth} ${classes.clienteleProjWidth}`}
                    IconComponent={Chevron_Down_Icon}
                    name="clientele"
                    value={clientele}
                    isProjPage={true}
                    renderValue={(value) =>
                      get(value, "name", "Choose up to 5")
                    }
                    MenuProps={{
                      getContentAnchorEl: null,
                      disableScrollLock: true,
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                    }}
                  >
                    <CustomScrollbars className={classes.customScrollHeight}>
                      {lookUpData &&
                        map(lookUpData.clientels, (clientele) => {
                          return (
                            <MenuItem
                              onClick={handleDropdownSelect(
                                "clientele",
                                clientele
                              )}
                            >
                              {clientele.name}
                            </MenuItem>
                          );
                        })}
                    </CustomScrollbars>
                    <Row className={classes.inputContainer}>
                      <input
                        type="text"
                        placeholder="ADD YOUR OWN"
                        className={classes.inputField}
                        onBlur={(e) => {
                          setValues({
                            ...values,
                            newOptionValue: e.target.value,
                          });
                        }}
                      />
                      <p
                        className={classes.plus}
                        onClick={addTitle("clientele")}
                      >
                        <div className={`${classes.iconPlusStyle}`} />
                      </p>
                    </Row>
                  </CustomSelectProj>
                </Row>

                <Row className={classes.fieldsAlign}>
                  {/* <span className={classes.ovalCircle}></span> */}
                  <Text
                    size={16}
                    color={color.primary_palette.black}
                    family="avenir_sb"
                    className={classes.settingsTxtAlign}
                  >
                    {strings.settings.titles.your_venue}{" "}
                  </Text>
                  <CustomSelectProj
                    className={`${classes.dropdownWidth} ${classes.clienteleProjWidth}`}
                    IconComponent={Chevron_Down_Icon}
                    name="venue"
                    isProjPage={true}
                    value={venue}
                    renderValue={(value) =>
                      get(value, "name", "What best describes you")
                    }
                    MenuProps={{
                      getContentAnchorEl: null,
                      disableScrollLock: true,
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                    }}
                  >
                    <CustomScrollbars className={classes.customScrollHeight}>
                      {lookUpData &&
                        map(lookUpData.venues, (venue) => {
                          return (
                            <MenuItem
                              onClick={handleDropdownSelect("venue", venue)}
                            >
                              {venue.name}
                            </MenuItem>
                          );
                        })}
                    </CustomScrollbars>
                    <Row className={classes.inputContainer}>
                      <input
                        type="text"
                        placeholder="ADD YOUR OWN"
                        className={classes.inputField}
                        onBlur={(e) => {
                          setValues({
                            ...values,
                            newOptionValue: e.target.value,
                          });
                        }}
                      />
                      <p className={classes.plus} onClick={addTitle("venue")}>
                        <div className={`${classes.iconPlusStyle}`} />
                      </p>
                    </Row>
                  </CustomSelectProj>
                </Row>
              </Row>
              <Row>
                <Row
                  className={`${classes.dropdown_margin} ${classes.padding_left_13}`}
                >
                  {map(clientele, (eachClientele) => {
                    return (
                      <div className={classes.listAlign}>
                        {eachClientele.name}
                        <img
                          src="/assets/images/sketched-x.png"
                          alt=""
                          onClick={handleDropdownSelect(
                            "clientele",
                            eachClientele,
                            "del"
                          )}
                          className={classes.crossIconStyle}
                        />
                      </div>
                    );
                  })}
                </Row>
                <Row
                  className={`${classes.dropdown_margin} ${classes.padding_left_37}`}
                >
                  {map(venue, (eachVenue) => {
                    return (
                      <div className={classes.listAlign}>
                        {eachVenue.name}
                        <img
                          src="/assets/images/sketched-x.png"
                          onClick={handleDropdownSelect(
                            "venue",
                            eachVenue,
                            "del"
                          )}
                          className={classes.crossIconStyle}
                          alt=""
                        />
                      </div>
                    );
                  })}
                </Row>
              </Row>
              <Row className={`${classes.companySettBlock} `}>
                <Row
                  className={`${classes.fieldsAlign} ${classes.marginTop14}`}
                >
                  {/* <span className={classes.ovalCircle}></span> */}
                  <Text
                    size={16}
                    color={color.primary_palette.black}
                    family="avenir_sb"
                    className={`${classes.settingsTxtAlign} ${classes.licensedAlign}`}
                  >
                    {strings.settings.titles.licensed}{" "}
                  </Text>
                  <Row>
                    <CustomRadioButton
                      label={strings.settings.titles.yes}
                      labelplacement="end"
                      className={classes.radioBtn}
                      size="small"
                      name="licensed"
                      value={"true"}
                      checked={licensed}
                      onChange={handleInputChange}
                    />
                    <CustomRadioButton
                      label={strings.settings.titles.no}
                      labelplacement="end"
                      className={classes.radioBtn}
                      size="small"
                      name="licensed"
                      value={"false"}
                      checked={licensed == false}
                      onChange={handleInputChange}
                    />
                  </Row>
                </Row>
                {licensed && (
                  <Row
                    className={`${classes.fieldsAlign} ${classes.LicenseBlockAlign}`}
                  >
                    <Text
                      size={16}
                      color={color.primary_palette.black}
                      family="avenir_sb"
                      className={`${classes.settingsTxtAlign} ${classes.lower_zindex}`}
                    >
                      {strings.settings.titles.whom}{" "}
                    </Text>
                    <CustomSelectProj
                      className={`${classes.dropdownWidth} ${classes.clienteleProjWidth}`}
                      IconComponent={Chevron_Down_Icon}
                      name="license"
                      isProjPage={true}
                      value={license}
                      renderValue={(value) =>
                        get(value, "name", "Choose license")
                      }
                      MenuProps={{
                        getContentAnchorEl: null,
                        disableScrollLock: true,
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                      }}
                    >
                      <CustomScrollbars
                        className={`${classes.customScrollHeight}`}
                      >
                        {lookUpData &&
                          lookUpData.configData &&
                          map(
                            lookUpData.configData.licensedIssue,
                            (license) => {
                              return (
                                <MenuItem
                                  onClick={handleDropdownSelect(
                                    "license",
                                    license
                                  )}
                                >
                                  {license.name}
                                </MenuItem>
                              );
                            }
                          )}
                      </CustomScrollbars>
                      <Row className={classes.inputContainer}>
                        <input
                          type="text"
                          placeholder="ADD YOUR OWN"
                          className={classes.inputField}
                          onBlur={(e) => {
                            setValues({
                              ...values,
                              newOptionValue: e.target.value,
                            });
                          }}
                        />
                        <p
                          className={classes.plus}
                          onClick={addTitle("license")}
                        >
                          <div className={`${classes.iconPlusStyle}`} />
                        </p>
                      </Row>
                    </CustomSelectProj>
                  </Row>
                )}
              </Row>
              {licensed && (
                <Row
                  className={`${classes.license_margin} ${classes.margin_top_neg_8}`}
                >
                  {map(license, (eachLicense) => {
                    return (
                      <div className={classes.listAlign}>
                        {eachLicense.name}
                        <img
                          src="/assets/images/sketched-x.png"
                          onClick={handleDropdownSelect(
                            "license",
                            eachLicense,
                            "del"
                          )}
                          className={classes.crossIconStyle}
                          alt=""
                        />
                      </div>
                    );
                  })}
                </Row>
              )}
            </div>
            <div>
              <Row>
                <Text
                  size={20}
                  color={color.primary_palette.franklin_purple}
                  family="gillsans_sb"
                  transform="uppercase"
                >
                  {strings.settings.titles.typical_job}
                </Text>
              </Row>
              <textarea
                className={classes.typical_job}
                name="companyBio"
                defaultValue={values.companyBio}
                onBlur={handleInputChange}
              />
            </div>
            <Row className={classes.save_wrapper}>
              <div>
                <Text
                  onClick={handleDeleteCompanyModal}
                  color={color.secondary_palette.grays.medium_gray}
                  family="avenir_sb"
                  className={`${classes.txtLeft} ${classes.pointer} ${classes.delete_company}`}
                >
                  <Trash_can className={classes.trash_icon_delete} /> DELETE
                  COMPANY
                </Text>
                <Text
                  color={color.primary_palette.christmas_red}
                  family="avenir_sb"
                  className={classes.errorText}
                >
                  {deleteCompanyError}
                </Text>
              </div>
              <div className={classes.SaveBtnDiv}>
                <CustomButton
                  className={classes.edit_save_btn}
                  onClick={saveCompanyData}
                  disabled={formHasErrors}
                >
                  {" "}
                  Save{" "}
                </CustomButton>
              </div>
            </Row>
          </div>
        </Row>
      </Row>
      <CustomModal
        open={openCroppingModal}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <ChoosePicture
          isProfileCropper={true}
          imageUrl={get(imageData[croppingModalType], "imageUrl", "")}
          type={croppingModalType}
          updateCroppedImage={updateCroppedImage}
          openImageExp={openImageExp}
          onClose={handleClose}
        />
      </CustomModal>
      <CustomModal
        open={OpenRemovePersonModal}
        onClose={handleRemovePerson}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <RemovePerson onSure={removeAdmin} onClose={handleRemovePerson} />
      </CustomModal>
      <CustomModal
        open={openAdminDeleteModal}
        onClose={openAdminDelete}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <RemoveAdmin onClose={openAdminDelete} />
      </CustomModal>
      <CustomModal
        open={DeleteCompanyModal}
        onClose={handleDeleteCompanyModal}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <DeleteCompany
          onSure={deleteCompany}
          onClose={handleDeleteCompanyModal}
        />
      </CustomModal>
    </>
  );
}

export default companyBasicStyles(CompanyBasics);
