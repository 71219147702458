import React from "react";

import { color } from "../../utilities/themes";
import ProductStyles from "./styles/productStyles";
import Text from "../common/ui_kit/text";
import strings from "../../utilities/strings";
import CustomButton from "../navigations/custom_buttons";
import Close_Icon from "../data_display/icons/Close";

function ProductDelete(props) {
  const { classes, onClose, productsInfo, deleteProdPub } = props;

  return (
    <>
      <div className={classes.product_cover}>
        <div className={classes.btnRight}>
          <CustomButton className={classes.crossBtn} isX>
            <Close_Icon onClick={onClose} />
          </CustomButton>
        </div>
        <div className={classes.product_cover_inner}>
          {productsInfo && productsInfo.imageUri ? (
            <img
              alt=""
              src={productsInfo.imageUri}
              className={classes.deleteImgBlock}
            />
          ) : (
            <div className={classes.img_block}></div>
          )}
          <Text
            size={20}
            color={color.primary_palette.black}
            family="avenir_light"
            className={`${classes.textCenter} ${classes.padding_20}`}
          >
            {productsInfo && productsInfo.title
              ? productsInfo.title
              : strings.product_publication.titles.Interior}
          </Text>
        </div>
        <Text
          size={25}
          color={color.greyish_brown}
          className={`${classes.textCenter} ${classes.padding_sides}`}
        >
          {strings.product_publication.titles.confirmation}
        </Text>
        <Text
          size={20}
          color={color.primary_palette.black}
          family="gillsans_light"
          className={classes.textCenter}
        >
          {strings.product_publication.titles.action}
        </Text>
        <div className={classes.textCenter}>
          <CustomButton
            className={classes.deleteBtn}
            onClick={deleteProdPub(productsInfo._id)}
          >
            {strings.product_publication.titles.delete_item}
          </CustomButton>
        </div>
      </div>
    </>
  );
}

export default ProductStyles(ProductDelete);
