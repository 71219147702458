import React from "react";
import { get } from "lodash";

import GridmsStyles from "./styles/gridmsStyles";
import Row from "../common/ui_kit/row";

function GridSixMs(props) {
  const { classes, imageData } = props;

  return (
    <>
      <div className={classes.publicviewProject}>
        <Row className={classes.grid_main_spacing}>
          <div>
            <div className={classes.imgDiv}>
              <img
                className={classes.grid_6ms_1}
                alt=""
                src={
                  get(
                    imageData[0],
                    "alterImageUri.croppedImage",
                    "/assets/images/green.PNG"
                  ) || "/assets/images/green.PNG"
                }
              />
              {!get(imageData[0], "alterImageUri.croppedImage") && (
                <div className={classes.numberAlign4_2}>1</div>
              )}
            </div>
            <div className={classes.imgDiv}>
              <img
                className={classes.grid_6ms_2}
                alt=""
                src={
                  get(
                    imageData[1],
                    "alterImageUri.croppedImage",
                    "/assets/images/gray.PNG"
                  ) || "/assets/images/gray.PNG"
                }
              />
              {!get(imageData[1], "alterImageUri.croppedImage") && (
                <div className={classes.numberAlign}>2</div>
              )}
            </div>
          </div>
          <div>
            <div className={classes.imgDiv}>
              <img
                className={classes.grid_6ms_3}
                alt=""
                src={
                  get(
                    imageData[2],
                    "alterImageUri.croppedImage",
                    "/assets/images/blue.PNG"
                  ) || "/assets/images/blue.PNG"
                }
              />
              {!get(imageData[2], "alterImageUri.croppedImage") && (
                <div className={classes.numberAlign4_2}>3</div>
              )}
            </div>
            <div className={classes.imgDiv}>
              <img
                className={classes.grid_6ms_4}
                alt=""
                src={
                  get(
                    imageData[3],
                    "alterImageUri.croppedImage",
                    "/assets/images/blue.PNG"
                  ) || "/assets/images/blue.PNG"
                }
              />
              {!get(imageData[3], "alterImageUri.croppedImage") && (
                <div className={classes.numberAlign}>4</div>
              )}
            </div>
          </div>
          <div>
            <div className={classes.imgDiv}>
              <img
                className={classes.grid_6ms_5}
                alt=""
                src={
                  get(
                    imageData[4],
                    "alterImageUri.croppedImage",
                    "/assets/images/gray.PNG"
                  ) || "/assets/images/gray.PNG"
                }
              />
              {!get(imageData[4], "alterImageUri.croppedImage") && (
                <div className={classes.numberAlign4_2}>5</div>
              )}
            </div>
            <div className={classes.imgDiv}>
              <img
                className={classes.grid_6ms_6}
                alt=""
                src={
                  get(
                    imageData[5],
                    "alterImageUri.croppedImage",
                    "/assets/images/green.PNG"
                  ) || "/assets/images/green.PNG"
                }
              />
              {!get(imageData[5], "alterImageUri.croppedImage") && (
                <div className={classes.numberAlign}>6</div>
              )}
            </div>
          </div>
        </Row>
      </div>
    </>
  );
}

export default GridmsStyles(GridSixMs);
