import React from "react";
import strings from "../../utilities/strings";
import {  Text } from "../common/ui_kit";
import { color } from "../../utilities/themes";
import { get, sortBy, map, startsWith } from "lodash";
import MobilePorfolioStyles from "./Styles/MobileportfioloStyles";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


function MobileProductsPublications(props) {
  const { classes, portfolioData } = props;

  const getUrl = (url) => {
    if (url) {
      if (startsWith(url, "http://") || startsWith(url, "https://")) {
        return url;
      }
      return `//${url}`;
    }
    return "javascript:void(0)";
  };

  const renderSlides = () => {
    const sortedData = sortBy(
      get(portfolioData, "productsOrPublications", []),
      (obj) => !obj.isProduct
    );
    const labelIndexes = {
      products: null,
      publications: null,
    };
    sortedData.map((each, idx) => {
      if (
        each.isProduct &&
        !labelIndexes.products &&
        labelIndexes.products !== 0
      ) {
        labelIndexes.products = idx;
      }
      if (!each.isProduct && !labelIndexes.publications) {
        labelIndexes.publications = idx;
      }
      return;
    });
    return map(sortedData, (data, idx) => {
      return (
        <div className={classes.slider_width}>
          {data.referenceUri ? (
            <a
              href={data.referenceUri && getUrl(data.referenceUri)}
              target="_blank"
            >
              <img
                src={get(data, "imageUri", "/assets/images/engraved-pilar.png")}
                className={`${classes.sliderImgStyle} ${classes.cursor}`}
                alt=""
              />
            </a>
          ) : (
            <img
              src={get(data, "imageUri", "/assets/images/engraved-pilar.png")}
              className={`${classes.sliderImgStyle} ${classes.cursor}`}
              //   onClick={redirectToProdView}
              alt=""
            />
          )}
          <Text
            className={`${classes.textCenter} ${classes.sliderTextWrap} hover_text`}
            size={14}
            color={color.primary_palette.black}
            family="gillsans_sb"
            transform="capitalize"
          >
            {get(
              data,
              "title",
              strings.portfolio_userView.titles.products_des1
            )}
          </Text>
          <Text
            className={`${classes.textCenter} ${classes.sliderTextWrap} ${classes.subTxtHover}`}
            size={14}
            color={color.primary_palette.black}
            family="gillsans_r"
          >
            {get(data, "subTitle")}
          </Text>
        </div>
      );
    });
  };

  const returnSliderClass = (length) => {
    switch (length) {
      case 2:
        return classes.slider2Images;
      case 3:
        return classes.slider3Images;
      default:
        return classes.slider;
    }
  };

  return (
    <div
      className={returnSliderClass(
        get(portfolioData, "productsOrPublications.length", 0)
      )}
    >
      <Slider slidesToShow={1}>{renderSlides()}</Slider>
    </div>
  );
}

export default MobilePorfolioStyles(MobileProductsPublications);
