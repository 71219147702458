import { withStyles } from "@material-ui/core/styles";
import tradework_theme, { pxToRem, color } from "../../../utilities/themes";

const styles = withStyles({
  link_text: {
    ...tradework_theme.typography.styles.avenir_sb,
    color: color.primary_palette.free_purple,
    fontSize: pxToRem(14),
    cursor: "pointer",
  },
  nav_spacings: {
    marginRight: pxToRem(50),
    padding: `${pxToRem(10)} ${pxToRem(0)}`,
    "&:hover": {
      textDecoration: "none",
    },
  },
  miller: {
    ...tradework_theme.typography.styles.TrajanPro3,
    textTransform: "capitalize",
    padding: `${pxToRem(6)} ${pxToRem(0)}`,
    color: color.primary_palette.black,
  },
  width_20: {
    marginRight: pxToRem(50),
    alignItems: "flex-end",
    position: "relative",
    top: pxToRem(6),
  },
  activeLink: {
    borderBottom: `solid ${pxToRem(5)} ${
      color.primary_palette.franklin_purple
    }`,
    paddingBottom: pxToRem(5),
  },
  envolopStyle: {
    textAlign: "center",
    width: "33%",
    marginLeft: pxToRem(27),
    alignItems: "center",
  },
  width_80: {
    width: "75%",
  },
  markerAlign: {
    height: pxToRem(25),
    width: pxToRem(18),
    marginLeft: pxToRem(6),
    verticalAlign: "text-bottom",
    cursor: "pointer",
  },
  border_bottom: {
    borderBottom: `solid ${pxToRem(1)} ${color.blessed_platinum}`,
    marginTop: pxToRem(80),
    height: pxToRem(32),
    paddingBottom: pxToRem(6),
    paddingLeft: pxToRem(118),
  },
  marginTop80: {
    marginTop: pxToRem(94),
  },
  activeBorder: {
    borderBottom: `solid ${pxToRem(3)} ${color.primary_palette.free_purple}`,
  },
  width100: {
    width: "100%",
  },
  initial_txt: {
    textTransform: "initial",
  },
  hover_location: {
    width: "33%",
    "& .hover_display": {
      display: "none",
    },
    "&:hover": {
      "& .hover_display": {
        display: "block",
      },
      "& .txt_green": {
        color: color.form_colors.sucess_color,
      },
      "& .hover_icon": {
        "& path": {
          fill: color.form_colors.sucess_color,
        },
      },
    },
  },
  hover_content: {
    width: pxToRem(176.4),
    border: `solid ${pxToRem(1)} ${color.indigo_blue}`,
    padding: pxToRem(4),
    position: "absolute",
    right: pxToRem(94),
    borderRadius: pxToRem(4),
    backgroundColor: color.primary_palette.white,
    zIndex: 9,
    boxShadow: "0 1px 4px 0 rgba(0, 0, 0, 0.5)",
    "& .triangle": {
      borderBottomColor: color.indigo_blue,
      position: "absolute",
      display: "block",
      width: 0,
      height: 0,
      borderStyle: "solid",
      borderWidth: pxToRem(8),
      borderTopWidth: pxToRem(0),
      borderColor: "transparent",
      right: pxToRem(11),
      top: "-23%",
      transform: "rotate(0deg)",
      "&:after": {
        top: pxToRem(11.5),
        margin: pxToRem(-10),
        content: "''",
        borderBottomColor: color.primary_palette.white,
        position: "absolute",
        display: "block",
        width: 0,
        height: 0,
        borderStyle: "solid",
        borderWidth: pxToRem(10),
        borderTopWidth: pxToRem(0),
        borderColor: "transparent",
      },
    },
  },
  envelope_icon: {
    width: pxToRem(27),
    height: pxToRem(27),
    paddingLeft: pxToRem(10),
    position: "relative",
    top: pxToRem(-4),
    cursor: "pointer",
  },
  regionsAlignPublic: {
    fontSize: pxToRem(12),
    ...tradework_theme.typography.styles.avenir_bold,
    color: color.form_colors.sucess_color,
  },
  regionsAlignNotPublic: {
    fontSize: pxToRem(12),
    ...tradework_theme.typography.styles.avenir_light,
    color: color.secondary_palette.blues.click_blue,
  },
  editToggleTxt: {
    width: pxToRem(74),
    cursor: "pointer",
  },
  publicTxtAlign: {
    marginLeft: pxToRem(32),
  },
  preview_btn: {
    height: pxToRem(16),
    width: pxToRem(179),
    marginRight: pxToRem(20),
    borderRadius: pxToRem(2),
    marginLeft: pxToRem(32),
    position: "absolute",
    top: pxToRem(166),
    border: `solid ${pxToRem(0.5)} ${color.primary_palette.black}`,
    zIndex: "999",
    backgroundColor: color.primary_palette.white,
    "& .MuiButton-label": {
      color: color.primary_palette.black,
      fontSize: pxToRem(12),
      ...tradework_theme.typography.styles.avenir_sb,
      position: "relative !important",
      textTransform: "none",
    },
    "&:hover": {
      border: `solid ${pxToRem(1.6)} ${color.primary_palette.highlight_purple}`,
      backgroundColor: `${color.primary_palette.white} !important`,
    },
  },
  publicIconAlign: {
    position: "relative",
    top: pxToRem(20),
    "& .hover_icon": {
      cursor: "pointer",
    },
    "&:hover": {
      "& .hover_icon": {
        "& path": {
          fill: color.form_colors.sucess_color,
        },
      },
    },
  },
});

export default styles;
