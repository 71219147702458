import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import MenuItem from "@material-ui/core/MenuItem";
import { map, get } from "lodash";
import { useHistory } from "react-router-dom";

import { color } from "../../utilities/themes";
import NewPortfolioModalStyles from "./styles/newPortfolioPopupStyles";
import Text from "../common/ui_kit/text";
import strings from "../../utilities/strings";
import Close_Icon from "../data_display/icons/Close";
import CustomButton from "../navigations/custom_buttons";
import CustomRadioButton from "../inputs/custom_radio_button";
import CustomSelect from "../common/ui_kit/custom_select";
import Chevron_Down_Icon from "../data_display/icons/Arrow_Chevron_Down";
import CustomScrollbars from "../data_display/custom_scroll";
import CustomTextField from "../inputs/custom_textfields";
import { Row } from "../common/ui_kit";

import { SettingsActions } from "../../redux/actions";

function NewPortfolioModal(props) {
  const { classes, onClose } = props;
  const [state, setState] = useState({
    isNewPortfolio: "create",
    portfolioName: "",
    dupePortfolioName: "",
    errorMessage: "",
  });

  const {
    list,
    isNewPortfolio,
    portfolioName,
    dupePortfolioName,
    errorMessage,
  } = state;

  const dispatch = useDispatch();
  const history = useHistory();
  const fetchData = () => {
    dispatch(
      SettingsActions.getCompanyPortfolioData(localStorage.getItem("companyId"))
    );
    dispatch(
      SettingsActions.getCompanyLocationsData(localStorage.getItem("companyId"))
    );
  };

  //API
  useEffect(() => {
    fetchData();
  }, []);

  const handleDropdownSelect = (name, value) => (e) => {
    e.preventDefault();
    e.stopPropagation();
    setState({ ...state, [name]: value });
  };

  const portfolioList = useSelector(
    (resData) => resData.Settings.CompanyPortfolioData
  );

  const handleInputChange = (e) => {
    if (e.target.name === "isNewPortfolio") {
      setState({
        ...state,
        [e.target.name]: e.target.value,
        list: "",
        portfolioName: "",
        dupePortfolioName: "",
      });
      return;
    }
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const saveDupeData = () => {
    if (isNewPortfolio === "back") {
      onClose();
      history.push("/portfolioView");
    }
    if (isNewPortfolio === "create") {
      onClose();
      history.push("/portfolioView");
      localStorage.setItem("portfolioNewName", portfolioName);
      localStorage.removeItem("portfolioId");
      window.location.reload();
    }
    const dataToSubmit = {
      companyId: localStorage.getItem("companyId"),
      portfolioName: portfolioName ? portfolioName : dupePortfolioName,
      portfolioId: get(list, "_id", ""),
      isNew: isNewPortfolio === "create" ? true : false,
    };
    dispatch(
      SettingsActions.addDupePortfolio(dataToSubmit, (res) => {
        if (!res.error.error) {
          localStorage.setItem("portfolioId", res.data._id);
          window.location.reload();
          onClose();
        } else {
          setState({ ...state, errorMessage: res.error.error.message });
        }
      })
    );
  };

  return (
    <>
      <div className={classes.product_cover}>
        <div className={classes.textRight}>
          <CustomButton className={classes.cross_btn} onClick={onClose} isX>
            <Close_Icon className={classes.cross_mark} onClick={onClose} />
          </CustomButton>
        </div>
        <div className={classes.product_cover_inner}>
          <div className={`${classes.textCenter} ${classes.padding_bottom_40}`}>
            <Text
              size={18}
              color={color.primary_palette.black}
              family="avenir_light"
            >
              {strings.new_portfolio_popup.titles.leaving}
            </Text>
            <Text
              size={30}
              color={color.primary_palette.black}
              family="TrajanPro3"
              className={classes.txtCaptial}
            >
              {localStorage.getItem("portfolioName")}
            </Text>
            <Text
              size={18}
              color={color.primary_palette.black}
              family="avenir_light"
            >
              {strings.new_portfolio_popup.titles.creating}
            </Text>
            <Text
              size={18}
              color={color.primary_palette.black}
              family="avenir_light"
            >
              {localStorage.getItem("companyName")}
            </Text>
          </div>
          <div className={classes.padding_left_70}>
            <Row className={classes.alignSelf}>
              <CustomRadioButton
                label={strings.new_portfolio_popup.titles.creating_new}
                labelPlacement="end"
                size="large"
                className={classes.radio_button}
                name="isNewPortfolio"
                value={"create"}
                checked={isNewPortfolio === "create"}
                onChange={handleInputChange}
              />
              <CustomTextField
                name="portfolioName"
                className={classes.portfolioNameStyle}
                placeholder={strings.new_portfolio_popup.titles.portfolio_name}
                onChange={handleInputChange}
                value={portfolioName}
                onFocus={(e) => (e.target.placeholder = "")}
                onBlur={(e) => {
                  e.target.placeholder =
                    strings.new_portfolio_popup.titles.portfolio_name;
                }}
              />
            </Row>
            <Row className={classes.txtTransform}>
              <CustomRadioButton
                label={strings.new_portfolio_popup.titles.start_dupe}
                labelPlacement="end"
                size="large"
                className={classes.radio_button}
                name="isNewPortfolio"
                value={"dupe"}
                checked={isNewPortfolio === "dupe"}
                onChange={handleInputChange}
              />
              <CustomSelect
                className={classes.portfolio_items}
                IconComponent={Chevron_Down_Icon}
                name="list"
                value={list}
                renderValue={(value) =>
                  get(value, "name", "Select a Portfolio")
                }
                MenuProps={{
                  getContentAnchorEl: null,
                  disableScrollLock: true,
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                }}
              >
                <CustomScrollbars className={classes.customScrollHeight}>
                  {portfolioList &&
                    portfolioList.portfolios &&
                    map(portfolioList.portfolios, (listVal) => {
                      return (
                        <MenuItem onClick={handleDropdownSelect("list", listVal)}>
                          {listVal.name}
                        </MenuItem>
                      );
                    })}
                </CustomScrollbars>
              </CustomSelect>
            </Row>
            {isNewPortfolio === "dupe" && (
              <div className={classes.dupeNameAlign}>
                <Text
                  size={20}
                  color={color.primary_palette.black}
                  family="gillsans_light"
                  className={classes.dupeTxtAlign}
                >
                  as
                </Text>
                <CustomTextField
                  name="dupePortfolioName"
                  className={classes.portfolioNameStyle}
                  placeholder={strings.new_portfolio_popup.titles.portfolio}
                  onChange={handleInputChange}
                  value={dupePortfolioName}
                  onFocus={(e) => (e.target.placeholder = "")}
                  onBlur={(e) => {
                    e.target.placeholder =
                      strings.new_portfolio_popup.titles.portfolio;
                  }}
                />
              </div>
            )}
            <div className={classes.txtTransform}>
              <CustomRadioButton
                label={`${
                  strings.new_portfolio_popup.titles.take_me
                } ${localStorage.getItem("portfolioName")}`}
                labelPlacement="end"
                size="large"
                className={classes.radio_button}
                name="isNewPortfolio"
                value={"back"}
                checked={isNewPortfolio === "back"}
                onChange={handleInputChange}
              />
            </div>
          </div>
        </div>
        <div className={classes.error}>{errorMessage}</div>
        <div className={classes.btnDivAlign}>
          <CustomButton className={classes.deleteBtn} onClick={saveDupeData}>
            {strings.new_portfolio_popup.titles.lets_go}
          </CustomButton>
        </div>
      </div>
    </>
  );
}

export default NewPortfolioModalStyles(NewPortfolioModal);
