import React from "react";

import RecoverCredentialsStyles from "./styles/recoverCredentialsStyle";
import Text from "../../components/common/ui_kit/text";
import strings from "../../utilities/strings";
import CustomButton from "../../components/navigations/custom_buttons";
import Close_Icon from "../../components/data_display/icons/Close";
import CustomModal from "../../components/inputs/custom_modal";
import { color } from "../../utilities/themes";

function PasswordLinkMail(props) {
  const { classes, onClose, dataFromRecover } = props;

  return (
    <>
      <CustomModal open={true}>
        <div className={classes.recover_credentials}>
          <div className={classes.textRight}>
            <CustomButton className={classes.crossBtn} onClick={onClose} isX>
              <Close_Icon />
            </CustomButton>
          </div>
          <div className={classes.textCenter}>
            <img
              src="/assets/images/recover1.png"
              alt="recover1"
              className={classes.recover_icon}
            />
            <Text
              size={20}
              family="gillsans_sb"
              color={color.primary_palette.franklin_purple}
              className={classes.txtCapital}
            >
              {strings.passwords.titles.recover_credentials}
            </Text>
            <Text
              size={15}
              family="gillsans_sb"
              color={color.primary_palette.black}
              className={classes.padding_top_8}
            >
              {strings.passwords.titles.sent_to_email}
              <br />
              {dataFromRecover}
            </Text>
            <CustomButton onClick={onClose} className={classes.greatBtn}>
              {strings.passwords.titles.great}
            </CustomButton>
          </div>
        </div>
      </CustomModal>
    </>
  );
}

export default RecoverCredentialsStyles(PasswordLinkMail);
