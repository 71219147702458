import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { map, get, size } from "lodash";

import PortfolioStyles from "./styles/portfolioStyles";
import Row from "../common/ui_kit/row";
import Text from "../common/ui_kit/text";
import strings from "../../utilities/strings";
import { color } from "../../utilities/themes";
import CustomToggleButton from "../../components/inputs/custom_toggle_button";
import NewPortfolioModal from "../build_portfolio/new_port_popup";
import CustomModal from "../inputs/custom_modal";
import DeleteModal from "./delete_projectModal";
import ContactModal from "./contact_modal";

import { SettingsActions, LookUpActions } from "../../redux/actions";

function PurchaseHistory(props) {
  const [state, setState] = useState({
    openDupeModal: false,
    openDeleteModal: false,
    portId: "",
  });
  const { classes, historyTable } = props;

  const {
    portfolioTransactions,
    openDupeModal,
    openDeleteModal,
    portId,
    openContactModal,
  } = state;

  const dispatch = useDispatch();

  const CompanyPortfolioData = useSelector(
    (resData) => resData.Settings.CompanyPortfolioData
  );

  //API
  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (CompanyPortfolioData) {
      if (CompanyPortfolioData.portfolios) {
        setState({
          ...state,
          companyPortfolios: [...CompanyPortfolioData.portfolios],
          portfolioTransactions: [...CompanyPortfolioData.transactions],
          legacyMarkets: [...CompanyPortfolioData.legacyMarkets],
        });
      }
    }
  }, [CompanyPortfolioData]);

  const fetchData = () => {
    dispatch(
      SettingsActions.getCompanyPortfolioData(localStorage.getItem("companyId"))
    );
    dispatch(
      SettingsActions.getCompanyBasicsData(localStorage.getItem("companyId"))
    );
    dispatch(
      SettingsActions.getCompanyLocationsData(localStorage.getItem("companyId"))
    );
    dispatch(LookUpActions.getLookUpData());
  };

  const loadReciept = (reciept) => () => {
    if (reciept) {
      window.open(reciept);
    }
  };

  const handleDeleteModal = (portId) => () => {
    setState({
      ...state,
      openDeleteModal: !openDeleteModal,
      portId: portId,
    });
  };

  const openMobileModal = (portId) => {
    setState({
      ...state,
      openContactModal: !openContactModal,
    });
  };

  //returns formatted date in US
  const getPreviewDate = (date) => {
    if (!date) {
      return "";
    }
    const NewDt = new Date(date);
    return new Intl.DateTimeFormat("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    }).format(NewDt);
  };

  // returns formatted salary in USD
  const getFormattedCurrency = (salary) => {
    if (!salary) {
      return "";
    }
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 0,
    }).format(salary);
  };

  const closeDupeModal = () => {
    setState({
      ...state,
      openDupeModal: false,
    });
  };

  const deletePortName = () => {
    const dataToSubmit = {
      portfolioId: portId,
      displayedAtId: "",
    };
    dispatch(
      SettingsActions.deletePortfolio(dataToSubmit, (res) => {
        if (!res.error.error) {
          fetchData();
          setState({
            ...state,
            openDeleteModal: false,
          });
        } else {
          setState({ ...state, errorMessage: res.error.message.message });
        }
      })
    );
  };

  const onAutoRenew = (flag, transactionId) => {
    const dataToSubmit = {
      autoRenew: !flag,
      transactionId: transactionId,
    };
    dispatch(
      SettingsActions.portfolioAutoRenew(dataToSubmit, (res) => {
        if (!res.error.error) {
          fetchData();
        } else {
          setState({ ...state, errorMessage: res.error.message.message });
        }
      })
    );
  };

  return (
    <>
      <Row
        className={`${classes.width_100} ${classes.block} ${classes.padding_top}`}
      >
        <Text
          size={25}
          color={color.primary_palette.free_purple}
          family="gillsans_sb"
        >
          {strings.settings.titles.purchase_history}
        </Text>
        <Text
          size={12}
          color={color.primary_palette.free_purple}
          family="avenir_sb"
        >
          {strings.settings.titles.purchase_history_sub}
        </Text>
        <table className={classes.table_data} ref={historyTable}>
          <thead className={classes.headBorder}>
            <tr>
              <th>Portfolio</th>
              <th>Market</th>
              <th scope="col">Categories Displayed</th>
              <th>Purchased/ Verified</th>
              <th>Amount Paid</th>
              <th>Payment Mode</th>
              <th>Portfolio Expiration</th>
              <th>Autopay</th>
              <th>Receipt/ Invoice</th>
            </tr>
          </thead>

          {portfolioTransactions &&
            map(portfolioTransactions, (transaction) => {
              let displayedAtContent = {};
              if (
                transaction.portfolioId &&
                transaction.portfolioId.displayedAt != null
              ) {
                for (let displayData of transaction.portfolioId.displayedAt) {
                  if (displayData._id) {
                    displayedAtContent = displayData;
                    break;
                  }
                }
              }
              return (
                <tbody className={classes.body_spacing}>
                  <tr>
                    <td
                      className={`${classes.text_transform} ${classes.padding_space_table}`}
                    >
                      {size(get(transaction, "portfolioId.name", ""))
                        ? get(transaction, "portfolioId.name", "")
                        : get(
                            transaction,
                            "FRTransactionItems[0].FRPortfolio",
                            ""
                          )}
                    </td>
                    <td className={classes.padding_space_table}>
                      {size(get(transaction, "transactionItems[0].region", ""))
                        ? get(transaction, "transactionItems[0].region", "")
                        : get(
                            transaction,
                            "FRTransactionItems[0].FRMarket",
                            ""
                          )}
                    </td>
                    <td className={classes.padding_space_table} scope="row">
                      {size(
                        get(transaction, "transactionItems[0].category", "")
                      )
                        ? map(
                            get(transaction, "transactionItems[0].category"),
                            (cate) => {
                              return (
                                <span>
                                  {cate} <br />
                                </span>
                              );
                            }
                          )
                        : map(
                            get(
                              transaction,
                              "FRTransactionItems[0].FRCategory"
                            ),
                            (cate) => {
                              return (
                                <span>
                                  {cate} <br />
                                </span>
                              );
                            }
                          )}
                    </td>
                    <td className={classes.padding_space_table}>
                      {getPreviewDate(get(transaction, "createdAt", ""))} <br />
                      {get(transaction, "createdAt", "") && (
                        <Text
                          family="gillsans_sb"
                          size={15}
                          color={color.primary_palette.franklin_purple}
                        >
                          Verified
                        </Text>
                      )}
                    </td>
                    <td className={classes.padding_space_table}>
                      {getFormattedCurrency(get(transaction, "amount", ""))}
                      {getPreviewDate(get(transaction, "dueDate")) && (
                        <>
                          <Text
                            family="gillsans_sb"
                            size={15}
                            color={color.primary_palette.franklin_purple}
                          >
                            Payment Due
                          </Text>
                          {getFormattedCurrency(
                            get(transaction, "dueAmount", "")
                          )}{" "}
                          &nbsp;
                          {getPreviewDate(get(transaction, "dueDate", ""))}
                        </>
                      )}
                    </td>
                    <td className={classes.padding_space_table}>
                      {get(transaction, "paymentType", "")}
                    </td>
                    <td className={classes.padding_space_table}>
                      {!get(transaction, "FRData")
                        ? getPreviewDate(
                            get(displayedAtContent, "expirationDate", "")
                          )
                        : getPreviewDate(
                            get(transaction, "FRExpirationDate", "")
                          )}
                    </td>
                    <td className={classes.padding_space_table}>
                      {!get(transaction, "FRData") &&
                      get(transaction, "last4") ? (
                        <>
                          <Row
                            className={`${classes.alignItemsCenter} ${classes.toggleDots}`}
                          >
                            <Text
                              family="gillsans_sb"
                              size={15}
                              color={color.primary_palette.franklin_purple}
                            >
                              on
                            </Text>
                            <CustomToggleButton
                              onChange={(e) => {
                                onAutoRenew(e.target.checked, transaction._id);
                              }}
                              key={transaction.autoRenew}
                              checked={!transaction.autoRenew}
                            />
                            <Text
                              family="gillsans_sb"
                              size={15}
                              color={color.primary_palette.franklin_purple}
                            >
                              off
                            </Text>
                          </Row>

                          <div className={classes.cardDetails}>
                            <img
                              alt=""
                              src="/assets/images/mastercard.png"
                              className={classes.masterAlign}
                            />
                            •••• {get(transaction, "last4", "")}
                          </div>
                        </>
                      ) : (
                        <Text
                          family="Helvetica"
                          size={15}
                          color={color.wizard_box_colors.shadow_gray}
                          className={classes.enableStyle}
                          onClick={openMobileModal}
                        >
                          enable
                        </Text>
                      )}
                    </td>
                    <td
                      className={`${classes.txtCenter} ${classes.padding_space_table}`}
                    >
                      {get(transaction, "receiptPath", "") && (
                        <span
                          onClick={loadReciept(
                            get(transaction, "receiptPath", "")
                          )}
                          className={classes.viewReciptStyle}
                        >
                          Invoice
                        </span>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td></td>
                    <td></td>
                    <td scope="row" className={classes.table_spacing}></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                </tbody>
              );
            })}
        </table>
      </Row>
      <CustomModal open={openDupeModal} onClose={closeDupeModal}>
        <NewPortfolioModal onClose={closeDupeModal} />
      </CustomModal>
      <CustomModal open={openDeleteModal} onClose={handleDeleteModal()}>
        <DeleteModal
          onClose={handleDeleteModal()}
          deleteProject={deletePortName}
        />
      </CustomModal>
      <CustomModal open={openContactModal} onClose={openMobileModal}>
        <ContactModal onClose={openMobileModal} />
      </CustomModal>
    </>
  );
}

export default PortfolioStyles(PurchaseHistory);
