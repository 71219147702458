import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { get } from "lodash";
import { ENVELOP_ICON } from "../../constants";
import strings from "../../utilities/strings";
import Location from "../data_display/icons/location";
import { useHistory } from "react-router-dom";

import publicStyles from "./styles/publicNavStyles";
import CustomToggleButton from "../../components/inputs/custom_toggle_button";
import { Row, Text } from "../common/ui_kit";
import { color } from "../../utilities/themes";
import { ProfileActions } from "../../redux/actions";
import CustomModal from "../inputs/custom_modal";
import CongratsModal from "../modals/congratsModal";

import PorfolioUserView from "../build_portfolio/portfolio_UserView";

function PublicPortfolioView(props) {
  const { classes } = props;
  const [values, setValues] = useState({
    isPortfolioVisible: false,
    isPublicMode: true,
    openInviteModal: false,
    openMarketModal: false,
  });

  const dispatch = useDispatch();
  const history = useHistory();

  const portfolioData = useSelector((resData) => resData.Profile.portfolioData);
  const displayedAtData = useSelector(
    (resData) => resData.Profile.displayedAtData
  );

  const { isPublicMode, openInviteModal, openMarketModal } = values;

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    dispatch(
      ProfileActions.getPortfolioData(localStorage.getItem("portfolioId"))
    );
  };

  const toggleInviteModal = () => {
    localStorage.removeItem("isPortfolioPublic");
    setValues({
      ...values,
      openInviteModal: !openInviteModal,
    });
  };

  const onEditModeChange = (e) => {
    history.push("/portfolioView");
  };

  const toggleMarketModal = () => {
    setValues({
      ...values,
      openMarketModal: !openMarketModal,
    });
  };

  return (
    <>
      <div className={classes.marginTop80}>
        <Row className={classes.topHeaderBlock}>
          <Row className={classes.envolopStyle}>
            {get(displayedAtData, "isVisibleToPublic", false) && (
              <>
                <Text
                  size={14}
                  color={color.primary_palette.black}
                  family="avenir_bold"
                  transform="uppercase"
                  className={classes.txtAlignTop}
                >
                  {
                    strings.portfolio_userView.titles
                      .invite_friends_clients_view
                  }
                </Text>
                <img
                  src={ENVELOP_ICON}
                  className={classes.envelope_icon}
                  onClick={toggleInviteModal}
                  alt=""
                />
              </>
            )}
          </Row>
          <Text
            size={14}
            color={color.primary_palette.black}
            family="avenir_bold"
            transform="uppercase"
            className={`${classes.txtAlignTop} ${classes.hover_location}`}
            onClick={toggleMarketModal}
          >
            <span className="txt_green">
              {strings.portfolio_userView.titles.displayed_where_is}
            </span>
            <Location className={`${classes.markerAlign} hover_icon`} />
            <div>
              {portfolioData?.displayedAt?.map((display, idx) => {
                return (
                  <span
                    className={
                      display.isVisibleToPublic
                        ? classes.regionsAlignPublic
                        : classes.regionsAlignNotPublic
                    }
                  >
                    {display.region}&nbsp;
                    {get(portfolioData, "displayedAt.length") > idx + 1 && (
                      <>•&nbsp;</>
                    )}
                  </span>
                );
              })}
            </div>
            <div className={`${classes.hover_content} hover_display`}>
              <div className="triangle"></div>
              <Text
                size={10}
                color={color.primary_palette.black}
                family="avenir_roman"
                className={classes.initial_txt}
              >
                The Cities and Categories where you want this Portfolio to be
                displayed
              </Text>
            </div>
          </Text>
          <Row className={`${classes.flex_grow} ${classes.positionRelative} `}>
            <Row className={`${classes.envolopStyle} ${classes.txtAlignTop20}`}>
              <Row
                className={`${classes.edit_mode_switch} ${classes.justify_right}`}
              >
                <Row></Row>
                <Row
                  justify="space-between"
                  align="center"
                  className={classes.edit_mode_switch}
                >
                  <Text
                    family="gillsans_sb"
                    size={14}
                    color={color.primary_palette.black}
                    className={classes.editToggleTxt}
                    onClick={onEditModeChange}
                  >
                    EDIT VIEW
                  </Text>
                  <CustomToggleButton
                    onChange={onEditModeChange}
                    checked={isPublicMode}
                  />
                  <Text
                    family="gillsans_sb"
                    size={14}
                    color={color.primary_palette.black}
                    className={classes.editToggleTxt}
                  >
                    PUBLIC PREVIEW
                  </Text>
                </Row>
              </Row>
            </Row>
          </Row>
        </Row>
        <PorfolioUserView disableEdit={true} isPublic={true} />
      </div>
      <CustomModal
        open={openInviteModal}
        onClose={toggleInviteModal}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <CongratsModal onClose={toggleInviteModal} />
      </CustomModal>
    </>
  );
}

export default publicStyles(PublicPortfolioView);
