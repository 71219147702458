import React, { useState } from "react";
import { get } from "lodash";

import EnableSettingStyles from "./styles/enableSettingsStyles";
import strings from "../../utilities/strings";
import Close_Icon from "../data_display/icons/Close";
import CustomButton from "../navigations/custom_buttons";
import CustomRadioButton from "../inputs/custom_radio_button";
import { useHistory } from "react-router";
import Location from "../data_display/icons/location";
import { color } from "../../utilities/themes";
import Text from "../common/ui_kit/text";

function MarketListModal(props) {
  const { classes, onClose, notPublicPortfolio } = props;
  const history = useHistory();
  const [state, setState] = useState({
    nameVal: "",
    selectedPortData: "",
  });
  const { nameVal, selectedPortData } = state;


  const handleInputChange = (portData) => (e) => {
    const { value } = e.target;
    setState({ ...state, nameVal: value, selectedPortData: portData });
  };

  const onGoClickHandle = () => {
    if (!selectedPortData) {
      return;
    }
    localStorage.setItem("portfolioId", get(selectedPortData, "_id"));
    localStorage.setItem("portfolioName", get(selectedPortData, "name"));
    localStorage.removeItem("isPortfolioPublic");
    history.push("/portfolioView");
    localStorage.setItem("openMarketModal", true);
    onClose();
  };

  return (
    <>
      <div className={classes.enable_width}>
        <div className={classes.btnRight}>
          <CustomButton className={classes.crossBtn} isX>
            <Close_Icon onClick={onClose} />
          </CustomButton>
        </div>
        <div>
          <Location className={`${classes.markerAlign}`} />
          <Text
            size={25}
            color={color.primary_palette.black}
            family="avenir_roman"
            className={classes.publishTxtAlign}
            transform="uppercase"
          >
            Pay & Publish
          </Text>
        </div>
        {notPublicPortfolio.map((portData) => {
          return (
            <div className={classes.radioAlign}>
              <CustomRadioButton
                label={get(portData, "name")}
                labelPlacement="end"
                name={get(portData, "name")}
                value={get(portData, "name")}
                checked={nameVal == get(portData, "name")}
                onChange={handleInputChange(portData)}
                className={classes.product_radio}
              />
            </div>
          );
        })}
        <div></div>
        <div className={classes.textSpacing}>
          <CustomButton className={classes.goBtn} onClick={onGoClickHandle}>
            {strings.settings.titles.go}
          </CustomButton>
        </div>
      </div>
    </>
  );
}

export default EnableSettingStyles(MarketListModal);
