import { withStyles } from "@material-ui/core/styles";
import tradework_theme, { pxToRem, color } from "../../../utilities/themes";

const styles = withStyles({
  enable_width: {
    width: pxToRem(418),
    border: `solid ${pxToRem(2)} ${color.primary_palette.free_purple}`,
    backgroundColor: color.secondary_palette.blues.modal_blue,
    margin: "0 auto",
    textAlign: "center",
    "&:focus": {
      outline: "none",
    },
  },
  markerAlign: {
    height: pxToRem(58),
    width: pxToRem(41),
    marginTop: pxToRem(-36),
  },
  txtCenter: {
    textAlign: "center",
  },
  btnRight: {
    textAlign: "right",
  },
  crossBtn: {
    minWidth: pxToRem(30),
    padding: pxToRem(16),
    "& .MuiSvgIcon-root": {
      fontSize: pxToRem(15),
    },
  },
  padding_top_10: {
    paddingTop: pxToRem(10),
  },
  textSpacing: {
    padding: `${pxToRem(0)} ${pxToRem(75)} ${pxToRem(20)} ${pxToRem(75)}`,
  },
  publishTxtAlign: {
    marginTop: pxToRem(9),
    marginBottom: pxToRem(18),
  },
  mOnItBtn: {
    width: pxToRem(134),
    height: pxToRem(25),
    borderRadius: pxToRem(12.5),
    border: `solid ${pxToRem(0.8)} ${color.primary_palette.franklin_purple}`,
    backgroundColor: color.primary_palette.white,
    ...tradework_theme.typography.styles.avenir_sb,
    color: `${color.primary_palette.franklin_purple} !important`,
    marginTop: pxToRem(20),
  },
  goBtn: {
    width: pxToRem(83),
    height: pxToRem(25),
    borderRadius: pxToRem(12.5),
    border: `solid ${pxToRem(0.8)} ${color.primary_palette.franklin_purple}`,
    backgroundColor: color.primary_palette.white,
    ...tradework_theme.typography.styles.avenir_sb,
    color: `${color.primary_palette.franklin_purple} !important`,
    marginTop: pxToRem(20),
  },
  product_radio: {
    "& .MuiFormControlLabel-label": {
      ...tradework_theme.typography.styles.avenir_sb,
      fontSize: pxToRem(16),
      color: color.primary_palette.black,
      textTransform: "uppercase",
    },
    "& .MuiSvgIcon-root": {
      fontSize: pxToRem(20),
    },
  },
  radioAlign: {
    marginLeft: pxToRem(60),
    textAlign: "left",
  },
});

export default styles;
