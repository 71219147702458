import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";
import { makeStyles, withStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    "& :hover": {},
  },
}));

function FilledArrow(props) {
  const classes = useStyles();
  function styledIcon() {
    return (
      <span className={classes.root}>
        <SvgIcon {...props} viewBox="0 0 26 15">
          <defs>
            <path
              d="M16.873 0l.425.469C19.734 3.106 22.64 5.446 26 7.499l-.614.395c-3.244 2.12-6.031 4.519-8.513 7.106l.525-4.375-.883-.055c-5.3-.295-10.677.028-16.515.532L3.525 7.5 0 3.762l1.063.079c5.646.417 10.957.762 16.335.535L16.873 0z"
              id="prefix__a"
            />
          </defs>
          <use fill="#C0202A" xlinkHref="#prefix__a" fillRule="evenodd" />
        </SvgIcon>
      </span>
    );
  }

  /**
   * Custom styling the colors
   */
  const Custom_FilledArrow = withStyles({
    root: {
      "& > svg": {
        // fontSize: '1.2rem',
      },
      "& :hover": {},
    },
  })(styledIcon);
  return (
    <>
      <Custom_FilledArrow {...props} />
    </>
  );
}

export default FilledArrow;
