import api, { lookupApi } from ".";
import { URI, LOOKUP_URI } from "../../constants";

export default {
  getAllRegions: () => {
    return api.get(URI.GET_REGIONS);
  },
  getAllCategories: () => {
    return api.get(URI.GET_CATEGORIES);
  },
  getProjectSelection: () => {
    return api.get(URI.PROJECT_SELECTION);
  },
  getProjectImageSelection: () => {
    return api.get(URI.PROJECT_IMAGE_SELECTION);
  },
  saveProfileImageData: () => {
    return api.post(URI.SAVE_PROFILE_IMAGES);
  },
  saveElevatorPitchData: (data) => {
    return api.post(URI.SAVE_ELEVATOR_PITCH, data);
  },
  saveProjectData: (data) => {
    return api.post(URI.CREATE_UPDATE_PROJECT, data);
  },
  saveProductData: (data) => {
    return api.post(URI.CREATE_PRODUCT_PUBLICATION, data);
  },
  getProductsAndPublicationData: (id) => {
    return api.get(`${URI.GET_PRODUCTS_PUBLICATION_DATA}${id}`);
  },
  deleteProductAndPublications: (id) => {
    return api.delete(`${URI.DELETE_PRODUCT_PUBLICATION}${id}`);
  },
  updateProductAndPublications: (data) => {
    return api.put(URI.UPDATE_PRODUCTS_PUBLICATIONS, data);
  },
  getProjectData: () => {
    return api.get(URI.GET_ALL_PROJECT_DATA);
  },
  getProjectsByPortfolio: (id) => {
    return api.get(`${URI.GET_PROJECTS_DATA_BY_PORTFOLIO}${id}`);
  },
  getPortfolioData: (id) => {
    return api.get(`${URI.PORTFOLIO_DATA}${id}`);
  },
  getPortfolioDataBySlug: (market, slug) => {
    return api.get(`${URI.PORTFOLIO_DATA_BY_SLUG}${market}/${slug}`);
  },
  getVignetteData: (data) => {
    return api.post(URI.CREATE_UPDATE_VIGNETTE, data);
  },
  deleteVignette: (id) => {
    return api.delete(`${URI.DELETE_VIGNETTE}${id}`);
  },
  getProjectIndividualData: (id) => {
    return api.get(`${URI.GET_PROJECT_DATA}${id}`);
  },
  deleteProject: (id) => {
    return api.delete(`${URI.DELETE_PROJECT}${id}`);
  },
  sortProject: (data) => {
    return api.post(URI.PROJECT_SORT, data);
  },
  sortVignettes: (data) => {
    return api.post(URI.SORT_VIGNETTES, data);
  },
  sortProducts: (data) => {
    return api.post(URI.PRODUCT_SORT, data);
  },
  instagramVerificationCode: (data) => {
    return api.post(URI.INSTA_VERIFICATION_CODE, data);
  },
  getInstagramFeeds: () => {
    return api.get(URI.GET_INSTA_FEEDS);
  },
  saveBusinessData: (data) => {
    return api.post(URI.ASSIGN_PORTFOLIO_BUSINESSCARD, data);
  },
  getCoverProfileData: (id) => {
    return api.get(`${URI.GET_COVER_PROFILE_PHOTOS}${id}`);
  },
  getCompaniesByUser: () => {
    return api.get(URI.COMPANY_BY_USER);
  },
  getUserData: () => {
    return api.get(URI.GET_PROFILE_DATA);
  },
  updateUserData: (data) => {
    return api.post(URI.UPDATE_PROFILE_DATA, data);
  },
  getCompanyProfileData: () => {
    return api.get(URI.GET_COMPANY_PROFILE_DATA);
  },
  getTrades: () => {
    return api.get(URI.TRADES_LIST);
  },
  getAccolades: () => {
    return lookupApi.get(LOOKUP_URI.GET_ACCOLADES);
  },
  getConfigData: () => {
    return lookupApi.get(LOOKUP_URI.GET_CONFIG);
  },
  getVibes: () => {
    return lookupApi.get(LOOKUP_URI.GET_VIBES);
  },
  getClientel: () => {
    return lookupApi.get(LOOKUP_URI.GET_CLIENTEL);
  },
  getVenue: () => {
    return lookupApi.get(LOOKUP_URI.GET_VENUE);
  },
  updateCompanyInfo: (id, data) => {
    return api.post(`${URI.UPDATE_COMPANY_INFO}${id}`, data);
  },
  removeCompanyAdmin: (data) => {
    return lookupApi.post(LOOKUP_URI.REMOVE_COMPANY_ADMIN, data);
  },
  getCompanyList: () => {
    return api.get(URI.GET_COMPANIES);
  },
  getPortfolioList: () => {
    return api.get(URI.GET_PORTFOLIOS);
  },
  portfolioPayment: (data) => {
    return api.post(URI.PAYMENT_PORTFOLIO, data);
  },
  printPortfolioInvoice: (data) => {
    return api.post(URI.PRINT_PORTFOLIO_INVOICE, data);
  },
  saveConnectData: (data) => {
    return api.post(URI.CONNECT_PORTFOLIO, data);
  },
  savePaypalTransactions: (data) => {
    return api.post(URI.PAYPAL_PAYMENT, data);
  },
  sendInviteMails: (data) => {
    return api.post(URI.SHARE_INVITE, data);
  },
  deleteProfileImage: (portfolioId, imageType) => {
    return api.get(`${URI.DELETE_PORTFOLIO_IMAGES}${portfolioId}/${imageType}`);
  },
  actAsUser: (data) => {
    return api.post(URI.ACT_AS_USER, data);
  },
};
