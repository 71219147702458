const createImage = (url) =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener("load", () => resolve(image));
    image.addEventListener("error", (error) => reject(error));
    image.setAttribute("crossOrigin", "anonymous"); // needed to avoid cross-origin issues on CodeSandbox
    image.src = url;
  });

function getRadianAngle(degreeValue) {
  return (degreeValue * Math.PI) / 180;
}

/**
 * This function was adapted from the one in the ReadMe of https://github.com/DominicTobias/react-image-crop
 * @param {File} image - Image File url
 * @param {Object} pixelCrop - pixelCrop Object provided by react-easy-crop
 * @param {number} rotation - optional rotation parameter
 */
export default async function getCroppedImg(imageSrc, pixelCrop, colorValue) {
  const image = await createImage(imageSrc);
  const canvas = document.createElement("canvas");
  canvas.width = pixelCrop.width;
  canvas.height = pixelCrop.height;
  const ctx = canvas.getContext("2d");
  ctx.fillStyle = colorValue;
  ctx.fillRect(0, 0, canvas.width, canvas.height);
  ctx.drawImage(
    image,
    pixelCrop.x,
    0,
    imageSrc.width - pixelCrop.x,
    pixelCrop.y,
    0,
    0,
    pixelCrop.width,
    pixelCrop.height
  );

  // As Base64 string
  // return canvas.toDataURL('image/jpeg');

  // As a blob
  return new Promise((resolve) => {
    canvas.toBlob((file) => {
      // resolve(URL.createObjectURL(file));
      resolve(file);
    }, "image/jpeg");
  });
}

export function computeCroppedArea(
  crop,
  mediaSize,
  cropSize,
  aspect,
  zoom,
  rotation = 0,
  restrictPosition = true
) {
  // if the media is rotated by the user, we cannot limit the position anymore
  // as it might need to be negative.
  const noOp = (_max, value) => {
    return value;
  };
  const limitArea = (max, value) => {
    return Math.min(max, Math.max(0, value));
  };
  const limitAreaFn = restrictPosition && rotation === 0 ? limitArea : noOp;
  const croppedAreaPercentages = {
    x: limitAreaFn(
      100,
      (((mediaSize.width - cropSize.width / zoom) / 2 - crop.x / zoom) /
        mediaSize.width) *
        100
    ),
    y: limitAreaFn(
      100,
      (((mediaSize.height - cropSize.height / zoom) / 2 - crop.y / zoom) /
        mediaSize.height) *
        100
    ),
    width: limitAreaFn(100, ((cropSize.width / mediaSize.width) * 100) / zoom),
    height: limitAreaFn(
      100,
      ((cropSize.height / mediaSize.height) * 100) / zoom
    ),
  };

  // we compute the pixels size naively
  const widthInPixels = Math.round(
    limitAreaFn(
      mediaSize.naturalWidth,
      (croppedAreaPercentages.width * mediaSize.naturalWidth) / 100
    )
  );
  const heightInPixels = Math.round(
    limitAreaFn(
      mediaSize.naturalHeight,
      (croppedAreaPercentages.height * mediaSize.naturalHeight) / 100
    )
  );
  const isImgWiderThanHigh =
    mediaSize.naturalWidth >= mediaSize.naturalHeight * aspect;

  // then we ensure the width and height exactly match the aspect (to avoid rounding approximations)
  // if the media is wider than high, when zoom is 0, the crop height will be equals to iamge height
  // thus we want to compute the width from the height and aspect for accuracy.
  // Otherwise, we compute the height from width and aspect.
  const sizePixels = isImgWiderThanHigh
    ? {
        width: Math.round(heightInPixels * aspect),
        height: heightInPixels,
      }
    : {
        width: widthInPixels,
        height: Math.round(widthInPixels / aspect),
      };
  const croppedAreaPixels = {
    ...sizePixels,
    x: Math.round(
      limitAreaFn(
        mediaSize.naturalWidth - sizePixels.width,
        (croppedAreaPercentages.x * mediaSize.naturalWidth) / 100
      )
    ),
    y: Math.round(
      limitAreaFn(
        mediaSize.naturalHeight - sizePixels.height,
        (croppedAreaPercentages.y * mediaSize.naturalHeight) / 100
      )
    ),
  };
  return { croppedAreaPercentages, croppedAreaPixels };
}
