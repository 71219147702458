import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import strings from "../../utilities/strings";
import { Text } from "../common/ui_kit";
import { color } from "../../utilities/themes";
import { get } from "lodash";
import MobilePorfolioStyles from "./Styles/MobileportfioloStyles";

import { ProfileActions } from "../../redux/actions";

import MobileFlagShip from "./MobileFlagShip";
import MobileOptionsFooter from "./MobileOptionsFooter";

function MobileProjects(props) {
  const { classes } = props;
  const dispatch = useDispatch();

  const portfolioData = useSelector((resData) => resData.Profile.portfolioData);

  useEffect(() => {
    dispatch(
      ProfileActions.getPortfolioData(localStorage.getItem("portfolioId"))
    );
  }, []);

  const returnFlagShipDataPublic = () => {
    const flagshipsData =
      portfolioData && portfolioData.projects
        ? [...portfolioData.projects]
        : [];
    return flagshipsData.slice(0, 6);
  };

  const flagshipProjectDataPublic = returnFlagShipDataPublic();

  return (
    <>
      <div className={`${classes.paddingTop25} `}>
        <div className={classes.headerAlign}>
          <img src="/assets/images/fr_header.PNG" />
        </div>
        <div className={`${classes.txtCenter} ${classes.paddingTop25}`}>
          <Text
            size={14}
            color={color.primary_palette.black}
            family="TrajanPro3"
          >
            {get(portfolioData, "name", "")}
          </Text>
          <img
            src="/assets/images/Horizontal_DB.jpg"
            className={classes.horizontal_DB}
            alt=""
          />
        </div>
        <Text
          size={20}
          color={color.primary_palette.black}
          family="gillsans_light"
          transform="uppercase"
          className={`${classes.txtCenter}`}
        >
          {strings.portfolio_userView.titles.all_projects}
        </Text>
        {flagshipProjectDataPublic.map((project, index) => {
          const align = (index + 1) % 2 === 0 ? "label_left" : "label_right";
          return <MobileFlagShip align={align} project={project} idx={index} />;
        })}
        <div className={classes.marginTop38}>
          <MobileOptionsFooter />
        </div>
        <div className={classes.footerAlign}>
          <img src="/assets/images/fr_footer.PNG" />
        </div>
      </div>
    </>
  );
}

export default MobilePorfolioStyles(MobileProjects);
