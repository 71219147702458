import { withStyles } from "@material-ui/core/styles";
import tradework_theme, { pxToRem, color } from "../../../utilities/themes";

const styles = withStyles({
  recover_credentials: {
    width: pxToRem(411),
    border: `solid ${pxToRem(2)} ${color.primary_palette.franklin_purple}`,
    backgroundColor: color.secondary_palette.blues.modal_blue,
    "&:focus": {
      outline: "none",
    },
  },
  textCenter: {
    textAlign: "center",
  },
  password_width: {
    width: pxToRem(208),
    marginTop: pxToRem(19),
    "& .MuiFormLabel-root": {
      ...tradework_theme.typography.styles.gillsans_light,
      fontSize: pxToRem(18),
      marginTop: pxToRem(-5),
    },
    "& .MuiFormLabel-root.Mui-focused": {
      fontSize: pxToRem(10),
      ...tradework_theme.typography.styles.gillsans_r,
      color: color.primary_palette.franklin_purple,
    },
    "& .Mui-error": {
      color: color.primary_palette.christmas_red,
    },
    "& .MuiInputBase-input": {
      ...tradework_theme.typography.styles.gillsans_light,
      color: color.primary_palette.black,
    },
    "& .MuiInputLabel-shrink": {
      fontSize: pxToRem(10),
      ...tradework_theme.typography.styles.gillsans_r,
      color: color.primary_palette.franklin_purple,
      marginTop: pxToRem(9),
    },
    "& .MuiInputAdornment-root": {
      "&:focus": {
        outline: "none",
      },
    },
  },
  textRight: {
    textAlign: "right",
  },
  crossBtn: {
    minWidth: pxToRem(30),
    padding: pxToRem(16),
    "& .MuiSvgIcon-root": {
      fontSize: pxToRem(15),
    },
  },
  ribbon_icon: {
    fontSize: pxToRem(57),
  },
  txtCapital: {
    textTransform: "uppercase",
  },
  emai_username: {
    width: pxToRem(200),
    marginTop: pxToRem(14),
    "& .MuiFormLabel-root": {
      ...tradework_theme.typography.styles.gillsans_light,
      fontSize: pxToRem(14),
    },
    "& .MuiFormLabel-root.Mui-focused": {
      fontSize: pxToRem(10),
      ...tradework_theme.typography.styles.gillsans_r,
      color: `${color.primary_palette.franklin_purple}`,
    },
    "& .Mui-error": {
      color: color.primary_palette.christmas_red,
      fontSize: pxToRem(10),
    },
    "& .MuiInputBase-input": {
      ...tradework_theme.typography.styles.gillsans_light,
      color: color.primary_palette.black,
      fontSize: pxToRem(18),
    },
    "& .MuiInputLabel-shrink": {
      fontSize: pxToRem(10),
      marginTop: pxToRem(9),
      ...tradework_theme.typography.styles.gillsans_r,
    },
  },
  set_new_password: {
    width: pxToRem(242),
    height: pxToRem(30),
    borderRadius: pxToRem(20),
    border: `solid ${pxToRem(1)} ${color.form_colors.blueberry_purple}`,
    backgroundColor: color.primary_palette.white,
    fontSize: pxToRem(14),
    color: `${color.form_colors.royal_purple_1} !important`,
    ...tradework_theme.typography.styles.gillsans_sb,
    marginTop: pxToRem(15),
    marginBottom: pxToRem(12),
  },
  recover: {
    fontSize: pxToRem(14),
    color: color.primary_palette.franklin_purple,
    ...tradework_theme.typography.styles.gillsans_sb,
  },
  sign_in: {
    width: "50%",
    flexGrow: 1,
    textAlign: "center",
    paddingLeft: pxToRem(20),
  },
  join_here: {
    width: "50%",
    flexGrow: 1,
    textAlign: "center",
  },
  footer_spacing: {
    paddingTop: pxToRem(21),
    paddingBottom: pxToRem(40),
  },
  margin_left_3: {
    marginLeft: pxToRem(3),
  },
  recover_icon: {
    height: pxToRem(101),
  },
  padding_top_8: {
    paddingTop: pxToRem(8),
  },
  greatBtn: {
    width: pxToRem(138),
    height: pxToRem(30),
    borderRadius: pxToRem(20),
    border: `solid ${pxToRem(1)} ${color.form_colors.blueberry_purple}`,
    backgroundColor: color.primary_palette.white,
    fontSize: pxToRem(14),
    color: `${color.form_colors.royal_purple_1} !important`,
    ...tradework_theme.typography.styles.gillsans_sb,
    marginTop: pxToRem(20),
    marginBottom: pxToRem(20),
  },
  againBtn: {
    width: pxToRem(192),
    height: pxToRem(30),
    borderRadius: pxToRem(20),
    border: `solid ${pxToRem(1)} ${color.form_colors.blueberry_purple}`,
    backgroundColor: color.primary_palette.white,
    fontSize: pxToRem(14),
    color: `${color.form_colors.royal_purple_1} !important`,
    ...tradework_theme.typography.styles.gillsans_sb,
    marginTop: pxToRem(20),
    marginBottom: pxToRem(20),
  },
  padding_r_l_60: {
    paddingRight: pxToRem(60),
    paddingLeft: pxToRem(60),
  },
  sign_inBtn: {
    width: pxToRem(179),
    height: pxToRem(30),
    borderRadius: pxToRem(20),
    border: `solid ${pxToRem(1)} ${color.form_colors.blueberry_purple}`,
    backgroundColor: color.primary_palette.white,
    fontSize: pxToRem(14),
    color: `${color.form_colors.royal_purple_1} !important`,
    ...tradework_theme.typography.styles.gillsans_sb,
    marginTop: pxToRem(20),
    marginBottom: pxToRem(20),
  },
});

export default styles;
