import { withStyles } from "@material-ui/core/styles";
import tradework_theme, { pxToRem, color } from "../../../utilities/themes";

const styles = withStyles({
  copperModal: {
    width: pxToRem(657),
    border: `solid ${pxToRem(2)} ${color.primary_palette.free_purple}`,
    backgroundColor: color.secondary_palette.blues.modal_blue,
  },
  btnRight: {
    textAlign: "right",
  },
  crossBtn: {
    minWidth: pxToRem(30),
    padding: pxToRem(16),
    "& .MuiSvgIcon-root": {
      fontSize: pxToRem(15),
    },
  },
  textCenter: {
    textAlign: "center",
  },
  copperModalContent: {
    padding: `${pxToRem(0)} ${pxToRem(50)}`,
  },
  vignette_link: {
    flexGrow: 1,
    width: "35%",
  },
  vignette_link_radio: {
    flexGrow: 1,
    width: "65%",
  },
  inputField: {
    ...tradework_theme.typography.styles.avenir_light,
    marginLeft: pxToRem(0),
    "&:hover": {
      "&:not(.Mui-disabled)": {
        "&:after": {
          borderBottom: "transparent !important",
        },
        "&:before": {
          borderBottom: "transparent !important",
        },
      },
    },
    "&:before": {
      borderBottom: "0 !important",
    },
    "& .MuiInputBase-root": {
      width: "100%",
      border: "none",
      "&:hover": {
        border: "none !important",
      },
      "&:active": {
        border: "none !important",
      },
      "&:focus": {
        border: "none !important",
      },
      "&:focus-within": {
        border: "none !important",
      },
    },
    "& .MuiSvgIcon-root": {
      display: "none",
    },

    "& .MuiInputBase-input": {
      "&::placeholder": {
        fontSize: pxToRem(14),
        ...tradework_theme.typography.styles.avenir_light,
        color: color.secondary_palette.grays.shadow_gray,
        // fontStyle: "oblique",
        opacity: 1,
      },
    },
  },
  bodereRed: {
    "& .Mui-error": {
      borderBottom: `solid ${pxToRem(1)} ${
        color.primary_palette.christmas_red
      } !important`,
      border: `${pxToRem(0)} !important`,
    },
  },
  borderBlack: {
    borderBottom: `solid ${pxToRem(1)} ${
      color.secondary_palette.grays.shadow_gray
    }`,
  },
  linkRadio: {
    "& .MuiTypography-root ": {
      fontSize: pxToRem(15),
      color: color.secondary_palette.grays.drag_text,
      ...tradework_theme.typography.styles.avenir_light,
    },
    "& .MuiSvgIcon-root": {
      fontSize: pxToRem(14),
    },
  },
  linkRadioRed: {
    "& .MuiTypography-root ": {
      fontSize: pxToRem(15),
      color: color.primary_palette.christmas_red,
      ...tradework_theme.typography.styles.avenir_light,
    },
    "& .MuiSvgIcon-root": {
      fontSize: pxToRem(14),
    },
  },
  vignette_linkAlign: {
    justifyContent: "center",
    alignItems: "center",
    alignSelf: "center",
  },
  vignette_link_input: {
    alignSelf: "flex-end",
  },
  save_btn: {
    marginTop: pxToRem(22),
    marginBottom: pxToRem(22),
    width: pxToRem(208),
    height: pxToRem(35),
    borderRadius: pxToRem(22),
    border: `solid ${pxToRem(1.6)} ${color.primary_palette.franklin_purple}`,
    backgroundColor: color.primary_palette.white,
    "& .MuiButton-label": {
      ...tradework_theme.typography.styles.avenir_sb,
      fontSize: pxToRem(16.3),
      color: color.primary_palette.franklin_purple,
    },
  },
  save_btn_disable: {
    marginTop: pxToRem(22),
    marginBottom: pxToRem(22),
    width: pxToRem(208),
    height: pxToRem(35),
    borderRadius: pxToRem(22),
    backgroundColor: color.primary_palette.white,
    border: `solid ${pxToRem(1.6)} ${color.primary_palette.disable_border}`,
    "& .MuiButton-label": {
      ...tradework_theme.typography.styles.avenir_sb,
      fontSize: pxToRem(16.3),
    },
  },
  justifyCenter: {
    justifyContent: "center",
  },
  cropperDiv: {
    position: "relative",
    width: pxToRem(306),
    height: pxToRem(170),
    margin: "0 auto",
    border: `solid ${pxToRem(2)} ${color.primary_palette.free_purple}`,
    marginTop: pxToRem(16),
    marginBottom: pxToRem(18),
    "& .reactEasyCrop_CropArea": {
      border: `solid ${pxToRem(2)} ${
        color.secondary_palette.blues.click_blue
      } !important`,
    },
  },
  slider_width: {
    width: pxToRem(316),
    margin: "0 auto",
    "& .MuiSlider-thumb": {
      width: pxToRem(22),
      height: pxToRem(22),
      marginTop: pxToRem(-10),
    },
    "& .MuiSlider-track": {
      backgroundColor: color.secondary_palette.grays.shadow_gray,
    },
    "& .MuiSlider-rail": {
      backgroundColor: color.secondary_palette.grays.shadow_gray,
      opacity: 1,
    },
  },
  margin_top_20: {
    marginTop: pxToRem(-20),
  },
  margin_left_12: {
    marginLeft: pxToRem(-12),
  },
  choose_black: {
    width: pxToRem(26),
    height: pxToRem(26),
    border: `solid ${pxToRem(0.5)} ${color.primary_palette.black}`,
    backgroundColor: color.primary_palette.black,
    margin: `${pxToRem(12)} ${pxToRem(12)}`,
    cursor: "pointer",
  },
  choose_gray: {
    width: pxToRem(26),
    height: pxToRem(26),
    border: `solid ${pxToRem(1)} ${
      color.secondary_palette.grays.background_gray
    }`,
    backgroundColor: color.secondary_palette.grays.portfolio_view_gray,
    margin: `${pxToRem(12)} ${pxToRem(12)}`,
    cursor: "pointer",
  },
  choose_white: {
    width: pxToRem(26),
    height: pxToRem(26),
    border: `solid ${pxToRem(1.5)} ${color.primary_palette.highlight_purple}`,
    backgroundColor: color.primary_palette.white,
    margin: `${pxToRem(12)} ${pxToRem(12)}`,
    cursor: "pointer",
  },
  choose_bg_txt: {
    alignSelf: "center",
  },
  choose_gray_active: {
    width: pxToRem(26),
    height: pxToRem(26),
    border: `solid ${pxToRem(2)} ${color.primary_palette.highlight_purple}`,
    backgroundColor: color.secondary_palette.grays.portfolio_view_gray,
    margin: `${pxToRem(12)} ${pxToRem(12)}`,
    cursor: "pointer",
  },
  choose_white_active: {
    width: pxToRem(26),
    height: pxToRem(26),
    border: `solid ${pxToRem(2)} ${color.primary_palette.highlight_purple}`,
    backgroundColor: color.primary_palette.white,
    margin: `${pxToRem(12)} ${pxToRem(12)}`,
    cursor: "pointer",
  },
  choose_black_active: {
    width: pxToRem(26),
    height: pxToRem(26),
    border: `solid ${pxToRem(2)} ${color.primary_palette.highlight_purple}`,
    backgroundColor: color.primary_palette.black,
    margin: `${pxToRem(12)} ${pxToRem(12)}`,
    cursor: "pointer",
  },
});

export default styles;
