const types = {
  REQUEST: "REQUEST",
  SUCCESS: "SUCCESS",
  FAILURE: "FAILURE",
  HORSEREQUEST: "HORSEREQUEST",
  HORSESUCCESS: "HORSESUCCESS",
  HORSEFAILURE: "HORSEFAILURE",
};

export default types;
