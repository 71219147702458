import { withStyles } from "@material-ui/core/styles";
import { color, pxToRem } from "../../../utilities/themes";

const styles = withStyles({
  input_circle: {
    display: "none",
  },
  textCenter: {
    textAlign: "center",
    justifyContent: "center",
    alignSelf: "center",
    flexGrow: 1,
    width: "50%",
  },
  addImage: {
    width: "100%",
    position: "relative",
    display: "block",
  },
  addImage_pub: {
    width: "100%",
    position: "relative",
  },
  img_block: {
    textAlign: "center",
  },
  errorMessage: {
    color: color.primary_palette.christmas_red,
    position: "absolute",
    fontSize: pxToRem(13),
  },
  icons_blk: {
    position: "absolute",
    bottom: 0,
    right: pxToRem(7),
  },
  trash_can: {
    display: "block",
    width: pxToRem(25),
    paddingBottom: pxToRem(7),
    cursor: "pointer",
  },
  default_image_add: {
    width: pxToRem(115),
    height: pxToRem(138),
    textAlign: "center",
    border: `solid ${pxToRem(0.9)} ${
      color.secondary_palette.grays.shadow_gray
    }`,
    background: color.primary_palette.white,
    position: "relative",
    cursor: " pointer",
    "& p": {
      textTransform: "uppercase",
    },
    "&:hover": {
      border: `solid ${pxToRem(0.9)} ${color.primary_palette.franklin_purple}`,
      background: color.primary_palette.white,
      "& .addHover_effect": {
        "& ellipse": {
          fill: color.green_world,
        },
      },
      "& p": {
        color: color.secondary_palette.grays.medium_gray,
      },
    },
  },
  addHover: {
    width: pxToRem(58),
    height: pxToRem(59),
    marginTop: pxToRem(30),
    border: `solid ${pxToRem(2)} ${color.primary_palette.white}`,
    borderRadius: "100%",
  },
  errorMessageProjects: {
    color: color.primary_palette.christmas_red,
  },
});

export default styles;
