import api from ".";
import { URI } from "../../constants";

export default {
  recover_link: (data) => {
    return api.post(URI.RECOVER, data);
  },
  setNewPassword: (data) => {
    return api.post(URI.RESET_PASSWORD, data);
  },
};
