import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { map, get, find, filter } from "lodash";

import MenuItem from "@material-ui/core/MenuItem";

import OfficeLocationStyles from "./styles/officeLocationStyles";
import Row from "../common/ui_kit/row";
import Text from "../common/ui_kit/text";
import strings from "../../utilities/strings";
import Lil_Plus_filled from "../data_display/icons/Lil_Plus_filled";
import { color } from "../../utilities/themes";
import CustomButton from "../navigations/custom_buttons";
import CustomSelect from "../common/ui_kit/custom_select";
import Chevron_Down_Icon from "../data_display/icons/Arrow_Chevron_Down";
import BusinessPrefilled from "../../components/business_card/businessCard_prefilled";
import CustomScrollbars from "../data_display/custom_scroll";
import CustomModal from "../inputs/custom_modal";
import AddAnotherCard from "../business_card/anotherCard";
import Trash_can from "../data_display/icons/Trash_can";
import DeleteOfficeLocation from "../modals/deleteOfficeLoation";

import { SettingsActions, LookUpActions } from "../../redux/actions";
import CustomCheckbox from "../inputs/custom_checkbox";
import CustomTextField from "../inputs/custom_textfields";
import Element_Required_Icon from "../data_display/icons/ElementRequiered";
import Styled_cross from "../data_display/icons/styled_cross";

function OfficeLocations(props) {
  const { classes } = props;
  const [state, setState] = useState({
    openAddNewModal: false,
    openDeleteOffice: false,
    marketMandatoryError: false,
    notPublicError: false,
  });
  const [limitError, setLimitError] = useState(false);

  const {
    openAddNewModal,
    openDeleteOffice,
    businessCardId,
    marketMandatoryError,
    notPublicError,
    region,
    category,
  } = state;

  const dispatch = useDispatch();

  const addressData = useSelector(
    (resData) => resData.Settings.CompanyLocationsData
  );
  const lookUpData = useSelector((resData) => resData.LookUp.LookUpData);

  //API
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    dispatch(
      SettingsActions.getCompanyPortfolioData(localStorage.getItem("companyId"))
    );
    dispatch(
      SettingsActions.getCompanyLocationsData(localStorage.getItem("companyId"))
    );
    dispatch(LookUpActions.getLookUpData());
  };

  const handleBusinessModal = () => {
    setState({
      ...state,
      openAddNewModal: !openAddNewModal,
    });
  };

  // Method responsible to return categorized menu items
  const renderCategorizedMenu = (menuData, type, businessCard) => {
    // list of color codes to be appied for category tiles based on index
    const colorCodes = get(lookUpData, "configData.dropdownColorCodes", []);
    // fetching category names
    const groups = Object.keys(menuData)
      .filter((each) => each !== "noParent")
      .sort();
    // pushing nonParent items to array
    let menuItems = menuData.noParent
      ? menuData.noParent.map((each) => each)
      : [];

    groups.map((eachGroup, index) => {
      // interating group titles and pushint to menu item array
      let subGroupItems = [{ name: eachGroup, color: colorCodes[index] }];
      menuData[eachGroup].map((eachGroupItem) => {
        // iterating subgroup items
        subGroupItems.push(eachGroupItem);
      });
      // concatinating menuItems which are returned and categorized items
      menuItems = [...menuItems, ...subGroupItems];
      return null;
    });

    // mapping menuItems (objects) and returning JSX
    return menuItems.map((eachItem) => {
      return (
        <MenuItem
          style={{ background: eachItem.color }}
          onClick={
            !eachItem.color &&
            handleMarketSelect(businessCard, "category", eachItem.name)
          }
          className={eachItem.color && classes.textCapital}
        >
          {eachItem.name}
        </MenuItem>
      );
    });
  };

  const reloadOfficeLocations = () => {
    fetchData();
  };

  const handleOfficeModal = (businessCard) => () => {
    setState({
      ...state,
      openDeleteOffice: !openDeleteOffice,
      businessCardId: get(businessCard, "_id", ""),
    });
  };

  const handleInputChange = (businessCard) => (e) => {
    const { name, checked, value } = e.target;
    if (name === "isHeadOffice" || name === "isBillingAddress") {
      addressData.businessCards.map((businessCardData) => {
        businessCardData[name] = false;
      });
      businessCard[name] = checked;
    } else if (name === "isPublicDisplay") {
      if (get(businessCard, "displayedWhere").includes("Not Public")) {
        addressData.businessCards.map((businessCard) => {
          businessCard.notPublicError = true;
        });
        setState({ ...state });
        return;
      } else {
        addressData.businessCards.map((businessCard) => {
          businessCard.notPublicError = false;
        });
        businessCard[name] = checked;
      }
    } else {
      businessCard[name] = value;
    }
    submitBusinessCard(businessCard)();
    setState({ ...state });
  };

  const deleteRegion = (businessCard, region, categoryName) => () => {
    const dataToSubmit = {
      market: region,
      category: categoryName,
    };
    dispatch(
      SettingsActions.deleteBusinessMarketCategory(
        businessCard._id,
        dataToSubmit,
        (res) => {
          if (!res.error.error) {
            if (!get(res, "data.categoryInPortfolio")) {
              const { marketsAndServices } = businessCard;
              businessCard.errorMsg = "";
              businessCard.errorMsgNext = "";
              setLimitError(false);
              const foundRegion = find(marketsAndServices, { region });
              if (foundRegion) {
                foundRegion.category = foundRegion.category.filter(
                  (each) => each !== categoryName
                );
              }
              if (foundRegion.category.length === 0) {
                businessCard.marketsAndServices =
                  businessCard.marketsAndServices.filter(
                    (each) => each.region !== region
                  );
              }
              setState({ ...state });
            } else {
              if (businessCard) {
                businessCard.errorMsg = `You have a Portfolio called ${get(
                  res,
                  "data.categoryPortfolioName"
                )} with ${categoryName} provided by the ${
                  businessCard.nickname
                } Offices location.`;
                businessCard.errorMsgNext = `Thus, you cannot remove ${categoryName} here, unless you remove it first in the ${get(
                  res,
                  "data.categoryPortfolioName"
                )} Portfolio.`;
                setState({ ...state });
              }
            }
          } else {
            setState({ ...state, errorMessage: res.error.message.message });
          }
        }
      )
    );
  };

  const handleDropdownSelect = (businessCard, name, value, del) => (e) => {
    e.preventDefault();
    e.stopPropagation();
    const checkForDuplicate = filter(
      businessCard[name],
      (each) => each === value
    )[0];
    if (!checkForDuplicate) {
      businessCard[name] = businessCard[name] || [];
      businessCard[name] = [...businessCard[name], value];
    } else {
      if (!del) {
        return;
      }
      const updatedData = filter(businessCard[name], (each) => {
        return each !== value;
      });
      businessCard[name] = [...updatedData];
    }
    setState({ ...state });
  };

  const handleMarketSelect = (businessCard, name, value) => (e) => {
    e.preventDefault();
    e.stopPropagation();
    businessCard[name] = value;
    setState({ ...state });
  };

  const handleAddRegionClick = (businessCard) => () => {
    setLimitError(false);
    const { category, region } = businessCard;
    if (!category || !region) {
      return;
    }
    businessCard.marketsAndServices = businessCard.marketsAndServices || [];
    let foundRegion = find(businessCard.marketsAndServices, { region: region });
    if (foundRegion) {
      let foundCategory = filter(
        foundRegion.category,
        (each) => each === category
      )[0];
      if (foundCategory) {
        return;
      }
      foundRegion.category = foundRegion.category || [];
      if (foundRegion.category.length >= 4) {
        setLimitError(true);
        return;
      }
      foundRegion.category = [...foundRegion.category, category];
      foundRegion.marketsAndServicesId = get(foundRegion, "_id", "");
    } else {
      businessCard.marketsAndServices.category =
        businessCard.marketsAndServices.category || [];
      businessCard.marketsAndServices = [
        ...businessCard.marketsAndServices,
        {
          region,
          category: [...businessCard.marketsAndServices.category, category],
          marketsAndServicesId: get(businessCard, "marketsAndServices._id", ""),
        },
      ];
    }
    submitBusinessCard(businessCard)();
    businessCard.region = "";
    businessCard.category = "";
    setState({ ...state });
    setLimitError(false);
  };

  const returnRegionsList = (portifolioObj) => {
    return (
      <div>
        <ol className={classes.regionListAlign}>
          {map(portifolioObj?.marketsAndServices, (eachRegion, idx) => {
            return (
              <li className={idx > 0 && classes.padding_top_37}>
                <span className={classes.list_dropdown}>
                  {get(eachRegion, "region", "")} -{" "}
                </span>
                {map(eachRegion?.category, (category) => {
                  return (
                    <>
                      <span className={classes.categoryAlign}>
                        {category}{" "}
                        <Styled_cross
                          className={classes.coloredCrossStyle}
                          onClick={deleteRegion(
                            portifolioObj,
                            eachRegion.region,
                            category
                          )}
                        />
                      </span>
                    </>
                  );
                })}
              </li>
            );
          })}
        </ol>
      </div>
    );
  };

  const submitBusinessCard = (businessCard) => () => {
    if (businessCard.isPublicDisplay) {
      if (
        get(businessCard, "marketsAndServices.length") === 0 ||
        !get(businessCard, "marketsAndServices")
      ) {
        addressData.businessCards.map((businessCardData) => {
          if (businessCardData._id === businessCard._id) {
            businessCard.marketMandatoryError = true;
          }
        });
        setState({ ...state });
        return;
      } else {
        addressData.businessCards.map((businessCard) => {
          businessCard.marketMandatoryError = false;
        });
        setState({ ...state });
      }
    }
    const dataToSubmit = {
      businessCards: [businessCard],
    };
    dispatch(
      SettingsActions.updateOfficeLocationsData(
        localStorage.getItem("companyId"),
        dataToSubmit,
        (res) => {
          if (!res.error.error) {
            dispatch(
              SettingsActions.getCompanyLocationsData(
                localStorage.getItem("companyId")
              )
            );
          } else {
            setState({ ...state, errorMessage: res.error.message.message });
          }
        }
      )
    );
  };

  const OnClickTriangle = () => {
    return (
      <>
        <div className={`${classes.hover_content} hover_display`}>
          <div className="triangle"></div>
          <Text
            size={10}
            color={color.primary_palette.black}
            family="avenir_roman"
            className={classes.initial_txt}
          >
            This Office will display in the Franklin Report Search Results for
            that Market and Service
          </Text>
        </div>
      </>
    );
  };

  const retrunNote = () => {
    setState({
      ...state,
      ShowNote: true,
    });
  };

  return (
    <>
      <Row className={classes.padding_left_42}>
        <Row className={classes.location_content}>
          <Text
            size={25}
            color={color.form_colors.royal_purple_1}
            family="avenir_sb"
            className={`${classes.txtTransform} ${classes.heading_location}`}
          >
            {strings.settings.titles.office_locations}
          </Text>
          <Row
            className={`${classes.description_spacing} ${classes.heading_location}`}
          >
            <div className={classes.width_70}>
              <Text
                size={16}
                color={color.primary_palette.black}
                family="avenir_roman"
              >
                {strings.settings.titles.location_des}
              </Text>
              <Text
                size={16}
                color={color.primary_palette.black}
                family="avenir_roman"
              >
                {strings.settings.titles.location_des1}
              </Text>
            </div>
            <div className={`${classes.width_30} ${classes.txtCenter}`}>
              <div>
                <span
                  className={classes.addOffice}
                  onClick={handleBusinessModal}
                >
                  <Lil_Plus_filled className={classes.add_fill} />
                  {strings.settings.titles.add_office}
                </span>
              </div>
            </div>
          </Row>
          {addressData &&
            map(addressData.businessCards, (businessCard, idx) => {
              return (
                <Row className={classes.businessCardStyle}>
                  <div>
                    <div>
                      <Text
                        size={10}
                        color={color.primary_palette.franklin_purple}
                        family="gillsans_sb"
                        className={classes.nickname_label}
                      >
                        {strings.settings.titles.ofc_nickename}
                      </Text>
                      <CustomTextField
                        className={classes.city_textfield}
                        placeholder="office nick name"
                        value={
                          get(businessCard, "nickname", "") ||
                          get(businessCard, "name", "")
                        }
                        name="nickname"
                        onChange={handleInputChange(businessCard)}
                      />
                    </div>
                    <div className={classes.pos_relative}>
                      <BusinessPrefilled businessCard={businessCard} />
                    </div>
                    <CustomCheckbox
                      className={classes.Headquarters}
                      label={strings.settings.titles.Headquarters}
                      checked={businessCard?.isHeadOffice}
                      name="isHeadOffice"
                      onChange={handleInputChange(businessCard)}
                    />
                    <CustomCheckbox
                      className={classes.Headquarters}
                      label={strings.settings.titles.billing_address}
                      checked={businessCard?.isBillingAddress}
                      onChange={handleInputChange(businessCard)}
                      name="isBillingAddress"
                    />
                    <CustomCheckbox
                      className={classes.Headquarters}
                      label={strings.settings.titles.publicly_display}
                      checked={businessCard?.isPublicDisplay}
                      onChange={handleInputChange(businessCard)}
                      name="isPublicDisplay"
                    />
                    <div className={classes.errorBlockAlign}>
                      {get(businessCard, "notPublicError", false) && (
                        <Text
                          size={15}
                          color={color.primary_palette.christmas_red}
                          family="avenir_sb"
                        >
                          <img
                            src="/assets/icons/info_1.svg"
                            class="info_img"
                            alt=""
                            className={classes.infoIconStyle}
                          />
                          {strings.settings.titles.public_error_1}
                        </Text>
                      )}
                      {get(businessCard, "marketMandatoryError", false) && (
                        <Text
                          size={15}
                          color={color.primary_palette.christmas_red}
                          family="avenir_sb"
                        >
                          <img
                            src="/assets/icons/info_1.svg"
                            class="info_img"
                            alt=""
                            className={classes.infoIconStyle}
                          />
                          {strings.settings.titles.public_error_2}
                        </Text>
                      )}
                    </div>
                    {/* <Row
                      className={`${classes.dropDownRow} ${classes.marginTop30}`}
                    >
                      <Element_Required_Icon
                        className={classes.select_field_required_another}
                      />
                      <Text
                        size={15}
                        color={color.primary_palette.franklin_purple}
                        family="avenir_sb"
                        className={`${classes.txtTransform} ${classes.margin_right_8}`}
                      >
                        {strings.settings.titles.market_ofc}
                      </Text>
                      <CustomSelect
                        className={`${classes.select_items} ${classes.width_231}`}
                        IconComponent={Chevron_Down_Icon}
                        name="displayedWhere"
                        value="Displayed Where?"
                      >
                        <CustomScrollbars
                          className={classes.customScrollHeight}
                        >
                          {lookUpData &&
                            map(lookUpData.regions, (region) => {
                              return (
                                <MenuItem
                                  onClick={handleDropdownSelect(
                                    businessCard,
                                    "displayedWhere",
                                    region.name
                                  )}
                                >
                                  {region.name}
                                </MenuItem>
                              );
                            })}
                        </CustomScrollbars>
                        <MenuItem
                          onClick={handleDropdownSelect(
                            businessCard,
                            "displayedWhere",
                            "Not Public"
                          )}
                        >
                          Not Public
                        </MenuItem>
                      </CustomSelect>
                      <div className={classes.note_icon}>
                        <img
                          src="/assets/images/note.PNG"
                          className={classes.cursor}
                          onClick={retrunNote}
                          alt=""
                        />
                        <div className="hover_display">{OnClickTriangle()}</div>
                      </div>
                    </Row> */}
                    {/* <Row className={classes.dropdown_margin}>
                      {map(businessCard?.displayedWhere, (location) => {
                        return (
                          <div className={classes.listAlign}>
                            {location}
                            <img
                              src="/assets/images/sketched-x.png"
                              alt=""
                              onClick={handleDropdownSelect(
                                businessCard,
                                "displayedWhere",
                                location,
                                "del"
                              )}
                              className={classes.crossIconStyle}
                            />
                          </div>
                        );
                      })}
                    </Row> */}
                    {/* <Row className={classes.dropDownRow}>
                      <Element_Required_Icon
                        className={classes.select_field_required_another}
                      />
                      <Text
                        size={15}
                        color={color.primary_palette.franklin_purple}
                        family="avenir_sb"
                        className={`${classes.txtTransform} ${classes.margin_right_4}`}
                      >
                        {strings.settings.titles.services_offered}
                      </Text>
                      <CustomSelect
                        className={`${classes.select_items} ${classes.width_309}`}
                        IconComponent={Chevron_Down_Icon}
                        name="sevicesOffered"
                        value="Services"
                      >
                        <CustomScrollbars
                          className={classes.customScrollHeight}
                        >
                          {lookUpData &&
                            map(lookUpData.trades, (category) => {
                              return (
                                <MenuItem
                                  onClick={handleDropdownSelect(
                                    businessCard,
                                    "sevicesOffered",
                                    category.name
                                  )}
                                >
                                  {category.name}
                                </MenuItem>
                              );
                            })}
                        </CustomScrollbars>
                      </CustomSelect>
                    </Row> */}
                    {/* <Row className={classes.dropdown_margin}>
                      {map(businessCard?.sevicesOffered, (services) => {
                        return (
                          <div className={classes.listAlign}>
                            {services}
                            <img
                              src="/assets/images/sketched-x.png"
                              alt=""
                              onClick={handleDropdownSelect(
                                businessCard,
                                "sevicesOffered",
                                services,
                                "del"
                              )}
                              className={classes.crossIconStyle}
                            />
                          </div>
                        );
                      })}
                    </Row> */}
                    <Row className={`${classes.marginTop30}`}>
                      <Text
                        size={14}
                        color={color.primary_palette.franklin_purple}
                        family="avenir_black_r"
                        className={`${classes.margin_right_4}`}
                      >
                        {strings.settings.titles.services_provided}
                      </Text>
                      {idx === 0 && (
                        <Text
                          size={12}
                          color={color.primary_palette.black}
                          family="avenir_light"
                        >
                          These markets/categories will display in Franklin
                          Report, to attract new clients
                        </Text>
                      )}
                    </Row>
                    <Row>
                      <CustomSelect
                        className={`${classes.select_items} ${classes.width_155}`}
                        IconComponent={Chevron_Down_Icon}
                        name="region"
                        value={businessCard.region}
                        renderValue={(value) =>
                          value ? (
                            <span className={classes.colorPurple}>{value}</span>
                          ) : (
                            <span>Market</span>
                          )
                        }
                        MenuProps={{
                          getContentAnchorEl: null,
                          disableScrollLock: true,
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                        }}
                      >
                        <CustomScrollbars
                          className={classes.customScrollHeight}
                        >
                          {lookUpData &&
                            map(lookUpData.regions, (region) => {
                              return (
                                <MenuItem
                                  onClick={handleMarketSelect(
                                    businessCard,
                                    "region",
                                    region.name
                                  )}
                                >
                                  {region.name}
                                </MenuItem>
                              );
                            })}
                        </CustomScrollbars>
                      </CustomSelect>
                      <CustomSelect
                        className={`${classes.select_items} ${classes.width_270}`}
                        IconComponent={Chevron_Down_Icon}
                        name="category"
                        value={businessCard.category}
                        renderValue={(value) =>
                          value ? (
                            <span className={classes.colorPurple}>{value}</span>
                          ) : (
                            <span>Category</span>
                          )
                        }
                        MenuProps={{
                          getContentAnchorEl: null,
                          disableScrollLock: true,
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                        }}
                      >
                        <CustomScrollbars
                          className={classes.customScrollHeightCategory}
                        >
                          {lookUpData &&
                            renderCategorizedMenu(
                              lookUpData.trades,
                              "category",
                              businessCard
                            )}
                        </CustomScrollbars>
                      </CustomSelect>
                      <CustomButton
                        className={classes.add_btn}
                        onClick={
                          businessCard.category &&
                          businessCard.region &&
                          handleAddRegionClick(businessCard)
                        }
                      >
                        {strings.business_card.titles.add}
                      </CustomButton>
                    </Row>
                    {limitError && (
                      <Text
                        color={color.primary_palette.christmas_red}
                        size={15}
                        family="avenir_bold"
                        className={classes.marginTop6}
                      >
                        <img
                          src="/assets/icons/info_1.svg"
                          class="info_img"
                          alt=""
                          className={classes.marginLeft4}
                        />
                        Maximum four categories per market
                      </Text>
                    )}
                    {get(businessCard, "errorMsg", "") && (
                      <Text
                        color={color.primary_palette.tricks_red}
                        size={12}
                        family="avenir_bold"
                        className={classes.marginTop6}
                      >
                        <img
                          src="/assets/icons/info_1.svg"
                          class="info_img"
                          alt=""
                          className={classes.infoIconStyle}
                        />
                        {get(businessCard, "errorMsg", "")}
                        <br />
                        {get(businessCard, "errorMsgNext", "")}
                      </Text>
                    )}
                    {returnRegionsList(businessCard)}
                  </div>
                  <Trash_can
                    className={classes.trash_icon}
                    onClick={handleOfficeModal(businessCard)}
                  />
                  <CustomButton
                    className={classes.saveBtn}
                    onClick={submitBusinessCard(businessCard)}
                  >
                    {strings.settings.titles.save}
                  </CustomButton>
                </Row>
              );
            })}
        </Row>
      </Row>
      <CustomModal
        open={openAddNewModal}
        onClose={handleBusinessModal}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <AddAnotherCard
          onClose={handleBusinessModal}
          businessCard={get(addressData, "businessCards[0]", {})}
        />
      </CustomModal>
      <CustomModal
        open={openDeleteOffice}
        onClose={handleOfficeModal()}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <DeleteOfficeLocation
          open={openDeleteOffice}
          onClose={handleOfficeModal()}
          locationId={businessCardId}
          reloadOfficeLocations={reloadOfficeLocations}
        />
      </CustomModal>
    </>
  );
}

export default OfficeLocationStyles(OfficeLocations);
