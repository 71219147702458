import axios from "axios";
import { default as profileStepperApi } from "./profile_stepper_api";
import { default as SettingsApi } from "./settings_api";

import {
  SERVER_URL,
  SPACK_SERVER_URL,
  LOOKUP_SERVER_URL,
} from "../../constants";
import { RequestInterceptor } from "./request_interceptor";
import { ResponseInterceptor } from "./response_interceptor";
import { default as JoinusApi } from "./joinUs_api";
import { default as LoginApi } from "./login_api";
import { default as RecoverApi } from "./recover_api";
import { default as LookUpDataApi } from "./lookup_api";

const api = axios.create({
  baseURL: SERVER_URL,
});

const spackApi = axios.create({
  baseURL: SPACK_SERVER_URL,
});

const lookupApi = axios.create({
  baseURL: LOOKUP_SERVER_URL,
});

api.interceptors.request.use(RequestInterceptor);
api.interceptors.response.use(null, ResponseInterceptor);

spackApi.interceptors.request.use(RequestInterceptor);
spackApi.interceptors.response.use(null, ResponseInterceptor);

lookupApi.interceptors.request.use(RequestInterceptor);
lookupApi.interceptors.response.use(null, ResponseInterceptor);

export default api;
export {
  profileStepperApi,
  LoginApi,
  JoinusApi,
  spackApi,
  lookupApi,
  SettingsApi,
  LookUpDataApi,
  RecoverApi
};
