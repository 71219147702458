import React from "react";

import { color } from "../../utilities/themes";
import DeleteCompanyStyles from "./styles/deleteCompanyStyles";
import Text from "../common/ui_kit/text";
import strings from "../../utilities/strings";
import Close_Icon from "../data_display/icons/Close";
import CustomButton from "../navigations/custom_buttons";

function RemoveAdmin(props) {
  const { classes, onClose } = props;

  return (
    <>
      <div className={classes.delete_admin_width}>
        <div className={classes.txtRight}>
          <Close_Icon className={classes.cross_icon} onClick={onClose} />
        </div>
        <div className={classes.textCenter}>
          <img
            src="/assets/images/securecross.png"
            alt="paper"
            className={classes.secure_icon}
          />
          <Text
            size={15}
            family="avenir_sb"
            color={color.primary_palette.black}
            className={classes.styleChangesTxt}
          >
            {strings.modal.titles.currently_signed}
          </Text>
          <Text
            size={15}
            family="avenir_sb"
            color={color.primary_palette.black}
            className={classes.subTextHeading}
          >
            {strings.modal.titles.contact_admin}
          </Text>
          <CustomButton className={classes.okayBtn} onClick={onClose}>
            {strings.modal.titles.got}
          </CustomButton>
        </div>
      </div>
    </>
  );
}

export default DeleteCompanyStyles(RemoveAdmin);
