import { withStyles } from "@material-ui/core/styles";
import { pxToRem, color } from "../../../utilities/themes";

const styles = withStyles({
  congratsModalStyles: {
    width: pxToRem(372),
    height: pxToRem(242),
    border: `solid ${pxToRem(2)} ${color.primary_palette.free_purple}`,
    backgroundColor: color.secondary_palette.blues.modal_blue,
    margin: "0 auto",
    boxShadow: `2px 2px 4px 0 rgba(0, 0, 0, 0.5)`,
  },
  recover: {
    marginTop: pxToRem(23),
    marginBottom: pxToRem(15),
    height: pxToRem(98),
  },
  txtCenter: {
    textAlign: "center",
  },
  carryBtn: {
    width: pxToRem(132),
    height: pxToRem(25),
    padding: 0,
    fontSize: pxToRem(15),
    color: `${color.primary_palette.franklin_purple} !important`,
    backgroundColor: color.primary_palette.white,
    border: `solid ${pxToRem(1)} ${color.primary_palette.free_purple}`,
    borderRadius: pxToRem(15),
    marginTop: pxToRem(20),
    marginBottom: pxToRem(15),
  },
});

export default styles;
