import React from "react";
import _ from "lodash";
import { Scrollbars } from "react-custom-scrollbars";

const renderThumb = ({ style, ...props }) => {
  const thumbStyle = {
    width: "15px",
    backgroundImage: "url(" + "/assets/images/Black-Pearl.png" + ")",
    backgroundPosition: "center",
    backgroundSize: "contain",
    position: "absolute",
    backgroundRepeat: "no-repeat",
    height: "15px !important",
    zIndex: "1000",
    transform: "translateY(0px)",
    right: "-7px",
  };
  return (
    <div
      className={"thumbstyle"}
      style={{ ...style, ...thumbStyle }}
      {...props}
    />
  );
};

const renderTrack = ({ style, ...props }) => {
  const trackStyle = {
    width: "1px",
    height: "100%",
    backgroundColor: "#979797",
    right: "8px",
  };
  return (
    <div className="track" style={{ ...style, ...trackStyle }} {...props} />
  );
};

const CustomScrollbars = (props) => {

  let scrollbarRef = null;

  /**
   * This method is used to set the height to the scrollbar.
   * Since scrollbar doesn't set with any default height hence content will not be visible when it's rendered.
   * Setting the height dynamically based on the content/children height with some calculations
   * MaxHeight is 120px, Threshold height is 105px.
   */
  const setHeight = () => {
    if (scrollbarRef) {
      const contentDiv = scrollbarRef.view;
      const scrollWrapper = _.get(contentDiv, "parentElement");
      // if props has height no need of calculations
      if (props.height) {
        scrollWrapper.style.height = props.height;
        return;
      }
      // get the no. of child count
      const childCount = contentDiv.childElementCount;
      let allChildHeight = 0;
      if (childCount > 0) {
        // get the first child visible height.
        const eachChildHeight = contentDiv.firstElementChild.clientHeight;
        // sum all child heights
        allChildHeight = eachChildHeight * childCount;
        // just simply took 105 as threshold, as maxheight is 120.
        // so comparing sum of childs height with 105
        if (allChildHeight > 105) {
          // set default as 120
          scrollWrapper.style.height = `120px`;
          return;
        }
      }
      // set sum of childs height since sum is not greater than 105.
      scrollWrapper.style.height = `${allChildHeight}px`;
    }
  };

  const setRef = (ref) => {
    scrollbarRef = ref;
    // if props has setRef then invoke props method
    props.setRef && props.setRef(ref);
    setHeight();
  };

  return (
    <Scrollbars
      ref={setRef}
      thumbSize={props.isModalScroll ? 15 : 10}
      thumbMinSize={40}
      renderThumbHorizontal={renderThumb}
      renderThumbVertical={renderThumb}
      renderTrackVertical={renderTrack}
      hideTracksWhenNotNeeded
      {...props}
    />
  );
};
export default CustomScrollbars;
