import { withStyles } from "@material-ui/core/styles";
import tradework_theme, { pxToRem, color } from "../../../utilities/themes";

const styles = withStyles({
  product_cover: {
    width: pxToRem(402),
    border: `solid ${pxToRem(2)} ${color.primary_palette.free_purple}`,
    paddingBottom: pxToRem(20),
    backgroundColor: color.secondary_palette.blues.modal_blue,
    position: "relative",
  },
  textRight: {
    textAlign: "right",
  },
  cross_mark: {
    fontSize: pxToRem(15),
    stroke: color.primary_palette.black,
    cursor: "pointer",
  },
  cross_btn: {
    minWidth: pxToRem(40),
    padding: pxToRem(16),
  },
  product_cover_inner: {
    width: pxToRem(360),
    margin: "0 auto",
    textAlign: "center",
  },
  product_cover_inner_project: {
    width: pxToRem(360),
    margin: "0 auto",
    textAlign: "center",
    border: `solid ${pxToRem(1)} ${color.primary_palette.franklin_purple}`,
    backgroundColor: color.secondary_palette.blues.tile_blue,
  },
  img_block: {
    width: pxToRem(177),
    height: pxToRem(118),
    backgroundColor: color.secondary_palette.grays.shadow_gray,
    margin: `${pxToRem(0)} auto`,
    marginTop: pxToRem(27),
    marginBottom: pxToRem(11),
  },
  padding_20: {
    padding: `${pxToRem(0)} ${pxToRem(20)}`,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  image_block: {
    width: pxToRem(177),
    height: pxToRem(118),
    margin: `${pxToRem(0)} auto`,
    marginTop: pxToRem(27),
    marginBottom: pxToRem(11),
  },
  padding_sides: {
    paddingTop: pxToRem(30),
    paddingBottom: pxToRem(13),
  },
  deleteBtn: {
    width: pxToRem(160),
    height: pxToRem(30),
    borderRadius: pxToRem(20),
    border: `solid ${pxToRem(2)} ${color.form_colors.blueberry_purple}`,
    backgroundColor: color.primary_palette.white,
    marginTop: pxToRem(19),
    marginBottom: pxToRem(20),
    "& .MuiButton-label": {
      ...tradework_theme.typography.styles.avenir_sb,
      fontSize: pxToRem(15),
      color: color.form_colors.royal_purple_1,
    },
  },
  deleteBtnDisabled: {
    width: pxToRem(160),
    height: pxToRem(30),
    borderRadius: pxToRem(20),
    marginTop: pxToRem(19),
    marginBottom: pxToRem(20),
    backgroundColor: color.primary_palette.white,
    border: `solid ${pxToRem(2)} ${color.primary_palette.disable_border}`,
    "& .MuiButton-label": {
      ...tradework_theme.typography.styles.avenir_sb,
      fontSize: pxToRem(15),
    },
  },
  textCenter: {
    textAlign: "center",
  },
  displayBlock: {
    padding: `${0} ${pxToRem(56)}`,
    textAlign: "center",
  },
  txtAlign: {
    display: "inline-block",
  },
  width172: {
    width: pxToRem(172),
  },
  textFieldAlign: {
    marginLeft: pxToRem(8),
    position: "relative",
    float: "right",
    top: pxToRem(-6),
    "& .MuiInputBase-input": {
      ...tradework_theme.typography.styles.NeutraText,
      fontSize: pxToRem(16),
      color: color.primary_palette.black,
      paddingBottom: 0,
    },
  },
  textFieldAlign_error: {
    marginLeft: pxToRem(8),
    position: "relative",
    top: pxToRem(-6),
    float: "right",
    "& .MuiInputBase-input": {
      ...tradework_theme.typography.styles.NeutraText,
      fontSize: pxToRem(16),
      color: color.primary_palette.black,
      paddingBottom: 0,
    },
    "& .MuiInput-underline": {
      "&:before": {
        borderBottom: `solid ${pxToRem(1)} ${
          color.primary_palette.christmas_red
        }`,
      },
      "&:after": {
        borderBottom: `solid ${pxToRem(1)} ${
          color.primary_palette.christmas_red
        }`,
      },
    },
  },
  textBlockAlign: {
    marginBottom: pxToRem(20),
    display: "inline-block",
    float: "right",
  },
  inputBlocksAlign: {
    padding: `${0} ${pxToRem(56)}`,
    textAlign: "center",
    marginTop: pxToRem(12),
  },

  plainInputWidth: {
    // width: pxToRem(354),
    backgroundColor: "transparent",
    "& input": {
      textAlign: "center",
      fontSize: pxToRem(16),
    },
    "& .MuiFormControl-root": {
      marginTop: `${pxToRem(-7)} !important`,
      marginBottom: `${pxToRem(0)} !important`,
    },
    "& ::placeholder": {
      fontSize: pxToRem(16),
      color: color.secondary_palette.grays.shadow_gray,
      ...tradework_theme.typography.styles.NeutraText,
      opacity: 1,
    },
  },
  edit_icon: {
    fontSize: pxToRem(60),
    position: "absolute",
    top: pxToRem(-26),
    left: "45%",
  },
  txtAlignCenter: {
    textAlign: "center",
    margin: "auto",
    width: "100%",
    display: "inline-block",
  },
  margin_bottom_16: {
    marginBottom: pxToRem(16),
  },
  hideField: {
    display: "none",
  },
  infoIcon: {
    width: pxToRem(15),
    height: pxToRem(14),
    margin: "-3px",
    marginRight: "1px",
  },
  error_msg_color: {
    color: color.primary_palette.christmas_red,
  },
  phoneNumberActions: {
    marginLeft: pxToRem(14),
    display: "inline",
    marginTop: pxToRem(6),
  },
  dropdownOptions: {
    position: "absolute",
    border: "1px solid #bfbfbf",
    borderRadius: "4px",
    boxShadow: "1px 1px 2px 0 rgba(0, 0, 0, 0.15)",
    fontSize: "12px",
    height: "30px",
    width: "74px",
    padding: "5px 2px 5px 2px",
    background: "white",
    cursor: "pointer",
    zIndex: 1000,
  },
  dropDownIcon: {
    height: "5px",
    width: "11px",
    cursor: "pointer",
  },
  countryImage: {
    width: "14px",
    height: "9px",
    paddingRight: "2px",
  },
});

export default styles;
