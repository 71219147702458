import React, { useEffect } from "react";

import { get } from "lodash";
import { useSelector, useDispatch } from "react-redux";

import profileStyle from "./styles/profileImageStyles";
import Row from "../common/ui_kit/row";
import Text from "../common/ui_kit/text";
import strings from "../../utilities/strings";
import Close_Icon from "../data_display/icons/Close";
import CustomButton from "../navigations/custom_buttons";
import AddImage from "./add_image";
import CustomModal from "../inputs/custom_modal";
import ChoosePicture from "./choose_picture";
import EditCircle from "../data_display/icons/Edit";
import CustomScrollbars from "../data_display/custom_scroll";

import { ProfileActions } from "../../redux/actions";
import { color } from "../../utilities/themes";

function ProfileImage(props) {
  const { classes, onClose, updateCroppedOnProfile } = props;
  const [values, setValues] = React.useState({
    openCroppingModal: false,
    croppingModalType: null,
    imageData: {},
    openImageExp: {},
    backgroundColor: "",
  });

  const { openCroppingModal, croppingModalType, imageData, showEditIcon } =
    values;

  const dispatch = useDispatch();

  const handleClose = () => {
    setValues({
      ...values,
      openCroppingModal: false,
    });
  };

  const loadedImageData = useSelector(
    (resData) => resData.Profile.coverImageData
  );

  useEffect(() => {
    if (loadedImageData) {
      const showEditIconVal =
        loadedImageData.coverImgUri ||
        loadedImageData.profileImgUri ||
        loadedImageData.companyLogo;
      setValues({
        ...values,
        imageData: {
          cover: {
            imageUrl: loadedImageData.coverImgUri,
          },
          profile: {
            imageUrl: loadedImageData.profileImgUri,
          },
          logo: {
            imageUrl: loadedImageData.companyLogo,
          },
        },
        showEditIcon: showEditIconVal,
      });
    }
  }, [loadedImageData]);

  const toggleCroppingModal = (obj) => {
    const { imageData, type } = obj;
    setValues({
      ...values,
      openCroppingModal: true,
      croppingModalType: type,
      imageData: { ...values.imageData, [type]: imageData },
    });
  };

  const updateCroppedImage = (imageObject, type, colorVal) => {
    const { croppedImage, blobUrl } = imageObject;
    imageData[type].imageUrl = croppedImage;
    imageData[type].blobFile = blobUrl;
    setValues({ ...values, imageData, backgroundColor: colorVal });
    updateCroppedOnProfile(imageObject, type);
  };

  const openImageExp = (type) => () => {
    setValues({
      ...values,
      openImageExp: { ...openImageExp, [type]: Date.now() },
      openCroppingModal: false,
    });
  };

  const onSubmit = () => {
    const dataToSubmit = {
      profile: get(imageData, "profile.blobFile", ""),
      cover: get(imageData, "cover.blobFile", ""),
      logo: get(imageData, "logo.blobFile", ""),
      portfolioId: localStorage.getItem("portfolioId"),
      backgroundColor: values.backgroundColor,
      teamImgUri: "",
    };
    dispatch(
      ProfileActions.saveProfileImage(dataToSubmit, (res) => {
        if (!res.error.error) {
          dispatch(
            ProfileActions.getCoverImageData(
              localStorage.getItem("portfolioId")
            )
          );
          dispatch(
            ProfileActions.getPortfolioData(localStorage.getItem("portfolioId"))
          );
        }
      })
    );
    dispatch(
      ProfileActions.getPortfolioData(localStorage.getItem("portfolioId"))
    );
    onClose();
  };

  return (
    <>
      <div className={classes.profile_width}>
        <div className={classes.textRight}>
          <CustomButton className={classes.cross_btn} onClick={onClose} isX>
            <Close_Icon className={classes.cross_mark} onClick={onClose} />
          </CustomButton>
        </div>
        {showEditIcon && (
          <div className={classes.edit_pencil}>
            <EditCircle />
          </div>
        )}
        <Text
          size={30}
          color={color.primary_palette.black}
          family="avenir_light"
          className={classes.title_text}
        >
          {strings.add_image.titles.profile_img}
        </Text>
        <CustomScrollbars className={classes.customScroll} isModalScroll={true}>
          <div className={classes.padding_block}>
            <Row className={classes.spacing_imgs}>
              <AddImage
                defaultImage="/assets/icons/Circle Plus 1.svg"
                className={classes.circle}
                ImageDimensions={classes.dimensions_fix}
                isProductCropper={true}
                MainText={strings.add_image.titles.company_headshot}
                MainTextStyle={classes.mainTextsize}
                subText={strings.add_image.titles.company_headshot_des}
                subTextStyle={classes.subTextsize}
                sizeText={strings.add_image.titles.size_170}
                sizeStyle={classes.sizeTextStyle}
                type="profile"
                croppedImage={get(imageData, "profile.imageUrl", "")}
                imageUpload={toggleCroppingModal}
                openImageExp={get(values.openImageExp, "profile", false)}
                transparentColor={color.primary_palette.white}
              />
            </Row>
            <Row className={classes.spacing_imgs}>
              <AddImage
                defaultImage="/assets/icons/Square Plus 2.svg"
                className={classes.rectangle}
                ImageDimensions={classes.dimensions_fix}
                isProductCropper={true}
                MainText={strings.add_image.titles.company_logo}
                MainTextStyle={classes.mainTextsize}
                subText={strings.add_image.titles.mandatory_info}
                subTextStyle={classes.subTextsize}
                subText1={strings.add_image.titles.mandatory_des}
                subTextStyle1={classes.subTextsize}
                subTextStyle={classes.subTextsize}
                sizeText={strings.add_image.titles.size_300}
                sizeStyle={classes.sizeTextStyle}
                type="logo"
                croppedImage={get(imageData, "logo.imageUrl", "")}
                imageUpload={toggleCroppingModal}
                openImageExp={get(values.openImageExp, "logo", false)}
                transparentColor={color.primary_palette.white}
              />
            </Row>
            <Row className={classes.spacing_imgs}>
              <AddImage
                defaultImage="/assets/icons/Square Plus 2.svg"
                className={classes.square}
                ImageDimensions={classes.dimensions_fix}
                isProductCropper={true}
                MainText={strings.add_image.titles.cover_photo}
                MainTextStyle={classes.mainTextsize}
                subTextStyle={classes.subTextsize}
                subText1={strings.add_image.titles.cover_photo_info}
                subTextStyle1={classes.subTextsize}
                subTextStyle={classes.subTextsize}
                sizeText={strings.add_image.titles.size_1024}
                sizeStyle={classes.sizeTextStyle}
                type="cover"
                croppedImage={get(imageData, "cover.imageUrl", "")}
                imageUpload={toggleCroppingModal}
                openImageExp={get(values.openImageExp, "cover", false)}
                transparentColor={color.primary_palette.white}
              />
            </Row>
            <Row className={classes.btnAlign}>
              <CustomButton className={classes.continue_btn} onClick={onSubmit}>
                {strings.business_card.titles.continue}
              </CustomButton>
            </Row>
          </div>
        </CustomScrollbars>
      </div>

      <CustomModal
        open={openCroppingModal}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <ChoosePicture
          isProfileCropper={true}
          imageUrl={get(imageData[croppingModalType], "imageUrl", "")}
          type={croppingModalType}
          updateCroppedImage={updateCroppedImage}
          openImageExp={openImageExp}
          onClose={handleClose}
          transparentColor={color.primary_palette.white}
        />
      </CustomModal>
    </>
  );
}

export default profileStyle(ProfileImage);
