import { withStyles } from "@material-ui/core/styles";
import tradework_theme, { pxToRem, color } from "../../../utilities/themes";

const styles = withStyles({
  copperModal: {
    width: pxToRem(590),
    border: `solid ${pxToRem(2)} ${color.primary_palette.free_purple}`,
    backgroundColor: color.secondary_palette.blues.modal_blue,
  },
  btnRight: {
    textAlign: "right",
  },
  marginAuto: {
    margin: "0 auto",
  },
  info_img: {
    verticalAlign: "middle",
    marginTop: pxToRem(-2),
    paddingRight: pxToRem(3),
    width: pxToRem(9),
    height: pxToRem(9),
  },
  error: {
    color: color.primary_palette.christmas_red,
    fontSize: pxToRem(12),
    ...tradework_theme.typography.styles.avenir_bold,
  },
  trash_can: {
    position: "absolute",
    right: pxToRem(54),
    top: pxToRem(336),
    cursor: "pointer",
  },
  crossBtn: {
    minWidth: pxToRem(30),
    padding: pxToRem(16),
    "& .MuiSvgIcon-root": {
      fontSize: pxToRem(15),
    },
  },
  textCenter: {
    textAlign: "center",
  },
  copperModalContent: {
    padding: `${pxToRem(0)} ${pxToRem(90)}`,
    position: "relative",
  },
  save_btn: {
    marginTop: pxToRem(22),
    marginBottom: pxToRem(22),
    width: pxToRem(208),
    height: pxToRem(35),
    borderRadius: pxToRem(22),
    border: `solid ${pxToRem(1.6)} ${color.primary_palette.franklin_purple}`,
    backgroundColor: color.primary_palette.white,
    "& .MuiButton-label": {
      ...tradework_theme.typography.styles.avenir_sb,
      fontSize: pxToRem(16.3),
      color: color.primary_palette.franklin_purple,
    },
  },
  justifyCenter: {
    justifyContent: "space-around",
  },
  cropperDiv: {
    position: "relative",
    width: pxToRem(408),
    height: pxToRem(296),
    margin: "0 auto",
    border: `solid ${pxToRem(2)} ${color.primary_palette.free_purple}`,
    marginTop: pxToRem(16),
    marginBottom: pxToRem(18),
    "& .reactEasyCrop_CropArea": {
      border: `solid ${pxToRem(2)} ${
        color.secondary_palette.blues.click_blue
      } !important`,
    },
  },
  slider_width: {
    width: pxToRem(293),
    margin: "0 auto",
    "& .MuiSlider-thumb": {
      width: pxToRem(22),
      height: pxToRem(22),
      marginTop: pxToRem(-10),
    },
    "& .MuiSlider-track": {
      backgroundColor: color.secondary_palette.grays.shadow_gray,
    },
    "& .MuiSlider-rail": {
      backgroundColor: color.secondary_palette.grays.shadow_gray,
      opacity: 1,
    },
  },
  margin_top_20: {
    marginTop: pxToRem(-20),
  },
  optimal_text: {
    ...tradework_theme.typography.styles.OptimaLT,
  },
  choose_black: {
    width: pxToRem(26),
    height: pxToRem(26),
    border: `solid ${pxToRem(0.5)} ${color.primary_palette.black}`,
    backgroundColor: color.primary_palette.black,
    margin: `${pxToRem(12)} ${pxToRem(12)}`,
    cursor: "pointer",
  },
  choose_black_active: {
    width: pxToRem(26),
    height: pxToRem(26),
    border: `solid ${pxToRem(2)} ${color.primary_palette.highlight_purple}`,
    backgroundColor: color.primary_palette.black,
    margin: `${pxToRem(12)} ${pxToRem(12)}`,
    cursor: "pointer",
  },
  choose_gray: {
    width: pxToRem(26),
    height: pxToRem(26),
    border: `solid ${pxToRem(0.5)} ${
      color.secondary_palette.grays.background_gray
    }`,
    backgroundColor: color.secondary_palette.grays.background_gray,
    margin: `${pxToRem(12)} ${pxToRem(12)}`,
    cursor: "pointer",
  },
  choose_gray_active: {
    width: pxToRem(26),
    height: pxToRem(26),
    border: `solid ${pxToRem(2)} ${color.primary_palette.highlight_purple}`,
    backgroundColor: color.secondary_palette.grays.background_gray,
    margin: `${pxToRem(12)} ${pxToRem(12)}`,
    cursor: "pointer",
  },
  choose_white: {
    width: pxToRem(26),
    height: pxToRem(26),
    border: `solid ${pxToRem(1)} ${
      color.secondary_palette.grays.background_gray
    }`,
    backgroundColor: color.secondary_palette.grays.portfolio_view_gray,
    margin: `${pxToRem(12)} ${pxToRem(12)}`,
    cursor: "pointer",
  },
  choose_white_active: {
    width: pxToRem(26),
    height: pxToRem(26),
    border: `solid ${pxToRem(2)} ${color.primary_palette.highlight_purple}`,
    backgroundColor: color.secondary_palette.grays.portfolio_view_gray,
    margin: `${pxToRem(12)} ${pxToRem(12)}`,
    cursor: "pointer",
  },
  choose_bg_txt: {
    alignSelf: "center",
  },
  checkeredAlign: {
    height: pxToRem(26),
    width: pxToRem(26),
  },
});

export default styles;
