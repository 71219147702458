import React from "react";
import { makeStyles } from "@material-ui/core";
import Text from "./text";
import { color, pxToRem } from "../../../utilities/themes";

const useStyles = makeStyles({
  container: {
    position: "relative",
    // width: pxToRem(309),
    minHeight: pxToRem(60),
    boxShadow: `${pxToRem(1)} ${pxToRem(1)} ${pxToRem(
      1
    )} 0 rgba(0, 0, 0, 0.26)`,
    border: `solid ${pxToRem(1)} ${color.primary_palette.franklin_purple}`,
    backgroundColor: color.secondary_palette.blues.tile_blue
  },
  title: {
    display: "inline-block",
    marginLeft: pxToRem(20),
    marginRight: pxToRem(20),
    marginTop: pxToRem(-18),
    wordBreak: "break-all",
    backgroundColor: color.primary_palette.white,
    padding: `0px ${pxToRem(5)}`
  }
});

const TitleBox = ({ title, className, style, children, ...props }) => {
  const classes = useStyles(props);
  return (
    <div className={`${classes.container} ${className}`} style={style}>
      {title && (
        <Text
          family="gillsans_sb"
          size={22}
          color={color.primary_palette.franklin_purple}
          className={classes.title}
        >
          {title}
        </Text>
      )}
      {children}
    </div>
  );
};

export default TitleBox;
