import React, { useRef, useEffect, useState } from "react";
import Cropper from "react-easy-crop";
import { computeCroppedArea } from "./crop_image";
import "./crop.css";

function CustomCropper({ aspect, onMediaLoaded, multiple, ...props }) {
  const [aspectRatio, setAspectRatio] = useState(aspect);
  const [croppingImage, setCroppingImage] = useState(null);
  const [extraCropBox, setExtraCropBox] = useState(null);
  const cropperRef = useRef(null);

  // effect runs on change of aspect ratio
  useEffect(() => {
    if (multiple && aspectRatio) {
      manageCropBox();
    }
  }, [aspectRatio]);

  const manageCropBox = () => {
    const { current: cropperObj } = cropperRef;
    if (!cropperObj) {
      return;
    }

    const containerDiv = cropperObj.containerRef;
    // get the main cropper div
    const cropBox = containerDiv.querySelector("div[data-testid='cropper']");
    if (!cropBox) {
      return;
    }

    // create new div as extra cropbox to appear inside
    let newCropBox = document.createElement("div");//NOSONAR
    // this condition satisfies if we are cropping rectange shape else round shape cropping
    if (props.cropShape == "rect") {
      // append our own classname
      cropBox.className += " parent-crop-box";
      // create paragraph(p) element and text node
      const cropBoxText = document.createElement("p");
      const cropBoxTextNode = document.createTextNode(
        "Single Image crop lines"
      );
      // append text to paragraph and paragraph to cropper div
      cropBoxText.append(cropBoxTextNode);
      cropBox.append(cropBoxText);

      // create new div as extra cropbox to appear inside
      newCropBox = document.createElement("div");
      newCropBox.className = "child-crop-box";
      // create paragraph(p) element and text node
      const newCropboxText = document.createElement("p");
      const newCropBoxTextNode = document.createTextNode("2 Images crop lines");
      // append text to paragraph and paragraph to extra cropbox div
      newCropboxText.append(newCropBoxTextNode);
      newCropBox.append(newCropboxText);

      // then append extra cropbox to main crop div
      cropBox.append(newCropBox);
    } else {
      // create new div as extra cropbox to appear inside
      newCropBox = document.createElement("div");
      // assigning all styles and classname from main crop box
      newCropBox.className = `${cropBox.className} square-crop-box`;
      newCropBox.style.width = `${cropBox.offsetHeight - 20}px`;
      newCropBox.style.height = `${cropBox.offsetHeight - 20}px`;
      // appending to parent div of the main crop box
      containerDiv.appendChild(newCropBox);
      // adding our own classname to main crop box
      cropBox.className += " circle-crop-box";
    }
    // holding main cropping image and extra crop box which we needed further
    setCroppingImage(containerDiv.querySelector("img"));
    setExtraCropBox(newCropBox);
  };

  const onLoaded = (media) => {
    // manually calculate and set aspect ratio if there is no aspect ratio provided
    if (!aspectRatio) {
      setAspectRatio(media.naturalWidth / media.naturalHeight);
    } else {
      multiple && setTimeout(manageCropBox, 500);
    }
    onMediaLoaded && onMediaLoaded(media);
  };

  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    const croppedCoords = {
      main: {
        croppedArea,
        croppedAreaPixels,
      },
    };
    if (multiple && croppingImage) {
      /* calculating cropped coords of extra cropbox, below method is borrowed from react-easy-crop library only
      sending required arguments of extra cropbox to get perfect coords */
      const { croppedAreaPercentages, croppedAreaPixels } = computeCroppedArea(//NOSONAR
        props.crop,
        {
          width: croppingImage.width,
          height: croppingImage.height,
          naturalWidth: croppingImage.naturalWidth,
          naturalHeight: croppingImage.naturalHeight,
        },
        {
          width: extraCropBox.clientWidth,
          height: extraCropBox.clientHeight,
        },
        extraCropBox.clientWidth / extraCropBox.clientHeight,
        props.zoom,
        0
      );
      croppedCoords.extra = {
        croppedArea: croppedAreaPercentages,
        croppedAreaPixels,
      };
    }
    props.onCropComplete && props.onCropComplete(croppedCoords);
  };

  return (
    <Cropper
      {...props}
      ref={cropperRef}
      aspect={aspectRatio}
      onMediaLoaded={onLoaded}
      onCropComplete={onCropComplete}
      restrictPosition={false}
      minZoom={0.4}
    />
  );
}

export default CustomCropper;
