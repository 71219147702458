import React, { useState } from "react";
import { Grid, InputAdornment } from "@material-ui/core";
import { get } from "lodash";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import CustomInputLabel from "../../../components/data_display/custom_label";
import Close_Icon from "../../../components/data_display/icons/Close";
import Element_Required_Icon from "../../../components/data_display/icons/ElementRequiered";
import HidePassword_Icon from "../../../components/data_display/icons/HidePassword";
import Ribbon from "../../../components/data_display/icons/Ribbon";
import CustomCheckbox from "../../../components/inputs/custom_checkbox";
import CustomTextField from "../../../components/inputs/custom_textInput";
import CustomButton from "../../../components/navigations/custom_buttons";
import CustomLink from "../../../components/navigations/custom_links";

import {
  ATLEAST_ONE_CAPITAL_LETTER,
  NUM_OR_SYMBOL,
  REGEX_EMAIL,
  PHONE_NUMBER_FORMAT,
  REGEX_EMIAL_END_WITH_DOT,
} from "../../../validations/validations";
import strings from "../../../utilities/strings";
import { color } from "../../../utilities/themes";
import CustomModal from "../../../components/inputs/custom_modal";
import Chevron_Down_Icon from "../../../components/data_display/icons/Arrow_Chevron_Down";
import styleSheet from "../../styles/join_us_styles";
import { JoinUsActions } from "../../../redux/actions";
import {
  TOKEN_KEY,
  FRANKLIN_QA,
  FRANKLIN_PROD,
  FRANKLIN_STAGE,
} from "../../../constants";

function Joinus(props) {
  const [values, setValues] = useState({
    showEmailSuggestion: false,
    showPassword: false,
    countryCode: "USA",
    formData: {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      phoneNumber: "",
    },
    errors: {
      firstName: { touched: false },
      lastName: { touched: false },
      password: null,
      email: null,
      phoneNumber: null,
    },
    popoverModal: true,
    closeTinyPopover: true,
  });
  const { classes, signUpError, openLoginModal } = props;
  const {
    showPassword,
    showCountryDropdown,
    formData,
    errors,
    countryCode,
    showEmailSuggestion,
    formData: { firstName, lastName, phoneNumber, email, password },
    errorMessage,
    showSignInError,
  } = values;

  const dispatch = useDispatch();
  const history = useHistory();

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !showPassword });
  };

  const triggerCountryDropdown = () => {
    setValues({ ...values, showCountryDropdown: !showCountryDropdown });
  };

  const handleCountryChange = (value) => {
    formData.phoneNumber = "";
    setValues({
      ...values,
      countryCode: value,
      showCountryDropdown: !showCountryDropdown,
    });
  };

  const hasEightChars = (value) => {
    return value.length >= 8;
  };

  const hasUpperCaseLetter = (value) => {
    return ATLEAST_ONE_CAPITAL_LETTER.test(value);
  };

  const hasNumOrSymbol = (value) => {
    return NUM_OR_SYMBOL.test(value);
  };

  const handleValidation = (name, value) => {
    errors[name] = errors[name] || {};
    if (name === "firstName" || name === "lastName") {
      // VALIDATION FOR FIRST NAME AND LAST NAME
      if (!value || value.length > 35) {
        errors[name] = {
          error: true,
          message: strings.join_us.errors.firstName_min_max,
          touched: true,
        };
      } else {
        errors[name] = {
          error: false,
          message: "",
          touched: true,
        };
      }
      setValues({
        ...values,
        errors,
      });
    }
    if (name === "email") {
      // VALIDATION FOR EMAIL
      if (!value) {
        errors[name] = {
          error: true,
          message: strings.join_us.errors.email_address_required,
          touched: true,
        };
        setValues({ ...values, errors });
        return;
      } else {
        // IF EMAIL HAS VALUE
        const regexTest = REGEX_EMAIL.test(value);
        if (!regexTest) {
          errors[name] = {
            error: true,
            message: strings.join_us.errors.invalid_email_address,
            touched: true,
          };
        } else {
          errors[name] = {
            error: false,
            message: "",
            touched: true,
          };
          setValues({ ...values, errors });
        }
      }
    }
    if (name === "password") {
      const hasEight = hasEightChars(value);
      const numOrSymbol = hasNumOrSymbol(value);
      const hasCaps = hasUpperCaseLetter(value);
      if (!value) {
        errors[name] = {
          error: true,
          touched: true,
          message: strings.join_us.errors.password_required,
          hasEight,
          numOrSymbol,
          hasCaps,
        };
        setValues({ ...values, errors });
        return;
      } else {
        if (hasEight && numOrSymbol && hasCaps) {
          errors[name] = {
            error: false,
            message: "",
            hasEight,
            numOrSymbol,
            hasCaps,
            touched: true,
          };
        } else {
          errors[name] = {
            error: true,
            message: "",
            hasEight,
            numOrSymbol,
            hasCaps,
            touched: true,
          };
        }
        setValues({ ...values, errors });
      }
    }
    if (name === "phoneNumber") {
      if (
        (value && countryCode === "USA" && value.length !== 10) ||
        (value &&
          countryCode === "nonUSA" &&
          (value.length < 10 || value.length > 20))
      ) {
        errors[name] = {
          error: true,
          message: "",
          touched: true,
        };
      } else {
        errors[name] = {
          error: false,
          message: "",
          touched: true,
        };
      }
      setValues({ ...values, errors });
    }
  };

  const onInputBlur = (event, blur) => {
    event.preventDefault();
    if (event.target.name === "email") {
      // IF EMAIL MATCHES WITH REGEX DISPLAY SUGGESTION AND VALIDATING
      let showSuggestion = false;

      formData[event.target.name] = event.target.value;
      blur && handleValidation(event.target.name, event.target.value);
      if (REGEX_EMIAL_END_WITH_DOT.test(event.target.value)) {
        showSuggestion = true;
      }
      setValues({
        ...values,
        formData,
        showEmailSuggestion: showSuggestion,
        errorMessage: "",
        showSignInError: false,
      });
      return;
    }
    if (event.target.name === "phoneNumber") {
      // DEFORMATTING PHONE NUMBER AND SENDING TO VALIDATION
      const val = deformatPhoneNumber(event.target.value);
      if (isNaN(val)) {
        return;
      }
      formData[event.target.name] = val;
      blur && handleValidation(event.target.name, val);
    } else {
      formData[event.target.name] = event.target.value;
      blur && handleValidation(event.target.name, event.target.value);
    }
    setValues({
      ...values,
      formData,
      errorMessage: "",
      showSignInError: false,
    });
  };

  const onEmailSuggestionChecked = (value) => (e) => {
    formData.email = value;
    handleValidation("email", value);
    setValues({ ...values, formData, showEmailSuggestion: false });
  };
  const formatPhoneNumber = (value) => {
    return value.replace(PHONE_NUMBER_FORMAT, "$1.$2.$3");
  };

  const deformatPhoneNumber = (number) => {
    return number.replace(/[().\s/]/g, "");
  };

  const onPhoneNumberFocus = () => {
    formData.phoneNumber = formData.phoneNumber.replace(/[().\s/]/g, "");
    errors.phoneNumber = {
      error: false,
      message: "",
      touched: true,
    };
    setValues({
      ...values,
      formData,
      errors,
      errorMessage: "",
      showSignInError: false,
    });
  };

  const hasFromErrors = () => {
    const allFields = Object.keys(errors);
    if (allFields.length === 0) {
      return true;
    }
    const allErrors = allFields.map((field) => {
      if (field === "phoneNumber" && !errors[field]) {
        return false;
      }
      return get(errors[field], "error", true);
    });
    return allErrors.includes(true);
  };

  const submitForm = () => {
    dispatch(
      JoinUsActions.joinUs({ ...formData }, (resp) => {
        if (get(resp, "error.message.code", false) === 1011) {
          errors.email = {
            error: true,
            message: strings.join_us.errors.email_address_required,
            touched: true,
          };
          signUpError("You're already a member!", formData.email, true);
          setValues({
            ...values,
            errorMessage: get(resp, "error.message.message"),
            showSignInError: true,
            errors,
          });
        }
        if (get(resp, "error.message.code", false) === 1012) {
          errors.phoneNumber = {
            error: true,
            message: strings.join_us.errors.email_address_required,
            touched: true,
          };
          signUpError("You're already a member!", formData.email, true);
          setValues({
            ...values,
            errorMessage: get(resp, "error.message.message"),
            errors,
            showSignInError: true,
          });
        }
        if (get(resp, "data.token", false)) {
          const isReview = localStorage.getItem("Review");
          if (isReview) {
            let host = get(window, "location.host", "");
            if (host) {
              if (host.includes("localhost")) {
                setTimeout(function () {
                  window.location.href = `${FRANKLIN_STAGE}/NavigateUsers.aspx?Token=${get(
                    resp,
                    "data.token"
                  )}&URL=MemberReview`;
                }, 2000);
              } else if (host.includes("-dev-")) {
                setTimeout(function () {
                  window.location.href = `${FRANKLIN_QA}/NavigateUsers.aspx?Token=${get(
                    resp,
                    "data.token"
                  )}&URL=MemberReview`;
                }, 2000);
              } else if (host.includes("-qa-")) {
                setTimeout(function () {
                  window.location.href = `${FRANKLIN_QA}/NavigateUsers.aspx?Token=${get(
                    resp,
                    "data.token"
                  )}&URL=MemberReview`;
                }, 2000);
              } else if (host.includes("-stage-")) {
                setTimeout(function () {
                  window.location.href = `${FRANKLIN_STAGE}/NavigateUsers.aspx?Token=${get(
                    resp,
                    "data.token"
                  )}&URL=MemberReview`;
                }, 2000);
              } else if (host.includes("franklinreport")) {
                setTimeout(function () {
                  window.location.href = `${FRANKLIN_PROD}/NavigateUsers.aspx?Token=${get(
                    resp,
                    "data.token"
                  )}&URL=MemberReview`;
                }, 2000);
              }
            }
            setTimeout(function () {
              window.location.href = `${FRANKLIN_PROD}/NavigateUsers.aspx?Token=${get(
                resp,
                "data.token"
              )}&URL=MemberReview`;
            }, 2000);
          }
          const isFrRedirect = localStorage.getItem("redirectFranklin");
          if (isFrRedirect === "true") {
            let host = get(window, "location.host", "");
            if (host) {
              if (host.includes("localhost")) {
                setTimeout(function () {
                  window.location.href = `${FRANKLIN_STAGE}/NavigateUsers.aspx?Token=${get(
                    resp,
                    "data.token"
                  )}&URL=default`;
                }, 2000);
              } else if (host.includes("-dev-")) {
                setTimeout(function () {
                  window.location.href = `${FRANKLIN_QA}/NavigateUsers.aspx?Token=${get(
                    resp,
                    "data.token"
                  )}&URL=default`;
                }, 2000);
              } else if (host.includes("-qa-")) {
                setTimeout(function () {
                  window.location.href = `${FRANKLIN_QA}/NavigateUsers.aspx?Token=${get(
                    resp,
                    "data.token"
                  )}&URL=default`;
                }, 2000);
              } else if (host.includes("-stage-")) {
                setTimeout(function () {
                  window.location.href = `${FRANKLIN_STAGE}/NavigateUsers.aspx?Token=${get(
                    resp,
                    "data.token"
                  )}&URL=default`;
                }, 2000);
              } else if (host.includes("franklinreport")) {
                setTimeout(function () {
                  window.location.href = `${FRANKLIN_PROD}/NavigateUsers.aspx?Token=${get(
                    resp,
                    "data.token"
                  )}&URL=default`;
                }, 2000);
              }
            }
            setTimeout(function () {
              window.location.href = `${FRANKLIN_PROD}/NavigateUsers.aspx?Token=${get(
                resp,
                "data.token"
              )}&URL=default`;
            }, 2000);
          }

          localStorage.setItem("profile", JSON.stringify(resp.data));
          localStorage.setItem("hasExistingPortfolio", false);
          localStorage.setItem(TOKEN_KEY, resp.data.token);
          localStorage.setItem("isPublicMode", false);
          localStorage.setItem(
            "user_data",
            get(resp.data, "user.firstName", "")
          );
          props.closeJoinUs();
          localStorage.removeItem("frpage");
          history.push("/");
        }
      })
    );
  };

  const onClearInput = (name) => () => {
    formData[name] = "";
    handleValidation(name, "");
    setValues({ ...values, showEmailSuggestion: false });
  };

  const disableSubmit = hasFromErrors();
  return (
    <>
      <CustomModal
        open
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        className={
          localStorage.getItem("Review") === "true"
            ? `${classes.backgroundBlack} ${classes.modal_popup}`
            : classes.modal_popup
        }
      >
        <Grid
          container
          direction="column"
          justify="center"
          alignItems="center"
          className={classes.form_root}
        >
          <form className={classes.form} autoComplete="off">
            <Grid container justify="flex-end" md={12}>
              <CustomButton className={classes.closeBtn} isX>
                <Close_Icon
                  className={classes.joinUs_cross}
                  onClick={() => props.closeJoinUs()}
                />
              </CustomButton>
            </Grid>
            <div className={classes.form_padding}>
              <Grid
                container
                direction="column"
                justify="center"
                alignItems="center"
              >
                <img
                  src="/assets/images/Acorn.png"
                  alt="title"
                  className={classes.dragon_width}
                />
              </Grid>
              <div className={classes.join_us_label}>
                {strings.landing_page.titles.join_us}
              </div>
              <div>
                <CustomTextField
                  label={strings.join_us.titles.firstName}
                  clearIcon
                  onClear={onClearInput("firstName")}
                  // key={firstName}
                  className={`${
                    !get(errors, "firstName.touched", false)
                      ? classes.inp_initial
                      : get(errors, "firstName.error", false)
                      ? classes.inp_error
                      : classes.inp_success
                  } ${classes.textFieldSuccess}`}
                  clearIcon={true}
                  onBlur={(e) => onInputBlur(e, true)}
                  name={"firstName"}
                  defaultValue={firstName}
                  icon={
                    <Element_Required_Icon
                      className={
                        get(errors, "firstName.error", false)
                          ? classes.errorIcon
                          : classes.requiredIcon
                      }
                      color={color.form_colors.blueberry_purple}
                    />
                  }
                />
                {get(errors, "firstName.error", false) && (
                  <span className={classes.errorText}>
                    {strings.join_us.errors.firstName_min_max}
                  </span>
                )}
              </div>
              <div>
                <CustomTextField
                  onChange={onInputBlur}
                  clearIcon
                  // key={lastName}
                  onBlur={(e) => onInputBlur(e, true)}
                  onClear={onClearInput("lastName")}
                  name={"lastName"}
                  autoComplete="off"
                  value={lastName}
                  label={strings.join_us.titles.lastName}
                  className={`${
                    !get(errors, "lastName.touched", false)
                      ? classes.inp_initial
                      : get(errors, "lastName.error", false)
                      ? classes.inp_error
                      : classes.inp_success
                  } ${classes.textFieldSuccess}`}
                  clearIcon={true}
                  icon={
                    <Element_Required_Icon
                      className={
                        get(errors, "lastName.error", false)
                          ? classes.errorIcon
                          : classes.requiredIcon
                      }
                    />
                  }
                />
                {get(errors, "lastName.error", false) && (
                  <span className={classes.errorText}>
                    {strings.join_us.errors.firstName_min_max}
                  </span>
                )}
              </div>
              {showEmailSuggestion && (
                <div className={classes.auto_email}>
                  <CustomCheckbox
                    label={`Do you mean ${email}com?`}
                    labelPlacement="start"
                    text_transform="none"
                    onChange={onEmailSuggestionChecked(`${email}com`)}
                  />
                  <CustomInputLabel
                    className={classes.prediction_yes}
                    is_input_label={true}
                    label_name="Yes"
                    style={{ color: "#410166" }}
                  />
                </div>
              )}
              <div>
                <CustomTextField
                  onChange={onInputBlur}
                  clearIcon
                  onClear={onClearInput("email")}
                  onBlur={(e) => onInputBlur(e, true)}
                  name={"email"}
                  autoComplete="off"
                  value={email}
                  label={
                    !showEmailSuggestion && strings.join_us.titles.email_address
                  }
                  className={`${
                    !get(errors, "email.touched", false)
                      ? classes.inp_initial
                      : get(errors, "email.error", false)
                      ? classes.inp_error
                      : classes.inp_success
                  } ${classes.textFieldSuccess}`}
                  clearIcon={true}
                  icon={
                    <Element_Required_Icon
                      className={
                        get(errors, "email.error", false)
                          ? classes.errorIcon
                          : classes.requiredIcon
                      }
                    />
                  }
                />
              </div>
              {/* fake username and password block to disable autcomplete for chrome */}
              <div className={classes.displayNone}>
                <input
                  id="username"
                  className={classes.displayNone}
                  type="text"
                  name="fakeusernameremembered"
                  autocomplete="new-password"
                ></input>
                <input
                  id="password"
                  className={classes.displayNone}
                  type="password"
                  name="fakepasswordremembered"
                  autocomplete="new-password"
                ></input>
              </div>
              <div>
                <CustomTextField
                  clearIcon
                  autocomplete="new-password"
                  onChange={(e) => onInputBlur(e, true)}
                  name={"password"}
                  // key={password}
                  value={password}
                  type={showPassword ? "text" : "password"}
                  label={strings.join_us.titles.password}
                  className={`${
                    !get(errors, "password.touched", false)
                      ? classes.inp_initial
                      : get(errors, "password.error", false)
                      ? classes.inp_error
                      : classes.inp_success
                  } ${classes.textFieldSuccess}`}
                  clearIcon={true}
                  icon={
                    <Element_Required_Icon
                      className={
                        get(errors, "password.error", false)
                          ? classes.errorIcon
                          : classes.requiredIcon
                      }
                    />
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        tabIndex="-1"
                        aria-label="toggle password visibility"
                        className={classes.password_icon}
                        onClick={handleClickShowPassword}
                      >
                        {password.length > 0 && (
                          <Close_Icon
                            onMouseUp={onClearInput("password")}
                            style={{
                              height: "7px",
                              width: "7px",
                              cursor: "pointer",
                              zIndex: "1000",
                              marginRight: "10px",
                            }}
                          />
                        )}
                        {showPassword ? (
                          <img
                            src="/assets/images/View Password .svg"
                            alt=""
                            className={classes.cursor}
                          />
                        ) : (
                          <HidePassword_Icon
                            style={{
                              fontSize: "1.3125rem",
                              cursor: "pointer",
                            }}
                          />
                        )}
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              <div>
                <span
                  className={
                    !get(errors, "password.touched", false)
                      ? classes.password_validation
                      : get(errors, "password.hasEight", false)
                      ? classes.successText
                      : classes.errorText
                  }
                >
                  {strings.join_us.messages.eight_chars}
                </span>
                <span
                  className={
                    !get(errors, "password.touched", false)
                      ? classes.password_validation
                      : get(errors, "password.hasCaps", false)
                      ? classes.successText
                      : classes.errorText
                  }
                >
                  {strings.join_us.messages.one_letter}
                </span>
                <span
                  className={
                    !get(errors, "password.touched", false)
                      ? classes.password_validation
                      : get(errors, "password.numOrSymbol", false)
                      ? classes.successText
                      : classes.errorText
                  }
                >
                  {strings.join_us.messages.number_symbol}
                </span>
              </div>
              <div>
                <CustomTextField
                  clearIcon
                  onChange={onInputBlur}
                  name={"phoneNumber"}
                  label={strings.join_us.titles.phone_number}
                  value={
                    get(phoneNumber, "length", 0) === 10
                      ? formatPhoneNumber(phoneNumber)
                      : phoneNumber
                  }
                  onBlur={(e) => onInputBlur(e, true)}
                  inputProps={{
                    maxLength: countryCode === "USA" ? 10 : 20,
                    onFocus: onPhoneNumberFocus,
                  }}
                  style={{ marginLeft: "23px" }}
                  className={`${
                    !get(errors, "phoneNumber.touched", false) ||
                    get(phoneNumber, "length") === 0
                      ? classes.inp_initial
                      : get(errors, "phoneNumber.error", false)
                      ? classes.inp_error
                      : classes.inp_success
                  } ${classes.textFieldSuccess}`}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        tabIndex="-1"
                        aria-label="toggle password visibility"
                        className={classes.password_icon}
                      >
                        <div className={classes.phoneNumberActions}>
                          <Close_Icon
                            className={classes.phonenum_clr}
                            onMouseUp={onClearInput("phoneNumber")}
                          />
                          <img
                            src={
                              countryCode === "USA"
                                ? "/assets/images/usa-flag-icon.png"
                                : "/assets/images/nonUSA.PNG"
                            }
                            className={classes.countryImage}
                            alt="USA"
                          />
                          <Chevron_Down_Icon
                            className={classes.dropDownIcon}
                            onClick={triggerCountryDropdown}
                          />
                          {showCountryDropdown && (
                            <div className={classes.dropdownOptions}>
                              <div
                                onClick={() => {
                                  handleCountryChange("USA");
                                }}
                              >
                                USA
                              </div>
                              <div
                                onClick={() => {
                                  handleCountryChange("nonUSA");
                                }}
                              >
                                Non-USA
                              </div>
                            </div>
                          )}
                        </div>
                      </InputAdornment>
                    ),
                  }}
                  error={false}
                />
                <div className={classes.phone_suggestion}>
                  {strings.join_us.messages.option_login}
                </div>
              </div>
              <div className={classes.errorMessage}>{errorMessage}</div>
              <Grid container item justify="center" alignItems="center">
                <CustomButton
                  variant="outlined"
                  color="primary"
                  size="large"
                  isX
                  custom_type="submit"
                  className={classes.join_us_button}
                  disabled={disableSubmit}
                  onMouseUp={submitForm}
                  endIcon={
                    !disableSubmit && (
                      <Ribbon
                        color="primary"
                        style={{
                          fontSize: "35px",
                          marginTop: "-18px",
                          marginRight: "-4px",
                        }}
                      />
                    )
                  }
                >
                  {strings.join_us.titles.join}
                </CustomButton>
              </Grid>
              <Grid
                container
                item
                justify="center"
                alignItems="center"
                className={
                  showSignInError
                    ? classes.account_exists_error
                    : classes.account_exists
                }
              >
                {strings.join_us.titles.account_exists} &nbsp;
                <CustomLink
                  underline="always"
                  className={
                    showSignInError ? classes.error_text : classes.cursor
                  }
                  onClick={openLoginModal}
                >
                  <label className={classes.cursor}>
                    {strings.join_us.titles.sign_in}
                  </label>
                </CustomLink>
              </Grid>
              <Grid
                container
                item
                justify="center"
                alignItems="center"
                className={classes.terms_services}
              >
                {strings.join_us.titles.sign_up_fr} &nbsp;
                <CustomLink underline="always">
                  <label>{strings.join_us.titles.terms_services}</label>
                </CustomLink>
              </Grid>
            </div>
          </form>
          <div className={classes.modal_root}>
            {/* <Modal
              open={showTinyPopover && closeTinyPopover}
              aria-labelledby="modal-title"
              disablePortal
              disableEnforceFocus
              disableAutoFocus
              disableScrollLock={true}
              hideBackdrop={true}
              aria-describedby="modal-description"
              className={classes.modal_root}
            >
              <div className={classes.modal}>
                <Close_Icon
                  className={classes.cross_icon}
                  onClick={closePopoverModal}
                />
                <p className={classes.modal_title} id="modal-title">
                  {strings.join_us.titles.profile_name}:
                </p>
                <p className={classes.modal_description} id="modal-description">
                  {strings.join_us.titles.popover_description}
                </p>
              </div>
            </Modal> */}
          </div>
        </Grid>
      </CustomModal>
    </>
  );
}
export default styleSheet(Joinus);
