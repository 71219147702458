import { withStyles } from "@material-ui/core/styles";
import tradework_theme, { pxToRem, color } from "../../../utilities/themes";

const styles = withStyles({
  dashboardWidth: {
    width: pxToRem(912),
    height: pxToRem(624),
    border: `solid ${pxToRem(2)} ${color.primary_palette.free_purple}`,
    backgroundColor: color.secondary_palette.blues.modal_blue,
    position: "relative",
  },
  btnRight: {
    textAlign: "right",
  },
  cursorMove: {
    cursor: "move",
  },
  save_item: {
    marginBottom: pxToRem(10),
    width: pxToRem(121),
    height: pxToRem(25),
    borderRadius: pxToRem(27),
    border: `solid ${pxToRem(1)} ${color.primary_palette.franklin_purple}`,
    backgroundColor: color.primary_palette.white,
    marginTop: pxToRem(22),
    "& .MuiButton-label": {
      ...tradework_theme.typography.styles.avenir_sb,
      fontSize: pxToRem(15),
      color: color.primary_palette.franklin_purple,
    },
  },
  crossBtn: {
    minWidth: pxToRem(30),
    padding: pxToRem(16),
    "& .MuiSvgIcon-root": {
      fontSize: pxToRem(15),
    },
  },
  textCenter: {
    textAlign: "center",
  },
  listTextArea: {
    display: "flex",
  },
  hamburger_icon: {
    cursor: "move",
    position: "relative",
    top: pxToRem(-4),
    width: pxToRem(9),
  },
  product_img: {
    width: pxToRem(162),
    height: pxToRem(162),
    border: `solid ${pxToRem(1)} ${color.primary_palette.franklin_purple}`,
    backgroundColor: color.secondary_palette.blues.tile_blue,
    position: "relative",
    marginRight: pxToRem(13),
    marginBottom: pxToRem(13),
    display: "inline-block",
    zIndex: "99999",
    "&:hover": {
      transform: "scale(1.06)",
    },
  },
  prod_pub_height: {
    minHeight: pxToRem(470),
  },
  product_img_active: {
    width: pxToRem(190),
    height: pxToRem(180),
    border: `solid ${pxToRem(1.1)} ${color.primary_palette.franklin_purple}`,
    backgroundColor: color.secondary_palette.blues.tile_blue,
    position: "relative",
    marginRight: pxToRem(13),
    display: "inline-block",
    zIndex: "99999",
    boxShadow: `${pxToRem(5)} ${pxToRem(5)} ${pxToRem(5)} 0 rgba(0, 0, 0, 0.4)`,
  },
  displayInline: {
    display: "inline-block",
  },
  spacing: {
    paddingBottom: pxToRem(60),
    paddingLeft: pxToRem(45),
    paddingRight: pxToRem(6),
    marginTop: pxToRem(-16),
  },
  spaceBetween: {
    justifyContent: "space-between",
  },
  padding_Top_27: {
    paddingTop: pxToRem(27),
  },
  padding_Top_22: {
    paddingTop: pxToRem(22),
  },
  padding_Top_8: {
    paddingTop: pxToRem(8),
  },
  edit_icon: {
    fontSize: pxToRem(60),
    position: "absolute",
    top: pxToRem(-27),
    left: "47%",
  },
  trash_can: {
    fontSize: pxToRem(9),
    display: "block",
    paddingBottom: pxToRem(5),
    cursor: "pointer",
  },
  hamburger: {
    width: `${pxToRem(9)} !important`,
    height: `${pxToRem(9)} !important`,
    display: "block",
    paddingBottom: pxToRem(5),
  },
  icons: {
    position: "absolute",
    display: "block",
    paddingBottom: pxToRem(5),
    top: pxToRem(5),
    right: pxToRem(5),
  },
  product_block_img: {
    width: pxToRem(124),
    height: pxToRem(148),
    marginLeft: pxToRem(16),
    marginTop: pxToRem(7),
  },
  product_block_img_active: {
    width: pxToRem(150),
    height: pxToRem(160),
    marginLeft: pxToRem(16),
    marginTop: pxToRem(7),
  },
  product_individual: {
    width: pxToRem(146),
    height: pxToRem(162),
    marginTop: pxToRem(5),
    "& img": {
      width: "100%",
      height: "100%",
    },
  },
  product_icon: {
    width: pxToRem(800),
    overflowX: "auto",
    "& ul": {
      marginTop: pxToRem(5),
    },
  },
  cursorPointer: {
    cursor: "pointer",
  },
  ulAlign: {
    paddingLeft: pxToRem(13),
  },
  addImage_default: {
    width: pxToRem(130),
    height: pxToRem(162),
    marginTop: pxToRem(5),
    textAlign: "center",
    border: `solid ${pxToRem(1)} ${color.secondary_palette.grays.shadow_gray}`,
    background: color.primary_palette.white,
    position: "relative",
    cursor: " pointer",
    "& p": {
      textTransform: "uppercase",
    },
    "&:hover": {
      border: `solid ${pxToRem(1)} ${color.primary_palette.franklin_purple}`,
      "& .addHover_effect": {
        "& ellipse": {
          fill: color.green_world,
        },
      },
      "& p": {
        color: color.secondary_palette.grays.medium_gray,
      },
    },
  },
  addHover: {
    fontSize: pxToRem(78),
    padding: pxToRem(26),
  },
});

export default styles;
