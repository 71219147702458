import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";
import { makeStyles, withStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    "& :hover": {},
  },
}));

function Location(props) {
  const classes = useStyles(props);
  function styledIcon(styledProps) {
    return (
      <span className={classes.root}>
        <SvgIcon {...styledProps} viewBox="0 0 18 25">
          <path
            d="M8.821 4.29a4.456 4.456 0 110 8.913 4.456 4.456 0 010-8.912zm-7.369 9.387l7.37 11.18 7.368-11.18a8.823 8.823 0 10-14.735 0h-.003z"
            fill="#410166"
            fillRule="evenodd"
          />
        </SvgIcon>
      </span>
    );
  }

  /**
   * Custom styling the colors
   */
  const CustomLocation = withStyles({
    root: {
      "& > svg": {
        fontSize: "1.65rem",
      },
      "& :hover": {},
    },
  })(styledIcon);
  return (
    <>
      <CustomLocation {...props} />
    </>
  );
}

export default Location;
