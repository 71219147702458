import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";
import { makeStyles, withStyles } from "@material-ui/core/styles";

import { color } from "../../../utilities/themes";

const useStyles = makeStyles((theme) => ({
  root: {
    "& :hover": {},
  },
}));

const getColor = (propsVal, line_type) => {
  let colorValue = propsVal.color
    ? propsVal.color
    : color.secondary_palette.grays.light_gray;

  if (propsVal && propsVal.color === "primary") {
    if (line_type === "stroke") {
      colorValue = color.primary_palette.franklin_purple;
    }
    if (line_type === "fill") {
      colorValue = color.primary_palette.white;
    }
  }

  return colorValue;
};

function Lil_Plus_filled(props) {
  const classes = useStyles(props);
  function styledIcon(styledProps) {
    return (
      <span className={classes.root}>
        <SvgIcon {...styledProps} viewBox="0 0 12 12">
          <g fill="none" fillRule="evenodd">
            <path
              d="M6 12A6 6 0 116 0a6 6 0 010 12z"
              fill={getColor(styledProps, "fill")}
            />
            <path
              fill="#FEFEFE"
              d="M10.5 4.998H7.003V1.5H4.998v3.498H1.5v2.004h3.498V10.5h2.005V7.002H10.5z"
            />
          </g>
        </SvgIcon>
      </span>
    );
  }

  /**
   * Custom styling the colors
   */
  const Custom_Lil_Plus_filled = withStyles({
    root: {
      "& > svg": {
        fontSize: "1.65rem",
      },
      "& :hover": {},
    },
  })(styledIcon);
  return (
    <>
      <Custom_Lil_Plus_filled {...props} />
    </>
  );
}

export default Lil_Plus_filled;
