import { withStyles } from "@material-ui/core/styles";
import tradework_theme, { pxToRem, color } from "../../../utilities/themes";

const styles = withStyles({
  portfolioModal: {
    width: pxToRem(686),
    border: `solid ${pxToRem(2)} ${color.primary_palette.free_purple}`,
    backgroundColor: color.secondary_palette.blues.modal_blue,
    margin: "0 auto",
    paddingBottom: pxToRem(10),
  },
  btnRight: {
    textAlign: "right",
  },
  margin_top_14: {
    marginTop: pxToRem(-14),
  },
  crossBtn: {
    minWidth: pxToRem(30),
    padding: pxToRem(16),
    "& .MuiSvgIcon-root": {
      fontSize: pxToRem(15),
    },
  },
  textCenter: {
    textAlign: "center",
  },
  Greeting_Card: {
    width: pxToRem(75),
    height: pxToRem(76),
    display: "inline-block",
  },
  textAlign: {
    display: "inline-block",
  },
  current_text: {
    width: "83%",
    margin: "0 auto",
  },
  regular_text: {
    margin: "0 auto",
  },
  lower_base: {
    padding: `${pxToRem(0)} ${pxToRem(39)}`,
  },
  okBtn: {
    width: pxToRem(106),
    height: pxToRem(25),
    borderRadius: pxToRem(27),
    border: `solid ${pxToRem(1.2)} ${color.primary_palette.franklin_purple}`,
    backgroundColor: color.primary_palette.white,
    marginBottom: pxToRem(14),
    "& .MuiButton-label": {
      color: color.primary_palette.franklin_purple,
      ...tradework_theme.typography.styles.avenir_black_r,
      textTransform: "none",
      fontSize: pxToRem(11.6),
    },
  },
  inlineBlock: {
    display: "inline-block",
    marginRight: pxToRem(20),
    position: "relative",
    top: pxToRem(10),
  },
  marginTop20: {
    marginTop: pxToRem(22),
    marginBottom: pxToRem(14),
  },
  marginBottom14: {
    marginBottom: pxToRem(14),
  },
  headingTxtAlign: {
    padding: `${pxToRem(0)} ${pxToRem(34)}`,
    marginBottom: pxToRem(8),
  },
});

export default styles;
