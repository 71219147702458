import { withStyles } from "@material-ui/core/styles";
import tradework_theme, { pxToRem, color } from "../../../utilities/themes";

const styles = withStyles({
  profile_width: {
    width: pxToRem(930),
    border: `solid ${pxToRem(1)} ${color.primary_palette.free_purple}`,
    backgroundColor: color.secondary_palette.blues.modal_blue,
    position: "relative",
  },
  textRight: {
    textAlign: "right",
  },
  cross_mark: {
    fontSize: pxToRem(15),
  },
  cross_btn: {
    minWidth: pxToRem(40),
    padding: pxToRem(16),
  },
  edit_pencil: {
    width: pxToRem(55),
    position: "absolute",
    left: 0,
    right: 0,
    top: pxToRem(-20),
    margin: "0 auto",
    "& .MuiSvgIcon-root": {
      fontSize: pxToRem(55),
    },
  },
  circle: {
    width: pxToRem(169),
    height: pxToRem(169),
    borderRadius: "50%",
    "&:hover": {
      cursor: "pointer",
    },
  },
  mainTextsize: {
    fontSize: pxToRem(20),
    ...tradework_theme.typography.styles.avenir_sb,
    color: color.primary_palette.franklin_purple,
    "&:hover": {
      cursor: "pointer",
      textDecoration: "underline",
    },
  },
  subTextsize: {
    fontSize: pxToRem(14),
    ...tradework_theme.typography.styles.avenir_sb,
    color: color.primary_palette.black,
  },
  sizeTextStyle: {
    fontSize: pxToRem(12),
    ...tradework_theme.typography.styles.avenir_sb,
    color: color.primary_palette.black,
  },
  spacing_imgs: {
    padding: `${pxToRem(13)} ${pxToRem(0)}`,
  },
  rectangle: {
    width: pxToRem(79),
    height: pxToRem(79),
    "&:hover": {
      cursor: "pointer",
    },
  },
  square: {
    width: pxToRem(326),
    height: pxToRem(163),
    "&:hover": {
      cursor: "pointer",
    },
  },
  continue_btn: {
    width: pxToRem(130),
    height: pxToRem(25),
    borderRadius: pxToRem(27),
    border: `solid ${pxToRem(1)} ${color.primary_palette.franklin_purple}`,
    backgroundColor: color.primary_palette.white,
    "& .MuiButton-label": {
      fontSize: pxToRem(15),
      color: color.primary_palette.franklin_purple,
      ...tradework_theme.typography.styles.avenir_sb,
    },
  },
  btnAlign: {
    justifyContent: "flex-end",
    width: "87%",
    paddingBottom: pxToRem(36),
  },
  padding_block: {
    padding: `${pxToRem(0)} ${pxToRem(50)}`,
  },
  title_text: {
    textAlign: "center",
    textTransform: "uppercase",
  },
  dimensions_fix: {
    width: "50%",
    flexGrow: 1,
  },
  customScroll: {
    minHeight: pxToRem(580),
  },
});

export default styles;
