import React, { useState } from "react";
import { get, startsWith } from "lodash";

import BusinessOneStyles from "./styles/businessOneStyles";
import Row from "../common/ui_kit/row";
import Text from "../common/ui_kit/text";
import Chevron_Down_Icon from "../data_display/icons/Arrow_Chevron_Down";
import { color } from "../../utilities/themes";
import { PHONE_NUMBER_FORMAT } from "../../validations/validations";
import BusinessOne from "./businessCard_step1";
import CustomModal from "../inputs/custom_modal";

function BusinessPrefilled(props) {
  const { classes, businessCard } = props;

  const [state, setState] = useState({
    openEditBusinessModal: false,
  });

  const { openEditBusinessModal } = state;

  const formatPhoneNumber = (phoneNumber) => {
    return phoneNumber.replace(PHONE_NUMBER_FORMAT, "$1.$2.$3");
  };

  const handleBusinessModal = () => {
    setState({
      ...state,
      openEditBusinessModal: !openEditBusinessModal,
    });
  };

  const getUrl = (url) => {
    if (url) {
      if (startsWith(url, "http://") || startsWith(url, "https://")) {
        return url;
      }
      return `//${url}`;
    }
    return "javascript:void(0)";
  };

  const mailTo = (email) => () => {
    window.location.href = `mailto:${email}`;
    return "javascript:void(0)";
  };

  return (
    <>
      <div className={classes.BusinessCard_width}>
        <div>
          <Text
            size={30}
            color={color.primary_palette.black}
            family="TrajanPro3"
            className={`${classes.txtCenter} ${classes.txtCapitalise}`}
            transform="capitalize"
          >
            {get(businessCard, "name", "")}
          </Text>
        </div>
        <div className={`${classes.position_relative} ${classes.flag_blk}`}>
          <Text
            size={10}
            color={color.knight_armour}
            family="avenir_light"
            className={classes.flexRight}
          >
            <img
              src="/assets/images/global_usa.png"
              className={classes.flag_icon}
              alt=""
            />
            USA
            <Chevron_Down_Icon className={classes.drpDown} />
          </Text>
        </div>
        <div className={classes.card_spacing}>
          <Row>
            <div className={`${classes.width_50} ${classes.padding_right_15}`}>
              <Text
                size={16}
                color={color.primary_palette.black}
                family="avenir_light"
                className={classes.txtCenter}
              >
                {get(businessCard, "address", "")}
              </Text>
            </div>
            <div className={classes.width_50}>
              <Text
                size={16}
                color={color.primary_palette.black}
                family="avenir_light"
                className={classes.txtCenter}
              >
                {formatPhoneNumber(get(businessCard, "phoneNumber", ""))}
              </Text>
            </div>
          </Row>
          <Row>
            <div className={`${classes.width_50} ${classes.padding_right_15}`}>
              <Text
                size={16}
                color={color.primary_palette.black}
                family="avenir_light"
                className={classes.txtCenter}
              >
                {get(businessCard, "address1") || (
                  <span>
                    {" "}
                    {get(businessCard, "city", "City")}
                    {get(businessCard, "state", "") &&
                      get(businessCard, "city", "City") &&
                      ", "}
                    {get(businessCard, "state", "")}&nbsp;
                    {get(businessCard, "zip", "Zip")}
                  </span>
                )}
              </Text>
            </div>
            <div className={classes.width_50}>
              <Text
                size={16}
                color={color.primary_palette.black}
                family="avenir_light"
                className={classes.txtCenter}
              >
                <span
                  onClick={mailTo(get(businessCard, "email"))}
                  className={classes.emailActiveStyle}
                >
                  {get(businessCard, "email", "")}
                </span>
              </Text>
            </div>
          </Row>
          <Row>
            <div className={`${classes.width_50} ${classes.padding_right_15}`}>
              <Text
                size={16}
                color={color.primary_palette.black}
                family="avenir_light"
                className={classes.txtCenter}
              >
                {get(businessCard, "address1") && (
                  <span>
                    {" "}
                    {get(businessCard, "city", "City")}
                    {get(businessCard, "state", "") &&
                      get(businessCard, "city", "") &&
                      ", "}
                    {get(businessCard, "state", "")}&nbsp;
                    {get(businessCard, "zip", "Zip")}{" "}
                  </span>
                )}
              </Text>
            </div>
            <div className={classes.width_50}>
              <Text
                size={16}
                color={color.primary_palette.black}
                family="avenir_light"
                className={classes.txtCenter}
              >
                <a
                  href={getUrl(get(businessCard, "webSite"))}
                  target="_blank"
                  className={classes.websiteTxt}
                >
                  {get(businessCard, "webSite", "")}
                </a>
              </Text>
            </div>
          </Row>
          {/* <Row>
            <div className={`${classes.width_50} ${classes.padding_right_15}`}>
              <Text
                size={16}
                color={color.primary_palette.black}
                family="avenir_light"
                className={`${classes.txtCenter} ${classes.stateZipAlign}`}
              >
                {get(businessCard, "state", "")}
              </Text>
              <Text
                size={16}
                color={color.primary_palette.black}
                family="avenir_light"
                className={`${classes.txtCenter} ${classes.stateZipAlign}`}
              >
                {get(businessCard, "zip", "")}
              </Text>
            </div>
          </Row> */}
          <div
            className={`${classes.position_relative} ${classes.margin_bottom_9}`}
          >
            <img
              src="/assets/icons/pencil.svg"
              className={classes.pencil_icon}
              onClick={handleBusinessModal}
              alt=""
            />
          </div>
        </div>
      </div>
      <CustomModal
        open={openEditBusinessModal}
        onClose={handleBusinessModal}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <BusinessOne
          onClose={handleBusinessModal}
          businessCard={businessCard}
        />
      </CustomModal>
    </>
  );
}

export default BusinessOneStyles(BusinessPrefilled);
