import { withStyles } from "@material-ui/core/styles";
import tradework_theme, { pxToRem, color } from "../../../utilities/themes";

const styles = withStyles({
  portfolio_block: {
    width: pxToRem(1218),
    margin: "0 auto",
    paddingTop: pxToRem(24),
    paddingBottom: pxToRem(40),
    marginTop: pxToRem(64),
  },
  pointerNone: {
    pointerEvents: "none",
    opacity: "0.6",
  },
  alignPortfolioMenu: {
    width: pxToRem(490),
  },
  left_side: {
    width: "40%",
  },
  right_side: {
    width: "60%",
    display: "block",
    position: "relative",
  },
  block_group: {
    display: "block",
    textAlign: "center",
  },
  free: {
    ...tradework_theme.typography.styles.gillsans_sb,
    color: color.primary_palette.free_purple,
  },
  free_portfolio: {
    textAlign: "left",
    paddingLeft: pxToRem(12),
  },
  build_des: {
    width: "68%",
    margin: "0 auto",
    paddingTop: pxToRem(11),
  },
  build_des_1: {
    width: "68%",
    margin: "0 auto",
  },
  free_block: {
    width: "85%",
    margin: "0 auto",
    paddingTop: pxToRem(130),
  },
  current_franklin: {
    width: "62%",
    margin: "0 auto",
    paddingTop: pxToRem(30),
  },
  build_text: {
    margin: "0 auto",
    marginBottom: pxToRem(29),
  },
  headerRadioAlign: {
    marginTop: pxToRem(10),
  },
  build_btn: {
    width: pxToRem(212),
    height: pxToRem(43),
    borderRadius: pxToRem(27),
    border: `solid ${pxToRem(2)} ${color.primary_palette.franklin_purple}`,
    color: `${color.primary_palette.white} !important`,
    marginTop: pxToRem(40),
    marginBottom: pxToRem(90),
    "& .MuiButton-label": {
      fontSize: pxToRem(20),
      ...tradework_theme.typography.styles.avenir_sb,
      color: color.primary_palette.franklin_purple,
    },
  },
  justify: {
    justifyContent: "center",
  },
  static_img: {
    width: pxToRem(316),
    height: pxToRem(720),
  },
  left_text: {
    display: "block",
    textAlign: "right",
    paddingRight: pxToRem(16),
  },
  profile_cover: {
    paddingTop: pxToRem(10),
    width: pxToRem(85),
    textAlign: "center",
    marginLeft: "40%",
  },
  company_snapshot: {
    paddingTop: pxToRem(40),
  },
  business_card: {
    paddingTop: pxToRem(18),
  },
  vignettes: {
    paddingTop: pxToRem(74),
  },
  unliited_projects: {
    paddingTop: pxToRem(90),
    width: pxToRem(131),
  },
  products: {
    paddingTop: pxToRem(264),
  },
  instagram: {
    paddingTop: pxToRem(52),
  },
  arrow_img: {
    width: pxToRem(267),
    paddingTop: pxToRem(33),
  },
  greeting_img: {
    width: pxToRem(77),
  },
  future_rate_card: {
    position: "relative",
    top: pxToRem(-30),
    cursor: "pointer",
    borderBottom: `solid ${pxToRem(1)} ${
      color.secondary_palette.grays.shadow_gray
    }`,
    lineHeight: pxToRem(20),
  },
  textsBlockAlign: {
    textAlign: "left",
    flexDirection: "column !important",
  },
  headerTextAlign: {
    marginTop: pxToRem(23),
  },
  textCenter: {
    textAlign: "center",
  },
  orAlign: {
    marginTop: pxToRem(20),
  },
  width_155: {
    width: pxToRem(155),
  },
  width_270: {
    width: pxToRem(270),
  },
  select_items: {
    height: pxToRem(20),
    borderRadius: pxToRem(2),
    marginRight: pxToRem(8),
    marginTop: pxToRem(6),
    backgroundColor: color.primary_palette.white,
    border: `solid ${pxToRem(1)} ${color.secondary_palette.grays.shadow_gray}`,
    "& .MuiSvgIcon-root": {
      fontSize: pxToRem(14),
    },
    "& p": {
      ...tradework_theme.typography.styles.avenir_sb,
      fontSize: pxToRem(15),
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    },
  },
  portfolio_items: {
    width: pxToRem(370),
    height: pxToRem(20),
    borderRadius: pxToRem(2),
    marginRight: pxToRem(8),
    marginTop: pxToRem(6),
    marginLeft: pxToRem(16),
    backgroundColor: color.primary_palette.white,
    border: `solid ${pxToRem(1)} ${color.secondary_palette.grays.shadow_gray}`,
    "& .MuiSvgIcon-root": {
      fontSize: pxToRem(14),
    },
    "& p": {
      ...tradework_theme.typography.styles.avenir_sb,
      fontSize: pxToRem(15),
    },
  },
  portfolio_name_select: {
    width: pxToRem(410),
    height: pxToRem(20),
    borderRadius: pxToRem(2),
    marginRight: pxToRem(8),
    marginTop: pxToRem(6),
    backgroundColor: color.primary_palette.white,
    border: `solid ${pxToRem(1)} ${color.secondary_palette.grays.shadow_gray}`,
    "& .MuiSvgIcon-root": {
      fontSize: pxToRem(14),
    },
    "& p": {
      ...tradework_theme.typography.styles.avenir_sb,
      fontSize: pxToRem(15),
    },
  },
  add_btn: {
    width: pxToRem(59),
    height: pxToRem(20),
    borderRadius: pxToRem(3),
    marginTop: pxToRem(6),
    padding: 0,
    minWidth: pxToRem(59),
    border: `solid ${pxToRem(1)} ${color.primary_palette.free_purple}`,
    backgroundColor: color.secondary_palette.grays.btn_gray,
    "& .MuiButton-label": {
      fontSize: pxToRem(15),
      ...tradework_theme.typography.styles.avenir_sb,
      color: color.primary_palette.free_purple,
      lineHeight: pxToRem(15),
    },
  },
  coloredCrossStyle: {
    width: pxToRem(14),
    marginLeft: pxToRem(4),
    position: "relative",
    top: pxToRem(6),
    cursor: "pointer",
    "& path": {
      fill: color.secondary_palette.grays.shadow_gray,
    },
  },
  portfolioNameStyle: {
    textTransform: "uppercase",
    marginLeft: pxToRem(16),
    width: pxToRem(410),
    "& .MuiInputBase-input": {
      // textTransform: "uppercase",
      paddingBottom: pxToRem(0),
      ...tradework_theme.typography.styles.NeutraText,
      color: color.primary_palette.black,
    },
    "&::placeholder": {
      // fontStyle: "oblique",
      color: color.wizard_box_colors.shadow_gray,
      ...tradework_theme.typography.styles.avenir_light,
      opacity: 1,
    },
  },
  portfolioNameStyle_error: {
    textTransform: "uppercase",
    marginLeft: pxToRem(16),
    width: pxToRem(410),
    "& .MuiInputBase-input": {
      textTransform: "uppercase",
      color: color.primary_palette.black,
      ...tradework_theme.typography.styles.NeutraText,
      paddingBottom: pxToRem(0),
      borderBottom: `solid ${pxToRem(1)} ${
        color.primary_palette.christmas_red
      }`,
    },
    "&::placeholder": {
      // fontStyle: "oblique",
      color: color.wizard_box_colors.shadow_gray,
      ...tradework_theme.typography.styles.avenir_light,
      opacity: 1,
    },
  },
  invoicing_accordion: {
    width: "55%",
    cursor: "pointer",
    "& .upIcon": {
      display: "none",
    },
  },

  radioBtn: {
    "& .MuiTypography-root": {
      textTransform: "initial",
    },
  },
  radioBtn_error: {
    "& .MuiTypography-root": {
      textTransform: "initial",
      color: color.primary_palette.christmas_red,
    },
  },
  invoicing: {
    padding: pxToRem(5),
  },
  txtRight: {
    textAlign: "right",
  },
  customScrollHeight: {
    "& li": {
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "normal",
    },
  },
  error: {
    color: color.primary_palette.christmas_red,
    fontSize: pxToRem(16),
    textAlign: "center",
    marginTop: pxToRem(30),
  },
  lil_filled: {
    fontSize: pxToRem(16),
    verticalAlign: "middle",
    paddingRight: pxToRem(7),
    position: "relative",
    top: pxToRem(-2),
    "& path": {
      "&:nth-child(1)": {
        fill: color.secondary_palette.grays.shadow_gray,
      },
    },
  },
  spacing_add_another_office: {
    paddingTop: pxToRem(9),
  },
  notPublic: {
    color: color.not_public_color,
  },
  public: {
    color: color.public_color,
  },
  completed_align: {
    width: "72%",
    margin: "0 auto",
    paddingBottom: pxToRem(3),
  },
  dupeBlockAlign: {
    margin: `${pxToRem(30)} ${pxToRem(0)} ${pxToRem(8)} ${pxToRem(30)}`,
    padding: pxToRem(20),
    borderRadius: pxToRem(7),
    border: `solid ${pxToRem(1)} ${color.primary_palette.franklin_purple}`,
  },
  marginLeft16: {
    marginLeft: pxToRem(16),
  },
  width_567: {
    width: `${pxToRem(567)}`,
  },
  regionListAlign: {
    paddingLeft: pxToRem(30),
    margin: 0,
    paddingTop: pxToRem(14),
    paddingBottom: pxToRem(7),
    "& li": {
      fontSize: pxToRem(15),
      ...tradework_theme.typography.styles.avenir_sb,
      color: color.primary_palette.black,
    },
  },
  categoryAlign: {
    marginLeft: pxToRem(8),
  },
  marginTop6: {
    marginTop: pxToRem(6),
  },
  marginLeft4: {
    marginLeft: pxToRem(4),
  },
  colorPurple: {
    color: color.primary_palette.highlight_purple,
  },
  customScrollHeightCategory: {
    minHeight: `${pxToRem(330)} !important`,
    "& li": {
      overflow: "hidden",
      whiteSpace: "normal",
      textOverflow: "ellipsis",
    },
  },
  textCapital: {
    textTransform: "uppercase",
  },
  saveFlipIcon: {
    cursor: "pointer",
    marginTop: pxToRem(6),
  },
  betaStampIcon: {
    height: pxToRem(100),
    position: "absolute",
    right: pxToRem(0),
    cursor: "pointer",
    top: pxToRem(44),
  },
});

export default styles;
