import React, { useState, useEffect } from "react";
import MenuItem from "@material-ui/core/MenuItem";
import { get, map, filter, find } from "lodash";
import { useSelector, useDispatch } from "react-redux";

import BusinessStyles from "./styles/businessStyle";
import Row from "../common/ui_kit/row";
import Text from "../common/ui_kit/text";
import strings from "../../utilities/strings";
import CustomTextField from "../inputs/custom_textfields";
import CustomSelect from "../common/ui_kit/custom_select";
import Chevron_Down_Icon from "../data_display/icons/Arrow_Chevron_Down";
import CustomButton from "../navigations/custom_buttons";
import CustomScrollbars from "../data_display/custom_scroll";
import { color } from "../../utilities/themes";
import Close_Icon from "../data_display/icons/Close";
import Element_Required_Icon from "../data_display/icons/ElementRequiered";
import CustomCheckbox from "../inputs/custom_checkbox";
import ChevronDownIcon from "../data_display/icons/Arrow_Chevron_Down";
import {
  PHONE_NUMBER_FORMAT,
  REGEX_URL,
  REGEX_EMAIL,
} from "../../validations/validations";
import Styled_cross from "../data_display/icons/styled_cross";

import {
  ProfileActions,
  SettingsActions,
  LookUpActions,
} from "../../redux/actions";

function AddAnotherCard(props) {
  const [state, setState] = useState({
    address: "",
    address1: "",
    city: "",
    email: "",
    webSite: "",
    phoneNumber: "",
    errors: {},
    selectedCompany: "",
    nickname: "",
    isBillingAddress: false,
    isHeadOffice: false,
    displayedWhere: [],
    sevicesOffered: [],
    companyName: "",
    zipCode: "",
    showCountryDD: false,
    isUSA: true,
    selectedRegions: [],
  });
  const { classes, onClose, businessCard, closeBusiness, isPortfolioEdit } =
    props;

  const {
    address,
    address1,
    city,
    email,
    webSite,
    phoneNumber,
    nickname,
    isHeadOffice,
    isBillingAddress,
    displayedWhere,
    isPublicDisplay,
    companyName,
    zipCode,
    showCountryDD,
    stateCode,
    isUSA,
    errors,
    region,
    category,
    selectedRegions,
  } = state;

  const dispatch = useDispatch();
  const [limitError, setLimitError] = useState(false);

  const lookUpData = useSelector((resData) => resData.LookUp.LookUpData);

  const hasFormErrors = () => {
    const allErrors = Object.values(errors).map(
      (errObj) => errObj.error || false
    );
    if (allErrors.includes(true) || !phoneNumber || !nickname) {
      return true;
    }
    return false;
  };
  const fetchData = () => {
    dispatch(LookUpActions.getLookUpData());
  };

  useEffect(() => {
    let userData = localStorage.getItem("profile");
    if (userData !== "undefined") {
      userData = JSON.parse(userData);
    } else {
      userData = {};
    }
    let company = {
      name: localStorage.getItem("companyName"),
      _id: localStorage.getItem("companyId"),
    };
    setState({
      ...state,
      selectedCompany: company,
      email: get(userData, "email", ""),
      phoneNumber: get(userData, "phoneNumber", ""),
      companyName: localStorage.getItem("companyName"),
    });

    fetchData();
  }, []);

  useEffect(() => {
    if (businessCard) {
      let userData = localStorage.getItem("profile");
      if (userData !== "undefined") {
        userData = JSON.parse(userData);
      } else {
        userData = {};
      }
      let company = {
        name: get(businessCard, "companyName", ""),
        _id: get(businessCard, "companyId", ""),
      };
      setState({
        ...state,
        selectedCompany: company,
        email: get(userData, "email", ""),
        phoneNumber: get(userData, "phoneNumber", ""),
        companyName: localStorage.getItem("companyName"),
      });
    }
  }, [businessCard]);

  const formatPhoneNumber = () => {
    if (!phoneNumber) {
      return;
    }
    return phoneNumber.toString().replace(PHONE_NUMBER_FORMAT, "$1.$2.$3");
  };

  const handleInputChange = (e) => {
    if (
      e.target.name === "isHeadOffice" ||
      e.target.name === "isBillingAddress" ||
      e.target.name === "isPublicDisplay"
    ) {
      setState({ ...state, [e.target.name]: e.target.checked });
      return;
    } else if (e.target.name === "phoneNumber") {
      const val = deformatPhoneNumber(e.target.value);
      if (!isUSA) {
        setState({ ...state, phoneNumber: val });
        return;
      } else if (isNaN(val)) {
        if (!val) {
          setState({ ...state, phoneNumber: "" });
        }
        return;
      }
      setState({ ...state, phoneNumber: val });
      return;
    }
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const deformatPhoneNumber = (number) => {
    // DEFORMATTING
    if (!number) {
      return;
    }
    return number.replace(/[().\s/]/g, "");
  };

  const handleValidationOnBlur = (e) => {
    handleValidations(e.target.name, e.target.value);
  };

  const handleMarketSelect = (name, value) => (e) => {
    e.preventDefault();
    e.stopPropagation();
    setState({ ...state, [name]: value });
  };

  const handleDropdownSelect = (name, value, del) => (e) => {
    e.preventDefault();
    e.stopPropagation();
    const checkForDuplicate = find(state[name], {
      name: value.name,
    });
    if (!checkForDuplicate) {
      setState({ ...state, [name]: [...state[name], value] });
    } else {
      if (!del) {
        return;
      }
      const updatedData = filter(state[name], (each) => {
        return each.name != value.name;
      });
      setState({ ...state, [name]: [...updatedData] });
    }
  };

  const handleValidations = (name, value) => {
    if (
      name === "email" ||
      name === "webSite" ||
      name === "phoneNumber" ||
      name === "portfolioName" ||
      name === "companyName" ||
      name === "city" ||
      name === "nickname"
    ) {
      errors[name] = errors[name] || {};
      if (
        (name === "webSite" && value && !REGEX_URL.test(value)) ||
        (name === "email" && (!value || !REGEX_EMAIL.test(value))) ||
        (name === "portfolioName" && !value) ||
        (name == "phoneNumber" && value && value.length < 10) ||
        (name == "phoneNumber" && !value) ||
        (name === "companyName" && !value) ||
        (name === "nickname" && !value)
      ) {
        errors[name] = { error: true, message: "" };
        setState({
          ...state,
        });
        return;
      }
      errors[name] = { error: false, message: "" };
      setState({
        ...state,
      });
      return;
    }
  };

  const clearInput = (nameVal) => () => {
    setState({ ...state, [nameVal]: "" });
  };

  const onPhoneNumberBlur = (e) => {
    // FORMATTING PHONE NUMBER
    handleValidations(e.target.name, e.target.value);
    if (phoneNumber) {
      const num = phoneNumber
        .toString()
        .replace(PHONE_NUMBER_FORMAT, "$1.$2.$3");
      setState({ ...state, phoneNumber: num });
    }
    return;
  };

  const onPhoneNumberFocus = () => {
    // ON FOCUS REMOVING FORMAT
    if (phoneNumber) {
      setState({ ...state, phoneNumber: phoneNumber.replace(/[().\s/]/g, "") });
      return;
    }
  };

  const handleAddRegionClick = () => {
    const { category, region, selectedRegions } = state;
    setLimitError(false);
    errors.selectedRegions = { error: false, message: "" };
    let foundRegion = find(selectedRegions, { region: region });
    if (foundRegion) {
      let foundCategory = filter(
        foundRegion.category,
        (each) => each === category
      )[0];
      if (foundCategory) {
        return;
      }
      foundRegion.category = foundRegion.category || [];
      foundRegion.category = foundRegion.category || [];
      if (foundRegion.category.length >= 4) {
        setLimitError(true);
        return;
      }
      foundRegion.category = [...foundRegion.category, category];
      setState({
        ...state,
        category: "",
        region: "",
      });
      setLimitError(false);
    } else {
      selectedRegions.category = selectedRegions.category || [];
      setState({
        ...state,
        selectedRegions: [
          ...selectedRegions,
          {
            region,
            category: [...selectedRegions.category, category],
          },
        ],
        category: "",
        region: "",
      });
    }
  };

  const deleteRegion = (region, categoryName) => () => {
    const { selectedRegions } = state;
    setLimitError(false);
    const foundRegion = find(selectedRegions, { region });

    if (foundRegion) {
      foundRegion.category = foundRegion.category.filter(
        (each) => each !== categoryName
      );
    }

    if (foundRegion.category.length === 0) {
      const filteredRegions = selectedRegions.filter(
        (each) => each.region !== region
      );
      setState({ ...state, selectedRegions: [...filteredRegions] });
      return;
    }

    setState({ ...state });
  };

  // const handleDropdownSelect = (name, value) => (e) => {
  //   e.preventDefault();
  //   e.stopPropagation();
  //   if (name === "selectedPortfolio" && value) {
  //     localStorage.setItem("portfolioId", value._id);
  //     history.push("/portfolioView");
  //   } else {
  //     localStorage.removeItem("portfolioId");
  //   }
  //   state.errors.listData = { error: false, message: "" };
  //   setState({ ...state, [name]: value });
  // };

  const returnRegionsList = () => {
    const { selectedRegions } = state;
    return (
      <div>
        <ol className={classes.regionListAlign}>
          {map(selectedRegions, (location) => {
            return (
              <li>
                {get(location, "region", "")} -{" "}
                {map(location?.category, (category) => {
                  return (
                    <>
                      <span className={classes.categoryAlign}>
                        {category}{" "}
                        <Styled_cross
                          className={classes.coloredCrossStyle}
                          onClick={deleteRegion(location.region, category)}
                        />
                      </span>
                    </>
                  );
                })}
              </li>
            );
          })}
        </ol>
      </div>
    );
  };

  const submitBusinessCard = () => {
    if (hasError) {
      handleValidations("phoneNumber", state.phoneNumber);
      handleValidations("companyName", state.companyName);
      handleValidations("nickname", state.nickname);
      return;
    }
    if (isPublicDisplay && get(selectedRegions, "length") === 0) {
      errors.selectedRegions = { error: true, message: "" };
      setState({
        ...state,
      });
      return;
    }
    const dataToSubmit = [
      {
        ...state,
        sevicesOffered: map(state.sevicesOffered, "name"),
        displayedWhere: map(state.displayedWhere, "name"),
        companyName: businessCard.companyName,
        companyId: localStorage.getItem("companyId"),
        name: companyName,
        isPortfolioEdit: isPortfolioEdit ? true : false,
        portfolioId: isPortfolioEdit ? localStorage.getItem("portfolioId") : "",
        marketsAndServices: selectedRegions,
      },
    ];
    const businessCards = { businessCards: dataToSubmit };
    dispatch(
      SettingsActions.updateOfficeLocationsData(
        localStorage.getItem("companyId"),
        businessCards,
        (res) => {
          if (!res.error.error) {
            dispatch(
              SettingsActions.getCompanyLocationsData(
                localStorage.getItem("companyId")
              )
            );
            dispatch(
              ProfileActions.getPortfolioData(
                localStorage.getItem("portfolioId")
              )
            );
            onClose();
            closeBusiness();
          } else {
            setState({ ...state, errorMessage: res.error.message.message });
          }
        }
      )
    );
  };

  // Method responsible to return categorized menu items
  const renderCategorizedMenu = (menuData, type) => {
    // list of color codes to be appied for category tiles based on index
    const colorCodes = get(lookUpData, "configData.dropdownColorCodes", []);
    // fetching category names
    const groups = Object.keys(menuData)
      .filter((each) => each !== "noParent")
      .sort();
    // pushing nonParent items to array
    let menuItems = menuData.noParent
      ? menuData.noParent.map((each) => each)
      : [];

    groups.map((eachGroup, index) => {
      // interating group titles and pushint to menu item array
      let subGroupItems = [{ name: eachGroup, color: colorCodes[index] }];
      menuData[eachGroup].map((eachGroupItem) => {
        // iterating subgroup items
        subGroupItems.push(eachGroupItem);
      });
      // concatinating menuItems which are returned and categorized items
      menuItems = [...menuItems, ...subGroupItems];
      return null;
    });

    // mapping menuItems (objects) and returning JSX
    return menuItems.map((eachItem) => {
      return (
        <MenuItem
          style={{ background: eachItem.color }}
          onClick={!eachItem.color && handleMarketSelect(type, eachItem.name)}
          className={eachItem.color && classes.textCapital}
        >
          {eachItem.name}
        </MenuItem>
      );
    });
  };

  const handleUSADropdown = () => {
    setState({ ...state, showCountryDD: !showCountryDD });
  };

  const handleCountrySelect = (value) => () => {
    setState({
      ...state,
      isUSA: value === "USA",
      showCountryDD: false,
      phoneNumber: "",
    });
  };

  const hasError = hasFormErrors();

  return (
    <>
      <div className={classes.modal_bussinesscard_new}>
        <div className={classes.txtRight}>
          <Close_Icon className={classes.close_mark} onClick={onClose} />
        </div>
        <Row className={`${classes.free_block_new}`}>
          <img
            src="/assets/icons/fr_stamp.svg"
            className={classes.stamp_width}
            alt=""
          />
          <Row className={`${classes.main_text} ${classes.border_none}`}>
            <Text
              size={26}
              color={color.primary_palette.black}
              family="avenir_sb"
            >
              {strings.business_card.titles.add_another_card}{" "}
              {localStorage.getItem("companyName")}
            </Text>
          </Row>
        </Row>
        <Row className={classes.business_width_office}>
          <Row className={classes.inner_block}>
            <Row className={classes.display_blk}>
              <Element_Required_Icon
                className={
                  get(state.errors, "companyName.error", "")
                    ? classes.select_field_required_error_another
                    : classes.select_field_required_another
                }
              />
              <CustomTextField
                placeholder="Company Name"
                name="companyName"
                value={companyName}
                className={
                  get(state.errors, "companyName.error", "")
                    ? classes.select_field_error
                    : classes.select_field
                }
                onChange={handleInputChange}
                onBlur={handleValidationOnBlur}
              />
            </Row>
            <Row className={classes.flex}>
              <div className={classes.textFiled}>
                <CustomTextField
                  className={classes.input_field}
                  label={strings.business_card.titles.company_add1}
                  name="address"
                  value={address}
                  onChange={handleInputChange}
                />
              </div>
              <div className={classes.textFiled}>
                <Element_Required_Icon
                  className={
                    get(state.errors, "email.error", "")
                      ? classes.required_error
                      : classes.field_icon
                  }
                />
                <CustomTextField
                  className={`${
                    get(state.errors, "email.error", "") && classes.error_field
                  } ${classes.input_field} ${classes.email_field_business}`}
                  label={strings.business_card.titles.email}
                  name="email"
                  value={email}
                  onChange={handleInputChange}
                  onBlur={handleValidationOnBlur}
                />
                {/* {get(state.errors, "email.error", "") && (
                  <Text
                    size={10}
                    color={color.primary_palette.christmas_red}
                    family="gillsans_r"
                    className={classes.error_text_email}
                  >
                    <img src="/assets/icons/info_1.svg" class="info_img" alt=""/>{" "}
                    Email
                  </Text>
                )} */}
              </div>
              <div className={classes.textFiled}>
                <CustomTextField
                  className={classes.input_field}
                  label={strings.business_card.titles.company_add2}
                  name="address1"
                  value={address1}
                  onChange={handleInputChange}
                />
              </div>
              <div className={classes.textFiled}>
                <CustomTextField
                  className={`${
                    get(state.errors, "webSite.error", "") &&
                    classes.error_field
                  } ${classes.input_field} ${classes.website_field}`}
                  label={strings.business_card.titles.website}
                  name="webSite"
                  value={webSite}
                  onChange={handleInputChange}
                  onBlur={handleValidationOnBlur}
                />
                {/* {get(state.errors, "webSite.error", "") && (
                  <Text
                    size={10}
                    color={color.primary_palette.christmas_red}
                    family="gillsans_r"
                    className={classes.error_text_email}
                  >
                    <img src="/assets/icons/info_1.svg" class="info_img" alt=""/>{" "}
                    Website
                  </Text>
                )} */}
              </div>
              <div className={classes.last_field}>
                <div className={classes.textFiled_last}>
                  <CustomTextField
                    className={`${
                      get(state.errors, "city.error", "") && classes.error_field
                    } ${classes.input_field} ${classes.city_field_card}`}
                    label={strings.business_card.titles.city}
                    name="city"
                    value={city}
                    onChange={handleInputChange}
                    onBlur={handleValidationOnBlur}
                  />
                </div>
                <div className={classes.textFiled_last}>
                  <CustomTextField
                    className={`${classes.input_field} ${classes.st_field}`}
                    label={strings.business_card.titles.st}
                    name="stateCode"
                    value={stateCode ? stateCode : ""}
                    onChange={handleInputChange}
                    inputProps={{
                      maxLength: 2,
                    }}
                  />
                </div>
                <div className={classes.textFiled_last}>
                  <CustomTextField
                    className={`${classes.input_field} ${classes.zip_field}`}
                    label={strings.business_card.titles.zip}
                    name="zipCode"
                    value={zipCode}
                    onChange={handleInputChange}
                  />
                </div>
                <div className={classes.textFiled_last}>
                  <Element_Required_Icon
                    className={
                      get(state.errors, "phoneNumber.error", "")
                        ? classes.required_error
                        : classes.field_icon
                    }
                  />
                  <CustomTextField
                    className={`${
                      get(state.errors, "phoneNumber.error", "") &&
                      classes.error_field
                    } ${classes.input_field} ${classes.phone_field}`}
                    label={strings.business_card.titles.phone}
                    name="phoneNumber"
                    onChange={handleInputChange}
                    value={
                      isUSA && get(phoneNumber, "length", 0) >= 10
                        ? formatPhoneNumber()
                        : phoneNumber
                    }
                    onBlur={onPhoneNumberBlur}
                    inputProps={{
                      maxLength: isUSA ? 10 : 20,
                      onFocus: onPhoneNumberFocus,
                    }}
                  />
                  {/* {get(state.errors, "phoneNumber.error", "") && (
                    <Text
                      size={10}
                      color={color.primary_palette.christmas_red}
                      family="gillsans_r"
                      className={`${classes.error_text_email} ${classes.phn_error}`}
                    >
                      <img src="/assets/icons/info_1.svg" class="info_img" alt=""/>{" "}
                      Phone Number
                    </Text>
                  )} */}
                  <div className={classes.flag_block}>
                    <div
                      className={`${classes.usaDivAlign} ${classes.txtRight}`}
                    >
                      {" "}
                      <span className={classes.usaStyle}>
                        {" "}
                        <img
                          src={
                            isUSA
                              ? "/assets/images/usa-flag-icon.png"
                              : "/assets/images/nonUSA.PNG"
                          }
                          alt="USA"
                          className={classes.usaFlagStyle}
                        />
                      </span>
                      <ChevronDownIcon
                        className={classes.downArrowIcon}
                        onClick={handleUSADropdown}
                      />
                    </div>
                    {showCountryDD && (
                      <div className={classes.dropdown_content}>
                        <li value={"USA"} onClick={handleCountrySelect("USA")}>
                          USA
                        </li>
                        <li
                          value={"NONUSA"}
                          onClick={handleCountrySelect("NONUSA")}
                        >
                          NON-USA
                        </li>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </Row>
          </Row>
        </Row>
        <Row className={classes.chkBxAlign}>
          <CustomCheckbox
            label={strings.settings.titles.Headquarters}
            className={classes.check_box}
            name="isHeadOffice"
            checked={isHeadOffice}
            onChange={handleInputChange}
          />
          <CustomCheckbox
            label={strings.settings.titles.billing_address}
            className={classes.check_box}
            name="isBillingAddress"
            checked={isBillingAddress}
            onChange={handleInputChange}
          />
          <CustomCheckbox
            label={strings.settings.titles.publicly_display}
            checked={isPublicDisplay}
            onChange={handleInputChange}
            name="isPublicDisplay"
            className={classes.check_box}
          />
        </Row>
        <div className={classes.width_block}>
          <Row className={`${classes.alignItems} ${classes.padding_bottom_16}`}>
            <Text
              size={14}
              color={
                get(state.errors, "nickname.error", "")
                  ? color.primary_palette.christmas_red
                  : color.primary_palette.franklin_purple
              }
              family="gillsans_sb"
              className={classes.txtTransform}
            >
              <Element_Required_Icon
                className={
                  get(state.errors, "nickname.error", "")
                    ? classes.required_error_nickname
                    : classes.element_requried
                }
              />
              {strings.settings.titles.ofc_nickname}
            </Text>
            <CustomTextField
              className={classes.city_field}
              name="nickname"
              value={nickname}
              onChange={handleInputChange}
            />
            <Close_Icon
              className={classes.nickname_clear}
              onClick={clearInput("nickname")}
            />
          </Row>
          {/*  <Row className={`${classes.alignItems}`}>
            <Text
              size={14}
              color={color.primary_palette.franklin_purple}
              family="gillsans_sb"
              className={classes.txtTransform}
            >
              {strings.settings.titles.market_ofc}
            </Text>
            <CustomSelect
              className={`${classes.dropdown} ${classes.width_231}`}
              IconComponent={Chevron_Down_Icon}
              name="displayedWhere"
              value="Displayed"
            >
              <CustomScrollbars className={classes.customScrollHeight}>
                {lookUpData &&
                  map(lookUpData.regions, (region) => {
                    return (
                      <MenuItem
                        key={region.name}
                        onClick={handleDropdownSelect("displayedWhere", region)}
                      >
                        {region.name}
                      </MenuItem>
                    );
                  })}
              </CustomScrollbars>
            </CustomSelect>
          </Row> */}
          <Row className={classes.dropdown_margin}>
            {map(displayedWhere, (eachLocation) => {
              return (
                <div className={classes.listAlign}>
                  {eachLocation.name}
                  <img
                    src="/assets/images/sketched-x.png"
                    onClick={handleDropdownSelect(
                      "displayedWhere",
                      eachLocation,
                      "del"
                    )}
                    className={classes.crossIconStyle}
                    alt=""
                  />
                </div>
              );
            })}
          </Row>
          <Row className={classes.marginLeft16}>
            <CustomSelect
              className={`${classes.select_items} ${classes.width_155}`}
              IconComponent={Chevron_Down_Icon}
              name="region"
              value={region}
              renderValue={(value) =>
                value ? (
                  <span className={classes.colorPurple}>{value}</span>
                ) : (
                  <span>Market</span>
                )
              }
              MenuProps={{
                getContentAnchorEl: null,
                disableScrollLock: true,
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
              }}
            >
              <CustomScrollbars className={classes.customScrollHeight}>
                {lookUpData &&
                  map(lookUpData.regions, (region) => {
                    return (
                      <MenuItem
                        onClick={handleMarketSelect("region", region.name)}
                      >
                        {region.name}
                      </MenuItem>
                    );
                  })}
              </CustomScrollbars>
            </CustomSelect>
            <CustomSelect
              className={`${classes.select_items} ${classes.width_270}`}
              IconComponent={Chevron_Down_Icon}
              name="category"
              value={category}
              renderValue={(value) =>
                value ? (
                  <span className={classes.colorPurple}>{value}</span>
                ) : (
                  <span>Category</span>
                )
              }
              MenuProps={{
                getContentAnchorEl: null,
                disableScrollLock: true,
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
              }}
            >
              <CustomScrollbars className={classes.customScrollHeightCategory}>
                {lookUpData &&
                  renderCategorizedMenu(lookUpData.trades, "category")}
              </CustomScrollbars>
            </CustomSelect>
            <CustomButton
              className={classes.add_btn}
              onClick={category && region && handleAddRegionClick}
            >
              {strings.business_card.titles.add}
            </CustomButton>
          </Row>
          {/* <Row
            className={`${classes.alignItems} ${classes.padding_top_6} ${classes.paddingBottom}`}
          > */}
          {limitError && (
            <Text
              color={color.primary_palette.christmas_red}
              size={15}
              family="avenir_bold"
              className={classes.marginTop6}
            >
              <img
                src="/assets/icons/info_1.svg"
                class="info_img"
                alt=""
                className={classes.marginLeft4}
              />
              Maximum four categories per market
            </Text>
          )}
          {returnRegionsList()}
          {/* <Text
              size={14}
              color={color.primary_palette.franklin_purple}
              family="gillsans_sb"
              className={classes.txtTransform}
            >
              {strings.settings.titles.services_offered}
            </Text>
            <CustomSelect
              className={`${classes.dropdown} ${classes.width_309}`}
              IconComponent={Chevron_Down_Icon}
              name="sevicesOffered"
              value="Services"
            >
              <CustomScrollbars className={classes.customScrollHeight}>
                {lookUpData &&
                  map(lookUpData.trades, (category) => {
                    return (
                      <MenuItem
                        onClick={handleDropdownSelect(
                          "sevicesOffered",
                          category
                        )}
                      >
                        {category.name}
                      </MenuItem>
                    );
                  })}
              </CustomScrollbars>
            </CustomSelect>
          </Row>
          <Row className={classes.dropdown_margin}>
            {map(sevicesOffered, (eachService) => {
              return (
                <div className={classes.listAlign}>
                  {eachService.name}
                  <img
                    src="/assets/images/sketched-x.png"
                    onClick={handleDropdownSelect(
                      "sevicesOffered",
                      eachService,
                      "del"
                    )}
                    className={classes.crossIconStyle}
                    alt=""
                  />
                </div>
              );
            })} */}
          {/* </Row> */}
        </div>
        {get(errors, "selectedRegions.error") && (
          <Text
            color={color.primary_palette.christmas_red}
            size={15}
            family="avenir_bold"
            className={`${classes.marginTop6} ${classes.textCenter}`}
          >
            <img
              src="/assets/icons/info_1.svg"
              class="info_img"
              alt=""
              className={classes.marginLeft4}
            />
            Add Atleast One Market/Category
          </Text>
        )}
        <div className={classes.txtRight}>
          <CustomButton
            className={classes.continueBtn}
            onClick={submitBusinessCard}
          >
            {strings.business_card.titles.continue}
          </CustomButton>
        </div>
      </div>
    </>
  );
}

export default BusinessStyles(AddAnotherCard);
