import { withStyles } from "@material-ui/core/styles";
import tradework_theme, {
  color,
  pxToRem,
  font_sizes,
  px_to_rem_sizes,
} from "../../utilities/themes";

const styles = withStyles({
  form: {
    backgroundColor: color.primary_palette.fafa_gray,
    border: `0.125rem solid ${color.primary_palette.franklin_purple}`,
    width: "393px",
    minHeight: "637px",
    paddingBottom: "10px",
  },
  displayNone: {
    display: "none",
  },
  countryImage: {
    width: "25px",
    height: "16px",
    paddingRight: "14px",
  },
  userTypes: {
    cursor: "pointer",
  },
  phoneNumberCross: {
    height: "7px",
    width: "7px",
    paddingRight: "10px",
  },
  dropDownIcon: {
    height: "5px",
    width: "10px",
    cursor: "pointer",
    position: "absolute",
    top: pxToRem(16),
    right: pxToRem(0),
  },
  phoneNumberActions: {
    marginTop: pxToRem(10),
  },
  dropdownOptions: {
    position: "absolute",
    border: "1px solid #bfbfbf",
    borderRadius: "4px",
    boxShadow: "1px 1px 2px 0 rgba(0, 0, 0, 0.15)",
    fontSize: "14px",
    height: "34px",
    width: "74px",
    padding: "5px 2px 5px 2px",
    background: "white",
    right: "-44px",
    bottom: 0,
    top: "32px",
    cursor: "pointer",
    zIndex: 1000,
  },
  tw_icon: {
    margin: "38px 0 13px 0",
    textAlign: "center",
  },
  join_us_label: {
    fontSize: font_sizes.font_24,
    textAlign: "center",
    color: color.primary_palette.franklin_purple,
    marginBottom: pxToRem(10),
    fontFamily: "gillsans_sb",
  },
  MuiInputLabel: {
    color: color.primary_palette.franklin_purple,
  },

  sucess_underline: {
    "& .MuiInput-underline:before": {
      borderBottomColor: color.form_colors.sucess_color,
    },
  },
  requiredIcon: {
    fontSize: "14.6px",
    position: "relative",
    bottom: "12px",
    left: "5px",
    verticalAlign: "super",
    color: color.form_colors.blueberry_purple,
    width: "15px",
    height: "15px",
  },
  password_icon: {
    "&:focus": {
      outline: "none",
    },
  },
  flexEnd: {
    justifyContent: "flex-end",
  },
  join_us_button: {
    border: `1.3px solid ${color.form_colors.blueberry_purple}`,
    backgroundColor: color.primary_palette.white,
    margin: "20px 0 30px 0",
    padding: "0 21px",
    width: "118px",
    fontSize: "15px",
    height: "26px",
    borderRadius: "26.7px",
    color: color.form_colors.royal_purple_1,
    "& .MuiButton-label": {
      ...tradework_theme.typography.styles.avenir_sb,
    },
    "&:hover": {
      backgroundColor: color.button_hover,
      background: "none",
      color: `${color.primary_palette.franklin_purple} !important`,
      border: `1.3px solid ` + color.primary_palette.franklin_purple,
      margin: "20px 0 30px 0",
      fontSize: "15px",
      padding: "0 21px",
      width: "118px",
      height: "26px",
      borderRadius: "26.7px",
    },
  },
  modal_root: {
    "& div:first-child": {
      position: "initial !important",
    },
  },
  modal: {
    border: `4px solid #69986c`,
    backgroundColor: color.form_colors.gray,
    position: "absolute",
    margin: "0px 170px",
    top: "138px",
    padding: "26px 11px 25px 11px",
    outlineColor: "transparent",
    zIndex: "2",
    width: "127px",
    height: "98px",
    "&:focus": {
      outline: "none !important",
    },
  },
  hideField: {
    display: "none",
  },
  cross_icon: {
    position: "relative",
    top: "-22px",
    fontSize: "9px",
    left: "121px",
    cursor: "pointer",
  },
  cursor: {
    cursor: "pointer",
  },
  modal_title: {
    fontSize: "0.875rem",
    margin: "0",
    color: color.primary_palette.black,
    position: "relative",
    top: "-18px",
  },
  modal_description: {
    fontSize: "0.875rem",
    margin: "0",
    color: "#4a4a4a",
    position: "relative",
    top: "-17px",
  },
  breadcrumb: {
    marginTop: "0.75em",
    "& .MuiLink-underlineAlways": {
      borderBottomStyle: "solid",
      borderBottomColor: color.primary_palette.franklin_purple,
      borderBottomWidth: px_to_rem_sizes.size_2,
      textDecoration: "none",
      "& label": {
        fontWeight: "900",
      },
    },
    "& label": {
      color: color.primary_palette.black,
      fontSize: "16px",
      fontWeight: "500",
      fontFamily: "avenir_sb",
    },
  },
  breadcrumbText: {
    color: color.primary_palette.franklin_purple + `!important`,
    fontSize: font_sizes.font_16,
    // margin: "1.3125rem 0 0.1875rem 2.625em",
    fontWeight: "600 !important",
    margin: 0,
    fontFamily: "gillsans_r !important",
    // fontFamily: 'gillsans !important',
    //  fontFamily: tradework_theme.typography.
  },
  account_exists: {
    fontSize: font_sizes.font_14,
    fontFamily: "gillsans_sb",
    color: color.form_colors.royal_purple_1,
    "& .MuiTypography-colorPrimary": {
      color: color.form_colors.royal_purple_1,
    },
  },
  account_exists_error: {
    fontSize: font_sizes.font_14,
    fontFamily: "gillsans_sb",
    color: color.primary_palette.christmas_red,
    "& .MuiTypography-colorPrimary": {
      color: color.primary_palette.christmas_red,
    },
  },
  error_text: {
    color: `${color.primary_palette.christmas_red} !important`,
    cursor: "pointer",
  },
  terms_services: {
    fontSize: "13px",
    fontFamily: "gillsans_light",
    "& .MuiLink-underlineAlways": {
      color: color.primary_palette.black,
    },
  },
  auto_email: {
    color: "#c01f2a",
    marginLeft: "7px",
    position: "relative",
    marginTop: "-30px !important",
    top: "18px",
    "& .MuiFormControlLabel-label": {
      fontSize: "10px",
      color: "#c01f2a",
      fontFamily: "gillsans_r",
    },
    "& .MuiInputLabel-root": {
      fontSize: "10px",
      marginLeft: "2px",
      display: "inline-block",
    },
  },
  errorText: {
    position: "relative",
    top: "-0.5625rem",
    left: "1.4375rem",
    fontSize: font_sizes.font_12,
    color: color.primary_palette.christmas_red + "!important",
    fontFamily: "avenir_bold",
  },
  errorMessage: {
    fontSize: font_sizes.font_12,
    color: color.primary_palette.christmas_red + "!important",
    fontFamily: "avenir_bold",
    marginLeft: pxToRem(22),
  },
  successText: {
    position: "relative",
    top: "-0.5625rem",
    left: "1.4375rem",
    fontSize: font_sizes.font_12,
    color: color.form_colors.sucess_color + "!important",
    fontFamily: "avenir_bold",
  },
  password_validation: {
    position: "relative",
    top: "-0.5625rem",
    left: "1.4375rem",
    fontSize: font_sizes.font_12,
    color: color.form_colors.textfield_color + "!important",
    fontFamily: "avenir_light",
  },
  phone_suggestion: {
    color: color.wizard_box_colors.shadow_gray,
    position: "relative",
    top: "-0.5625rem",
    left: "1.4375rem",
    fontSize: font_sizes.font_12,
    fontFamily: "gillsans_light",
  },
  phone_suggestion_password: {
    color: color.wizard_box_colors.shadow_gray,
    position: "relative",
    top: "-0.5625rem",
    left: "1.4375rem",
    fontSize: "13px",
    fontFamily: "avenir_light",
  },
  textFieldError: {
    border: "1px solid red",
  },
  textFieldInput: {
    borderRadius: 4,
    backgroundColor: "#fff",
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "10px 12px",
    width: "calc(100% - 24px)",
    "&:focus": {
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
  animation_textfield: {
    borderBottom: "2px solid red",
    transform: "scaleX(150)",
  },
  align_phonenumber: {
    display: "flex",
    justifyContent: "flex-end",
  },
  prediction_yes: {
    color: `${color.primary_palette.black} !important`,
    fontFamily: "gillsans_r",
    fontSize: "10px",
  },
  error_msg: {
    color: color.primary_palette.christmas_red,
    fontSize: font_sizes.font_16,
    fontFamily: "gillsans_r",
    position: "absolute",
    bottom: "50px",
  },
  closeBtn: {
    paddingTop: "16px",
    paddingRight: 0,
    paddingBottom: 0,
    cursor: "pointer",
    zIndex: "999",
    marginRight: pxToRem(-2),
  },
  form_padding: {
    padding: "0 50px 0 30px",
    marginTop: "-30px",
    // position: "relative",
  },
  joinUs_cross: {
    fontSize: font_sizes.font_16,
  },
  dragon_width: {
    width: "61px",
    height: "80px",
    marginTop: pxToRem(20),
  },
  modal_popup: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& .MuiGrid-root": {
      "&:focus": {
        outline: 0 + ` !important`,
      },
    },
  },
  errorIcon: {
    color: color.primary_palette.christmas_red,
    fontSize: "14.6px",
    position: "relative",
    bottom: "12px",
    left: "5px",
    verticalAlign: "super",
    width: "15px",
    height: "15px",
  },
  inp_error: {
    "& .MuiInput-underline:before": {
      borderBottomColor: `${color.primary_palette.christmas_red}`,
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: `${color.primary_palette.christmas_red}`,
    },
    "& .Mui-focused": {
      fontSize: font_sizes.font_14,
      color: `${color.primary_palette.christmas_red}`,
    },
    "& .MuiFormLabel-root": {
      color: `${color.primary_palette.christmas_red}`,
    },
    "& .MuiInputBase-input": {
      color: color.primary_palette.black,
    },
  },
  inp_success: {
    "& .MuiInput-underline:before": {
      borderBottomColor: `${color.form_colors.sucess_color}`,
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: `${color.form_colors.sucess_color}`,
    },
    "& .Mui-focused": {
      fontSize: font_sizes.font_14,
      color: `${color.primary_palette.franklin_purple}`,
    },
    "& .MuiFormLabel-root": {
      color: `${color.primary_palette.franklin_purple}`,
    },
    "& .MuiInputBase-input": {
      color: color.primary_palette.black,
    },
  },
  inp_initial: {
    "& .MuiInput-underline:before": {
      borderBottomColor: color.primary_palette.highlight_purple,
    },
    "& .MuiInputLabel-formControl": {
      color: color.form_colors.textfield_color,
      top: "5px" + "!important",
    },
    "& .MuiInputLabel-shrink": {
      color: color.primary_palette.franklin_purple,
      fontSize: "12px",
      fontFamily: "gillsans_r",
    },
  },
  textFieldSuccess: {
    fontSize: font_sizes.font_18,
    color: color.form_colors.textfield_color,
    width: "288px",
    marginBottom: "12px",
    fontFamily: "NeutraText",
    "& .MuiFormLabel-root.Mui-focused": {
      fontSize: "10px",
    },
    "& .MuiFormHelperText-root.Mui-error": {
      margin: "0",
      position: "relative",
      top: "0.25rem",
    },
    "& .MuiInputBase-input": {
      fontFamily: "NeutraText",
      fontSize: "18px",
      padding: "6px 0px 4px 0px !important",
    },
    "& .MuiInputLabel-formControl": {
      top: "0px" + "!important",
    },
    "& .MuiFormLabel-root": {
      fontSize: "18px",
      fontFamily: "gillsans_light",
    },
    "& .MuiInputLabel-shrink": {
      fontSize: "10px",
      fontFamily: "gillsans_r",
      marginTop: "9px",
    },
  },
  phonenum_clr: {
    height: "7px",
    width: "7px",
    cursor: "pointer",
    zIndex: "1000",
    position: "absolute",
    right: pxToRem(45),
    top: pxToRem(15),
  },
  backgroundBlack: {
    background: "rgba(159, 159, 159, 1) !important",
  },
});
export default styles;
