/**
 * Custom Link component derived from Material UI Link .
 */
import React from "react";
import Link from "@material-ui/core/Link";
import { withStyles } from "@material-ui/core/styles";
/**
 * Link Component to used accross applications for navigations
 * @param {*} props
 */
function CustomLink(props) {
  function styleLink(styledProps) {
    return <Link {...styledProps}></Link>;
  }
  /**
   * Style customization for inputs
   */
  const CstmLink = withStyles({
    root: {},
  })(styleLink);
  /**
   * Style customization for formcontrol label
   */

  return <CstmLink {...props} />;
}

export default CustomLink;
