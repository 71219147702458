import React from "react";

import { color } from "../../utilities/themes";
import DeleteProjetStyles from "./styles/delete_projectStyles";
import Text from "../common/ui_kit/text";
import strings from "../../utilities/strings";
import Close_Icon from "../data_display/icons/Close";
import CustomButton from "../navigations/custom_buttons";

function ContactModal(props) {
  const { classes, onClose } = props;

  return (
    <>
      <div className={classes.contact_cover}>
        <div className={classes.textRight}>
          <CustomButton className={classes.cross_btn_del} onClick={onClose} isX>
            <Close_Icon
              className={classes.contact_cross_mark}
              onClick={onClose}
            />
          </CustomButton>
        </div>
        <div className={classes.mobile_cover_width}>
          <img
            alt=""
            src="/assets/images/icon-telephone.png"
            className={classes.mobile_image}
          />
        </div>
        <Text
          size={15}
          color={color.primary_palette.black}
          className={`${classes.textCenter} ${classes.padding_sides}`}
          family="avenir_roman"
        >
          {strings.add_project.titles.please_call}
        </Text>
        <Text
          size={15}
          color={color.primary_palette.black}
          className={`${classes.textCenter} ${classes.padding_sides}`}
          family="avenir_roman"
        >
          {strings.add_project.titles.enable_feature}
        </Text>
        <Text
          size={15}
          color={color.primary_palette.black}
          className={`${classes.textCenter} ${classes.padding_sides} ${classes.contact_num}`}
          family="avenir_roman"
        >
          {strings.add_project.titles.contact_num}
        </Text>
        <Text
          size={15}
          color={color.primary_palette.black}
          className={`${classes.textCenter} ${classes.padding_sides}`}
          family="avenir_roman"
        >
          {strings.add_project.titles.automated_feature}
        </Text>
        <div className={classes.textCenter}>
          <CustomButton className={classes.inBtn} onClick={onClose}>
            {strings.add_project.titles.on_it}
          </CustomButton>
        </div>
      </div>
    </>
  );
}

export default DeleteProjetStyles(ContactModal);
