import React from "react";
import { get } from "lodash";

import GridStyles from "./styles/gridStyles";
import Row from "../common/ui_kit/row";

function GridEleven(props) {
  const { classes, imageData, imageSize } = props;

  return (
    <>
      <div className={`${classes.publicviewProject} ${classes.width_789}`}>
        <Row
          className={
            imageSize ? classes.grid_main_spacing_m : classes.grid_main_spacing
          }
        >
          <div>
            <div>
              <img
                alt=""
                className={imageSize ? classes.grid_11_1_m : classes.grid_11_1}
                src={
                  get(imageData[0], "imageUri", "") || "/assets/images/gray.PNG"
                }
              />
            </div>
            <div>
              <img
                alt=""
                className={imageSize ? classes.grid_11_2_m : classes.grid_11_2}
                src={
                  get(imageData[4], "imageUri", "") || "/assets/images/gray.PNG"
                }
              />
            </div>
          </div>
          <div>
            <div>
              <img
                alt=""
                className={imageSize ? classes.grid_11_3_m : classes.grid_11_3}
                src={
                  get(imageData[1], "imageUri", "") || "/assets/images/gray.PNG"
                }
              />
            </div>
            <div>
              <img
                alt=""
                className={imageSize ? classes.grid_11_4_m : classes.grid_11_4}
                src={
                  get(imageData[5], "imageUri", "") || "/assets/images/gray.PNG"
                }
              />
            </div>
            <div>
              <img
                alt=""
                className={imageSize ? classes.grid_11_5_m : classes.grid_11_5}
                src={
                  get(imageData[8], "imageUri", "") || "/assets/images/gray.PNG"
                }
              />
            </div>
          </div>
          <Row className={classes.wrap}>
            <Row>
              <div>
                <img
                  alt=""
                  className={
                    imageSize ? classes.grid_11_6_m : classes.grid_11_6
                  }
                  src={
                    get(imageData[2], "imageUri", "") ||
                    "/assets/images/gray.PNG"
                  }
                />
              </div>
              <div>
                <img
                  alt=""
                  className={
                    imageSize ? classes.grid_11_7_m : classes.grid_11_9
                  }
                  src={
                    get(imageData[3], "imageUri", "") ||
                    "/assets/images/gray.PNG"
                  }
                />
              </div>
            </Row>
            <Row>
              <div>
                <img
                  alt=""
                  className={
                    imageSize ? classes.grid_11_8_m : classes.grid_11_7
                  }
                  src={
                    get(imageData[6], "imageUri", "") ||
                    "/assets/images/gray.PNG"
                  }
                />
              </div>
              <div>
                <img
                  alt=""
                  className={
                    imageSize ? classes.grid_11_9_m : classes.grid_11_10
                  }
                  src={
                    get(imageData[7], "imageUri", "") ||
                    "/assets/images/gray.PNG"
                  }
                />
              </div>
            </Row>
            <Row>
              <div>
                <img
                  alt=""
                  className={
                    imageSize ? classes.grid_11_10_m : classes.grid_11_8
                  }
                  src={
                    get(imageData[9], "imageUri", "") ||
                    "/assets/images/gray.PNG"
                  }
                />
              </div>
              <div>
                <img
                  alt=""
                  className={
                    imageSize ? classes.grid_11_11_m : classes.grid_11_11
                  }
                  src={
                    get(imageData[10], "imageUri", "") ||
                    "/assets/images/gray.PNG"
                  }
                />
              </div>
            </Row>
          </Row>
        </Row>
      </div>
    </>
  );
}

export default GridStyles(GridEleven);
