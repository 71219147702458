import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { get } from "lodash";

import { StepButton } from "@material-ui/core";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

import stepperStyles from "./styles/stepperStyle";
import PorfolioUserView from "../build_portfolio/portfolio_UserView";
import { ProfileActions } from "../../redux/actions";

function getSteps() {
  return [
    "Business Card",
    "Profile & Cover",
    "Company Blurb",
    "Projects",
    "Products & Publications",
    "Vignettes",
  ];
}

function getStepContent(
  stepIndex,
  openImageModal,
  openBusinessModal,
  openElevatorModal,
  triggerPopupModals,
  props,
  values
) {
  switch (stepIndex) {
    case 0:
      return (
        <PorfolioUserView
          {...props}
          openBusinessModal={openBusinessModal}
          openBusinessEditModal={values.openBusinessEditModal}
          triggerPopupModals={triggerPopupModals}
        />
      );
    case 1:
      return (
        <PorfolioUserView
          {...props}
          openImageModal={openImageModal}
          triggerPopupModals={triggerPopupModals}
        />
      );
    case 2:
      return (
        <PorfolioUserView
          {...props}
          openElevatorModal={openElevatorModal}
          triggerPopupModals={triggerPopupModals}
        />
      );
    case 3:
      return (
        <PorfolioUserView
          {...props}
          openProjModal={values.openProjModal}
          triggerPopupModals={triggerPopupModals}
        />
      );
    case 4:
      return (
        <PorfolioUserView
          {...props}
          openProductModal={values.openProductModal}
          triggerPopupModals={triggerPopupModals}
        />
      );
    case 5:
      return (
        <PorfolioUserView
          {...props}
          openVignetteModal={values.openVignetteModal}
          triggerPopupModals={triggerPopupModals}
        />
      );
    default:
      return "Unknown stepIndex";
  }
}

function CustomStepper(props) {
  const { classes } = props;
  const [activeStep, setActiveStep] = React.useState(0);
  const [values, setValues] = React.useState({
    openImageModal: false,
    openBusinessModal: false,
    openElevatorModal: false,
    openProjModal: false,
    openProductModal: false,
    openVignetteModal: false,
    completedSteps: {},
  });
  const steps = getSteps();

  const dispatch = useDispatch();

  const portfolioData = useSelector((resData) => resData.Profile.portfolioData);

  //API
  useEffect(() => {
    if (!portfolioData) {
      dispatch(
        ProfileActions.getPortfolioData(localStorage.getItem("portfolioId"))
      );
    }
  }, []);

  useEffect(() => {
    if (portfolioData) {
      const hasElevatorPitchData =
        Object.keys(get(portfolioData, "elevatorPitch", {})).length > 0;
      const hasProjects = get(portfolioData, "projects.length", 0) > 0;
      const hasPublications =
        get(portfolioData, "productsOrPublications.length", 0) > 0;
      const hasDisplayedAt = get(portfolioData, "displayedAt.length", 0) > 0;
      const hasVignettes = get(portfolioData, "vignettes.length", 0) > 0;
      const hasCoverPic =
        get(portfolioData, "profileImgUri", "") &&
        get(portfolioData, "coverImgUri", "")
          ? true
          : false;
      setValues({
        ...values,
        completedSteps: {
          "Company Blurb": hasElevatorPitchData,
          Projects: hasProjects,
          "Products & Publications": hasPublications,
          "Business Card": hasDisplayedAt,
          Vignettes: hasVignettes,
          "Profile & Cover": hasCoverPic,
        },
      });
    }
  }, [portfolioData]);

  const handleReset = () => {
    setActiveStep(0);
  };

  const triggerPopupModals = (type) => {
    setValues({ ...values, [type]: !values[type] });
  };

  const onStepClick = (index) => () => {
    setActiveStep(index);
    if (index == 0) {
      if (values.completedSteps && !values.completedSteps["Business Card"]) {
        triggerPopupModals("openBusinessModal");
      } else {
        triggerPopupModals("openBusinessEditModal");
      }
    }
    if (index === 1) {
      triggerPopupModals("openImageModal");
    }
    if (index === 2) {
      triggerPopupModals("openElevatorModal");
    }
    if (index === 3) {
      triggerPopupModals("openProjModal");
    }
    if (index === 4) {
      triggerPopupModals("openProductModal");
    }
    if (index === 5) {
      triggerPopupModals("openVignetteModal");
    }
  };

  const returnClass = (type) => {
    // if (type === "Business Card" && values.completedSteps[type]) {
    //   return classes.disabled_stp_bc;
    // }
    if (values.completedSteps[type]) {
      return classes.completed_step;
    }
    return classes.incomplete_step;
  };

  const {
    openImageModal,
    openBusinessModal,
    openElevatorModal
  } = values;

  return (
    <>
      <div className={classes.companyStepper}>
        <Stepper alternativeLabel nonLinear>
          {steps.map((label, index) => (
            <Step
              key={label}
              className={`${classes.main_step} ${returnClass(label)}`}
            >
              <StepButton onClick={onStepClick(index)}>
                <StepLabel className={`${classes.upperText} completedStep` } completed={false}>
                  {label}
                </StepLabel>
              </StepButton>
            </Step>
          ))}
        </Stepper>

        <div>
          {activeStep === steps.length ? (
            <div>
              <Typography className={classes.instructions}>
                All steps completed
              </Typography>
              <Button onClick={handleReset}>Reset</Button>
            </div>
          ) : (
            <div>
              <Typography className={classes.instructions}>
                {getStepContent(
                  activeStep,
                  openImageModal,
                  openBusinessModal,
                  openElevatorModal,
                  triggerPopupModals,
                  props,
                  values
                )}
              </Typography>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default stepperStyles(CustomStepper);
