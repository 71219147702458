import React, { useState, useEffect } from "react";
import { get, startsWith } from "lodash";

import BusinessStyles from "./styles/businessStyle";
import Row from "../common/ui_kit/row";
import Text from "../common/ui_kit/text";
import strings from "../../utilities/strings";
import { color } from "../../utilities/themes";

import { PHONE_NUMBER_FORMAT } from "../../validations/validations";

function CoverBusiness(props) {
  const [state, setState] = useState({
    streetAddr1: "",
    streetAddr2: "",
    city: "",
    email: "",
    webSite: "",
    phoneNumber: "",
    companyName: "",
  });
  const { classes, businessCardData, isPublicMode, openEditBusinessCard } =
    props;

  const getUrl = (url) => {
    if (url) {
      if (startsWith(url, "http://") || startsWith(url, "https://")) {
        return url;
      }
      return `//${url}`;
    }
    return "javascript:void(0)";
  };

  useEffect(() => {
    if (businessCardData) {
      setState({
        ...state,
        streetAddr1: get(businessCardData, "address1", ""),
        streetAddr2: get(businessCardData, "address", ""),
        city: get(businessCardData, "city", ""),
        webSite: get(businessCardData, "webSite", ""),
        email: get(businessCardData, "email", ""),
        phoneNumber: get(businessCardData, "phoneNumber", ""),
        companyName: get(businessCardData, "name", ""),
      });
    }
  }, [businessCardData]);

  const formatPhoneNumber = (val) => {
    return val.replace(PHONE_NUMBER_FORMAT, "$1.$2.$3");
  };

  const mailTo = (email) => () => {
    window.location.href = `mailto:${email}`;
    return "javascript:void(0)";
  };

  return (
    <>
      <div className={classes.bussinesscard}>
        <Row className={`${classes.business_cover} ${classes.middle}`}>
          <Row className={`${classes.inner_block} ${classes.cover_card_hover}`}>
            {!isPublicMode && (
              <div className={classes.pencil_hover_text}>
                <Text
                  size={20}
                  family="avenir_sb"
                  color={color.primary_palette.franklin_purple}
                  transform="uppercase"
                  className={`${classes.businessTxtAlign} ${classes.newHelpTxtAlign} pencil_text`}
                  onClick={openEditBusinessCard}
                >
                  {strings.portfolio_userView.titles.business_card}
                </Text>
                <div
                  className={`${classes.iconStylePencil} ${classes.edit_circle} pencil_icon`}
                  onClick={openEditBusinessCard}
                ></div>
              </div>
            )}
            <Row className={`${classes.cover_display_blk}`}>
              <Text
                size={28}
                color={color.primary_palette.black}
                family="TrajanPro3"
                className={`${classes.txtCenter} ${classes.companyNameStyle}`}
              >
                {get(businessCardData, "name", "Company Name")}
              </Text>
            </Row>
            <Row className={classes.flex}>
              <div className={`${classes.textFiled} ${classes.margin_top_0}`}>
                <Text
                  size={16}
                  color={color.primary_palette.black}
                  family="avenir_sb"
                  className={classes.txtCenterEllipses}
                >
                  {get(businessCardData, "address", "Address")}
                </Text>
              </div>
              <div className={`${classes.textFiled} ${classes.margin_top_0}`}>
                <Text
                  size={16}
                  color={color.primary_palette.black}
                  family="avenir_sb"
                  className={classes.txtCenterEllipses}
                >
                  {formatPhoneNumber(get(businessCardData, "phoneNumber", ""))}
                </Text>
              </div>
              <div className={`${classes.textFiled} ${classes.margin_top_0}`}>
                <Text
                  size={16}
                  color={color.primary_palette.black}
                  family="avenir_sb"
                  className={classes.txtCenterEllipses}
                >
                  {get(businessCardData, "address1") || (
                    <span>
                      {" "}
                      {get(businessCardData, "city", "City")}
                      {get(businessCardData, "state", "") && ", "}
                      {get(businessCardData, "state", "")}&nbsp;
                      {get(businessCardData, "zip", "Zip")}
                    </span>
                  )}
                </Text>
              </div>
              <div className={`${classes.textFiled} ${classes.margin_top_0}`}>
                <Text
                  size={16}
                  color={color.primary_palette.black}
                  family="avenir_sb"
                  className={classes.txtCenterEllipses}
                >
                  <span
                    onClick={mailTo(get(businessCardData, "email"))}
                    className={classes.emailActiveStyle}
                  >
                    {get(businessCardData, "email", "")}
                  </span>
                </Text>
              </div>
              <div className={`${classes.textFiled} ${classes.margin_top_0}`}>
                <Text
                  size={16}
                  color={color.primary_palette.black}
                  family="avenir_sb"
                  className={classes.txtCenterEllipses}
                >
                  {get(businessCardData, "address1") && (
                    <span>
                      {" "}
                      {get(businessCardData, "city", "City")}
                      {get(businessCardData, "state", "") && ", "}
                      {get(businessCardData, "state", "")}&nbsp;
                      {get(businessCardData, "zip", "Zip")}{" "}
                    </span>
                  )}
                </Text>
              </div>
              <div className={`${classes.textFiled} ${classes.margin_top_0}`}>
                <Text
                  size={16}
                  color={color.primary_palette.black}
                  family="avenir_sb"
                  className={classes.txtCenterEllipses}
                >
                  <a
                    href={getUrl(get(businessCardData, "webSite"))}
                    target="_blank"
                    className={classes.websiteTxt}
                  >
                    {get(businessCardData, "webSite", "")}
                  </a>
                </Text>
              </div>
              {/* <div
                className={`${classes.textFiled} ${classes.margin_top_0}  ${classes.width_50}`}
              >
                <Row>
                  <Text
                    size={16}
                    color={color.primary_palette.black}
                    family="avenir_sb"
                    className={`${classes.txtCenterEllipses} ${classes.flex_grow_1}`}
                  >
                    {get(businessCardData, "state", "state")}
                  </Text>
                  <Text
                    size={16}
                    color={color.primary_palette.black}
                    family="avenir_sb"
                    className={`${classes.txtCenterEllipses} ${classes.flex_grow_1}`}
                  >
                    {get(businessCardData, "zip", "zip")}
                  </Text>
                </Row>
              </div> */}
            </Row>
          </Row>
          {/* <Row className={classes.height17}></Row> */}
        </Row>
      </div>
    </>
  );
}

export default BusinessStyles(CoverBusiness);
