import React from "react";
import { styled } from "@material-ui/core/styles";
import { color } from "../../utilities/themes";

const HorseLoading = ({ loading }) => {
  return !loading ? null : (
    <LoadingWheel>
      <img
        src="/assets/images/Franklin the horse.svg"
        alt=""
        style={{ width: "70%", position: "relative", top: "220px" }}
      />
      <div style={{ width: "30%" }}>
        <div style={{ fontSize: "30px" }}>WE ARE CREATING</div>
        <div style={{ fontSize: "30px" }}>YOUR PORTFOLIO</div>
        <img src="/assets/images/loading.gif" alt="" />
      </div>
    </LoadingWheel>
  );
};

const LoadingWheel = styled("div")({
  backgroundColor: color.primary_palette.white,
  position: "fixed",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
  height: "100%",
  opacity: 1,
  zIndex: 2000,
});

export default HorseLoading;
