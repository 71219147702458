import React from "react";
import { useHistory } from "react-router-dom";

import { color } from "../../utilities/themes";
import VignettesProductStyles from "./styles/vignettesAddProjectStyles";
import Text from "../common/ui_kit/text";
import Row from "../common/ui_kit/row";
import strings from "../../utilities/strings";
import CustomButton from "../navigations/custom_buttons";
import Close_Icon from "../data_display/icons/Close";

function VignettesAddProject(props) {
  const { classes, onClose } = props;

  const vignettesAddImage = [
    "/assets/images/icon_hat.png",
    "/assets/images/icon_hat.png",
    "/assets/images/icon_hat.png",
    "/assets/images/icon_hat.png",
  ];

  const history = useHistory();

  const redirectToAddProject = () => {
    history.push("/AddProjects");
  };

  return (
    <>
      <div className={classes.dashboardWidth}>
        {/* <div className={classes.textCenter}>
          <Edit_Circle_Icon className={classes.edit_icon} />
        </div> */}
        <div className={classes.btnRight}>
          <CustomButton className={classes.crossBtn} isX>
            <Close_Icon onClick={onClose} />
          </CustomButton>
        </div>
        <div className={classes.spacing}>
          <Row className={classes.vignettes_flex}>
            {vignettesAddImage.map((item) => (
              <div className={classes.vignettes_img}>
                <img alt="" src={item} />
              </div>
            ))}
          </Row>
          <Text
            size={25}
            color={color.primary_palette.black}
            className={`${classes.textSpacing} ${classes.textCenter}`}
          >
            {strings.vignettes.titles.add_vignettes}
          </Text>
          <div className={classes.textCenter}>
            <CustomButton
              className={classes.add_projectBtn}
              onClick={redirectToAddProject}
            >
              {strings.vignettes.titles.add_project}
            </CustomButton>
          </div>
        </div>
      </div>
    </>
  );
}

export default VignettesProductStyles(VignettesAddProject);
