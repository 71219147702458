import { withStyles } from "@material-ui/core/styles";
import tradework_theme, { pxToRem, color } from "../../../utilities/themes";

const styles = withStyles({
  recover_password_width: {
    width: pxToRem(415),
    height: pxToRem(474),
    border: `solid ${pxToRem(2)} ${color.primary_palette.franklin_purple}`,
    backgroundColor: color.secondary_palette.blues.modal_blue,
    "&:focus": {
      outline: "none",
    },
  },
  textCenter: {
    textAlign: "center",
  },
  password_width: {
    width: pxToRem(208),
    marginTop: pxToRem(19),
    "& .MuiFormLabel-root": {
      ...tradework_theme.typography.styles.gillsans_light,
      fontSize: pxToRem(18),
      marginTop: pxToRem(-5),
    },
    "& .MuiFormLabel-root.Mui-focused": {
      fontSize: pxToRem(10),
      ...tradework_theme.typography.styles.gillsans_r,
      color: color.primary_palette.franklin_purple,
    },
    "& .Mui-error": {
      color: color.primary_palette.christmas_red,
    },
    "& .MuiInputBase-input": {
      // ...tradework_theme.typography.styles.gillsans_light,
      ...tradework_theme.typography.styles.NeutraText,
      color: color.primary_palette.black,
    },
    "& .MuiInputLabel-shrink": {
      fontSize: pxToRem(10),
      ...tradework_theme.typography.styles.gillsans_r,
      color: color.primary_palette.franklin_purple,
      marginTop: pxToRem(9),
    },
    "& .MuiInputAdornment-root": {
      "&:focus": {
        outline: "none",
      },
    },
  },
  set_new_password: {
    width: pxToRem(179),
    height: pxToRem(30),
    borderRadius: pxToRem(20),
    border: `solid ${pxToRem(1)} ${color.form_colors.blueberry_purple}`,
    backgroundColor: color.primary_palette.white,
    fontSize: pxToRem(14),
    color: `${color.form_colors.royal_purple_1} !important`,
    ...tradework_theme.typography.styles.gillsans_sb,
    marginTop: pxToRem(15),
    marginBottom: pxToRem(39),
  },
  recover: {
    fontSize: pxToRem(14),
    color: color.primary_palette.franklin_purple,
    ...tradework_theme.typography.styles.gillsans_sb,
  },
  textRight: {
    textAlign: "right",
  },
  crossBtn: {
    minWidth: pxToRem(30),
    padding: pxToRem(16),
    "& .MuiSvgIcon-root": {
      fontSize: pxToRem(15),
    },
  },
  char_num: {
    ...tradework_theme.typography.styles.Helvetica_Neue,
    fontWeight: 100,
  },

  //   success
  success_width: {
    width: pxToRem(389),
    border: `solid ${pxToRem(2)} ${color.primary_palette.franklin_purple}`,
    backgroundColor: color.primary_palette.white,
    "&:focus": {
      outline: "none",
    },
  },
  ribbon: {
    fontSize: pxToRem(86),
  },
  successCheck: {
    fontSize: pxToRem(60),
  },
  successModalPadding: {
    padding: pxToRem(20),
  },
  okBtn: {
    width: pxToRem(90),
    height: pxToRem(30),
    borderRadius: pxToRem(20),
    border: `solid ${pxToRem(1)} ${color.form_colors.blueberry_purple}`,
    backgroundColor: color.primary_palette.white,
    fontSize: pxToRem(14),
    color: `${color.form_colors.royal_purple_1} !important`,
    ...tradework_theme.typography.styles.gillsans_sb,
    marginTop: pxToRem(15),
    marginBottom: pxToRem(24),
  },

  // find ur password
  phn_email_blk: {
    width: pxToRem(208),
    marginTop: pxToRem(20),
    "& .MuiFormLabel-root": {
      ...tradework_theme.typography.styles.gillsans_light,
      fontSize: pxToRem(14),
    },
    "& .MuiFormLabel-root.Mui-focused": {
      fontSize: pxToRem(10),
      ...tradework_theme.typography.styles.gillsans_r,
      color: `${color.primary_palette.franklin_purple}`,
    },
    "& .Mui-error": {
      color: color.primary_palette.christmas_red,
      fontSize: pxToRem(10),
    },
    "& .MuiInputBase-input": {
      ...tradework_theme.typography.styles.gillsans_light,
      color: color.primary_palette.black,
      fontSize: pxToRem(18),
    },
    "& .MuiInputLabel-shrink": {
      fontSize: pxToRem(10),
      marginTop: pxToRem(9),
      ...tradework_theme.typography.styles.gillsans_r,
    },
  },
  locateBtn: {
    width: pxToRem(192),
    height: pxToRem(30),
    borderRadius: pxToRem(20),
    border: `solid ${pxToRem(1)} ${color.form_colors.blueberry_purple}`,
    backgroundColor: color.primary_palette.white,
    fontSize: pxToRem(14),
    color: `${color.form_colors.royal_purple_1} !important`,
    ...tradework_theme.typography.styles.gillsans_sb,
    marginTop: pxToRem(28),
    marginBottom: pxToRem(18),
  },
  sign_in: {
    width: "50%",
    flexGrow: 1,
    textAlign: "center",
    paddingLeft: pxToRem(20),
  },
  join_here: {
    width: "50%",
    flexGrow: 1,
    textAlign: "center",
  },
  footer_spacing: {
    paddingTop: pxToRem(21),
    paddingBottom: pxToRem(40),
  },
  get_new_password: {
    width: pxToRem(192),
    height: pxToRem(30),
    borderRadius: pxToRem(20),
    border: `solid ${pxToRem(1)} ${color.form_colors.blueberry_purple}`,
    backgroundColor: color.primary_palette.white,
    fontSize: pxToRem(14),
    color: `${color.form_colors.royal_purple_1} !important`,
    ...tradework_theme.typography.styles.gillsans_sb,
    marginTop: pxToRem(35),
    marginBottom: pxToRem(30),
  },
  spacingRadio: {
    paddingTop: pxToRem(24),
    width: pxToRem(208),
    margin: "0 auto",
  },
  radioBtn: {
    "& .MuiTypography-root": {
      fontSize: pxToRem(10),
      color: color.primary_palette.franklin_purple,
      textTransform: "capitalize",
    },
    "& .MuiSvgIcon-root": {
      fontSize: pxToRem(20),
    },
  },
  inline_block: {
    display: "inline-block",
  },

  // securityModal
  securityModal_width: {
    width: pxToRem(415),
    border: `solid ${pxToRem(2)} ${color.primary_palette.franklin_purple}`,
    backgroundColor: color.secondary_palette.blues.modal_blue,
    padding: `${pxToRem(0)} ${pxToRem(38)}`,
    "&:focus": {
      outline: "none",
    },
  },
  text_message_spacing: {
    paddingTop: pxToRem(29),
    paddingBottom: pxToRem(40),
  },
  security_block: {
    width: pxToRem(47),
    height: pxToRem(58),
    border: `solid ${pxToRem(2)} ${color.secondary_palette.grays.shadow_gray}`,
    backgroundColor: color.primary_palette.white,
    "& .MuiInput-root": {
      marginTop: pxToRem(26),
    },
  },
  justify_evenly: {
    justifyContent: "space-evenly",
  },
  get_code_spacing: {
    paddingTop: pxToRem(64),
    paddingBottom: pxToRem(50),
  },
  lock_img: {
    width: pxToRem(81),
    height: pxToRem(71),
    paddingTop: pxToRem(50),
    paddingBottom: pxToRem(28),
  },
  margin_top_25: {
    marginTop: pxToRem(-25),
  },
  lock_img_new: {
    width: pxToRem(81),
    height: pxToRem(71),
    paddingBottom: pxToRem(45),
  },
  errorAlign: {
    marginTop: pxToRem(10),
    marginBottom: pxToRem(30),
  },
  cursor: {
    cursor: "pointer",
  },
  inpErr: {
    "& .MuiInputLabel-shrink": {
      color: color.primary_palette.christmas_red,
    },
  },
  padding_top_10: {
    paddingTop: pxToRem(10),
  },
  margin_left_3: {
    marginLeft: pxToRem(3),
  },
  errorText: {
    position: "relative",
    fontSize: pxToRem(12),
    color: color.primary_palette.christmas_red + "!important",
    fontFamily: "avenir_light",
  },
  successText: {
    position: "relative",
    fontSize: pxToRem(12),
    color: color.form_colors.sucess_color + "!important",
    fontFamily: "avenir_light",
  },
});

export default styles;
