import { withStyles } from "@material-ui/core/styles";
import tradework_theme, { pxToRem, color } from "../../../utilities/themes";

const styles = withStyles({
  MoneyModalWidth: {
    width: pxToRem(641),
    border: `solid ${pxToRem(2)} ${color.primary_palette.free_purple}`,
    backgroundColor: color.secondary_palette.blues.modal_blue,
    margin: "0 auto",
    padding: pxToRem(19),
    paddingTop: pxToRem(0),
    "&:focus": {
      outline: "none",
    },
  },
  btnRight: {
    textAlign: "right",
  },
  crossBtn: {
    fontSize: pxToRem(15),
    paddingTop: pxToRem(15),
    cursor: "pointer",
    "& path": {
      stroke: color.pedagogical_bronze,
    },
  },
  textCenter: {
    textAlign: "center",
  },
  party_icon: {
    width: pxToRem(70),
    height: pxToRem(70),
  },
  padding_bottom_19: {
    paddingBottom: pxToRem(19),
  },
  padding_bottom_50: {
    paddingBottom: pxToRem(50),
  },
  padding_bottom_5: {
    paddingBottom: pxToRem(5),
  },
  margin_top_25: {
    marginTop: pxToRem(-25),
  },
  table_width: {
    width: pxToRem(296),
    border: `solid ${pxToRem(1)} ${color.secondary_palette.grays.shadow_gray}`,
    backgroundColor: color.primary_palette.white,
    borderRadius: pxToRem(5),
    margin: `${pxToRem(25)} auto`,
    padding: `${pxToRem(6)} ${pxToRem(10)}`,
    "& tr": {
      "& td": {
        "&:nth-child(1)": {
          ...tradework_theme.typography.styles.gillsans_light,
          color: color.primary_palette.black,
          width: "80%",
          textAlign: "right",
        },
        "&:nth-child(2)": {
          ...tradework_theme.typography.styles.gillsans_r,
          color: color.primary_palette.black,
          width: "20%",
          textAlign: "right",
        },
      },
    },
  },
  border_bottom: {
    borderBottom: `solid ${pxToRem(1)} ${color.primary_palette.black}`,
  },
  width_33: {
    width: "33%",
  },
  input_field: {
    border: `solid ${pxToRem(1)} ${color.secondary_palette.grays.shadow_gray}`,
    height: pxToRem(20),
    // width: "96%",
    fontSize: pxToRem(16),
    borderRadius: pxToRem(3),
    backgroundColor: color.primary_palette.white,
    "& .MuiInputBase-input": {
      padding: `${pxToRem(0)} ${pxToRem(3)}`,
      ...tradework_theme.typography.styles.NeutraText,
      fontSize: pxToRem(16),
      color: color.primary_palette.black,
    },
    "& .MuiInput-underline": {
      "&:after": {
        display: "none",
      },
      "&:before": {
        display: "none",
      },
    },
  },
  cardName: {
    width: pxToRem(122),
    marginRight: pxToRem(14),
  },
  cardName1: {
    width: pxToRem(202),
    marginRight: pxToRem(7),
  },
  expMonthYear: {
    width: pxToRem(50),
    marginRight: pxToRem(14),
  },
  zipCode: {
    width: pxToRem(61),
    marginRight: pxToRem(12),
  },
  cvv: {
    width: pxToRem(75),
  },
  or_spacing: {
    marginLeft: pxToRem(13),
    marginRight: pxToRem(13),
  },
  limboAddMarket: {
    textAlign: "right",
    marginRight: pxToRem(16),
    marginBottom: pxToRem(10),
    marginTop: pxToRem(-20),
  },
  renewBtn: {
    padding: 0,
    width: pxToRem(170),
    height: pxToRem(30),
    borderRadius: pxToRem(20),
    border: `solid ${pxToRem(1)} ${color.form_colors.blueberry_purple}`,
    backgroundColor: color.primary_palette.white,
    color: `${color.primary_palette.franklin_purple} !important`,
    ...tradework_theme.typography.styles.avenir_bold,
    fontSize: pxToRem(15),
    marginBottom: pxToRem(18),
    display: "block",
    "& .MuiButton-label": {
      position: "relative !important",
    },
  },
  addMarketStyle: {
    float: "right",
    position: "relative",
    top: pxToRem(-48),
    right: pxToRem(10),
    cursor: "pointer",
  },
  clearBoth: {
    clear: "both",
  },
  txtRight: {
    textAlign: "right",
  },
  txtCenter: {
    textAlign: "center",
  },
  portfolioMoneyModal: {
    width: pxToRem(641),
    border: `solid ${pxToRem(2)} ${color.primary_palette.free_purple}`,
    backgroundColor: color.secondary_palette.blues.modal_blue,
    margin: "0 auto",
    padding: pxToRem(19),
    "&:focus": {
      outline: "none",
    },
  },
  cross_icon: {
    fontSize: pxToRem(15),
    cursor: "pointer",
    "& path": {
      stroke: color.pedagogical_bronze,
    },
  },
  padding_top_10: {
    paddingTop: pxToRem(10),
  },
  textCapital: {
    textTransform: "uppercase",
  },
  img_main_border: {
    border: `solid ${pxToRem(2)} ${color.primary_palette.black}`,
    width: pxToRem(211),
    padding: pxToRem(5),
    height: pxToRem(163),
    maxHeight: pxToRem(163),
  },
  img_sub_border: {
    border: `solid ${pxToRem(0.8)} ${color.primary_palette.black}`,
    width: "100%",
    height: "100%",
    position: "relative",
  },
  company_logo: {
    width: "100%",
    height: "75%",
  },
  padding_top_12: {
    paddingTop: pxToRem(12),
  },
  user_logo: {
    width: pxToRem(46),
    height: pxToRem(46),
    borderRadius: " 100%",
    border: `solid ${pxToRem(1)} ${color.primary_palette.black}`,
    left: pxToRem(12),
    bottom: pxToRem(12),
    position: "absolute",
  },
  text_decoration: {
    borderBottom: `solid ${pxToRem(1)} ${color.primary_palette.black}`,
  },
  width_18: {
    width: "18%",
  },
  width_70: {
    width: "70%",
  },
  ellipsis_text: {
    width: pxToRem(99),
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    margin: "0 auto",
  },
  alignments_col: {
    display: "flex",
    flexDirection: "column !important",
    height: pxToRem(260),
    flexWrap: "wrap",
    marginTop: pxToRem(10),
  },
  gill_sb: {
    ...tradework_theme.typography.styles.gillsans_sb,
  },
  img_data_spcaing: {
    paddingLeft: pxToRem(156),
    paddingRight: pxToRem(35),
    overflow: "auto",
  },
  padding_left_25: {
    paddingLeft: pxToRem(25),
  },
  greatBtn: {
    width: pxToRem(122),
    height: pxToRem(30),
    padding: 0,
    borderRadius: pxToRem(20),
    border: `solid ${pxToRem(1)} ${color.form_colors.blueberry_purple}`,
    backgroundColor: color.primary_palette.white,
    ...tradework_theme.typography.styles.avenir_sb,
    fontSize: pxToRem(15),
    color: `${color.primary_palette.franklin_purple} !important`,
  },
  text_decoration_franklin_purple: {
    textDecoration: "underline",
  },
  printAlign: {
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  cursor: {
    cursor: "pointer",
  },
  future_rate_card: {
    textDecoration: "underline",
    cursor: "pointer",
  },
  width_324: {
    width: pxToRem(324),
    margin: "0 auto",
    backgroundColor: color.primary_palette.white,
    border: `solid ${pxToRem(1)} ${color.secondary_palette.grays.shadow_gray}`,
    borderRadius: pxToRem(5),
    paddingBottom: pxToRem(20),
    paddingRight: pxToRem(20),
    marginTop: pxToRem(10),
  },
  total_amount: {
    borderTop: `solid ${pxToRem(1)} ${color.primary_palette.black}`,
    padding: `${pxToRem(0)} ${pxToRem(0)} ${pxToRem(0)} ${pxToRem(10)}`,
  },
  padding_top_20: {
    paddingTop: pxToRem(20),
  },
  padding_top_15: {
    paddingTop: pxToRem(15),
  },
  underline: {
    textDecoration: "underline",
  },
  print: {
    width: pxToRem(324),
    margin: "0 auto",
    cursor: "pointer",
  },
  padding_top_7: {
    paddingTop: pxToRem(7),
  },
  text_field: {
    borderRadius: pxToRem(3),
    border: `solid ${pxToRem(1)} ${color.secondary_palette.grays.shadow_gray}`,
    backgroundColor: color.primary_palette.white,
    height: pxToRem(20),
    "& .MuiInput-underline": {
      "&:after": {
        display: "none",
      },
      "&:before": {
        display: "none",
      },
    },
    "& .MuiInputBase-input": {
      height: pxToRem(20),
      padding: 0,
    },
  },
  card: {
    width: pxToRem(121),
  },
  name_card: {
    width: pxToRem(202),
  },
  exp: {
    width: pxToRem(50),
  },
  zip: {
    width: pxToRem(61),
  },
  security: {
    width: pxToRem(75),
  },
  padding_top_40: {
    paddingTop: pxToRem(40),
  },
  check_save: {
    "& .MuiTypography-root": {
      ...tradework_theme.typography.styles.gillsans_r,
      fontSize: pxToRem(12),
      color: color.primary_palette.black,
      // marginTop: pxToRem(-6)
    },
    "& .MuiButtonBase-root": {
      paddingTop: 0,
      paddingRight: 0,
      paddingBottom: 0,
    },
  },
  check_box_spacing: {
    justifyContent: "flex-end",
    paddingRight: pxToRem(40),
  },
  check_box_spacingend: {
    justifyContent: "flex-end",
  },
  margin_right_0: {
    marginRight: 0,
  },
  checkBtn: {
    width: pxToRem(256),
    fontSize: pxToRem(15),
    ...tradework_theme.typography.styles.avenir_sb,
    color: `${color.primary_palette.franklin_purple} !important`,
    height: pxToRem(25),
    padding: 0,
    border: `solid ${pxToRem(1)} ${color.form_colors.blueberry_purple}`,
    backgroundColor: color.primary_palette.white,
    borderRadius: pxToRem(20),
  },
  justifyCenter: {
    justifyContent: "center",
  },
  coloredCrossStyle: {
    width: pxToRem(14),
    marginLeft: pxToRem(6),
    marginRight: pxToRem(6),
    position: "relative",
    top: pxToRem(6),
    cursor: "pointer",
    "& path": {
      fill: color.secondary_palette.grays.shadow_gray,
    },
  },
  which_office: {
    width: pxToRem(440),
    borderRadius: pxToRem(2),
    height: pxToRem(20),
    marginRight: pxToRem(21),
    "& .MuiSvgIcon-root": {
      fontSize: pxToRem(14),
    },
    "& p": {
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
    },
  },
  trash_icon: {
    fontSize: pxToRem(12),
    paddingLeft: pxToRem(10),
    cursor: "pointer",
    "& path": {
      fill: color.secondary_palette.grays.background_gray,
    },
  },
  moenyModalScroll: {
    paddingRight: pxToRem(18),
  },
  padding_bottom_8: {
    paddingBottom: pxToRem(8),
  },
  custom_scroll_height: {
    minHeight: `${pxToRem(600)} !important`,
  },
  rates_sb: {
    ...tradework_theme.typography.styles.gillsans_sb,
    color: color.primary_palette.franklin_purple,
    cursor: "pointer",
  },
  padding_92: {
    padding: `${pxToRem(0)} ${pxToRem(92)}`,
  },
  hidden: {
    overflow: "hidden",
  },
  underlineHover: {
    "&:hover": {
      textDecoration: "underline",
    },
  },
  btnsAlign: {
    alignItems: "center",
    justifyContent: "center",
  },
  saveTxtAlign: {
    width: "82%",
    margin: "0 auto",
    marginTop: pxToRem(20),
    marginBottom: pxToRem(10),
  },
  footerTxtAlign: {
    width: "60%",
    margin: "0 auto",
    lineHeight: "1.2",
  },
});

export default styles;
