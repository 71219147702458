import { withStyles } from "@material-ui/core/styles";
import  { pxToRem, color } from "../../../utilities/themes";

const styles = withStyles({
  grid_2_1: {
    width: pxToRem(501),
    backgroundColor: color.secondary_palette.grays.gary_shade,
    height: pxToRem(529),
    marginRight: pxToRem(21),
  },
  grid_2_2: {
    width: pxToRem(501),
    backgroundColor: color.secondary_palette.grays.gary_shade,
    height: pxToRem(529),
  },
  grid_3_1: {
    width: pxToRem(675),
    height: pxToRem(259),
    marginBottom: pxToRem(15),
    backgroundColor: color.secondary_palette.grays.gary_shade,
  },
  grid_3_2: {
    width: pxToRem(675),
    height: pxToRem(259),
    backgroundColor: color.secondary_palette.grays.gary_shade,
  },
  grid_3_3: {
    width: pxToRem(327),
    height: pxToRem(529),
    backgroundColor: color.secondary_palette.grays.gary_shade,
    marginLeft: pxToRem(21),
  },
  grid_main_spacing: {
    marginTop: pxToRem(10),
    marginBottom: pxToRem(10),
    cursor: "pointer",
    justifyContent: "center",
  },
  grid_main_spacing_m: {
    marginTop: pxToRem(10),
    marginBottom: pxToRem(10),
    justifyContent: "center",
  },
  publicviewProject: {
    width: pxToRem(1023),
    margin: "0 auto",
  },
  grid_4_1: {
    width: pxToRem(327),
    height: pxToRem(529),
    marginRight: pxToRem(23),
    backgroundColor: color.secondary_palette.grays.gary_shade,
  },
  grid_4_2: {
    width: pxToRem(325),
    height: pxToRem(254),
    marginBottom: pxToRem(22),
    backgroundColor: color.secondary_palette.grays.gary_shade,
  },
  grid_4_3: {
    width: pxToRem(325),
    height: pxToRem(254),
    backgroundColor: color.secondary_palette.grays.gary_shade,
  },
  grid_4_4: {
    width: pxToRem(327),
    height: pxToRem(529),
    marginLeft: pxToRem(23),
    backgroundColor: color.secondary_palette.grays.gary_shade,
  },
  grid_5_1: {
    width: pxToRem(333),
    height: pxToRem(156),
    marginBottom: pxToRem(22),
    backgroundColor: color.secondary_palette.grays.gary_shade,
  },
  grid_5_2: {
    width: pxToRem(333),
    height: pxToRem(352),
    backgroundColor: color.secondary_palette.grays.gary_shade,
  },
  grid_5_3: {
    width: pxToRem(327),
    height: pxToRem(529),
    marginRight: pxToRem(16),
    marginLeft: pxToRem(16),
    backgroundColor: color.secondary_palette.grays.gary_shade,
  },
  grid_5_4: {
    width: pxToRem(333),
    height: pxToRem(352),
    backgroundColor: color.secondary_palette.grays.gary_shade,
  },
  grid_5_5: {
    width: pxToRem(333),
    height: pxToRem(156),
    marginTop: pxToRem(22),
    backgroundColor: color.secondary_palette.grays.gary_shade,
  },
  grid_6_1: {
    width: pxToRem(333),
    height: pxToRem(352),
    backgroundColor: color.secondary_palette.grays.gary_shade,
    marginBottom: pxToRem(22),
    marginRight: pxToRem(17),
  },
  grid_6_2: {
    width: pxToRem(333),
    height: pxToRem(156),
    backgroundColor: color.secondary_palette.grays.gary_shade,
    marginRight: pxToRem(17),
  },
  grid_6_3: {
    width: pxToRem(325),
    height: pxToRem(254),
    backgroundColor: color.secondary_palette.grays.gary_shade,
    marginRight: pxToRem(17),
    marginBottom: pxToRem(22),
  },
  grid_6_4: {
    width: pxToRem(325),
    height: pxToRem(254),
    backgroundColor: color.secondary_palette.grays.gary_shade,
    marginRight: pxToRem(17),
  },
  grid_6_5: {
    width: pxToRem(333),
    height: pxToRem(156),
    backgroundColor: color.secondary_palette.grays.gary_shade,
    marginBottom: pxToRem(22),
  },
  grid_6_6: {
    width: pxToRem(333),
    height: pxToRem(352),
    backgroundColor: color.secondary_palette.grays.gary_shade,
  },

  grid_7_1: {
    width: pxToRem(242),
    height: pxToRem(189),
    backgroundColor: color.secondary_palette.grays.gary_shade,
    marginBottom: pxToRem(16),
    marginRight: pxToRem(16),
  },
  grid_7_2: {
    width: pxToRem(242),
    height: pxToRem(329),
    backgroundColor: color.secondary_palette.grays.gary_shade,
    marginRight: pxToRem(16),
  },
  grid_7_3: {
    width: pxToRem(246),
    height: pxToRem(260),
    backgroundColor: color.secondary_palette.grays.gary_shade,
  },
  grid_7_4: {
    width: pxToRem(246),
    height: pxToRem(260),
    backgroundColor: color.secondary_palette.grays.gary_shade,
    marginLeft: pxToRem(11),
  },
  grid_7_5: {
    width: pxToRem(503),
    height: pxToRem(260),
    backgroundColor: color.secondary_palette.grays.gary_shade,
    marginTop: pxToRem(14),
  },
  grid_7_6: {
    width: pxToRem(242),
    height: pxToRem(189),
    backgroundColor: color.secondary_palette.grays.gary_shade,
    marginLeft: pxToRem(20),
  },
  grid_7_7: {
    width: pxToRem(242),
    height: pxToRem(329),
    backgroundColor: color.secondary_palette.grays.gary_shade,
    marginLeft: pxToRem(20),
    marginTop: pxToRem(16),
  },
  grid_8_1: {
    width: pxToRem(242),
    height: pxToRem(189),
    backgroundColor: color.secondary_palette.grays.gary_shade,
    marginBottom: pxToRem(11),
  },
  grid_8_2: {
    width: pxToRem(242),
    height: pxToRem(329),
    backgroundColor: color.secondary_palette.grays.gary_shade,
  },
  grid_8_3: {
    width: pxToRem(242),
    height: pxToRem(329),
    backgroundColor: color.secondary_palette.grays.gary_shade,
    marginRight: pxToRem(9),
    marginLeft: pxToRem(21),
  },
  grid_8_4: {
    width: pxToRem(247),
    height: pxToRem(127),
    backgroundColor: color.secondary_palette.grays.gary_shade,
    marginBottom: pxToRem(9),
  },
  grid_8_5: {
    width: pxToRem(247),
    height: pxToRem(193),
    backgroundColor: color.secondary_palette.grays.gary_shade,
  },
  grid_8_6: {
    width: pxToRem(498),
    height: pxToRem(191),
    backgroundColor: color.secondary_palette.grays.gary_shade,
    marginTop: pxToRem(9),
    marginLeft: pxToRem(21),
  },
  grid_8_7: {
    width: pxToRem(242),
    height: pxToRem(329),
    backgroundColor: color.secondary_palette.grays.gary_shade,
    marginLeft: pxToRem(22),
  },
  grid_8_8: {
    width: pxToRem(242),
    height: pxToRem(189),
    backgroundColor: color.secondary_palette.grays.gary_shade,
    marginLeft: pxToRem(22),
    marginTop: pxToRem(11),
  },
  grid_9_1: {
    width: pxToRem(242),
    height: pxToRem(189),
    backgroundColor: color.secondary_palette.grays.gary_shade,
    marginBottom: pxToRem(18),
    marginRight: pxToRem(23),
  },
  grid_9_2: {
    width: pxToRem(242),
    height: pxToRem(158),
    backgroundColor: color.secondary_palette.grays.gary_shade,
    marginBottom: pxToRem(13),
    marginRight: pxToRem(23),
  },
  grid_9_3: {
    width: pxToRem(242),
    height: pxToRem(158),
    backgroundColor: color.secondary_palette.grays.gary_shade,
    marginRight: pxToRem(23),
  },
  grid_9_4: {
    width: pxToRem(498),
    height: pxToRem(191),
    backgroundColor: color.secondary_palette.grays.gary_shade,
    marginBottom: pxToRem(16),
  },
  grid_9_5: {
    width: pxToRem(242),
    height: pxToRem(329),
    backgroundColor: color.secondary_palette.grays.gary_shade,
    marginRight: pxToRem(14),
  },
  grid_9_6: {
    width: pxToRem(242),
    height: pxToRem(158),
    backgroundColor: color.secondary_palette.grays.gary_shade,
    marginBottom: pxToRem(13),
  },
  grid_9_7: {
    width: pxToRem(242),
    height: pxToRem(158),
    backgroundColor: color.secondary_palette.grays.gary_shade,
  },
  grid_9_8: {
    width: pxToRem(242),
    height: pxToRem(329),
    backgroundColor: color.secondary_palette.grays.gary_shade,
    marginBottom: pxToRem(18),
    marginLeft: pxToRem(23),
  },
  grid_9_9: {
    width: pxToRem(242),
    height: pxToRem(189),
    backgroundColor: color.secondary_palette.grays.gary_shade,
    marginLeft: pxToRem(23),
  },
  grid_10_1: {
    width: pxToRem(242),
    height: pxToRem(329),
    backgroundColor: color.secondary_palette.grays.gary_shade,
    marginRight: pxToRem(17),
    marginBottom: pxToRem(10),
  },
  grid_10_2: {
    width: pxToRem(242),
    height: pxToRem(189),
    backgroundColor: color.secondary_palette.grays.gary_shade,
    marginRight: pxToRem(17),
  },
  grid_10_3: {
    width: pxToRem(242),
    height: pxToRem(158),
    backgroundColor: color.secondary_palette.grays.gary_shade,
    marginRight: pxToRem(19),
    marginBottom: pxToRem(12),
  },
  grid_10_4: {
    width: pxToRem(242),
    height: pxToRem(189),
    backgroundColor: color.secondary_palette.grays.gary_shade,
    marginRight: pxToRem(19),
    marginBottom: pxToRem(11),
  },
  grid_10_5: {
    width: pxToRem(242),
    height: pxToRem(158),
    backgroundColor: color.secondary_palette.grays.gary_shade,
    marginRight: pxToRem(19),
  },
  grid_10_6: {
    width: pxToRem(242),
    height: pxToRem(329),
    backgroundColor: color.secondary_palette.grays.gary_shade,
    marginRight: pxToRem(19),
    marginBottom: pxToRem(10),
  },
  grid_10_7: {
    width: pxToRem(242),
    height: pxToRem(189),
    backgroundColor: color.secondary_palette.grays.gary_shade,
    marginRight: pxToRem(19),
  },
  grid_10_8: {
    width: pxToRem(242),
    height: pxToRem(158),
    backgroundColor: color.secondary_palette.grays.gary_shade,
    marginBottom: pxToRem(13),
  },
  grid_10_9: {
    width: pxToRem(242),
    height: pxToRem(158),
    backgroundColor: color.secondary_palette.grays.gary_shade,
    marginBottom: pxToRem(10),
  },
  grid_10_10: {
    width: pxToRem(242),
    height: pxToRem(189),
    backgroundColor: color.secondary_palette.grays.gary_shade,
  },
  grid_11_1: {
    width: pxToRem(246),
    height: pxToRem(260),
    backgroundColor: color.secondary_palette.grays.gary_shade,
    marginRight: pxToRem(18),
    marginBottom: pxToRem(13),
  },
  grid_11_2: {
    width: pxToRem(246),
    height: pxToRem(260),
    backgroundColor: color.secondary_palette.grays.gary_shade,
    marginRight: pxToRem(18),
  },
  grid_11_3: {
    width: pxToRem(242),
    height: pxToRem(158),
    backgroundColor: color.secondary_palette.grays.gary_shade,
    marginRight: pxToRem(18),
    marginBottom: pxToRem(17),
  },
  grid_11_4: {
    width: pxToRem(242),
    height: pxToRem(189),
    backgroundColor: color.secondary_palette.grays.gary_shade,
    marginRight: pxToRem(18),
    marginBottom: pxToRem(11),
  },
  grid_11_5: {
    width: pxToRem(242),
    height: pxToRem(158),
    backgroundColor: color.secondary_palette.grays.gary_shade,
    marginRight: pxToRem(18),
  },
  grid_11_6: {
    width: pxToRem(242),
    height: pxToRem(158),
    backgroundColor: color.secondary_palette.grays.gary_shade,
    marginRight: pxToRem(18),
    marginBottom: pxToRem(13),
  },
  grid_11_7: {
    width: pxToRem(148),
    height: pxToRem(156),
    backgroundColor: color.secondary_palette.grays.gary_shade,
    marginRight: pxToRem(20),
    marginBottom: pxToRem(17),
  },
  grid_11_8: {
    width: pxToRem(242),
    height: pxToRem(189),
    backgroundColor: color.secondary_palette.grays.gary_shade,
    marginRight: pxToRem(18),
  },
  grid_11_9: {
    width: pxToRem(242),
    height: pxToRem(158),
    backgroundColor: color.secondary_palette.grays.gary_shade,
    marginBottom: pxToRem(13),
  },
  grid_11_10: {
    width: pxToRem(333),
    height: pxToRem(156),
    backgroundColor: color.secondary_palette.grays.gary_shade,
    marginBottom: pxToRem(17),
  },
  grid_11_11: {
    width: pxToRem(242),
    height: pxToRem(189),
    backgroundColor: color.secondary_palette.grays.gary_shade,
  },
  wrap: {
    flexWrap: "wrap",
  },
  grid_12_1: {
    width: pxToRem(242),
    height: pxToRem(189),
    backgroundColor: color.secondary_palette.grays.gary_shade,
    marginRight: pxToRem(17),
    marginBottom: pxToRem(13),
  },
  grid_12_2: {
    width: pxToRem(244),
    height: pxToRem(126),
    backgroundColor: color.secondary_palette.grays.gary_shade,
    marginBottom: pxToRem(12),
    marginRight: pxToRem(17),
  },
  grid_12_3: {
    width: pxToRem(242),
    height: pxToRem(189),
    backgroundColor: color.secondary_palette.grays.gary_shade,
    marginRight: pxToRem(17),
  },
  grid_12_4: {
    width: pxToRem(242),
    height: pxToRem(158),
    backgroundColor: color.secondary_palette.grays.gary_shade,
    marginBottom: pxToRem(12),
    marginRight: pxToRem(19),
  },
  grid_12_5: {
    width: pxToRem(242),
    height: pxToRem(189),
    backgroundColor: color.secondary_palette.grays.gary_shade,
    marginBottom: pxToRem(12),
    marginRight: pxToRem(19),
  },
  grid_12_6: {
    width: pxToRem(242),
    height: pxToRem(158),
    backgroundColor: color.secondary_palette.grays.gary_shade,
    marginRight: pxToRem(19),
  },
  grid_12_7: {
    width: pxToRem(242),
    height: pxToRem(158),
    backgroundColor: color.secondary_palette.grays.gary_shade,
    marginBottom: pxToRem(12),
    marginRight: pxToRem(18),
  },
  grid_12_8: {
    width: pxToRem(242),
    height: pxToRem(158),
    backgroundColor: color.secondary_palette.grays.gary_shade,
    marginBottom: pxToRem(12),
  },
  grid_12_9: {
    width: pxToRem(242),
    height: pxToRem(158),
    backgroundColor: color.secondary_palette.grays.gary_shade,
    marginBottom: pxToRem(12),
    marginRight: pxToRem(18),
  },
  grid_12_10: {
    width: pxToRem(242),
    height: pxToRem(158),
    backgroundColor: color.secondary_palette.grays.gary_shade,
    marginBottom: pxToRem(12),
  },
  grid_12_11: {
    width: pxToRem(179),
    height: pxToRem(189),
    backgroundColor: color.secondary_palette.grays.gary_shade,
    marginRight: pxToRem(32),
  },
  grid_12_12: {
    width: pxToRem(291),
    height: pxToRem(190),
    backgroundColor: color.secondary_palette.grays.gary_shade,
  },
  grid_1_1: {
    width: pxToRem(717),
    backgroundColor: color.secondary_palette.grays.gary_shade,
    height: pxToRem(371),
  },
  // reduced 30%
  grid_1_1_m: {
    width: pxToRem(502),
    backgroundColor: color.secondary_palette.grays.gary_shade,
    height: pxToRem(260),
  },
  grid_2_2_m: {
    width: pxToRem(351),
    backgroundColor: color.secondary_palette.grays.gary_shade,
    height: pxToRem(371),
  },
  grid_2_1_m: {
    width: pxToRem(351),
    backgroundColor: color.secondary_palette.grays.gary_shade,
    height: pxToRem(371),
    marginRight: pxToRem(21),
  },
  grid_3_1_m: {
    width: pxToRem(473),
    height: pxToRem(182),
    marginBottom: pxToRem(15),
    backgroundColor: color.secondary_palette.grays.gary_shade,
  },
  grid_3_2_m: {
    width: pxToRem(473),
    height: pxToRem(172),
    backgroundColor: color.secondary_palette.grays.gary_shade,
  },
  grid_3_3_m: {
    width: pxToRem(229),
    height: pxToRem(371),
    backgroundColor: color.secondary_palette.grays.gary_shade,
    marginLeft: pxToRem(21),
  },
  grid_4_1_m: {
    width: pxToRem(229),
    height: pxToRem(371),
    marginRight: pxToRem(23),
    backgroundColor: color.secondary_palette.grays.gary_shade,
  },
  grid_4_2_m: {
    width: pxToRem(228),
    height: pxToRem(172),
    marginBottom: pxToRem(22),
    backgroundColor: color.secondary_palette.grays.gary_shade,
  },
  grid_4_3_m: {
    width: pxToRem(228),
    height: pxToRem(172),
    backgroundColor: color.secondary_palette.grays.gary_shade,
  },
  grid_4_4_m: {
    width: pxToRem(229),
    height: pxToRem(371),
    marginLeft: pxToRem(23),
    backgroundColor: color.secondary_palette.grays.gary_shade,
  },
  grid_5_1_m: {
    width: pxToRem(234),
    height: pxToRem(110),
    marginBottom: pxToRem(22),
    backgroundColor: color.secondary_palette.grays.gary_shade,
  },
  grid_5_2_m: {
    width: pxToRem(234),
    height: pxToRem(247),
    backgroundColor: color.secondary_palette.grays.gary_shade,
  },
  grid_5_3_m: {
    width: pxToRem(229),
    height: pxToRem(382),
    marginRight: pxToRem(16),
    marginLeft: pxToRem(16),
    backgroundColor: color.secondary_palette.grays.gary_shade,
  },
  grid_5_4_m: {
    width: pxToRem(234),
    height: pxToRem(247),
    backgroundColor: color.secondary_palette.grays.gary_shade,
  },
  grid_5_5_m: {
    width: pxToRem(234),
    height: pxToRem(110),
    marginTop: pxToRem(22),
    backgroundColor: color.secondary_palette.grays.gary_shade,
  },
  grid_6_1_m: {
    width: pxToRem(234),
    height: pxToRem(247),
    backgroundColor: color.secondary_palette.grays.gary_shade,
    marginBottom: pxToRem(22),
    marginRight: pxToRem(17),
  },
  grid_6_2_m: {
    width: pxToRem(234),
    height: pxToRem(110),
    backgroundColor: color.secondary_palette.grays.gary_shade,
    marginRight: pxToRem(17),
  },
  grid_6_3_m: {
    width: pxToRem(228),
    height: pxToRem(178),
    backgroundColor: color.secondary_palette.grays.gary_shade,
    marginRight: pxToRem(17),
    marginBottom: pxToRem(22),
  },
  grid_6_4_m: {
    width: pxToRem(228),
    height: pxToRem(178),
    backgroundColor: color.secondary_palette.grays.gary_shade,
    marginRight: pxToRem(17),
  },
  grid_6_5_m: {
    width: pxToRem(234),
    height: pxToRem(110),
    backgroundColor: color.secondary_palette.grays.gary_shade,
    marginBottom: pxToRem(22),
  },
  grid_6_6_m: {
    width: pxToRem(234),
    height: pxToRem(247),
    backgroundColor: color.secondary_palette.grays.gary_shade,
  },
  grid_7_1_m: {
    width: pxToRem(170),
    height: pxToRem(133),
    backgroundColor: color.secondary_palette.grays.gary_shade,
    marginBottom: pxToRem(16),
    marginRight: pxToRem(16),
  },
  grid_7_2_m: {
    width: pxToRem(170),
    height: pxToRem(231),
    backgroundColor: color.secondary_palette.grays.gary_shade,
    marginRight: pxToRem(16),
  },
  grid_7_3_m: {
    width: pxToRem(173),
    height: pxToRem(182),
    backgroundColor: color.secondary_palette.grays.gary_shade,
  },
  grid_7_4_m: {
    width: pxToRem(173),
    height: pxToRem(182),
    backgroundColor: color.secondary_palette.grays.gary_shade,
    marginLeft: pxToRem(11),
  },
  grid_7_5_m: {
    width: pxToRem(353),
    height: pxToRem(182),
    backgroundColor: color.secondary_palette.grays.gary_shade,
    marginTop: pxToRem(14),
  },
  grid_7_6_m: {
    width: pxToRem(170),
    height: pxToRem(133),
    backgroundColor: color.secondary_palette.grays.gary_shade,
    marginLeft: pxToRem(20),
  },
  grid_7_7_m: {
    width: pxToRem(170),
    height: pxToRem(231),
    backgroundColor: color.secondary_palette.grays.gary_shade,
    marginLeft: pxToRem(20),
    marginTop: pxToRem(16),
  },
  grid_8_1_m: {
    width: pxToRem(170),
    height: pxToRem(133),
    backgroundColor: color.secondary_palette.grays.gary_shade,
    marginBottom: pxToRem(11),
  },
  grid_8_2_m: {
    width: pxToRem(170),
    height: pxToRem(231),
    backgroundColor: color.secondary_palette.grays.gary_shade,
  },
  grid_8_3_m: {
    width: pxToRem(170),
    height: pxToRem(231),
    backgroundColor: color.secondary_palette.grays.gary_shade,
    marginRight: pxToRem(9),
    marginLeft: pxToRem(21),
  },
  grid_8_4_m: {
    width: pxToRem(173),
    height: pxToRem(89),
    backgroundColor: color.secondary_palette.grays.gary_shade,
    marginBottom: pxToRem(9),
  },
  grid_8_5_m: {
    width: pxToRem(173),
    height: pxToRem(136),
    backgroundColor: color.secondary_palette.grays.gary_shade,
  },
  grid_8_6_m: {
    width: pxToRem(349),
    height: pxToRem(128),
    backgroundColor: color.secondary_palette.grays.gary_shade,
    marginTop: pxToRem(9),
    marginLeft: pxToRem(21),
  },
  grid_8_7_m: {
    width: pxToRem(170),
    height: pxToRem(231),
    backgroundColor: color.secondary_palette.grays.gary_shade,
    marginLeft: pxToRem(22),
  },
  grid_8_8_m: {
    width: pxToRem(170),
    height: pxToRem(133),
    backgroundColor: color.secondary_palette.grays.gary_shade,
    marginLeft: pxToRem(22),
    marginTop: pxToRem(11),
  },
  grid_9_1_m: {
    width: pxToRem(170),
    height: pxToRem(133),
    backgroundColor: color.secondary_palette.grays.gary_shade,
    marginBottom: pxToRem(18),
    marginRight: pxToRem(23),
  },
  grid_9_2_m: {
    width: pxToRem(170),
    height: pxToRem(111),
    backgroundColor: color.secondary_palette.grays.gary_shade,
    marginBottom: pxToRem(13),
    marginRight: pxToRem(23),
  },
  grid_9_3_m: {
    width: pxToRem(170),
    height: pxToRem(111),
    backgroundColor: color.secondary_palette.grays.gary_shade,
    marginRight: pxToRem(23),
  },
  grid_9_4_m: {
    width: pxToRem(349),
    height: pxToRem(134),
    backgroundColor: color.secondary_palette.grays.gary_shade,
    marginBottom: pxToRem(16),
  },
  grid_9_5_m: {
    width: pxToRem(170),
    height: pxToRem(240),
    backgroundColor: color.secondary_palette.grays.gary_shade,
    marginRight: pxToRem(14),
  },
  grid_9_6_m: {
    width: pxToRem(170),
    height: pxToRem(100),
    backgroundColor: color.secondary_palette.grays.gary_shade,
    marginBottom: pxToRem(13),
  },
  grid_9_7_m: {
    width: pxToRem(170),
    height: pxToRem(124),
    backgroundColor: color.secondary_palette.grays.gary_shade,
  },
  grid_9_8_m: {
    width: pxToRem(170),
    height: pxToRem(231),
    backgroundColor: color.secondary_palette.grays.gary_shade,
    marginBottom: pxToRem(18),
    marginLeft: pxToRem(23),
  },
  grid_9_9_m: {
    width: pxToRem(170),
    height: pxToRem(133),
    backgroundColor: color.secondary_palette.grays.gary_shade,
    marginLeft: pxToRem(23),
  },
  grid_10_1_m: {
    width: pxToRem(170),
    height: pxToRem(231),
    backgroundColor: color.secondary_palette.grays.gary_shade,
    marginRight: pxToRem(17),
    marginBottom: pxToRem(10),
  },
  grid_10_2_m: {
    width: pxToRem(170),
    height: pxToRem(140),
    backgroundColor: color.secondary_palette.grays.gary_shade,
    marginRight: pxToRem(17),
  },
  grid_10_3_m: {
    width: pxToRem(170),
    height: pxToRem(111),
    backgroundColor: color.secondary_palette.grays.gary_shade,
    marginRight: pxToRem(19),
    marginBottom: pxToRem(12),
  },
  grid_10_4_m: {
    width: pxToRem(170),
    height: pxToRem(133),
    backgroundColor: color.secondary_palette.grays.gary_shade,
    marginRight: pxToRem(19),
    marginBottom: pxToRem(11),
  },
  grid_10_5_m: {
    width: pxToRem(170),
    height: pxToRem(111),
    backgroundColor: color.secondary_palette.grays.gary_shade,
    marginRight: pxToRem(19),
  },
  grid_10_6_m: {
    width: pxToRem(170),
    height: pxToRem(231),
    backgroundColor: color.secondary_palette.grays.gary_shade,
    marginRight: pxToRem(19),
    marginBottom: pxToRem(10),
  },
  grid_10_7_m: {
    width: pxToRem(170),
    height: pxToRem(140),
    backgroundColor: color.secondary_palette.grays.gary_shade,
    marginRight: pxToRem(19),
  },
  grid_10_8_m: {
    width: pxToRem(170),
    height: pxToRem(111),
    backgroundColor: color.secondary_palette.grays.gary_shade,
    marginBottom: pxToRem(13),
  },
  grid_10_9_m: {
    width: pxToRem(170),
    height: pxToRem(111),
    backgroundColor: color.secondary_palette.grays.gary_shade,
    marginBottom: pxToRem(10),
  },
  grid_10_10_m: {
    width: pxToRem(170),
    height: pxToRem(133),
    backgroundColor: color.secondary_palette.grays.gary_shade,
  },
  grid_11_1_m: {
    width: pxToRem(173),
    height: pxToRem(182),
    backgroundColor: color.secondary_palette.grays.gary_shade,
    marginRight: pxToRem(18),
    marginBottom: pxToRem(13),
  },
  grid_11_2_m: {
    width: pxToRem(173),
    height: pxToRem(192),
    backgroundColor: color.secondary_palette.grays.gary_shade,
    marginRight: pxToRem(18),
  },
  grid_11_3_m: {
    width: pxToRem(170),
    height: pxToRem(111),
    backgroundColor: color.secondary_palette.grays.gary_shade,
    marginRight: pxToRem(18),
    marginBottom: pxToRem(17),
  },
  grid_11_4_m: {
    width: pxToRem(170),
    height: pxToRem(133),
    backgroundColor: color.secondary_palette.grays.gary_shade,
    marginRight: pxToRem(18),
    marginBottom: pxToRem(11),
  },
  grid_11_5_m: {
    width: pxToRem(170),
    height: pxToRem(111),
    backgroundColor: color.secondary_palette.grays.gary_shade,
    marginRight: pxToRem(18),
  },
  grid_11_6_m: {
    width: pxToRem(190),
    height: pxToRem(111),
    backgroundColor: color.secondary_palette.grays.gary_shade,
    marginRight: pxToRem(18),
    marginBottom: pxToRem(13),
  },
  grid_11_7_m: {
    width: pxToRem(196),
    height: pxToRem(110),
    backgroundColor: color.secondary_palette.grays.gary_shade,
    // marginRight: pxToRem(20),
    marginBottom: pxToRem(17),
  },
  grid_11_8_m: {
    width: pxToRem(136),
    height: pxToRem(133),
    backgroundColor: color.secondary_palette.grays.gary_shade,
    marginRight: pxToRem(18),
    marginBottom: pxToRem(17),
  },
  grid_11_9_m: {
    width: pxToRem(251),
    height: pxToRem(133),
    backgroundColor: color.secondary_palette.grays.gary_shade,
    marginBottom: pxToRem(13),
  },
  grid_11_10_m: {
    width: pxToRem(200),
    height: pxToRem(111),
    backgroundColor: color.secondary_palette.grays.gary_shade,
    // marginBottom: pxToRem(17),
    marginRight: pxToRem(18),
  },
  grid_11_11_m: {
    width: pxToRem(188),
    height: pxToRem(111),
    backgroundColor: color.secondary_palette.grays.gary_shade,
  },
  width_789: {
    width: `${pxToRem(789)} !important`,
    margin: "0 auto",
  },
  grid_12_1_m: {
    width: pxToRem(170),
    height: pxToRem(133),
    backgroundColor: color.secondary_palette.grays.gary_shade,
    marginRight: pxToRem(17),
    marginBottom: pxToRem(13),
  },
  grid_12_2_m: {
    width: pxToRem(171),
    height: pxToRem(89),
    backgroundColor: color.secondary_palette.grays.gary_shade,
    marginBottom: pxToRem(12),
    marginRight: pxToRem(17),
  },
  grid_12_3_m: {
    width: pxToRem(170),
    height: pxToRem(133),
    backgroundColor: color.secondary_palette.grays.gary_shade,
    marginRight: pxToRem(17),
  },
  grid_12_4_m: {
    width: pxToRem(170),
    height: pxToRem(111),
    backgroundColor: color.secondary_palette.grays.gary_shade,
    marginBottom: pxToRem(12),
    marginRight: pxToRem(19),
  },
  grid_12_5_m: {
    width: pxToRem(170),
    height: pxToRem(133),
    backgroundColor: color.secondary_palette.grays.gary_shade,
    marginBottom: pxToRem(12),
    marginRight: pxToRem(19),
  },
  grid_12_6_m: {
    width: pxToRem(170),
    height: pxToRem(111),
    backgroundColor: color.secondary_palette.grays.gary_shade,
    marginRight: pxToRem(19),
  },
  grid_12_7_m: {
    width: pxToRem(170),
    height: pxToRem(111),
    backgroundColor: color.secondary_palette.grays.gary_shade,
    marginBottom: pxToRem(12),
    marginRight: pxToRem(18),
  },
  grid_12_8_m: {
    width: pxToRem(170),
    height: pxToRem(111),
    backgroundColor: color.secondary_palette.grays.gary_shade,
    marginBottom: pxToRem(12),
  },
  grid_12_9_m: {
    width: pxToRem(170),
    height: pxToRem(111),
    backgroundColor: color.secondary_palette.grays.gary_shade,
    marginBottom: pxToRem(12),
    marginRight: pxToRem(18),
  },
  grid_12_10_m: {
    width: pxToRem(170),
    height: pxToRem(111),
    backgroundColor: color.secondary_palette.grays.gary_shade,
    marginBottom: pxToRem(12),
  },
  grid_12_11_m: {
    width: pxToRem(126),
    height: pxToRem(133),
    backgroundColor: color.secondary_palette.grays.gary_shade,
    marginRight: pxToRem(18),
  },
  grid_12_12_m: {
    width: pxToRem(214),
    height: pxToRem(133),
    backgroundColor: color.secondary_palette.grays.gary_shade,
  },
  width_740: {
    width: `${pxToRem(740)} !important`,
    margin: "0 auto",
  },
});

export default styles;
