import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { get, map, startsWith } from "lodash";
import { useHistory } from "react-router-dom";

import { color } from "../../utilities/themes";
import publicViewStyles from "./styles/publicViewStyles";
import Row from "../common/ui_kit/row";
import Text from "../common/ui_kit/text";
import strings from "../../utilities/strings";
import CustomButton from "../navigations/custom_buttons";
import Location from "../data_display/icons/location";
import Feedback from "../modals/feedbackModal";

import { ProfileActions, SettingsActions } from "../../redux/actions";
import CustomModal from "../inputs/custom_modal";

function PublicView(props) {
  const { classes, noMargin } = props;
  const history = useHistory();
  const [state, setState] = useState({
    toggleFeedbackModal: false,
  });

  const { toggleFeedbackModal } = state;

  const getUrl = (url) => {
    if (url) {
      if (startsWith(url, "http://") || startsWith(url, "https://")) {
        return url;
      }
      return `//${url}`;
    }
    return "javascript:void(0)";
  };

  //API
  useEffect(() => {
    fetchData();
  }, []);

  const productsAndPublications = useSelector(
    (resData) => resData.Profile.productsAndPublicationData
  );
  const portfolioData = useSelector((resData) => resData.Profile.portfolioData);

  const dispatch = useDispatch();
  const fetchData = () => {
    dispatch(
      ProfileActions.fetchProjectAndPublicationData(
        localStorage.getItem("portfolioId")
      )
    );
  };

  const redirectToPortfolioPage = () => {
    history.push("/portfolioView");
  };

  const openMarketModal = () => {
    dispatch(SettingsActions.isOpenMarketModal(true));
    history.push("/portfolioView");
    localStorage.setItem("openMarketModal", true);
  };

  const handleFeedbackModal = () => {
    setState({
      ...state,
      toggleFeedbackModal: !toggleFeedbackModal,
    });
  };

  return (
    <>
      <div
        className={
          noMargin
            ? classes.product_publication_public
            : classes.product_publication
        }
      >
        {!get(props, "location.state.isPublicMode", true) && (
          <>
            {!get(portfolioData, "isVisibleToPublic") && (
              <Row className={classes.publicIconAlign}>
                <Text
                  size={14}
                  color={color.primary_palette.black}
                  family="avenir_sb"
                  className={classes.publicTxtAlign}
                >
                  {" "}
                  Your are in Portfolio:{" "}
                  <span>{get(portfolioData, "name", "")}</span>. To make public,
                  click here{" "}
                </Text>
                <Location
                  className={`${classes.markerAlign} hover_icon`}
                  onClick={openMarketModal}
                />
              </Row>
            )}
            <CustomButton
              className={classes.preview_btn}
              onMouseUp={redirectToPortfolioPage}
            >
              {strings.add_project.titles.back_to_portfolio}
            </CustomButton>
          </>
        )}
        <img
          src="/assets/images/Beta_Stamp.png"
          className={classes.betaStampIcon}
          alt=""
          onClick={handleFeedbackModal}
        />
        <div className={classes.textCenter}>
          <Text
            size={25}
            color={color.primary_palette.black}
            className={classes.padding_top_25}
            family="TrajanPro3"
          >
            {strings.publicView_p.titles.Products_Publications}
          </Text>
          <img
            alt=""
            src="/assets/images/Horizontal_DB.jpg"
            className={classes.horizontal_DB}
          />
          <Text
            size={40}
            color={color.primary_palette.black}
            className={classes.padding_top_18}
            family="TrajanPro3"
          >
            {productsAndPublications &&
              get(productsAndPublications, "name", "")}
          </Text>
        </div>
        {get(productsAndPublications, "products.length", "") > 0 && (
          <div className={classes.product}>
            <Text
              size={27}
              color={color.primary_palette.free_purple}
              className={classes.product_text}
              family="TrajanPro3"
            >
              {strings.publicView_p.titles.products}
            </Text>

            {get(productsAndPublications, "products", "") &&
              map(productsAndPublications.products, (product) => {
                return (
                  <Row className={classes.product_spacing}>
                    <Row className={classes.width40}>
                      <div className={classes.product_img}>
                        <div className={classes.img_block}>
                          <img alt="" src={product.imageUri} />
                        </div>
                      </div>
                      <div className={classes.titleDivAlign}>
                        <div className={classes.subDivAlign}>
                          <Text
                            size={16}
                            color={color.primary_palette.black}
                            family="avenir_bold"
                            className={classes.product_heading}
                            transform="capitalize"
                          >
                            {product.title}
                          </Text>
                          <Text
                            size={16}
                            color={color.secondary_palette.grays.gary_shade}
                            family="avenir_sb"
                          >
                            {product.subTitle}
                          </Text>
                        </div>
                        {get(product, "referenceUri") && (
                          <Row className={classes.flex_end_block}>
                            <Text
                              size={14}
                              color={color.primary_palette.free_purple}
                              family="avenir_sb"
                              className={classes.more_info}
                            >
                              <a
                                href={getUrl(product.referenceUri)}
                                target="_blank"
                                className={classes.more_info}
                              >
                                {strings.publicView_p.titles.more_info}
                              </a>
                            </Text>
                          </Row>
                        )}
                      </div>
                    </Row>
                    <Row className={classes.width60}>
                      <div className={classes.product_des}>
                        <Text
                          size={14}
                          color={color.primary_palette.black}
                          family="avenir_light"
                          className={classes.margin_17}
                        >
                          {get(product, "description", "")}
                        </Text>
                      </div>
                      <Row>
                        {get(product, "tags[0]", "") &&
                          map(product.tags[0].split(","), (tags) => {
                            return (
                              <Text
                                size={14}
                                color={color.vicious_oyster}
                                family="avenir_bold"
                                className={classes.margin_17}
                              >
                                {tags}
                              </Text>
                            );
                          })}
                      </Row>
                    </Row>
                  </Row>
                );
              })}
          </div>
        )}
        {get(productsAndPublications, "publications.length", "") > 0 && (
          <div className={classes.product}>
            <Text
              size={27}
              color={color.primary_palette.free_purple}
              className={classes.product_text}
              family="TrajanPro3"
            >
              {strings.publicView_p.titles.publications}
            </Text>

            {get(productsAndPublications, "publications", "") &&
              map(productsAndPublications.publications, (publication) => {
                return (
                  <Row className={classes.product_spacing}>
                    <Row className={classes.width40}>
                      <div className={classes.product_img}>
                        <div className={classes.img_block}>
                          <img alt="" src={publication.imageUri} />
                        </div>
                      </div>
                      <div className={classes.titleDivAlign}>
                        <div className={classes.subDivAlign}>
                          <Text
                            size={16}
                            color={color.primary_palette.black}
                            family="avenir_bold"
                            className={classes.product_heading}
                            transform="capitalize"
                          >
                            {publication.title}
                          </Text>
                          <Text
                            size={16}
                            color={color.secondary_palette.grays.gary_shade}
                            family="avenir_sb"
                          >
                            {publication.subTitle}
                          </Text>
                        </div>
                        {get(publication, "referenceUri") && (
                          <Row className={classes.flex_end_block}>
                            <Text
                              size={14}
                              color={color.primary_palette.free_purple}
                              family="avenir_sb"
                              className={classes.more_info}
                            >
                              <a
                                href={getUrl(publication.referenceUri)}
                                target="_blank"
                                className={classes.more_info}
                              >
                                {strings.publicView_p.titles.more_info}
                              </a>
                            </Text>
                          </Row>
                        )}
                      </div>
                    </Row>
                    <Row className={classes.width60}>
                      <div className={classes.product_des}>
                        <Text
                          size={14}
                          color={color.primary_palette.black}
                          family="avenir_light"
                          className={classes.margin_17}
                        >
                          {get(publication, "description", "")}
                        </Text>
                      </div>
                      <Row>
                        {get(publication, "tags[0]", "") &&
                          map(publication.tags[0].split(","), (tags) => {
                            return (
                              <Text
                                size={14}
                                color={color.vicious_oyster}
                                family="avenir_bold"
                                className={classes.margin_17}
                              >
                                {tags}
                              </Text>
                            );
                          })}
                      </Row>
                    </Row>
                  </Row>
                );
              })}
          </div>
        )}
      </div>
      <CustomModal
        open={toggleFeedbackModal}
        onClose={handleFeedbackModal}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Feedback
          open={toggleFeedbackModal}
          onClose={handleFeedbackModal}
          locationInfo="Products and Publications Page"
        />
      </CustomModal>
    </>
  );
}

export default publicViewStyles(PublicView);
