import { withStyles } from "@material-ui/core/styles";
import tradework_theme, { pxToRem, color } from "../../../utilities/themes";

const styles = withStyles({
  dashboardWidth: {
    width: pxToRem(858),
    border: `solid ${pxToRem(2)} ${color.primary_palette.free_purple}`,
    backgroundColor: color.secondary_palette.blues.modal_blue,
    position: "relative",
  },
  btnRight: {
    textAlign: "right",
  },
  crossBtn: {
    minWidth: pxToRem(30),
    padding: pxToRem(16),
    "& .MuiSvgIcon-root": {
      fontSize: pxToRem(15),
    },
  },
  textCenter: {
    textAlign: "center",
  },
  edit_icon: {
    fontSize: pxToRem(60),
    position: "absolute",
    top: pxToRem(-28),
    left: "47%",
  },
  vignettes_img_block: {
    width: pxToRem(136),
    position: "relative",
    height: pxToRem(110),
    marginRight: pxToRem(10),
    marginTop: pxToRem(5),
    display: "inline-block",
    zIndex: 9999,
    "&:hover": {
      transform: "scale(1.06)",
    },
  },
  vignettes_img_block_active: {
    width: pxToRem(150),
    height: pxToRem(130),
    position: "relative",
    marginRight: pxToRem(10),
    marginTop: pxToRem(5),
    display: "inline-block",
    zIndex: 9999,
    boxShadow: `${pxToRem(5)} ${pxToRem(5)} ${pxToRem(5)} 0 rgba(0, 0, 0, 0.4)`,
  },
  vignettes_img: {
    width: pxToRem(110),
    height: pxToRem(110),
    backgroundColor: color.secondary_palette.grays.background_gray,
    "& img": {
      width: "100%",
      height: "100%",
      objectFit: "cover",
    },
  },
  vignettes_img_active: {
    width: pxToRem(126),
    height: pxToRem(126),
    backgroundColor: color.secondary_palette.grays.background_gray,
    "& img": {
      width: "100%",
      height: "100%",
      objectFit: "cover",
    },
  },
  trash_icon: {
    fontSize: pxToRem(9),
    display: "block",
    paddingBottom: pxToRem(4),
    cursor: "pointer",
  },
  hamburger_icon: {
    width: pxToRem(9),
    display: "block",
    paddingBottom: pxToRem(4),
    cursor: "move",
  },
  pencil_icon: {
    width: pxToRem(9),
    display: "block",
    paddingBottom: pxToRem(4),
    cursor: "pointer",
  },
  icons_list: {
    position: "absolute",
    top: 0,
    right: pxToRem(4),
  },
  justifyCenter: {
    justifyContent: "center",
  },
  spacing: {
    padding: `${pxToRem(0)} ${pxToRem(30)}`,
  },
  saveBtn: {
    marginBottom: pxToRem(7),
    width: pxToRem(91),
    height: pxToRem(25),
    borderRadius: pxToRem(27),
    padding: 0,
    border: `solid ${pxToRem(1)} ${color.primary_palette.franklin_purple}`,
    backgroundColor: color.primary_palette.white,
    marginTop: pxToRem(5),
    "& .MuiButton-label": {
      ...tradework_theme.typography.styles.avenir_sb,
      fontSize: pxToRem(15),
      color: color.primary_palette.franklin_purple,
    },
  },
  imageDisplaySize: {
    height: pxToRem(77),
    width: pxToRem(98),
    paddingTop: pxToRem(5),
    marginRight: pxToRem(7),
    cursor: "copy",
  },
  imagePlaceholderSize: {
    height: pxToRem(77),
    paddingTop: pxToRem(5),
    marginRight: pxToRem(7),
    cursor: "pointer",
  },
  flex_wrap: {
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
  dragable_scroll: {
    minHeight: pxToRem(171),
  },
  margin_top_10: {
    marginTop: pxToRem(-10),
  },
  vignettes_scroll: {
    minHeight: pxToRem(375),
  },
  add_vignette_img: {
    width: pxToRem(77),
    height: pxToRem(77),
  },
  noProjectTxtAlign: {
    width: pxToRem(600),
    margin: "0 auto",
    textAlign: "center",
  },
});

export default styles;
