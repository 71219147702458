import { withStyles } from "@material-ui/core/styles";
import tradework_theme, { pxToRem, color } from "../../utilities/themes";

const styles = withStyles({
  wrapper: {
    marginTop: pxToRem(100),
    textAlign: "center",
  },
  select_field: {
    borderBottom: `solid ${pxToRem(1)} ${
      color.secondary_palette.grays.shadow_gray
    }`,
    width: "100%",
    marginRight: pxToRem(30),
    "& .MuiInputBase-root": {
      borderBottom: 0,
    },
    "& .MuiInput-underline": {
      "&:before": {
        display: "none",
      },
      "&:after": {
        display: "none",
      },
    },
    "& .MuiInputBase-input": {
      fontSize: pxToRem(16),
      // ...tradework_theme.typography.styles.TrajanPro3,
      ...tradework_theme.typography.styles.NeutraText,
      color: color.primary_palette.black,
      textTransform: "uppercase",
      textAlign: "center",
    },
    "& .MuiFormLabel-root": {
      width: "100%",
      fontSize: pxToRem(16),
      color: color.secondary_palette.grays.shadow_gray,
      // fontStyle: "oblique",
      textAlign: "center",
      ...tradework_theme.typography.styles.avenir_sb,
    },
    "& .MuiFormControl-marginNormal": {
      marginBottom: pxToRem(-6),
    },
    "& .MuiInputLabel-shrink": {
      display: "none",
    },
  },
  field_wrapper: {
    alignItems: "flex-end",
    justifyContent: "center",
  },
  grid_button: {
    width: pxToRem(150),
    height: pxToRem(25),
    borderRadius: pxToRem(27),
    border: `solid ${pxToRem(2)} ${color.primary_palette.franklin_purple}`,
    backgroundColor: color.primary_palette.white,
    color: `${color.primary_palette.franklin_purple} !important`,
    ...tradework_theme.typography.styles.avenir_bold,
    fontSize: pxToRem(15),
    marginTop: pxToRem(30),
    marginLeft: pxToRem(20),
  },
  dropdownWidth: {
    flexGrow: 1,
    border: 0,
    width: pxToRem(318),
    padding: 0,
    borderBottom: `solid ${pxToRem(1)} ${
      color.secondary_palette.grays.shadow_gray
    }`,
    "& p": {
      fontSize: pxToRem(14),
      color: color.primary_palette.black,
      ...tradework_theme.typography.styles.avenir_light,
      position: "relative",
      top: pxToRem(4),
    },
    "& .MuiSvgIcon-root": {
      fontSize: pxToRem(10),
      position: "relative",
      top: pxToRem(4),
    },
  },
});

export default styles;
