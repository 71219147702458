import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { get, startsWith } from "lodash";
import { Slider } from "@material-ui/core";

import chooseStyle from "./styles/choose_picture";
import Row from "../common/ui_kit/row";
import Text from "../common/ui_kit/text";
import strings from "../../utilities/strings";
import Close_Icon from "../data_display/icons/Close";
import CustomButton from "../navigations/custom_buttons";
import CustomCropper from "../cropper/custom_cropper";
import getCroppedImg from "../cropper/crop_image";
import Trash_can from "../data_display/icons/Trash_can";

import { color } from "../../utilities/themes";
import { ProfileActions } from "../../redux/actions";

function ChoosePicture(props) {
  const {
    classes,
    type,
    imageUrl,
    coverImageUrl,
    isProfileCropper,
    updateCroppedImage,
    openImageExp,
    onClose,
    ishideDelete,
    transparentColor,
  } = props;
  const [values, setValues] = useState({
    zoom: 1,
    crop: { x: 0, y: 0 },
    colorValue: transparentColor,
  });

  const dispatch = useDispatch();

  const showCroppedImage = async () => {
    const { croppedAreaPixels } = values;
    try {
      let urlObj = "";
      if (isProfileCropper) {
        urlObj = imageUrl;
      } else {
        urlObj = coverImageUrl;
      }
      const croppedImage = await getCroppedImg(
        urlObj,
        croppedAreaPixels,
        values.colorValue
      );

      let originalBlob = await fetch(imageUrl).then((r) => r.blob());

      const obj = {
        showCropper: false,
      };
      if (isProfileCropper) {
        obj.croppedImage = URL.createObjectURL(croppedImage);
        obj.blobUrl = croppedImage;
        obj.originalBlob = originalBlob;
        setValues({
          ...values,
          croppedProfileImage: croppedImage,
          originalImage: urlObj,
        });
      } else {
        obj.croppedCoverImage = URL.createObjectURL(croppedImage);
        obj.blobUrl = croppedImage;
        obj.originalBlob = originalBlob;
        setValues({ ...values, croppedCoverImage: croppedImage });
      }
      setValues({ ...values, obj });
      updateCroppedImage(obj, type);
      const dataToSubmit = {
        [type]: get(obj, "blobUrl", ""),
        [`original${type}`]:
          startsWith(coverImageUrl, "http://") && get(obj, "originalBlob"),
      };
      if (type === "companyBasicsLogo" || type === "headShot") {
        dispatch(
          ProfileActions.saveCompanyBasicsImages(dataToSubmit, (res) => {
            onClose();
          })
        );
        return;
      }
      dispatch(
        ProfileActions.saveProfileImage(dataToSubmit, (res) => {
          if (!res.error.error) {
            dispatch(
              ProfileActions.getCoverImageData(
                localStorage.getItem("portfolioId")
              )
            );
            dispatch(
              ProfileActions.getPortfolioData(
                localStorage.getItem("portfolioId")
              )
            );
          }
        })
      );
      onClose();
      dispatch(
        ProfileActions.getPortfolioData(localStorage.getItem("portfolioId"))
      );
    } catch (e) {
      console.error(e);
    }
  };

  const deleteImage = () => {
    let ImageType =
      type === "logo" ? "LOGO" : type === "cover" ? "COVER" : "HEADSHOT";
    dispatch(
      ProfileActions.deleteProfileImage(
        localStorage.getItem("portfolioId"),
        ImageType,
        (res) => {
          if (type === "logo") {
            localStorage.removeItem("activeCompanyLogo");
          }
          onClose();
          dispatch(
            ProfileActions.getPortfolioData(localStorage.getItem("portfolioId"))
          );
        }
      )
    );
  };

  const changeBgColor = (selectedColor) => () => {
    setValues({ ...values, colorValue: selectedColor });
  };

  const onCropComplete = (coords) => {
    const croppedAreaPixels = get(coords, "main.croppedAreaPixels", "");
    setValues({ ...values, croppedAreaPixels });
  };

  const setCrop = (value) => {
    setValues({ ...values, crop: value });
  };

  const setZoom = (value) => {
    setValues({ ...values, zoom: value });
  };
  const { zoom, crop, colorValue } = values;
  return (
    <>
      <div className={classes.choose_width}>
        <div className={classes.textRight}>
          <CustomButton className={classes.cross_btn} isX>
            <Close_Icon className={classes.cross_mark} onClick={onClose} />
          </CustomButton>
        </div>
        <div className={classes.padding_space}>
          <Text
            size={28}
            color={color.primary_palette.black}
            family="avenir_light"
            className={classes.title_text}
          >
            {type == "profile" && strings.add_image.titles.profile_picture}
            {type == "logo" && strings.add_image.titles.company_logo_title}
            {type == "companyBasicsLogo" &&
              strings.add_image.titles.company_logo_title}
            {type == "cover" && strings.add_image.titles.cover_image}
            {type == "headShot" &&
              strings.add_image.titles.company_logo_headshot}
          </Text>
          {type == "cover" && (
            <Text
              size={16}
              color={color.primary_palette.black}
              family="avenir_roman"
              className={classes.title_sub_text}
            >
              {strings.add_image.titles.cover_subtext}
            </Text>
          )}
          {type == "logo" && (
            <Text
              size={16}
              color={color.primary_palette.black}
              family="avenir_roman"
              className={classes.drag_text}
            >
              {strings.add_image.titles.company_logo_subtitle}
            </Text>
          )}
          <div
            className={
              (type == "profile" && classes.cropProfile) ||
              (type == "logo" && classes.cropLogo) ||
              (type == "companyBasicsLogo" && classes.cropLogo) ||
              (type == "cover" && classes.cropCover) ||
              (type == "headShot" && classes.cropCover)
            }
            style={{ backgroundColor: values.colorValue }}
          >
            <CustomCropper
              image={isProfileCropper ? imageUrl : coverImageUrl}
              crop={crop}
              zoom={zoom}
              cropShape={type == "cover" ? "rect" : "round"}
              aspect={type == "cover" ? 2 : 1}
              onCropChange={setCrop}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
              showGrid={false}
              multiple={type == "cover" ? false : true}
            />
          </div>
          {(startsWith(imageUrl, "https://") ||
            startsWith(coverImageUrl, "https://")) &&
            !ishideDelete && (
              <Trash_can className={classes.trash_can} onClick={deleteImage} />
            )}
          <div className={classes.slider_width}>
            <Slider
              value={zoom}
              min={0.4}
              max={3}
              step={0.1}
              aria-labelledby="Zoom"
              classes={classes.slider}
              onChange={(e, zoomVal) => setZoom(zoomVal)}
            />
          </div>
          <div className={classes.drag_text}>
            <Text
              size={15}
              color={
                type == "profile"
                  ? color.secondary_palette.grays.medium_gray
                  : color.secondary_palette.grays.drag_text
              }
              family="avenir_roman"
            >
              {strings.add_image.titles.drag_img}
            </Text>
            <Text
              size={15}
              color={
                type == "profile"
                  ? color.secondary_palette.grays.medium_gray
                  : color.secondary_palette.grays.drag_text
              }
              family="avenir_roman"
            >
              {strings.add_image.titles.drag_zoom}
            </Text>
          </div>
          <div className={classes.drag_text}>
            <Text
              size={15}
              color={color.primary_palette.black}
              family="avenir_roman"
              className={classes.profile_usage}
            >
              {type == "profile" && strings.add_image.titles.profile_usage}
            </Text>
          </div>
          <Row className={classes.centre}>
            <Text
              size={15}
              color={color.secondary_palette.grays.choose_cover}
              family="avenir_light"
              className={classes.choose_bg_txt}
            >
              {strings.add_image.titles.chosse_bg}
            </Text>
            <span
              className={
                colorValue === "#000000"
                  ? classes.choose_black_active
                  : classes.choose_black
              }
              onClick={changeBgColor("#000000")}
            ></span>
            <span
              className={
                colorValue === "#d8d8d8"
                  ? classes.choose_gray_active
                  : classes.choose_gray
              }
              onClick={changeBgColor("#d8d8d8")}
            ></span>
            <span
              className={
                colorValue === transparentColor
                  ? classes.choose_white_active
                  : classes.choose_white
              }
              onClick={changeBgColor(transparentColor)}
            >
              <img
                src="/assets/images/Transparency Tile.svg"
                alt=""
                className={classes.checkeredAlign}
              />
            </span>
          </Row>
          <Row className={classes.btn_spacing}>
            <Row className={classes.justifyLeft}>
              <CustomButton
                className={classes.choose_btn}
                onClick={openImageExp(type, true)}
              >
                {strings.add_image.titles.chooseimg}
              </CustomButton>
            </Row>
            <Row className={classes.justifyRight}>
              <CustomButton
                className={classes.choose_btn}
                onClick={showCroppedImage}
              >
                {strings.add_image.titles.save_continue}
              </CustomButton>
            </Row>
          </Row>
        </div>
      </div>
    </>
  );
}

export default chooseStyle(ChoosePicture);
