import React from "react";
import { makeStyles } from "@material-ui/core";

import ChevronDownIcon from "../data_display/icons/Arrow_Chevron_Down";

import { Row } from "../common/ui_kit";
import tradework_theme, { pxToRem } from "../../utilities/themes";

const useStyles = makeStyles({
  accordion_title: {
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    fontSize: pxToRem(16),
    ...tradework_theme.typography.styles.avenir_sb,
    width: "90%",
  },
  accordion_item: {
    overflow: "hidden",
    transition: "max-height 0.3s cubic-bezier(1, 0, 1, 0)",
    height: "auto",
    maxHeight: "9999px",
  },
  collapsed: {
    maxHeight: 0,
    transition: "max-height 0.35s cubic-bezier(0, 1, 0, 1)",
  },
  accordion_content: {},
  accordion_wrapper: {
    position: "relative",
  },
  accordion_wrapper_spacing: {
    position: "relative",
  },
  border_wrapper: {
    border: "solid 1px #979797",
    borderRadius: "4px",
  },
  upIcon: {
    // position: "absolute",
    top: pxToRem(8),
    right: pxToRem(8),
    cursor: "pointer",
    textAlign: "right",
    paddingRight: pxToRem(10),
    width: "10%",
    alignSelf: "center"
  },
  mainlogo: {
    paddingRight: pxToRem(25),
    width: pxToRem(32),
    height: pxToRem(20),
  },
  IconUp: {
    fontSize: pxToRem(10),
    transform: "rotateX(180deg)",
  },
  IconDown: {
    fontSize: pxToRem(10),
  },
});

const CustomAccordion = ({
  title,
  children,
  accordionWrapper,
  cardLogo,
  defaultLogo,
  className,
  upIconDisplay,
}) => {
  const classes = useStyles();
  const [isOpen, setOpen] = React.useState(false);
  return (
    <div
      className={`${accordionWrapper} ${className} ${
        isOpen ? classes.accordion_wrapper : classes.accordion_wrapper_spacing
      } ${isOpen && classes.border_wrapper}`}
    >
      <Row>
        <div
          className={`${classes.accordion_title} ${classes.titleStyles} ${
            isOpen ? classes.open : ""
          }`}
          onClick={() => setOpen(!isOpen)}
        >
          {defaultLogo && cardLogo && !isOpen && (
            <img src={cardLogo} alt="logo" className={classes.mainlogo} />
          )}
          {!isOpen && title}
        </div>
        <div
          className={`${classes.upIcon} ${isOpen && `${upIconDisplay}`}`}
          onClick={() => setOpen(!isOpen)}
        >
          <ChevronDownIcon
            className={isOpen ? classes.IconUp : classes.IconDown}
          />
        </div>
      </Row>
      <div
        className={`${classes.accordion_item} ${
          !isOpen ? classes.collapsed : ""
        }`}
      >
        <div
          className={`${classes.accordion_content}`}
          onClick={() => setOpen(!isOpen)}
        >
          {children}
        </div>
      </div>
    </div>
  );
};
export default CustomAccordion;
