import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";
import { makeStyles, withStyles } from "@material-ui/core/styles";

import { color } from "../../../utilities/themes";

const useStyles = makeStyles((theme) => ({
  root: {
    "& :hover": {},
  },
}));

const getColor = (propsVal, line_type) => {
  let colorValue = propsVal.color
    ? propsVal.color
    : color.secondary_palette.grays.light_gray;

  if (propsVal && propsVal.color === "primary") {
    if (line_type === "stroke") {
      colorValue = color.primary_palette.franklin_purple;
    }
    if (line_type === "fill") {
      colorValue = color.primary_palette.franklin_purple;
    }
  }
  return colorValue;
};

function Close_Icon(props) {
  const classes = useStyles();
  function styledIcon(styleProps) {
    return (
      <span className={classes.root}>
        <SvgIcon {...styleProps} viewBox="0 0 15 15">
          <g
            fill="none"
            fillRule="evenodd"
            strokeLinecap="round"
            stroke={getColor(styleProps, "stroke")}
          >
            <path d="M14.531.469L.241 14.759M.469.469l14.29 14.29" />
          </g>
        </SvgIcon>
      </span>
    );
  }

  /**
   * Custom styling the colors
   */
  const Custom_Close_Icon = withStyles({
    root: {
      "& > svg": {
        // fontSize: '1.2rem',
      },
      "& :hover": {},
    },
  })(styledIcon);
  return (
    <>
      <Custom_Close_Icon {...props} />
    </>
  );
}

export default Close_Icon;
