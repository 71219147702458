import React, { useEffect } from "react";
import { get, startsWith } from "lodash";
import MobileBusinessCard from "./MobileBusiness";
import MobilePorfolioStyles from "./Styles/MobileportfioloStyles";
import MobileFlagShip from "./MobileFlagShip";
import MobileCompanyDes from "./MobileCompanyDes";
import MobileProductsPublications from "./Mobile_products_publications";
import { Text } from "../common/ui_kit";
import strings from "../../utilities/strings";
import { color } from "../../utilities/themes";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { ProfileActions } from "../../redux/actions";
import { FACEBOOK_URL, INSTAGRAM_URL, TWITTER_URL } from "../../constants";
import MobileOptionsFooter from "./MobileOptionsFooter";

function MobilePorfolio(props) {
  const { classes } = props;
  let { slug, market, vendorId } = useParams();
  const dispatch = useDispatch();

  const portfolioData = useSelector((resData) => resData.Profile.portfolioData);

  //API
  useEffect(() => {
    if (slug) {
      localStorage.setItem("viewType", "public");
      localStorage.setItem("portfolioSlug", slug);
      localStorage.setItem("vendorId", vendorId);
      dispatch(
        ProfileActions.getPortfolioDataBySlug(market, slug, (res) => {
          localStorage.setItem("portfolioId", res.data.portfolio._id);
        })
      );
    } else {
      dispatch(
        ProfileActions.getPortfolioData(localStorage.getItem("portfolioId"))
      );
    }
  }, []);

  const redirectToSocial = (name, link) => () => {
    if (link) {
      if (!startsWith(link, "http://") || !startsWith(link, "https://")) {
        window.open(
          name === "twitter"
            ? TWITTER_URL + link
            : name === "facebook"
            ? FACEBOOK_URL + link
            : INSTAGRAM_URL + link,
          "_blank" // <- This is what makes it open in a new window.
        );
      } else {
        window.open(
          link,
          "_blank" // <- This is what makes it open in a new window.
        );
      }
    }
  };

  const returnFlagShipDataPublic = () => {
    const flagshipsData =
      portfolioData && portfolioData.projects
        ? [...portfolioData.projects]
        : [];
    return flagshipsData.slice(0, 6);
  };

  const flagshipProjectDataPublic = returnFlagShipDataPublic();

  return (
    <div className={classes.mainBlock}>
      <div className={classes.headerAlign}>
        <img src="/assets/images/fr_header.PNG" />
      </div>
      <div className={classes.relative}>
        <MobileBusinessCard
          businessCardData={get(
            portfolioData,
            "displayedAt[0].businessCardData",
            ""
          )}
          portfolioData={portfolioData}
        />
      </div>
      <div>
        <Text
          size={20}
          color={color.primary_palette.black}
          family="gillsans_light"
          transform="uppercase"
          className={`${classes.flagSpacing}`}
        >
          {strings.portfolio_userView.titles.flagship_project}
        </Text>
        {flagshipProjectDataPublic.map((project, index) => {
          const align = (index + 1) % 2 === 0 ? "label_left" : "label_right";
          return <MobileFlagShip align={align} project={project} idx={index} />;
        })}
      </div>
      <div>
        <MobileCompanyDes portfolioData={portfolioData} />
      </div>
      <div className={classes.ppBackgroundStyle}>
        <Text
          size={20}
          color={color.primary_palette.black}
          family="gillsans_light"
          transform="uppercase"
          className={`${classes.flagSpacing} ${classes.txtCenter}`}
        >
          {strings.portfolio_userView.titles.products}
        </Text>
        <MobileProductsPublications portfolioData={portfolioData} />
      </div>
      <div className={classes.txtCenter}>
        <Text
          size={20}
          color={color.primary_palette.black}
          family="gillsans_light"
          transform="uppercase"
          className={`${classes.flagSpacing} ${classes.txtCenter}`}
        >
          {strings.portfolio_userView.titles.connect_dot}
        </Text>
        <div className={classes.socialAlign}>
          <div>
            {get(portfolioData, "socialMediaConnect.instagram", "") && (
              <img
                src="/assets/icons/Instagram_colored.svg"
                className={`${classes.socialImageStyle} ${classes.cursor}`}
                onClick={redirectToSocial(
                  "instagram",
                  get(portfolioData, "socialMediaConnect.instagram", "")
                )}
                alt=""
              />
            )}
            {get(portfolioData, "socialMediaConnect.twitter", "") && (
              <img
                src="/assets/icons/twitter_colored.svg"
                className={`${classes.socialImageStyle} ${classes.cursor}`}
                onClick={redirectToSocial(
                  "twitter",
                  get(portfolioData, "socialMediaConnect.twitter", "")
                )}
                alt=""
              />
            )}

            {get(portfolioData, "socialMediaConnect.facebook", "") && (
              <img
                src="/assets/icons/Facebook_colored.svg"
                className={`${classes.socialImageStyle} ${classes.cursor}`}
                onClick={redirectToSocial(
                  "facebook",
                  get(portfolioData, "socialMediaConnect.facebook", "")
                )}
                alt=""
              />
            )}
          </div>
        </div>
      </div>
      <div>
        <MobileOptionsFooter />
      </div>
      <div className={classes.footerAlign}>
        <img src="/assets/images/fr_footer.PNG" />
      </div>
    </div>
  );
}

export default MobilePorfolioStyles(MobilePorfolio);
