import React from "react";

import Modal from "@material-ui/core/Modal";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  custom_modal: {
    background: "rgba(159, 159, 159, 0.6)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    outline: "none",
  }
});

function CustomModal({ className, children, ...props }) {
  const classes = useStyles();
  return (
    <>
      <Modal
        className={`${classes.custom_modal} ${className}`}
        {...props}
        disableBackdropClick
      >
        {children}
      </Modal>
      
    </>
  );
}

export default CustomModal;
