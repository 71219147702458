import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { get, isEmpty } from "lodash";

import publicStyles from "./styles/publicNavStyles";
import { Row, Text } from "../common/ui_kit";
import CustomLink from "../navigations/custom_links";

import PublicViewProjects from "../add_new_project/publicView_project";
import PublicView from "../product_publications/pro_pub_PublicView";
import { ApiActionTypes } from "../../redux/actionTypes";
import PorfolioUserView from "../build_portfolio/portfolio_UserView";
import ProjectSingleView from "../add_new_project/individual_project_view";
import { ProfileActions } from "../../redux/actions";
import { FRANKLIN_QA, FRANKLIN_STAGE, FRANKLIN_PROD } from "../../constants";
import CustomButton from "../navigations/custom_buttons";
import { useHistory } from "react-router";
import { color } from "../../utilities/themes";
import Location from "../data_display/icons/location";

function PublicNav(props) {
  const { classes, isProjectView, onTabChange, hasSlug, vendorId, frReview } =
    props;
  const [state, setState] = useState({
    clickedType: isProjectView ? "" : "OVERVIEW",
    companyInfo: "",
  });

  const { clickedType } = state;
  const dispatch = useDispatch();
  const history = useHistory();

  const setType = (type) => () => {
    onTabChange && onTabChange(type);
    if (type === "FRANKLIN REPORT CARD") {
      let host = get(window, "location.host", "");
      let frLoginHost = "";
      if (host) {
        if (host.includes("localhost")) {
          frLoginHost = FRANKLIN_QA;
        } else if (host.includes("-dev-")) {
          frLoginHost = FRANKLIN_QA;
        } else if (host.includes("-qa-")) {
          frLoginHost = FRANKLIN_QA;
        } else if (host.includes("-stage-")) {
          frLoginHost = FRANKLIN_STAGE;
        } else if (host.includes("franklinreport")) {
          frLoginHost = FRANKLIN_PROD;
        }
      }
      return (window.location.href = `${frLoginHost}/ReportCard.aspx?v=${vendorId}`);
    }
    setState({
      ...state,
      clickedType: type,
    });
  };

  const redirectToPortfolioPage = () => {
    history.push("/portfolioView");
  };

  const portfolioData = useSelector((resData) => resData.Profile.portfolioData);

  const publicNav = [
    "OVERVIEW",
    "ALL PROJECTS",
    "PRODUCTS & PUBLICATIONS",
    "FRANKLIN REPORT CARD",
  ];

  const navigateToComponent = (val) => {
    switch (val) {
      case "OVERVIEW":
        return <PorfolioUserView disableEdit={true} isPublic={true} />;
      case "ALL PROJECTS":
        return <PublicViewProjects />;
      case "PRODUCTS & PUBLICATIONS":
        return <PublicView noMargin={true} />;
      case "INDIVIDUAL PROJECT":
        return <ProjectSingleView />;
      default:
        return "";
    }
  };

  const displayLabel = (label) => {
    if (
      label === "PRODUCTS & PUBLICATIONS" &&
      (isEmpty(portfolioData) ||
        get(portfolioData, "productsOrPublications.length") === 0)
    ) {
      return false;
    }
    return true;
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    if (!hasSlug) {
      dispatch(
        ProfileActions.getPortfolioData(localStorage.getItem("portfolioId"))
      );
    } else {
      dispatch({ type: ApiActionTypes.HORSEREQUEST });
    }
  };

  const openMarketModal = () => {
    history.push("/portfolioView");
    localStorage.setItem("openMarketModal", true);
  };

  return (
    <>
      <Row className={classes.border_bottom}>
        <Row className={classes.width_20}>
          <Text size={20} className={classes.miller}>
            {get(
              portfolioData,
              "companyName",
              localStorage.getItem("companyName")
            )}
          </Text>
        </Row>
        <Row>
          {publicNav.map(
            (label, index) =>
              displayLabel(label) && (
                <CustomLink key={label} className={classes.nav_spacings}>
                  <label
                    className={
                      label === clickedType
                        ? `${classes.link_text} ${classes.activeLink}`
                        : `${classes.link_text}`
                    }
                    onClick={setType(label)}
                  >
                    {label === "FRANKLIN REPORT CARD" && !vendorId ? "" : label}
                  </label>
                </CustomLink>
              )
          )}
        </Row>
      </Row>
      {/* {!get(props, "location.state.isPublicMode", true) && ( */}
      {isProjectView &&
        clickedType &&
        localStorage.getItem("isPublicMode") === "false" && (
          <>
            <Row className={classes.publicIconAlign}>
              <Text
                size={14}
                color={color.primary_palette.black}
                family="avenir_sb"
                className={classes.publicTxtAlign}
              >
                {" "}
                Your are in Portfolio:{" "}
                <span>{get(portfolioData, "name", "")}</span>. To make public,
                click here{" "}
              </Text>
              <Location
                className={`${classes.markerAlign} hover_icon`}
                onClick={openMarketModal}
              />
            </Row>
            <CustomButton
              className={classes.preview_btn}
              onMouseUp={redirectToPortfolioPage}
            >
              Back to Main Porfolio Editing
            </CustomButton>
          </>
        )}
      {/* )} */}
      <Row className={classes.width100}>{navigateToComponent(clickedType)}</Row>
    </>
  );
}

export default publicStyles(PublicNav);
