import React, { useRef, useState, useEffect } from "react";
import { get } from "lodash";

import CustomModal from "../inputs/custom_modal";
import AlterImageCropper from "../add_new_project/alter_image_cropper";
import UploadImgStyles from "./styles/UploadImgStyles";
import AddImageHover from "../data_display/icons/add_default_hover";
import DeleteModal from "../add_new_project/delete_projectModal";

import { MAX_UPLOAD_SIZE } from "../../constants";
import Element_Required_Icon from "../data_display/icons/ElementRequiered";

function UploadImageColor(props) {
  const uploadRef = useRef(null);
  const {
    classes,
    hasImageData,
    onClear,
    noRecrop,
    originalImg,
    transparentColor,
  } = props;

  const [uploadedImage, setUploadedImage] = useState({ togglecropper: false });
  const [state, setState] = useState({
    openDeleteModal: false,
  });
  const [errors, setErrors] = useState({});

  useEffect(() => {
    const { croppedImage } = props;
    if (croppedImage) {
      setUploadedImage({ ...uploadedImage, image: croppedImage });
    } else {
      setUploadedImage({ ...uploadedImage, image: "" });
    }
  }, [props.croppedImage]);

  const imageUpload = (e) => {
    e.preventDefault();
    let file = e.target.files[0];
    if (!file) {
      return;
    }
    uploadRef.current.value = "";
    if (!file.type.includes("image")) {
      setErrors({
        ...errors,
        image: "Upload JPG or PNG, Max 5 MB",
      });
      return;
    }
    if (file.size > MAX_UPLOAD_SIZE) {
      setErrors({
        ...errors,
        image: "Upload JPG or PNG, Max 5 MB",
      });
      return;
    }
    setUploadedImage({
      ...uploadedImage,
      initialImage: URL.createObjectURL(file),
      imageFile: file,
      togglecropper: true,
    });
    setErrors({
      ...errors,
      image: "",
    });
  };

  const togglCropModal = () => {
    setUploadedImage({
      ...uploadedImage,
      togglecropper: !uploadedImage.togglecropper,
    });
  };

  const handleButtonClick = (openUpload) => {
    if (get(uploadedImage, "image", "") && openUpload !== true && !noRecrop) {
      recropImage();
      return;
    }
    uploadRef.current.click();
  };

  const openFileUpload = () => {
    uploadRef.current.click();
  };

  const handleDeleteImage = () => {
    onClear && onClear(props.id, "projectImagesData")();
    toggleDeleteModal();
  };

  const setCroppedImage = (url, data) => {
    const { blobUrl, croppedImage } = data;
    setUploadedImage({
      ...uploadedImage,
      image: url,
      imageFile: blobUrl,
      togglecropper: false,
    });
    props.imageUpload &&
      props.imageUpload(
        {
          imageFile: blobUrl,
          imageUrl: croppedImage,
          id: props.id,
          originalImageUrl: uploadedImage.initialImage,
          originalImageFile: uploadedImage.imageFile,
        },
        props.name
      );
  };

  const recropImage = () => {
    setUploadedImage({
      ...uploadedImage,
      initialImage: originalImg,
      togglecropper: !uploadedImage.togglecropper,
    });
  };

  const toggleDeleteModal = () => {
    setState({
      ...state,
      openDeleteModal: !openDeleteModal,
    });
  };

  const { togglecropper } = uploadedImage;
  const { openDeleteModal } = state;
  return (
    <div>
      <div
        className={props.containerStyles || classes.containerStyles}
        onClick={handleButtonClick}
      >
        <Element_Required_Icon
          className={classes.required_icon}
          style={{ top: props.requiredIconStyle }}
        />
        {uploadedImage.image && (
          <img
            className={`${props.plus_bgcolor || classes.plus_bgcolor} ${
              props.color_div
            }`}
            key={uploadedImage.image}
            src={uploadedImage.image}
            style={{ border: "none" }}
            alt=""
          />
        )}

        {!uploadedImage.image && (
          <div
            className={`${props.plus_bgcolor || classes.plus_bgcolor} ${
              props.color_div || classes.color_div
            }`}
          >
            {/* <Element_Required_Icon className={classes.required_icon} /> */}
            <AddImageHover
              className={`${props.iconStyle} ${
                props.addImageStyle || classes.addImageStyle
              }`}
            />
          </div>
        )}
      </div>
      {hasImageData && (
        <div className={classes.icons_blk}>
          <img
            src="/assets/icons/Crop Icon Black 02@1x.svg"
            alt=""
            className={classes.action_icons}
            onClick={get(uploadedImage, "image", "") && recropImage}
          />
          <img
            src="/assets/icons/Swap Icon Black 02.svg"
            alt=""
            className={classes.action_icons}
            onClick={openFileUpload}
          />
          <img
            src="/assets/icons/Trashcan Icon Black 02.svg"
            className={classes.action_icons}
            onClick={get(uploadedImage, "image", "") && toggleDeleteModal}
            alt=""
          />
        </div>
      )}
      <input
        id="file"
        ref={uploadRef}
        onChange={imageUpload}
        type="file"
        accept="image/*"
        style={{ display: "none" }}
      />
      {get(errors, "image") && (
        <div className={classes.error}>
          <img
            src="/assets/icons/info_1.svg"
            class="info_img"
            alt=""
            style={{ verticalAlign: "middle", marginRight: "4px" }}
          />
          {get(errors, "image")}
        </div>
      )}
      <CustomModal open={togglecropper}>
        <AlterImageCropper
          image={get(uploadedImage, "initialImage", "")}
          alterCroppedImage={setCroppedImage}
          cropSize={props.cropSize}
          toggleImageExp={handleButtonClick}
          onClose={togglCropModal}
          type="projectImage"
          transparentColor={transparentColor}
          errors={errors}
        />
      </CustomModal>
      <CustomModal open={openDeleteModal} onClose={toggleDeleteModal}>
        <DeleteModal
          onClose={toggleDeleteModal}
          deleteProject={handleDeleteImage}
          imagesData={uploadedImage.image}
        />
      </CustomModal>
    </div>
  );
}

export default UploadImgStyles(UploadImageColor);
