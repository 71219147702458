import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import strings from "../../utilities/strings";
import { Text } from "../common/ui_kit";
import { color } from "../../utilities/themes";
import { get, map, startsWith } from "lodash";
import MobilePorfolioStyles from "./Styles/MobileportfioloStyles";

import { ProfileActions } from "../../redux/actions";

import MobileOptionsFooter from "./MobileOptionsFooter";

function MobileProducts(props) {
  const { classes } = props;
  const dispatch = useDispatch();

  const portfolioData = useSelector((resData) => resData.Profile.portfolioData);
  const productsAndPublications = useSelector(
    (resData) => resData.Profile.productsAndPublicationData
  );

  useEffect(() => {
    dispatch(
      ProfileActions.getPortfolioData(localStorage.getItem("portfolioId"))
    );
    dispatch(
      ProfileActions.fetchProjectAndPublicationData(
        localStorage.getItem("portfolioId")
      )
    );
  }, []);

  const getUrl = (url) => {
    if (url) {
      if (startsWith(url, "http://") || startsWith(url, "https://")) {
        return url;
      }
      return `//${url}`;
    }
    return "javascript:void(0)";
  };

  return (
    <>
      <div className={`${classes.paddingTop25} `}>
        <div className={classes.headerAlign}>
          <img src="/assets/images/fr_header.PNG" />
        </div>
        <div className={`${classes.txtCenter} ${classes.paddingTop25}`}>
          <Text
            size={14}
            color={color.primary_palette.black}
            family="TrajanPro3"
          >
            {get(portfolioData, "name", "")}
          </Text>
          <img
            src="/assets/images/Horizontal_DB.jpg"
            className={classes.horizontal_DB}
            alt=""
          />
        </div>
        <Text
          size={20}
          color={color.primary_palette.black}
          family="gillsans_light"
          transform="uppercase"
          className={`${classes.txtCenter}`}
        >
          {strings.portfolio_userView.titles.products_publications}
        </Text>
        <div>
          <div>
            {get(productsAndPublications, "products.length", "") > 0 && (
              <div className={classes.product}>
                <Text
                  size={20}
                  color={color.primary_palette.black}
                  className={classes.productsHeaderStyle}
                  family="gillsans_light"
                  transform="uppercase"
                >
                  {strings.publicView_p.titles.products}
                </Text>
                {get(productsAndPublications, "products", "") &&
                  map(productsAndPublications.products, (product, idx) => {
                    return (
                      <div className={classes.product_spacing}>
                        <div className={classes.width40}>
                          <div className={classes.product_img}>
                            <div>
                              <img
                                alt=""
                                src={product.imageUri}
                                className={classes.img_block}
                              />
                            </div>
                          </div>
                          <div className={classes.titleDivAlign}>
                            <div className={classes.subDivAlign}>
                              <Text
                                size={16}
                                color={color.primary_palette.black}
                                family="avenir_bold"
                                className={classes.product_heading}
                                transform="capitalize"
                              >
                                {product.title}
                              </Text>
                              <Text
                                size={16}
                                color={color.secondary_palette.grays.gary_shade}
                                family="avenir_sb"
                              >
                                {product.subTitle}
                              </Text>
                            </div>
                            <div className={classes.width60}>
                              <div className={classes.product_des}>
                                <Text
                                  size={14}
                                  color={color.primary_palette.black}
                                  family="avenir_light"
                                  className={classes.margin_17}
                                >
                                  {get(product, "description", "")}
                                </Text>
                              </div>
                              {/* <div>
                          {get(product, "tags[0]", "") &&
                            map(product.tags[0].split(","), (tags) => {
                              return (
                                <Text
                                  size={14}
                                  color={color.vicious_oyster}
                                  family="avenir_bold"
                                  className={classes.margin_17}
                                >
                                  {tags}
                                </Text>
                              );
                            })}
                        </div> */}
                            </div>
                            {get(product, "referenceUri") && (
                              <div className={classes.flex_end_block}>
                                <Text
                                  size={14}
                                  color={color.primary_palette.free_purple}
                                  family="avenir_sb"
                                  className={classes.more_info}
                                >
                                  <a
                                    href={getUrl(product.referenceUri)}
                                    target="_blank"
                                    className={classes.more_info}
                                  >
                                    {strings.publicView_p.titles.more_info}
                                  </a>
                                </Text>
                              </div>
                            )}
                          </div>
                        </div>
                        {idx !==
                          get(productsAndPublications, "products.length") -
                            1 && <div className={classes.breakLine}></div>}
                      </div>
                    );
                  })}
              </div>
            )}
          </div>
          <div>
            {get(productsAndPublications, "publications.length", "") > 0 && (
              <div className={classes.product}>
                <Text
                  size={20}
                  color={color.primary_palette.black}
                  className={classes.productsHeaderStyle}
                  family="gillsans_light"
                  transform="uppercase"
                >
                  {strings.publicView_p.titles.publications}
                </Text>
                {get(productsAndPublications, "publications", "") &&
                  map(
                    productsAndPublications.publications,
                    (publication, idx) => {
                      return (
                        <div className={classes.product_spacing}>
                          <div className={classes.width40}>
                            <div className={classes.product_img}>
                              <div>
                                <img
                                  alt=""
                                  src={publication.imageUri}
                                  className={classes.img_block}
                                />
                              </div>
                            </div>
                            <div className={classes.titleDivAlign}>
                              <div className={classes.subDivAlign}>
                                <Text
                                  size={16}
                                  color={color.primary_palette.black}
                                  family="avenir_bold"
                                  className={classes.product_heading}
                                  transform="capitalize"
                                >
                                  {publication.title}
                                </Text>
                                <Text
                                  size={16}
                                  color={
                                    color.secondary_palette.grays.gary_shade
                                  }
                                  family="avenir_sb"
                                >
                                  {publication.subTitle}
                                </Text>
                              </div>
                              <div className={classes.width60}>
                                <div className={classes.product_des}>
                                  <Text
                                    size={14}
                                    color={color.primary_palette.black}
                                    family="avenir_light"
                                    className={classes.margin_17}
                                  >
                                    {get(publication, "description", "")}
                                  </Text>
                                </div>
                                {/* <div>
                          {get(product, "tags[0]", "") &&
                            map(product.tags[0].split(","), (tags) => {
                              return (
                                <Text
                                  size={14}
                                  color={color.vicious_oyster}
                                  family="avenir_bold"
                                  className={classes.margin_17}
                                >
                                  {tags}
                                </Text>
                              );
                            })}
                        </div> */}
                              </div>
                              {get(publication, "referenceUri") && (
                                <div className={classes.flex_end_block}>
                                  <Text
                                    size={14}
                                    color={color.primary_palette.free_purple}
                                    family="avenir_sb"
                                    className={classes.more_info}
                                  >
                                    <a
                                      href={getUrl(publication.referenceUri)}
                                      target="_blank"
                                      className={classes.more_info}
                                    >
                                      {strings.publicView_p.titles.more_info}
                                    </a>
                                  </Text>
                                </div>
                              )}
                            </div>
                          </div>
                          <div className={classes.breakLine}></div>
                        </div>
                      );
                    }
                  )}
              </div>
            )}
          </div>
        </div>
        <div>
          <MobileOptionsFooter />
        </div>
        <div className={classes.footerAlign}>
          <img src="/assets/images/fr_footer.PNG" />
        </div>
      </div>
    </>
  );
}

export default MobilePorfolioStyles(MobileProducts);
