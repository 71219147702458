import { withStyles } from "@material-ui/core/styles";
import tradework_theme, { pxToRem, color } from "../../../utilities/themes";

const styles = withStyles({
  delete_company_width: {
    width: pxToRem(474),
    height: pxToRem(307),
    border: `solid ${pxToRem(2)} ${color.primary_palette.franklin_purple}`,
    backgroundColor: color.secondary_palette.blues.modal_blue,
    padding: pxToRem(16),
    paddingBottom: pxToRem(20),
    position: "relative",
    textAlign: "center",
    "&:focus": {
      outline: "none !important",
    },
  },
  txtRight: {
    textAlign: "right",
  },
  cross_icon: {
    fontSize: pxToRem(15),
    cursor: "pointer",
    "& path": {
      stroke: color.primary_palette.black,
    },
  },
  textCenter: {
    textAlign: "center",
  },
  secure_icon: {
    width: pxToRem(60),
    height: pxToRem(69),
  },
  sureBtn: {
    width: pxToRem(160),
    height: pxToRem(30),
    borderRadius: pxToRem(20),
    border: `solid ${pxToRem(2)} ${color.form_colors.blueberry_purple}`,
    backgroundColor: color.primary_palette.white,
    marginTop: pxToRem(18),
    padding: 0,
    ...tradework_theme.typography.styles.gillsans_sb,
    color: `${color.primary_palette.franklin_purple} !important`,
    fontSize: pxToRem(15),
  },
  okayBtn: {
    width: pxToRem(107),
    height: pxToRem(25),
    borderRadius: pxToRem(20),
    border: `solid ${pxToRem(2)} ${color.form_colors.blueberry_purple}`,
    backgroundColor: color.primary_palette.white,
    marginTop: pxToRem(18),
    padding: 0,
    ...tradework_theme.typography.styles.gillsans_sb,
    color: `${color.primary_palette.franklin_purple} !important`,
    fontSize: pxToRem(15),
  },
  hand_icon: {
    width: pxToRem(55),
    height: pxToRem(80),
  },
  list_icon: {
    width: pxToRem(60),
    height: pxToRem(77),
  },
  deleteTxtAlign: {
    width: pxToRem(370),
    margin: "0 auto",
    marginBottom: pxToRem(11),
    marginTop: pxToRem(24),
  },
  btnAlignChange: {
    marginBottom: pxToRem(4),
  },
  textHeading: {
    width: pxToRem(370),
    margin: "0 auto",
    marginTop: pxToRem(14),
  },
  subTextHeading: {
    marginBottom: pxToRem(24),
  },
  delete_admin_width: {
    width: pxToRem(389),
    height: pxToRem(256),
    border: `solid ${pxToRem(2)} ${color.primary_palette.franklin_purple}`,
    backgroundColor: color.secondary_palette.blues.modal_blue,
    padding: pxToRem(16),
    position: "relative",
    textAlign: "center",
    "&:focus": {
      outline: "none !important",
    },
  },
  styleChangesTxt: {
    width: pxToRem(334),
    margin: "0 auto",
    marginBottom: pxToRem(10),
    marginTop: pxToRem(10),
  },
  letter_icon: {
    width: pxToRem(181),
    margin: "0 auto",
    paddingBottom: pxToRem(26),
  },
  confirm_company_width: {
    width: pxToRem(323),
    height: pxToRem(198),
    border: `solid ${pxToRem(1.5)} ${color.primary_palette.franklin_purple}`,
    backgroundColor: color.secondary_palette.blues.modal_blue,
    padding: pxToRem(16),
    position: "relative",
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "&:focus": {
      outline: "none !important",
    },
  },
  betaIcon: {
    height: pxToRem(150),
  },
  feedback_modal: {
    width: pxToRem(500),
    border: `solid ${pxToRem(2)} ${color.primary_palette.franklin_purple}`,
    backgroundColor: color.secondary_palette.blues.modal_blue,
    padding: pxToRem(16),
    position: "relative",
    textAlign: "center",
    "&:focus": {
      outline: "none !important",
    },
  },
  dispatchBtn: {
    width: pxToRem(120),
    height: pxToRem(25),
    borderRadius: pxToRem(20),
    border: `solid ${pxToRem(2)} ${color.form_colors.blueberry_purple}`,
    backgroundColor: color.primary_palette.white,
    marginTop: pxToRem(18),
    padding: 0,
    ...tradework_theme.typography.styles.gillsans_sb,
    color: `${color.primary_palette.franklin_purple} !important`,
    fontSize: pxToRem(15),
  },
  invite_textField: {
    width: "74%",
  },
  invite_textArea: {
    backgroundColor: color.primary_palette.white,
    height: `${pxToRem(72)} !important`,
    width: "70%",
    resize: "none",
    padding: pxToRem(10),
    ...tradework_theme.typography.styles.NeutraText,
    fontSize: pxToRem(16),
    "&::placeholder": {
      // fontStyle: "oblique !important",
      color: color.secondary_palette.grays.shadow_gray,
      ...tradework_theme.typography.styles.avenir_light,
      opacity: 1,
    },
  },
  labelChangesText: {
    textAlign: "left",
    paddingLeft: pxToRem(64),
  },
  paddingBottom20: {
    paddingBottom: pxToRem(20),
  },
});

export default styles;
