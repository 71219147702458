import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { get, map } from "lodash";
import uuidv4 from "uuid/v4";

import { color } from "../../utilities/themes";
import Row from "../common/ui_kit/row";
import CongratsModalStyles from "./styles/congratsModalStyles";
import Text from "../common/ui_kit/text";
import strings from "../../utilities/strings";
import Close_Icon from "../data_display/icons/Close";
import CustomButton from "../navigations/custom_buttons";
import Add from "../data_display/icons/add_default_hover";
import CustomScrollbars from "../data_display/custom_scroll";
import CustomModal from "../inputs/custom_modal";
import ConfirmInvite from "./confirmModal";
import CustomInputCount from "../inputs/custom_input_count";
import CustomCheckbox from "../inputs/custom_checkbox";

import { ProfileActions } from "../../redux/actions";
import {
  LLAMA_DEV_URL,
  LLAMA_PROD_URL,
  LLAMA_QA_URL,
  LLAMA_STAGE_URL,
} from "../../constants";

function CongratsModal(props) {
  const { classes, onClose } = props;
  const textAreaRef = useRef(null);
  const [copySuccess, setCopySuccess] = useState("");
  const [state, setState] = useState({
    sharedEmails: [
      {
        _id: uuidv4(),
        email: [],
        personalNotes: "",
      },
      {
        _id: uuidv4(),
        email: [],
        personalNotes: "",
      },
      {
        _id: uuidv4(),
        email: [],
        personalNotes: "",
      },
    ],
    openInviteModal: false,
    isSameMessage: false,
    sameMessageText: "",
  });

  const {
    personalNotes,
    email,
    sharedEmails,
    openInviteModal,
    userEmail,
    userData,
    sameMessageText,
    isSameMessage,
    portfolioLink,
  } = state;

  const dispatch = useDispatch();

  const portfolioData = useSelector((resData) => resData.Profile.portfolioData);

  const handleInputChange = (section) => (e) => {
    const { name, value } = e.target;
    if (name === "email") {
      let email = value.split(",");
      section[name] = email;
      return;
    }
    section[name] = value;
    setState({ ...state });
  };

  //API
  useEffect(() => {
    let host = get(window, "location.host", "");
    let redirectUrl = "";
    if (host) {
      if (host.includes("localhost")) {
        redirectUrl = LLAMA_DEV_URL;
      } else if (host.includes("-dev-")) {
        redirectUrl = LLAMA_QA_URL;
      } else if (host.includes("-qa-")) {
        redirectUrl = LLAMA_QA_URL;
      } else if (host.includes("-stage-")) {
        redirectUrl = LLAMA_STAGE_URL;
      } else if (host.includes("franklinreport")) {
        redirectUrl = LLAMA_PROD_URL;
      }
    }
    let userData = localStorage.getItem("profile");
    if (userData !== "undefined") {
      userData = JSON.parse(userData);
    } else {
      userData = {};
    }
    setState({
      ...state,
      userData: userData || {},
      userEmail: userData?.email,
      portfolioLink: `${redirectUrl}/public-portfolio/NYC/${get(
        portfolioData,
        "slug",
        ""
      )}`,
    });
  }, []);

  const copyToClipboard = (e) => {
    textAreaRef.current.select();
    document.execCommand("copy");
    // This is just personal preference.
    // I prefer to not show the whole text area selected.
    e.target.focus();
    setCopySuccess("Copied!");
  };

  const sendInvite = () => {
    const { sharedEmails } = state;
    if (
      get(sharedEmails[0], "personalNotes") === "" &&
      get(sharedEmails[0], "email.length") === 0
    ) {
      return;
    }
    const data = {
      ...state,
      companyName: localStorage.getItem("companyName"),
      name: localStorage.getItem("user_data"),
      portfolioId: localStorage.getItem("portfolioId"),
      portfolioLink: portfolioLink,
    };

    dispatch(
      ProfileActions.sendInviteMails(data, () => {
        setState({ ...state, openInviteModal: true });
        setTimeout(function () {
          setState({ ...state, openInviteModal: false });
          localStorage.removeItem("isPortfolioPublic");
          onClose();
        }, 2000);
      })
    );
  };

  const handleInviteModal = () => {
    setState({ ...state, openInviteModal: !openInviteModal });
  };

  const handleChange = (e) => {
    const { name, value, checked } = e.target;
    if (name === "isSameMessage") {
      setState({ ...state, [name]: checked });
      return;
    }
    setState({ ...state, [name]: value });
  };

  const addNewBlock = () => {
    setState({
      ...state,
      sharedEmails: [
        ...state.sharedEmails,
        {
          _id: uuidv4(),
          email: [],
          personalNotes: "",
        },
        {
          _id: uuidv4(),
          email: [],
          personalNotes: "",
        },
        {
          _id: uuidv4(),
          email: [],
          personalNotes: "",
        },
      ],
    });
  };

  return (
    <>
      <div className={classes.congratsModalStyles}>
        <div className={`${classes.btnRight}`}>
          <CustomButton className={classes.crossBtn} isX>
            <Close_Icon onClick={onClose} />
          </CustomButton>
        </div>
        <div>
          {/* <div className={classes.textCenter}>
            <img alt=""
              // src="assets/images/Invite_Envelope.png"
              src="assets/images/llama_image.png"
              className={classes.invite_Envelope}
            />
          </div> */}
          <Text
            size={23}
            family="avenir_light"
            color={color.primary_palette.black}
            className={`${classes.textCenter} ${classes.headerTxtAlign}`}
          >
            {strings.modal.titles.beautiful_portfolio}
          </Text>
          <div className={classes.boxAlign}>
            <div className={classes.inputsAlignDiv}>
              <Row className={classes.alignBaseline}>
                <Row>
                  <Text
                    size={14}
                    family="avenir_sb"
                    color={color.primary_palette.black}
                  >
                    Your name:
                  </Text>
                </Row>
                <Row>
                  <Text
                    size={14}
                    family="avenir_sb"
                    color={color.primary_palette.black}
                    className={classes.marinLeft5}
                  >
                    {get(userData, "firstName", "")} $
                    {get(userData, "lastName", "")}
                  </Text>
                </Row>
              </Row>
              <Row className={classes.alignBaseline}>
                <Row>
                  <Text
                    size={14}
                    family="primary_palette.black"
                    color={color.primary_palette.black}
                  >
                    Your email:
                  </Text>
                </Row>
                <Row>
                  <Text
                    size={14}
                    family="avenir_sb"
                    color={color.primary_palette.black}
                    className={classes.marinLeft5}
                  >
                    {userEmail}
                  </Text>
                </Row>
              </Row>
              <img
                alt=""
                // src="assets/images/Invite_Envelope.png"
                src="assets/images/The-Pilar-Stamp.png"
                className={classes.invite_Envelope}
              />
            </div>

            <CustomScrollbars
              className={`${classes.scroll_area_profile}`}
              scrollbarTrackStyles={classes.scrollbar_track}
            >
              <div className={classes.form_width}>
                <Text
                  size={13}
                  family="avenir_bold"
                  color={color.primary_palette.black}
                  className={classes.input_field_email}
                >
                  Client’s emails, separated by commas
                </Text>
                <Text
                  size={13}
                  family="avenir_bold"
                  color={color.primary_palette.black}
                  className={classes.input_field_email}
                >
                  Individual Message
                </Text>
              </div>
              {map(sharedEmails, (section) => {
                return (
                  <div className={classes.form_width}>
                    <CustomInputCount
                      className={classes.input_field_email}
                      // placeholder={strings.modal.titles.inputplaceholder}
                      onBlur={handleInputChange(section)}
                      defaultValue={email}
                      name="email"
                    />
                    <CustomInputCount
                      className={classes.input_field_notes}
                      // placeholder={strings.modal.titles.txtAreaPlaceholder}
                      onBlur={handleInputChange(section)}
                      name="personalNotes"
                      defaultValue={personalNotes}
                    />
                  </div>
                );
              })}
              <div className={classes.btnRightAlign}>
                {" "}
                <Add
                  className={`${classes.plusAlign} addHover_effect`}
                  onClick={addNewBlock}
                />
                <Text
                  size={12}
                  color={color.add_more_color}
                  family="avenir_black_r"
                  className={classes.newAdminAlign}
                  onClick={addNewBlock}
                >
                  {strings.settings.titles.add_more}
                </Text>
              </div>
            </CustomScrollbars>
          </div>
          {/* <div className={classes.checkBoxAlign}>
            <CustomCheckbox
              label={strings.settings.titles.same_message}
              className={classes.check_box}
              name="isSameMessage"
              checked={isSameMessage}
              onChange={handleChange}
            />
          </div> */}

          {/* <div>
            <CustomInputCount
              className={classes.input_field_notes_message}
              // placeholder={strings.modal.titles.txtAreaPlaceholder}
              onBlur={handleChange}
              name="sameMessageText"
              defaultValue={sameMessageText}
            />
          </div> */}
          <div>
            <div className={classes.copyBlock}>
              <Text
                size={14}
                family="avenir_bold"
                color={color.primary_palette.black}
                className={classes.displayInline}
              >
                Your Portfolio Link
              </Text>
              <input
                ref={textAreaRef}
                name="portfolioLink"
                defaultValue={portfolioLink}
                className={classes.messageBoxStyle}
              />
              <Text
                size={12}
                family="avenir_sb"
                color={color.primary_palette.franklin_purple}
                className={classes.copyAlign}
                onClick={copyToClipboard}
              >
                COPY
              </Text>
              {/* <button onClick={copyToClipboard} className={classes.clipBoardBtn}>
              COPY
            </button> */}
              {copySuccess && (
                <img
                  alt=""
                  src="assets/icons/Green Checkmark.svg"
                  // className={classes.invite_Envelope}
                />
              )}
            </div>
            <CustomButton className={classes.invitesBtn} onClick={sendInvite}>
              {strings.modal.titles.send_invites}
            </CustomButton>
          </div>
        </div>
      </div>
      <CustomModal open={openInviteModal} onClose={handleInviteModal}>
        <ConfirmInvite onClose={handleInviteModal} />
      </CustomModal>
    </>
  );
}

export default CongratsModalStyles(CongratsModal);
