import React from "react";
import { get } from "lodash";
import SettingNav from "./setting_nav";
import { useHistory } from "react-router-dom";

function CompanyRedirect(props) {
  const history = useHistory();
  localStorage.setItem("companyId", get(props.match.params, "companyId", ""));
  localStorage.setItem(
    "companyName",
    get(props.match.params, "companyName", "")
  );
  history.push("/settings/company");
  return <SettingNav />;
}

export default CompanyRedirect;
