import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";
import { makeStyles, withStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    "& :hover": {},
  },
}));

function AddProduct(props) {
  const classes = useStyles(props);
  function styledIcon(styledProps) {
    return (
      <span className={classes.root}>
        <SvgIcon width={78} height={80} viewBox="0 0 78 80" {...styledProps}>
          <title>ADD</title>
          <g fill="none" fillRule="evenodd">
            <ellipse fill="#D8D8D8" cx={39} cy={40} rx={39} ry={40} />
            <path fill="#FFF" d="M35.226 17.204h8.387v46.452h-8.387z" />
            <path fill="#FFF" d="M62.065 36.129v8.602h-45.29V36.13z" />
          </g>
        </SvgIcon>
      </span>
    );
  }

  /**
   * Custom styling the colors
   */
  const CustomAddProduct = withStyles({
    root: {
      "& > svg": {
        fontSize: "1.65rem",
      },
      "& :hover": {},
    },
  })(styledIcon);
  return (
    <>
      <CustomAddProduct {...props} />
    </>
  );
}

export default AddProduct;
