import React from "react";

import DeleteCompanyStyles from "./styles/deleteCompanyStyles";
import Text from "../common/ui_kit/text";
import strings from "../../utilities/strings";
import { color } from "../../utilities/themes";

function ConfirmInvite(props) {
  const { classes } = props;

  return (
    <>
      <div className={classes.confirm_company_width}>
        <div>
          <img
            alt=""
            src="/assets/images/Bye Baby Icon.png"
            className={classes.letter_icon}
          />

          <Text
            size={20}
            family="avenir_sb"
            color={color.primary_palette.franklin_purple}
            className={classes.textCenter}
          >
            {strings.modal.titles.email_success}
          </Text>
        </div>
      </div>
    </>
  );
}

export default DeleteCompanyStyles(ConfirmInvite);
