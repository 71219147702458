import React from "react";

import { color } from "../../utilities/themes";
import DeleteProjetStyles from "./styles/delete_projectStyles";
import Text from "../common/ui_kit/text";
import strings from "../../utilities/strings";
import Close_Icon from "../data_display/icons/Close";
import CustomButton from "../navigations/custom_buttons";

function DeleteModal(props) {
  const { classes, onClose, deleteProject } = props;

  return (
    <>
      <div className={classes.product_cover}>
        <div className={classes.textRight}>
          {/* <CustomButton className={classes.cross_btn}> */}
          <Close_Icon
            className={classes.cross_mark_port}
            onClick={onClose}
            isX
          />
          {/* </CustomButton> */}
        </div>
        <div className={classes.product_cover_inner}>
          <img
            src="/assets/images/Delete Portfolio.png"
            className={classes.image_block}
            alt=""
          />
        </div>
        <Text
          size={25}
          color={color.greyish_brown}
          className={`${classes.textCenter} ${classes.padding_sides}`}
        >
          {strings.add_project.titles.confirmation_portfolio}
        </Text>
        <Text
          size={18}
          color={color.primary_palette.black}
          family="gillsans_light"
          className={classes.textCenter}
        >
          {strings.add_project.titles.undo}
        </Text>
        <div className={classes.textCenter}>
          <CustomButton className={classes.deleteBtn} onClick={deleteProject}>
            {strings.add_project.titles.yes_in}
          </CustomButton>
        </div>
      </div>
    </>
  );
}

export default DeleteProjetStyles(DeleteModal);
