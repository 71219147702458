import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { get, map } from "lodash";
import { useHistory } from "react-router-dom";

import individualProjectStyles from "./styles/individualProjectStyles";
import Row from "../common/ui_kit/row";
import Text from "../common/ui_kit/text";
import strings from "../../utilities/strings";
import { color } from "../../utilities/themes";
import GridTwo from "../grids/grid_2";
import GridThree from "../grids/grid_3";
import GridFour from "../grids/grid_4";
import GridFive from "../grids/grid_5";
import GridSix from "../grids/grid_6";
import Gridseven from "../grids/grid_7";
import GridEight from "../grids/grid_8";
import GridNine from "../grids/grid_9";
import GridTen from "../grids/grid_10";
import GridEleven from "../grids/grid_11";
import GridTwelve from "../grids/grid_12";
import GridOne from "../grids/gird_1";
import CustomButton from "../navigations/custom_buttons";

import { ProfileActions, SettingsActions } from "../../redux/actions";
import PublicNav from "../../components/publicNav/publicNav";
import Location from "../data_display/icons/location";
import Feedback from "../modals/feedbackModal";
import CustomModal from "../inputs/custom_modal";

function ProjectSingleView(props) {
  const { classes } = props;

  const [state, setState] = useState({
    projectImages: [],
    projectImagesWithoutCover: [],
    selectedTab: "",
    toggleFeedbackModal: false,
  });

  const {
    descriptionTxt,
    selectedTab,
    projectImagesWithoutCover,
    projectImages,
    toggleFeedbackModal,
  } = state;

  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    const projectImages = get(props.location.state, "projectImages", []);
    const coverImageUri = get(props.location.state, "actualCoverImageUri", {});
    const projectImagesWithCover = [
      { actualImageUri: coverImageUri },
      ...projectImages,
    ];
    const projectImagesWithoutCoverVal = [...projectImages];
    const descriptionTxtVal = splitIntoLines(
      get(props.location.state, "description", ""),
      get(props.location.state, "description.length", "") / 2
    );
    setState({
      ...state,
      projectImages: [...projectImagesWithCover],
      projectImagesWithoutCover: projectImagesWithoutCoverVal,
      title: get(props.location.state, "title", ""),
      clientele: get(props.location.state, "venue", []),
      settingDisplay: get(props.location.state, "setting", []),
      ballParkCost: get(props.location.state, "ballparkCost", []),
      tagsList: get(props.location.state, "tags", []),
      styleDisplay: get(props.location.state, "style", []),
      index: get(props.location.state, "idx", ""),
      isNonEdit: get(props.location.state, "isPublicMode", true),
      isProj: get(props.location.state, "val", ""),
      description: get(props.location.state, "description", ""),
      descriptionTxt: descriptionTxtVal,
    });
  }, []);

  const renderSlides = () => {
    const { projectImages } = state;
    return projectImages.map((image) => (
      <div className={classes.slider_width} key={projectImages}>
        <img
          src={
            get(image, "actualImageUri", "/assets/images/gray.PNG") ||
            "/assets/images/gray.PNG"
          }
          className={classes.imageWidth}
          alt=""
        />
        <Text
          size={14}
          color={color.primary_palette.black}
          family="avenir_light"
          className={classes.text_center}
        >
          {get(image, "projectImgDescription", "")}
        </Text>
      </div>
    ));
  };

  const handleFeedbackModal = () => {
    setState({
      ...state,
      toggleFeedbackModal: !toggleFeedbackModal,
    });
  };

  const splitIntoLines = (input, len) => {
    var i;
    var output = [];
    var lineSoFar = "";
    var temp;
    var words = input.split(" ");
    for (i = 0; i < words.length; ) {
      // check if adding this word would exceed the len
      temp = addWordOntoLine(lineSoFar, words[i]);
      if (temp.length > len) {
        if (lineSoFar.length == 0) {
          lineSoFar = temp; // force to put at least one word in each line
          i++; // skip past this word now
        }
        output.push(lineSoFar); // put line into output
        lineSoFar = ""; // init back to empty
      } else {
        lineSoFar = temp; // take the new word
        i++; // skip past this word now
      }
    }
    if (lineSoFar.length > 0) {
      output.push(lineSoFar);
    }
    return output;
  };

  const addWordOntoLine = (line, word) => {
    if (line.length != 0) {
      line += " ";
    }
    return (line += word);
  };

  const allProjectsData = useSelector(
    (resData) => resData.Profile.projectsByPortfolio
  );
  const portfolioData = useSelector((resData) => resData.Profile.portfolioData);

  const editProjectData = (projectId) => () => {
    dispatch(
      ProfileActions.getIndividualProjectData(projectId, () => {
        history.push({
          pathname: "/AddProjects",
          state: { edit: true },
        });
      })
    );
  };

  const redirectToPortfolio = () => {
    history.push("/portfolioView");
  };

  const redirectToOverview = () => {
    history.goBack();
  };

  //API
  useEffect(() => {
    window.scrollTo(0, 0);
    fetchData();
  }, []);

  const fetchData = () => {
    dispatch(
      ProfileActions.getProjectsByPortfolio(localStorage.getItem("portfolioId"))
    );
  };

  const projectInfoData = (data) => {
    switch (data.length) {
      case 1:
        return <GridOne imageData={data} imageSize={true} />;
      case 2:
        return <GridTwo imageData={data} imageSize={true} />;
      case 3:
        return <GridThree imageData={data} imageSize={true} />;
      case 4:
        return <GridFour imageData={data} imageSize={true} />;
      case 5:
        return <GridFive imageData={data} imageSize={true} />;
      case 6:
        return <GridSix imageData={data} imageSize={true} />;
      case 7:
        return <Gridseven imageData={data} imageSize={true} />;
      case 8:
        return <GridEight imageData={data} imageSize={true} />;
      case 9:
        return <GridNine imageData={data} imageSize={true} />;
      case 10:
        return <GridTen imageData={data} imageSize={true} />;
      case 11:
        return <GridEleven imageData={data} imageSize={true} />;
      case 12:
        return <GridTwelve imageData={data} imageSize={true} />;
    }
  };

  const handleSelectTab = (name) => {
    setState({
      ...state,
      selectedTab: name,
    });
  };

  const previewEntirePort = () => {
    history.push("/publicPortfolioView");
  };

  const redirectTo = (type) => () => {
    const { index } = state;
    if (!allProjectsData) {
      return;
    }
    const { projects } = allProjectsData;
    if (projects) {
      if (
        (type === "previous" && index === 0) ||
        (type === "next" && index === projects.length - 1)
      ) {
        return null;
      }
      const data = type === "next" ? projects[index + 1] : projects[index - 1];
      const descriptionTxtVal = splitIntoLines(
        get(data, "description", ""),
        get(data, "description.length", "") / 2
      );
      const projectImages = get(data, "projectImages", []);
      const coverImageUri = get(data, "actualCoverImageUri", {});
      const projectImagesWithCover = [
        { actualImageUri: coverImageUri },
        ...projectImages,
      ];
      setState({
        ...state,
        projectImagesWithoutCover: data.projectImages.map((each) => {
          return { ...each };
        }),
        projectImages: [...projectImagesWithCover],
        title: get(data, "title", ""),
        clientele: get(data, "venue", []),
        styleDisplay: get(data, "style", []),
        settingDisplay: get(data, "setting", []),
        ballParkCost: get(data, "ballparkCost", []),
        description: get(data, "description", ""),
        tagsList: get(data, "tags", []),
        descriptionTxt: descriptionTxtVal,
        index: type === "next" ? index + 1 : index - 1,
      });
    }
  };

  const openMarketModal = () => {
    dispatch(SettingsActions.isOpenMarketModal(true));
    history.push("/portfolioView");
    localStorage.setItem("openMarketModal", true);
  };

  return (
    <>
      <div className={classes.margin_top_116}>
        {localStorage.getItem("isPublicMode") !== "true" ? (
          <PublicNav isProjectView={true} onTabChange={handleSelectTab} />
        ) : (
          <CustomButton
            className={`${classes.portfolio_overView_btn}`}
            onClick={redirectToOverview}
          >
            {strings.add_project.titles.back_to_overview}
          </CustomButton>
        )}
        {!selectedTab && (
          <div className={classes.sample_projectWidth}>
            {localStorage.getItem("isPublicMode") === "false" && (
              <>
                <Row>
                  <div className={classes.width_50}>
                    <div className={classes.editModeBlock}>
                      <Text
                        size={12}
                        color={color.primary_palette.black}
                        family="avenir_black_r"
                        className={classes.editBlockStyle}
                      >
                        {strings.add_project.titles.edit_mode}
                      </Text>
                      <Row>
                        <CustomButton
                          className={`${classes.custom_edit_btn_styles} ${classes.marginRight7}`}
                          onClick={redirectToPortfolio}
                        >
                          {strings.add_project.titles.main_portfolio}
                        </CustomButton>
                        <CustomButton
                          className={classes.current_btn_yellow_style}
                          onMouseUp={editProjectData(props.location.state._id)}
                        >
                          {strings.add_project.titles.this_project}
                        </CustomButton>
                        <img
                          src="/assets/icons/pencil.svg"
                          onMouseUp={editProjectData(props.location.state._id)}
                          alt=""
                          className={classes.pencilAlign}
                        />
                      </Row>
                    </div>
                  </div>
                  <div className={`${classes.width_50} ${classes.flexEnd}`}>
                    <div
                      className={`${classes.editModeBlock} ${classes.publicAlign}`}
                    >
                      <Text
                        size={12}
                        color={color.primary_palette.black}
                        family="avenir_black_r"
                        className={classes.editBlockStyle}
                      >
                        {strings.add_project.titles.public_previews}
                      </Text>
                      <Row>
                        <CustomButton
                          className={`${classes.custom_public_btn_styles} ${classes.marginRight7}`}
                          onClick={previewEntirePort}
                        >
                          {strings.add_project.titles.this_portfolio}
                        </CustomButton>
                        <CustomButton
                          className={classes.current_btn_style}
                          // onClick={redirectIndividual(
                          //   individualProjectData,
                          //   false
                          // )}
                        >
                          {strings.add_project.titles.this_project}
                        </CustomButton>
                      </Row>
                    </div>
                  </div>
                </Row>
                {!get(portfolioData, "isVisibleToPublic") && (
                  <Row className={classes.hoverIconBlock}>
                    <Text
                      size={14}
                      color={color.primary_palette.black}
                      family="avenir_sb"
                      className={classes.width462}
                    >
                      {" "}
                      Your are in Portfolio:{" "}
                      <span>{get(portfolioData, "name", "")}</span>. To make
                      public, click here{" "}
                    </Text>
                    <Location
                      className={`${classes.markerAlign} hover_icon`}
                      onClick={openMarketModal}
                    />
                  </Row>
                )}
              </>
            )}
            <img
              src="/assets/images/Beta_Stamp.png"
              className={classes.betaStampIcon}
              alt=""
              onClick={handleFeedbackModal}
            />
            {get(state, "isProj") !== false && (
              <Row className={classes.buttons_align}>
                {get(state, "index") !== 0 && (
                  <Row className={classes.left_previous}>
                    <Text
                      size={20}
                      color={color.primary_palette.black}
                      family="avenir_sb"
                      onClick={redirectTo("previous")}
                      className={classes.clickBtnStyle}
                    >
                      {strings.add_project.titles.previous_project}
                    </Text>
                  </Row>
                )}
                {get(state, "index") !==
                  get(allProjectsData, "projects.length") - 1 && (
                  <Row className={classes.right_previous}>
                    <Text
                      size={20}
                      color={color.primary_palette.black}
                      family="avenir_sb"
                      onClick={redirectTo("next")}
                      className={classes.clickBtnStyle}
                    >
                      {strings.add_project.titles.next_project}
                    </Text>
                  </Row>
                )}
              </Row>
            )}
            <div className={classes.slider}>
              <Text
                size={40}
                color={color.primary_palette.free_purple}
                family="avenir_light"
                transform="uppercase"
                className={classes.titleStyle}
              >
                {get(state, "title", "")}
              </Text>
              <Slider slidesToShow={1} dots={true} key={Date.now()}>
                {renderSlides()}
              </Slider>
            </div>
            <div className={classes.design_img}>
              {" "}
              {projectInfoData(projectImagesWithoutCover)}
            </div>
            {get(state, "description", "") && (
              <>
                <Text
                  size={40}
                  color={color.primary_palette.black}
                  family="avenir_light"
                  transform="uppercase"
                  className={classes.text_center}
                >
                  {strings.add_project.titles.design_insights}
                </Text>
                <Row>
                  <img
                    src="/assets/images/Horizontal_DB.jpg"
                    className={classes.horizontal_DB}
                    alt=""
                  />
                </Row>
              </>
            )}

            {get(state, "description.length", "") > 700 ? (
              <Row className={classes.imageDesc}>
                <Row className={classes.text1}>
                  <Text
                    size={14}
                    color={color.primary_palette.black}
                    family="avenir_light"
                    className={`${classes.marginSides} ${classes.font_four}`}
                  >
                    <div
                      dangerouslySetInnerHTML={{
                        __html: descriptionTxt[0],
                      }}
                    ></div>
                  </Text>
                </Row>
                <Row className={classes.text2}>
                  <Text
                    size={14}
                    color={color.primary_palette.black}
                    family="avenir_light"
                    className={`${classes.marginSides} ${classes.font_four}`}
                  >
                    <div
                      dangerouslySetInnerHTML={{
                        __html: `${descriptionTxt[1]} ${descriptionTxt[2]}`,
                      }}
                    ></div>
                  </Text>
                </Row>
              </Row>
            ) : (
              <Row className={classes.imageDesc}>
                <Row className={classes.text1}>
                  <Text
                    size={14}
                    color={color.primary_palette.black}
                    family="avenir_light"
                    className={`${classes.marginSides} ${classes.font_four}`}
                  >
                    <div
                      dangerouslySetInnerHTML={{
                        __html: get(state, "description", ""),
                      }}
                    ></div>
                  </Text>
                </Row>
              </Row>
            )}
            <Row className={classes.imageDesc2}>
              {get(state, "clientele.length") > 0 && (
                <Row>
                  <Text
                    size={15}
                    color={color.primary_palette.black}
                    family="avenir_black_r"
                  >
                    Clientele&nbsp;
                  </Text>
                  {map(state.clientele, (venue, idx) => {
                    return (
                      <Text
                        size={15}
                        color={color.primary_palette.black}
                        family="avenir_light"
                      >
                        {venue}
                        {get(state, "clientele.length") > idx + 1 && <>,</>}
                        &nbsp;
                      </Text>
                    );
                  })}
                </Row>
              )}
              {get(state, "styleDisplay.length") > 0 && (
                <Row>
                  <Text
                    size={15}
                    color={color.primary_palette.black}
                    family="avenir_black_r"
                  >
                    &nbsp; Style&nbsp;
                  </Text>
                  {map(state.styleDisplay, (style, idx) => {
                    return (
                      <Text
                        size={15}
                        color={color.primary_palette.black}
                        family="avenir_light"
                      >
                        {style}
                        {get(state, "styleDisplay.length") > idx + 1 && <>,</>}
                        &nbsp;
                      </Text>
                    );
                  })}
                </Row>
              )}
              {get(state, "settingDisplay.length") > 0 && (
                <Row>
                  <Text
                    size={15}
                    color={color.primary_palette.black}
                    family="avenir_black_r"
                  >
                    &nbsp; Setting/Vibe&nbsp;
                  </Text>
                  {map(state.settingDisplay, (settings, idx) => {
                    return (
                      <Text
                        size={15}
                        color={color.primary_palette.black}
                        family="avenir_light"
                      >
                        {settings}
                        {get(state, "settingDisplay.length") > idx + 1 && (
                          <>,</>
                        )}
                        &nbsp;
                      </Text>
                    );
                  })}
                </Row>
              )}
              {get(state, "ballParkCost.length") > 0 && (
                <Row>
                  <Text
                    size={15}
                    color={color.primary_palette.black}
                    family="avenir_black_r"
                  >
                    &nbsp; Ballpark Cost&nbsp;
                  </Text>
                  {map(state.ballParkCost, (ballPark) => {
                    return (
                      <Text
                        size={15}
                        color={color.primary_palette.black}
                        family="avenir_light"
                      >
                        {ballPark}
                      </Text>
                    );
                  })}
                </Row>
              )}
            </Row>
            <Row className={classes.imageDesc}>
              {map(state.tagsList, (tags) => {
                return (
                  <Text
                    size={15}
                    color={color.secondary_palette.blues.click_blue}
                    family="avenir_black_r"
                  >
                    {tags}&nbsp;
                  </Text>
                );
              })}
            </Row>
          </div>
        )}
      </div>
      <CustomModal
        open={toggleFeedbackModal}
        onClose={handleFeedbackModal}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Feedback
          open={toggleFeedbackModal}
          onClose={handleFeedbackModal}
          locationInfo="Individual Project View Page"
        />
      </CustomModal>
    </>
  );
}

export default individualProjectStyles(ProjectSingleView);
