import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { map, get, find, filter } from "lodash";
import Row from "../common/ui_kit/row";

import { color } from "../../utilities/themes";
import CongratsModalStyles from "./styles/marketModalStyles";
import Text from "../common/ui_kit/text";
import strings from "../../utilities/strings";
import Close_Icon from "../data_display/icons/Close";
import CustomButton from "../navigations/custom_buttons";
import CustomSelect from "../common/ui_kit/custom_select";
import Chevron_Down_Icon from "../data_display/icons/Arrow_Chevron_Down";
import CustomScrollbars from "../data_display/custom_scroll";
import { MenuItem } from "@material-ui/core";
import {
  ProfileActions,
  LookUpActions,
  SettingsActions,
} from "../../redux/actions";

import Styled_cross from "../data_display/icons/styled_cross";
import Trash_can from "../data_display/icons/Trash_can";

function MarketsModal(props) {
  const {
    classes,
    onClose,
    onPortfolioVisibility,
    onPortfolioVisibilityAll,
    savePortfolio,
  } = props;
  const [state, setState] = useState({
    category: "",
  });
  const [limitError, setLimitError] = useState(false);

  const { category } = state;

  const dispatch = useDispatch();

  const portfolioData = useSelector((resData) => resData.Profile.portfolioData);
  const lookUpData = useSelector((resData) => resData.LookUp.LookUpData);
  const addressData = useSelector(
    (resData) => resData.Settings.CompanyLocationsData
  );

  const handleDropdownSelect =
    (name, value, regionObj, portifolioObj) => (e) => {
      if (regionObj) {
        regionObj.location = value;
        setState({ ...state });
        return;
      }
      if (name === "region" || name === "category") {
        portifolioObj[name] = value;
      }
      setState({ ...state });
    };

  const handleAddRegionClick = (portifolioObj) => () => {
    setLimitError(false);
    const { category, region } = portifolioObj;
    if (!category || !region) {
      return;
    }
    portifolioObj.displayedAt = portifolioObj.displayedAt || [];
    let foundRegion = find(portifolioObj.displayedAt, { region: region });
    if (foundRegion) {
      let foundCategory = filter(
        foundRegion.category,
        (each) => each === category
      )[0];
      if (foundCategory) {
        return;
      }
      foundRegion.category = foundRegion.category || [];
      if (foundRegion.category.length >= 4) {
        setLimitError(true);
        return;
      }
      foundRegion.category = [...foundRegion.category, category];
      foundRegion.displayedAtId = get(foundRegion, "_id", "");
    } else {
      portifolioObj.displayedAt.category =
        portifolioObj.displayedAt.category || [];
      portifolioObj.displayedAt = [
        ...portifolioObj.displayedAt,
        {
          region,
          category: [...portifolioObj.displayedAt.category, category],
          displayedAtId: get(portifolioObj, "displayedAt._id", ""),
        },
      ];
    }
    portifolioObj.region = "";
    portifolioObj.category = "";
    handleDropdownSelect(
      "location",
      get(addressData, "businessCards[0]"),
      portifolioObj.displayedAt[get(portifolioObj, "displayedAt.length") - 1]
    )();
    setState({ ...state });
    setLimitError(false);
  };

  const deleteRegion = (portfolioObj, region, categoryName) => () => {
    setLimitError(false);
    const { displayedAt } = portfolioObj;
    const foundRegion = find(displayedAt, { region });
    if (foundRegion) {
      if (foundRegion.category.length === 1 && foundRegion.madePublic) {
        foundRegion.showPublicMessage = true;
        setState({ ...state });
        setTimeout(function () {
          foundRegion.showPublicMessage = false;
          setState({ ...state });
        }, 5000);
      } else {
        foundRegion.category = foundRegion.category.filter(
          (each) => each !== categoryName
        );
      }
    }
    if (foundRegion.category.length === 0) {
      portfolioObj.displayedAt = portfolioObj.displayedAt.filter(
        (each) => each.region !== region
      );
    }
    setState({ ...state });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    dispatch(
      ProfileActions.getPortfolioData(localStorage.getItem("portfolioId"))
    );
    dispatch(LookUpActions.getLookUpData());
    dispatch(
      SettingsActions.getCompanyLocationsData(localStorage.getItem("companyId"))
    );
    dispatch(
      SettingsActions.getCompanyBasicsData(localStorage.getItem("companyId"))
    );
    dispatch(
      SettingsActions.getCompanyPortfolioData(localStorage.getItem("companyId"))
    );
  };

  const deleteCategory = (portfolioObj, region) => () => {
    setLimitError(false);
    const { displayedAt } = portfolioObj;
    portfolioObj.displayedAt = portfolioObj.displayedAt.filter(
      (each) => each.region !== region
    );
    setState({ ...state });
    return;
  };

  const returnRegionsList = (portifolioObj) => {
    return (
      <div>
        <ol className={classes.listNone}>
          {map(portifolioObj?.displayedAt, (eachRegion) => {
            return (
              <li className={`${classes.displayFlex} ${classes.marginBottom8}`}>
                <Row className={`${classes.displayInline} ${classes.width110}`}>
                  {get(eachRegion, "madePublic") ? (
                    get(eachRegion, "isVisibleToPublic", "") ? (
                      <Text
                        className={`${classes.publicTxtStyle} ${classes.publicGreen}`}
                        size={15}
                        color={color.pubic_green}
                        family="avenir_bold"
                        onClick={onPortfolioVisibility(
                          false,
                          portifolioObj._id,
                          eachRegion._id,
                          portifolioObj,
                          eachRegion
                        )}
                      >
                        IS Public
                      </Text>
                    ) : (
                      <Text
                        family="avenir_black_r"
                        size={15}
                        color={color.paid_green}
                        className={`${classes.publicTxtStyle}`}
                        transform="uppercase"
                        onClick={onPortfolioVisibility(
                          false,
                          portifolioObj._id,
                          eachRegion._id,
                          portifolioObj,
                          eachRegion
                        )}
                      >
                        PAID, GO PUBLIC
                      </Text>
                    )
                  ) : (
                    <Text
                      className={`${classes.publicTxtStyle} ${classes.notPublicBlue}`}
                      size={15}
                      color={color.secondary_palette.grays.medium_gray}
                      family="avenir_bold"
                      onClick={onPortfolioVisibility(
                        true,
                        portifolioObj._id,
                        eachRegion._id,
                        portifolioObj,
                        eachRegion
                      )}
                    >
                      Make Public
                    </Text>
                  )}
                </Row>
                <div
                  className={`${classes.list_dropdowm} ${classes.displayInline} ${classes.width140}`}
                >
                  {get(eachRegion, "region", "")}
                </div>
                <div
                  className={`${classes.displayInline}  ${classes.width180} ${classes.verticalTopAlign}`}
                >
                  {map(eachRegion?.category, (category) => {
                    return (
                      <>
                        <div
                          className={`${classes.categoryAlign} ${classes.alignTop}`}
                        >
                          {category}{" "}
                          <Styled_cross
                            className={classes.coloredCrossStyle}
                            onClick={deleteRegion(
                              portifolioObj,
                              eachRegion.region,
                              category
                            )}
                          />
                        </div>
                      </>
                    );
                  })}
                </div>
                <Row className={`${classes.displayInline} ${classes.width170}`}>
                  <CustomSelect
                    className={classes.which_office}
                    IconComponent={Chevron_Down_Icon}
                    name="location"
                    value={eachRegion.location}
                    renderValue={(value) =>
                      value ? (
                        <span className={classes.avenir_light}>
                          <span className={classes.avenir_heavy}>
                            {get(value, "nickname", "") ||
                              get(value, "name", "")}
                          </span>
                        </span>
                      ) : (
                        "Office Address"
                      )
                    }
                    MenuProps={{
                      getContentAnchorEl: null,
                      disableScrollLock: true,
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                    }}
                  >
                    <CustomScrollbars className={classes.customScrollHeight}>
                      {get(addressData, "businessCards", "") &&
                        map(addressData.businessCards, (location) => {
                          return (
                            <MenuItem
                              onClick={handleDropdownSelect(
                                "location",
                                location,
                                eachRegion
                              )}
                            >
                              <span>
                                {get(location, "nickname", "") ||
                                  get(location, "name", "")}
                              </span>
                            </MenuItem>
                          );
                        })}
                    </CustomScrollbars>
                  </CustomSelect>
                </Row>
                <Trash_can
                  className={classes.trash_icon}
                  onClick={deleteCategory(
                    portifolioObj,
                    eachRegion.region,
                    category
                  )}
                />
                <Row className={classes.portfolio_radio}>
                  <Row className={classes.padding_left_7}></Row>
                </Row>
                {get(eachRegion, "showPublicMessage", false) && (
                  <Text
                    size={15}
                    color={color.primary_palette.christmas_red}
                    family="avenir_sb"
                  >
                    {strings.settings.titles.paid_error}
                  </Text>
                )}
              </li>
            );
          })}
        </ol>
      </div>
    );
  };

  // Method responsible to return categorized menu items
  const renderCategorizedMenu = (menuData, type, val, portfolioData) => {
    // list of color codes to be appied for category tiles based on index
    const colorCodes = get(lookUpData, "configData.dropdownColorCodes", []);
    // fetching category names
    const groups = Object.keys(menuData)
      .filter((each) => each !== "noParent")
      .sort();
    // pushing nonParent items to array
    let menuItems = menuData.noParent
      ? menuData.noParent.map((each) => each)
      : [];

    groups.map((eachGroup, index) => {
      // interating group titles and pushint to menu item array
      let subGroupItems = [{ name: eachGroup, color: colorCodes[index] }];
      menuData[eachGroup].map((eachGroupItem) => {
        // iterating subgroup items
        subGroupItems.push(eachGroupItem);
      });
      // concatinating menuItems which are returned and categorized items
      menuItems = [...menuItems, ...subGroupItems];
      return null;
    });

    // mapping menuItems (objects) and returning JSX
    return menuItems.map((eachItem) => {
      return (
        <MenuItem
          style={{ background: eachItem.color }}
          onClick={
            !eachItem.color &&
            handleDropdownSelect(type, eachItem.name, val, portfolioData)
          }
          className={eachItem.color && classes.textCapital}
        >
          {eachItem.name}
        </MenuItem>
      );
    });
  };

  const returnNewMarket = () => {
    return (
      <>
        <div className={classes.regionBlock}>
          <ul className={classes.listNone}>
            {" "}
            <Row>
              <Text
                size={15}
                family="avenir_bold"
                color={color.primary_palette.franklin_purple}
                transform="uppercase"
                className={classes.width110}
              ></Text>
              <Text
                size={15}
                family="avenir_bold"
                color={color.primary_palette.franklin_purple}
                transform="uppercase"
                className={classes.width140}
              >
                Market
              </Text>
              <Text
                size={15}
                family="avenir_bold"
                color={color.primary_palette.franklin_purple}
                transform="uppercase"
                className={classes.width180}
              >
                Categories
              </Text>
              <Text
                size={15}
                family="avenir_bold"
                color={color.primary_palette.franklin_purple}
                transform="uppercase"
                className={classes.width210}
              >
                Business Card Address
              </Text>
            </Row>
            {returnRegionsList(portfolioData)}
          </ul>
          <div>
            <Text
              size={15}
              color={color.primary_palette.franklin_purple}
              family="avenir_bold"
              className={classes.bottomAlign}
            >
              <span className={classes.pointer_cursor}>+ Add More Markets</span>
            </Text>
          </div>
          <Row className={classes.centerContent}>
            <CustomSelect
              className={`${classes.market_dropdown} ${classes.dropdown}`}
              IconComponent={Chevron_Down_Icon}
              name="region"
              value={get(portfolioData, "region")}
              renderValue={(value) =>
                value ? (
                  <span className={classes.colorPurple}>{value}</span>
                ) : (
                  <span>Market</span>
                )
              }
              MenuProps={{
                getContentAnchorEl: null,
                disableScrollLock: true,
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
              }}
            >
              <CustomScrollbars className={classes.customScrollHeight}>
                {lookUpData &&
                  map(lookUpData.regions, (region) => {
                    return (
                      <MenuItem
                        onClick={handleDropdownSelect(
                          "region",
                          region.name,
                          null,
                          portfolioData
                        )}
                      >
                        {region.name}
                      </MenuItem>
                    );
                  })}
              </CustomScrollbars>
            </CustomSelect>

            <CustomSelect
              className={`${classes.category_dropdown} ${classes.dropdown} ${classes.margin_left_10}`}
              IconComponent={Chevron_Down_Icon}
              name="category"
              renderValue={(value) =>
                value ? (
                  <span className={classes.colorPurple}>{value}</span>
                ) : (
                  <span>Category</span>
                )
              }
              value={get(portfolioData, "category")}
              MenuProps={{
                getContentAnchorEl: null,
                disableScrollLock: true,
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
              }}
            >
              <CustomScrollbars className={classes.customScrollHeightCategory}>
                {/* {lookUpData &&
                  map(lookUpData.trades, (category) => {
                    return (
                      <MenuItem
                        onClick={handleDropdownSelect(
                          "category",
                          category.name,
                          null,
                          portfolioData
                        )}
                      >
                        {category.name}
                      </MenuItem>
                    );
                  })} */}

                {lookUpData &&
                  renderCategorizedMenu(
                    lookUpData.trades,
                    "category",
                    null,
                    portfolioData
                  )}
              </CustomScrollbars>
            </CustomSelect>
            <CustomButton
              className={classes.addBtn}
              onClick={handleAddRegionClick(portfolioData)}
            >
              {strings.portfolioMoney.title.add}
            </CustomButton>
          </Row>
          {limitError && (
            <Text
              color={color.primary_palette.christmas_red}
              size={15}
              family="avenir_bold"
              className={classes.marginTop6}
            >
              <img
                src="/assets/icons/info_1.svg"
                class="info_img"
                alt=""
                className={classes.marginLeft4}
              />
              Maximum four categories per market
            </Text>
          )}
        </div>
      </>
    );
  };

  return (
    <>
      <div className={classes.congratsModalStyles}>
        <div className={classes.btnRight}>
          <CustomButton className={classes.crossBtn} isX>
            <Close_Icon onClick={onClose} />
          </CustomButton>
        </div>
        <div className={classes.textCenter}>
          <img
            alt=""
            src="/assets/images/marker.png"
            className={classes.markerAlign}
          />
          <Text
            size={30}
            color={color.primary_palette.black}
            family="avenir_sb"
            transform="uppercase"
          >
            {strings.portfolio_userView.titles.displayed_where_is}
          </Text>
          <Text
            size={20}
            color={color.primary_palette.black}
            family="avenir_sb"
          >
            {strings.portfolio_userView.titles.displayed_where_is_sub}
          </Text>
        </div>

        <Row>{returnNewMarket()}</Row>
        <Row>
          <div className={`${classes.txtRight} ${classes.flex_grow}`}>
            <CustomButton
              className={classes.invitesBtn}
              onClick={onPortfolioVisibilityAll(portfolioData)}
            >
              {strings.modal.titles.make_public}
            </CustomButton>
          </div>
          <div className={`${classes.txtLeft} ${classes.flex_grow}`}>
            <CustomButton
              className={classes.invitesBtn}
              onClick={savePortfolio(portfolioData)}
            >
              {strings.modal.titles.upadte_display}
            </CustomButton>
          </div>
        </Row>
      </div>
    </>
  );
}

export default CongratsModalStyles(MarketsModal);
