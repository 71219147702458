import React from "react";

import PortFolio from "../build_portfolio/portfolio";

function Default_Landing(props) {
  return (
    <>
      <PortFolio loginRef={props.loginRef} router={props.history} />
    </>
  );
}

export default Default_Landing;
