const types = {
  FETCH_ALL_REGIONS: "FETCH_ALL_REGIONS",
  FETCH_ALL_CATEGORIES: "FETCH_ALL_CATEGORIES",
  SAVE_PROFILE_IMAGE_DATA: "SAVE_PROFILE_IMAGE_DATA",
  SAVE_ELEVATOR_PITCH_DATA: "SAVE_ELEVATOR_PITCH_DATA",
  FETCH_PROJECT_SELECTION: "FETCH_PROJECT_SELECTION",
  FETCH_PROJECT_IMAGE_SELECTION: "FETCH_PROJECT_IMAGE_SELECTION",
  SAVE_UPDATE_PROJECT: "SAVE_UPDATE_PROJECT",
  SAVE_PRODUCT_DATA: "SAVE_PRODUCT_DATA",
  GET_PRODUCT_PUBLICATION_DATA: "GET_PRODUCT_PUBLICATION_DATA",
  DELETE_PRODUCT_PUBLICATION: "DELETE_PRODUCT_PUBLICATION",
  FETCH_ALL_PROJECTS: "FETCH_ALL_PROJECTS",
  GET_PORTFOLIO_DATA: "GET_PORTFOLIO_DATA",
  GET_PORTFOLIO_DATA_BY_SLUG: "GET_PORTFOLIO_DATA_BY_SLUG",
  GET_PORTFOLIO_LIST: "GET_PORTFOLIO_LIST",
  GET_INDIVIDUAL_PROJECT_DATA: "GET_INDIVIDUAL_PROJECT_DATA",
  GET_PROFILE_DATA: "GET_PROFILE_DATA",
  INSTAGRAM_FEEDS: "INSTAGRAM_FEEDS",
  GET_COVER_IMAGE_DATA: "GET_COVER_IMAGE_DATA",
  PERSONAL_SETTING_DATA: "PERSONAL_SETTING_DATA",
  COMPANY_SETTING_DATA: "COMPANY_SETTING_DATA",
  ALL_TRADES_LIST: "ALL_TRADES_LIST",
  GET_ACCOLADES_LIST: "GET_ACCOLADES_LIST",
  GET_CONFIG_DATA: "GET_CONFIG_DATA",
  GET_VIBES_DATA: "GET_VIBES_DATA",
  GET_CLIENTEL_DATA: "GET_CLIENTEL_DATA",
  GET_VENUE_DATA: "GET_VENUE_DATA",
  GET_COMPANY_DATA: "GET_COMPANY_DATA",
  COMPANY_DATA_BY_USER: "COMPANY_DATA_BY_USER",
  PROJECTS_BY_PORTFOLIO: "PROJECTS_BY_PORTFOLIO"
};
export default types;
