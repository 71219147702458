import React, { useState, useEffect } from "react";
import uuidv4 from "uuid/v4";
import { map, find, get } from "lodash";
import { useDispatch } from "react-redux";
import {
  sortableContainer,
  sortableElement,
  sortableHandle,
} from "react-sortable-hoc";
import arrayMove from "array-move";

import { color } from "../../utilities/themes";
import dashboardStyles from "./styles/dashboardStyles";
import Row from "../common/ui_kit/row";
import Text from "../common/ui_kit/text";
import strings from "../../utilities/strings";
import Close_Icon from "../data_display/icons/Close";
import CustomButton from "../navigations/custom_buttons";
import Edit_Circle_Icon from "../data_display/icons/Edit";
import Trash_can from "../data_display/icons/Trash_can";
import CustomScrollbars from "../data_display/custom_scroll";
import CustomModal from "../inputs/custom_modal";
import VignetteCropper from "./vignetteCopperModal";
import SvgHamburger from "../data_display/icons/Hamburger";
import VignetteDelete from "./vignetteDeleteModal";

import { REGEX_URL } from "../../validations/validations";
import { ProfileActions } from "../../redux/actions";

function VignetteDashBoard(props) {
  const { classes, onClose, project, sortedVignetteData, onProductOpen } =
    props;
  const [state, setState] = useState({
    openCropperModal: false,
    openDeleteModal: false,
    vignettesImages: [],
    isProduct: true,
    productLink: "",
    isLinkedToProject: true,
    errors: {},
    portfolioId: localStorage.getItem("portfolioId"),
  });

  const dispatch = useDispatch();

  useEffect(() => {
    let vignettesBlock = [];
    for (let i = 0; i < 10; i++) {
      vignettesBlock[i] = {
        id: uuidv4(),
        position: i + 1,
        isLinkedToProject: true,
      };
    }
    setState({ ...state, vignettesImages: [...vignettesBlock] });
  }, []);

  useEffect(() => {
    if (sortedVignetteData) {
      const vignetteData = [...sortedVignetteData];
      if (vignetteData.length < 10) {
        const leftPositions = [];
        const registeredPositions = vignetteData.map((each) => {
          return each.position;
        });
        for (let i = 0; i < 10; i++) {
          if (!registeredPositions.includes(i + 1)) {
            leftPositions.push(i + 1);
          }
        }
        map(leftPositions, (pos) => {
          vignetteData.push({
            id: uuidv4(),
            position: pos,
            isLinkedToProject: true,
          });
        });
      }
      setState({
        ...state,
        vignettesImages: map(vignetteData, (each) => {
          return {
            ...each,
            id: each._id || uuidv4(),
            img: each.imageUri ? each.imageUri : "/assets/images/gray.PNG",
          };
        }).sort((a, b) => a.position - b.position),
      });
    }
  }, [sortedVignetteData]);

  const SortableItem = sortableElement(({ each, index, title }) => {
    return (
      <div className={classes.vignettes_img_block}>
        <div
          className={classes.vignettes_img}
          id={each.id}
          onDragOver={handleDragOver}
          onDrop={onDrop}
        >
          <img
            alt=""
            id={each.id}
            src={each.img || "/assets/images/gray.PNG"}
          />
        </div>
        <div className={classes.icons_list}>
          <Trash_can
            className={classes.trash_icon}
            onClick={
              each.img != "/assets/images/gray.PNG" && deleteVignette(each._id)
            }
          />
          <DragHandle styleClasses={classes} />
          <img
            alt=""
            src="/assets/icons/pencil.svg"
            className={classes.pencil_icon}
            onClick={
              each.img != "/assets/images/gray.PNG" && handleCropperModal(each)
            }
          />
        </div>
      </div>
    );
  });

  const dragStart = (event) => {
    const { id, name } = event.target;
    setState({ ...state, draggedImage: id, projectId: name });
  };

  const onDrop = (e) => {
    e.preventDefault();
    const foundData = find(vignettesImages, { id: e.target.id });
    setState({
      ...state,
      openCropperModal: true,
      cropperData: foundData,
    });
  };

  const SortableContainer = sortableContainer(({ styleContainer, children }) => {
    return <ul className={styleContainer.listTextArea}>{children}</ul>;
  });

  const DragHandle = sortableHandle(({ styleClasses }) => (
    <SvgHamburger className={styleClasses.hamburger_icon} />
  ));

  const saveSort = () => {
    dispatch(
      ProfileActions.saveVignetteSortOrder(vignettesImages, () => {
        onClose();
        dispatch(
          ProfileActions.getPortfolioData(localStorage.getItem("portfolioId"))
        );
      })
    );
  };

  const setCroppedImage = (imageUrl, imageFile) => {
    const container = find(vignettesImages, { id: cropperData.id });
    container.img = imageUrl;
    container.projectId = projectId;
    setState({ ...state });
    const dataToSubmit = {
      image: imageUrl,
      projectId,
      position: container.position,
      portfolioId,
      isProduct: container.isLinkedToProject,
      productLink: container.productLink,
      draggedImage,
      imageFile,
    };

    dispatch(
      ProfileActions.saveVignetteData(dataToSubmit, () => {
        dispatch(
          ProfileActions.getPortfolioData(localStorage.getItem("portfolioId"))
        );
        setState({
          ...state,
          isProduct: true,
          productLink: "",
          openCropperModal: false,
          image: "",
          imageUrl: "",
          img: "",
          draggedImage: "",
          imageFile: "",
        });
      })
    );
  };

  const handleInputChange = (data) => (e) => {
    handleValidations(e.target.name, e.target.value);
    const { name, value } = e.target;
    if (name === "isProduct" || name === "isProduct1") {
      if (name === "isProduct") {
        data.isLinkedToProject = true;
        data.productLink = "";
      } else {
        data.isLinkedToProject = false;
      }
      setState({ ...state });
      return;
    }
    data.productLink = value;
    setState({ ...state });
  };

  const handleValidations = (name, value) => {
    if (name === "productLink") {
      errors[name] = errors[name] || {};
      if (!REGEX_URL.test(value)) {
        errors[name] = { error: true, message: "" };
        setState({
          ...state,
        });
        return;
      }
      errors[name] = { error: false, message: "" };
      setState({
        ...state,
      });
      return;
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDeleteModal = (cropperDataVal) => () => {
    setState({
      ...state,
      openDeleteModal: !openDeleteModal,
      cropperData: cropperDataVal,
    });
  };

  const handleCropperModal = (cropperDataVal) => () => {
    setState({
      ...state,
      openCropperModal: !openCropperModal,
      cropperData: cropperDataVal,
    });
  };

  const deleteVignette = (id) => () => {
    dispatch(
      ProfileActions.deleteVignette(id, () => {
        dispatch(
          ProfileActions.getPortfolioData(localStorage.getItem("portfolioId"))
        );
      })
    );
    setState({
      ...state,
      openDeleteModal: false,
    });
  };

  const onSortEnd = ({ oldIndex, newIndex }) => {
    setState({
      ...state,
      vignettesImages: arrayMove(state.vignettesImages, oldIndex, newIndex),
    });
  };

  const {
    openCropperModal,
    draggedImage,
    cropperData,
    openDeleteModal,
    errors,
    vignettesImages,
    projectId,
    portfolioId,
  } = state;

  return (
    <>
      <div className={classes.dashboardWidth}>
        <div className={classes.textCenter}>
          <Edit_Circle_Icon className={classes.edit_icon} />
        </div>
        <div className={classes.btnRight}>
          <CustomButton className={classes.crossBtn} isX>
            <Close_Icon onClick={onClose} />
          </CustomButton>
        </div>
        <Text
          size={30}
          family="avenir_light"
          color={color.primary_palette.black}
          transform="uppercase"
          className={`${classes.textCenter} ${classes.margin_top_10}`}
        >
          {strings.portfolio_userView.titles.Vignettes}
        </Text>
        <Text
          size={16}
          color={color.secondary_palette.grays.drag_text}
          family="avenir_light"
          className={`${classes.textCenter} ${classes.margin_top_10}`}
        >
          {strings.vignettes.titles.vignettes_des1}
        </Text>
        <div className={classes.spacing}>
          <SortableContainer
            styleContainer={classes}
            onSortEnd={onSortEnd}
            useDragHandle
            axis="xy"
          >
            {vignettesImages &&
              vignettesImages.map((each, idx) => {
                return (
                  <SortableItem each={each} key={idx} index={idx} title={idx} />
                );
              })}
          </SortableContainer>
          <div>
            <Text
              size={20}
              color={color.primary_palette.free_purple}
              family="avenir_sb"
            >
              {strings.vignettes.titles.vignettes_drag}
            </Text>
          </div>
          <Row className={classes.flex_wrap}>
            <CustomScrollbars
              className={classes.dragable_scroll}
              isModalScroll={true}
            >
              {project.map((projectData) => (
                <>
                  {projectData.projectImages.map(
                    (imageData) =>
                      imageData.actualImageUri && (
                        <img
                          alt=""
                          src={imageData.actualImageUri}
                          draggable="true"
                          id={imageData.actualImageUri}
                          className={classes.imageDisplaySize}
                          name={projectData._id}
                          onDragStart={dragStart}
                        />
                      )
                  )}
                </>
              ))}
              <img
                alt=""
                src="/assets/images/Vignette Instruction.svg"
                className={classes.imagePlaceholderSize}
                onClick={onProductOpen}
              />
            </CustomScrollbars>
          </Row>
        </div>
        {sortedVignetteData.length === 0 && (
          <div className={classes.noProjectTxtAlign}>
            <Text
              size={15}
              color={color.primary_palette.highlight_purple}
              family="avenir_bold"
            >
              To create a Vignettes Section, you need to first add images in
              your Projects Sections. We look forward to seeing your work
            </Text>
          </div>
        )}
        <div className={`${classes.textCenter}`}>
          <CustomButton className={classes.saveBtn} onClick={saveSort}>
            {strings.elevator_pitch.titles.save}
          </CustomButton>
        </div>
      </div>
      <CustomModal open={openCropperModal} onClose={handleCropperModal("")}>
        <VignetteCropper
          image={draggedImage}
          setCroppedImage={setCroppedImage}
          onClose={handleCropperModal("")}
          handleInputChange={handleInputChange}
          isProductValue={get(state, "isProduct", true)}
          productLinkVal={get(state, "productLink", "")}
          cropperData={cropperData}
          errors={errors}
        />
      </CustomModal>
      <CustomModal open={openDeleteModal} onClose={handleDeleteModal("")}>
        <VignetteDelete
          onClose={handleDeleteModal("")}
          cropperData={cropperData}
          deleteVignette={deleteVignette}
        />
      </CustomModal>
    </>
  );
}

export default dashboardStyles(VignetteDashBoard);
