import React, { Component } from "react";
import PropTypes from "prop-types";
import { get } from "lodash";

import { Input } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import tradework_theme, { color, pxToRem } from "../../utilities/themes";
import { MAX_INPUT_COUNT } from "../../constants";

//**MODIFIED EXISTING CUSTOM TEXT AREA COMPONENT SINCE SIMILAR FUNTIONALITY */
const useStyles = {
  num: {
    position: "absolute",
    right: 0,
    padding: 0,
    fontSize: pxToRem(15),
    ...tradework_theme.typography.styles.avenir_sb,
  },
  num_placing: {
    top: "12.3%",
  },
  input_padding: {
    paddingRight: pxToRem(30),
  },
  error: {
    borderBottom: `1px solid ${color.primary_palette.christmas_red}`,
  },
};

class CustomInputCount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      text: "",
      inputFocus: false,
    };
  }

  static propTypes = {
    onBlur: PropTypes.func,
    onClear: PropTypes.func,
    className: PropTypes.string,
    classes: PropTypes.object,
    defaultValue: PropTypes.string,
    enableCount: PropTypes.bool,
    countValue: PropTypes.string,
    enableClear: PropTypes.bool,
    placeholder: PropTypes.string,
  };

  static defaultProps = {
    defaultValue: "",
    placeholder: "",
    enableCount: false,
    enableClear: false,
  };

  componentDidMount() {
    this.setState({ text: this.props.defaultValue });
  }

  componentDidUpdate(prevProps) {
    if (this.props.defaultValue !== prevProps.defaultValue) {
      this.setState({ text: this.props.defaultValue });
    }
  }

  onChange = (e, callBack, hasError) => {
    const value = e.target.value;
    const LIMIT = this.props.countValue || MAX_INPUT_COUNT;
    const remainingLimit = LIMIT - value.length;
    if (Number(remainingLimit) < 0) {
      if (!hasError) {
        callBack && callBack({ error: true });
      }
    } else {
      if (hasError) {
        callBack && callBack({ error: true });
      }
    }
    this.setState({ text: value });
  };

  onClear = () => {
    this.setState({ text: "" }, () => {
      this.props.onClear && this.props.onClear();
    });
  };

  onInputFocus = () => {
    const { inputFocus } = this.state;
    this.setState({ inputFocus: !inputFocus });
  };

  onInputBlur = (e) => {
    this.props.onBlur && this.props.onBlur(e);
    this.props.enableInlineCount && this.onInputFocus();
  };

  render() {
    const { text, inputFocus } = this.state;
    const {
      className,
      placementnumber,
      classes,
      enableCount,
      enableInlineCount,
      enableClear,
      countValue,
      error,
      override,
      onOverride,
      errorIcon,
      callBack,
      hasError,
      index,
      countColor,
      ...rest
    } = this.props;

    const remainingLimit = this.props.countValue
      ? this.props.countValue - get(text, "length")
      : MAX_INPUT_COUNT - get(text, "length");

    return (
      <>
        <Input
          {...rest}
          autoComplete="nofill"
          className={`${className} ${error && classes.error} ${
            inputFocus && classes.input_padding
          }`}
          onFocus={(e) => (e.target.placeholder = "")}
          value={text}
          onBlur={(e) => {
            this.onInputBlur(e);
            e.target.placeholder = this.props.placeholder;
          }}
          onChange={(e) => this.onChange(e, callBack, hasError, index)}
        />
        {(enableCount || enableClear) && (
          <div>
            {enableCount && (
              <span
                className={`${classes.num} ${
                  inputFocus && classes.num_placing
                } ${placementnumber}`}
                style={{
                  ...(remainingLimit > 10 && {
                    color: color.secondary_palette.grays.shadow_gray,
                  }),
                  ...(remainingLimit <= 10 && {
                    color: color.accent_colors_and_one_offs.links_orange,
                  }),
                  ...(remainingLimit <= 0 && {
                    color: color.primary_palette.tricks_red,
                  }),
                }}
              >
                {remainingLimit}
              </span>
            )}
          </div>
        )}
      </>
    );
  }
}

export default withStyles(useStyles, { withTheme: true })(CustomInputCount);
