import React, { useState, useEffect, useRef } from "react";
import _ from "lodash";
import {
  ClickAwayListener,
  Popper,
  Paper,
  makeStyles,
} from "@material-ui/core";

const useStyles = makeStyles({
  flex: {
    // width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "white",
    height: "23px",
    padding: "0px 5px",
    border: "solid 1px rgba(0, 0, 0, 0.30)",
    cursor: "pointer",
    "&:hover": {
      borderColor: "black",
    },
    "&:focus": {
      borderColor: "black",
    },
  },
  disabled: {
    cursor: "default",
    "&:hover": {
      borderColor: "rgba(0, 0, 0, 0.30)",
    },
    color: "rgba(0, 0, 0, 0.38)",
  },
  paper: {
    boxShadow:
      "0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)",
  },
  invertedIcon: {
    transform: "rotate(180deg)",
  },
});

function CustomSelect({
  value,
  IconComponent,
  className,
  style,
  renderValue,
  disabled,
  onBlur,
  onFocus,
  children,
  isAddProj,
  isProjPage,
  ...props
}) {
  const anchorRef = useRef(null);
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    toggleDropdown(false);
  }, [value]);

  const toggleDropdown = (flag) => {
    flag && onFocus && onFocus();
    // if dropdown open and trying to close dropdown then call onBlur if exist
    open && !flag && onBlur && onBlur();
    setOpen(flag);
  };

  return (
    <>
      <div
        ref={anchorRef}
        onClick={() => {
          !disabled && toggleDropdown(!open);
        }}
        className={`${classes.flex} ${
          disabled && classes.disabled
        } ${className}`}
        style={style}
        // {...props}
      >
        <p>{(renderValue && renderValue(value)) || value}</p>
        {IconComponent && (
          <IconComponent className={open && classes.invertedIcon} />
        )}
      </div>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        style={{
          zIndex: isAddProj ? 1200 : 1500,
          border: isProjPage ? "1px solid #000" : "",
        }}
      >
        <ClickAwayListener
          onClickAway={(evt) => {
            toggleDropdown(!open);
          }}
        >
          <Paper
            elevation={3}
            square
            style={{
              width: _.get(anchorRef, "current.offsetWidth"),
              padding: "10px 0px",
            }}
            className={classes.paper}
          >
            {children}
          </Paper>
        </ClickAwayListener>
      </Popper>
    </>
  );
}

export default CustomSelect;
