import React, { useState, useEffect } from "react";
import { get } from "lodash";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  sortableContainer,
  sortableElement,
  sortableHandle,
} from "react-sortable-hoc";
import arrayMove from "array-move";

import { color } from "../../utilities/themes";
import dashboardStyles from "./styles/dashboradStyles";
import Row from "../common/ui_kit/row";
import Text from "../common/ui_kit/text";
import strings from "../../utilities/strings";
import Close_Icon from "../data_display/icons/Close";
import CustomButton from "../navigations/custom_buttons";
import Edit_Circle_Icon from "../data_display/icons/Edit";
import Trash_can from "../data_display/icons/Trash_can";
import CustomScrollbars from "../data_display/custom_scroll";
import SvgHamburger from "../data_display/icons/Hamburger";
import AddIcon from "../data_display/icons/add_default_hover";
import CustomModal from "../inputs/custom_modal";
import DeleteProjectModal from "./delete_project";

import { ProfileActions } from "../../redux/actions";

function ProjectDashBoard(props) {
  const { classes, onClose, projectData } = props;
  const [state, setState] = useState({
    openDeleteModal: false,
  });

  useEffect(() => {
    const flagShipsBlockData = projectData.slice(0, projectData.length);
    setState({
      ...state,
      flagShipsBlock: flagShipsBlockData,
      additionalBlock,
    });
  }, [projectData]);

  const onSortEnd = ({ oldIndex, newIndex }) => {
    setState({
      ...state,
      flagShipsBlock: arrayMove(state.flagShipsBlock, oldIndex, newIndex),
    });
  };

  const dispatch = useDispatch();
  const history = useHistory();

  const editProjectData = (projectId) => () => {
    dispatch(
      ProfileActions.getIndividualProjectData(projectId, () => {
        history.push({
          pathname: "/AddProjects",
          state: { edit: true },
        });
      })
    );
  };

  const handleDeleteModal = (projInfo) => () => {
    setState({
      ...state,
      openDeleteModal: !openDeleteModal,
      projectInfo: projInfo,
    });
  };

  const deleteProject = (id) => () => {
    dispatch(
      ProfileActions.deleteProject(id, () => {
        dispatch(
          ProfileActions.getPortfolioData(localStorage.getItem("portfolioId"))
        );
      })
    );
    setState({
      ...state,
      openDeleteModal: false,
    });
  };

  const addProjectRedirect = () => {
    history.push({
      pathname: "/AddProjects",
      state: { edit: false },
    });
  };

  const SortableItem = sortableElement(({ project, index, title }) => {
    return (
      <>
        <div className={classes.flagship_blk}>
          <img
            src={get(project, "coverImageUri", "")}
            className={classes.flagship_img}
            alt=""
          />
          <Text
            size={18.2}
            color={color.primary_palette.black}
            family="avenir_sb"
            transform="capitalize"
            className={classes.txtEllipses}
          >
            {get(project, "title", strings.add_project.titles.project_img_des)}
          </Text>
          <div className={classes.icons_list}>
            <Trash_can
              className={classes.trash_icon}
              onClick={handleDeleteModal(project)}
            />
            <DragHandle styleClasses={classes} />
            <img
              src="/assets/icons/pencil.svg"
              className={classes.pencil_icon}
              onClick={project && editProjectData(project._id)}
              alt=""
            />
            <div
              className={
                title <= 5 ? classes.ovalNumber : classes.ovalDisabledNumber
              }
            >
              {title + 1}
            </div>
          </div>
        </div>
        {/* {title == 5 && (
          <div>
            <div className={`${classes.btnRight} ${classes.plus_hover}`}>
              <CustomButton
                className={classes.add_project}
                onClick={addProjectRedirect}
              >
                <AddIcon />
                {strings.add_project.titles.add_new_project}
              </CustomButton>
            </div>{" "}
            <div className={classes.blks_additional}>
              <div className={classes.flagship}>
                <Text
                  size={20}
                  family="avenir_sb"
                  color={color.wide_open_satin}
                  className={`${classes.padding_bottom_8}  ${classes.border_blk}`}
                >
                  {strings.add_project.titles.additional_project}
                </Text>
              </div>
            </div>
          </div>
        )} */}
      </>
    );
  });

  const SortableContainer = sortableContainer(({ styleContainer, children }) => {
    return <ul className={styleContainer.listTextArea}>{children}</ul>;
  });

  const saveProjectSortOrder = () => {
    const dataToSubmit = {
      portfolioId: localStorage.getItem("portfolioId"),
      projectsData: flagShipsBlock,
    };
    dispatch(
      ProfileActions.saveProjectSortOrder(dataToSubmit, () => {
        onClose();
        dispatch(
          ProfileActions.getPortfolioData(localStorage.getItem("portfolioId"))
        );
      })
    );
  };

  const DragHandle = sortableHandle(({ styleClasses }) => (
    <SvgHamburger className={styleClasses.hamburger_icon} />
  ));

  const { projectInfo, openDeleteModal, flagShipsBlock, additionalBlock } =
    state;

  return (
    <>
      <div className={classes.project_dashboard}>
        <div className={classes.textCenter}>
          <Edit_Circle_Icon className={classes.edit_icon} />
        </div>
        <div className={classes.btnRight}>
          <CustomButton className={classes.crossBtn} isX>
            <Close_Icon onClick={onClose} />
          </CustomButton>
        </div>
        <div className={classes.margin_top_16}>
          <Text
            size={30}
            family="avenir_light"
            color={color.primary_palette.black}
            className={`${classes.textCenter} ${classes.project_dashboard_text}`}
          >
            {strings.add_project.titles.project_dashboard}
          </Text>
          <Text
            size={15}
            family="avenir_sb"
            color={color.wide_open_satin}
            className={`${classes.textCenter} ${classes.margin_top_6}`}
          >
            {strings.add_project.titles.your_project_des1}
          </Text>
          <Text
            size={15}
            family="avenir_sb"
            color={color.wide_open_satin}
            className={classes.textCenter}
          >
            {strings.add_project.titles.your_project_des2}
          </Text>
          <div className={classes.padding_right}>
            <CustomScrollbars
              className={classes.scroll_bar}
              isModalScroll={true}
            >
              <div>
                <div>
                  <div className={classes.flagship}>
                    <Text
                      size={20}
                      family="avenir_sb"
                      color={color.wide_open_satin}
                      className={`${classes.addFlagshipSpacing}  ${classes.flagship_align}`}
                    >
                      {strings.add_project.titles.flagship_project}
                    </Text>
                    <Row className={classes.flex_wrap}>
                      <SortableContainer
                        styleContainer={classes}
                        onSortEnd={onSortEnd}
                        useDragHandle
                        axis="xy"
                      >
                        {flagShipsBlock &&
                          flagShipsBlock.map((project, idx) => {
                            return (
                              <SortableItem
                                project={project}
                                key={idx}
                                index={idx}
                                title={idx}
                              />
                            );
                          })}
                      </SortableContainer>
                    </Row>
                  </div>
                </div>
              </div>
            </CustomScrollbars>
          </div>
          <div className={classes.textCenter}>
            <div className={classes.add_project_btn}>
              <CustomButton
                className={classes.add_project}
                onClick={addProjectRedirect}
                isX
              >
                <AddIcon />
                {strings.add_project.titles.add_new_project}
              </CustomButton>
            </div>
            <CustomButton
              className={classes.saveBtn}
              onClick={saveProjectSortOrder}
            >
              {strings.elevator_pitch.titles.save}
            </CustomButton>
          </div>
        </div>
      </div>
      <CustomModal open={openDeleteModal} onClose={handleDeleteModal("")}>
        <DeleteProjectModal
          onClose={handleDeleteModal("")}
          projectInfo={projectInfo}
          deleteProject={deleteProject}
        />
      </CustomModal>
    </>
  );
}

export default dashboardStyles(ProjectDashBoard);
