import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { get } from "lodash";

import { color } from "../../utilities/themes";
import SocialLinkStyles from "./styles/socialLinkStyles";
import Text from "../common/ui_kit/text";
import strings from "../../utilities/strings";
import Close_Icon from "../data_display/icons/Close";
import CustomTextField from "../../components/inputs/custom_textfields";
import CustomButton from "../navigations/custom_buttons";

import { ProfileActions } from "../../redux/actions";
import Edit_Circle_Icon from "../data_display/icons/Edit";

function SocialLink(props) {
  const { classes, onClose, socialFeedData } = props;
  const [state, setState] = useState({
    facebook: "",
    twitter: "",
    instagram: "",
    errors: {},
  });

  const { facebook, errors, twitter, instagram } = state;

  const dispatch = useDispatch();

  useEffect(() => {
    if (socialFeedData) {
      setState({
        facebook: get(socialFeedData, "facebook", ""),
        twitter: get(socialFeedData, "twitter", ""),
        instagram: get(socialFeedData, "instagram", ""),
        errors: {},
      });
    }
  }, [socialFeedData]);

  // input change event to get value of input
  const handleInputChange = (e) => {
    handleValidation(e.target.name, e.target.value);
    const { name, value } = e.target;
    setState({
      ...state,
      [name]: value,
    });
  };

  const saveBasicDetails = () => {
    const dataToSubmit = {
      ...state,
      portfolioId: localStorage.getItem("portfolioId"),
    };

    dispatch(
      ProfileActions.saveConnectData(dataToSubmit, () => {
        dispatch(
          ProfileActions.getPortfolioData(localStorage.getItem("portfolioId"))
        );
        onClose();
      })
    );
  };

  const handleValidation = (name, value) => {
    if (name === "facebook" || name === "twitter" || name === "instagram") {
      if (value.length > 60) {
        errors[name] = { error: true, message: "" };
        setState({
          ...state,
        });
        return;
      }
      errors[name] = { error: false, message: "" };
      setState({
        ...state,
      });
      return;
    }
  };

  const hasFormErrors = () => {
    const allErrors =
      errors &&
      Object.values(errors || {}).map((errObj) => errObj?.error || false);
    if (allErrors && allErrors.includes(true)) {
      return true;
    }
    return false;
  };

  const disableSubmit = hasFormErrors();
  return (
    <>
      <div className={classes.product_cover}>
        {socialFeedData && <Edit_Circle_Icon className={classes.edit_icon} />}
        <div className={classes.textRight}>
          <CustomButton className={classes.cross_btn} onClick={onClose} isX>
            <Close_Icon className={classes.cross_mark} onClick={onClose} />
          </CustomButton>
        </div>
        <div className={classes.displayBlock}>
          <Text
            size={24}
            color={color.primary_palette.franklin_purple}
            family="avenir_bold"
          >
            {strings.settings.titles.add_social}
          </Text>
        </div>
        <div className={classes.inputBlocksAlign}>
          <div className={classes.textBlockAlign}>
            <Text
              size={15}
              color={color.primary_palette.black}
              family="gillsans_sb"
              className={classes.txtAlign}
            >
              {strings.settings.titles.facebook}
            </Text>
            <span className={classes.subTxtAlign}>/</span>
            <CustomTextField
              className={classes.textFieldAlign}
              onChange={handleInputChange}
              name="facebook"
              value={facebook}
            />
          </div>
          <div className={classes.textBlockAlign}>
            <Text
              size={15}
              color={color.primary_palette.black}
              family="gillsans_sb"
              className={`${classes.txtAlign} ${classes.marginLeft12}`}
            >
              {strings.settings.titles.twitter}
            </Text>
            <span className={classes.subTxtAlign}>@</span>
            <CustomTextField
              className={classes.textFieldAlign}
              onChange={handleInputChange}
              name="twitter"
              value={twitter}
            />
          </div>
          <div className={classes.textBlockAlign}>
            <Text
              size={15}
              color={color.primary_palette.black}
              family="gillsans_sb"
              className={classes.txtAlign}
            >
              {strings.settings.titles.instagram}
            </Text>
            <span className={classes.subTxtAlign}>@</span>
            <CustomTextField
              className={classes.textFieldAlign}
              onChange={handleInputChange}
              name="instagram"
              value={instagram}
            />
          </div>
        </div>
        <div className={classes.textCenter}>
          <CustomButton
            className={
              disableSubmit ? classes.deleteBtnDisabled : classes.deleteBtn
            }
            onClick={saveBasicDetails}
            disabled={disableSubmit}
          >
            {socialFeedData
              ? strings.add_project.titles.update
              : strings.add_project.titles.save}
          </CustomButton>
        </div>
      </div>
    </>
  );
}

export default SocialLinkStyles(SocialLink);
