import React from "react";
import { get } from "lodash";

import GridStyles from "./styles/gridStyles";
import Row from "../common/ui_kit/row";

function GridFour(props) {
  const { classes, imageData, imageSize } = props;

  return (
    <>
      <div className={classes.publicviewProject}>
        <Row
          className={
            imageSize ? classes.grid_main_spacing_m : classes.grid_main_spacing
          }
        >
          <div>
            <img
              alt=""
              className={imageSize ? classes.grid_4_1_m : classes.grid_4_1}
              src={
                get(imageData[0], "imageUri", "") || "/assets/images/gray.PNG"
              }
            />
          </div>
          <div>
            <div>
              <img
                alt=""
                className={imageSize ? classes.grid_4_2_m : classes.grid_4_2}
                src={
                  get(imageData[1], "imageUri", "") || "/assets/images/gray.PNG"
                }
              />
            </div>
            <div>
              <img
                alt=""
                className={imageSize ? classes.grid_4_3_m : classes.grid_4_3}
                src={
                  get(imageData[2], "imageUri", "") || "/assets/images/gray.PNG"
                }
              />
            </div>
          </div>
          <div>
            <img
              alt=""
              className={imageSize ? classes.grid_4_4_m : classes.grid_4_4}
              src={
                get(imageData[3], "imageUri", "") || "/assets/images/gray.PNG"
              }
            />
          </div>
        </Row>
      </div>
    </>
  );
}

export default GridStyles(GridFour);
