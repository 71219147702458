import { withStyles } from "@material-ui/core/styles";
import tradework_theme, { pxToRem, color } from "../../utilities/themes";

const styles = withStyles({
  wrapper: {
    marginTop: pxToRem(80)
  },
  wrapper_container: {
    width: "90% !important",
    margin: "auto",
  },
  headerDiv: {
    textAlign: 'center'
  },
  grid_button: {
    width: pxToRem(150),
    height: pxToRem(25),
    borderRadius: pxToRem(27),
    border: `solid ${pxToRem(2)} ${color.primary_palette.franklin_purple}`,
    backgroundColor: color.primary_palette.white,
    color: `${color.primary_palette.franklin_purple} !important`,
    ...tradework_theme.typography.styles.avenir_bold,
    fontSize: pxToRem(15),
    marginTop: pxToRem(30),
    marginLeft: pxToRem(20),
  },
  draft_btn: {
    width: pxToRem(170),
    height: pxToRem(35),
    marginRight: pxToRem(20),
    borderRadius: pxToRem(22),
    border: `solid ${pxToRem(1.6)} ${color.primary_palette.franklin_purple}`,
    backgroundColor: color.primary_palette.white,
    "& .MuiButton-label": {
      color: color.primary_palette.franklin_purple,
      fontSize: pxToRem(16.3),
      ...tradework_theme.typography.styles.avenir_bold,
    },
  },
  back_btn: {
    width: pxToRem(128),
    height: pxToRem(35),
    marginRight: pxToRem(20),
    borderRadius: pxToRem(22),
    border: `solid ${pxToRem(1.6)} ${color.primary_palette.franklin_purple}`,
    backgroundColor: color.primary_palette.white,
    "& .MuiButton-label": {
      color: color.primary_palette.franklin_purple,
      fontSize: pxToRem(16.3),
      ...tradework_theme.typography.styles.avenir_bold,
    },
  },
  preview_btn: {
    height: pxToRem(22),
    width: pxToRem(157),
    marginRight: pxToRem(20),
    borderRadius: pxToRem(4),
    border: `solid ${pxToRem(1.6)} ${color.primary_palette.franklin_purple}`,
    backgroundColor: color.primary_palette.white,
    position: "absolute",
    right: pxToRem(20),
    top: pxToRem(140),
    "& .MuiButton-label": {
      color: color.primary_palette.black,
      fontSize: pxToRem(15),
      ...tradework_theme.typography.styles.avenir_sb,
      textTransform: "capitalize",
    },
  },
  preview_btn_entire: {
    height: pxToRem(22),
    width: pxToRem(181),
    marginRight: pxToRem(20),
    borderRadius: pxToRem(4),
    border: `solid ${pxToRem(1.6)} ${color.primary_palette.franklin_purple}`,
    backgroundColor: color.primary_palette.white,
    position: "absolute",
    right: pxToRem(20),
    top: pxToRem(110),
    "& .MuiButton-label": {
      color: color.primary_palette.black,
      fontSize: pxToRem(15),
      ...tradework_theme.typography.styles.avenir_sb,
      textTransform: "capitalize",
    },
  },
  back_btn_disable: {
    width: pxToRem(138),
    height: pxToRem(35),
    marginRight: pxToRem(20),
    borderRadius: pxToRem(22),
    backgroundColor: color.primary_palette.white,
    border: `solid ${pxToRem(2)} ${color.primary_palette.disable_border}`,
    "& .MuiButton-label": {
      ...tradework_theme.typography.styles.avenir_bold,
      fontSize: pxToRem(15),
    },
  },
  save_btn: {
    width: pxToRem(90),
    height: pxToRem(35),
    borderRadius: pxToRem(22),
    border: `solid ${pxToRem(1.6)} ${color.primary_palette.franklin_purple}`,
    backgroundColor: color.primary_palette.sky_blue,
    marginRight: pxToRem(2),
    "& .MuiButton-label": {
      color: color.primary_palette.franklin_purple,
      fontSize: pxToRem(16.3),
      ...tradework_theme.typography.styles.avenir_bold,
    },
  },
  save_btn_disable: {
    width: pxToRem(208),
    height: pxToRem(35),
    borderRadius: pxToRem(22),
    backgroundColor: color.primary_palette.white,
    border: `solid ${pxToRem(2)} ${color.primary_palette.disable_border}`,
    "& .MuiButton-label": {
      ...tradework_theme.typography.styles.avenir_bold,
      fontSize: pxToRem(15),
    },
  },
  edit_save_btn: {
    width: pxToRem(132),
    height: pxToRem(35),
    borderRadius: pxToRem(22),
    border: `solid ${pxToRem(1.6)} ${color.primary_palette.franklin_purple}`,
    backgroundColor: color.primary_palette.white,
    marginRight: pxToRem(13),
    "& .MuiButton-label": {
      color: color.primary_palette.franklin_purple,
      fontSize: pxToRem(16.3),
      ...tradework_theme.typography.styles.avenir_bold,
    },
  },
  edit_save_btn_disable: {
    width: pxToRem(132),
    height: pxToRem(35),
    borderRadius: pxToRem(22),
    backgroundColor: color.primary_palette.white,
    border: `solid ${pxToRem(2)} ${color.primary_palette.disable_border}`,
    "& .MuiButton-label": {
      ...tradework_theme.typography.styles.avenir_bold,
      fontSize: pxToRem(15),
    },
  },
  save_end_send_btn: {
    width: pxToRem(280),
    height: pxToRem(35),
    borderRadius: pxToRem(22),
    border: `solid ${pxToRem(1.6)} ${color.primary_palette.franklin_purple}`,
    backgroundColor: color.primary_palette.brick_orange,
    marginRight: pxToRem(13),
    "& .MuiButton-label": {
      color: color.primary_palette.franklin_purple,
      fontSize: pxToRem(16.3),
      ...tradework_theme.typography.styles.avenir_bold,
    },
  },
  fright: {
    float: 'right'
  },
  fleft: {
    float: 'left'
  },
  fileds_div: {
    float: 'left',
    width: "40%",
    margin: "auto",
    padding: '10px',
    marginTop: '10px',
    color: '#4e75a0'
  },
  email_temp: {
    float: 'right',
    width: "52%",
    border: '1px solid #8a8a8a',
    minHeight: '200px',
    padding: '30px',
    marginTop: '10px',
    marginBottom: '30px',
    backgroundColor:"#fafafa"
  },
  email_temp_header: {
    height: '130px',
    display: 'block'
  },
  displayblock: {
    display: 'block !important'
  },
  portfoliosExp: {
    textAlign: 'center',
    display: 'block !important',
    fontSize: '20px',
    fontWeight: 'bold',
    marginTop: '20px'
  },
  dear: {
    display: 'block !important',
    marginTop: '20px'
  },
  sectionHeader: {
    fontSize: '18px',
    color: `${color.primary_palette.franklin_purple}`,
    fontWeight: 'bold',
    marginTop: '20px'
  },
  sectionBody: {
    border: '2px solid #cfb3e6',
    minHeight: '50px',
    display: 'block',
    padding: "10px"
  },
  headBorder: {
    boxShadow: '0px 1px 0px 0px #410166',
  },
  payRow: {
    textAlign: 'center',
    marginTop: '20px'
  },
  clickHereToPay: {
    textAlign: 'center',
    margin: 'auto',
    width: pxToRem(350),
    paddingTop: '12px',
    paddingBottom: '12px',
    textDecoration:'none',
    borderRadius: pxToRem(12),
    border: `solid ${pxToRem(1.6)} ${color.primary_palette.gray}`,
    "& .MuiButton-label": {
      color: color.primary_palette.franklin_purple,
      fontSize: pxToRem(20)
    },
  },
  mailacheck: {
    textAlign: 'center',
    display: 'block !important',
    marginTop: "20px"
  },
  inputField: {
    width: "100%",
    paddingLeft: pxToRem(3),
    border: `${pxToRem(2)} solid #cacaca`,
    height: pxToRem(15),
    "&::placeholder": {
      color: color.primary_palette.highlight_purple,
      fontSize: pxToRem(12),
      ...tradework_theme.typography.styles.NeutraText,
      opacity: 1,
    },
    "&:focus": {
      outline: "none",
    },
  },
  selectField:{
    width: "100%",
    paddingLeft: pxToRem(3),
    border: `${pxToRem(2)} solid #cacaca`
  },
  inputContainer: {
    marginBottom: pxToRem(10),
    marginTop: pxToRem(10),
    height: pxToRem(26),
  },
  clientEmail: {
    width: '73%',
    marginLeft: "10px"
  },
  expire: {
    marginTop: "10px",
    width: '60%',
    marginLeft: "10px"
  },
  typical_job: {
    width: '97%',
    height: pxToRem(60),
    fontSize: pxToRem(15),
    border: `${pxToRem(2)} solid #cacaca`,
    marginTop: pxToRem(2),
    resize: "none",
  },
  signature:{
    width: pxToRem(410),
    height: pxToRem(110),
    fontSize: pxToRem(15),
    border: `${pxToRem(2)} solid #cacaca`,
    marginTop: pxToRem(2),
    resize: "none",
  },
  market: {
    width: pxToRem(100),
    marginLeft:'10px',
    marginRight:'10px'
  },
  category: {
    width: pxToRem(126),
    marginLeft: "10px"
  },
  marketTop:{
    marginTop:"35px"
  },
  width33:{
    width:"33px"
  },
  width50:{
    width: "48px",
    marginLeft: "5px",
    marginRight: "5px"
  },
  categorywidth:{
    width: "170px",
    marginLeft: "5px",
    marginRight: "5px"
  },
  mt20:{
    marginTop: "10px"
  },
  table_data: {
    width: "100%",
    "& th": {
      ...tradework_theme.typography.styles.avenir_bold,
      fontWeight: 900,
      fontSize: pxToRem(15),
      textAlign: "center",
      color: color.primary_palette.free_purple
    },
    "& td": {
      ...tradework_theme.typography.styles.avenir_roman,
      fontSize: pxToRem(15),
      color: color.primary_palette.black,
      width: pxToRem(130),
      textAlign: "center",
    },
  },
  companyName:{
    marginBottom:pxToRem(10),
    fontSize:'20px',
    fontWeight:"bold"
  },
  minHeight680:{
    minHeight: '680px'
  }
});

export default styles;
