import React from "react";

import { color } from "../../utilities/themes";
import PorfolioRateStyles from "./styles/portfolioCardModalstyle";
import Text from "../common/ui_kit/text";
import strings from "../../utilities/strings";
import Close_Icon from "../data_display/icons/Close";
import CustomButton from "../navigations/custom_buttons";

function PortfolioModal(props) {
  const { classes, onClose } = props;

  return (
    <>
      <div className={classes.portfolioModal}>
        <div className={classes.btnRight}>
          <CustomButton className={classes.crossBtn} isX>
            <Close_Icon onClick={onClose} />
          </CustomButton>
        </div>
        <div className={classes.textCenter}>
          <div className={`${classes.textCenter} ${classes.margin_top_14}`}>
            <img
              alt=""
              src="/assets/images/ratecard.png"
              className={classes.Greeting_Card}
            />
          </div>
          <Text
            size={30}
            family="avenir_light"
            color={color.primary_palette.black}
            className={`${classes.textCenter}`}
          >
            {strings.modal.titles.portfolio_rate_card}
          </Text>
          {/* <Text
            size={20}
            family="avenir_roman"
            color={color.primary_palette.free_purple}
            className={`${classes.textCenter} ${classes.headingTxtAlign}`}
          >
            {strings.modal.titles.portfolio_rate_card_des}
          </Text> */}
        </div>
        <div className={classes.current_text}>
          <Text
            size={15}
            family="avenir_roman"
            color={color.primary_palette.black}
            className={`${classes.textCenter} ${classes.marginBottom14}`}
          >
            {strings.modal.titles.portfolio_rate_current}
          </Text>
        </div>
        <div className={`${classes.regular_text} ${classes.marginBottom14}`}>
          <Text
            size={15}
            family="avenir_roman"
            color={color.primary_palette.black}
            className={classes.textCenter}
          >
            {strings.modal.titles.regular_base}
          </Text>
          <Text
            size={15}
            family="avenir_roman"
            color={color.primary_palette.black}
            className={classes.textCenter}
          >
            {strings.modal.titles.unlimited}
          </Text>
          <Text
            size={15}
            family="avenir_roman"
            color={color.primary_palette.black}
            className={classes.textCenter}
          >
            {strings.modal.titles.categories}
          </Text>
        </div>
        <div
          className={`${classes.additional_spacing} ${classes.marginBottom14}`}
        >
          <Text
            size={15}
            family="avenir_bold"
            color={color.primary_palette.black}
            className={classes.textCenter}
          >
            {strings.modal.titles.additional_card}
          </Text>
          <Text
            size={15}
            family="avenir_roman"
            color={color.primary_palette.black}
            className={classes.textCenter}
          >
            {strings.modal.titles.second_city}
          </Text>
          <Text
            size={15}
            family="avenir_roman"
            color={color.primary_palette.black}
            className={classes.textCenter}
          >
            {strings.modal.titles.third_city}
          </Text>
          <Text
            size={15}
            family="avenir_roman"
            color={color.primary_palette.black}
            className={classes.textCenter}
          >
            {strings.modal.titles.fourth_city}
          </Text>
        </div>
        {/* <div className={classes.lower_base}>
          <Text
            size={15}
            family="avenir_roman"
            color={color.primary_palette.black}
            className={`${classes.textCenter} ${classes.marginBottom14}`}
          >
            {strings.modal.titles.lower_base}
          </Text>
        </div> */}
        <div className={classes.textCenter}>
          <CustomButton className={classes.okBtn} onClick={onClose}>
            {strings.modal.titles.ok}
          </CustomButton>
        </div>
      </div>
    </>
  );
}

export default PorfolioRateStyles(PortfolioModal);
