import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import ReactQuill from "react-quill";
import { get } from "lodash";

import { color } from "../../utilities/themes";
import elevatorStyles from "./styles/elevatorStyles";
import Row from "../common/ui_kit/row";
import Text from "../common/ui_kit/text";
import strings from "../../utilities/strings";
import Close_Icon from "../data_display/icons/Close";
import CustomButton from "../navigations/custom_buttons";
import Edit_Circle_Icon from "../data_display/icons/Edit";
import CustomInputCount from "../inputs/custom_input_count";
import CustomTextEditor from "../inputs/custom_text_editor";
import { REMOVE_TAGS } from "../../validations/validations";

import { ProfileActions } from "../../redux/actions";

function ElevatorPitch(props) {
  const { onClose, data } = props;
  const [state, setState] = useState({
    errors: {},
    portfolioId: localStorage.getItem("portfolioId"),
    snapshop: "",
    expertiseTitle: "AREAS OF EXPERTISE",
    phylosophyTitle: "COMPANY PHILOSOPHY",
    description: "",
    expertise: "",
  });
  const dispatch = useDispatch();
  const [description, setDescription] = useState("");
  const [expertise, setExpertise] = useState("");
  const { snapshop, errors, phylosophyTitle, expertiseTitle } = state;

  useEffect(() => {
    // INTIAL DATA
    if (!data) {
      return;
    }
    setState({
      ...state,
      ...data,
    });
    setDescription(data.description);
    setExpertise(data.expertise);
  }, [data]);

  const handleValidations = (name, value) => {
    if (name === "snapshop") {
      errors[name] = errors[name] || {};
      if (value.length > 80 || !value) {
        errors[name] = { error: true, message: "" };
        setState({
          ...state,
        });
        return;
      }
      errors[name] = { error: false, message: "" };
      setState({
        ...state,
      });
      return;
    }
    if (name === "description" || name === "expertise") {
      errors[name] = errors[name] || {};
      if (value.replace(REMOVE_TAGS, "").length > 500) {
        errors[name] = { error: true, message: "" };
        setState({
          ...state,
        });
        return;
      }
      errors[name] = { error: false, message: "" };
      setState({
        ...state,
      });
      return;
    }
  };

  const hasFormErrors = () => {
    const allErrors = Object.values(errors).map(
      (errObj) => errObj.error || false
    );
    return allErrors.includes(true);
  };
  const handleInputChange = (e) => {
    handleValidations(e.target.name, e.target.value);
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const returnRemainingLimit = (currentCount, maxCount) => {
    if (!currentCount) {
      currentCount = 0;
    }
    return maxCount - currentCount;
  };

  const saveElevatorData = () => {
    const dataToSubmit = {
      ...state,
      description: description,
      expertise: expertise,
    };
    if (
      hasError ||
      (description && description.replace(REMOVE_TAGS, "").length > 500) ||
      (expertise && expertise.replace(REMOVE_TAGS, "").length > 500)
    ) {
      return;
    }
    dispatch(
      ProfileActions.saveElevatorPitch(dataToSubmit, () => {
        onClose();
        dispatch(
          ProfileActions.getPortfolioData(localStorage.getItem("portfolioId"))
        );
      })
    );
  };

  const clearInput = (nameVal) => () => {
    if (nameVal === "description") {
      setDescription("");
    } else if (nameVal === "expertise") {
      setExpertise("");
    }
    setState({ ...state, [nameVal]: "" });
  };

  const { classes } = props;
  const hasError = hasFormErrors();

  return (
    <>
      <div className={classes.elevator_width}>
        {get(data, "description") && (
          <div className={classes.textCenter}>
            <Edit_Circle_Icon className={classes.edit_icon} />
          </div>
        )}
        <div className={classes.textRight}>
          <CustomButton className={classes.cross_btn} isX>
            <Close_Icon className={classes.cross_mark} onClick={onClose} />
          </CustomButton>
        </div>
        <div className={classes.elevator_spacing}>
          <div className={classes.textCenter}>
            <Text
              size={30}
              family="avenir_light"
              color={color.primary_palette.black}
              transform="uppercase"
            >
              {strings.elevator_pitch.titles.elevator_pitch}
            </Text>
            <Text
              size={22.5}
              family="gillsans_r"
              color={color.secondary_palette.grays.medium_gray}
            >
              {strings.elevator_pitch.titles.elevator_pitch_des}
            </Text>
          </div>
          <div className={classes.company_snapshop}>
            <Text
              size={16}
              family="avenir_sb"
              color={color.primary_palette.franklin_purple}
              className={classes.paddingLeft6}
            >
              {strings.elevator_pitch.titles.company_snapshop}
            </Text>
            <CustomInputCount
              enableCount
              countValue={80}
              placeholder={
                strings.elevator_pitch.titles.company_snapshop_placeholder
              }
              className={classes.company_snapshop_input}
              onBlur={handleInputChange}
              name="snapshop"
              defaultValue={snapshop}
            />
            <Close_Icon
              className={classes.snap_clear}
              onClick={clearInput("snapshop")}
            />
            <Text
              size={16}
              family="avenir_sb"
              color={color.primary_palette.franklin_purple}
              className={`${classes.conceptAlign} ${classes.paddingLeft6}`}
            >
              {strings.elevator_pitch.titles.Concept1}
            </Text>
            <CustomInputCount
              placeholder="COMPANY PHILOSOPHY"
              className={classes.company_snapshop_input2}
              onBlur={handleInputChange}
              name="phylosophyTitle"
              defaultValue={phylosophyTitle ? phylosophyTitle : ""}
            />
            <Close_Icon
              className={classes.cross_mark_phil}
              onClick={clearInput("phylosophyTitle")}
            />
          </div>
          <div className={classes.company_philosophy}>
            <Text
              size={12}
              family="avenir_sb"
              color={color.quil_placeholder_color}
              className={classes.placeholder1Align}
            >
              {strings.elevator_pitch.titles.placeholder1}
            </Text>
            <ReactQuill
              theme={"snow"}
              className={classes.wordBuild}
              name={"description"}
              value={description}
              onChange={setDescription}
            />
            <Close_Icon
              className={classes.cross_mark_desc_quil}
              onClick={clearInput("description")}
            />
            <div
              className={
                returnRemainingLimit(
                  description &&
                    get(description.replace(REMOVE_TAGS, ""), "length", 0),
                  500
                ) <= 50
                  ? returnRemainingLimit(
                      description &&
                        get(description.replace(REMOVE_TAGS, ""), "length", 0),
                      500
                    ) <= 0
                    ? classes.countAlign_negative
                    : classes.countAlign_warning
                  : classes.countAlign
              }
            >
              {returnRemainingLimit(
                description &&
                  get(description.replace(REMOVE_TAGS, ""), "length", 0),
                500
              )}
            </div>
            <Text
              size={16}
              family="avenir_sb"
              color={color.primary_palette.franklin_purple}
              className={classes.paddingLeft6}
            >
              {strings.elevator_pitch.titles.Concept2}
            </Text>
            <div>
              <CustomInputCount
                placeholder="AREAS OF EXPERTISE"
                className={`${classes.company_snapshop_input2} ${classes.concept2Align}`}
                onBlur={handleInputChange}
                name="expertiseTitle"
                defaultValue={expertiseTitle}
              />
              <Close_Icon
                className={classes.cross_mark_exp}
                onClick={clearInput("expertiseTitle")}
              />
            </div>
            <div className={classes.posRelative}>
              <Text
                size={12}
                family="avenir_sb"
                color={color.quil_placeholder_color}
                className={classes.placeholder2Align}
              >
                {strings.elevator_pitch.titles.placeholder2}
              </Text>
              <ReactQuill
                theme={"snow"}
                className={classes.wordBuild}
                name={"expertise"}
                value={expertise}
                onChange={setExpertise}
              />
              <Close_Icon
                className={classes.cross_mark_exp_quil}
                onClick={clearInput("expertise")}
              />
              <div
                className={
                  returnRemainingLimit(
                    expertise &&
                      get(expertise.replace(REMOVE_TAGS, ""), "length", 0),
                    500
                  ) <= 50
                    ? returnRemainingLimit(
                        expertise &&
                          get(expertise.replace(REMOVE_TAGS, ""), "length", 0),
                        500
                      ) <= 0
                      ? classes.countAlign_negative
                      : classes.countAlign_warning
                    : classes.countAlign
                }
              >
                {returnRemainingLimit(
                  expertise &&
                    get(expertise.replace(REMOVE_TAGS, ""), "length", 0),
                  500
                )}
              </div>
            </div>
          </div>
          {((snapshop && snapshop.length > 80) ||
            (description &&
              description.replace(REMOVE_TAGS, "").length > 500) ||
            (expertise && expertise.replace(REMOVE_TAGS, "").length > 500)) && (
            <Text
              color={color.primary_palette.christmas_red}
              size={15}
              family="avenir_bold"
              className={classes.errorAlign}
            >
              ! TMI- Too Much Information. Please shorten words above.
            </Text>
          )}
          <Row className={classes.alignCenter}>
            <CustomButton
              className={hasError ? classes.save_btn_disable : classes.save_btn}
              onMouseUp={saveElevatorData}
              disabled={hasError}
            >
              {strings.elevator_pitch.titles.save}
            </CustomButton>
          </Row>
        </div>
      </div>
    </>
  );
}

export default elevatorStyles(ElevatorPitch);
