import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";
import { makeStyles, withStyles } from "@material-ui/core/styles";

function Trash_can(props) {
  const classes = makeStyles(props);
  function styledIcon(styledProps) {
    return (
      <span className={classes.root}>
        <SvgIcon {...styledProps} viewBox="0 0 15 19">
          <defs>
            <path id="prefix__a" d="M.01.136h14.268v15.62H.01z" />
            <path id="prefix__c" d="M0 .002h14.964v2.692H0z" />
          </defs>
          <g fill="none" fillRule="evenodd">
            <g transform="translate(.448 3.242)">
              <mask id="prefix__b" fill="#fff">
                <use xlinkHref="#prefix__a" />
              </mask>
              <path
                d="M10.91 13.667a.707.707 0 01-.744.67.715.715 0 01-.648-.77l.734-10.88a.707.707 0 01.745-.67.715.715 0 01.648.771l-.734 10.879zm-2.889-.037a.71.71 0 01-.698.722.71.71 0 01-.698-.722V2.724c0-.399.312-.722.698-.722a.71.71 0 01.698.722V13.63zm-3.921.708a.707.707 0 01-.746-.671L2.62 2.788a.715.715 0 01.648-.771.707.707 0 01.745.67l.734 10.88a.715.715 0 01-.647.77zM.01.136l1.683 14.668c.062.543.508.952 1.036.952h8.965c.532 0 .979-.414 1.037-.962L14.278.136H.01z"
                fill="#2C1851"
                mask="url(#prefix__b)"
              />
            </g>
            <g transform="translate(0 .23)">
              <mask id="prefix__d" fill="#fff">
                <use xlinkHref="#prefix__c" />
              </mask>
              <path
                d="M14.41 1.191h-3.085v-.18c0-.557-.437-1.009-.975-1.009H4.614c-.538 0-.974.452-.974 1.009v.18H.555A.565.565 0 000 1.766v.353c0 .318.249.575.555.575h13.854a.565.565 0 00.555-.575v-.353a.565.565 0 00-.555-.575"
                fill="#2C1851"
                mask="url(#prefix__d)"
              />
            </g>
          </g>
        </SvgIcon>
      </span>
    );
  }

  /**
   * Custom styling the colors
   */
  const Custom_Trash_can = withStyles({
    root: {
      "& > svg": {},
      "& :hover": {},
    },
  })(styledIcon);
  return (
    <>
      <Custom_Trash_can {...props} />
    </>
  );
}

export default Trash_can;
