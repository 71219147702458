import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { get, map } from "lodash";
import uuidv4 from "uuid/v4";

import { color } from "../../utilities/themes";
import CongratsModalStyles from "./styles/congratsModalStyles";
import Text from "../common/ui_kit/text";
import strings from "../../utilities/strings";
import CustomTextArea from "../inputs/custom_text_area";
import Close_Icon from "../data_display/icons/Close";
import CustomButton from "../navigations/custom_buttons";
import CustomTextField from "../inputs/custom_textfields";
import Add from "../data_display/icons/add_default_hover";
import CustomScrollbars from "../data_display/custom_scroll";
import CustomModal from "../inputs/custom_modal";
import ConfirmInvite from "./confirmModal";

import { ProfileActions } from "../../redux/actions";
import { LLAMA_DEV_URL, LLAMA_PROD_URL, LLAMA_QA_URL, LLAMA_STAGE_URL } from "../../constants";

function InviteModal(props) {
  const { classes, onClose } = props;
  const [state, setState] = useState({
    sharedEmails: [
      {
        _id: uuidv4(),
        email: [],
        personalNotes: "",
      },
      {
        _id: uuidv4(),
        email: [],
        personalNotes: "",
      },
    ],
    openInviteModal: false,
  });

  const { personalNotes, email, sharedEmails, openInviteModal } = state;

  const dispatch = useDispatch();

  const portfolioData = useSelector((resData) => resData.Profile.portfolioData);

  const handleInputChange = (section) => (e) => {
    const { name, value } = e.target;
    if (name === "email") {
      let email = value.split(",");
      section[name] = email;
      return;
    }
    section[name] = value;
    setState({ ...state });
  };

  //API
  useEffect(() => {
    let userData = localStorage.getItem("profile");
    if (userData !== "undefined") {
      userData = JSON.parse(userData);
    } else {
      userData = {};
    }
    setState({
      ...state,
      userData: userData || {},
      userEmail: userData?.email,
    });
  }, []);

  const sendInvite = () => {
    const { sharedEmails } = state;
    if (
      get(sharedEmails[0], "personalNotes") == "" &&
      get(sharedEmails[0], "email.length") == 0
    ) {
      return;
    }
    let redirectUrl = "";
    if (host) {
      if (host.includes("localhost")) {
        redirectUrl = LLAMA_DEV_URL;
      } else if (host.includes("-dev-")) {
        redirectUrl = LLAMA_QA_URL;
      } else if (host.includes("-qa-")) {
        redirectUrl = LLAMA_QA_URL;
      } else if (host.includes("-stage-")) {
        redirectUrl = LLAMA_STAGE_URL;
      } else if (host.includes("franklinreport")) {
        redirectUrl = LLAMA_PROD_URL;
      }
    }
    let host = get(window, "location.host", "");
    const data = {
      ...state,
      companyName: localStorage.getItem("companyName"),
      name: localStorage.getItem("user_data"),
      portfolioId: localStorage.getItem("portfolioId"),
      portfolioLink: `${redirectUrl}/public-portfolio/NYC/${get(
        portfolioData,
        "slug",
        ""
      )}`,
    };

    dispatch(
      ProfileActions.sendInviteMails(data, () => {
        setState({ ...state, openInviteModal: true });
        setTimeout(function () {
          setState({ ...state, openInviteModal: false });
          localStorage.removeItem("isPortfolioPublic");
          onClose();
        }, 2000);
      })
    );
  };

  const handleInviteModal = () => {
    setState({ ...state, openInviteModal: !openInviteModal });
  };

  const addNewBlock = () => {
    setState({
      ...state,
      sharedEmails: [
        ...state.sharedEmails,
        {
          _id: uuidv4(),
          email: [],
          personalNotes: "",
        },
        {
          _id: uuidv4(),
          email: [],
          personalNotes: "",
        },
      ],
    });
  };

  return (
    <>
      <div className={classes.inviteModalStyles}>
        <div className={`${classes.btnRight} ${classes.bgRibbon}`}>
          <CustomButton className={classes.crossBtn} isX>
            <Close_Icon onClick={onClose} />
          </CustomButton>
        </div>
        <div className={classes.textCenter}>
          <img
            alt=""
            src="assets/images/llama_new.PNG"
            className={classes.llama}
          />
          <Text
            size={30}
            color={color.primary_palette.black}
            family="avenir_light"
          >
            {strings.modal.titles.congrats}
          </Text>
          <Text
            size={16}
            color={color.primary_palette.black}
            family="avenir_light"
          >
            {strings.modal.titles.invite_des}
          </Text>
        </div>
        <CustomScrollbars
          className={`${classes.scroll_area_invite}`}
          scrollbarTrackStyles={classes.scrollbar_track}
          isModalScroll={true}
        >
          {map(sharedEmails, (section) => {
            return (
              <div className={classes.input_field_width}>
                <div>
                  <CustomTextArea
                    placeholder={strings.modal.titles.txtAreaPlaceholder}
                    className={classes.invite_textArea}
                    onBlur={handleInputChange(section)}
                    name="personalNotes"
                    defaultValue={personalNotes}
                  />
                </div>
                <div>
                  <CustomTextField
                    className={classes.invite_textField}
                    placeholder={strings.modal.titles.inputplaceholder}
                    onBlur={handleInputChange(section)}
                    defaultValue={email}
                    name="email"
                  />
                </div>
              </div>
            );
          })}
        </CustomScrollbars>
        <div className={classes.btnRight}>
          <CustomButton className={classes.add_moreBtn} onClick={addNewBlock}>
            <Add />
            {strings.modal.titles.add_more}
          </CustomButton>
        </div>
        <div className={classes.textCenter}>
          <CustomButton
            className={classes.send_invitesBtn}
            onClick={sendInvite}
          >
            <img
              alt=""
              src="assets/images/Invite_Envelope.png"
              className={classes.Invite_Envelope_btn}
            />
            {strings.modal.titles.send_invites_btn}
          </CustomButton>
        </div>
      </div>
      <CustomModal open={openInviteModal} onClose={handleInviteModal}>
        <ConfirmInvite onClose={handleInviteModal} />
      </CustomModal>
    </>
  );
}

export default CongratsModalStyles(InviteModal);
