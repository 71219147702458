import { withStyles } from "@material-ui/core/styles";
import tradework_theme, { pxToRem, color } from "../../../utilities/themes";

const styles = withStyles({
  txtRight: {
    textAlign: "right",
  },
  txtCenter: {
    textAlign: "center",
  },
  portfolioMoney: {
    marginTop: pxToRem(120),
    marginBottom: pxToRem(90),
  },
  margin_center: {
    margin: "0 auto",
  },
  party_icon: {
    width: pxToRem(100),
    height: pxToRem(100),
    top: pxToRem(-25),
    position: "relative",
    left: pxToRem(60),
  },
  payPalStyle: {
    width: pxToRem(274),
    margin: "0 auto",
  },
  party_icon_green: {
    width: pxToRem(100),
    height: pxToRem(100),
    position: "relative",
    left: pxToRem(64),
    top: pxToRem(-25),
  },
  party_icon_red: {
    width: pxToRem(100),
    height: pxToRem(100),
    position: "relative",
    left: pxToRem(180),
    top: pxToRem(-25),
  },
  marginLeft100: {
    marginLeft: pxToRem(-100),
  },
  width_80: {
    width: "80%",
  },
  width_20: {
    width: "20%",
  },
  width_908: {
    width: pxToRem(970),
  },
  padding_top_10: {
    paddingTop: pxToRem(10),
  },
  padding_top_12: {
    paddingTop: pxToRem(12),
  },
  img_data_spcaing: {
    paddingLeft: pxToRem(35),
    paddingRight: pxToRem(35),
  },
  textCapital: {
    textTransform: "uppercase",
  },
  marketTxtStyle: {
    ...tradework_theme.typography.styles.avenir_black_r,
  },
  img_main_border: {
    border: `solid ${pxToRem(2.5)} ${color.primary_palette.black}`,
    width: pxToRem(315),
    padding: pxToRem(5),
    height: pxToRem(243),
    // maxHeight: pxToRem(200),
  },
  img_sub_border: {
    border: `solid ${pxToRem(0.8)} ${color.primary_palette.black}`,
    width: "100%",
    height: "100%",
    position: "relative",
  },
  company_logo: {
    width: "100%",
    height: "75%",
  },
  width_18: {
    width: "18%",
  },
  width_70: {
    width: "70%",
  },
  user_logo: {
    width: pxToRem(69),
    height: pxToRem(69),
    borderRadius: " 100%",
    border: `solid ${pxToRem(1.5)} ${color.primary_palette.black}`,
    left: pxToRem(12),
    bottom: pxToRem(12),
    position: "absolute",
  },
  ellipsis_text: {
    width: pxToRem(220),
    whiteSpace: "nowrap",
    textAlign: "center",
    overflow: "hidden",
    textOverflow: "ellipsis",
    margin: "0 auto",
    marginTop: pxToRem(8),
    marginLeft: pxToRem(30),
  },
  text_decoration: {
    fontSize: pxToRem(15),
    ...tradework_theme.typography.styles.TrajanPro3,
  },
  alignments_col: {
    display: "flex",
    flexDirection: "column !important",
    marginTop: pxToRem(10),
  },
  marginTop20: {
    marginTop: pxToRem(20),
    padding: pxToRem(20),
    border: `solid ${pxToRem(2)} ${color.primary_palette.franklin_purple}`,
    borderRadius: pxToRem(8),
  },
  padding_top_15: {
    paddingTop: pxToRem(15),
  },
  padding_left_25: {
    paddingLeft: pxToRem(25),
  },
  padding_right_10: {
    paddingRight: pxToRem(10),
  },
  text_decoration_franklin_purple: {
    textDecoration: "underline",
  },
  gill_r: {
    ...tradework_theme.typography.styles.gillsans_r,
  },
  border_gray: {
    borderBottom: `solid ${pxToRem(1)} ${
      color.secondary_palette.grays.shadow_gray
    }`,
    paddingBottom: pxToRem(1),
  },
  width_50: {
    width: "50%",
    paddingLeft: pxToRem(12),
    paddingRight: pxToRem(12),
  },
  widthHeading: {
    width: pxToRem(324),
    margin: "0 auto",
    paddingBottom: pxToRem(6),
    textAlign: "left",
  },
  gary_data: {
    color: color.secondary_palette.grays.medium_gray,
    ...tradework_theme.typography.styles.avenir_black_r,
  },
  liAlignList: {
    marginBottom: pxToRem(20),
  },
  market_dropdown: {
    width: pxToRem(155),
    height: pxToRem(20),
  },
  category_dropdown: {
    width: pxToRem(207),
    height: pxToRem(20),
    "& p": {
      ...tradework_theme.typography.styles.avenir_sb,
      fontSize: pxToRem(15),
      textOverflow: "ellipsis",
      overflow: "hidden",
      whiteSpace: "nowrap",
      textTransform: "capitalize",
    },
  },
  customScrollHeight: {
    minHeight: `${pxToRem(150)} !important`,
    "& li": {
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "normal",
    },
  },
  dropdown: {
    border: `solid ${pxToRem(1)} ${color.secondary_palette.grays.shadow_gray}`,
    backgroundColor: color.primary_palette.white,
    borderRadius: pxToRem(2),
    "& .MuiSvgIcon-root": {
      fontSize: pxToRem(14),
    },
    "& p": {
      fontSize: pxToRem(15),
      ...tradework_theme.typography.styles.avenir_sb,
    },
  },
  margin_left_10: {
    marginLeft: pxToRem(10),
  },
  addBtn: {
    width: pxToRem(59),
    height: pxToRem(20),
    padding: 0,
    border: `solid ${pxToRem(1)} ${color.primary_palette.free_purple}`,
    backgroundColor: color.secondary_palette.grays.btn_gray,
    fontSize: pxToRem(15),
    color: `${color.primary_palette.franklin_purple} !important`,
    ...tradework_theme.typography.styles.avenir_sb,
    borderRadius: pxToRem(5),
    marginLeft: pxToRem(15),
  },
  list_data: {
    ...tradework_theme.typography.styles.avenir_sb,
    fontSize: pxToRem(15),
    color: color.primary_palette.black,
  },
  cross_styled_icon: {
    fontSize: pxToRem(8),
    "& path": {
      fill: color.secondary_palette.grays.shadow_gray,
    },
  },
  margin_right_5: {
    marginRight: pxToRem(5),
  },
  list_dropdowm: {
    paddingLeft: pxToRem(0),
    "& li": {
      ...tradework_theme.typography.styles.avenir_sb,
      fontSize: pxToRem(15),
    },
  },
  dropdown_ofc: {
    borderRadius: pxToRem(4),
    width: pxToRem(454),
    height: pxToRem(24),
    border: `solid ${pxToRem(1)} ${color.secondary_palette.grays.shadow_gray}`,
    "& .MuiSvgIcon-root": {
      fontSize: pxToRem(15),
      "& path": {
        stroke: color.secondary_palette.grays.shadow_gray,
      },
    },
    "& p": {
      fontSize: pxToRem(15),
      ...tradework_theme.typography.styles.avenir_sb,
    },
  },
  width_324: {
    width: pxToRem(324),
    margin: "0 auto",
    backgroundColor: color.primary_palette.fafa_gray,
    border: `solid ${pxToRem(1)} ${color.secondary_palette.grays.shadow_gray}`,
    borderRadius: pxToRem(5),
    paddingBottom: pxToRem(20),
  },
  total_amount: {
    borderTop: `solid ${pxToRem(1)} ${color.primary_palette.black}`,
    padding: `${pxToRem(0)} ${pxToRem(0)} ${pxToRem(0)} ${pxToRem(10)}`,
  },
  padding_top_25: {
    paddingTop: pxToRem(10),
  },
  underline: {
    textDecoration: "underline",
  },
  print: {
    cursor: "pointer",
    textTransform: "capitalize",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  padding_top_7: {
    paddingTop: pxToRem(7),
  },
  text_field: {
    borderRadius: pxToRem(3),
    border: `solid ${pxToRem(1)} ${color.secondary_palette.grays.shadow_gray}`,
    backgroundColor: color.primary_palette.white,
    height: pxToRem(20),
    "& .MuiInput-underline": {
      "&:after": {
        display: "none",
      },
      "&:before": {
        display: "none",
      },
    },
    "& .MuiInputBase-input": {
      height: pxToRem(20),
      ...tradework_theme.typography.styles.NeutraText,
      padding: 0,
      color: color.primary_palette.black,
    },
  },
  card: {
    width: pxToRem(175),
  },
  name_card: {
    width: pxToRem(156),
  },
  exp: {
    width: pxToRem(50),
    "& ::placeholder": {
      fontSize: pxToRem(20),
      ...tradework_theme.typography.styles.gillsans_r,
      textAlign: "center",
      opacity: 1,
    },
  },
  zip: {
    width: pxToRem(61),
  },
  security: {
    width: pxToRem(75),
  },
  padding_top_40: {
    paddingTop: pxToRem(20),
  },
  saveTxtAlign: {
    textAlign: "center",
    marginTop: pxToRem(40),
  },
  check_save: {
    "& .MuiTypography-root": {
      ...tradework_theme.typography.styles.gillsans_r,
      fontSize: pxToRem(12),
      color: color.primary_palette.black,
      // marginTop: pxToRem(-6)
    },
    "& .MuiButtonBase-root": {
      paddingTop: 0,
      paddingRight: pxToRem(4),
      paddingBottom: 0,
    },
  },
  check_box_spacing: {
    justifyContent: "flex-end",
    paddingRight: pxToRem(8),
  },
  checkBtn: {
    width: pxToRem(256),
    fontSize: pxToRem(15),
    ...tradework_theme.typography.styles.avenir_sb,
    color: `${color.primary_palette.franklin_purple} !important`,
    height: pxToRem(25),
    padding: 0,
    border: `solid ${pxToRem(1)} ${color.form_colors.blueberry_purple}`,
    backgroundColor: color.primary_palette.white,
    borderRadius: pxToRem(20),
  },
  justifyCenter: {
    justifyContent: "center",
  },
  coloredCrossStyle: {
    width: pxToRem(8),
    height: pxToRem(10),
    marginLeft: pxToRem(6),
    marginRight: pxToRem(6),
    position: "relative",
    cursor: "pointer",
    "& path": {
      fill: color.secondary_palette.grays.shadow_gray,
    },
  },
  which_office: {
    width: pxToRem(440),
    borderRadius: pxToRem(2),
    height: pxToRem(20),
    marginRight: pxToRem(21),
    marginTop: pxToRem(6),
    "& .MuiSvgIcon-root": {
      fontSize: pxToRem(14),
    },
    "& p": {
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      fontSize: pxToRem(14),
    },
  },
  future_rate_card: {
    cursor: "pointer",
    textDecoration: "underline",
  },
  trash_icon: {
    height: pxToRem(15),
    width: pxToRem(12),
    paddingLeft: pxToRem(10),
    cursor: "pointer",
    "& path": {
      fill: color.secondary_palette.grays.background_gray,
    },
  },
  padding_left_40: {
    paddingLeft: pxToRem(40),
  },
  regionListAlign: {
    listStyleType: "disc",
    paddingLeft: pxToRem(17),
  },
  avenir_heavy: {
    ...tradework_theme.typography.styles.avenir_sb,
  },
  avenir_light: {
    ...tradework_theme.typography.styles.avenir_light,
  },
  categoryAlign: {
    paddingLeft: pxToRem(5),
    fontWeight: "bold",
    whiteSpace: "nowrap",
  },
  padding_top_4: {
    paddingTop: pxToRem(4),
  },
  border_right_gary: {
    borderRight: `solid ${pxToRem(1)} ${
      color.secondary_palette.grays.shadow_gray
    }`,
  },
  margin_right_2: {
    marginRight: pxToRem(-2),
    margin: 0,
  },
  margin_right_22: {
    margin: 0,
  },
  warning_icon: {
    width: pxToRem(14),
    height: pxToRem(15),
    paddingRight: pxToRem(5),
    position: "relative",
    top: pxToRem(4),
  },
  padding_left_60: {
    paddingLeft: pxToRem(60),
  },
  marginTop4: {
    marginTop: pxToRem(4),
  },
  marketFont: {
    fontSize: pxToRem(15),
    ...tradework_theme.typography.styles.avenir_black_r,
  },
  fixedSubText: {
    fontSize: pxToRem(12),
    ...tradework_theme.typography.styles.avenir_roman,
    color: color.secondary_palette.grays.medium_gray,
    borderTop: `solid ${pxToRem(1)} ${
      color.secondary_palette.grays.shadow_gray
    }`,
  },
  helperTxtAlign: {
    position: "relative",
    top: pxToRem(2),
  },
  expiryTextAlign: {
    display: "flex",
    alignItems: "flex-end",
    marginLeft: pxToRem(13),
  },
  padding_top_3: {
    paddingTop: pxToRem(3),
  },
  marginTop10: {
    marginTop: pxToRem(10),
  },
  lineHeightMin: {
    lineHeight: 1.2,
  },
  marginTop6: {
    marginTop: pxToRem(6),
  },
  limboTextAlign: {
    marginLeft: pxToRem(-130),
    marginBottom: pxToRem(20),
  },
  marginLeft4: {
    marginLeft: pxToRem(4),
  },
  colorPurple: {
    color: color.primary_palette.highlight_purple,
  },
  customScrollHeightCategory: {
    minHeight: `${pxToRem(330)} !important`,
    "& li": {
      overflow: "hidden",
      whiteSpace: "normal",
      textOverflow: "ellipsis",
    },
  },
  textCapital: {
    textTransform: "uppercase",
  },
});

export default styles;
