import React, { useState, useEffect } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { makeStyles } from "@material-ui/core";

import { pxToRem } from "../../utilities/themes";

const useStyles = makeStyles({
  countAlign: {
    marginTop: pxToRem(46),
    textAlign: "right",
  },
});

export default function CustomTextEditor(props) {
  const [state, setState] = useState({ value: 0 });
  const MAX_COUNT = 2200;
  const { styles, theme, value, handleChange, name } = props;
  const remaining_count = MAX_COUNT - state.value.length;
  const classes = useStyles(props);

  useEffect(() => {
    setState({ ...state, value });
  }, [value]);

  const handleInputChange = (inputVal) => {
    setState({ ...state, value: inputVal });
  };

  return (
    <>
      <ReactQuill
        theme={theme || "snow"}
        className={styles}
        name={name}
        value={state.value}
        onChange={handleInputChange}
        onBlur={handleChange}
      />
      <div className={classes.countAlign}>{remaining_count}</div>
    </>
  );
}
