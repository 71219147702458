import { withStyles } from "@material-ui/core/styles";
import tradework_theme, {
  color,
  pxToRem,
  font_sizes,
} from "../../utilities/themes";

const styles = withStyles({
  popup: {
    backgroundColor: color.secondary_palette.grays.fafa_gray,
    padding: pxToRem(16),
    border: `2px solid ${color.primary_palette.franklin_purple}`,
    marginTop: "70px",
    width: "335px",
    // height: "484px",
    margin: "0 auto",
    position: "relative",
    "&:focus": {
      border: `2px solid ${color.primary_palette.franklin_purple}`,
      outline: "none",
    },
  },
  rememberMe: {
    marginTop: "-14px",
    width: pxToRem(114),
    float: "right",
    "& .MuiIconButton-root": {
      paddingRight: 0,
    },
  },
  rememberMeText: {
    fontSize: "12px !important",
    fontWeight: "600",
    fontFamily: "gillsans_light",
    "& .MuiFormControlLabel-label": {
      fontFamily: "gillsans_r",
      fontSize: "12px !important",
      marginTop: pxToRem(-4),
      "&:hover": {
        textDecoration: "underline",
      },
    },
  },
  closeBtn: {
    padding: 0,
    minWidth: pxToRem(16),
    "& .MuiButton-label": {
      "& svg": {
        fontSize: pxToRem(16),
      },
    },
  },
  formContainer: {
    paddingTop: "18px",
    width: pxToRem(260),
  },
  submit_button: {
    borderRadius: "20px",
    border: `0.08125rem solid ${color.form_colors.blueberry_purple}`,
    backgroundColor: color.primary_palette.white,
    fontSize: "14px",
    padding: "0 21px",
    width: "172px",
    fontWeight: 600,
    height: "26px",
    marginTop: "15px",
    marginBottom: "32px",
    ...tradework_theme.typography.styles.gillsans_sb,
    color: color.form_colors.royal_purple_1,
    "&:hover": {
      backgroundColor: color.button_hover,
      background: "none",
      color: `${color.primary_palette.franklin_purple} !important`,
      border: `0.08125rem solid ` + color.primary_palette.franklin_purple,
    },
    "& .MuiButton-endIcon": {
      top: "8px !important",
      right: "-23px !important",
    },
  },
  auto_email: {
    color: color.primary_palette.christmas_red,
    position: "absolute",
    top: "166px",
    "& .MuiFormControlLabel-label": {
      fontSize: "10px",
      color: color.primary_palette.christmas_red,
      fontFamily: "gillsans_r",
    },
    "& .MuiFormControlLabel-root": {
      marginLeft: 0,
    },
    "& .MuiInputLabel-root": {
      fontSize: "0.625em",
      color: color.primary_palette.black,
      marginLeft: "2px",
      display: "inline-block",
      fontFamily: "NeutraText",
    },
  },
  errorText: {
    position: "relative",
    top: pxToRem(2),
    fontSize: font_sizes.font_12,
    color: color.primary_palette.christmas_red,
    textAlign: "left",
  },
  successText: {
    position: "relative",
    top: "-0.5625rem",
    fontSize: font_sizes.font_12,
  },
  textfieldSpark: {
    "& .MuiInput-underline:hover:after": {
      borderBottomColor: color.primary_palette.highlight_purple,
      transform: "scaleX(100)",
    },
    "& .MuiInput-underline:hover:before": {
      borderBottom: "0.5px solid rgba(0, 0, 0, 0.87)",
    },
  },
  sucess_underline: {
    "& .MuiInput-underline:before": {},
  },
  footerContainer: {
    textAlign: "center",
    fontSize: "14px",
    color: color.form_colors.royal_purple_1,
    fontFamily: "gillsans_sb",
    "& .MuiTypography-colorError": {
      color: `${color.primary_palette.christmas_red} !important`,
    },
    "& .MuiTypography-colorPrimary": {
      color: color.form_colors.royal_purple_1,
    },
  },
  footer_spacing: {
    padding: "0 16px",
    paddingBottom: "30px",
  },
  cursor: {
    cursor: "pointer",
  },
  hideField: {
    display: "none",
  },
  signUpErrorMessage: {
    position: "absolute",
    top: pxToRem(205),
    left: pxToRem(67),
    fontSize: "10px",
    color: color.primary_palette.christmas_red,
  },
  forgotPasswordLarge: {
    color: color.primary_palette.franklin_purple,
    fontSize: pxToRem(16),
    textDecoration: "underline",
    fontWeight: 600,
    fontFamily: "gillsans_r",
    textTransform: "uppercase",
    marginTop: pxToRem(39),
  },
  inp_error: {
    "& .MuiInput-underline:before": {
      borderBottomColor: `${color.primary_palette.christmas_red}`,
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: `${color.primary_palette.christmas_red}`,
    },
    "& .Mui-focused": {
      fontSize: font_sizes.font_14,
      color: `${color.primary_palette.christmas_red}`,
    },
    "& .MuiFormLabel-root": {
      color: `${color.primary_palette.christmas_red}  !important`,
    },
    "& .MuiInputBase-input": {
      color: color.primary_palette.black,
    },
    "& .MuiInputBase-root": {
      "&:hover": {
        borderBottomColor: `${color.primary_palette.christmas_red}`,
      },
    },
    "& .MuiInputLabel-shrink": {
      color: `${color.primary_palette.christmas_red} !important`,
    },
  },
  inp_success: {
    "& .MuiInput-underline:before": {
      borderBottomColor: `${color.form_colors.sucess_color}`,
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: `${color.form_colors.sucess_color}`,
    },
    "& .Mui-focused": {
      fontSize: font_sizes.font_14,
      color: `${color.primary_palette.franklin_purple}`,
    },
    "& .MuiFormLabel-root": {
      color: `${color.primary_palette.franklin_purple}`,
    },
    "& .MuiInputBase-input": {
      color: color.primary_palette.black,
    },
    "& .MuiInputLabel-shrink": {
      color: `${color.primary_palette.pine_green} !important`,
    },
  },
  inp_initial: {
    "& .MuiInput-underline:before": {
      borderBottomColor: color.primary_palette.highlight_purple,
    },
    "& .MuiInputLabel-formControl": {
      color: color.form_colors.textfield_color,
      top: "0px" + "!important",
    },
    "& .MuiInputLabel-shrink": {
      color: `${color.primary_palette.franklin_purple} !important`,
      fontSize: "12px",
      fontFamily: "gillsans_r",
    },
  },
  textFieldSuccess: {
    fontSize: font_sizes.font_18,
    color: color.form_colors.textfield_color,
    width: pxToRem(208),
    marginBottom: "12px",
    fontFamily: "gillsans_light",
    "& .MuiFormLabel-root.Mui-focused": {
      fontSize: "10px",
    },
    "& .MuiFormHelperText-root.Mui-error": {
      margin: "0",
      position: "relative",
      top: "0.25rem",
    },
    "& .MuiInput-underline": {
      "&:before": {
        bottom: pxToRem(-1),
      },
      "&:after": {
        bottom: pxToRem(-1),
      },
      "&:hover": {
        "&:not(.Mui-disabled)": {
          "&:before": {
            borderBottom: "0 !important",
          },
        },
      },
    },
    "& .MuiInputBase-input": {
      fontFamily: "NeutraText",
      fontSize: "18px",
      padding: "6px 0px 4px 0px !important",
    },
    "& .MuiInputBase-root": {
      borderBottom: `${pxToRem(1)} solid ${
        color.primary_palette.highlight_purple
      }`,
      // "&:hover": {
      //   borderBottom: `${pxToRem(1)} solid ${
      //     color.primary_palette.highlight_purple
      //   }`,
      // },
    },
    "& .MuiFormLabel-root": {
      color: color.form_colors.textfield_color,
      fontSize: "18px",
      fontFamily: "gillsans_light",
    },
    "& .MuiInputLabel-shrink": {
      fontFamily: "gillsans_r",
      fontSize: "10px",
      marginTop: "9px",
    },
    "& .MuiInputAdornment-root": {
      "& .MuiSvgIcon-root": {
        marginTop: pxToRem(8),
      },
      "&:focus": {
        outline: "none !important",
      },
      "& span": {
        "&:focus": {
          outline: "none !important",
        },
      },
    },
  },
  paper: {
    boxShadow: "none",
    backgroundColor: "transparent",
  },
  loginMessageAlign: {
    position: "relative",
    top: pxToRem(-10),
  },
  contentCenter: {
    display: "flex",
    justifyContent: "center",
  },
  errorMessageAlign: {
    position: "relative",
    top: pxToRem(10),
  },
  info_img: {
    position: "relative",
    top: pxToRem(4),
  },
  backgroundBlack: {
    background: "rgba(159, 159, 159, 1) !important",
  },
});
export default styles;
