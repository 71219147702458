import React from "react";

import Row from "../common/ui_kit/row";
import Text from "../common/ui_kit/text";
import CustomButton from "../navigations/custom_buttons";
import strings from "../../utilities/strings";
import PortFolioStyles from "./styles/portfolioStyles";
import CustomModal from "../inputs/custom_modal";
import { color } from "../../utilities/themes";
import PortfolioModal from "../../components/modals/portfolioCardModal";
import { useHistory } from "react-router-dom";

function PortFolio(props) {
  const { classes } = props;

  const [state, setState] = React.useState({
    openRateModal: false,
  });
  const history = useHistory();

  const { openRateModal } = state;

  const openRatePopup = () => {
    setState({
      ...setState,
      openRateModal: true,
    });
  };

  const redirectToUserView = () => {
    const isLoggedIn = localStorage.getItem("userType");
    if (!isLoggedIn) {
      props.loginRef.current.click();
      return;
    }
    history.push("/portfolioView");
  };

  const handleClose = () => {
    setState({
      ...setState,
      openRateModal: false,
    });
  };

  return (
    <>
      <Row className={classes.portfolio_block}>
        <Row className={classes.right_side}>
          <Row className={classes.block_group}>
            <Text
              size={30}
              color={color.primary_palette.franklin_purple}
              family="OptimaLT"
              className={classes.build_text}
            >
              {strings.build.titles.build_title}
            </Text>
            <Text
              size={18}
              color={color.primary_palette.black}
              family="avenir_sb"
              className={classes.marginTop34}
            >
              {strings.build.titles.build_des}
              {strings.build.titles.will_look}
              <img
                src="assets/icons/Purple Arrow Graphic Right.svg"
                className={classes.graphicImage}
                alt=""
              />
            </Text>
            {/* <Text
              size={25}
              color={color.primary_palette.black}
              family="avenir_light"
              className={classes.marginBottom34}
            >
              {strings.build.titles.will_look}
            </Text> */}

            <div>
              {/* <img
                src="assets/images/Large_white_arrow.jpg"
                className={classes.arrow_img}
                alt=""
              /> */}
              <CustomButton
                className={classes.build_btn}
                onClick={redirectToUserView}
              >
                {strings.build.titles.build_yours}
              </CustomButton>
            </div>
            <Row className={classes.free_block}>
              <Text
                size={20}
                color={color.primary_palette.black}
                family="gillsans_r"
                className={classes.free_portfolio}
              >
                <span>{strings.build.titles.portfolio}</span>
                <span className={classes.free}>
                  {strings.build.titles.portfolio_free}
                </span>
                <span>{strings.build.titles.portfolio_con}</span>
              </Text>
            </Row>
            <Text
              size={20}
              color={color.primary_palette.black}
              family="gillsans_r"
              className={`${classes.current_franklin}`}
            >
              {strings.build.titles.portfolio_new}
            </Text>
            <Text
              size={20}
              color={color.primary_palette.black}
              family="gillsans_r"
            >
              {strings.build.titles.portfolio_new_2}
            </Text>
            <Text
              size={20}
              color={color.primary_palette.black}
              family="gillsans_r"
            >
              {strings.build.titles.portfolio_new_3}
            </Text>
            <Text>
              <Text
                size={20}
                color={color.primary_palette.black}
                family="gillsans_r"
                className={`${classes.current_franklin}`}
              >
                Create your portfolio for free. To make it public, payment or
                credit card
              </Text>
              <Text
                size={20}
                color={color.primary_palette.black}
                family="gillsans_r"
              >
                verification is required.
                <Text
                  size={20}
                  color={color.secondary_palette.grays.shadow_gray}
                  family="gillsans_r"
                  className={classes.future_rate_card}
                  onClick={openRatePopup}
                >
                  {strings.build.titles.see_rate_card}
                </Text>
              </Text>
            </Text>
            <Text
              size={20}
              color={color.primary_palette.black}
              family="gillsans_r"
              className={`${classes.current_franklin} ${classes.current_franklin_style}`}
            >
              {strings.build.titles.current_franklin}
            </Text>
            <Text
              size={20}
              color={color.primary_palette.black}
              family="gillsans_r"
              className={`${classes.current_franklin} ${classes.current_franklin_style1}`}
            >
              {strings.build.titles.current_franklin_1}
            </Text>
          </Row>
          {/* <Row className={classes.justify}>
            <CustomButton
              className={classes.build_btn}
              onClick={redirectToUserView}
            >
              {strings.build.titles.build_yours}
            </CustomButton>
          </Row> */}
        </Row>
        <Row className={classes.left_side}>
          <Row className={classes.static_img}>
            <img
              src="/assets/images/latest_sample_portfolio.jpg"
              alt=""
              className={classes.static_img}
            />
          </Row>
        </Row>
      </Row>
      {/* <Row>
        <Text
          size={20}
          color={color.secondary_palette.grays.shadow_gray}
          family="gillsans_sb"
          className={classes.future_rate_card}
          onClick={openRatePopup}
        >
          {strings.build.titles.future_rate_card}
        </Text>
      </Row> */}
      <CustomModal
        open={openRateModal}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <PortfolioModal onClose={handleClose} />
      </CustomModal>
    </>
  );
}

export default PortFolioStyles(PortFolio);
