import { withStyles } from "@material-ui/core/styles";
import tradework_theme, { color, pxToRem } from "../../../utilities/themes";

const styles = withStyles({
  containerStyles: {
    width: pxToRem(213),
    height: pxToRem(142),
    border: `solid ${pxToRem(1)} ${color.secondary_palette.grays.medium_gray}`,
    display: "flex",
    cursor: "pointer",
  },
  addImageStyle: {
    fontSize: pxToRem(68),
    border: "2px solid white",
    borderRadius: "100%",
    display: "block",
    margin: "0 auto",
    alignItems: "center",
    "& ellipse": {
      fill: "#9c7fb0",
    },
  },
  error: {
    color: color.primary_palette.christmas_red,
    fontSize: pxToRem(12),
    ...tradework_theme.typography.styles.avenir_bold,
  },
  info_img: {
    verticalAlign: "middle",
    marginTop: pxToRem(-2),
    paddingRight: pxToRem(3),
    width: pxToRem(9),
    height: pxToRem(9),
  },
  plus_bgcolor: {
    backgroundColor: "#9c7fb0",
  },
  color_div: {
    position: "relative",
    textAlign: "center",
    width: "100%",
    marginTop: pxToRem(16),
    marginBottom: pxToRem(16),
  },
  required_icon: {
    fontSize: pxToRem(19),
    top: pxToRem(20),
    position: "absolute",
    left: pxToRem(7),
  },
  action_icons: {
    display: "block",
    width: pxToRem(25),
    paddingBottom: pxToRem(7),
    cursor: "pointer",
  },
  icons_blk: {
    position: "absolute",
    bottom: 0,
    right: pxToRem(7),
  },
});

export default styles;
