import React, { useState, useEffect } from "react";
import { get, map } from "lodash";
import { useSelector, useDispatch } from "react-redux";

import BusinessOneStyles from "./styles/businessOneStyles";
import Row from "../common/ui_kit/row";
import Text from "../common/ui_kit/text";
import strings from "../../utilities/strings";
import CustomTextField from "../inputs/custom_textfields";
import { color } from "../../utilities/themes";
import CustomButton from "../navigations/custom_buttons";
import {
  PHONE_NUMBER_FORMAT,
  REGEX_URL,
  REGEX_EMAIL,
} from "../../validations/validations";
import Close_Icon from "../data_display/icons/Close";
import AddAnotherCard from "./anotherCard";
import CustomModal from "../inputs/custom_modal";
import Edit_Circle_Icon from "../data_display/icons/Edit";
import Element_Required_Icon from "../data_display/icons/ElementRequiered";
import ChevronDownIcon from "../data_display/icons/Arrow_Chevron_Down";
import Lil_Plus_filled from "../data_display/icons/Lil_Plus_filled";
import CustomSelect from "../common/ui_kit/custom_select";
import Chevron_Down_Icon from "../data_display/icons/Arrow_Chevron_Down";
import CustomScrollbars from "../data_display/custom_scroll";

import { ProfileActions, SettingsActions } from "../../redux/actions";
import { MenuItem } from "@material-ui/core";

function BusinessOne(props) {
  const { classes, businessCard, onClose } = props;
  const [state, setState] = useState({
    companyName: "",
    address: "",
    address1: "",
    city: "",
    state: "",
    zipCode: "",
    email: "",
    webSite: "",
    phoneNumber: "",
    selectedCompany: "",
    location: "",
    errors: {},
    userData: {},
    openAddNewModal: false,
    showCountryDD: false,
    isUSA: true,
  });

  const {
    companyName,
    address,
    address1,
    city,
    email,
    webSite,
    phoneNumber,
    openAddNewModal,
    userData,
    showCountryDD,
    isUSA,
    stateCode,
    zipCode,
    location,
    displayNickName,
    errors,
  } = state;

  const dispatch = useDispatch();
  const fetchData = () => {
    dispatch(ProfileActions.getCompanyData());
    dispatch(
      SettingsActions.getCompanyLocationsData(localStorage.getItem("companyId"))
    );
  };

  useEffect(() => {
    fetchData();
  }, []);

  const locationsData = useSelector(
    (resData) => resData.Settings.CompanyLocationsData
  );

  const handleBusinessModal = () => {
    setState({
      ...state,
      openAddNewModal: !openAddNewModal,
    });
  };

  const formatPhoneNumber = () => {
    if (!phoneNumber) {
      return;
    }
    return phoneNumber.toString().replace(PHONE_NUMBER_FORMAT, "$1.$2.$3");
  };

  const handleInputChange = (e) => {
    if (e.target.name === "phoneNumber") {
      const val = deformatPhoneNumber(e.target.value);
      if (!isUSA) {
        setState({ ...state, phoneNumber: val });
        return;
      } else if (isNaN(val)) {
        if (!val) {
          setState({ ...state, phoneNumber: "" });
        }
        return;
      }
      setState({ ...state, phoneNumber: val });
      return;
    }
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const handleValidationOnBlur = (e) => {
    handleValidations(e.target.name, e.target.value);
  };

  const handleValidations = (name, value) => {
    if (
      name === "email" ||
      name === "webSite" ||
      name === "phoneNumber" ||
      name === "portfolioName" ||
      name === "companyName"
    ) {
      errors[name] = errors[name] || {};
      if (
        (name === "webSite" && value && !REGEX_URL.test(value)) ||
        (name === "email" && (!value || !REGEX_EMAIL.test(value))) ||
        (name === "portfolioName" && !value) ||
        (name == "phoneNumber" && value && value.length < 10) ||
        (name == "phoneNumber" && !value) ||
        (name === "companyName" && !value)
      ) {
        errors[name] = { error: true, message: "" };
        setState({
          ...state,
        });
        return;
      }
      errors[name] = { error: false, message: "" };
      setState({
        ...state,
      });
      return;
    }
  };

  const deformatPhoneNumber = (number) => {
    // DEFORMATTING
    if (!number) {
      return;
    }
    return number.replace(/[().\s/]/g, "");
  };

  const onPhoneNumberFocus = () => {
    // ON FOCUS REMOVING FORMAT
    if (phoneNumber) {
      setState({ ...state, phoneNumber: phoneNumber.replace(/[().\s/]/g, "") });
      return;
    }
  };

  const onPhoneNumberBlur = (e) => {
    // FORMATTING PHONE NUMBER
    handleValidations(e.target.name, e.target.value);
    if (phoneNumber) {
      const num = phoneNumber
        .toString()
        .replace(PHONE_NUMBER_FORMAT, "$1.$2.$3");
      setState({ ...state, phoneNumber: num });
    }
    return;
  };

  useEffect(() => {
    let userInfo = localStorage.getItem("profile");
    if (userInfo !== "undefined") {
      userInfo = JSON.parse(userInfo);
    } else {
      userInfo = {};
    }
    if (businessCard) {
      let company = {
        name: get(businessCard, "companyName", ""),
        _id: get(businessCard, "companyId", ""),
      };
      setState({
        ...state,
        address: get(businessCard, "address", ""),
        address1: get(businessCard, "address1", ""),
        city: get(businessCard, "city", ""),
        stateCode: get(businessCard, "state", ""),
        zipCode: get(businessCard, "zip", ""),
        email: get(businessCard, "email", ""),
        webSite: get(businessCard, "webSite", ""),
        phoneNumber: get(businessCard, "phoneNumber", ""),
        companyName: get(businessCard, "name", ""),
        nickname: get(businessCard, "nickname", ""),
        displayNickName:
          get(businessCard, "nickname", "") ||
          get(businessCard, "companyName", ""),
        errors: {},
        selectedCompany: company,
        userData: userInfo,
      });
    } else {
      setState({
        ...state,
        userData: userInfo,
      });
    }
  }, [businessCard]);

  const hasFormErrors = () => {
    const allErrors = Object.values(errors).map(
      (errObj) => errObj.error || false
    );
    if (allErrors.includes(true) || !phoneNumber || !email) {
      return true;
    }
    return false;
  };

  const selectedLocation = (locationName, name, locationData) => () => {
    setState({
      ...state,
      [name]: locationName,
      locationInfo: locationData,
      address: get(locationData, "address", "") || "",
      address1: get(locationData, "address1", "") || "",
      city: get(locationData, "city", "") || "",
      zipCode: get(locationData, "zip", "") || "",
      stateCode: get(locationData, "state", "") || "",
      webSite: get(locationData, "webSite", "") || "",
      phoneNumber: get(locationData, "phoneNumber", "") || "",
    });
  };

  const closeBusiness = () => {
    onClose();
  };

  const submitBusinessCard = () => {
    if (hasError) {
      handleValidations("phoneNumber", state.phoneNumber);
      handleValidations("companyName", state.companyName);
      return;
    }
    const dataToSubmit = [
      {
        ...state,
        companyName: businessCard.companyName,
        _id: businessCard._id,
        companyId: businessCard.companyId,
        isHeadOffice: businessCard.isHeadOffice,
        isBillingAddress: businessCard.isBillingAddress,
        sevicesOffered: businessCard.sevicesOffered,
        displayedWhere: businessCard.displayedWhere,
        name: companyName,
        marketsAndServices: businessCard.marketsAndServices,
      },
    ];
    const businessCards = { businessCards: dataToSubmit };
    dispatch(
      SettingsActions.updateOfficeLocationsData(
        localStorage.getItem("companyId"),
        businessCards,
        (res) => {
          if (!res.error.error) {
            dispatch(
              SettingsActions.getCompanyLocationsData(
                localStorage.getItem("companyId")
              ),
              dispatch(
                ProfileActions.getCoverImageData(
                  localStorage.getItem("portfolioId")
                )
              ),
              dispatch(
                ProfileActions.getPortfolioData(
                  localStorage.getItem("portfolioId")
                )
              )
            );
            onClose();
          } else {
            setState({ ...state, errorMessage: res.error.message.message });
          }
        }
      )
    );
  };

  const handleUSADropdown = () => {
    setState({ ...state, showCountryDD: !showCountryDD });
  };

  const handleCountrySelect = (value) => () => {
    setState({
      ...state,
      isUSA: value === "USA",
      showCountryDD: false,
      phoneNumber: "",
    });
  };

  const hasError = hasFormErrors();

  return (
    <>
      <div className={classes.office_edit_bussinesscard}>
        <div className={classes.txtCenter}>
          <Edit_Circle_Icon className={classes.edit_icon} />
        </div>
        <div className={classes.txtRight}>
          <Close_Icon className={classes.close_mark} onClick={onClose} />
        </div>
        <Row className={`${classes.main_text} ${classes.border_none}`}>
          <Text
            size={30}
            color={color.primary_palette.black}
            family="avenir_sb"
            className={`${classes.txtCenter} ${classes.padding_bottom_15} ${classes.padding_top_15}`}
          >
            {strings.business_card.titles.your_business_card}
          </Text>
        </Row>
        <Text
          size={15}
          color={color.primary_palette.franklin_purple}
          family="avenir_sb"
          className={`${classes.txtUppercase} ${classes.padding_left_57}`}
        >
          {strings.business_card.titles.your_company}&nbsp; {displayNickName}{" "}
          &nbsp;
          {strings.business_card.titles.your_company_location}
        </Text>
        <div className={classes.BusinessCard_width_edit}>
          <div className={classes.BusinessCard_inner_edit}>
            <div className={classes.pos_relative}>
              <Element_Required_Icon
                className={
                  get(state.errors, "companyName.error", "")
                    ? classes.select_field_required_error
                    : classes.select_field_required
                }
              />
              <CustomTextField
                label="Company Name"
                name="companyName"
                className={
                  get(state.errors, "companyName.error", "")
                    ? classes.select_field_error
                    : classes.select_field
                }
                value={companyName}
                onChange={handleInputChange}
                onBlur={handleValidationOnBlur}
              />
            </div>
            <div>
              <Row>
                <div className={`${classes.width_50}`}>
                  <CustomTextField
                    // label="5 West 5th Ave"
                    label={strings.business_card.titles.company_add1}
                    name="address"
                    className={`${classes.border_bottom}`}
                    value={address}
                    onChange={handleInputChange}
                  />
                </div>
                <div className={classes.width_50}>
                  <Element_Required_Icon
                    className={
                      get(state.errors, "email.error", "")
                        ? classes.required_error
                        : classes.field_icon
                    }
                  />
                  <CustomTextField
                    // label="HR@AAArchitects.com"
                    label={strings.business_card.titles.email}
                    name="email"
                    className={`${
                      get(state.errors, "email.error", "") &&
                      classes.error_field
                    } ${classes.border_bottom} ${classes.email_field}`}
                    value={email}
                    onChange={handleInputChange}
                    onBlur={handleValidationOnBlur}
                  />
                  {/* {get(state.errors, "email.error", "") && (
                    <Text
                      size={10}
                      color={color.primary_palette.christmas_red}
                      family="gillsans_r"
                      className={classes.error_text_email}
                    >
                      <img src="/assets/icons/info_1.svg" class="info_img" alt=""/>{" "}
                      Email
                    </Text>
                  )} */}
                </div>
              </Row>
              <Row>
                <div
                  className={`${classes.width_50} ${classes.padding_right_15}`}
                >
                  <CustomTextField
                    // label="Suite 17S"
                    label={strings.business_card.titles.company_add2}
                    name="address1"
                    className={`${classes.border_bottom}`}
                    value={address1}
                    onChange={handleInputChange}
                  />
                </div>
                <div className={classes.width_50}>
                  <CustomTextField
                    // label="AARchitects.com"
                    label={strings.business_card.titles.website}
                    name="webSite"
                    className={`${
                      get(state.errors, "webSite.error", "") &&
                      classes.error_field
                    } ${classes.border_bottom} ${classes.email_field}`}
                    value={webSite}
                    onChange={handleInputChange}
                    onBlur={handleValidationOnBlur}
                  />
                  {/* {get(state.errors, "webSite.error", "") && (
                    <Text
                      size={10}
                      color={color.primary_palette.christmas_red}
                      family="gillsans_r"
                      className={classes.error_text_email}
                    >
                      <img src="/assets/icons/info_1.svg" class="info_img" alt=""/>{" "}
                      Website
                    </Text>
                  )} */}
                </div>
              </Row>
              <Row className={classes.last_field}>
                <div className={classes.pos_relative}>
                  <CustomTextField
                    // label="New York, NY 10001 "
                    label={strings.business_card.titles.city}
                    name="city"
                    className={`${
                      get(state.errors, "city.error", "") && classes.error_field
                    } ${classes.border_bottom}`}
                    value={city}
                    onChange={handleInputChange}
                    onBlur={handleValidationOnBlur}
                  />
                </div>
                <div className={classes.textFiled_last}>
                  <CustomTextField
                    className={`${classes.border_bottom} ${classes.st_field}`}
                    label={strings.business_card.titles.st}
                    name="stateCode"
                    value={stateCode ? stateCode : ""}
                    onChange={handleInputChange}
                    inputProps={{
                      maxLength: 2,
                    }}
                  />
                </div>
                <div className={classes.textFiled_last}>
                  <CustomTextField
                    className={`${classes.border_bottom} ${classes.zip_field}`}
                    label={strings.business_card.titles.zip}
                    name="zipCode"
                    value={zipCode}
                    onChange={handleInputChange}
                  />
                </div>
                <div>
                  <div className={classes.pos_relative}>
                    <Element_Required_Icon
                      className={
                        get(state.errors, "phoneNumber.error", "")
                          ? classes.required_error
                          : classes.field_icon
                      }
                    />
                    <CustomTextField
                      className={`${
                        get(state.errors, "phoneNumber.error", "") &&
                        classes.error_field
                      } ${classes.border_bottom} ${classes.phone_field}`}
                      label={strings.business_card.titles.phone}
                      name="phoneNumber"
                      onChange={handleInputChange}
                      value={
                        isUSA && get(phoneNumber, "length", 0) >= 10
                          ? formatPhoneNumber()
                          : phoneNumber
                      }
                      onBlur={onPhoneNumberBlur}
                      inputProps={{
                        maxLength: isUSA ? 10 : 20,
                        onFocus: onPhoneNumberFocus,
                      }}
                    />
                    {/* {get(state.errors, "phoneNumber.error", "") && (
                      <Text
                        size={10}
                        color={color.primary_palette.christmas_red}
                        family="gillsans_r"
                        className={`${classes.error_text_email} ${classes.phn_error}`}
                      >
                        <img src="/assets/icons/info_1.svg" class="info_img" alt=""/>{" "}
                        Phone Number
                      </Text>
                    )} */}
                    <div className={classes.flag_block}>
                      <div
                        className={`${classes.usaDivAlign} ${classes.txtRight}`}
                      >
                        {" "}
                        <span className={classes.usaStyle}>
                          {" "}
                          <img
                            src={
                              isUSA
                                ? "/assets/images/usa-flag-icon.png"
                                : "/assets/images/nonUSA.PNG"
                            }
                            alt="USA"
                            className={classes.usaFlagStyle}
                          />
                        </span>
                        <ChevronDownIcon
                          className={classes.downArrowIcon}
                          onClick={handleUSADropdown}
                        />
                      </div>
                      {showCountryDD && (
                        <div className={classes.dropdown_content}>
                          <li
                            value={"USA"}
                            onClick={handleCountrySelect("USA")}
                          >
                            USA
                          </li>
                          <li
                            value={"NONUSA"}
                            onClick={handleCountrySelect("NONUSA")}
                          >
                            NON-USA
                          </li>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </Row>
            </div>
          </div>
        </div>

        <div className={classes.width_482}>
          <Text
            size={15}
            family="avenir_bold"
            color={color.primary_palette.free_purple}
            transform="uppercase"
            className={classes.padding_bottom_5}
          >
            {strings.business_card.titles.assign}
          </Text>
          {get(locationsData, "businessCards.length", 0) > 0 && (
            <CustomSelect
              className={classes.portfolio_items}
              IconComponent={Chevron_Down_Icon}
              name="location"
              value={location}
              renderValue={(value) => value || "CHOOSE ANOTHER OFFICE"}
              MenuProps={{
                getContentAnchorEl: null,
                disableScrollLock: true,
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
              }}
            >
              <CustomScrollbars className={classes.customScrollHeight}>
                {locationsData &&
                  map(locationsData.businessCards, (locationVal) => {
                    return (
                      <MenuItem
                        onClick={selectedLocation(
                          get(locationVal, "nickname", "") ||
                            get(locationVal, "name", ""),
                          "location",
                          locationVal
                        )}
                      >
                        {get(locationVal, "nickname", "") ||
                          get(locationVal, "name", "")}
                      </MenuItem>
                    );
                  })}
                <MenuItem className={classes.txtRight}>
                  <Text
                    size={15}
                    color={color.primary_palette.black}
                    family="gillsans_sb"
                    className={classes.spacing_add_another_office}
                    onClick={handleBusinessModal}
                  >
                    <Lil_Plus_filled className={classes.lil_filled} />{" "}
                    {strings.build.titles.add_another_office}
                  </Text>
                </MenuItem>
              </CustomScrollbars>
            </CustomSelect>
          )}
          <Text
            size={12}
            family="avenir_book_r"
            color={color.secondary_palette.grays.medium_gray}
            className={`${classes.padding_bottom_10} ${classes.padding_top_5}`}
          >
            {strings.business_card.titles.assign_des}
          </Text>
        </div>
        <div className={`${classes.width_482} ${classes.add_new_office}`}>
          <Text
            size={15}
            color={color.primary_palette.free_purple}
            family="avenir_bold"
            onClick={handleBusinessModal}
            transform="uppercase"
          >
            <span className={`${classes.cursor} ${classes.add_new}`}>
              {strings.business_card.titles.add_new_office}
              <Lil_Plus_filled className={classes.lil_filled} />
            </span>
          </Text>
        </div>
        <div className={classes.width_482}>
          <div>
            <Text
              size={15}
              color={color.primary_palette.free_purple}
              family="avenir_sb"
              transform="uppercase"
            >
              {strings.business_card.titles.you}
            </Text>
            <Text
              size={15}
              color={color.primary_palette.free_purple}
              family="avenir_sb"
              className={classes.customer_info}
            >
              {strings.business_card.titles.name}{" "}
              <span className={classes.text_info}>
                {get(userData, "firstName", "") +
                  " " +
                  get(userData, "lastName", "")}
              </span>
            </Text>
            <Text
              size={15}
              color={color.primary_palette.black}
              family="avenir_roman"
              className={classes.customer_info_data}
            ></Text>
          </div>
          <div>
            <Text
              size={15}
              color={color.primary_palette.free_purple}
              family="avenir_sb"
              className={classes.customer_info}
            >
              {strings.business_card.titles.mail}{" "}
              <span className={classes.text_info}>
                {get(userData, "email", "")}
              </span>
            </Text>
            <Text
              size={15}
              color={color.primary_palette.black}
              family="avenir_roman"
              className={classes.customer_info_data}
            ></Text>
          </div>
          <div className={classes.txtCenter}>
            <CustomButton
              className={classes.continue_btn}
              onMouseUp={submitBusinessCard}
            >
              {strings.business_card.titles.update}
            </CustomButton>
          </div>
        </div>
      </div>

      <CustomModal
        open={openAddNewModal}
        onClose={handleBusinessModal}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <AddAnotherCard
          onClose={handleBusinessModal}
          businessCard={businessCard}
          isPortfolioEdit
          closeBusiness={closeBusiness}
        />
      </CustomModal>
    </>
  );
}

export default BusinessOneStyles(BusinessOne);
