import React from "react";
import { Row, Text } from "../common/ui_kit";
import { color } from "../../utilities/themes";
import { get, startsWith } from "lodash";
import MobilePorfolioStyles from "./Styles/MobileportfioloStyles";

import { PHONE_NUMBER_FORMAT } from "../../validations/validations";

function MobileBusinessCard(props) {
  const { classes, businessCardData, portfolioData } = props;

  const formatPhoneNumber = (val) => {
    return val.replace(PHONE_NUMBER_FORMAT, "$1.$2.$3");
  };

  const mailTo = (email) => () => {
    window.location.href = `mailto:${email}`;
    return "javascript:void(0)";
  };

  const getUrl = (url) => {
    if (url) {
      if (startsWith(url, "http://") || startsWith(url, "https://")) {
        return url;
      }
      return `//${url}`;
    }
    return "javascript:void(0)";
  };

  return (
    <div className={classes.mobile_business}>
      {/* <Text size={25} color={color.primary_palette.black}>
    Robert Couturier Inc.
    </Text> */}
      <Text
        size={25}
        color={color.primary_palette.black}
        family="TrajanPro3"
        className={`${classes.txtCenter} ${classes.companyNameStyle}`}
      >
        {get(businessCardData, "name", "")}
      </Text>
      <div className={classes.border_top_gray}>
        <Row className={classes.padding_top_9}>
          <div className={classes.width_50}>
            <Text
              size={14}
              color={color.primary_palette.black}
              family="avenir_sb"
              className={classes.txtCenterEllipses}
            >
              {get(businessCardData, "address", "")}
            </Text>
          </div>
          <div className={classes.width_50}>
            <Text
              size={14}
              color={color.primary_palette.black}
              family="avenir_sb"
              className={classes.txtCenterEllipses}
            >
              {formatPhoneNumber(get(businessCardData, "phoneNumber", ""))}
            </Text>
          </div>
        </Row>
        <Row className={classes.padding_top_9}>
          <div className={classes.width_50}>
            <Text
              size={14}
              color={color.primary_palette.black}
              family="avenir_sb"
              className={classes.txtCenterEllipses}
            >
              {get(businessCardData, "address1") || (
                <span>
                  {" "}
                  {get(businessCardData, "city", "")}
                  {get(businessCardData, "state", "") && ", "}
                  {get(businessCardData, "state", "")}&nbsp;
                  {get(businessCardData, "zip", "")}
                </span>
              )}
            </Text>
          </div>
          <div className={classes.width_50}>
            <span
              onClick={mailTo(get(businessCardData, "email"))}
              className={classes.emailActiveStyle}
            >
              {get(businessCardData, "email", "")}
            </span>
          </div>
        </Row>
        <Row className={classes.padding_top_9}>
          <div className={classes.width_50}>
            <Text
              size={14}
              color={color.primary_palette.black}
              family="avenir_sb"
              className={classes.txtCenterEllipses}
            >
              {get(businessCardData, "address1") && (
                <span>
                  {" "}
                  {get(businessCardData, "city", "")}
                  {get(businessCardData, "state", "") && ", "}
                  {get(businessCardData, "state", "")}&nbsp;
                  {get(businessCardData, "zip", "")}{" "}
                </span>
              )}
            </Text>
          </div>
          <div className={classes.width_50}>
            <Text
              size={14}
              color={color.primary_palette.black}
              family="avenir_sb"
              className={classes.txtCenterEllipses}
            >
              <a
                href={getUrl(get(businessCardData, "webSite"))}
                target="_blank"
                className={classes.websiteTxt}
              >
                {get(businessCardData, "webSite", "")}
              </a>
            </Text>
          </div>
        </Row>
      </div>
      {get(portfolioData, "companyLogo") && (
        <img
          src={
            get(portfolioData, "companyLogo", "") ||
            "/assets/images/Blank Eagle Ribbon.svg"
          }
          className={classes.rc_img}
        />
      )}
    </div>
  );
}

export default MobilePorfolioStyles(MobileBusinessCard);
