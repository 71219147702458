import React from "react";
import { get } from "lodash";
import { color } from "../../utilities/themes";
import { Row, Text } from "../common/ui_kit";
import MobilePorfolioStyles from "./Styles/MobileportfioloStyles";
import { useHistory } from "react-router";

function MobileFlagShip(props) {
  const { classes, align, project, idx } = props;
  const history = useHistory();

  const redirectToProjectView = (projectData) => () => {
    history.push({
      pathname: "/mobile-individual-project",
      state: { ...projectData, idx },
    });
  };

  const returnRightContent = () => {
    return (
      <>
        <Row
          className={classes.flagLeft}
          onClick={redirectToProjectView(project)}
        >
          <img
            alt=""
            src={get(project, "coverImageUri")}
            className={classes.flag_img}
            onClick={redirectToProjectView(project)}
          />
          <div className={`${classes.width_35} ${classes.pos_relative}`}>
            <Text
              className={`${classes.txtCenter} ${classes.border_top}`}
            ></Text>
            <Text
              size={7.8}
              color={color.primary_palette.black}
              className={`${classes.txtCenter}`}
              family="avenir_sb"
              transform="uppercase"
            >
              {get(project, "subtitle", "Classic Revival")}
            </Text>
            <div className={classes.flag_title}>
              <Text
                size={14.7}
                color={color.primary_palette.black}
                family="avenir_black_r"
                transform="uppercase"
              >
                {get(project, "title", "Tropical House By Sea")}
              </Text>
            </div>
            <Row className={classes.view_data}>
              <div className={classes.border_right}>
                <Text
                  size={7.8}
                  color={color.primary_palette.black}
                  family="avenir_light"
                >
                  images
                </Text>
                <Text
                  size={19.6}
                  color={color.primary_palette.black}
                  family="avenir_light"
                >
                  {get(project, "projectImages.length", "0")}
                </Text>
              </div>
              <div>
                <Text
                  size={19.6}
                  color={color.primary_palette.black}
                  family="avenir_sb"
                >
                  VIEW
                </Text>
              </div>
            </Row>
          </div>
        </Row>
      </>
    );
  };

  const returnLeftContent = () => {
    return (
      <>
        <Row
          className={classes.flagLeft}
          onClick={redirectToProjectView(project)}
        >
          <div className={`${classes.width_35} ${classes.pos_relative}`}>
            <Text
              className={`${classes.txtCenter} ${classes.border_top_left}`}
            ></Text>
            <Text
              size={7.8}
              color={color.primary_palette.black}
              className={`${classes.txtRight} ${classes.textWidthLeft}`}
              family="avenir_sb"
              transform="uppercase"
            >
              {get(project, "subtitle", "Classic Revival")}
            </Text>
            <div className={classes.flag_title_right}>
              <Text
                size={14.7}
                color={color.primary_palette.black}
                family="avenir_black_r"
                transform="uppercase"
              >
                {get(project, "title", "Tropical House By Sea")}
              </Text>
            </div>
            <Row className={`${classes.view_data} ${classes.alignLeft}`}>
              <div className={classes.border_right}>
                <Text
                  size={7.8}
                  color={color.primary_palette.black}
                  family="avenir_light"
                >
                  images
                </Text>
                <Text
                  size={19.6}
                  color={color.primary_palette.black}
                  family="avenir_light"
                >
                  {get(project, "projectImages.length", "0")}
                </Text>
              </div>
              <div>
                <Text
                  size={19.6}
                  color={color.primary_palette.black}
                  family="avenir_sb"
                >
                  VIEW
                </Text>
              </div>
            </Row>
          </div>
          <img
            alt=""
            src={get(project, "coverImageUri")}
            className={classes.flag_img}
            onClick={redirectToProjectView(project)}
          />
        </Row>
      </>
    );
  };

  return (
    <div className={classes.flagship_margins}>
      {align === "label_left" && returnLeftContent()}
      {align === "label_right" && returnRightContent()}
    </div>
  );
}

export default MobilePorfolioStyles(MobileFlagShip);
