import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { get } from "lodash";

import { color } from "../../utilities/themes";
import ProductStyles from "./styles/productStyles";
import Row from "../common/ui_kit/row";
import Text from "../common/ui_kit/text";
import strings from "../../utilities/strings";
import Close_Icon from "../data_display/icons/Close";
import CustomButton from "../navigations/custom_buttons";
import Element_Required_Icon from "../data_display/icons/ElementRequiered";
import CustomInputCount from "../inputs/custom_input_count";
import CustomRadioButton from "../inputs/custom_radio_button";
import CustomTextArea from "../inputs/custom_text_area";
import AddImage from "../add_images/add_image_cropper";
import Edit_Circle_Icon from "../data_display/icons/Edit";

import { ProfileActions } from "../../redux/actions";
import { REGEX_URL } from "../../validations/validations";

function ProductPublication(props) {
  const { classes, onClose, isProductValue, productOrPublicationData } = props;
  const [state, setState] = useState({
    errors: {},
    portfolioId: localStorage.getItem("portfolioId"),
    isProduct: true,
    title: "",
    subTitle: "",
    referenceUri: "",
    description: "",
    tags: "",
    imageUri: "",
    imageData: {},
  });

  const dispatch = useDispatch();

  const {
    isProduct,
    title,
    subTitle,
    referenceUri,
    description,
    tags,
    imageData,
    errors,
  } = state;

  const handleInputChange = (e) => {
    handleValidations(e.target.name, e.target.value);
    if (e.target.name === "isProduct") {
      setState({ ...state, [e.target.name]: e.target.value === "true" });
      return;
    }
    if (e.target.name === "tags") {
      if (e.target.value) {
        const tagsData = e.target.value;
        const tagsArray = tagsData.split(",");
        for (var i = 0; i < tagsArray.length; i++) {
          tagsArray[i] = tagsArray[i].includes("#")
            ? tagsArray[i]
            : " #" + tagsArray[i];
        }
        setState({ ...state, [e.target.name]: tagsArray });
        return;
      }
    }
    setState({ ...state, [e.target.name]: e.target.value });
  };

  //API
  useEffect(() => {
    setState({ ...state, isProduct: isProductValue });
  }, []);

  useEffect(() => {
    if (!productOrPublicationData) {
      return;
    }
    setState({ ...state, ...productOrPublicationData });
  }, [productOrPublicationData]);

  const saveProductPublicationData = () => {
    const data = {
      ...state,
    };
    if (hasError) {
      handleValidations("title", state.title);
      let hasimageUri = state.imageUri ? true : false;
      let hasImageData = get(imageData, "imageUrl") ? true : false;
      if (!hasimageUri && !hasImageData) {
        state.errors.imageData = { error: true, message: "" };
        setState({
          ...state,
        });
        return;
      } else {
        state.errors.imageData = { error: false, message: "" };
        setState({
          ...state,
        });
      }
      return;
    }
    dispatch(
      ProfileActions.saveProductData(data, (res) => {
        dispatch(ProfileActions.getProductsResponseData(res.result));
      })
    );
    onClose();
  };

  const handleValidations = (name, value) => {
    if (name === "title") {
      errors[name] = errors[name] || {};
      if (value.length > 60 || !value) {
        errors[name] = { error: true, message: "" };
        setState({
          ...state,
        });
        return;
      }
      errors[name] = { error: false, message: "" };
      setState({
        ...state,
      });
      return;
    }
    if (name === "subTitle") {
      errors[name] = errors[name] || {};
      if (value.length > 60) {
        errors[name] = { error: true, message: "" };
        setState({
          ...state,
        });
        return;
      }
      errors[name] = { error: false, message: "" };
      setState({
        ...state,
      });
      return;
    }
    if (name === "referenceUri") {
      errors[name] = errors[name] || {};
      if (name === "referenceUri" && !REGEX_URL.test(value) && value) {
        errors[name] = { error: true, message: "" };
        setState({
          ...state,
        });
        return;
      }
      errors[name] = { error: false, message: "" };
      setState({
        ...state,
      });
      return;
    }
    if (name === "description") {
      errors[name] = errors[name] || {};
      if (value.length > 600) {
        errors[name] = { error: true, message: "" };
        setState({
          ...state,
        });
        return;
      }
      errors[name] = { error: false, message: "" };
      setState({
        ...state,
      });
      return;
    }
  };

  const hasFormErrors = () => {
    const allErrors = Object.values(errors).map(
      (errObj) => errObj.error || false
    );
    if (
      allErrors.includes(true) ||
      !(imageData.imageUrl || get(productOrPublicationData, "imageUri")) ||
      !title
    ) {
      return true;
    }
    return false;
  };

  const setCroppedImage = (data) => {
    const { imageFile, imageUrl, originalImageUrl, originalImageFile } = data;
    imageData.imageUrl = imageUrl;
    imageData.blobFile = imageFile;
    imageData.originalImageUrl = originalImageUrl;
    imageData.originalImageFile = originalImageFile;
    state.errors.imageData = { error: false, message: "" };
    setState({ ...state, imageData });
  };

  const hasError = hasFormErrors();

  return (
    <>
      <div className={classes.product_width}>
        {productOrPublicationData && (
          <div className={classes.textCenter}>
            <Edit_Circle_Icon className={classes.edit_icon} />
          </div>
        )}
        <div className={classes.btnRight}>
          <CustomButton className={classes.crossBtn} isX>
            <Close_Icon onClick={onClose} />
          </CustomButton>
        </div>

        <div className={`${classes.textCenter} ${classes.margin_top_20}`}>
          <Text
            size={30}
            color={color.primary_palette.black}
            family="avenir_light"
          >
            {productOrPublicationData
              ? strings.product_publication.titles.product_publication_edit
              : strings.product_publication.titles.product_publication}
          </Text>
        </div>
        <div className={classes.product_input}>
          <Row>
            <Row className={classes.justify_left}>
              <Text
                size={16}
                color={
                  get(state.errors, "title.error", "")
                    ? color.primary_palette.christmas_red
                    : color.primary_palette.franklin_purple
                }
                family="avenir_sb"
              >
                <Element_Required_Icon
                  className={
                    get(state.errors, "title.error", "")
                      ? classes.red_required_icon
                      : classes.required_icon
                  }
                />
                {
                  strings.product_publication.titles
                    .product_publication_required
                }
              </Text>
            </Row>
            <Row className={classes.justify_right}>
              <CustomRadioButton
                label={strings.product_publication.titles.product}
                labelPlacement="end"
                name="isProduct"
                value={"true"}
                checked={isProduct}
                onChange={handleInputChange}
                className={classes.product_radio}
                size="small"
              />
              <CustomRadioButton
                label={strings.product_publication.titles.publication}
                labelPlacement="end"
                name="isProduct"
                value={"false"}
                checked={isProduct == false}
                onChange={handleInputChange}
                className={classes.product_radio}
                size="small"
              />
            </Row>
          </Row>
          <div className={`${classes.pos_relative} ${classes.number}`}>
            <CustomInputCount
              enableCount
              countValue={60}
              className={
                get(state.errors, "title.error", "")
                  ? `${classes.red_product_inputfield} ${classes.font22}`
                  : `${classes.product_inputfield} ${classes.font22}`
              }
              onBlur={handleInputChange}
              name="title"
              defaultValue={title}
              error={get(state.errors, "title.error", "")}
            />
          </div>
          <div
            className={`${classes.pos_relative} ${classes.padding_top_24} ${classes.number}`}
          >
            <Text
              size={16}
              color={
                get(state.errors, "subTitle.error", "")
                  ? color.primary_palette.christmas_red
                  : color.primary_palette.franklin_purple
              }
              family="avenir_sb"
            >
              {strings.product_publication.titles.subtitle}
            </Text>
            <CustomInputCount
              enableCount
              countValue={60}
              className={
                get(state.errors, "subTitle.error", "")
                  ? classes.red_product_inputfield
                  : classes.product_inputfield
              }
              placeholder={
                strings.product_publication.titles.subtitle_placeholder
              }
              onBlur={handleInputChange}
              name="subTitle"
              defaultValue={subTitle}
              error={get(state.errors, "subTitle.error", "")}
            />
          </div>
          <div className={`${classes.pos_relative} ${classes.padding_top_24} `}>
            <Text
              size={16}
              color={
                get(state.errors, "referenceUri.error", "")
                  ? color.primary_palette.christmas_red
                  : color.primary_palette.franklin_purple
              }
              family="avenir_sb"
            >
              {strings.product_publication.titles.product_url}
            </Text>
            <CustomInputCount
              className={
                get(state.errors, "referenceUri.error", "")
                  ? classes.red_product_inputfield
                  : classes.product_inputfield
              }
              placeholder={
                strings.product_publication.titles.product_url_placeholder
              }
              onBlur={handleInputChange}
              name="referenceUri"
              defaultValue={referenceUri}
              error={get(state.errors, "referenceUri.error", "")}
            />
          </div>
          <div
            className={`${classes.pos_relative} ${classes.padding_top_24} ${classes.number_textare}`}
          >
            <Text
              size={16}
              color={color.primary_palette.franklin_purple}
              family="avenir_sb"
            >
              {strings.product_publication.titles.marketing_copy}
            </Text>
            <CustomTextArea
              enableCount
              countValue={600}
              className={classes.text_area}
              placeholder={
                strings.product_publication.titles.marketing_copy_placeholder
              }
              onBlur={handleInputChange}
              name="description"
              defaultValue={description}
            />
          </div>
          {description.length > 600 && (
            <div className={classes.errorTxt}>
              <img
                alt=""
                src="/assets/icons/info_1.svg"
                className={classes.errorImage}
              />{" "}
              {strings.product_publication.titles.errorTxtArea}
            </div>
          )}
          <Row>
            <div className={` ${classes.image_block}`}>
              <Element_Required_Icon
                className={
                  get(state.errors, "imageData.error", "")
                    ? classes.red_absolute_required
                    : classes.absolute_required
                }
              />
              <AddImage
                key={get(imageData, "imageUrl", "")}
                defaultImage={get(
                  productOrPublicationData,
                  "imageUri",
                  "/assets/images/Gray_Square.jpg"
                )}
                noRecrop
                name="imageUri"
                type="projectImage"
                croppedImage={
                  get(imageData, "imageUrl", "") ||
                  get(productOrPublicationData, "imageUri", "")
                }
                imageUpload={setCroppedImage}
                originalImg={get(imageData, "originalImageUrl", "")}
                hasImageData={false}
                cropSize={1 / 1.2}
                isProductCropper={true}
                MainText={
                  !productOrPublicationData
                    ? strings.product_publication.titles.upload_image
                    : strings.product_publication.titles.update_image
                }
                MainTextStyle={
                  get(state.errors, "imageData.error", "")
                    ? classes.mainTextsizeError
                    : classes.mainTextsize
                }
                transparentColor={
                  color.secondary_palette.grays.portfolio_view_gray
                }
              />
            </div>
            <div
              className={`${classes.pos_relative} ${classes.padding_top_24} ${classes.hashTagStyle}`}
            >
              <Text
                size={16}
                color={color.primary_palette.franklin_purple}
                family="avenir_sb"
              >
                {strings.product_publication.titles.hashtags}
              </Text>
              <CustomInputCount
                className={classes.product_inputfield}
                placeholder={
                  strings.product_publication.titles.hashtags_placeholder
                }
                onBlur={handleInputChange}
                name="tags"
                defaultValue={tags}
              />
            </div>
          </Row>
        </div>
        <div className={`${classes.textCenter} ${classes.buttonAlign}`}>
          <CustomButton
            className={classes.save_item}
            onMouseUp={saveProductPublicationData}
          >
            {strings.product_publication.titles.save_item}
          </CustomButton>
        </div>
      </div>
    </>
  );
}

export default ProductStyles(ProductPublication);
