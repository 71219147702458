import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { get } from "lodash";
import MenuItem from "@material-ui/core/MenuItem";

import personalSettingsStyles from "./styles/personalSettingsStyles";
import Chevron_Down_Icon from "../data_display/icons/Arrow_Chevron_Down";
import Row from "../common/ui_kit/row";
import Text from "../common/ui_kit/text";
import strings from "../../utilities/strings";
import { color } from "../../utilities/themes";
import CustomTextField from "../../components/inputs/custom_textfields";
import Close_Icon from "../../components/data_display/icons/Close";
import ShowPassword_Icon from "../../components/data_display/icons/ShowPassword";
import HidePassword_Icon from "../../components/data_display/icons/HidePassword";
import { InputAdornment } from "@material-ui/core";
import CustomToggleButton from "../../components/inputs/custom_toggle_button";
import CustomButton from "../navigations/custom_buttons";
import ChoosePicture from "../add_images/choose_picture";
import CustomModal from "../inputs/custom_modal";
import EditInfo from "./edit_info_modal";
import AddImage from "../add_images/add_image";
import UpdatePassword from "../modals/updatePassword";

import { LookUpActions, ProfileActions } from "../../redux/actions";
import {
  REGEX_URL,
  PHONE_NUMBER_FORMAT,
  ATLEAST_ONE_CAPITAL_LETTER,
  NUM_OR_SYMBOL,
} from "../../validations/validations";
import CustomSelect from "../common/ui_kit/custom_select";

function PersonalSettings(props) {
  const uploadRef = useRef(null);
  const [state, setState] = useState({
    showPassword: false,
    countryCode: "USA",
    phoneNumber: "",
    authenticationPhoneNumber: "",
    autoload: false,
    formData: {
      password: "",
    },
    errors: {
      password: null,
    },
    openEditModal: false,
    isEditable: false,
    myFlipbook: "",
    isEditFlipBook: false,
    imageData: {},
    openImageExp: {},
    croppingModalType: null,
    profileData: "",
    openSavedPassword: false,
  });
  const { classes } = props;

  const {
    showPassword,
    openEditModal,
    showCountryDropdown,
    countryCode,
    phoneNumber,
    authenticationPhoneNumber,
    croppingModalType,
    formData,
    formData: { password },
    errors,
    isEditable,
    isEditFlipBook,
    openCroppingModal,
    imageData,
    defaultSearchCity,
    openSavedPassword,
  } = state;

  const dispatch = useDispatch();

  //API
  useEffect(() => {
    fetchData();
    /*global FB*/
    window.fbAsyncInit = function () {
      FB.init({
        appId: "944303855989676",
        xfbml: true,
        version: "v2.6",
      });

      // FB.getLoginStatus(function (response) {
      //   //this.statusChangeCallback(response);
      // });
      // FB.login(function (response) {
      //   // handle the response
      // });
    };
  }, []);

  const fetchData = () => {
    dispatch(ProfileActions.getPersonalSettingData());
    dispatch(LookUpActions.getLookUpData());
  };

  const handleClose = () => {
    setState({
      ...state,
      openCroppingModal: false,
    });
  };

  const handleSavePassword = () => {
    setState({
      ...state,
      openSavedPassword: !openSavedPassword,
    });
  };

  const personalSettingsData = useSelector(
    (resData) => resData.Profile.personalSettingData
  );

  const lookUpData = useSelector((resData) => resData.LookUp.LookUpData);

  const openImageExp = (type) => () => {
    setState({
      ...state,
      openImageExp: { ...openImageExp, [type]: Date.now() },
      openCroppingModal: false,
    });
  };

  const triggerCountryDropdown = () => {
    const { showCountryDropdown } = state;
    setState({ ...state, showCountryDropdown: !showCountryDropdown });
  };

  const handleCountryChange = (value) => {
    const { showCountryDropdown } = state;
    setState({
      ...state,
      countryCode: value,
      authenticationPhoneNumber: "",
      showCountryDropdown: !showCountryDropdown,
    });
  };

  useEffect(() => {
    if (personalSettingsData) {
      setState({
        ...state,
        phoneNumber: get(personalSettingsData, "phoneNumber", ""),
        authenticationPhoneNumber: get(
          personalSettingsData,
          "authenticationPhoneNumber",
          ""
        )
          ? get(personalSettingsData, "authenticationPhoneNumber", "")
          : get(personalSettingsData, "phoneNumber", ""),
        myFlipbook: get(personalSettingsData, "myFlipbook", ""),
        defaultSearchCity: get(personalSettingsData, "defaultSearchCity", ""),
      });
    }
  }, [personalSettingsData]);

  const handleInputChange = (e) => {
    handleValidation(e.target.name, e.target.value);
    if (e.target.name === "authenticationPhoneNumber") {
      const val = deformatPhoneNumber(e.target.value);
      if (countryCode !== "USA") {
        setState({ ...state, authenticationPhoneNumber: val });
        return;
      } else if (isNaN(val)) {
        if (!val) {
          setState({ ...state, authenticationPhoneNumber: "" });
        }
        return;
      }
      setState({ ...state, authenticationPhoneNumber: val });
      return;
    }
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const handleEditModal = () => {
    setState({
      ...state,
      openEditModal: !openEditModal,
    });
  };

  const closeEditModal = () => {
    setState({
      ...state,
      openEditModal: false,
    });
  };

  const onPhoneNumberBlur = () => {
    // FORMATTING PHONE NUMBER
    const { authenticationPhoneNumber } = state;
    if (authenticationPhoneNumber) {
      const num = authenticationPhoneNumber
        .toString()
        .replace(PHONE_NUMBER_FORMAT, "$1.$2.$3");
      setState({ ...state, authenticationPhoneNumber: num });
    }
    return;
  };

  const updateCroppedImage = (imageObject, type) => {
    const { croppedImage, blobUrl } = imageObject;
    const { imageData } = state;

    imageData[type] = imageData[type] || {};
    imageData[type].imageUrl = croppedImage;
    imageData[type].blobFile = blobUrl;
    setState({ ...state, imageData });
  };

  const formatPhoneNumber = () => {
    const { authenticationPhoneNumber } = state;
    return authenticationPhoneNumber.replace(PHONE_NUMBER_FORMAT, "$1.$2.$3");
  };

  const deformatPhoneNumber = (number) => {
    // DEFORMATTING
    if (!number) {
      return;
    }
    return number.replace(/[().\s/]/g, "");
  };

  const onPhoneNumberFocus = () => {
    // ON FOCUS REMOVING FORMAT
    const { authenticationPhoneNumber } = state;
    if (authenticationPhoneNumber) {
      setState({
        ...state,
        authenticationPhoneNumber: authenticationPhoneNumber.replace(
          /[().\s/]/g,
          ""
        ),
      });
      return;
    }
  };

  const handleClickShowPassword = () => {
    setState({ ...state, showPassword: !showPassword });
  };

  const onClearInput = (name) => () => {
    formData[name] = "";
    setState({ ...state, formData });
  };

  const handlePasswordChange = (e) => {
    const { name, value } = e.target;
    formData[name] = value;
    handleValidation(name, value);
    setState({ ...state });
  };

  const hasEightChars = (value) => {
    return value.length >= 8;
  };

  const hasUpperCaseLetter = (value) => {
    return ATLEAST_ONE_CAPITAL_LETTER.test(value);
  };

  const hasNumOrSymbol = (value) => {
    return NUM_OR_SYMBOL.test(value);
  };

  const responseFacebook = (response) => {
    setState({ ...state, autoload: false });
  };

  const componentClicked = () => {
    setState({ ...state, autoload: true });
  };

  const phoneNumberUSFormat = (phoneNumber) => {
    return phoneNumber.replace(PHONE_NUMBER_FORMAT, "$1.$2.$3");
  };

  const phoneEditable = () => {
    setState({
      ...state,
      isEditable: !isEditable,
    });
  };

  const editFlipBook = () => {
    setState({
      ...state,
      isEditFlipBook: !isEditFlipBook,
    });
    saveBasicDetails();
  };

  const handleValidation = (name, value) => {
    if (name === "password" && value !== "") {
      const hasEight = hasEightChars(value);
      const numOrSymbol = hasNumOrSymbol(value);
      const hasCaps = hasUpperCaseLetter(value);
      if (!value) {
        errors[name] = {
          error: true,
          touched: true,
          message: strings.join_us.errors.password_required,
          hasEight,
          numOrSymbol,
          hasCaps,
        };
        setState({ ...state, errors });
        return;
      }
      if (value) {
        if (hasEight && numOrSymbol && hasCaps) {
          errors[name] = {
            error: false,
            message: "",
            hasEight,
            numOrSymbol,
            hasCaps,
            touched: true,
          };
        } else {
          errors[name] = {
            error: true,
            message: "",
            hasEight,
            numOrSymbol,
            hasCaps,
            touched: true,
          };
        }
        setState({ ...state, errors });
      }
    } else {
      errors[name] = { error: false, message: "" };
      setState({
        ...state,
      });
    }
    if (name === "myFlipbook") {
      if (
        value.length > 60 ||
        !value ||
        (name === "myFlipbook" && !REGEX_URL.test(value))
      ) {
        errors[name] = { error: true, message: "" };
        setState({
          ...state,
        });
        return;
      }
      errors[name] = { error: false, message: "" };
      setState({
        ...state,
      });
      return;
    }
  };

  const handleButtonClick = (e) => {
    uploadRef.current.click();
  };

  const toggleCroppingModal = (obj) => {
    const { imageData, type } = obj;
    setState({
      ...state,
      openCroppingModal: true,
      croppingModalType: type,
      imageData: { ...state.imageData, [type]: imageData },
    });
  };

  const saveBasicDetails = () => {
    if (hasError) {
      return;
    }
    const dataToSubmit = {
      ...state,
      firstName: personalSettingsData.firstName,
      lastName: personalSettingsData.lastName,
      userName: personalSettingsData.userName,
      email: personalSettingsData.email,
      phoneNumber: personalSettingsData.phoneNumber,
      authenticationPhoneNumber: state.authenticationPhoneNumber,
      myFlipbook: state.myFlipbook,
      password: state.formData.password,
      imageData: state.imageData,
    };
    dispatch(
      ProfileActions.savePersonalSettingData(dataToSubmit, () => {
        if (password.length > 0) {
          handleSavePassword();
        }
        fetchData();
      })
    );
  };

  const hasFormErrors = () => {
    const { errors } = state;
    const allErrors = Object.values(errors).map(
      (errObj) => errObj?.error || false
    );
    if (allErrors.includes(true)) {
      return true;
    }
    return false;
  };

  const hasError = hasFormErrors();

  const handleSelectDefaultSearchCity = (value) => () => {
    setState({ ...state, defaultSearchCity: value });
  };

  return (
    <>
      <Row>
        <Row>
          <div className={classes.subDivBlockMain}>
            <div className={classes.profileBlockAlign}>
              <div className={classes.profileImgStyle}>
                <AddImage
                  defaultImage={
                    get(
                      personalSettingsData,
                      "profileUrl",
                      "/assets/images/default_personal.png"
                    ) || "/assets/images/default_personal.png"
                  }
                  MainText="Your Profile Picture"
                  MainTextStyle={classes.mainTextUnderline}
                  className={classes.profileImgStyleAlign}
                  type="profile"
                  croppedImage={get(imageData, "profile.imageUrl", "")}
                  isProductCropper={true}
                  imageUpload={toggleCroppingModal}
                  originalImg={get(personalSettingsData, "profileUrl", "")}
                  openImageExp={get(state.openImageExp, "profile", false)}
                />
              </div>
              <div className={classes.btnBlockAlign}></div>
            </div>
            <div className={classes.basicInfo}>
              <Text
                size={15}
                color={color.primary_palette.franklin_purple}
                family="gillsans_sb"
                transform="uppercase"
              >
                {strings.settings.titles.basic_info}
                <img
                  alt=""
                  src="/assets/icons/pencil.svg"
                  className={classes.pencil_icon}
                  onClick={handleEditModal}
                />
              </Text>
            </div>
            <div>
              <div className={classes.txtBlock}>
                <Text
                  size={15}
                  color={color.primary_palette.black}
                  family="gillsans_sb"
                  className={classes.textInline}
                >
                  {strings.settings.titles.first_name}
                </Text>
                <Text
                  size={15}
                  color={color.primary_palette.black}
                  family="gillsans_light"
                  className={classes.textInline}
                >
                  &nbsp; {get(personalSettingsData, "firstName", "")}
                </Text>
              </div>
              <div className={classes.txtBlock}>
                <Text
                  size={15}
                  color={color.primary_palette.black}
                  family="gillsans_sb"
                  className={classes.textInline}
                >
                  {strings.settings.titles.last_name}
                </Text>
                <Text
                  size={15}
                  color={color.primary_palette.black}
                  family="gillsans_light"
                  className={classes.textInline}
                >
                  &nbsp; {get(personalSettingsData, "lastName", "")}
                </Text>
              </div>
              <div className={classes.txtBlock}>
                <Text
                  size={15}
                  color={color.primary_palette.black}
                  family="gillsans_sb"
                  className={classes.textInline}
                >
                  {strings.settings.titles.username}
                </Text>
                <Text
                  size={15}
                  color={color.primary_palette.black}
                  family="gillsans_light"
                  className={classes.textInline}
                >
                  &nbsp;{" "}
                  {get(personalSettingsData, "userName", "") ||
                    get(personalSettingsData, "email", "")}
                </Text>
                <Text
                  size={12}
                  color={color.primary_palette.black}
                  family="gillsans_light"
                >
                  {strings.settings.titles.username_tip}
                </Text>
              </div>
              <div className={classes.txtBlock}>
                <Text
                  size={15}
                  color={color.primary_palette.black}
                  family="gillsans_sb"
                  className={classes.textInline}
                >
                  {strings.settings.titles.primary_email}
                </Text>
                <Text
                  size={15}
                  color={color.primary_palette.black}
                  family="gillsans_light"
                  className={classes.textInline}
                >
                  &nbsp; {get(personalSettingsData, "email", "")}
                </Text>
              </div>
              <div className={classes.txtBlock}>
                <Text
                  size={15}
                  color={color.primary_palette.black}
                  family="gillsans_sb"
                  className={classes.textInline}
                >
                  {strings.settings.titles.main_phone}
                </Text>
                <Text
                  size={15}
                  color={color.primary_palette.black}
                  family="gillsans_light"
                  className={classes.textInline}
                >
                  &nbsp; {phoneNumberUSFormat(phoneNumber)}
                  {/* <div className={classes.phoneNumberActions}>
                    <img
                      alt=""
                      src={
                        countryCode === "USA"
                          ? "/assets/images/usa-flag-icon.png"
                          : "/assets/images/nonUSA.PNG"
                      }
                      className={classes.countryImage}
                    />
                    <Chevron_Down_Icon
                      className={classes.dropDownIcon}
                      onClick={triggerCountryDropdown}
                    />
                    {showCountryDropdown && (
                      <div className={classes.dropdownOptions}>
                        <div
                          onClick={() => {
                            handleCountryChange("USA");
                          }}
                        >
                          USA
                        </div>
                        <div
                          onClick={() => {
                            handleCountryChange("nonUSA");
                          }}
                        >
                          Non-USA
                        </div>
                      </div>
                    )}
                  </div> */}
                </Text>

                <Text
                  size={12}
                  color={color.primary_palette.black}
                  family="gillsans_light"
                >
                  {strings.settings.titles.phone_tip}
                </Text>
              </div>
              <div
                className={`${classes.default_search_wrap} ${classes.txtBlock}`}
              >
                <Text
                  size={14}
                  color={color.primary_palette.black}
                  family="avenir_black_r"
                  className={classes.textInline}
                >
                  {strings.settings.titles.default_search_city}
                </Text>
                <CustomSelect
                  MenuProps={{
                    getContentAnchorEl: null,
                    disableScrollLock: true,
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                  }}
                  value={defaultSearchCity}
                  IconComponent={Chevron_Down_Icon}
                  renderValue={(value) => value}
                  className={classes.default_search_dd}
                >
                  {get(lookUpData, "regions", []).map((region) => {
                    return (
                      <MenuItem
                        className={classes.default_search_items}
                        onClick={handleSelectDefaultSearchCity(region.name)}
                      >
                        {region.name}
                      </MenuItem>
                    );
                  })}
                </CustomSelect>
              </div>
              <div className={classes.txtBlock}>
                {/* commented for now to demo */}
                {/* <Text
                  size={15}
                  color={color.primary_palette.black}
                  family="gillsans_sb"
                  className={classes.textInline}
                >
                  {strings.settings.titles.default_flipbook}
                  <img alt=""
                    src="/assets/icons/pencil.svg"
                    className={classes.pencil_icon}
                    onClick={editFlipBook}
                  />
                </Text> */}
                {/* <Text
                  size={15}
                  color={color.primary_palette.black}
                  family="avenir_light"
                >
                  {!isEditFlipBook ? (
                    myFlipbook
                  ) : (
                    <>
                      <CustomTextField
                        name="myFlipbook"
                        onChange={handleInputChange}
                        value={myFlipbook}
                      />
                      <img alt=""
                        src="assets/icons/icon_happy_arrow.svg"
                        className={classes.saveFlipIcon}
                        onClick={editFlipBook}
                      />
                    </>
                  )}
                </Text> */}
              </div>
            </div>
          </div>
        </Row>
        <Row>
          <div className={`${classes.subDivBlock}`}>
            <Text
              size={15}
              color={color.primary_palette.franklin_purple}
              family="gillsans_sb"
              transform="uppercase"
            >
              {strings.settings.titles.privacy_setting}
            </Text>
            <div className={classes.passwordBlkAlign}>
              <Row>
                <Text
                  size={15}
                  color={color.primary_palette.black}
                  family="gillsans_sb"
                  className={`${classes.textInline} ${classes.passwordAlign}`}
                >
                  {strings.settings.titles.change_password}
                </Text>
                <div className={classes.password_field}>
                  <CustomTextField
                    clearIcon
                    className={classes.passwordFieldAlign}
                    autocomplete="new-password"
                    onChange={handlePasswordChange}
                    name={"password"}
                    value={password}
                    type={showPassword ? "text" : "password"}
                    clearIcon={true}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          tabIndex="-1"
                          aria-label="toggle password visibility"
                          className={classes.password_icon}
                          onClick={handleClickShowPassword}
                        >
                          <Close_Icon
                            onMouseUp={onClearInput("password")}
                            style={{
                              height: "7px",
                              width: "7px",
                              cursor: "pointer",
                              zIndex: "1000",
                              marginRight: "10px",
                            }}
                          />
                          {showPassword ? (
                            <img
                              src="/assets/images/View Password .svg"
                              alt=""
                              className={classes.cursor}
                            />
                          ) : (
                            <HidePassword_Icon
                              style={{
                                fontSize: "1.3125rem",
                                cursor: "pointer",
                              }}
                            />
                          )}
                        </InputAdornment>
                      ),
                    }}
                  />
                  <img
                    alt=""
                    src="/assets/icons/icon_happy_arrow.svg"
                    className={classes.happy_arrow}
                    onClick={saveBasicDetails}
                  />
                </div>
              </Row>
              <div className={classes.passwordTxtAlign}>
                <span
                  className={
                    !get(errors, "password.touched", false)
                      ? classes.password_validation
                      : get(errors, "password.hasEight", false)
                      ? classes.successText
                      : classes.errorText
                  }
                >
                  {strings.join_us.messages.eight_chars}
                </span>
                <span
                  className={
                    !get(errors, "password.touched", false)
                      ? classes.password_validation
                      : get(errors, "password.hasCaps", false)
                      ? classes.successText
                      : classes.errorText
                  }
                >
                  {strings.join_us.messages.one_letter}
                </span>
                <span
                  className={
                    !get(errors, "password.touched", false)
                      ? classes.password_validation
                      : get(errors, "password.numOrSymbol", false)
                      ? classes.successText
                      : classes.errorText
                  }
                >
                  {strings.join_us.messages.number_symbol}
                </span>
              </div>
            </div>
            <Text
              size={15}
              color={color.primary_palette.franklin_purple}
              family="gillsans_sb"
              transform="uppercase"
            >
              {strings.settings.titles.auth_options}
            </Text>
            <Text
              size={15}
              color={color.primary_palette.black}
              family="gillsans_light"
              className={classes.textWidthMobile}
            >
              {strings.settings.titles.mobile_auth}
            </Text>
            <CustomTextField
              className={`${classes.authOptionFieldAlign} ${
                !isEditable && classes.unEditable
              }`}
              name="authenticationPhoneNumber"
              onChange={handleInputChange}
              value={
                countryCode === "USA" &&
                get(authenticationPhoneNumber, "length", 0) >= 10
                  ? formatPhoneNumber()
                  : authenticationPhoneNumber
              }
              onBlur={onPhoneNumberBlur}
              inputProps={{
                maxLength: countryCode === "USA" ? 10 : 20,
                onFocus: onPhoneNumberFocus,
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment
                    position="end"
                    tabIndex="-1"
                    aria-label="toggle password visibility"
                    className={classes.password_icon}
                  >
                    <div className={classes.phoneNumberActions}>
                      <img
                        src={
                          countryCode === "USA"
                            ? "/assets/images/usa-flag-icon.png"
                            : "/assets/images/nonUSA.PNG"
                        }
                        className={classes.countryImage}
                        alt="USA"
                      />
                      <Chevron_Down_Icon
                        className={classes.dropDownIcon}
                        onClick={triggerCountryDropdown}
                      />
                      {showCountryDropdown && (
                        <div className={classes.dropdownOptions}>
                          <div
                            onClick={() => {
                              handleCountryChange("USA");
                            }}
                          >
                            USA
                          </div>
                          <div
                            onClick={() => {
                              handleCountryChange("nonUSA");
                            }}
                          >
                            Non-USA
                          </div>
                        </div>
                      )}
                    </div>
                  </InputAdornment>
                ),
              }}
            />
            {!isEditable ? (
              <img
                alt=""
                src="/assets/icons/pencil.svg"
                className={classes.auth_pencil_icon}
                onClick={phoneEditable}
              />
            ) : (
              <img
                alt=""
                src="/assets/icons/icon_happy_arrow.svg"
                className={classes.auth_pencil_icon}
                onClick={phoneEditable}
              />
            )}
            <Text
              size={15}
              color={color.primary_palette.franklin_purple}
              family="gillsans_sb"
              transform="uppercase"
            >
              {strings.settings.titles.privacy_pref}
            </Text>
            <Text
              size={15}
              color={color.primary_palette.black}
              family="gillsans_light"
            >
              {strings.settings.titles.privacy_pref_desc}
            </Text>
            <Row
              justify="space-between"
              align="center"
              className={classes.customToggleAlign}
            >
              <Row
                justify="space-between"
                align="center"
                className={classes.edit_mode_switch}
              >
                <Text
                  family="gillsans_sb"
                  size={15}
                  color={color.primary_palette.black}
                >
                  {strings.settings.titles.real_name}
                </Text>
                <CustomToggleButton />
                <Text
                  family="gillsans_sb"
                  size={15}
                  color={color.primary_palette.black}
                >
                  {strings.settings.titles.userName_primary}
                </Text>
              </Row>
            </Row>
            <Row
              justify="space-between"
              align="center"
              className={classes.customToggleAlign}
            >
              <Row
                justify="space-between"
                align="center"
                className={`${classes.edit_mode_switch} ${classes.edit_mode_switch1}`}
              >
                <Text
                  family="gillsans_sb"
                  size={15}
                  color={color.primary_palette.black}
                >
                  {strings.settings.titles.show_city}
                </Text>
                <CustomToggleButton className={classes.toggle_alignment} />
                <Text
                  family="gillsans_sb"
                  size={15}
                  color={color.primary_palette.black}
                  className={classes.padding_left_7}
                >
                  {strings.settings.titles.hide_city}
                </Text>
              </Row>
            </Row>
          </div>
        </Row>
      </Row>
      <div className={classes.SaveBtnDiv}>
        <CustomButton
          className={classes.edit_save_btn}
          onClick={saveBasicDetails}
        >
          Save
        </CustomButton>
      </div>
      <CustomModal open={openEditModal} onClose={handleEditModal}>
        <EditInfo
          onClose={handleEditModal}
          personalSettingsData={personalSettingsData}
        />
      </CustomModal>
      <CustomModal
        open={openCroppingModal}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <ChoosePicture
          isProfileCropper={true}
          imageUrl={get(imageData[croppingModalType], "imageUrl", "")}
          type={croppingModalType}
          updateCroppedImage={updateCroppedImage}
          openImageExp={openImageExp}
          onClose={handleClose}
          ishideDelete={true}
        />
      </CustomModal>
      <CustomModal open={openSavedPassword} onClose={handleSavePassword}>
        <UpdatePassword onClose={handleSavePassword} />
      </CustomModal>
    </>
  );
}

export default personalSettingsStyles(PersonalSettings);
