import { withStyles } from "@material-ui/core/styles";
import tradework_theme, { pxToRem, color } from "../../../utilities/themes";

const styles = withStyles({
  txtCenter: {
    textAlign: "center",
  },
  invoice_width: {
    width: pxToRem(827),
    boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.5)",
    border: `solid ${pxToRem(2)} ${color.operative_pearl}`,
    backgroundColor: color.primary_palette.white,
    padding: `${pxToRem(45)} ${pxToRem(27)}`,
    marginTop: pxToRem(50),
  },
  width_30: {
    width: "30%",
  },
  width_70: {
    width: "70%",
    position: "relative",
  },
  fr_icon: {
    width: pxToRem(200),
  },
  padding_top_60: {
    paddingTop: pxToRem(60),
  },
  padding_bottom_30: {
    paddingBottom: pxToRem(30),
  },
  text_decoration: {
    borderBottom: `solid ${pxToRem(2)} ${color.primary_palette.black}`,
  },
  invoice_data: {
    height: "100%",
    backgroundImage:
      "url('https://trablobdev.blob.core.windows.net/tra-con-use-dev/tradeWork/5e745a894d5c7406c0b2db5a1607598477814.png')",
    width: "100%",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  },
  padding_top_30: {
    paddingTop: pxToRem(10),
  },
  padding_top_36: {
    paddingTop: pxToRem(36),
  },
  border_bottom: {
    borderBottom: `solid ${pxToRem(1)} ${
      color.secondary_palette.grays.shadow_gray
    }`,
  },
  table: {
    borderCollapse: "collapse",
    width: "100%",
    tableLayout: "fixed",
    "& th": {
      ...tradework_theme.typography.styles.avenir_sb,
      color: color.primary_palette.black,
      fontSize: pxToRem(15),
    },
    "& td": {
      ...tradework_theme.typography.styles.avenir_sb,
      color: color.primary_palette.black,
      fontSize: pxToRem(15),
      textAlign: "center",
      paddingTop: pxToRem(15),
    },
  },
  txtLeft: {
    textAlign: "left !important",
  },
  padding_left_20: {
    paddingLeft: pxToRem(20),
  },
  txtCapital: {
    textTransform: "uppercase",
  },
  total_cal: {
    textAlign: "right",
    paddingTop: pxToRem(40),
  },
  padding_top_225: {
    paddingTop: pxToRem(225),
  },
  amount_due: {
    width: pxToRem(124),
    height: pxToRem(126),
    paddingTop: pxToRem(26),
    paddingRight: pxToRem(16),
  },
  paid_stamp: {
    width: pxToRem(224),
    paddingTop: pxToRem(26),
  },
  paid_green: {
    width: "50%",
    textAlign: "center",
  },
  paid_red: {
    width: "50%",
    textAlign: "right",
  },
  thank_you: {
    position: "absolute",
    bottom: pxToRem(46),
    left: "46%",
  },
  border: {
    width: pxToRem(88),
    height: pxToRem(1),
    backgroundColor: color.primary_palette.black,
    margin: "0 auto",
    display: "block",
    marginBottom: pxToRem(4),
  },
  rotate_data: {
    transform: "rotateZ(-10deg)",
    marginTop: pxToRem(-19),
    marginLeft: pxToRem(20),
  },
  pos_rel: {
    position: "relative",
    height: "100%",
  },
  circle: {
    width: pxToRem(40),
    height: pxToRem(40),
    border: `solid ${pxToRem(2)} ${color.primary_palette.free_purple}`,
    borderRadius: "100%",
    position: "absolute",
    backgroundColor: color.primary_palette.white,
    "&:after": {
      position: "absolute",
      content: "''",
      width: pxToRem(50),
      height: pxToRem(50),
      backgroundColor: color.primary_palette.white,
    },
  },
  circle1_position: {
    top: pxToRem(-16),
    left: pxToRem(-18),
    "&:after": {
      top: pxToRem(-13),
      left: pxToRem(-16),
      transform: "rotateZ(42deg)",
    },
  },
  circle2_position: {
    top: pxToRem(-16),
    right: pxToRem(-18),
    "&:after": {
      top: pxToRem(-10),
      left: pxToRem(6),
      transform: "rotateZ(-42deg)",
      width: `${pxToRem(34)} !important`,
      backgroundColor: color.primary_palette.white,
    },
  },
  circle3_position: {
    bottom: pxToRem(-21),
    left: pxToRem(-18),
    "&:after": {
      bottom: pxToRem(-13),
      left: pxToRem(-16),
      transform: "rotateZ(45deg)",
    },
  },
  circle4_position: {
    bottom: pxToRem(-16),
    right: pxToRem(-18),
    "&:after": {
      bottom: pxToRem(-12),
      left: pxToRem(7),
      transform: "rotateZ(38deg)",
      width: `${pxToRem(34)} !important`,
      backgroundColor: color.primary_palette.white,
    },
  },
  invoiceBtn: {
    width: pxToRem(113),
    height: pxToRem(16),
    padding: 0,
    border: `solid ${pxToRem(0.8)} ${color.primary_palette.franklin_purple}`,
    borderRadius: pxToRem(9.2),
    backgroundColor: color.primary_palette.white,
    color: `${color.primary_palette.franklin_purple} !important`,
    fontSize: pxToRem(11.2),
    ...tradework_theme.typography.styles.avenir_sb,
    marginBottom: pxToRem(14),
    marginLeft: pxToRem(14),
  },
  margin_top_40: {
    marginTop: pxToRem(40),
    justifyContent: "center",
    marginBottom: pxToRem(70),
  },
  padding_20: {
    padding: `${pxToRem(0)} ${pxToRem(20)}`,
  },
  txtRight: {
    textAlign: "right !important",
  },
  padding_top_10: {
    paddingTop: pxToRem(10),
  },
  padding_top_20: {
    paddingTop: pxToRem(20),
  },
  padding_top_5: {
    paddingTop: pxToRem(5),
  },
  txtBlack: {
    color: color.primary_palette.black,
  },
});

export default styles;
