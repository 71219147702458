import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";
import { makeStyles, withStyles } from "@material-ui/core/styles";

function Styled_cross(props) {
  function styledIcon() {
    const classes = makeStyles(props);
    return (
      <span className={classes.root}>
        <SvgIcon {...props} viewBox="0 0 11 11">
          <path
            d="M10.71 0l-.132.065-.105-.043h-.342l-.394.15-1.026.76-1.423 1.194L5.79 3.601l-.553-1.647-.369-.74-.5-.844-.315-.305-.157.108h-.291l-.394.11-.37.303-.157.239v1.54l.553 1.886.605 1.434-1.578 1.714-1.186 1.409-.394.413-.552.868-.132.39.079.347.078.108.343-.064.316-.216.105.042.157-.217h.132l.343-.456.105-.02.762-.717.291-.347L4 7.615l.053-.109.527-.455 1.604 2.04 1.5 1.496.21-.064.158.26.237.108.079-.065.264.044.474.13-.185-.239h.104l.106.13h.105l-.157-.237-.29-.392.027-.065-.105-.392-.08-.02-.08-.217-.315-.39-1-1.801-.815-1.823.131-.283.105-.043.107-.194L7 4.86l1.473-1.41.974-.824.869-.847h.078l.58-.673L11 .998 10.947.37 10.763 0z"
            fill="#C01F2A"
            fillRule="evenodd"
          />
        </SvgIcon>
      </span>
    );
  }

  /**
   * Custom styling the colors
   */
  const Custom_Styled_cross_Icon = withStyles({
    root: {
      "& > svg": {},
      "& :hover": {},
    },
  })(styledIcon);
  return (
    <>
      <Custom_Styled_cross_Icon {...props} />
    </>
  );
}

export default Styled_cross;
