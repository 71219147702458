import React from "react";
import { get } from "lodash";

import GridStyles from "./styles/gridStyles";
import Row from "../common/ui_kit/row";

function GridSix(props) {
  const { classes, imageData, imageSize } = props;

  return (
    <>
      <div className={classes.publicviewProject}>
        <Row
          className={
            imageSize ? classes.grid_main_spacing_m : classes.grid_main_spacing
          }
        >
          <div>
            <div>
              <img
                alt=""
                className={imageSize ? classes.grid_6_1_m : classes.grid_6_1}
                src={
                  get(imageData[0], "imageUri", "") || "/assets/images/gray.PNG"
                }
              />
            </div>
            <div>
              <img
                alt=""
                className={imageSize ? classes.grid_6_2_m : classes.grid_6_2}
                src={
                  get(imageData[1], "imageUri", "") || "/assets/images/gray.PNG"
                }
              />
            </div>
          </div>
          <div>
            <div>
              <img
                alt=""
                className={imageSize ? classes.grid_6_3_m : classes.grid_6_3}
                src={
                  get(imageData[2], "imageUri", "") || "/assets/images/gray.PNG"
                }
              />
            </div>
            <div>
              <img
                alt=""
                className={imageSize ? classes.grid_6_4_m : classes.grid_6_4}
                src={
                  get(imageData[3], "imageUri", "") || "/assets/images/gray.PNG"
                }
              />
            </div>
          </div>
          <div>
            <div>
              <img
                alt=""
                className={imageSize ? classes.grid_6_5_m : classes.grid_6_5}
                src={
                  get(imageData[4], "imageUri", "") || "/assets/images/gray.PNG"
                }
              />
            </div>
            <div>
              <img
                alt=""
                className={imageSize ? classes.grid_6_6_m : classes.grid_6_6}
                src={
                  get(imageData[5], "imageUri", "") || "/assets/images/gray.PNG"
                }
              />
            </div>
          </div>
        </Row>
      </div>
    </>
  );
}

export default GridStyles(GridSix);
