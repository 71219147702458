import React from "react";

import publicStyles from "./styles/portfolioUserStyles";
import PortFolio from "./portfolio";

function FrView(props) {
  localStorage.setItem("frpage", "");
  let tokenString = props.location;
  if (tokenString && tokenString.pathname) {
    if (tokenString.pathname === "/frlogin") {
      localStorage.setItem("frpage", "login");
      localStorage.setItem("redirectFranklin", true);
    } else if (tokenString.pathname === "/login") {
      localStorage.setItem("frpage", "login");
      localStorage.setItem("redirectFranklin", false);
    } else if (tokenString.pathname === "/frjoinus") {
      localStorage.setItem("frpage", "joinus");
      localStorage.setItem("redirectFranklin", true);
    } else if (tokenString.pathname === "/joinus") {
      localStorage.setItem("frpage", "joinus");
      localStorage.setItem("redirectFranklin", false);
    } else if (tokenString.pathname === "/frlogin/review") {
      localStorage.setItem("frpage", "login");
      localStorage.setItem("Review", true);
    } else if (tokenString.pathname === "/frlogin/newPortfolio") {
      localStorage.setItem("frpage", "login");
      localStorage.setItem("NewPortfolio", true);
    }
  }
  return <PortFolio router={props.history} />;
}

export default publicStyles(FrView);
