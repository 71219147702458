import React from "react";
import strings from "../../utilities/strings";
import { Text } from "../common/ui_kit";
import { color } from "../../utilities/themes";
import { get } from "lodash";
import MobilePorfolioStyles from "./Styles/MobileportfioloStyles";
import { FRANKLIN_QA, FRANKLIN_STAGE, FRANKLIN_PROD } from "../../constants";
import { useHistory } from "react-router";

function MobileOptionFooter(props) {
  const { classes } = props;
  const history = useHistory();

  const redirectTo = (type) => () => {
    if (type === "frReportCard") {
      let host = get(window, "location.host", "");
      let frLoginHost = "";
      if (host) {
        if (host.includes("localhost")) {
          frLoginHost = FRANKLIN_QA;
        } else if (host.includes("-dev-")) {
          frLoginHost = FRANKLIN_QA;
        } else if (host.includes("-qa-")) {
          frLoginHost = FRANKLIN_QA;
        } else if (host.includes("-stage-")) {
          frLoginHost = FRANKLIN_STAGE;
        } else if (host.includes("franklinreport")) {
          frLoginHost = FRANKLIN_PROD;
        }
      }
      return (window.location.href = `${frLoginHost}/ReportCard.aspx?v=${localStorage.getItem(
        "vendorId"
      )}`);
    } else if (type === "overView") {
      history.push("/mobile-overview");
    } else if (type === "allProjects") {
      history.push("/mobile-projects");
    } else if (type === "productsPublications") {
      history.push("/mobile-products-publications");
    }
  };

  return (
    <>
      <div className={` ${classes.optionsBorder}`}>
        <Text
          size={14}
          color={color.primary_palette.franklin_purple}
          family="avenir_sb"
          transform="uppercase"
          className={`${classes.txtCenter} ${classes.marginBottom9}`}
          onClick={redirectTo("overView")}
        >
          {strings.portfolio_userView.titles.overview}
        </Text>
        <Text
          size={14}
          color={color.primary_palette.franklin_purple}
          family="avenir_sb"
          transform="uppercase"
          className={`${classes.txtCenter} ${classes.marginBottom9}`}
          onClick={redirectTo("allProjects")}
        >
          {strings.portfolio_userView.titles.all_projects}
        </Text>
        <Text
          size={14}
          color={color.primary_palette.franklin_purple}
          family="avenir_sb"
          transform="uppercase"
          className={`${classes.txtCenter} ${classes.marginBottom9}`}
          onClick={redirectTo("productsPublications")}
        >
          {strings.portfolio_userView.titles.products_publications}
        </Text>
        <Text
          size={14}
          color={color.primary_palette.franklin_purple}
          family="avenir_sb"
          transform="uppercase"
          className={`${classes.txtCenter} ${classes.marginBottom9}`}
          onClick={redirectTo("frReportCard")}
        >
          {strings.portfolio_userView.titles.report_card}
        </Text>
      </div>
    </>
  );
}

export default MobilePorfolioStyles(MobileOptionFooter);
